import {
  BeneficiaryBeneficiaryRelationType,
  NonDiscretionaryAccountKycUserAnnualIncome,
  NonDiscretionaryAccountKycUserEmploymentStatus,
  NonDiscretionaryAccountKycUserInvestmentExperience,
  NonDiscretionaryAccountKycUserInvestmentObjective,
  NonDiscretionaryAccountKycUserLiquidityNeeds,
  NonDiscretionaryAccountKycUserMaritalStatus,
  NonDiscretionaryAccountKycUserNetWorth,
  NonDiscretionaryAccountKycUserTimeHorizon,
  RoboGeneralInvestmentRoboInfusionFreq,
  UserProfileUserVisaType,
  UserRiskTolerance,
} from '@fintronners/react-api';
import { V1GoalPriority } from '@fintronners/react-api/src/tsoai';
import i18n from '@fintronners/react-language/src/Lang';

export const UserKycEnum: Record<string, string> = {
  [NonDiscretionaryAccountKycUserInvestmentExperience.UserInvestmentExperienceExtensive]: i18n.t(
    'onboardingStocks.firstSurvey.experienceLevelAnswers.extensive',
  ),
  [NonDiscretionaryAccountKycUserInvestmentExperience.UserInvestmentExperienceGood]: i18n.t(
    'onboardingStocks.firstSurvey.experienceLevelAnswers.good',
  ),
  [NonDiscretionaryAccountKycUserInvestmentExperience.UserInvestmentExperienceLimited]: i18n.t(
    'onboardingStocks.firstSurvey.experienceLevelAnswers.limited',
  ),
  [NonDiscretionaryAccountKycUserInvestmentExperience.UserInvestmentExperienceNone]: i18n.t(
    'onboardingStocks.firstSurvey.experienceLevelAnswers.none',
  ),
  [UserRiskTolerance.UserRiskToleranceHigh]: i18n.t(
    `riskTolerance.${UserRiskTolerance.UserRiskToleranceHigh}`,
  ),
  [UserRiskTolerance.UserRiskToleranceLow]: i18n.t(
    `riskTolerance.${UserRiskTolerance.UserRiskToleranceLow}`,
  ),
  [UserRiskTolerance.UserRiskToleranceMedium]: i18n.t(
    `riskTolerance.${UserRiskTolerance.UserRiskToleranceMedium}`,
  ),
  [NonDiscretionaryAccountKycUserTimeHorizon.UserTimeHorizonAverage]: i18n.t(
    'onboardingStocks.firstSurvey.investmentHorizonAnswers.average',
  ),
  [NonDiscretionaryAccountKycUserTimeHorizon.UserTimeHorizonLongest]: i18n.t(
    'onboardingStocks.firstSurvey.investmentHorizonAnswers.longest',
  ),
  [NonDiscretionaryAccountKycUserTimeHorizon.UserTimeHorizonShort]: i18n.t(
    'onboardingStocks.firstSurvey.investmentHorizonAnswers.short',
  ),
  [NonDiscretionaryAccountKycUserAnnualIncome.UserAnnualIncome_0_25000]: '$0 - $25,000',
  [NonDiscretionaryAccountKycUserAnnualIncome.UserAnnualIncome_25001_50000]: '$25,001 - $50,000',
  [NonDiscretionaryAccountKycUserAnnualIncome.UserAnnualIncome_50001_100000]: '$50,001 - $100,000',
  [NonDiscretionaryAccountKycUserAnnualIncome.UserAnnualIncome_100001_200000]:
    '$100,001 - $200,000',
  [NonDiscretionaryAccountKycUserAnnualIncome.UserAnnualIncome_200001_300000]:
    '$200,001 - $300,000',
  [NonDiscretionaryAccountKycUserAnnualIncome.UserAnnualIncome_300001_500000]:
    '$300,001 - $500,000',
  [NonDiscretionaryAccountKycUserAnnualIncome.UserAnnualIncome_500001_1200000]:
    '$500,001 - $1,200,000',
  [NonDiscretionaryAccountKycUserAnnualIncome.UserAnnualIncome_500001_1000000]:
    '$500,001 - $1,000,000',
  [NonDiscretionaryAccountKycUserAnnualIncome.UserAnnualIncome_1000001_5000000]:
    '$1,000,001 - $5,000,000',
  [NonDiscretionaryAccountKycUserAnnualIncome.UserAnnualIncome_5000001_9999999]: '$5,000,001+',
  [NonDiscretionaryAccountKycUserAnnualIncome.UserAnnualIncome_1200001_9999999]: '$1,200,001+',
  [NonDiscretionaryAccountKycUserEmploymentStatus.UserEmploymentStatusEmployed]: i18n.t(
    'onboardingStocks.secondSurvey.employmentStatusAnswers.employed',
  ),
  [NonDiscretionaryAccountKycUserEmploymentStatus.UserEmploymentStatusRetired]: i18n.t(
    'onboardingStocks.secondSurvey.employmentStatusAnswers.retired',
  ),
  [NonDiscretionaryAccountKycUserEmploymentStatus.UserEmploymentStatusStudent]: i18n.t(
    'onboardingStocks.secondSurvey.employmentStatusAnswers.student',
  ),
  [NonDiscretionaryAccountKycUserEmploymentStatus.UserEmploymentStatusSelfEmployed]: i18n.t(
    'onboardingStocks.secondSurvey.employmentStatusAnswers.selfemployed',
  ),
  [NonDiscretionaryAccountKycUserEmploymentStatus.UserEmploymentStatusUnemployed]: i18n.t(
    'onboardingStocks.secondSurvey.employmentStatusAnswers.unemployed',
  ),
  [NonDiscretionaryAccountKycUserInvestmentObjective.UserInvestmentObjectiveCapitalPreservation]:
    i18n.t('onboardingStocks.firstSurvey.investmentObjectiveAnswers.capitalpreservation'),
  [NonDiscretionaryAccountKycUserInvestmentObjective.UserInvestmentObjectiveBalanced]: i18n.t(
    'onboardingStocks.firstSurvey.investmentObjectiveAnswers.balanced',
  ),
  [NonDiscretionaryAccountKycUserInvestmentObjective.UserInvestmentObjectiveGrowth]: i18n.t(
    'onboardingStocks.firstSurvey.investmentObjectiveAnswers.growth',
  ),
  [NonDiscretionaryAccountKycUserInvestmentObjective.UserInvestmentObjectiveIncome]: i18n.t(
    'onboardingStocks.firstSurvey.investmentObjectiveAnswers.income',
  ),
  [NonDiscretionaryAccountKycUserInvestmentObjective.UserInvestmentObjectiveOther]: i18n.t(
    'onboardingStocks.firstSurvey.investmentObjectiveAnswers.other',
  ),
  [NonDiscretionaryAccountKycUserInvestmentObjective.UserInvestmentObjectiveSpeculation]: i18n.t(
    'onboardingStocks.firstSurvey.investmentObjectiveAnswers.speculation',
  ),
  [NonDiscretionaryAccountKycUserLiquidityNeeds.UserLiquidityNeedsNotImportant]: i18n.t(
    'onboardingStocks.firstSurvey.liquidityNeedsAnswers.notimportant',
  ),
  [NonDiscretionaryAccountKycUserLiquidityNeeds.UserLiquidityNeedsSomewhatImportant]: i18n.t(
    'onboardingStocks.firstSurvey.liquidityNeedsAnswers.somewhatimportant',
  ),
  [NonDiscretionaryAccountKycUserLiquidityNeeds.UserLiquidityNeedsVeryImportant]: i18n.t(
    'onboardingStocks.firstSurvey.liquidityNeedsAnswers.veryimportant',
  ),
  [NonDiscretionaryAccountKycUserMaritalStatus.UserMaritalStatusDivorced]: i18n.t(
    'onboardingStocks.secondSurvey.maritalStatusAnswers.divorced',
  ),
  [NonDiscretionaryAccountKycUserMaritalStatus.UserMaritalStatusMarried]: i18n.t(
    'onboardingStocks.secondSurvey.maritalStatusAnswers.married',
  ),
  [NonDiscretionaryAccountKycUserMaritalStatus.UserMaritalStatusSingle]: i18n.t(
    'onboardingStocks.secondSurvey.maritalStatusAnswers.single',
  ),
  [NonDiscretionaryAccountKycUserMaritalStatus.UserMaritalStatusWidowed]: i18n.t(
    'onboardingStocks.secondSurvey.maritalStatusAnswers.widowed',
  ),
  [NonDiscretionaryAccountKycUserNetWorth.UserNetWorth_0_25000]: '$0 - $25,000',
  [NonDiscretionaryAccountKycUserNetWorth.UserNetWorth_0_50000]: '$0 - $50,000',
  [NonDiscretionaryAccountKycUserNetWorth.UserNetWorth_25001_50000]: '$25,001 - $50,000',
  [NonDiscretionaryAccountKycUserNetWorth.UserNetWorth_50001_100000]: '$50,001 - $100,000',
  [NonDiscretionaryAccountKycUserNetWorth.UserNetWorth_100001_200000]: '$100,001 - $200,000',
  [NonDiscretionaryAccountKycUserNetWorth.UserNetWorth_200001_300000]: '$200,001 - $300,000',
  [NonDiscretionaryAccountKycUserNetWorth.UserNetWorth_200001_500000]: '$200,001 - $500,000',
  [NonDiscretionaryAccountKycUserNetWorth.UserNetWorth_300001_500000]: '$300,001 - $500,000',
  [NonDiscretionaryAccountKycUserNetWorth.UserNetWorth_500001_1000000]: '$500,001 - $1,000,000',
  [NonDiscretionaryAccountKycUserNetWorth.UserNetWorth_1000001_5000000]: '$1,000,001 - $5,000,000',
  [NonDiscretionaryAccountKycUserNetWorth.UserNetWorth_5000001_9999999]: '$5,000,001+',
  [RoboGeneralInvestmentRoboInfusionFreq.RoboInfusionFreqWeekly]: i18n.t('common.frequency.weekly'),
  [RoboGeneralInvestmentRoboInfusionFreq.RoboInfusionFreqBiWeekly]: i18n.t(
    'common.frequency.biWeekly',
  ),
  [RoboGeneralInvestmentRoboInfusionFreq.RoboInfusionFreqSemiMonthly]: i18n.t(
    'common.frequency.semiMonthly',
  ),
  [RoboGeneralInvestmentRoboInfusionFreq.RoboInfusionFreqMonthly]: i18n.t(
    'common.frequency.monthly',
  ),
  [RoboGeneralInvestmentRoboInfusionFreq.RoboInfusionFreqSemiAnnually]: i18n.t(
    'common.frequency.semiAnnually',
  ),
  [RoboGeneralInvestmentRoboInfusionFreq.RoboInfusionFreqYearly]: i18n.t('common.frequency.yearly'),
  [V1GoalPriority.Dream]: i18n.t(`robo.goalImportance.${V1GoalPriority.Dream}`),
  [V1GoalPriority.Need]: i18n.t(`robo.goalImportance.${V1GoalPriority.Need}`),
  [V1GoalPriority.Want]: i18n.t(`robo.goalImportance.${V1GoalPriority.Want}`),
  [V1GoalPriority.Wish]: i18n.t(`robo.goalImportance.${V1GoalPriority.Wish}`),
  [V1GoalPriority.Unspecified]: i18n.t('common.unspecified'),
  [UserProfileUserVisaType.UserVisaTypeE1]: 'E1',
  [UserProfileUserVisaType.UserVisaTypeE2]: 'E2',
  [UserProfileUserVisaType.UserVisaTypeE3]: 'E3',
  [UserProfileUserVisaType.UserVisaTypeF1]: 'F1',
  [UserProfileUserVisaType.UserVisaTypeG4]: 'G4',
  [UserProfileUserVisaType.UserVisaTypeH1B]: 'H1B',
  [UserProfileUserVisaType.UserVisaTypeL1]: 'L1',
  [UserProfileUserVisaType.UserVisaTypeO1]: 'O1',
  [UserProfileUserVisaType.UserVisaTypeTn1]: 'Tn1',
  [UserProfileUserVisaType.UserVisaTypeOther]: i18n.t('common.other'),
  [BeneficiaryBeneficiaryRelationType.BeneficiaryRelationTypeOther]: i18n.t(
    'retirementBeneficiary.relationTypeAnswers.other',
  ),
  [BeneficiaryBeneficiaryRelationType.BeneficiaryRelationTypeSpouse]: i18n.t(
    'retirementBeneficiary.relationTypeAnswers.spouse',
  ),
  [BeneficiaryBeneficiaryRelationType.BeneficiaryRelationTypeTrust]: i18n.t(
    'retirementBeneficiary.relationTypeAnswers.trust',
  ),
};

export const getUserKycDisplayString = (
  input: keyof typeof UserKycEnum | boolean | undefined | null,
) => {
  if (input === undefined || input === null) {
    return '';
  }
  if (input === true) {
    return i18n.t('common.yes');
  }
  if (input === false) {
    return i18n.t('common.no');
  }
  return UserKycEnum[input] || '';
};
