import { gql } from '@apollo/client';

export const SECURITY_ASSET_BASIC_DETAILS = gql`
  fragment SecurityAssetBasicDetails on SecurityAsset {
    id
    createTime
    updateTime
    name
    description
    symbol
    iconURI
    logoURI
    highlyVolatile
    complexSecurity
    market {
      volume
      previousDayClose
      last
      close
      open
      bidSize
      askSize
      baseMin
    }
  }
`;

export const SECURITY_DETAILED_DATA = gql`
  fragment SecurityAssetDetailedData on SecurityAsset {
    ...SecurityAssetBasicDetails
    financials {
      statements(limit: 1) {
        revenue
        ebitda
        earningsPerShare
        profitMargin
        bookValuePerShare
      }
      dividends(limit: 1) {
        cashAmount
      }
      peRatio
    }
    market {
      historical {
        volume
      }
    }
    marketCap
    askExchange {
      id
      logo
      name
    }
    bidExchange {
      id
      logo
      name
    }
    sector {
      id
      name
    }
    securityType
    beta
    cusip
  }
  ${SECURITY_ASSET_BASIC_DETAILS}
`;

export const SECURITY_ASSET_BASIC_DETAILS_QUERY = gql`
  query SecurityAssetBasicDetailsQuery($id: ID, $symbol: String) {
    securityAssets(where: { or: { id: $id, symbol: $symbol } }) {
      edges {
        node {
          ...SecurityAssetBasicDetails
        }
      }
    }
  }
  ${SECURITY_ASSET_BASIC_DETAILS}
`;

export const SECURITY_ASSET_DETAILS = gql`
  query SecurityAssetDetails($securityId: ID!) {
    securityAssets(where: { id: $securityId }) {
      edges {
        node {
          ...SecurityAssetDetailedData
        }
      }
    }
  }
  ${SECURITY_DETAILED_DATA}
`;

export const GET_SECURITY_TRADING_DATA = gql`
  query GetSecurityTradingData($balanceIds: [ID!], $portfolioId: ID!, $securityId: ID!) {
    securityAssets(where: { id: $securityId }) {
      edges {
        node {
          securityType
          ...SecurityAssetBasicDetails
          details {
            ... on SecurityMutualFundDetails {
              transactionFee
            }
          }
        }
      }
    }
    userAccountPortfolioAssets(
      where: { hasAssetWith: { idIn: $balanceIds }, hasPortfolioWith: { id: $portfolioId } }
    ) {
      edges {
        node {
          id
          asset {
            id
            name
          }
          balances {
            balance
            settledBalance
            pendingBalance
            tradeable
          }
        }
      }
    }
  }
  ${SECURITY_ASSET_BASIC_DETAILS}
`;

export const GET_SECURITY_FOR_USER = gql`
  query GetSecurityForUser($securityID: ID!, $accountID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          ...SecurityAssetBasicDetails
          sector {
            id
            name
          }
          financials {
            dividends(limit: 1) {
              cashAmount
            }
          }
        }
      }
    }
    userAccountPortfolios(where: { hasAccountWith: { id: $accountID } }) {
      edges {
        node {
          id
          totalValue
          assetBalances(where: { hasAssetWith: { id: $securityID } }) {
            edges {
              node {
                costBasis
                balances {
                  activeBalance
                }
              }
            }
          }
        }
      }
    }
    dividendDistributions(
      # FIXME: Add security filter (not yet available on BE)
      where: { hasPortfolioWith: { hasAccountWith: { id: $accountID } } }
      orderBy: { direction: DESC, field: create_time }
    ) {
      edges {
        node {
          amount
          distributionAsset {
            id
          }
        }
      }
    }
  }
  ${SECURITY_ASSET_BASIC_DETAILS}
`;

export const GET_STOCK_PRICE_DATA = gql`
  query GetStockPriceData($securityId: ID!, $period: TimePeriod!, $start: Time!, $end: Time) {
    securityAssets(where: { id: $securityId }) {
      edges {
        node {
          id
          symbol
          market {
            previousDayClose
            last
            historical(range: { start: $start, period: $period, end: $end }) {
              start
              close
              avgPrice
              timestamps
            }
          }
        }
      }
    }
  }
`;

export const GET_USER_HAS_SHARES = gql`
  query GetUserHasShares($securityID: ID!) {
    userAccountPortfolioAssets(
      where: { hideZeroBalance: true, hasAssetWith: { id: $securityID } }
    ) {
      edges {
        node {
          balances {
            activeBalance
          }
        }
      }
    }
  }
`;

export const GET_SECURITY_SECTORS_CHART = gql`
  query GetSecuritySectorsChart($securityID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          details {
            ... on SecurityETFDetails {
              sectorCount
              sectors {
                name
                percentage
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_SECURITY_TOP_10_HOLDINGS_CHART = gql`
  query GetSecurityTop10HoldingsChart($securityId: ID!) {
    securityAssets(where: { id: $securityId }) {
      edges {
        node {
          name
          details {
            ... on SecurityETFDetails {
              __typename
              holdings {
                asset {
                  name
                  symbol
                }
                percentage
              }
            }
            ... on SecurityMutualFundDetails {
              __typename
              holdings {
                asset {
                  name
                  symbol
                }
                percentage
              }
            }
            ... on SecurityMutualFundDetails {
              holdings {
                asset {
                  name
                  symbol
                }
                percentage
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_MF_SECURITY_BUY_SCREEN_INFORMATION = gql`
  query GetMFSecurityBuyScreenInformation($securityID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          id
          details {
            __typename
            ... on SecurityMutualFundDetails {
              additionalInvestmentMinimum
              distributionServiceFee
              expenseRatioGross
              expenseRatioNet
              initialInvestmentMinimum
              loadPct
              loadType
              transactionFee
            }
          }
        }
      }
    }
  }
`;

export const GET_MUTUAL_FUND_DETAILS = gql`
  query GetMutualFundDetails($securityId: ID!) {
    securityAssets(where: { id: $securityId }) {
      edges {
        node {
          id
          details {
            __typename
            ... on SecurityMutualFundDetails {
              additionalInvestmentMinimum
              distributionServiceFee
              expenseRatioGross
              expenseRatioNet
              initialInvestmentMinimum
              loadPct
              loadType
              transactionFee
              additionalGroupRetirementInvestmentMinimum
              additionalInvestmentMinimum
              additionalIraInvestmentMinimum
              annualizedStdDev
              contractualExpenseWaiver
              distributionServiceFee
              expenseRatioGross
              expenseRatioNet
              feeClass
              fundCategory
              fundCompany
              fundInception
              initialGroupRetirementInvestmentMinimum
              initialInvestmentMinimum
              initialIraInvestmentMinimum
              loadPct
              loadType
              mutualFundType
              netAssetValue
              netAssetValueChange
              portfolioNetAssetValue
              rSquared
              shareClassNetAssetValue
              sharpeRatio
              transactionFee
              turnoverRate
              twelveMonthHighValue
              twelveMonthLowValue
              yearToDateReturns
              yield30Day
            }
          }
        }
      }
    }
  }
`;

export const GET_MUTUAL_FUND_PERFORMANCE = gql`
  query GetMutualFundPerformance($securityId: ID!) {
    securityAssets(where: { id: $securityId }) {
      edges {
        node {
          id
          details {
            __typename
            ... on SecurityMutualFundDetails {
              oneYearTotalReturn
              threeYearTotalReturn
              fiveYearTotalReturn
              tenYearTotalReturn
            }
          }
        }
      }
    }
  }
`;
