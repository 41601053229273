import React from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { SheetContent, SheetFooter, SheetHeader, SheetTitle } from '@/components/common/Sheet';
import { Button } from '@/components/common/Button';
import { useSidePanel } from '@/context/SidePanelContext';
import { ErrorSidePanel } from '@/components/common/SidePanels/ErrorSidePanel/ErrorSidePanel';
import { yupResolver } from '@hookform/resolvers/yup';
import { YES_OR_NO } from '@fintronners/react-api/src/utils/formUtils/schemas/types';
import useUserDetails from '@fintronners/internal-core/src/user/hooks/useUserDetails';
import { getSimpleEnumKeysAsArray } from '@fintronners/react-utils/src';
import { WebBodyText14 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import FormTextField from '@/components/common/Forms/fields/FormTextField';
import Image from 'next/image';
import { DeleteIcon } from '@/assets/icons';
import { PoliticallyExposedFormData, PoliticallyExposedSchema } from './schema';
import { V1UpdateProfileRequest } from '@fintronners/react-api/src';
import { InvestmentQuestionsRadio } from '../InvestmentQuestionsRadio';
import useProfileService from '@/hooks/api/useProfileService';
import PendingChangesNotice from '@/components/notices/PendingChangesNotice';
import { useSidepanelError } from '@/hooks/utils/useSidepanelError';

const displayStrings = {
  title: 'Are you politically exposed?',
  description: `If you aren't sure, it likely doesn't apply.`,
  answer: 'I am politically exposed',
  controlPersonOptions: {
    yes: 'Yes',
    no: 'No',
  },
  politicalOrganizationNameTitle:
    'Please enter the political organization with which you are affiliated.',
  politicalOrganizationLabel: 'Name of political organization',
  familyMembersFullNameTitle: 'Please enter the names of your immediate family members.',
  familyMemberPlaceholder: 'Full name of family member',
  addFamilyMember: '+ Add A Family Member',
  isPoliticallyExposedDescriptionTitle: 'Political Exposure',
  isPoliticallyExposedDescription:
    'A politically exposed person (PEP) is an individual with a high profile political role, or who has been entrusted with a prominent public function. PEPs represent a higher risk for financial institutions and Designed Non-Financial Businesses and professionals (DNFBS).',
  save: 'Save',
  back: 'Back',
};

type PoliticallyExposedFormProps = {
  isPending: boolean;
};

export const PoliticallyExposedForm: React.FC<PoliticallyExposedFormProps> = ({ isPending }) => {
  const userApiService = useProfileService();
  const { userDetails, refetch } = useUserDetails();

  const { goBack, setShowBackLink, setShowCloseButton } = useSidePanel();

  const { hasError, handleError, onErrorSidePanelBack } = useSidepanelError(
    setShowBackLink,
    setShowCloseButton,
  );

  const politicallyExposedDetails = userDetails?.profile?.politicallyExposedDetails;

  const {
    control,
    formState: { isValid, isDirty },
    watch,
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(PoliticallyExposedSchema),
    values: {
      isPoliticallyExposed: politicallyExposedDetails?.isPoliticallyExposed
        ? YES_OR_NO.YES
        : YES_OR_NO.NO,
      familyMembers: politicallyExposedDetails?.familyMembers
        ? politicallyExposedDetails?.familyMembers.map((familyMember) => ({
            value: familyMember,
          }))
        : [{ value: '' }],
      organizationName: politicallyExposedDetails?.organizationName ?? '',
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'familyMembers',
  });

  const isPoliticallyExposed = watch('isPoliticallyExposed');

  const onUpdateControlPerson = async (data: PoliticallyExposedFormData) => {
    let request: V1UpdateProfileRequest = {
      profileParameters: {
        politicallyExposedDetails: {
          isPoliticallyExposed: data.isPoliticallyExposed === YES_OR_NO.YES,
          familyMembers: data.familyMembers?.map((member) => member.value.trim()),
          organizationName: data.organizationName,
        },
      },
    };
    if (data.isPoliticallyExposed === YES_OR_NO.NO) {
      request = {
        profileParameters: {
          politicallyExposedDetails: {
            isPoliticallyExposed: false,
            familyMembers: undefined,
            organizationName: '',
          },
        },
      };
    }

    try {
      await userApiService.userServiceUpdateProfile(request);

      await refetch();
      goBack();
    } catch (error) {
      handleError();
    }
  };

  if (hasError) return <ErrorSidePanel onBack={onErrorSidePanelBack} />;

  return (
    <SheetContent>
      <form className="flex h-full flex-col" onSubmit={handleSubmit(onUpdateControlPerson)}>
        <SheetHeader>
          <SheetTitle>{displayStrings.title}</SheetTitle>
        </SheetHeader>
        <div className="flex grow flex-col gap-7">
          <WebBodyText14.Regular className="text-grey72">
            {displayStrings.description}
          </WebBodyText14.Regular>

          {isPending && <PendingChangesNotice />}

          <InvestmentQuestionsRadio
            title={displayStrings.answer}
            name="isPoliticallyExposed"
            dialogTitle={displayStrings.isPoliticallyExposedDescriptionTitle}
            dialogContent={displayStrings.isPoliticallyExposedDescription}
            items={getSimpleEnumKeysAsArray(
              YES_OR_NO,
              displayStrings.controlPersonOptions as Record<string, string>,
            )}
            control={control}
          />

          {isPoliticallyExposed === YES_OR_NO.YES && (
            <>
              <Controller
                control={control}
                name="organizationName"
                render={({ field, fieldState }) => (
                  <FormTextField
                    {...field}
                    fieldError={fieldState.error}
                    placeholder={displayStrings.politicalOrganizationLabel}
                    label={displayStrings.politicalOrganizationNameTitle}
                  />
                )}
              />

              <div className="flex flex-col gap-2">
                <WebBodyText14.Regular>
                  {displayStrings.familyMembersFullNameTitle}
                </WebBodyText14.Regular>

                <ul className="flex flex-col gap-4">
                  {fields.map((item, index) => (
                    <li key={item.id}>
                      <div className="flex justify-between gap-2">
                        <div className="w-full">
                          <Controller
                            control={control}
                            name={`familyMembers.${index}.value`}
                            render={({ field, fieldState }) => (
                              <FormTextField
                                {...field}
                                fieldError={fieldState.error}
                                placeholder={displayStrings.familyMemberPlaceholder}
                                rightComponent={
                                  index > 0 && (
                                    <button type="button" onClick={() => remove(index)}>
                                      <Image
                                        alt="Delete value icon"
                                        width={16}
                                        height={16}
                                        src={DeleteIcon.src}
                                      />
                                    </button>
                                  )
                                }
                              />
                            )}
                          />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>

                <Button
                  className="mt-2 w-[190px]"
                  variant="ghost"
                  onClick={() => append({ value: '' })}
                >
                  {displayStrings.addFamilyMember}
                </Button>
              </div>
            </>
          )}
        </div>

        <SheetFooter>
          <div className="mt-8 flex w-full flex-col">
            <Button
              variant="primary"
              type="submit"
              className="mb-4"
              disabled={!isDirty || !isValid}
            >
              {displayStrings.save}
            </Button>
            <Button variant="secondary" type="button" onClick={goBack}>
              {displayStrings.back}
            </Button>
          </div>
        </SheetFooter>
      </form>
    </SheetContent>
  );
};
