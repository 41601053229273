import { BaseAPI } from '@fintronners/react-api/src/tsoai/base';
import { Configuration } from '@fintronners/react-api/src/tsoai';
import { AxiosInstance } from 'axios';

// Inversion of control for the environment variables based on host to map APIs to their respective URLs.
// This is simpler than creating a separate deployment for different environment variables.  If a match
// is not found, it will default to the environment variables provided
// todo mike note - we should refactor these envs out into our config file
export const envs: { [key: string]: { [key: string]: string } } = {
  'localhost:3000': {
    // AUTH_API_URL: 'https://auth.ftron.dev.fintron-test.com',
    // NEW_API_URL: 'https://apis.ftron.dev.fintron-test.com',
    // GRAPHQL_API_URL: 'https://graphql.ftron.dev.fintron-test.com/query',
    AUTH_API_URL: 'https://auth.alight.uat.api.fintron.co',
    NEW_API_URL: 'https://apis.alight.uat.api.fintron.co',
    GRAPHQL_API_URL: 'https://graphql.alight.uat.api.fintron.co/query',
    BACK_HOME_URL:
      'https://uat-fed.nextcapital.com/idp/startSSO.ping?PartnerSpId=saml.qc.alight.com&TargetResource=https://sso.alight.com/ira_portal',
  },
  'fintron-web.vercel.app': {
    AUTH_API_URL: 'https://auth.alight.uat.api.fintron.co',
    NEW_API_URL: 'https://apis.alight.uat.api.fintron.co',
    GRAPHQL_API_URL: 'https://graphql.alight.uat.api.fintron.co/query',
    BACK_HOME_URL:
      'https://uat-fed.nextcapital.com/idp/startSSO.ping?PartnerSpId=saml.qc.alight.com&TargetResource=https://sso.alight.com/ira_portal',
  },
  'alight.fintron.co': {
    AUTH_API_URL: 'https://auth.alight.api.fintron.co',
    NEW_API_URL: 'https://apis.alight.api.fintron.co',
    GRAPHQL_API_URL: 'https://graphql.alight.api.fintron.co/query',
    BACK_HOME_URL:
      'https://prod-fed.nextcapital.com/idp/startSSO.ping?PartnerSpId=saml.alight.com&TargetResource=https://sso.alight.com/ira_portal',
  },
  'dev-fintron-web.vercel.app': {
    AUTH_API_URL: 'https://auth.ftron.dev.fintron-test.com',
    NEW_API_URL: 'https://apis.ftron.dev.fintron-test.com',
    GRAPHQL_API_URL: 'https://graphql.ftron.dev.fintron-test.com/query',
    BACK_HOME_URL:
      'https://uat-fed.nextcapital.com/idp/startSSO.ping?PartnerSpId=saml.qc.alight.com&TargetResource=https://sso.alight.com/ira_portal',
  },
  'uat-fintron-web.vercel.app': {
    AUTH_API_URL: 'https://auth.ftron.uat.api.fintron.co',
    NEW_API_URL: 'https://apis.ftron.uat.api.fintron.co',
    GRAPHQL_API_URL: 'https://graphql.ftron.uat.api.fintron.co/query',
    BACK_HOME_URL:
      'https://uat-fed.nextcapital.com/idp/startSSO.ping?PartnerSpId=saml.qc.alight.com&TargetResource=https://sso.alight.com/ira_portal',
  },
  'ftron-uat-fintron-web.vercel.app': {
    AUTH_API_URL: 'https://auth.ftron.uat.api.fintron.co',
    NEW_API_URL: 'https://apis.ftron.uat.api.fintron.co',
    GRAPHQL_API_URL: 'https://graphql.ftron.uat.api.fintron.co/query',
    BACK_HOME_URL:
      'https://uat-fed.nextcapital.com/idp/startSSO.ping?PartnerSpId=saml.qc.alight.com&TargetResource=https://sso.alight.com/ira_portal',
  },
  'republic-uat-fintron-web.vercel.app': {
    AUTH_API_URL: 'https://auth.republic.uat.api.fintron.co',
    NEW_API_URL: 'https://apis.republic.uat.api.fintron.co',
    GRAPHQL_API_URL: 'https://graphql.republic.uat.api.fintron.co/query',
    BACK_HOME_URL:
      'https://uat-fed.nextcapital.com/idp/startSSO.ping?PartnerSpId=saml.qc.alight.com&TargetResource=https://sso.alight.com/ira_portal',
  },
  'franklin-uat-fintron-web.vercel.app': {
    AUTH_API_URL: 'https://auth.franklin.uat.api.fintron.co',
    NEW_API_URL: 'https://apis.franklin.uat.api.fintron.co',
    GRAPHQL_API_URL: 'https://graphql.franklin.uat.api.fintron.co/query',
    BACK_HOME_URL:
      'https://uat-fed.nextcapital.com/idp/startSSO.ping?PartnerSpId=saml.qc.alight.com&TargetResource=https://sso.alight.com/ira_portal',
  },
  'prod-fintron-web.vercel.app': {
    AUTH_API_URL: 'https://auth.ftron.api.fintron.co',
    NEW_API_URL: 'https://apis.ftron.api.fintron.co',
    GRAPHQL_API_URL: 'https://graphql.ftron.api.fintron.co/query',
    BACK_HOME_URL:
      'https://uat-fed.nextcapital.com/idp/startSSO.ping?PartnerSpId=saml.qc.alight.com&TargetResource=https://sso.alight.com/ira_portal',
  },
  'demo.fintron.co': {
    AUTH_API_URL: 'https://auth.ftron.uat.api.fintron.co',
    NEW_API_URL: 'https://apis.ftron.uat.api.fintron.co',
    GRAPHQL_API_URL: 'https://graphql.ftron.uat.api.fintron.co/query',
  },
  'fintron.dev.dev.fintron.app': {
    AUTH_API_URL: 'https://auth.ftron.dev.fintron-test.com',
    NEW_API_URL: 'https://apis.ftron.dev.fintron-test.com',
    GRAPHQL_API_URL: 'https://graphql.ftron.dev.fintron-test.com/query',
  },
};

export const getAuthUrl = (host?: string) => {
  // remove any pr-###- prefix from the hostname as in pr-###-fintron-web.vercel.app
  const baseHostUrl = host?.replace(/^pr-\d+-/, '');
  const hostAuthApiUrl = baseHostUrl && envs[baseHostUrl]?.AUTH_API_URL;
  const needSetUrl = 'http://set-env-auth-api-url:3000';
  const result = hostAuthApiUrl || process.env.AUTH_API_URL || needSetUrl;

  if (hostAuthApiUrl) {
    console.debug(`Host-based Auth URL: ${result} (lookup: ${baseHostUrl})`);
  } else if (result === process.env.AUTH_API_URL) {
    console.debug(`Auth URL: ${result} (AUTH_API_URL)`);
  } else if (result === needSetUrl) {
    console.debug(`Auth URL can be set with AUTH_API_URL environment variable.`);
  }

  return result;
};

export const getGraphQLURL = (host?: string) => {
  // remove any pr-###- prefix from the hostname as in pr-###-fintron-web.vercel.app
  const baseHostUrl = host?.replace(/^pr-\d+-/, '');
  const hostGraphqlApiUrl = baseHostUrl && envs[baseHostUrl]?.GRAPHQL_API_URL;
  const needSetUrl = 'http://set-env-graphql-api-url:3000/query';
  const result = hostGraphqlApiUrl || process.env.GRAPHQL_API_URL || needSetUrl;
  if (hostGraphqlApiUrl) {
    console.debug(`Host-based GraphQL URL: ${result} (lookup: ${baseHostUrl})`);
  } else if (result === process.env.GRAPHQL_API_URL) {
    console.debug(`GraphQL URL: ${result} (GRAPHQL_API_URL)`);
  } else if (result === needSetUrl) {
    console.debug(`GraphQL URL can be set with GRAPHQL_API_URL environment variable.`);
  }

  return result;
};

export const getNewApiUrl = (host?: string) => {
  // remove any pr-###- prefix from the hostname as in pr-###-fintron-web.vercel.app
  const baseHostUrl = host?.replace(/^pr-\d+-/, '');
  const hostNewApiApiUrl = baseHostUrl && envs[baseHostUrl]?.NEW_API_URL;
  const needSetUrl = 'http://set-env-graphql-api-url:3000/query';
  const result = hostNewApiApiUrl || process.env.NEW_API_URL || needSetUrl;
  if (hostNewApiApiUrl) {
    console.debug(`Host-based New API URL: ${result} (lookup: ${baseHostUrl})`);
  } else if (result === process.env.NEW_API_URL) {
    console.debug(`New API URL: ${result} (NEW_API_URL)`);
  } else if (result === needSetUrl) {
    console.debug(`New API URL can be set with NEW_API_URL environment variable.`);
  }

  return result;
};

export const getBackHomeUrl = (host?: string) => {
  const baseHostUrl = host?.replace(/^pr-\d+-/, '');
  const hostBackHomeUrl = baseHostUrl && envs[baseHostUrl]?.BACK_HOME_URL;
  const defaultBackHomeUrl =
    'https://uat-fed.nextcapital.com/idp/startSSO.ping?PartnerSpId=saml.qc.alight.com&TargetResource=https://sso.alight.com/ira_portal';
  return hostBackHomeUrl || process.env.BACK_HOME_URL || defaultBackHomeUrl;
};

// Singleton class for the gRPC client.  Modified from GRPCAPI to expect a baseUrl when retrieving a service.
class RuntimeEnvGRPCAPI {
  axiosInstance: AxiosInstance | undefined;

  private services: Record<string, BaseAPI> = {};

  setAxiosInstance(axios: AxiosInstance) {
    this.axiosInstance = axios;
  }

  /**
   * Gets a service instance and stores it.
   */
  getService<T extends BaseAPI>(
    ServiceApi: new (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) => T,
    baseUrl: string,
  ): T {
    const key = `${ServiceApi.name}-${baseUrl}`;

    if (!this.services[key]) {
      console.debug(`Creating new service instance for ${key} with base URL: ${baseUrl}`);
      this.services[key] = new ServiceApi(new Configuration(), baseUrl, this.axiosInstance);
    }

    return this.services[key] as T;
  }

  /**
   * Gets a V1 service instance and stores it.
   */
  getV1Service<T extends BaseAPI>(
    ServiceApi: new (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) => T,
    configuration: Configuration = new Configuration(),
  ): T {
    const key = `${ServiceApi.name}-${configuration.basePath}`;

    if (!this.services[key]) {
      console.debug(
        `Creating new service instance for ${key} with base URL: ${configuration.basePath}`,
      );
      this.services[key] = new ServiceApi(
        configuration,
        configuration.basePath,
        this.axiosInstance,
      );
    }

    return this.services[key] as T;
  }

  /**
   * Gets a auth service instance and stores it.
   */
  getAuthService<T extends BaseAPI>(
    ServiceApi: new (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) => T,
    baseUrl: string,
    accessToken?: string,
  ): T {
    const key = `${ServiceApi.name}-${baseUrl}`;

    if (!this.services[key]) {
      console.debug(`Creating new auth service instance for ${key} with base URL: ${baseUrl}`);
      this.services[key] = new ServiceApi(
        new Configuration({
          ...(accessToken && {
            baseOptions: {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            },
          }),
        }),
        baseUrl,
        this.axiosInstance,
      );
    }

    return this.services[key] as T;
  }
}

export default new RuntimeEnvGRPCAPI();
