import FinTronConfig from '@fintronners/react-config/src/Config/FinTronConfig';

export enum AvailableCountries {
  US = 'US',
  BR = 'BR',
}

export enum AvailableAlpha3Countries {
  USA = 'USA',
  BRA = 'BRA',
}

export type Country = {
  name: string;
  flag: string;
  alpha2Code: AvailableCountries;
  alpha3Code?: AvailableAlpha3Countries;
  countryCode: string;
  areaCodes: number[];
};

export const supportedCountries: Record<AvailableCountries, Country> = {
  [AvailableCountries.US]: {
    name: 'United States',
    alpha2Code: AvailableCountries.US,
    alpha3Code: AvailableAlpha3Countries.USA,
    flag: '🇺🇸',
    countryCode: '+1',
    areaCodes: [
      201, 202, 203, 205, 206, 207, 208, 209, 210, 212, 213, 214, 215, 216, 217, 218, 219, 220, 224,
      225, 228, 229, 231, 234, 239, 240, 248, 251, 252, 253, 254, 256, 260, 262, 267, 269, 270, 272,
      276, 281, 301, 302, 303, 304, 305, 307, 308, 309, 310, 312, 313, 314, 315, 316, 317, 318, 319,
      320, 321, 323, 325, 330, 331, 334, 336, 337, 339, 346, 347, 351, 352, 360, 361, 364, 380, 385,
      386, 401, 402, 404, 405, 406, 407, 408, 409, 410, 412, 413, 414, 415, 417, 419, 423, 424, 425,
      430, 432, 434, 435, 440, 442, 443, 458, 463, 464, 469, 470, 475, 478, 479, 480, 484, 501, 502,
      503, 504, 505, 507, 508, 509, 510, 512, 513, 515, 516, 517, 518, 520, 530, 531, 539, 540, 541,
      551, 559, 561, 562, 563, 564, 567, 570, 571, 573, 574, 575, 580, 585, 586, 601, 602, 603, 605,
      606, 607, 608, 609, 610, 612, 614, 615, 616, 617, 618, 619, 620, 623, 626, 628, 629, 630, 631,
      636, 641, 646, 650, 651, 657, 659, 660, 661, 662, 667, 669, 678, 680, 681, 682, 689, 701, 702,
      703, 704, 706, 707, 708, 712, 713, 714, 715, 716, 717, 718, 719, 720, 724, 725, 727, 731, 732,
      734, 737, 740, 743, 747, 754, 757, 760, 762, 763, 765, 769, 770, 772, 773, 774, 775, 779, 781,
      785, 786, 801, 802, 803, 804, 805, 806, 808, 810, 812, 813, 814, 815, 816, 817, 818, 828, 830,
      831, 832, 835, 843, 845, 847, 848, 850, 854, 856, 857, 858, 859, 860, 862, 863, 864, 865, 870,
      872, 878, 901, 903, 904, 906, 907, 908, 909, 910, 912, 913, 914, 915, 916, 917, 918, 919, 920,
      925, 927, 928, 929, 930, 931, 935, 936, 937, 940, 941, 947, 949, 951, 952, 954, 956, 959, 970,
      971, 972, 973, 978, 979, 980, 984, 985, 989,
    ],
  },
  [AvailableCountries.BR]: {
    name: 'Brazil',
    alpha2Code: AvailableCountries.BR,
    alpha3Code: AvailableAlpha3Countries.BRA,
    flag: '🇧🇷',
    countryCode: '+55',
    areaCodes: [
      11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 24, 27, 28, 31, 32, 33, 34, 35, 37, 38, 41, 42,
      43, 44, 45, 46, 47, 48, 49, 51, 53, 54, 55, 61, 62, 63, 64, 65, 66, 67, 68, 69, 71, 73, 74,
      75, 77, 79, 81, 82, 83, 84, 85, 86, 87, 88, 89, 91, 92, 93, 94, 95, 96, 97, 98, 99,
    ],
  },
};

export const supportedCountriesArray = Object.values(supportedCountries);

export const formatPhoneNumber = (phone: string, country?: AvailableCountries): string => {
  const cleanedPhone = phone.replace(/[^\d]/g, '');

  if (country === AvailableCountries.BR) {
    if (!cleanedPhone.startsWith('55') || cleanedPhone.length < 12 || cleanedPhone.length > 13) {
      return phone;
    }

    const countryCode = '+55';
    const areaCode = cleanedPhone.slice(2, 4);
    const firstPart =
      cleanedPhone.length === 13 ? cleanedPhone.slice(4, 9) : cleanedPhone.slice(4, 8);
    const secondPart = cleanedPhone.length === 13 ? cleanedPhone.slice(9) : cleanedPhone.slice(8);

    return `${countryCode} (${areaCode}) ${firstPart}-${secondPart}`;
  }

  if (country === AvailableCountries.US) {
    if (!cleanedPhone.startsWith('1') || cleanedPhone.length !== 11) {
      throw new Error('Invalid US phone number');
    }

    const countryCode = '+1';
    const areaCode = cleanedPhone.slice(1, 4);
    const firstPart = cleanedPhone.slice(4, 7);
    const secondPart = cleanedPhone.slice(7);

    return `${countryCode} (${areaCode}) ${firstPart}-${secondPart}`;
  }

  throw new Error('Unsupported country');
};

export const getCountryByTenant = () => {
  if (FinTronConfig.tenant === 'banco-bradesco') {
    return supportedCountries.BR;
  }

  return supportedCountries.US;
};

export const getRandomUSAreaCode = () => {
  const usAreaCodes = supportedCountriesArray.find(
    (country) => country.alpha2Code === AvailableCountries.US,
  )?.areaCodes;

  if (!usAreaCodes) {
    return;
  }

  return usAreaCodes[Math.floor(Math.random() * usAreaCodes.length)];
};
