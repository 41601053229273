import React, { useEffect, useState, useRef } from 'react';
import { useRouter } from 'next/router';
import { useSidePanel } from '@/context/SidePanelContext';
import { getShouldByPassSupplementalQuestions } from '@/utils/onboarding';
import type { ServerSideProps } from '@/ssr/common';

import {
  shouldDisplaySupplementalQuestions,
  getInitialStep,
} from '@/components/common/SidePanels/SupplementalQuestionsSidePanel/utils';
import useUserDetails from '@fintronners/internal-core/src/user/hooks/useUserDetails';
import getOnboardingData from '@/api/getOnboardingData';

type OnboardingProviderProps = React.PropsWithChildren<ServerSideProps>;

const ERROR_MESSAGES = {
  onboardingData:
    '"onboardingData" is undefined. Make sure it is exported from getServerSideProps.',
};

const EXCLUDED_PATHNAMES = ['/', '/login', '/logout'];
const SUPPLEMENTAL_QUESTIONS_EXCLUDED_PATHNAMES = EXCLUDED_PATHNAMES;

/**
 * This Provider is used to show and handle Onboarding logic that will block the page.
 * Use one Effect per Onboarding flow.
 */
const OnboardingProvider: React.FC<OnboardingProviderProps> = ({
  children,
  onboardingData: ssrOnboardingData,
  userDetails: ssrUserDetails,
  tenant,
}) => {
  const { pathname } = useRouter();
  const { openSidePanel } = useSidePanel();

  const { data, userDetails, refetch: refetchUserDetails } = useUserDetails('no-cache');

  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(0);
  const [hasOpenedPanel, setHasOpenedPanel] = useState(false);

  const supplementalQuestionsRef = useRef<{ responsesSaved: boolean }>({
    responsesSaved: false,
  });

  const fetchAndValidateData = async () => {
    if (
      tenant !== 'Alight' ||
      SUPPLEMENTAL_QUESTIONS_EXCLUDED_PATHNAMES.includes(pathname) ||
      hasOpenedPanel ||
      supplementalQuestionsRef.current.responsesSaved
    ) {
      setIsLoading(false);
      return;
    }

    const shouldBypassSupplementalQuestions = getShouldByPassSupplementalQuestions();

    if (shouldBypassSupplementalQuestions) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    const onboardingData = ssrOnboardingData ?? getOnboardingData(data);

    if (!onboardingData) {
      console.error(ERROR_MESSAGES.onboardingData);
      setIsLoading(false);
      return;
    }

    const user = ssrUserDetails?.users.edges?.[0]?.node || userDetails;

    if (user) {
      const displaySupplementalQuestions = shouldDisplaySupplementalQuestions(onboardingData);
      if (displaySupplementalQuestions) {
        const initialStep = getInitialStep(onboardingData);
        openSidePanel('SupplementalQuestions', {
          data: onboardingData,
          initialStep,
          refetchUserDetails,
          supplementalQuestionsRef,
        });
        setHasOpenedPanel(true);
      }
      setIsLoading(false);
    } else if (timer < 60) {
      setTimer((prev) => prev + 5);
      await refetchUserDetails();
    } else {
      console.warn('Timeout reached: user details could not be fetched.');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!hasOpenedPanel && !supplementalQuestionsRef.current.responsesSaved) {
      fetchAndValidateData();
    }
  }, [
    timer,
    pathname,
    data,
    userDetails,
    ssrOnboardingData,
    ssrUserDetails,
    tenant,
    hasOpenedPanel,
  ]);

  // @ts-ignore - This is a hack to keep the timer running while the user is on the page
  useEffect(() => {
    if (!hasOpenedPanel && timer < 60 && !supplementalQuestionsRef.current.responsesSaved) {
      const timeout = setTimeout(() => {
        setTimer((prev) => prev + 5);
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, [timer, hasOpenedPanel]);

  return (
    <>
      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="w-12 h-12 border-4 border-t-transparent border-white rounded-full animate-spin" />
        </div>
      )}
      {children}
    </>
  );
};

export default OnboardingProvider;
