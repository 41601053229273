import { useRouter } from 'next/router';
import { WebBodyText14, WebButton16 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { thousandFormatNumbroCurrency } from '@fintronners/react-utils/src/numberUtilsTSX';

import { Alert } from '@/components/common/Alert/Alert';
import { Button } from '@/components/common/Button';
import { TransferCurrencyInput } from '../TransferCurrencyInput';
import { useFormContext } from 'react-hook-form';
import { useCallback } from 'react';
import RmdWarning from '@/components/features/rmd/RmdWarning/RmdWarning';
import useUserDetails from '@fintronners/internal-core/src/user/hooks/useUserDetails';
import {
  getShowRmdWarning,
  getShowAgePenalties,
  getShowBankAccountAgeRule,
} from '@fintronners/react-utils/src/withdrawalUtils';
import { UseExternalAccount } from '@fintronners/react-widgets/src/hooks/useExternalAccounts';

const displayStrings = {
  withdrawAll: `Withdraw All`,
  withdrawAllAvailable: (availableAmount: string) =>
    `Cash available to withdraw: ${availableAmount}`,
  agePenalties: 'Withdrawals before the age of 59½ may be subject to 10% penalty.',
  bankAccountAgeRule:
    'You recently linked a new bank account. You are required to wait 7 days before making a withdrawal.',
};

type WithdrawalFormType = {
  account: UseExternalAccount | null | undefined;
};

export const WithdrawalForm = ({ account }: WithdrawalFormType) => {
  const { getValues, setValue } = useFormContext();
  const { availableWithdrawalAmount } = getValues();

  const router = useRouter();

  const { type } = router.query;
  const isIRAAccount = ['trad-ira', 'roth-ira'].includes(type as string);

  const { userDetails } = useUserDetails();
  const dateOfBirth = userDetails?.profile?.dateOfBirth;

  const showRmdWarning = isIRAAccount && getShowRmdWarning(dateOfBirth);
  const showAgePenalties = isIRAAccount && getShowAgePenalties(dateOfBirth);
  const showBankAccountAgeWarning = Boolean(
    isIRAAccount && account && getShowBankAccountAgeRule(account.createTime),
  );

  const handleSetWithdrawalAmount = useCallback(
    () => setValue('amount', availableWithdrawalAmount),
    [availableWithdrawalAmount],
  );

  return (
    <>
      <TransferCurrencyInput disabled={showBankAccountAgeWarning} />
      <WebBodyText14.Regular className="text-grey55">
        {displayStrings.withdrawAllAvailable(
          thousandFormatNumbroCurrency(showBankAccountAgeWarning ? 0 : availableWithdrawalAmount),
        )}
      </WebBodyText14.Regular>
      {showRmdWarning && <RmdWarning />}
      {showAgePenalties && !showBankAccountAgeWarning && (
        <Alert variant="warning">
          <WebBodyText14.Regular className="text-grey72">
            {displayStrings.agePenalties}
          </WebBodyText14.Regular>
        </Alert>
      )}
      {account && showBankAccountAgeWarning && (
        <Alert variant="danger">
          <WebBodyText14.Regular className="text-grey72">
            {displayStrings.bankAccountAgeRule}
          </WebBodyText14.Regular>
        </Alert>
      )}
      <Button
        variant="secondary"
        onClick={handleSetWithdrawalAmount}
        disabled={showBankAccountAgeWarning}
      >
        <WebButton16.Bold>{displayStrings.withdrawAll}</WebButton16.Bold>
      </Button>
    </>
  );
};
