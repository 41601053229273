import * as yup from 'yup';
import {
  getAscendStringRequiredSchema,
  getOneOfEnumSchema,
} from '@fintronners/react-api/src/utils/formUtils/schemas/commonSchemas';
import { YES_OR_NO } from '@fintronners/react-api/src/utils/formUtils/schemas/types';
import { getImageFileSchema } from '@fintronners/react-api/src/utils/formUtils/schemas/commonSchemas';
import i18n from '@fintronners/react-language/src/Lang';

export const finraStockExchangeMemberFormSchema = yup.object({
  isAffiliatedExchangeOrFINRA: getOneOfEnumSchema(YES_OR_NO).required(),
  firmName: getAscendStringRequiredSchema(i18n.t('onboardingIsFinra.form.title')),
  letter: getImageFileSchema('isAffiliatedExchangeOrFINRA', YES_OR_NO.YES),
});

export type FinraStockExchangeMemberFormData = yup.InferType<
  typeof finraStockExchangeMemberFormSchema
>;
