import i18n from '@fintronners/react-language/src/Lang';
import { OrderDirection, SecurityAssetOrderField } from '@fintronners/react-api/src';
import {
  ETF_SECURITY_TYPES,
  MUTUAL_FUND_SECURITY_TYPES,
  SECURITY_CATEGORY,
  STOCK_SECURITY_TYPES,
} from './constants';
import { SecurityCategory, SortSelectOptionValue } from './types';

export const getSecurityTypesByCategory = (securityCategory: SecurityCategory) => {
  if (securityCategory === SECURITY_CATEGORY.STOCK) {
    return STOCK_SECURITY_TYPES;
  }

  if (securityCategory === SECURITY_CATEGORY.ETF) {
    return ETF_SECURITY_TYPES;
  }

  if (securityCategory === SECURITY_CATEGORY.MUTUAL_FUND) {
    return MUTUAL_FUND_SECURITY_TYPES;
  }

  return undefined;
};

export const parseSortOption = (sortOption: SortSelectOptionValue | undefined) => {
  return (sortOption?.split('-') ?? []) as [SecurityAssetOrderField, OrderDirection];
};

export const getSecurityCategoryTitle = (category: SecurityCategory): string => {
  if (category === SECURITY_CATEGORY.STOCK) return i18n.t('common.securityCategory.stocks');
  if (category === SECURITY_CATEGORY.ETF) return i18n.t('common.securityCategory.etfs');
  if (category === SECURITY_CATEGORY.MUTUAL_FUND)
    return i18n.t('common.securityCategory.mutualFunds');
  return '';
};
