import {
  V1UserEmploymentStatus,
  V1UserMaritalStatus,
  V1UserNetWorth,
} from '@fintronners/react-api';
import {
  getAscendStringRequiredSchema,
  getOneOfEnumSchema,
} from '@fintronners/react-api/src/utils/formUtils/schemas/commonSchemas';
import * as yup from 'yup';
import i18n from '@fintronners/react-language/src/Lang';
import { NonDiscretionaryAccountKycUserAnnualIncome } from '@fintronners/react-api/src';

export const personalFormSchema = yup.object({
  maritalStatus: getOneOfEnumSchema(V1UserMaritalStatus),
  employmentStatus: getOneOfEnumSchema(V1UserEmploymentStatus),
  annualIncome: getOneOfEnumSchema(NonDiscretionaryAccountKycUserAnnualIncome),
  netWorth: getOneOfEnumSchema(V1UserNetWorth),
  employerName: yup.string().when('employmentStatus', {
    is: V1UserEmploymentStatus.Employed,
    then: () => getAscendStringRequiredSchema(i18n.t('onboardingStocks.secondSurvey.employerName')),
  }),
  jobPosition: yup.string().when('employmentStatus', {
    is: V1UserEmploymentStatus.Employed,
    then: () => getAscendStringRequiredSchema(i18n.t('onboardingStocks.secondSurvey.position')),
  }),
});

export type PersonalFormData = yup.InferType<typeof personalFormSchema>;
