import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import Image from 'next/image';
import { SearchIcon } from '@/assets/icons';
import { Input } from '../Input/Input';
import { useRouter } from 'next/router';
import cn from '@/utils/tailwind';
import type { ClassValue } from 'clsx';

export interface SearchProps {
  placeholder?: string;
  initialValue?: string;
  onSearch: (value: string) => void;
  className?: ClassValue;
}

const SearchInput = ({ placeholder, onSearch, initialValue = '', className }: SearchProps) => {
  const [displayValue, setDisplayValue] = useState<string>(initialValue);
  const router = useRouter();

  const debouncedSearch = useCallback(
    debounce((value) => {
      onSearch(value);
    }, 200),
    [onSearch],
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setDisplayValue(value);
      debouncedSearch(value);
    },
    [debouncedSearch],
  );

  useEffect(() => {
    const { searchParam } = router.query;

    if (searchParam && searchParam?.length > 0) {
      setDisplayValue(String(searchParam));
      debouncedSearch(searchParam);
    }
  }, []);

  useEffect(() => {
    setDisplayValue(initialValue);
  }, [initialValue]);

  return (
    <div className={cn('relative', className)}>
      <Image
        className="absolute bottom-[9px] left-3"
        alt="Search icon"
        width={15}
        height={15}
        src={SearchIcon.src}
      />
      <Input
        className="pl-9 pr-2 font-normal"
        type="text"
        value={displayValue}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export default SearchInput;
