import {
  RecurringRuleRecurringRuleInterval,
  RoboGoalParametersRoboInfusionFreq,
} from '@fintronners/react-api/src/graphql/types/graphql';
import { V1RecurringRuleInterval, V1RoboInfusionFreq } from '../tsoai';
import i18next from '@fintronners/react-language/src/Lang';

type IntervalGroups = {
  [key in
    | V1RoboInfusionFreq
    | V1RecurringRuleInterval
    | RoboGoalParametersRoboInfusionFreq
    | RecurringRuleRecurringRuleInterval]: string;
};

/**
 * Mapping for handling the display of the frequency intervals for
 * robo investments and scheduled transactions
 */
export const FrequencyDisplayStringMap: IntervalGroups = {
  [V1RoboInfusionFreq.Monthly]: i18next.t('common.frequency.monthly'),
  [V1RoboInfusionFreq.SemiAnnually]: 'Semi-Annually',
  [V1RoboInfusionFreq.SemiMonthly]: 'Semi-Monthly',
  [V1RoboInfusionFreq.Unspecified]: 'Unspecified',
  [V1RoboInfusionFreq.Weekly]: i18next.t('common.frequency.weekly'),
  [V1RoboInfusionFreq.BiWeekly]: i18next.t('common.frequency.biWeekly'),
  [V1RoboInfusionFreq.Yearly]: 'Yearly',
  [V1RecurringRuleInterval.Unspecified]: '',
  [V1RecurringRuleInterval.Once]: 'Once',
  [V1RecurringRuleInterval.EveryWeek]: i18next.t('common.frequency.weekly'),
  [V1RecurringRuleInterval.EveryTwoWeeks]: i18next.t('common.frequency.biWeekly'),
  [V1RecurringRuleInterval.EveryFourWeeks]: 'EveryFourWeeks',
  [V1RecurringRuleInterval.EveryMonth]: i18next.t('common.frequency.monthly'),
  [V1RecurringRuleInterval.TwiceEveryMonth]: 'TwiceEveryMonth',
  [V1RecurringRuleInterval.EveryTwoMonths]: 'EveryTwoMonths',
  [V1RecurringRuleInterval.EveryThreeMonths]: 'EveryThreeMonths',
  [V1RecurringRuleInterval.SixMonths]: 'SixMonths',
  [V1RecurringRuleInterval.EveryYear]: 'EveryYear',
};

export const RecurringIntervalsLabelMap = {
  every_week: V1RecurringRuleInterval.EveryWeek,
  every_two_weeks: V1RecurringRuleInterval.EveryTwoWeeks,
  every_month: V1RecurringRuleInterval.EveryMonth,
};

export const RecurringIntervalsLabelMapKeys = {
  [V1RecurringRuleInterval.EveryWeek]: 'every_week',
  [V1RecurringRuleInterval.EveryTwoWeeks]: 'every_two_weeks',
  [V1RecurringRuleInterval.EveryMonth]: 'every_month',
  [V1RecurringRuleInterval.EveryFourWeeks]: null,
  [V1RecurringRuleInterval.EveryThreeMonths]: null,
  [V1RecurringRuleInterval.EveryTwoMonths]: null,
  [V1RecurringRuleInterval.EveryYear]: null,
  [V1RecurringRuleInterval.Once]: null,
  [V1RecurringRuleInterval.SixMonths]: null,
  [V1RecurringRuleInterval.TwiceEveryMonth]: null,
};

export const getNamedFreqDisplayString = (
  input: keyof typeof FrequencyDisplayStringMap | undefined,
) => {
  if (input === undefined) {
    return '';
  }
  return FrequencyDisplayStringMap[input] || '';
};

export const commonFrequencyInterval = [
  RoboGoalParametersRoboInfusionFreq.RoboInfusionFreqWeekly,
  RoboGoalParametersRoboInfusionFreq.RoboInfusionFreqBiWeekly,
  RoboGoalParametersRoboInfusionFreq.RoboInfusionFreqMonthly,
];
