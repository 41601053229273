import { useQuery } from '@apollo/client';
import {
  GET_ALL_ACCOUNTS_DATA,
  GetAllAccountsDataQuery,
  UserAccountAccountType,
} from '@fintronners/react-api/src';
import { useMemo } from 'react';

/**
 * This hook is used  to get the top level states for all accounts
 */
const useAllAccountsData = (shouldSkip: boolean = false) => {
  const { data, loading, refetch } = useQuery<GetAllAccountsDataQuery>(GET_ALL_ACCOUNTS_DATA, {
    pollInterval: 60000,
    fetchPolicy: 'cache-and-network',
    skip: shouldSkip,
    notifyOnNetworkStatusChange: true,
  });

  const [goals, selfDirected, ira, rothIra] = useMemo(() => {
    const accountEdges = data?.users?.edges?.[0]?.node?.accounts.edges;
    const roboGoalsEdges = data?.users?.edges?.[0]?.node?.roboGoals?.edges;

    const _selfDirected = accountEdges?.filter(
      (edge) => edge?.node?.type === UserAccountAccountType.AccountTypeBrokerage,
    )?.[0]?.node?.portfolios?.edges?.[0]?.node;

    const _ira = accountEdges?.filter(
      (edge) => edge?.node?.type === UserAccountAccountType.AccountTypeIra,
    )?.[0]?.node?.portfolios?.edges?.[0]?.node;

    const _rothIra = accountEdges?.filter(
      (edge) => edge?.node?.type === UserAccountAccountType.AccountTypeRothIra,
    )?.[0]?.node?.portfolios?.edges?.[0]?.node;

    const _goals = roboGoalsEdges
      ?.filter((edge) => edge?.node?.isActive)
      .map((edge) => {
        return edge?.node;
      });
    return [_goals, _selfDirected, _ira, _rothIra];
  }, [data, loading]);

  return { goals, selfDirected, ira, rothIra, loading, refetch };
};

export default useAllAccountsData;
