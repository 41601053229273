import { useQuery } from '@apollo/client';

// FinTron
import useUserAccount from '@/hooks/api/useUserAccount';
import { useStateForChart } from '@fintronners/react-widgets/src/Widgets/Charts/chartHooks';
import {
  thousandFormatNumbroCurrency,
  NumberNoNaN,
  formatPercent,
} from '@fintronners/react-utils/src/numberUtilsTSX';
import { ChartDuration } from '@fintronners/react-utils/src/durationUtils';
import {
  GET_ACCOUNT_VALUES,
  GetAccountValuesQuery,
  UserAccountAccountType,
} from '@fintronners/react-api/src';

import { GetAllUserAccountsStatusFragmentFragment } from '@fintronners/react-api/src';

type UserAccountReturnType = {
  userAccount?:
    | (GetAllUserAccountsStatusFragmentFragment & {
        firstPortfolioId: string;
      })
    | null;
};

type AccountValuesReturnType = {
  loading: boolean;
  gainsLosses: number;
  returnRate: number;
  formattedGainLoss: string;
  formattedReturnRate: string;
};

export const useAccountValues = (accountType: UserAccountAccountType): AccountValuesReturnType => {
  const { userAccount }: UserAccountReturnType = useUserAccount(accountType);

  const portfolioCreationTime = userAccount?.portfolios.edges?.find(
    (edge) => edge?.node?.id === userAccount?.firstPortfolioId,
  )?.node?.account.createTime;

  const { timeRange, period } = useStateForChart(
    ChartDuration.TRADING_DAY,
    true,
    portfolioCreationTime,
  );

  const { data, loading } = useQuery<GetAccountValuesQuery>(GET_ACCOUNT_VALUES, {
    skip: !userAccount?.firstPortfolioId,
    fetchPolicy: 'cache-and-network',
    pollInterval: 60000,
    variables: {
      portfolioId: userAccount?.firstPortfolioId,
      period: period,
      start: timeRange.start,
      end: timeRange.end,
    },
  });

  const valueHistory = data?.userAccountPortfolios?.edges?.at(0)?.node?.valueHistory;
  const totalValue = data?.totalValue?.edges?.at(0)?.node?.totalValue ?? 0;
  const _priceData = valueHistory?.values ?? [];
  const adjustedPriceData = totalValue
    ? [...(_priceData ?? []).slice(0, -1), totalValue]
    : _priceData;

  const gainsLosses = NumberNoNaN(valueHistory?.gainLosses[adjustedPriceData.length - 1] ?? []);

  const returnRate = NumberNoNaN(valueHistory?.returnRates[adjustedPriceData.length - 1] ?? []);
  const formattedReturnRate = formatPercent(returnRate);

  const formattedGainLoss =
    gainsLosses > 0
      ? `+${thousandFormatNumbroCurrency(gainsLosses)}`
      : thousandFormatNumbroCurrency(gainsLosses);

  return {
    loading,
    gainsLosses,
    returnRate,
    formattedGainLoss,
    formattedReturnRate,
  };
};
