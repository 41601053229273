import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SecurityCategory } from '@fintronners/internal-core/src/screeners/types';
import { SECURITY_CATEGORY } from '@fintronners/internal-core/src/screeners/constants';
import { useGetSearchPaginationDataQuery } from '@fintronners/react-api/src';

export type SecurityCategoryOption = {
  key: SecurityCategory;
  label: string;
};

export type UseSecurityCategoriesReturn = ReturnType<typeof useSecurityCategories>;

const useSecurityCategories = () => {
  const { t } = useTranslation();

  const { data: paginationData, loading } = useGetSearchPaginationDataQuery();

  const securityCategories = useMemo<SecurityCategoryOption[]>(() => {
    const options = [];

    const totalStocks = paginationData?.stock?.totalCount ?? 0;
    const totalEtfs = paginationData?.etf?.totalCount ?? 0;
    const totalMutualFunds = paginationData?.mutualFund?.totalCount ?? 0;

    if (totalStocks > 0) {
      options.push({
        key: SECURITY_CATEGORY.STOCK,
        label: t('common.securityCategory.stocks'),
      });
    }

    if (totalEtfs > 0) {
      options.push({
        key: SECURITY_CATEGORY.ETF,
        label: t('common.securityCategory.etfs'),
      });
    }

    if (totalMutualFunds > 0) {
      options.push({
        key: SECURITY_CATEGORY.MUTUAL_FUND,
        label: t('common.securityCategory.mutualFunds'),
      });
    }

    return options;
  }, [paginationData]);

  return {
    securityCategories,
    loading,
  };
};

export default useSecurityCategories;
