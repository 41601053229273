import { useMemo } from 'react';
import { useSearchParams } from 'next/navigation';
import { SecurityAssetSecurityType } from '@fintronners/react-api/src';
import { getSecurityTypesByCategory } from '@fintronners/internal-core/src/screeners/utils';
import type { SecurityCategory } from '@fintronners/internal-core/src/screeners/types';

/* constants */
export const SECURITY_CATEGORY_PARAM = 'security-category';

/**
 * useCurrentSecurityType Hook
 *
 * Retrieves the current security type and its corresponding key from the URL search parameters.
 * Useful for determining the active category in the navigation or obtaining the security type for filtering.
 */
const useCurrentSecurityType = (): {
  currentSecurityCategory: SecurityCategory;
  currentSecurityTypes: SecurityAssetSecurityType[] | undefined;
} => {
  const searchParams = useSearchParams();
  const currentSecurityCategory = searchParams.get(SECURITY_CATEGORY_PARAM) as SecurityCategory;

  const currentSecurityTypes = useMemo(
    () => getSecurityTypesByCategory(currentSecurityCategory),
    [currentSecurityCategory],
  );

  return { currentSecurityCategory, currentSecurityTypes };
};

export default useCurrentSecurityType;
