import {
  getProfileFieldUpdateRequests,
  getProfileFormFieldPending,
} from '@fintronners/react-api/src/utils/formUtils/schemas/pending/profile';
import useUserDetails from '@fintronners/internal-core/src/user/hooks/useUserDetails';

const usePendingProfileChanges = () => {
  const { userDetails } = useUserDetails('no-cache');
  const pendingProfileUpdateRequests = getProfileFieldUpdateRequests(
    userDetails?.profile?.updateRequests.edges,
  ).filter((r) => r.isPending);

  const isFieldInPendingState = (field: Parameters<typeof getProfileFormFieldPending>[1]) => {
    return getProfileFormFieldPending(pendingProfileUpdateRequests, field);
  };

  return {
    pendingProfileUpdateRequests,
    isFieldInPendingState,
  };
};

export default usePendingProfileChanges;
