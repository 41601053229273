import { gql } from '@apollo/client';

export const GET_SECURITIES = gql`
  query GetSecurities(
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
    $orderBy: SecurityAssetOrder
    $where: SecurityAssetWhereInput
  ) {
    securityAssets(
      first: $first
      after: $after
      last: $last
      before: $before
      where: $where
      orderBy: $orderBy
    ) {
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
        endCursor
      }
      totalCount
      edges {
        node {
          id
          name
          symbol
          active
          description
          iconURI
          logoURI
          color
          createTime
          updateTime
          securityType
          website
          cusip
          marketCap
          highlyVolatile
          details {
            ... on SecurityETFDetails {
              expenseRatioNet
            }
            ... on SecurityMutualFundDetails {
              expenseRatioNet
              loadType
              fundCategory
              initialInvestmentMinimum
              netAssetValue
            }
          }
          askExchange {
            id
          }
          bidExchange {
            id
          }
          complexSecurity
          sector {
            id
            name
          }
          financials {
            dividendYield
            peRatio
            dividends(limit: 1) {
              cashAmount
              payDate
            }
            statements(limit: 1) {
              revenue
              ebitda
              earningsPerShare
              bookValuePerShare
              profitMargin
              peRatio
            }
          }
          beta
          market {
            open
            bidSize
            last
            askSize
            baseMin
            close
            previousDayClose
            historical {
              close
            }
          }
          etfDetails {
            expenseRatio
          }
          # news(limit: 1) {
          #   title
          #   description
          #   url
          #   imageUrl
          #   source
          # }
        }
      }
    }
  }
`;

export const SECURITY_ASSET_PAGE = gql`
  fragment SecurityAssetPage on SecurityAsset {
    id
    name
    symbol
    active
    type
    iconURI
    logoURI
    market {
      last
      askSize
      baseMin
      previousDayClose
    }
  }
`;

export const SECURITY_ASSET_EDGE_PAGE = gql`
  fragment SecurityAssetEdgePage on SecurityAssetEdge {
    node {
      ...SecurityAssetPage
    }
  }
  ${SECURITY_ASSET_PAGE}
`;

export const GET_SECURITY_PAGES = gql`
  query GetSecurityPages(
    $first: Int
    $after: Cursor
    $orderBy: SecurityAssetOrder
    $where: SecurityAssetWhereInput
  ) {
    pages: securityAssets(first: $first, after: $after, where: $where, orderBy: $orderBy) {
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
        endCursor
      }
      edges {
        ...SecurityAssetEdgePage
      }
    }
  }
  ${SECURITY_ASSET_EDGE_PAGE}
`;

export const GET_SECURITY_SECTORS_FILTER_OPTIONS = gql`
  query GetSecuritySectorsFilterOptions($where: SecuritySectorWhereInput) {
    securitySectors(where: $where) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const GET_SEARCH_PAGINATION_DATA = gql`
  query GetSearchPaginationData($where: SecurityAssetWhereInput) {
    currentFilter: securityAssets(where: $where) {
      totalCount
    }
    stock: securityAssets(where: { securityTypeIn: [CS, CVR, PFD] }) {
      totalCount
    }
    etf: securityAssets(where: { securityTypeIn: [ETF, ETN] }) {
      totalCount
    }
    mutualFund: securityAssets(where: { securityTypeIn: [MUTUAL_FUND] }) {
      totalCount
    }
  }
`;
