import {
  CommonUserProfileDetailsFragment,
  CommonUserProfileRequestParametersFragment,
  UserProfileUpdateRequestUpdateRequestStatus,
} from '@fintronners/react-api/src';
import { getYesterday } from '@fintronners/react-api/src/utils/dateUtils';
import { FieldUpdateRequests } from '../types';

export type ProfileUpdateRequests = CommonUserProfileDetailsFragment['updateRequests']['edges'];

export type ProfileFieldUpdateRequests = FieldUpdateRequests<
  CommonUserProfileRequestParametersFragment | undefined
>;

/**
 *
 * @param updateRequests the gql profile update request response
 * @returns an array of profile field update request objects containing requestParameters and isPending
 */
export const getProfileFieldUpdateRequests = (
  updateRequests: ProfileUpdateRequests,
): ProfileFieldUpdateRequests | [] => {
  if (updateRequests === null || !updateRequests) return [];

  const yesterday = getYesterday();

  return updateRequests
    .filter(
      (edge) =>
        edge?.node?.status ===
          UserProfileUpdateRequestUpdateRequestStatus.UpdateRequestStatusPending ||
        edge?.node?.status ===
          UserProfileUpdateRequestUpdateRequestStatus.UpdateRequestStatusApproved,
    )
    .filter((edge) => {
      return new Date(edge?.node?.createTime) >= yesterday;
    })
    .map((edge) => ({
      requestParameters: edge?.node?.requestParameters,
      isPending:
        edge?.node?.status ===
        UserProfileUpdateRequestUpdateRequestStatus.UpdateRequestStatusPending,
    }));
};

/**
 *
 * @param profileFieldUpdateRequests the formatted profile field update request response
 * @param propLookup the keyed name value from gql fragment
 * @returns boolean - returns true if found a pending field, false otherwise
 */
export const getProfileFormFieldPending = (
  profileFieldUpdateRequests: ProfileFieldUpdateRequests | [],
  propLookup: keyof CommonUserProfileRequestParametersFragment,
) => {
  for (let i = 0; i < profileFieldUpdateRequests.length; i++) {
    if (profileFieldUpdateRequests[i].requestParameters !== undefined) {
      const requestParameters = profileFieldUpdateRequests[i].requestParameters; // makes ts happy
      const currRequest = requestParameters![propLookup];
      if (currRequest !== null && currRequest !== undefined) {
        return profileFieldUpdateRequests[i].isPending;
      }
    }
  }
  return false;
};

/**
 *
 * @param profileFieldUpdateRequests the formatted profile field update request response
 * @param propLookup the keyed name value from gql fragment
 * @param dbValue the original profile value from db
 * @returns string | null | undefined returns the markdowns value
 */
export const getProfileFormFieldValue = (
  profileFieldUpdateRequests: ProfileFieldUpdateRequests | [],
  propLookup: keyof CommonUserProfileRequestParametersFragment,
  dbValue: string | undefined | null,
) => {
  for (let i = 0; i < profileFieldUpdateRequests.length; i++) {
    if (profileFieldUpdateRequests[i].requestParameters !== undefined) {
      const requestParameters = profileFieldUpdateRequests[i].requestParameters; // makes ts happy
      const currRequest = requestParameters![propLookup];
      if (currRequest !== null && currRequest !== undefined) {
        return profileFieldUpdateRequests[i].isPending ? currRequest : dbValue ?? '';
      }
    }
  }
  return dbValue ?? '';
};
