import {
  WebBodyText16,
  WebBodyText14,
  WebHeading28,
  WebHeading32,
} from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import {
  formatPercent,
  thousandFormatNumbroCurrency,
} from '@fintronners/react-utils/src/numberUtilsTSX';
import { ImageWithFallback } from '../ImageWithFallback';
import getDynamicStockSymbolAsData from '@fintronners/react-utils/src/dynamicStockSymbolAsData';

type AssetOverviewProps = {
  price: number;
  basePrice: number;
  logoURI?: string | null | undefined;
  symbol: string | undefined;
  name: string | undefined;
  loading?: boolean;
};

const AssetOverview = ({
  price,
  basePrice,
  logoURI,
  symbol,
  name,
  loading,
}: AssetOverviewProps) => {
  const assetMedia = {
    logoURI,
    symbol,
    name,
  };

  const variationPrice = price - basePrice;
  const variationPercent = (price - basePrice) / basePrice;
  const formatedVariationPrice = thousandFormatNumbroCurrency(variationPrice);
  const formatedVariationPercent = formatPercent(variationPercent);

  const encodedSvg = getDynamicStockSymbolAsData(assetMedia?.symbol ?? '');

  return (
    <div>
      {loading ? (
        <div className="flex flex-col gap-3">
          <div className="flex gap-3 items-center">
            <div className="h-10 w-10 animate-pulse rounded-full border-grey25 border bg-gray-300" />
            <div className="h-7 w-20 animate-pulse bg-gray-300" />
          </div>
          <div className="h-4 w-20 animate-pulse bg-gray-300" />
          <div className="flex flex-col gap-3">
            <div className="h-8 w-20 animate-pulse bg-gray-300" />

            <div className="flex items-center gap-2">
              <div className="h-4 w-40 animate-pulse bg-gray-300" />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="flex items-center text-xs text-muted-foreground space-x-2">
            <div className="rounded-full border-grey25 border min-w-10 min-h-10 h-10 w-10 relative">
              <ImageWithFallback
                className="p-1"
                src={assetMedia?.logoURI || `data:image/svg+xml;charset=utf-8,${encodedSvg}`}
                fallbackSrc={`data:image/svg+xml;charset=utf-8,${encodedSvg}`}
                alt={assetMedia.symbol ?? '-'}
                style={{ objectFit: 'contain', objectPosition: 'center' }}
                fill
              />
            </div>
            <WebHeading32.Bold className="text-primary">{assetMedia.symbol}</WebHeading32.Bold>
          </div>
          <WebBodyText16.Regular className="text-grey55 text-muted-foreground">
            {assetMedia.name}
          </WebBodyText16.Regular>
          <div>
            <WebHeading28.Bold className="text-darkestBlue">
              {thousandFormatNumbroCurrency(price)}
            </WebHeading28.Bold>
            <div className="flex items-center gap-2">
              <WebBodyText14.Bold className={variationPrice >= 0 ? 'text-green' : 'text-red'}>
                {formatedVariationPrice}
              </WebBodyText14.Bold>
              <WebBodyText14.Bold className={variationPercent >= 0 ? 'text-green' : 'text-red'}>
                ({formatedVariationPercent})
              </WebBodyText14.Bold>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AssetOverview;
