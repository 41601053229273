import { V1RetirementDistributionType, V1TransferType } from '@fintronners/react-api/src';
import useUserAccount from '@/hooks/api/useUserAccount';
import useTransferService from './useTransferService';
import useCurrentAccountType, { useIsIRAAccount } from '../utils/useCurrentAccountType';
import { useWithholdings } from '@fintronners/internal-core/src/withholdingElections/hooks/useWithholdings';
import { differenceInYears } from 'date-fns';
import useUserDetails from '@fintronners/internal-core/src/user/hooks/useUserDetails';

export const PREMATURE_CONTRIBUTION_AGE = 59.5;

type CreateWithdrawal = {
  amount: string;
  accountId?: string;
};

export const useWithdrawal = () => {
  const transferService = useTransferService();

  const { federalWithholdingRate, stateWithholdingRate, isFederalWaived, isStateWaived } =
    useWithholdings();
  const { userDetails } = useUserDetails();
  const isIRAAccount = useIsIRAAccount();

  const accountType = useCurrentAccountType();
  const { userAccount } = useUserAccount(accountType);

  const portfolioId = userAccount?.firstPortfolioId || '';

  const dateOfBirth = userDetails?.profile?.dateOfBirth;
  const userAge = differenceInYears(new Date(), new Date(dateOfBirth));

  const retirementInfo = {
    distribution: {
      type:
        userAge < PREMATURE_CONTRIBUTION_AGE
          ? V1RetirementDistributionType.Premature
          : V1RetirementDistributionType.Normal,
      ...(!isFederalWaived && {
        federalTaxWithholding: {
          amount: {
            percentage: String(federalWithholdingRate),
          },
        },
      }),
      ...(!isStateWaived && {
        stateTaxWithholding: {
          amount: {
            percentage: String(stateWithholdingRate),
          },
        },
      }),
    },
  };

  const createWithdrawal = async ({ amount, accountId }: CreateWithdrawal) =>
    await transferService.transferServiceCreateTransfer({
      externalAccountUid: accountId ?? '',
      type: V1TransferType.Withdraw,
      amount,
      portfolioUid: portfolioId ?? '',
      ...(isIRAAccount && {
        retirementInfo,
      }),
    });

  return {
    createWithdrawal,
  };
};
