import i18next from '@fintronners/react-language/src/Lang';

export enum ErrorKeys {
  CodeMismatchException = 'CodeMismatchException',
  LimitExceededException = 'LimitExceededException',
  ExpiredCodeException = 'ExpiredCodeException',
}

/**
 * Get the specific error message based on the error key.
 * @param errorStr The error string to check.
 * @returns The specific error message or null if not found.
 * @example
 * ```ts
 * getSpecificErrorMessage('CodeMismatchException: The verification code you entered is incorrect.');
 * // 'The verification code you entered is incorrect.'
 * ```
 */
export const getSpecificErrorMessage = (errorStr: string): string | null => {
  if (!errorStr || !i18next) {
    return null;
  }

  for (const errorKey of Object.values(ErrorKeys)) {
    if (errorStr.includes(errorKey)) {
      return i18next.t(`errors.genericErrorCodes.${errorKey}`);
    }
  }

  return null;
};
