import i18next from '@fintronners/react-language/src/Lang';

export enum ChartDuration {
  DAY = 'DAY',
  TRADING_DAY = 'TRADING_DAY',
  EXTENDED_TRADING_DAY = 'EXTENDED_TRADING_DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  THREE_MONTHS = 'THREE_MONTHS',
  YEAR = 'YEAR',
  FIVE_YEARS = 'FIVE_YEARS',
  ALL = 'ALL',
}

export const ChartDurationShortNames: Record<ChartDuration, string> = {
  DAY: i18next.t('common.duration.day'),
  TRADING_DAY: i18next.t('common.duration.tradingDay'),
  EXTENDED_TRADING_DAY: i18next.t('common.duration.extendedTradingDay'),
  WEEK: i18next.t('common.duration.week'),
  MONTH: i18next.t('common.duration.month'),
  THREE_MONTHS: i18next.t('common.duration.threeMonths'),
  YEAR: i18next.t('common.duration.year'),
  FIVE_YEARS: i18next.t('common.duration.fiveYears'),
  ALL: i18next.t('common.duration.all'),
} as const;
