import { gql } from '@apollo/client';

export const GET_SECURITY_INDEX_SCREEN = gql`
  query GetSecurityIndexScreen($securityID: ID, $securitySymbol: String) {
    securityAssets(where: { or: { id: $securityID, symbol: $securitySymbol } }) {
      edges {
        node {
          id
          symbol
          securityType
        }
      }
    }
  }
`;

export const GET_SECURITY_HEADER_SECTION = gql`
  query GetSecurityHeaderSection(
    $securityID: ID!
    $accountID: ID
    $shouldCheckActiveBalance: Boolean!
  ) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          id
          symbol
          name
          logoURI
          securityType
          market {
            last
          }
        }
      }
    }
    userAccounts(where: { id: $accountID }) @include(if: $shouldCheckActiveBalance) {
      edges {
        node {
          balances(where: { hasAssetWith: { id: $securityID } }) {
            activeBalance
          }
        }
      }
    }
  }
`;

export const GET_SECURITY_MY_HOLDING_SECTION = gql`
  query GetSecurityMyHoldingSection($securityID: ID!, $accountID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          id
          market {
            last
            previousDayClose
          }
          financials {
            dividends(limit: 1) {
              cashAmount
            }
          }
        }
      }
    }
    userAccountPortfolios(where: { hasAccountWith: { id: $accountID } }) {
      edges {
        node {
          id
          totalValue
          assetBalances(where: { hasAssetWith: { id: $securityID } }) {
            edges {
              node {
                costBasis
                balances {
                  activeBalance
                }
              }
            }
          }
        }
      }
    }
    dividendDistributions(
      # FIXME: Add security filter (not yet available on BE)
      where: { hasPortfolioWith: { hasAccountWith: { id: $accountID } } }
      orderBy: { direction: DESC, field: create_time }
    ) {
      edges {
        node {
          amount
          distributionAsset {
            id
          }
        }
      }
    }
  }
`;

export const GET_SECURITY_TRADING_SECTION = gql`
  query GetSecurityTradingSection($securityID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          id
          askExchange {
            name
          }
          bidExchange {
            name
          }
          market {
            askSize
            bidSize
            volume
            historical {
              volume
            }
          }
        }
      }
    }
  }
`;

export const GET_SECURITY_STOCK_DATA_SECTION = gql`
  query GetStockSecurityDataSection($securityID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          id
          marketCap
          securityType
          financials {
            dividendTrailingTwelveMonths
            dividendYield
            dividends(limit: 1) {
              exDividendDate
            }
            statements(limit: 1) {
              totalEnterpriseValue
              revenue
              ebitda
              ebitdaMargin
              ebit
              profitMargin
              peRatio
              pegRatio
              earningsPerShare
              bookValuePerShare
              longTermDebt
              # FIXME: add quickRatio
              totalCash
            }
          }
        }
      }
    }
  }
`;

export const GET_SECURITY_STOCK_INFO_SECTION = gql`
  query GetStockSecurityInfoSection($securityID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          description
          name
          # FIXME: add ceo
          sector {
            name
          }
        }
      }
    }
  }
`;

export const GET_SECURITY_NEWS_SECTION = gql`
  query GetSecurityNewsSection($securityID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          news(limit: 1) {
            title
            datetime
            description
            url
            imageUrl
            source
          }
        }
      }
    }
  }
`;

export const GET_SECURITY_ETF_INFO_SECTION = gql`
  query GetEtfSecurityInfoSection($securityID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          id
          description
          market {
            last
            previousDayClose
          }
          details {
            __typename
            ... on SecurityETFDetails {
              netAssetValue
              yield30Day
              expenseRatioNet
            }
          }
        }
      }
    }
  }
`;

export const GET_SECURITY_DISTRIBUTIONS_SECTION = gql`
  query GetSecurityDistributionsSection($securityID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          id
          name
          symbol
          financials {
            dividends {
              cashAmount
              frequency
              payDate
            }
          }
        }
      }
    }
  }
`;

export const GET_SECURITY_SECTORS_SECTION = gql`
  query GetSecuritySectorsSection($securityID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          id
          details {
            __typename
            ... on SecurityETFDetails {
              holdings {
                __typename
              }
            }
            ... on SecurityMutualFundDetails {
              holdings {
                __typename
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_SECURITY_TOP_HOLDINGS_SECTION = gql`
  query GetSecurityTopHoldingsSection($securityID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          id
          details {
            __typename
            ... on SecurityETFDetails {
              holdings {
                __typename
              }
            }
            ... on SecurityMutualFundDetails {
              holdings {
                __typename
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_SECURITY_MF_DATA_SECTION = gql`
  query GetMFSecurityDataSection($securityID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          id
          beta
          details {
            __typename
            ... on SecurityMutualFundDetails {
              transactionFee
              netAssetValue
              netAssetValueChange
              yearToDateReturns
              yield30Day
              fundCategory
              fundInception
              loadType
              loadPct
              initialInvestmentMinimum
              additionalInvestmentMinimum
              turnoverRate
              portfolioNetAssetValue
              shareClassNetAssetValue
              twelveMonthLowValue
              twelveMonthHighValue
              rSquared
              sharpeRatio
              annualizedStdDev
            }
          }
        }
      }
    }
  }
`;

export const GET_SECURITY_MF_EXPENSES_SECTION = gql`
  query GetMFSecurityExpensesSection($securityID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          id
          details {
            __typename
            ... on SecurityMutualFundDetails {
              expenseRatioGross
              expenseRatioNet
              contractualExpenseWaiver
              distributionServiceFee
            }
          }
        }
      }
    }
  }
`;

export const GET_SECURITY_MF_MINIMUMS_SECTION = gql`
  query GetMFSecurityMinimumsSection($securityID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          id
          details {
            __typename
            ... on SecurityMutualFundDetails {
              initialInvestmentMinimum
              additionalInvestmentMinimum
              initialIraInvestmentMinimum
              additionalIraInvestmentMinimum
              initialGroupRetirementInvestmentMinimum
              additionalGroupRetirementInvestmentMinimum
            }
          }
        }
      }
    }
  }
`;

export const GET_SECURITY_MF_INFO_SECTION = gql`
  query GetMFSecurityInfoSection($securityID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          id
          name
          description
        }
      }
    }
  }
`;
