import {
  getAscendStringRequiredSchema,
  getOneOfEnumSchema,
} from '@fintronners/react-api/src/utils/formUtils/schemas/commonSchemas';
import { YES_OR_NO } from '@fintronners/react-api/src/utils/formUtils/schemas/types';
import i18n from '@fintronners/react-language/src/Lang';
import * as yup from 'yup';

//TODO: Move it to Fintron API
export const ControlPersonSchema = yup.object({
  isControlPerson: getOneOfEnumSchema(YES_OR_NO).required(),
  companySymbols: yup.array().when('isControlPerson', {
    is: YES_OR_NO.YES,
    then: (schema) =>
      schema
        .of(
          yup.object({
            value: getAscendStringRequiredSchema(
              i18n.t('onboardingIsControlPerson.symbolOfCompany'),
            ),
          }),
        )
        .required(),
  }),
});

export type ControlPersonFormData = yup.InferType<typeof ControlPersonSchema>;
