import { RecursivePartial } from '@fintronners/react-utils/types/types';

/**
 * Singleton used to configure global values for the FinTronConfig package.
 *
 * The FinTronConfig object needs to be set ASAP in order for the configurations to
 * be applied properly.
 *
 * NOTE: Please use deprecations aggressively as this is a very public API.
 */
class FinTronConfig {
  tenant: string = '{{Tenant}}';
  iosStoreUrl: string = '{{iOS Store URL}}';
  androidStoreUrl: string = '{{Android Store URL}}';
  heapId: string = '{{Heap ID}}';
  contactUrl: string = '{{Contact URL}}';
  supportEmail: string = '{{Support Email}}';
  /** @deprecated Use selfDirected.name*/
  brokerName: string = '{{Broker Name}}';
  /** @deprecated Use robo.name*/
  roboName: string = '{{Robo Name}}';
  privacyPolicyUrl: string = '{{Privacy Policy URL}}';
  termsAndConditionsUrl: string = '{{Terms of Service URL}}';
  disclosuresUrl: string = '{{Disclosures URL}}';
  parentName: string = '{{Parent Name}}';
  parentNameVariation: string = '{{Parent Name Variation}}';
  clearingFirm: string = '{{Clearing Firm}}';
  language: string = 'en';
  customerAgreementPdfUrl: string = '{{Customer Agreement PDF URL}}';
  /** @deprecated Use selfDirected.customerRelationshipSummaryPdfUrl or robo.customerRelationshipSummaryPdfUrl */
  customerRelationshipSummaryPdfUrl: string = '{{Customer Relationship Summary PDF URL}}';
  fundProspectusUrl: string = '{{Fund Prospectus URL}}';
  apexUrls: {
    apexCashSweep: string;
    apexMasterSecuritiesLendingAgreement: string;
    securitiesLendingDisclosure: string;
  } = {
    apexCashSweep: '{{Apex Cash Sweep URL}}',
    apexMasterSecuritiesLendingAgreement: '{{Apex Master Securities Lending Agreement URL}}',
    securitiesLendingDisclosure: '{{Securities Lending Disclosures URL}}',
  };

  embeddedSdkMode: {
    /** Shows the side drawer menu and alternative settings button*/
    drawerMenuEnabled: boolean;
    /** Hides the bottom tab navigation*/
    hideBottomTab: boolean;
    /** Hides FAQ support menus */
    disableFaqs: boolean;
    /**
     * Disables the safe area view in the FinTronExperience. This
     * is typically enabled if the host app is already handling safe
     * area views and insets.
     **/
    disableSafeAreaView: boolean;
    allowExitFromHomeScreen: boolean;
    /**
     * Should be set to true if the host is managing authentication
     * and the FinTronExperience should not handle authentication.
     * This will disable things such as resetting passwords, logging
     * out, and other authentication related features such as MFA.
     **/
    hostManagesAuthentication: boolean;
  } = {
    allowExitFromHomeScreen: false,
    drawerMenuEnabled: true,
    hideBottomTab: false,
    disableFaqs: false,
    disableSafeAreaView: false,
    hostManagesAuthentication: false,
  };
  robo: {
    // region top level robo config
    /** Enable FPSL and Cash Sweep for the discretionary account type. */
    fpslAndCashSweepEnabled: boolean;
    /** The name of the discretionary account type such as Robo, Advisor, etc... */
    name: string;
    /** The support email for the discretionary account type */
    supportEmail: string;
    /** The privacy policy URL for the discretionary account type */
    privacyPolicyUrl: string;
    /** The fund prospectus URL */
    fundProspectusUrl: string;
    /** The customer relationship summary PDF URL */
    customerRelationshipSummaryPdfUrl: string;
    /** The firm brochure URL */
    firmBrochureUrl?: string;
    /** The investment adviser agreement URL */
    investmentAdviserAgreementUrl?: string;
    /** The client relationship summary URL */
    clientRelationshipSummaryUrl?: string;
    /** The adviser brochure URL */
    adviserBrochureUrl?: string;
    /** The clearing firm customer account agreement URL */
    clearingFirmCustomerAccountAgreementUrl?: string;
    /** The apex customer privacy notice URL */
    apexCustomerPrivacyNoticeUrl?: string;
    /** The apex customer relationship summary URL */
    apexCustomerRelationshipSummaryUrl?: string;
    /** The apex FDIC sweep program URL */
    apexFdicSweepProgramUrl?: string;
    /** Inclusive transfer ranges such as daily deposit limits, minimum deposit etc... **/
    transfers: {
      /** Preselected values for target investments */
      preselectedTargetInvestments: number[];
      /** Preselected values for initial investments */
      preselectedInitialInvestments: number[];
      /** The minimum initial investment deposit amount */
      initialDepositMin: number;
      /** Preselected values for recurring investments */
      preselectedRecurringInvestments: number[];
      /** The percentage of the account balance that can be withdrawn from 0-1*/
      withdrawThresholdPercent: 0.9;
      /** The daily deposit limit*/
      dailyDepositLimit: number;
      /** The minimum deposit amount */
      depositMin: number;
      /** The minimum withdrawal amount */
      withdrawalMin: number;
    };
    // endregion
    /** goals specific config */
    goals: {
      /** The low risk percentage for the discretionary account type. */
      canCreateLowProbabilityGoal: boolean;
      lowRiskPercentage: number;
      /** The medium risk percentage for the discretionary account type. */
      mediumRiskPercentage: number;
      /** The high risk percentage for the discretionary account type. */
      highRiskPercentage: number;
    };
    /** General Investing specific config */
    generalInvesting: {
      /** Display name of the product */
      name: string;
    };
    agreement: {
      /** The URL for the first button PDF */
      firstButtonPdfUrl: string;
      /** The URL for the second button PDF */
      secondButtonPdfUrl: string;
    };
  } = {
    fpslAndCashSweepEnabled: false,
    name: 'FinTron Advisors',
    supportEmail: '{{Robo Support Email}}',
    privacyPolicyUrl: '{{Robo Privacy Policy URL}}',
    fundProspectusUrl: '{{Fund Prospectus URL}}',
    customerRelationshipSummaryPdfUrl: '{{Customer Relationship Summary PDF URL}}',
    firmBrochureUrl: '{{Firm Brochure URL}}',
    investmentAdviserAgreementUrl: '{{Investor Adviser Agreement URL}}',
    clientRelationshipSummaryUrl: '{{Client Relationship Summary URL}}',
    adviserBrochureUrl: '{{Adviser Brochure URL}}',
    clearingFirmCustomerAccountAgreementUrl: '{{Clearing Firm Customer Account Agreement URL}}',
    apexCustomerPrivacyNoticeUrl: '{{Apex Customer Privacy Notice URL}}',
    apexCustomerRelationshipSummaryUrl: '{{Apex Customer Relationship Summary URL}}',
    apexFdicSweepProgramUrl: '{{Apex FDIC Sweep Program URL}}',
    transfers: {
      preselectedTargetInvestments: [50000, 100000, 200000],
      preselectedInitialInvestments: [10000, 20000, 40000],
      preselectedRecurringInvestments: [250, 500, 1000],
      dailyDepositLimit: 49999.99,
      depositMin: 5,
      withdrawalMin: 5,
      initialDepositMin: 500,
      withdrawThresholdPercent: 0.9,
    },
    goals: {
      canCreateLowProbabilityGoal: true,
      lowRiskPercentage: 0.35,
      mediumRiskPercentage: 0.65,
      highRiskPercentage: 0.9,
    },
    generalInvesting: {
      name: 'General Investing',
    },
    agreement: {
      firstButtonPdfUrl: '{{Robo Agreement First Button PDF URL}}',
      secondButtonPdfUrl: '{{Robo Agreement Second Button PDF URL}}',
    },
  };
  selfDirected: {
    /**
     * Hide the search categories section on Security search screen.
     * @deprecated Since it was used for toggling the displaying of the old sector
     * screen section and we don't have it anymore, this flag is no longer needed.
     **/
    hideSearchCategory: boolean;
    /**
     * The name of the non-discretionary account type such as
     * Self-Directed, Brokerage, etc...
     * */
    name: string;
    /** The support email for the non-discretionary account */
    supportEmail: string;
    /** The privacy policy URL for the non-discretionary account */
    privacyPolicyUrl: string;
    /** The customer relationship summary PDF URL */
    customerRelationshipSummaryPdfUrl: string;
    /** Inclusive transfer ranges such as daily deposit limits, minimum deposit etc... **/
    transfers: {
      /** Preselected values for recurring investments */
      preselectedRecurringInvestments: number[];
      /** The daily deposit limit*/
      dailyDepositLimit: number;
      /** The minimum deposit amount */
      depositMin: number;
      /** The minimum withdrawal amount */
      withdrawalMin: number;
    };
    /**
     * Inclusive trade ranges such as minimum buy amount etc...
     */
    trades: {
      /** The minimum amount that can be bought */
      buyMin: number;
      /** The minimum amount that can be sold */
      sellMin: number;
      /** The minimum amount that can be invested in a repeat investment */
      repeatInvestmentMin: number;
    };
  } = {
    hideSearchCategory: false,
    name: 'Self-Directed',
    supportEmail: '{{Brokerage Support Email}}',
    privacyPolicyUrl: '{{Brokerage Privacy Policy URL}}',
    customerRelationshipSummaryPdfUrl: '{{Customer Relationship Summary PDF URL}}',
    transfers: {
      preselectedRecurringInvestments: [25, 50, 100],
      dailyDepositLimit: 49999.99,
      depositMin: 5,
      withdrawalMin: 5,
    },
    trades: {
      buyMin: 5,
      sellMin: 1,
      repeatInvestmentMin: 10,
    },
  };

  orders: {
    mutualFundSellTreshold: number;
  } = {
    /** The percentage of the balance that can be sold before triggering the "sell all" automatically, from 0-1*/
    mutualFundSellTreshold: 0.9,
  };

  linking: {
    /**
     * Custom URL scheme used to deep link into the FinTronExperience.
     * Must contain a trailing `://` to be valid.
     * e.g. "myapp://"
     */
    scheme: string;
    /**
     * Universal links used to deep link into the FinTronExperience.
     * Recommend include https:// in the hostname.
     * e.g. ["https://www.myapp.com"]
     */
    hostname: string[];
  } = {
    scheme: 'fintron://',
    hostname: ['https://www.fintroninvest.com', 'https://dev.fintroninvest.com'],
  };

  init = (globalValues: RecursivePartial<FinTronConfig> = {}) => {
    console.log('FinTronConfig initialized with values:', globalValues);
    const mergeProperties = (target: any, source: any) => {
      for (const key in source) {
        if (source.hasOwnProperty(key)) {
          if (typeof source[key] === 'object' && !Array.isArray(source[key])) {
            target[key] = mergeProperties(target[key] || {}, source[key]);
          } else {
            target[key] = source[key];
          }
        }
      }
      return target;
    };
    mergeProperties(this, globalValues);

    // Set selfDirected.customerRelationshipSummaryPdfUrl to match the deprecated top-level property
    if (globalValues.customerRelationshipSummaryPdfUrl) {
      this.selfDirected.customerRelationshipSummaryPdfUrl =
        globalValues.customerRelationshipSummaryPdfUrl;

      if (!globalValues.robo?.customerRelationshipSummaryPdfUrl) {
        this.robo.customerRelationshipSummaryPdfUrl =
          globalValues.customerRelationshipSummaryPdfUrl;
      }
    }

    require('@fintronners/react-language/src/Lang');
  };
}

export default new FinTronConfig();
