import { gql } from '@apollo/client';

export const GET_ROBO_ACCOUNT_PORTFOLIO_DETAILS = gql`
  query GetRoboAccountPortfolioDetails($id: ID!) {
    roboGoals(where: { id: $id }) {
      edges {
        node {
          id
          accountPortfolio {
            totalValue
            id
          }
          allocation {
            withdrawableValue
          }
        }
      }
    }
    roboGeneralInvestments(where: { id: $id }) {
      edges {
        node {
          id
          accountPortfolio {
            totalValue
            id
          }
          allocation {
            withdrawableValue
          }
        }
      }
    }
  }
`;

export const GET_ROBO_STOCK_SLEEVE_SCREEN = gql`
  query GetRoboStockSleeveScreen($isRobo: Boolean!, $portfolioID: ID, $roboID: ID) {
    modelPortfolios(where: { id: $portfolioID }) @skip(if: $isRobo) {
      edges {
        node {
          ...StockSleeveScreenModelPortfolioFragment
        }
      }
    }
    roboGeneralInvestments(where: { id: $roboID }) @include(if: $isRobo) {
      edges {
        node {
          id
          allocation {
            allocationBySector {
              value
              sector {
                id
              }
              assets {
                value
                asset {
                  id
                }
              }
            }
          }
          targetPortfolio {
            ...StockSleeveScreenModelPortfolioFragment
          }
        }
      }
    }
    roboGoals(where: { id: $roboID }) @include(if: $isRobo) {
      edges {
        node {
          id
          allocation {
            allocationBySector {
              value
              sector {
                id
              }
              assets {
                value
                asset {
                  id
                }
              }
            }
          }
          targetPortfolio {
            ...StockSleeveScreenModelPortfolioFragment
          }
        }
      }
    }
  }
  fragment StockSleeveScreenModelPortfolioFragment on ModelPortfolio {
    id
    sectors {
      targetPct
      sector {
        id
        name
        description
        uxType
        assets {
          targetPct
          asset {
            id
            symbol
            name
          }
        }
      }
    }
  }
`;

export const GET_ROBO_GOALS_AND_GI = gql`
  query GetGoalsAndGeneralInvestments {
    roboGoals {
      edges {
        node {
          id
        }
      }
    }
    roboGeneralInvestments {
      edges {
        node {
          id
        }
      }
    }
  }
`;
