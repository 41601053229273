import parsePhoneNumber from 'libphonenumber-js';
import { Country, supportedCountriesArray } from './countries';

export const phoneMasks = {
  US: '([000]) [000]-[0000]',
  BR: '([00]) [00000]-[0000]',
};

export const getPhoneNumberDetails = (phoneNumber: string) => {
  if (!phoneNumber) {
    return {
      phoneNumber,
      isValid: false,
    };
  }

  const phoneNumberObj = parsePhoneNumber(phoneNumber);

  const country: Country | undefined = supportedCountriesArray.find(
    (c) => c.alpha2Code === phoneNumberObj?.country,
  );

  return {
    phoneNumber,
    country,
    isValid: phoneNumberObj?.isValid(),
    nationalNumber: phoneNumberObj?.nationalNumber,
  };
};
