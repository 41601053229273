import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Any: { input: any; output: any };
  CountryCode: { input: any; output: any };
  /**
   * Define a Relay Cursor type:
   * https://relay.dev/graphql/connections.htm#sec-Cursor
   */
  Cursor: { input: string; output: string };
  Date: { input: any; output: any };
  Decimal: { input: any; output: any };
  EncryptedString: { input: any; output: any };
  /** The builtin Map type */
  Map: { input: any; output: any };
  PULID: { input: any; output: any };
  PhoneNumber: { input: any; output: any };
  /** The builtin Time type */
  Time: { input: any; output: any };
  UUID: { input: any; output: any };
  Uint: { input: any; output: any };
};

export type AchTransfer = BaseOrder &
  Node & {
    __typename?: 'ACHTransfer';
    /** Amount is the amount of the asset to be transferred. */
    amount: Scalars['Decimal']['output'];
    createTime: Scalars['Time']['output'];
    /** ErrorCode is the error code of the ach transfer if it failed */
    errorCode?: Maybe<AchTransferTransferErrorCode>;
    /** ExpectedSettlement is the date that the transfer is expected to settle. */
    expectedSettlementDate?: Maybe<Scalars['Date']['output']>;
    /** ExternalAccount is the external account that the transfer was made to or from. */
    externalAccount: ExternalAccount;
    /** external id of the transfer provided by the entity performing the transfer. */
    externalID?: Maybe<Scalars['String']['output']>;
    /** FeeAndCreditOrder is the fee that was assessed on this order. */
    feeOrder?: Maybe<FeeAndCreditOrder>;
    holdReason?: Maybe<AchTransferTransferHoldReason>;
    id: Scalars['ID']['output'];
    /** Amount of a deposit that was immediately cleared as an instant deposit. */
    instantDepositAmount?: Maybe<Scalars['Decimal']['output']>;
    isReturned: Scalars['Boolean']['output'];
    /** the time that the transfer was issued to the clearing house */
    issuedTime?: Maybe<Scalars['Time']['output']>;
    /** Lockup is the lockup that was created for this transfer. */
    lockup?: Maybe<AssetLockup>;
    /** Notes is the notes of the order intended for the principal. */
    note?: Maybe<Scalars['String']['output']>;
    orderStatus: OrderOrderStatus;
    orderType: OrderOrderType;
    portfolio: UserAccountPortfolio;
    /** Reason is a description as to why the transfer failed. */
    reason?: Maybe<Scalars['String']['output']>;
    /** RecurringRuleInstance is the recurring rule instance that created this transfer. */
    recurringRuleInstance?: Maybe<RecurringRuleInstance>;
    requestDetails: RequestDetails;
    retirementContributionInfo?: Maybe<RetirementContributionInfo>;
    retirementDistributionInfo?: Maybe<RetirementDistributionInfo>;
    /** Fee assessed because of a returned transfer. */
    returnFee?: Maybe<Scalars['Decimal']['output']>;
    /** the time that the transfer was settled. */
    settledTime?: Maybe<Scalars['Time']['output']>;
    status: AchTransferTransferStatus;
    statusChangeLog: Array<AchTransferStatusChangeLog>;
    /** Fee assessed by fintron. */
    transferFee?: Maybe<Scalars['Decimal']['output']>;
    type: AchTransferTransferType;
    /** the time that confirmation for the transfer was received. */
    unsettledTime?: Maybe<Scalars['Time']['output']>;
    updateTime: Scalars['Time']['output'];
    user: User;
  };

export type AchTransferStatusChangeLogArgs = {
  status?: InputMaybe<AchTransferTransferStatus>;
  statusIn?: InputMaybe<Array<AchTransferTransferStatus>>;
};

/** A connection to a list of items. */
export type AchTransferConnection = {
  __typename?: 'ACHTransferConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AchTransferEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AchTransferEdge = {
  __typename?: 'ACHTransferEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<AchTransfer>;
};

/** Ordering options for ACHTransfer connections */
export type AchTransferOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ACHTransfers. */
  field: AchTransferOrderField;
};

/** Properties by which ACHTransfer connections can be ordered. */
export enum AchTransferOrderField {
  CreateTime = 'create_time',
  IssuedTime = 'issued_time',
  SettledTime = 'settled_time',
  UnsettledTime = 'unsettled_time',
  UpdateTime = 'update_time',
}

export type AchTransferStatusChangeLog = {
  __typename?: 'ACHTransferStatusChangeLog';
  eventTime: Scalars['Time']['output'];
  note: Scalars['String']['output'];
  status: AchTransferTransferStatus;
};

/** ACHTransferTransferErrorCode is enum for the field error_code */
export enum AchTransferTransferErrorCode {
  TransferErrorCodeExceedsDailyLimit = 'TRANSFER_ERROR_CODE_EXCEEDS_DAILY_LIMIT',
  TransferErrorCodeInsufficientFunds = 'TRANSFER_ERROR_CODE_INSUFFICIENT_FUNDS',
  TransferErrorCodeInvalidAccountStatus = 'TRANSFER_ERROR_CODE_INVALID_ACCOUNT_STATUS',
  TransferErrorCodeInvalidAmount = 'TRANSFER_ERROR_CODE_INVALID_AMOUNT',
  TransferErrorCodeInvalidOrder = 'TRANSFER_ERROR_CODE_INVALID_ORDER',
  TransferErrorCodeOther = 'TRANSFER_ERROR_CODE_OTHER',
  TransferErrorCodeServerError = 'TRANSFER_ERROR_CODE_SERVER_ERROR',
  TransferErrorCodeUnapproved = 'TRANSFER_ERROR_CODE_UNAPPROVED',
  TransferErrorCodeUnsupported = 'TRANSFER_ERROR_CODE_UNSUPPORTED',
  TransferErrorCodeValidationFailed = 'TRANSFER_ERROR_CODE_VALIDATION_FAILED',
}

/** ACHTransferTransferHoldReason is enum for the field hold_reason */
export enum AchTransferTransferHoldReason {
  TransferHoldReasonAccountNeedsAttention = 'TRANSFER_HOLD_REASON_ACCOUNT_NEEDS_ATTENTION',
  TransferHoldReasonAccountOpenPending = 'TRANSFER_HOLD_REASON_ACCOUNT_OPEN_PENDING',
  TransferHoldReasonWithdrawLockup = 'TRANSFER_HOLD_REASON_WITHDRAW_LOCKUP',
}

/** ACHTransferTransferStatus is enum for the field status */
export enum AchTransferTransferStatus {
  TransferStatusCanceled = 'TRANSFER_STATUS_CANCELED',
  TransferStatusCanceling = 'TRANSFER_STATUS_CANCELING',
  TransferStatusFailed = 'TRANSFER_STATUS_FAILED',
  TransferStatusFunded = 'TRANSFER_STATUS_FUNDED',
  TransferStatusHold = 'TRANSFER_STATUS_HOLD',
  TransferStatusIssued = 'TRANSFER_STATUS_ISSUED',
  TransferStatusNew = 'TRANSFER_STATUS_NEW',
  TransferStatusSettled = 'TRANSFER_STATUS_SETTLED',
  TransferStatusUnsettled = 'TRANSFER_STATUS_UNSETTLED',
}

/** ACHTransferTransferType is enum for the field type */
export enum AchTransferTransferType {
  TransferTypeDeposit = 'TRANSFER_TYPE_DEPOSIT',
  TransferTypeWithdraw = 'TRANSFER_TYPE_WITHDRAW',
}

/**
 * ACHTransferWhereInput is used for filtering ACHTransfer objects.
 * Input was generated by ent.
 */
export type AchTransferWhereInput = {
  /** amount field predicates */
  amount?: InputMaybe<Scalars['Decimal']['input']>;
  amountGT?: InputMaybe<Scalars['Decimal']['input']>;
  amountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  amountLT?: InputMaybe<Scalars['Decimal']['input']>;
  amountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  amountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  and?: InputMaybe<Array<AchTransferWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** error_code field predicates */
  errorCode?: InputMaybe<AchTransferTransferErrorCode>;
  errorCodeIn?: InputMaybe<Array<AchTransferTransferErrorCode>>;
  errorCodeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  errorCodeNEQ?: InputMaybe<AchTransferTransferErrorCode>;
  errorCodeNotIn?: InputMaybe<Array<AchTransferTransferErrorCode>>;
  errorCodeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** expected_settlement_date field predicates */
  expectedSettlementDate?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateGT?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateGTE?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  expectedSettlementDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  expectedSettlementDateLT?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateLTE?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  expectedSettlementDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** external_id field predicates */
  externalID?: InputMaybe<Scalars['String']['input']>;
  externalIDContains?: InputMaybe<Scalars['String']['input']>;
  externalIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  externalIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  externalIDGT?: InputMaybe<Scalars['String']['input']>;
  externalIDGTE?: InputMaybe<Scalars['String']['input']>;
  externalIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  externalIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  externalIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  externalIDLT?: InputMaybe<Scalars['String']['input']>;
  externalIDLTE?: InputMaybe<Scalars['String']['input']>;
  externalIDNEQ?: InputMaybe<Scalars['String']['input']>;
  externalIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** external_account edge predicates */
  hasExternalAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasExternalAccountWith?: InputMaybe<Array<ExternalAccountWhereInput>>;
  /** fee_order edge predicates */
  hasFeeOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasFeeOrderWith?: InputMaybe<Array<FeeAndCreditOrderWhereInput>>;
  /** lockup edge predicates */
  hasLockup?: InputMaybe<Scalars['Boolean']['input']>;
  hasLockupWith?: InputMaybe<Array<AssetLockupWhereInput>>;
  /** portfolio edge predicates */
  hasPortfolio?: InputMaybe<Scalars['Boolean']['input']>;
  hasPortfolioWith?: InputMaybe<Array<UserAccountPortfolioWhereInput>>;
  /** recurring_rule_instance edge predicates */
  hasRecurringRuleInstance?: InputMaybe<Scalars['Boolean']['input']>;
  hasRecurringRuleInstanceWith?: InputMaybe<Array<RecurringRuleInstanceWhereInput>>;
  /** retirement_contribution_info edge predicates */
  hasRetirementContributionInfo?: InputMaybe<Scalars['Boolean']['input']>;
  hasRetirementContributionInfoWith?: InputMaybe<Array<RetirementContributionInfoWhereInput>>;
  /** retirement_distribution_info edge predicates */
  hasRetirementDistributionInfo?: InputMaybe<Scalars['Boolean']['input']>;
  hasRetirementDistributionInfoWith?: InputMaybe<Array<RetirementDistributionInfoWhereInput>>;
  hasUserWith?: InputMaybe<UserWhereInput>;
  /** hold_reason field predicates */
  holdReason?: InputMaybe<AchTransferTransferHoldReason>;
  holdReasonIn?: InputMaybe<Array<AchTransferTransferHoldReason>>;
  holdReasonIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  holdReasonNEQ?: InputMaybe<AchTransferTransferHoldReason>;
  holdReasonNotIn?: InputMaybe<Array<AchTransferTransferHoldReason>>;
  holdReasonNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** instant_deposit_amount field predicates */
  instantDepositAmount?: InputMaybe<Scalars['Decimal']['input']>;
  instantDepositAmountGT?: InputMaybe<Scalars['Decimal']['input']>;
  instantDepositAmountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  instantDepositAmountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  instantDepositAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  instantDepositAmountLT?: InputMaybe<Scalars['Decimal']['input']>;
  instantDepositAmountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  instantDepositAmountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  instantDepositAmountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  instantDepositAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** is_returned field predicates */
  isReturned?: InputMaybe<Scalars['Boolean']['input']>;
  isReturnedNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** issued_time field predicates */
  issuedTime?: InputMaybe<Scalars['Time']['input']>;
  issuedTimeGT?: InputMaybe<Scalars['Time']['input']>;
  issuedTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  issuedTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  issuedTimeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  issuedTimeLT?: InputMaybe<Scalars['Time']['input']>;
  issuedTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  issuedTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  issuedTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  issuedTimeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<AchTransferWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<AchTransferWhereInput>>;
  /** reason field predicates */
  reason?: InputMaybe<Scalars['String']['input']>;
  reasonContains?: InputMaybe<Scalars['String']['input']>;
  reasonContainsFold?: InputMaybe<Scalars['String']['input']>;
  reasonEqualFold?: InputMaybe<Scalars['String']['input']>;
  reasonGT?: InputMaybe<Scalars['String']['input']>;
  reasonGTE?: InputMaybe<Scalars['String']['input']>;
  reasonHasPrefix?: InputMaybe<Scalars['String']['input']>;
  reasonHasSuffix?: InputMaybe<Scalars['String']['input']>;
  reasonIn?: InputMaybe<Array<Scalars['String']['input']>>;
  reasonIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  reasonLT?: InputMaybe<Scalars['String']['input']>;
  reasonLTE?: InputMaybe<Scalars['String']['input']>;
  reasonNEQ?: InputMaybe<Scalars['String']['input']>;
  reasonNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  reasonNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** return_fee field predicates */
  returnFee?: InputMaybe<Scalars['Decimal']['input']>;
  returnFeeGT?: InputMaybe<Scalars['Decimal']['input']>;
  returnFeeGTE?: InputMaybe<Scalars['Decimal']['input']>;
  returnFeeIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  returnFeeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  returnFeeLT?: InputMaybe<Scalars['Decimal']['input']>;
  returnFeeLTE?: InputMaybe<Scalars['Decimal']['input']>;
  returnFeeNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  returnFeeNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  returnFeeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** settled_time field predicates */
  settledTime?: InputMaybe<Scalars['Time']['input']>;
  settledTimeGT?: InputMaybe<Scalars['Time']['input']>;
  settledTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  settledTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  settledTimeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  settledTimeLT?: InputMaybe<Scalars['Time']['input']>;
  settledTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  settledTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  settledTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  settledTimeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** status field predicates */
  status?: InputMaybe<AchTransferTransferStatus>;
  statusIn?: InputMaybe<Array<AchTransferTransferStatus>>;
  statusNEQ?: InputMaybe<AchTransferTransferStatus>;
  statusNotIn?: InputMaybe<Array<AchTransferTransferStatus>>;
  /** transfer_fee field predicates */
  transferFee?: InputMaybe<Scalars['Decimal']['input']>;
  transferFeeGT?: InputMaybe<Scalars['Decimal']['input']>;
  transferFeeGTE?: InputMaybe<Scalars['Decimal']['input']>;
  transferFeeIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  transferFeeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  transferFeeLT?: InputMaybe<Scalars['Decimal']['input']>;
  transferFeeLTE?: InputMaybe<Scalars['Decimal']['input']>;
  transferFeeNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  transferFeeNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  transferFeeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** type field predicates */
  type?: InputMaybe<AchTransferTransferType>;
  typeIn?: InputMaybe<Array<AchTransferTransferType>>;
  typeNEQ?: InputMaybe<AchTransferTransferType>;
  typeNotIn?: InputMaybe<Array<AchTransferTransferType>>;
  /** unsettled_time field predicates */
  unsettledTime?: InputMaybe<Scalars['Time']['input']>;
  unsettledTimeGT?: InputMaybe<Scalars['Time']['input']>;
  unsettledTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  unsettledTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  unsettledTimeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  unsettledTimeLT?: InputMaybe<Scalars['Time']['input']>;
  unsettledTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  unsettledTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  unsettledTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  unsettledTimeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type AcatTransfer = BaseOrder &
  Node & {
    __typename?: 'AcatTransfer';
    completeTime?: Maybe<Scalars['Time']['output']>;
    createTime: Scalars['Time']['output'];
    direction: AcatTransferAcatDirection;
    /** Date received from clearing house as to when the transfer is expected to settle */
    expectedSettlementDate?: Maybe<Scalars['Date']['output']>;
    id: Scalars['ID']['output'];
    /** Notes is the notes of the order intended for the principal. */
    note?: Maybe<Scalars['String']['output']>;
    orderStatus: OrderOrderStatus;
    orderType: OrderOrderType;
    portfolio: UserAccountPortfolio;
    requestDetails: RequestDetails;
    status: AcatTransferAcatTransferStatus;
    statusChangeLog: Array<AcatTransferStatusChangeLog>;
    transferDetails: AcatTransferDetails;
    transfers?: Maybe<Array<AcatTransferAsset>>;
    updateTime: Scalars['Time']['output'];
    user: User;
  };

export type AcatTransferStatusChangeLogArgs = {
  status?: InputMaybe<AcatTransferAcatTransferStatus>;
  statusIn?: InputMaybe<Array<AcatTransferAcatTransferStatus>>;
};

/** AcatTransferAcatDirection is enum for the field direction */
export enum AcatTransferAcatDirection {
  AcatDirectionJoining = 'ACAT_DIRECTION_JOINING',
  AcatDirectionJoiningAndLeaving = 'ACAT_DIRECTION_JOINING_AND_LEAVING',
  AcatDirectionLeaving = 'ACAT_DIRECTION_LEAVING',
  AcatDirectionUndefined = 'ACAT_DIRECTION_UNDEFINED',
}

/** AcatTransferAcatTransferStatus is enum for the field status */
export enum AcatTransferAcatTransferStatus {
  AcatTransferStatusClosePurge = 'ACAT_TRANSFER_STATUS_CLOSE_PURGE',
  AcatTransferStatusError = 'ACAT_TRANSFER_STATUS_ERROR',
  AcatTransferStatusInitiateRequest = 'ACAT_TRANSFER_STATUS_INITIATE_REQUEST',
  AcatTransferStatusMemoPurgePartialTransferRequestReceiver = 'ACAT_TRANSFER_STATUS_MEMO_PURGE_PARTIAL_TRANSFER_REQUEST_RECEIVER',
  AcatTransferStatusPartialRequest = 'ACAT_TRANSFER_STATUS_PARTIAL_REQUEST',
  AcatTransferStatusReject = 'ACAT_TRANSFER_STATUS_REJECT',
  AcatTransferStatusRequest = 'ACAT_TRANSFER_STATUS_REQUEST',
  AcatTransferStatusRequestAdjust = 'ACAT_TRANSFER_STATUS_REQUEST_ADJUST',
  AcatTransferStatusRequestAdjustPast = 'ACAT_TRANSFER_STATUS_REQUEST_ADJUST_PAST',
  AcatTransferStatusRequestPast = 'ACAT_TRANSFER_STATUS_REQUEST_PAST',
  AcatTransferStatusRequestReject = 'ACAT_TRANSFER_STATUS_REQUEST_REJECT',
  AcatTransferStatusReview = 'ACAT_TRANSFER_STATUS_REVIEW',
  AcatTransferStatusReviewAccelerate = 'ACAT_TRANSFER_STATUS_REVIEW_ACCELERATE',
  AcatTransferStatusReviewAdjustDeliverer = 'ACAT_TRANSFER_STATUS_REVIEW_ADJUST_DELIVERER',
  AcatTransferStatusReviewAsJustReceiverAccelerate = 'ACAT_TRANSFER_STATUS_REVIEW_AS_JUST_RECEIVER_ACCELERATE',
  AcatTransferStatusReviewError = 'ACAT_TRANSFER_STATUS_REVIEW_ERROR',
  AcatTransferStatusSettleClose = 'ACAT_TRANSFER_STATUS_SETTLE_CLOSE',
  AcatTransferStatusSettlePrep = 'ACAT_TRANSFER_STATUS_SETTLE_PREP',
  AcatTransferStatusSystemPurge = 'ACAT_TRANSFER_STATUS_SYSTEM_PURGE',
  AcatTransferStatusTransferComplete = 'ACAT_TRANSFER_STATUS_TRANSFER_COMPLETE',
  AcatTransferStatusUndefined = 'ACAT_TRANSFER_STATUS_UNDEFINED',
}

export type AcatTransferAsset = Node & {
  __typename?: 'AcatTransferAsset';
  acatTransfer?: Maybe<AcatTransfer>;
  asset?: Maybe<BaseAsset>;
  createTime: Scalars['Time']['output'];
  errors?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Quantity of asset to be transferred */
  quantity: Scalars['Decimal']['output'];
  /** Quantity of asset to be transferred */
  unadjustedQuantity?: Maybe<Scalars['Decimal']['output']>;
  updateTime: Scalars['Time']['output'];
};

/** Ordering options for AcatTransferAsset connections */
export type AcatTransferAssetOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order AcatTransferAssets. */
  field: AcatTransferAssetOrderField;
};

/** Properties by which AcatTransferAsset connections can be ordered. */
export enum AcatTransferAssetOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * AcatTransferAssetWhereInput is used for filtering AcatTransferAsset objects.
 * Input was generated by ent.
 */
export type AcatTransferAssetWhereInput = {
  and?: InputMaybe<Array<AcatTransferAssetWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** errors field predicates */
  errors?: InputMaybe<Scalars['String']['input']>;
  errorsContains?: InputMaybe<Scalars['String']['input']>;
  errorsContainsFold?: InputMaybe<Scalars['String']['input']>;
  errorsEqualFold?: InputMaybe<Scalars['String']['input']>;
  errorsGT?: InputMaybe<Scalars['String']['input']>;
  errorsGTE?: InputMaybe<Scalars['String']['input']>;
  errorsHasPrefix?: InputMaybe<Scalars['String']['input']>;
  errorsHasSuffix?: InputMaybe<Scalars['String']['input']>;
  errorsIn?: InputMaybe<Array<Scalars['String']['input']>>;
  errorsIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  errorsLT?: InputMaybe<Scalars['String']['input']>;
  errorsLTE?: InputMaybe<Scalars['String']['input']>;
  errorsNEQ?: InputMaybe<Scalars['String']['input']>;
  errorsNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  errorsNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** acat_transfer edge predicates */
  hasAcatTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  hasAcatTransferWith?: InputMaybe<Array<AcatTransferWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<AcatTransferAssetWhereInput>;
  or?: InputMaybe<Array<AcatTransferAssetWhereInput>>;
  /** quantity field predicates */
  quantity?: InputMaybe<Scalars['Decimal']['input']>;
  quantityGT?: InputMaybe<Scalars['Decimal']['input']>;
  quantityGTE?: InputMaybe<Scalars['Decimal']['input']>;
  quantityIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  quantityLT?: InputMaybe<Scalars['Decimal']['input']>;
  quantityLTE?: InputMaybe<Scalars['Decimal']['input']>;
  quantityNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  quantityNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** unadjusted_quantity field predicates */
  unadjustedQuantity?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedQuantityGT?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedQuantityGTE?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedQuantityIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  unadjustedQuantityIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  unadjustedQuantityLT?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedQuantityLTE?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedQuantityNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedQuantityNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  unadjustedQuantityNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** A connection to a list of items. */
export type AcatTransferConnection = {
  __typename?: 'AcatTransferConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AcatTransferEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type AcatTransferDetails = Node & {
  __typename?: 'AcatTransferDetails';
  account: UserAccount;
  assets?: Maybe<Array<AcatTransferDetailsAsset>>;
  clearingNotes?: Maybe<Array<Scalars['String']['output']>>;
  completeTime?: Maybe<Scalars['Time']['output']>;
  contraPartyAccountNumber?: Maybe<Scalars['String']['output']>;
  contraPartyAccountTitle?: Maybe<Scalars['String']['output']>;
  contraPartyParticipantNumber?: Maybe<Scalars['String']['output']>;
  controlNumber?: Maybe<Scalars['Int']['output']>;
  createTime: Scalars['Time']['output'];
  direction: AcatTransferDetailsAcatDirection;
  disableAutosign: Scalars['Boolean']['output'];
  error?: Maybe<Scalars['String']['output']>;
  /** Date received from clearing house as to when the transfer is expected to settle */
  expectedSettlementDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  portfolioTransfers?: Maybe<Array<AcatTransfer>>;
  processingCaseID?: Maybe<Scalars['Int']['output']>;
  /** rejection comment from the contra party */
  rejectComment?: Maybe<Scalars['String']['output']>;
  /** rejection comment from apex */
  rejectReason?: Maybe<Scalars['String']['output']>;
  /** The time at which the action was requested. */
  requestTime: Scalars['Time']['output'];
  requestUUID?: Maybe<Scalars['UUID']['output']>;
  requestor: Requestor;
  status: AcatTransferDetailsAcatTransferStatus;
  tifID?: Maybe<Scalars['String']['output']>;
  transferType: AcatTransferDetailsAcatTransferType;
  updateTime: Scalars['Time']['output'];
  user: User;
};

/** AcatTransferDetailsAcatDirection is enum for the field direction */
export enum AcatTransferDetailsAcatDirection {
  AcatDirectionJoining = 'ACAT_DIRECTION_JOINING',
  AcatDirectionJoiningAndLeaving = 'ACAT_DIRECTION_JOINING_AND_LEAVING',
  AcatDirectionLeaving = 'ACAT_DIRECTION_LEAVING',
  AcatDirectionUndefined = 'ACAT_DIRECTION_UNDEFINED',
}

/** AcatTransferDetailsAcatTransferStatus is enum for the field status */
export enum AcatTransferDetailsAcatTransferStatus {
  AcatTransferStatusClosePurge = 'ACAT_TRANSFER_STATUS_CLOSE_PURGE',
  AcatTransferStatusError = 'ACAT_TRANSFER_STATUS_ERROR',
  AcatTransferStatusInitiateRequest = 'ACAT_TRANSFER_STATUS_INITIATE_REQUEST',
  AcatTransferStatusMemoPurgePartialTransferRequestReceiver = 'ACAT_TRANSFER_STATUS_MEMO_PURGE_PARTIAL_TRANSFER_REQUEST_RECEIVER',
  AcatTransferStatusPartialRequest = 'ACAT_TRANSFER_STATUS_PARTIAL_REQUEST',
  AcatTransferStatusReject = 'ACAT_TRANSFER_STATUS_REJECT',
  AcatTransferStatusRequest = 'ACAT_TRANSFER_STATUS_REQUEST',
  AcatTransferStatusRequestAdjust = 'ACAT_TRANSFER_STATUS_REQUEST_ADJUST',
  AcatTransferStatusRequestAdjustPast = 'ACAT_TRANSFER_STATUS_REQUEST_ADJUST_PAST',
  AcatTransferStatusRequestPast = 'ACAT_TRANSFER_STATUS_REQUEST_PAST',
  AcatTransferStatusRequestReject = 'ACAT_TRANSFER_STATUS_REQUEST_REJECT',
  AcatTransferStatusReview = 'ACAT_TRANSFER_STATUS_REVIEW',
  AcatTransferStatusReviewAccelerate = 'ACAT_TRANSFER_STATUS_REVIEW_ACCELERATE',
  AcatTransferStatusReviewAdjustDeliverer = 'ACAT_TRANSFER_STATUS_REVIEW_ADJUST_DELIVERER',
  AcatTransferStatusReviewAsJustReceiverAccelerate = 'ACAT_TRANSFER_STATUS_REVIEW_AS_JUST_RECEIVER_ACCELERATE',
  AcatTransferStatusReviewError = 'ACAT_TRANSFER_STATUS_REVIEW_ERROR',
  AcatTransferStatusSettleClose = 'ACAT_TRANSFER_STATUS_SETTLE_CLOSE',
  AcatTransferStatusSettlePrep = 'ACAT_TRANSFER_STATUS_SETTLE_PREP',
  AcatTransferStatusSystemPurge = 'ACAT_TRANSFER_STATUS_SYSTEM_PURGE',
  AcatTransferStatusTransferComplete = 'ACAT_TRANSFER_STATUS_TRANSFER_COMPLETE',
  AcatTransferStatusUndefined = 'ACAT_TRANSFER_STATUS_UNDEFINED',
}

/** AcatTransferDetailsAcatTransferType is enum for the field transfer_type */
export enum AcatTransferDetailsAcatTransferType {
  AcatTransferTypeFailReversalBrokerToBrokerOnly = 'ACAT_TRANSFER_TYPE_FAIL_REVERSAL_BROKER_TO_BROKER_ONLY',
  AcatTransferTypeFullTransfer = 'ACAT_TRANSFER_TYPE_FULL_TRANSFER',
  AcatTransferTypeMutualFundCleanup = 'ACAT_TRANSFER_TYPE_MUTUAL_FUND_CLEANUP',
  AcatTransferTypePartialTransferDeliverer = 'ACAT_TRANSFER_TYPE_PARTIAL_TRANSFER_DELIVERER',
  AcatTransferTypePartialTransferReceiver = 'ACAT_TRANSFER_TYPE_PARTIAL_TRANSFER_RECEIVER',
  AcatTransferTypePositionTransferFundFirmToMutualFundCompanyOnly = 'ACAT_TRANSFER_TYPE_POSITION_TRANSFER_FUND_FIRM_TO_MUTUAL_FUND_COMPANY_ONLY',
  AcatTransferTypeReclaim = 'ACAT_TRANSFER_TYPE_RECLAIM',
  AcatTransferTypeResidualCredit = 'ACAT_TRANSFER_TYPE_RESIDUAL_CREDIT',
  AcatTransferTypeUndefined = 'ACAT_TRANSFER_TYPE_UNDEFINED',
}

export type AcatTransferDetailsAsset = Node & {
  __typename?: 'AcatTransferDetailsAsset';
  comment?: Maybe<Scalars['String']['output']>;
  createTime: Scalars['Time']['output'];
  errors?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isLong: Scalars['Boolean']['output'];
  /** Notional value of asset to be transferred */
  notional: Scalars['Decimal']['output'];
  /** Quantity of asset to be transferred */
  quantity: Scalars['Decimal']['output'];
  settlingLocation?: Maybe<Scalars['String']['output']>;
  transfer: AcatTransferDetails;
  /** Quantity of asset to be transferred */
  unadjustedQuantity?: Maybe<Scalars['Decimal']['output']>;
  updateTime: Scalars['Time']['output'];
};

/** Ordering options for AcatTransferDetailsAsset connections */
export type AcatTransferDetailsAssetOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order AcatTransferDetailsAssets. */
  field: AcatTransferDetailsAssetOrderField;
};

/** Properties by which AcatTransferDetailsAsset connections can be ordered. */
export enum AcatTransferDetailsAssetOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * AcatTransferDetailsAssetWhereInput is used for filtering AcatTransferDetailsAsset objects.
 * Input was generated by ent.
 */
export type AcatTransferDetailsAssetWhereInput = {
  and?: InputMaybe<Array<AcatTransferDetailsAssetWhereInput>>;
  /** comment field predicates */
  comment?: InputMaybe<Scalars['String']['input']>;
  commentContains?: InputMaybe<Scalars['String']['input']>;
  commentContainsFold?: InputMaybe<Scalars['String']['input']>;
  commentEqualFold?: InputMaybe<Scalars['String']['input']>;
  commentGT?: InputMaybe<Scalars['String']['input']>;
  commentGTE?: InputMaybe<Scalars['String']['input']>;
  commentHasPrefix?: InputMaybe<Scalars['String']['input']>;
  commentHasSuffix?: InputMaybe<Scalars['String']['input']>;
  commentIn?: InputMaybe<Array<Scalars['String']['input']>>;
  commentIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  commentLT?: InputMaybe<Scalars['String']['input']>;
  commentLTE?: InputMaybe<Scalars['String']['input']>;
  commentNEQ?: InputMaybe<Scalars['String']['input']>;
  commentNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  commentNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** errors field predicates */
  errors?: InputMaybe<Scalars['String']['input']>;
  errorsContains?: InputMaybe<Scalars['String']['input']>;
  errorsContainsFold?: InputMaybe<Scalars['String']['input']>;
  errorsEqualFold?: InputMaybe<Scalars['String']['input']>;
  errorsGT?: InputMaybe<Scalars['String']['input']>;
  errorsGTE?: InputMaybe<Scalars['String']['input']>;
  errorsHasPrefix?: InputMaybe<Scalars['String']['input']>;
  errorsHasSuffix?: InputMaybe<Scalars['String']['input']>;
  errorsIn?: InputMaybe<Array<Scalars['String']['input']>>;
  errorsIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  errorsLT?: InputMaybe<Scalars['String']['input']>;
  errorsLTE?: InputMaybe<Scalars['String']['input']>;
  errorsNEQ?: InputMaybe<Scalars['String']['input']>;
  errorsNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  errorsNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** transfer edge predicates */
  hasTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  hasTransferWith?: InputMaybe<Array<AcatTransferDetailsWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_long field predicates */
  isLong?: InputMaybe<Scalars['Boolean']['input']>;
  isLongNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<AcatTransferDetailsAssetWhereInput>;
  /** notional field predicates */
  notional?: InputMaybe<Scalars['Decimal']['input']>;
  notionalGT?: InputMaybe<Scalars['Decimal']['input']>;
  notionalGTE?: InputMaybe<Scalars['Decimal']['input']>;
  notionalIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  notionalLT?: InputMaybe<Scalars['Decimal']['input']>;
  notionalLTE?: InputMaybe<Scalars['Decimal']['input']>;
  notionalNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  notionalNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  or?: InputMaybe<Array<AcatTransferDetailsAssetWhereInput>>;
  /** quantity field predicates */
  quantity?: InputMaybe<Scalars['Decimal']['input']>;
  quantityGT?: InputMaybe<Scalars['Decimal']['input']>;
  quantityGTE?: InputMaybe<Scalars['Decimal']['input']>;
  quantityIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  quantityLT?: InputMaybe<Scalars['Decimal']['input']>;
  quantityLTE?: InputMaybe<Scalars['Decimal']['input']>;
  quantityNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  quantityNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** settling_location field predicates */
  settlingLocation?: InputMaybe<Scalars['String']['input']>;
  settlingLocationContains?: InputMaybe<Scalars['String']['input']>;
  settlingLocationContainsFold?: InputMaybe<Scalars['String']['input']>;
  settlingLocationEqualFold?: InputMaybe<Scalars['String']['input']>;
  settlingLocationGT?: InputMaybe<Scalars['String']['input']>;
  settlingLocationGTE?: InputMaybe<Scalars['String']['input']>;
  settlingLocationHasPrefix?: InputMaybe<Scalars['String']['input']>;
  settlingLocationHasSuffix?: InputMaybe<Scalars['String']['input']>;
  settlingLocationIn?: InputMaybe<Array<Scalars['String']['input']>>;
  settlingLocationIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  settlingLocationLT?: InputMaybe<Scalars['String']['input']>;
  settlingLocationLTE?: InputMaybe<Scalars['String']['input']>;
  settlingLocationNEQ?: InputMaybe<Scalars['String']['input']>;
  settlingLocationNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  settlingLocationNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** unadjusted_quantity field predicates */
  unadjustedQuantity?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedQuantityGT?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedQuantityGTE?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedQuantityIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  unadjustedQuantityIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  unadjustedQuantityLT?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedQuantityLTE?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedQuantityNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedQuantityNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  unadjustedQuantityNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** Ordering options for AcatTransferDetails connections */
export type AcatTransferDetailsOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order AcatTransferDetailsSlice. */
  field: AcatTransferDetailsOrderField;
};

/** Properties by which AcatTransferDetails connections can be ordered. */
export enum AcatTransferDetailsOrderField {
  RequestTime = 'REQUEST_TIME',
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * AcatTransferDetailsWhereInput is used for filtering AcatTransferDetails objects.
 * Input was generated by ent.
 */
export type AcatTransferDetailsWhereInput = {
  and?: InputMaybe<Array<AcatTransferDetailsWhereInput>>;
  /** complete_time field predicates */
  completeTime?: InputMaybe<Scalars['Time']['input']>;
  completeTimeGT?: InputMaybe<Scalars['Time']['input']>;
  completeTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  completeTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  completeTimeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  completeTimeLT?: InputMaybe<Scalars['Time']['input']>;
  completeTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  completeTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  completeTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  completeTimeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** contra_party_account_number field predicates */
  contraPartyAccountNumber?: InputMaybe<Scalars['String']['input']>;
  contraPartyAccountNumberContains?: InputMaybe<Scalars['String']['input']>;
  contraPartyAccountNumberContainsFold?: InputMaybe<Scalars['String']['input']>;
  contraPartyAccountNumberEqualFold?: InputMaybe<Scalars['String']['input']>;
  contraPartyAccountNumberGT?: InputMaybe<Scalars['String']['input']>;
  contraPartyAccountNumberGTE?: InputMaybe<Scalars['String']['input']>;
  contraPartyAccountNumberHasPrefix?: InputMaybe<Scalars['String']['input']>;
  contraPartyAccountNumberHasSuffix?: InputMaybe<Scalars['String']['input']>;
  contraPartyAccountNumberIn?: InputMaybe<Array<Scalars['String']['input']>>;
  contraPartyAccountNumberIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  contraPartyAccountNumberLT?: InputMaybe<Scalars['String']['input']>;
  contraPartyAccountNumberLTE?: InputMaybe<Scalars['String']['input']>;
  contraPartyAccountNumberNEQ?: InputMaybe<Scalars['String']['input']>;
  contraPartyAccountNumberNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  contraPartyAccountNumberNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** contra_party_account_title field predicates */
  contraPartyAccountTitle?: InputMaybe<Scalars['String']['input']>;
  contraPartyAccountTitleContains?: InputMaybe<Scalars['String']['input']>;
  contraPartyAccountTitleContainsFold?: InputMaybe<Scalars['String']['input']>;
  contraPartyAccountTitleEqualFold?: InputMaybe<Scalars['String']['input']>;
  contraPartyAccountTitleGT?: InputMaybe<Scalars['String']['input']>;
  contraPartyAccountTitleGTE?: InputMaybe<Scalars['String']['input']>;
  contraPartyAccountTitleHasPrefix?: InputMaybe<Scalars['String']['input']>;
  contraPartyAccountTitleHasSuffix?: InputMaybe<Scalars['String']['input']>;
  contraPartyAccountTitleIn?: InputMaybe<Array<Scalars['String']['input']>>;
  contraPartyAccountTitleIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  contraPartyAccountTitleLT?: InputMaybe<Scalars['String']['input']>;
  contraPartyAccountTitleLTE?: InputMaybe<Scalars['String']['input']>;
  contraPartyAccountTitleNEQ?: InputMaybe<Scalars['String']['input']>;
  contraPartyAccountTitleNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  contraPartyAccountTitleNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** contra_party_participant_number field predicates */
  contraPartyParticipantNumber?: InputMaybe<Scalars['String']['input']>;
  contraPartyParticipantNumberContains?: InputMaybe<Scalars['String']['input']>;
  contraPartyParticipantNumberContainsFold?: InputMaybe<Scalars['String']['input']>;
  contraPartyParticipantNumberEqualFold?: InputMaybe<Scalars['String']['input']>;
  contraPartyParticipantNumberGT?: InputMaybe<Scalars['String']['input']>;
  contraPartyParticipantNumberGTE?: InputMaybe<Scalars['String']['input']>;
  contraPartyParticipantNumberHasPrefix?: InputMaybe<Scalars['String']['input']>;
  contraPartyParticipantNumberHasSuffix?: InputMaybe<Scalars['String']['input']>;
  contraPartyParticipantNumberIn?: InputMaybe<Array<Scalars['String']['input']>>;
  contraPartyParticipantNumberIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  contraPartyParticipantNumberLT?: InputMaybe<Scalars['String']['input']>;
  contraPartyParticipantNumberLTE?: InputMaybe<Scalars['String']['input']>;
  contraPartyParticipantNumberNEQ?: InputMaybe<Scalars['String']['input']>;
  contraPartyParticipantNumberNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  contraPartyParticipantNumberNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** control_number field predicates */
  controlNumber?: InputMaybe<Scalars['Int']['input']>;
  controlNumberGT?: InputMaybe<Scalars['Int']['input']>;
  controlNumberGTE?: InputMaybe<Scalars['Int']['input']>;
  controlNumberIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  controlNumberIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  controlNumberLT?: InputMaybe<Scalars['Int']['input']>;
  controlNumberLTE?: InputMaybe<Scalars['Int']['input']>;
  controlNumberNEQ?: InputMaybe<Scalars['Int']['input']>;
  controlNumberNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  controlNumberNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** direction field predicates */
  direction?: InputMaybe<AcatTransferDetailsAcatDirection>;
  directionIn?: InputMaybe<Array<AcatTransferDetailsAcatDirection>>;
  directionNEQ?: InputMaybe<AcatTransferDetailsAcatDirection>;
  directionNotIn?: InputMaybe<Array<AcatTransferDetailsAcatDirection>>;
  /** disable_autosign field predicates */
  disableAutosign?: InputMaybe<Scalars['Boolean']['input']>;
  disableAutosignNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** error field predicates */
  error?: InputMaybe<Scalars['String']['input']>;
  errorContains?: InputMaybe<Scalars['String']['input']>;
  errorContainsFold?: InputMaybe<Scalars['String']['input']>;
  errorEqualFold?: InputMaybe<Scalars['String']['input']>;
  errorGT?: InputMaybe<Scalars['String']['input']>;
  errorGTE?: InputMaybe<Scalars['String']['input']>;
  errorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  errorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  errorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  errorIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  errorLT?: InputMaybe<Scalars['String']['input']>;
  errorLTE?: InputMaybe<Scalars['String']['input']>;
  errorNEQ?: InputMaybe<Scalars['String']['input']>;
  errorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  errorNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** expected_settlement_date field predicates */
  expectedSettlementDate?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateGT?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateGTE?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  expectedSettlementDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  expectedSettlementDateLT?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateLTE?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  expectedSettlementDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** account edge predicates */
  hasAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountWith?: InputMaybe<Array<UserAccountWhereInput>>;
  /** assets edge predicates */
  hasAssets?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssetsWith?: InputMaybe<Array<AcatTransferDetailsAssetWhereInput>>;
  /** portfolio_transfers edge predicates */
  hasPortfolioTransfers?: InputMaybe<Scalars['Boolean']['input']>;
  hasPortfolioTransfersWith?: InputMaybe<Array<AcatTransferWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<AcatTransferDetailsWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<AcatTransferDetailsWhereInput>>;
  /** processing_case_id field predicates */
  processingCaseID?: InputMaybe<Scalars['Int']['input']>;
  processingCaseIDGT?: InputMaybe<Scalars['Int']['input']>;
  processingCaseIDGTE?: InputMaybe<Scalars['Int']['input']>;
  processingCaseIDIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  processingCaseIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  processingCaseIDLT?: InputMaybe<Scalars['Int']['input']>;
  processingCaseIDLTE?: InputMaybe<Scalars['Int']['input']>;
  processingCaseIDNEQ?: InputMaybe<Scalars['Int']['input']>;
  processingCaseIDNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  processingCaseIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** reject_comment field predicates */
  rejectComment?: InputMaybe<Scalars['String']['input']>;
  rejectCommentContains?: InputMaybe<Scalars['String']['input']>;
  rejectCommentContainsFold?: InputMaybe<Scalars['String']['input']>;
  rejectCommentEqualFold?: InputMaybe<Scalars['String']['input']>;
  rejectCommentGT?: InputMaybe<Scalars['String']['input']>;
  rejectCommentGTE?: InputMaybe<Scalars['String']['input']>;
  rejectCommentHasPrefix?: InputMaybe<Scalars['String']['input']>;
  rejectCommentHasSuffix?: InputMaybe<Scalars['String']['input']>;
  rejectCommentIn?: InputMaybe<Array<Scalars['String']['input']>>;
  rejectCommentIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  rejectCommentLT?: InputMaybe<Scalars['String']['input']>;
  rejectCommentLTE?: InputMaybe<Scalars['String']['input']>;
  rejectCommentNEQ?: InputMaybe<Scalars['String']['input']>;
  rejectCommentNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  rejectCommentNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** reject_reason field predicates */
  rejectReason?: InputMaybe<Scalars['String']['input']>;
  rejectReasonContains?: InputMaybe<Scalars['String']['input']>;
  rejectReasonContainsFold?: InputMaybe<Scalars['String']['input']>;
  rejectReasonEqualFold?: InputMaybe<Scalars['String']['input']>;
  rejectReasonGT?: InputMaybe<Scalars['String']['input']>;
  rejectReasonGTE?: InputMaybe<Scalars['String']['input']>;
  rejectReasonHasPrefix?: InputMaybe<Scalars['String']['input']>;
  rejectReasonHasSuffix?: InputMaybe<Scalars['String']['input']>;
  rejectReasonIn?: InputMaybe<Array<Scalars['String']['input']>>;
  rejectReasonIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  rejectReasonLT?: InputMaybe<Scalars['String']['input']>;
  rejectReasonLTE?: InputMaybe<Scalars['String']['input']>;
  rejectReasonNEQ?: InputMaybe<Scalars['String']['input']>;
  rejectReasonNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  rejectReasonNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** request_time field predicates */
  requestTime?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  requestTimeLT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** request_uuid field predicates */
  requestUUID?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  requestUUIDLT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDLTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNEQ?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNotIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** requestor field predicates */
  requestor?: InputMaybe<Requestor>;
  requestorIn?: InputMaybe<Array<Requestor>>;
  requestorNEQ?: InputMaybe<Requestor>;
  requestorNotIn?: InputMaybe<Array<Requestor>>;
  /** status field predicates */
  status?: InputMaybe<AcatTransferDetailsAcatTransferStatus>;
  statusIn?: InputMaybe<Array<AcatTransferDetailsAcatTransferStatus>>;
  statusNEQ?: InputMaybe<AcatTransferDetailsAcatTransferStatus>;
  statusNotIn?: InputMaybe<Array<AcatTransferDetailsAcatTransferStatus>>;
  /** tif_id field predicates */
  tifID?: InputMaybe<Scalars['String']['input']>;
  tifIDContains?: InputMaybe<Scalars['String']['input']>;
  tifIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  tifIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  tifIDGT?: InputMaybe<Scalars['String']['input']>;
  tifIDGTE?: InputMaybe<Scalars['String']['input']>;
  tifIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  tifIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  tifIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  tifIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  tifIDLT?: InputMaybe<Scalars['String']['input']>;
  tifIDLTE?: InputMaybe<Scalars['String']['input']>;
  tifIDNEQ?: InputMaybe<Scalars['String']['input']>;
  tifIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  tifIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** transfer_type field predicates */
  transferType?: InputMaybe<AcatTransferDetailsAcatTransferType>;
  transferTypeIn?: InputMaybe<Array<AcatTransferDetailsAcatTransferType>>;
  transferTypeNEQ?: InputMaybe<AcatTransferDetailsAcatTransferType>;
  transferTypeNotIn?: InputMaybe<Array<AcatTransferDetailsAcatTransferType>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** An edge in a connection. */
export type AcatTransferEdge = {
  __typename?: 'AcatTransferEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<AcatTransfer>;
};

/** Ordering options for AcatTransfer connections */
export type AcatTransferOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order AcatTransfers. */
  field: AcatTransferOrderField;
};

/** Properties by which AcatTransfer connections can be ordered. */
export enum AcatTransferOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

export type AcatTransferStatusChangeLog = {
  __typename?: 'AcatTransferStatusChangeLog';
  eventTime: Scalars['Time']['output'];
  note: Scalars['String']['output'];
  status: AcatTransferAcatTransferStatus;
};

/**
 * AcatTransferWhereInput is used for filtering AcatTransfer objects.
 * Input was generated by ent.
 */
export type AcatTransferWhereInput = {
  and?: InputMaybe<Array<AcatTransferWhereInput>>;
  /** complete_time field predicates */
  completeTime?: InputMaybe<Scalars['Time']['input']>;
  completeTimeGT?: InputMaybe<Scalars['Time']['input']>;
  completeTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  completeTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  completeTimeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  completeTimeLT?: InputMaybe<Scalars['Time']['input']>;
  completeTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  completeTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  completeTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  completeTimeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** direction field predicates */
  direction?: InputMaybe<AcatTransferAcatDirection>;
  directionIn?: InputMaybe<Array<AcatTransferAcatDirection>>;
  directionNEQ?: InputMaybe<AcatTransferAcatDirection>;
  directionNotIn?: InputMaybe<Array<AcatTransferAcatDirection>>;
  /** expected_settlement_date field predicates */
  expectedSettlementDate?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateGT?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateGTE?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  expectedSettlementDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  expectedSettlementDateLT?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateLTE?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  expectedSettlementDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** portfolio edge predicates */
  hasPortfolio?: InputMaybe<Scalars['Boolean']['input']>;
  hasPortfolioWith?: InputMaybe<Array<UserAccountPortfolioWhereInput>>;
  /** transfer_details edge predicates */
  hasTransferDetails?: InputMaybe<Scalars['Boolean']['input']>;
  hasTransferDetailsWith?: InputMaybe<Array<AcatTransferDetailsWhereInput>>;
  /** transfers edge predicates */
  hasTransfers?: InputMaybe<Scalars['Boolean']['input']>;
  hasTransfersWith?: InputMaybe<Array<AcatTransferAssetWhereInput>>;
  hasUserWith?: InputMaybe<UserWhereInput>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<AcatTransferWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<AcatTransferWhereInput>>;
  /** status field predicates */
  status?: InputMaybe<AcatTransferAcatTransferStatus>;
  statusIn?: InputMaybe<Array<AcatTransferAcatTransferStatus>>;
  statusNEQ?: InputMaybe<AcatTransferAcatTransferStatus>;
  statusNotIn?: InputMaybe<Array<AcatTransferAcatTransferStatus>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type AccountChangeHistory = {
  __typename?: 'AccountChangeHistory';
  encumbrance: TimeSeries;
  frozen: TimeSeries;
  liability: TimeSeries;
  pending: TimeSeries;
  pendingLiability: TimeSeries;
  settled: TimeSeries;
};

export type AccountReconAdjustment = BaseOrder &
  Node & {
    __typename?: 'AccountReconAdjustment';
    createTime: Scalars['Time']['output'];
    id: Scalars['ID']['output'];
    /** Notes is the notes of the order intended for the principal. */
    note?: Maybe<Scalars['String']['output']>;
    orderStatus: OrderOrderStatus;
    orderType: OrderOrderType;
    /** The portfolios that this adjustment is associated with */
    portfolios?: Maybe<Array<UserAccountPortfolio>>;
    requestDetails: RequestDetails;
    /** The time at which the action was requested. */
    requestTime: Scalars['Time']['output'];
    requestUUID?: Maybe<Scalars['UUID']['output']>;
    requestor: Requestor;
    updateTime: Scalars['Time']['output'];
    /** The account that this adjustment is associated with */
    userAccount: UserAccount;
  };

/** A connection to a list of items. */
export type AccountReconAdjustmentConnection = {
  __typename?: 'AccountReconAdjustmentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AccountReconAdjustmentEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AccountReconAdjustmentEdge = {
  __typename?: 'AccountReconAdjustmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<AccountReconAdjustment>;
};

/** Ordering options for AccountReconAdjustment connections */
export type AccountReconAdjustmentOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order AccountReconAdjustments. */
  field: AccountReconAdjustmentOrderField;
};

/** Properties by which AccountReconAdjustment connections can be ordered. */
export enum AccountReconAdjustmentOrderField {
  RequestTime = 'REQUEST_TIME',
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * AccountReconAdjustmentWhereInput is used for filtering AccountReconAdjustment objects.
 * Input was generated by ent.
 */
export type AccountReconAdjustmentWhereInput = {
  and?: InputMaybe<Array<AccountReconAdjustmentWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** portfolios edge predicates */
  hasPortfolios?: InputMaybe<Scalars['Boolean']['input']>;
  hasPortfoliosWith?: InputMaybe<Array<UserAccountPortfolioWhereInput>>;
  /** user_account edge predicates */
  hasUserAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserAccountWith?: InputMaybe<Array<UserAccountWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<AccountReconAdjustmentWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<AccountReconAdjustmentWhereInput>>;
  /** request_time field predicates */
  requestTime?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  requestTimeLT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** request_uuid field predicates */
  requestUUID?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  requestUUIDLT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDLTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNEQ?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNotIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** requestor field predicates */
  requestor?: InputMaybe<Requestor>;
  requestorIn?: InputMaybe<Array<Requestor>>;
  requestorNEQ?: InputMaybe<Requestor>;
  requestorNotIn?: InputMaybe<Array<Requestor>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type AccountTransferForm = {
  __typename?: 'AccountTransferForm';
  documentID?: Maybe<Scalars['String']['output']>;
};

export type AccountTypeCustodian = Node & {
  __typename?: 'AccountTypeCustodian';
  /** Apex account type this branch record is for */
  accountType: AccountTypeCustodianAccountType;
  id: Scalars['ID']['output'];
};

/** AccountTypeCustodianAccountType is enum for the field account_type */
export enum AccountTypeCustodianAccountType {
  AccountTypeBrokerage = 'ACCOUNT_TYPE_BROKERAGE',
  AccountTypeBrokerageDiscretionary = 'ACCOUNT_TYPE_BROKERAGE_DISCRETIONARY',
  AccountTypeIra = 'ACCOUNT_TYPE_IRA',
  AccountTypeIraDiscretionary = 'ACCOUNT_TYPE_IRA_DISCRETIONARY',
  AccountTypeRothIra = 'ACCOUNT_TYPE_ROTH_IRA',
  AccountTypeRothIraDiscretionary = 'ACCOUNT_TYPE_ROTH_IRA_DISCRETIONARY',
}

/**
 * AccountTypeCustodianWhereInput is used for filtering AccountTypeCustodian objects.
 * Input was generated by ent.
 */
export type AccountTypeCustodianWhereInput = {
  /** account_type field predicates */
  accountType?: InputMaybe<AccountTypeCustodianAccountType>;
  accountTypeIn?: InputMaybe<Array<AccountTypeCustodianAccountType>>;
  accountTypeNEQ?: InputMaybe<AccountTypeCustodianAccountType>;
  accountTypeNotIn?: InputMaybe<Array<AccountTypeCustodianAccountType>>;
  and?: InputMaybe<Array<AccountTypeCustodianWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<AccountTypeCustodianWhereInput>;
  or?: InputMaybe<Array<AccountTypeCustodianWhereInput>>;
};

export type AddDocumentSetRequest = {
  __typename?: 'AddDocumentSetRequest';
  document?: Maybe<Document>;
};

export type Admin = {
  __typename?: 'Admin';
  createTime: Scalars['Time']['output'];
  email: Scalars['String']['output'];
  externalID: Scalars['String']['output'];
  /** The first name of the admin user */
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** The last name of the admin user */
  lastName?: Maybe<Scalars['String']['output']>;
  /** If the admin can act as a broker dealer principal */
  principal?: Maybe<BrokerDealerPrincipal>;
  updateTime: Scalars['Time']['output'];
};

export type AdminAction = Node & {
  __typename?: 'AdminAction';
  /** This is the action that an admin has taken on a user's account */
  adminActionType: AdminActionAdminActionType;
  /** The email of the admin who took the action */
  adminEmail: Scalars['String']['output'];
  /** The name of the admin who took the action */
  adminFirstName?: Maybe<Scalars['String']['output']>;
  adminID: Scalars['ID']['output'];
  /** The name of the admin who took the action */
  adminLastName?: Maybe<Scalars['String']['output']>;
  apexAccountReqID?: Maybe<Scalars['ID']['output']>;
  /** The account request that actions are being taken on behalf of. */
  apexAccountRequest?: Maybe<ApexAccountRequest>;
  /** The investigation that actions are being taken on behalf of. */
  apexInvestigation?: Maybe<ApexAccountInvestigation>;
  apexInvestigationID?: Maybe<Scalars['ID']['output']>;
  /** The apex meta that actions are being taken on behalf of. */
  apexMeta?: Maybe<ApexAccountMeta>;
  apexMetaID?: Maybe<Scalars['ID']['output']>;
  createTime: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  /** A note about the action taken by the admin. This is optional because many actions are self-explanatory */
  note?: Maybe<Scalars['String']['output']>;
  /** If the admin is a principal, this is their CRD number */
  principalCrdNumber?: Maybe<Scalars['String']['output']>;
  updateTime: Scalars['Time']['output'];
  /** The user that actions are being taken on behalf of. */
  user: User;
  /** The user account that actions are being taken on behalf of. */
  userAccount?: Maybe<UserAccount>;
  userAccountID?: Maybe<Scalars['ID']['output']>;
  userID: Scalars['ID']['output'];
};

/** AdminActionAdminActionType is enum for the field admin_action_type */
export enum AdminActionAdminActionType {
  AdminActionTypeAccountRequestAccept = 'ADMIN_ACTION_TYPE_ACCOUNT_REQUEST_ACCEPT',
  AdminActionTypeAccountRequestAppeal = 'ADMIN_ACTION_TYPE_ACCOUNT_REQUEST_APPEAL',
  AdminActionTypeAccountRequestContact = 'ADMIN_ACTION_TYPE_ACCOUNT_REQUEST_CONTACT',
  AdminActionTypeAccountRequestDeny = 'ADMIN_ACTION_TYPE_ACCOUNT_REQUEST_DENY',
  AdminActionTypeAccountRequestResubmit = 'ADMIN_ACTION_TYPE_ACCOUNT_REQUEST_RESUBMIT',
  AdminActionTypeAccountRequestSubmit = 'ADMIN_ACTION_TYPE_ACCOUNT_REQUEST_SUBMIT',
  AdminActionTypeAccountRequestUpdate = 'ADMIN_ACTION_TYPE_ACCOUNT_REQUEST_UPDATE',
  AdminActionTypeAccountUploadDocument = 'ADMIN_ACTION_TYPE_ACCOUNT_UPLOAD_DOCUMENT',
  AdminActionTypeReviewProgramEnrollment = 'ADMIN_ACTION_TYPE_REVIEW_PROGRAM_ENROLLMENT',
  AdminActionTypeUserAccountClose = 'ADMIN_ACTION_TYPE_USER_ACCOUNT_CLOSE',
  AdminActionTypeUserAccountRemoveTrustedContact = 'ADMIN_ACTION_TYPE_USER_ACCOUNT_REMOVE_TRUSTED_CONTACT',
  AdminActionTypeUserAccountUpdate = 'ADMIN_ACTION_TYPE_USER_ACCOUNT_UPDATE',
  AdminActionTypeUserAccountUpdateTags = 'ADMIN_ACTION_TYPE_USER_ACCOUNT_UPDATE_TAGS',
  AdminActionTypeUserCreate = 'ADMIN_ACTION_TYPE_USER_CREATE',
  AdminActionTypeUserCreateExternalAccount = 'ADMIN_ACTION_TYPE_USER_CREATE_EXTERNAL_ACCOUNT',
  AdminActionTypeUserUpdateProfile = 'ADMIN_ACTION_TYPE_USER_UPDATE_PROFILE',
  AdminActionTypeUserUpdateRestriction = 'ADMIN_ACTION_TYPE_USER_UPDATE_RESTRICTION',
}

/** Ordering options for AdminAction connections */
export type AdminActionOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order AdminActions. */
  field: AdminActionOrderField;
};

/** Properties by which AdminAction connections can be ordered. */
export enum AdminActionOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * AdminActionWhereInput is used for filtering AdminAction objects.
 * Input was generated by ent.
 */
export type AdminActionWhereInput = {
  /** admin_action_type field predicates */
  adminActionType?: InputMaybe<AdminActionAdminActionType>;
  adminActionTypeIn?: InputMaybe<Array<AdminActionAdminActionType>>;
  adminActionTypeNEQ?: InputMaybe<AdminActionAdminActionType>;
  adminActionTypeNotIn?: InputMaybe<Array<AdminActionAdminActionType>>;
  /** admin_email field predicates */
  adminEmail?: InputMaybe<Scalars['String']['input']>;
  adminEmailContains?: InputMaybe<Scalars['String']['input']>;
  adminEmailContainsFold?: InputMaybe<Scalars['String']['input']>;
  adminEmailEqualFold?: InputMaybe<Scalars['String']['input']>;
  adminEmailGT?: InputMaybe<Scalars['String']['input']>;
  adminEmailGTE?: InputMaybe<Scalars['String']['input']>;
  adminEmailHasPrefix?: InputMaybe<Scalars['String']['input']>;
  adminEmailHasSuffix?: InputMaybe<Scalars['String']['input']>;
  adminEmailIn?: InputMaybe<Array<Scalars['String']['input']>>;
  adminEmailLT?: InputMaybe<Scalars['String']['input']>;
  adminEmailLTE?: InputMaybe<Scalars['String']['input']>;
  adminEmailNEQ?: InputMaybe<Scalars['String']['input']>;
  adminEmailNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** admin_first_name field predicates */
  adminFirstName?: InputMaybe<Scalars['String']['input']>;
  adminFirstNameContains?: InputMaybe<Scalars['String']['input']>;
  adminFirstNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  adminFirstNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  adminFirstNameGT?: InputMaybe<Scalars['String']['input']>;
  adminFirstNameGTE?: InputMaybe<Scalars['String']['input']>;
  adminFirstNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  adminFirstNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  adminFirstNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  adminFirstNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  adminFirstNameLT?: InputMaybe<Scalars['String']['input']>;
  adminFirstNameLTE?: InputMaybe<Scalars['String']['input']>;
  adminFirstNameNEQ?: InputMaybe<Scalars['String']['input']>;
  adminFirstNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  adminFirstNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** admin_id field predicates */
  adminID?: InputMaybe<Scalars['ID']['input']>;
  adminIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  adminIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  adminIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** admin_last_name field predicates */
  adminLastName?: InputMaybe<Scalars['String']['input']>;
  adminLastNameContains?: InputMaybe<Scalars['String']['input']>;
  adminLastNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  adminLastNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  adminLastNameGT?: InputMaybe<Scalars['String']['input']>;
  adminLastNameGTE?: InputMaybe<Scalars['String']['input']>;
  adminLastNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  adminLastNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  adminLastNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  adminLastNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  adminLastNameLT?: InputMaybe<Scalars['String']['input']>;
  adminLastNameLTE?: InputMaybe<Scalars['String']['input']>;
  adminLastNameNEQ?: InputMaybe<Scalars['String']['input']>;
  adminLastNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  adminLastNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<AdminActionWhereInput>>;
  /** apex_account_req_id field predicates */
  apexAccountReqID?: InputMaybe<Scalars['ID']['input']>;
  apexAccountReqIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  apexAccountReqIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  apexAccountReqIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  apexAccountReqIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  apexAccountReqIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** apex_investigation_id field predicates */
  apexInvestigationID?: InputMaybe<Scalars['ID']['input']>;
  apexInvestigationIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  apexInvestigationIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  apexInvestigationIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  apexInvestigationIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  apexInvestigationIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** apex_meta_id field predicates */
  apexMetaID?: InputMaybe<Scalars['ID']['input']>;
  apexMetaIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  apexMetaIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  apexMetaIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  apexMetaIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  apexMetaIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** apex_account_request edge predicates */
  hasApexAccountRequest?: InputMaybe<Scalars['Boolean']['input']>;
  hasApexAccountRequestWith?: InputMaybe<Array<ApexAccountRequestWhereInput>>;
  /** apex_investigation edge predicates */
  hasApexInvestigation?: InputMaybe<Scalars['Boolean']['input']>;
  hasApexInvestigationWith?: InputMaybe<Array<ApexAccountInvestigationWhereInput>>;
  /** apex_meta edge predicates */
  hasApexMeta?: InputMaybe<Scalars['Boolean']['input']>;
  hasApexMetaWith?: InputMaybe<Array<ApexAccountMetaWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  /** user_account edge predicates */
  hasUserAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserAccountWith?: InputMaybe<Array<UserAccountWhereInput>>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<AdminActionWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<AdminActionWhereInput>>;
  /** principal_crd_number field predicates */
  principalCrdNumber?: InputMaybe<Scalars['String']['input']>;
  principalCrdNumberContains?: InputMaybe<Scalars['String']['input']>;
  principalCrdNumberContainsFold?: InputMaybe<Scalars['String']['input']>;
  principalCrdNumberEqualFold?: InputMaybe<Scalars['String']['input']>;
  principalCrdNumberGT?: InputMaybe<Scalars['String']['input']>;
  principalCrdNumberGTE?: InputMaybe<Scalars['String']['input']>;
  principalCrdNumberHasPrefix?: InputMaybe<Scalars['String']['input']>;
  principalCrdNumberHasSuffix?: InputMaybe<Scalars['String']['input']>;
  principalCrdNumberIn?: InputMaybe<Array<Scalars['String']['input']>>;
  principalCrdNumberIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  principalCrdNumberLT?: InputMaybe<Scalars['String']['input']>;
  principalCrdNumberLTE?: InputMaybe<Scalars['String']['input']>;
  principalCrdNumberNEQ?: InputMaybe<Scalars['String']['input']>;
  principalCrdNumberNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  principalCrdNumberNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** user_account_id field predicates */
  userAccountID?: InputMaybe<Scalars['ID']['input']>;
  userAccountIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  userAccountIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  userAccountIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  userAccountIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** user_id field predicates */
  userID?: InputMaybe<Scalars['ID']['input']>;
  userIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  userIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  userIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type AdminOrder = BaseOrder &
  Node & {
    __typename?: 'AdminOrder';
    createTime: Scalars['Time']['output'];
    /** Description for order displayed to user */
    description: Scalars['String']['output'];
    eventTime: Scalars['Time']['output'];
    id: Scalars['ID']['output'];
    /** should this order be visible to the user */
    isVisible: Scalars['Boolean']['output'];
    /** Notes is the notes of the order intended for the principal. */
    note?: Maybe<Scalars['String']['output']>;
    orderStatus: OrderOrderStatus;
    orderType: OrderOrderType;
    principal?: Maybe<BrokerDealerPrincipal>;
    requestDetails: RequestDetails;
    /** Title for order displayed to user */
    title: Scalars['String']['output'];
    updateTime: Scalars['Time']['output'];
    user: User;
  };

/** A connection to a list of items. */
export type AdminOrderConnection = {
  __typename?: 'AdminOrderConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AdminOrderEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AdminOrderEdge = {
  __typename?: 'AdminOrderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<AdminOrder>;
};

/** Ordering options for AdminOrder connections */
export type AdminOrderOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order AdminOrders. */
  field: AdminOrderOrderField;
};

/** Properties by which AdminOrder connections can be ordered. */
export enum AdminOrderOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * AdminOrderWhereInput is used for filtering AdminOrder objects.
 * Input was generated by ent.
 */
export type AdminOrderWhereInput = {
  and?: InputMaybe<Array<AdminOrderWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** event_time field predicates */
  eventTime?: InputMaybe<Scalars['Time']['input']>;
  eventTimeGT?: InputMaybe<Scalars['Time']['input']>;
  eventTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  eventTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  eventTimeLT?: InputMaybe<Scalars['Time']['input']>;
  eventTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  eventTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  eventTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** principal edge predicates */
  hasPrincipal?: InputMaybe<Scalars['Boolean']['input']>;
  hasPrincipalWith?: InputMaybe<Array<BrokerDealerPrincipalWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_visible field predicates */
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  isVisibleNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<AdminOrderWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<AdminOrderWhereInput>>;
  /** title field predicates */
  title?: InputMaybe<Scalars['String']['input']>;
  titleContains?: InputMaybe<Scalars['String']['input']>;
  titleContainsFold?: InputMaybe<Scalars['String']['input']>;
  titleEqualFold?: InputMaybe<Scalars['String']['input']>;
  titleGT?: InputMaybe<Scalars['String']['input']>;
  titleGTE?: InputMaybe<Scalars['String']['input']>;
  titleHasPrefix?: InputMaybe<Scalars['String']['input']>;
  titleHasSuffix?: InputMaybe<Scalars['String']['input']>;
  titleIn?: InputMaybe<Array<Scalars['String']['input']>>;
  titleLT?: InputMaybe<Scalars['String']['input']>;
  titleLTE?: InputMaybe<Scalars['String']['input']>;
  titleNEQ?: InputMaybe<Scalars['String']['input']>;
  titleNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/**
 * AdminWhereInput is used for filtering Admin objects.
 * Input was generated by ent.
 */
export type AdminWhereInput = {
  and?: InputMaybe<Array<AdminWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** email field predicates */
  email?: InputMaybe<Scalars['String']['input']>;
  emailContains?: InputMaybe<Scalars['String']['input']>;
  emailContainsFold?: InputMaybe<Scalars['String']['input']>;
  emailEqualFold?: InputMaybe<Scalars['String']['input']>;
  emailGT?: InputMaybe<Scalars['String']['input']>;
  emailGTE?: InputMaybe<Scalars['String']['input']>;
  emailHasPrefix?: InputMaybe<Scalars['String']['input']>;
  emailHasSuffix?: InputMaybe<Scalars['String']['input']>;
  emailIn?: InputMaybe<Array<Scalars['String']['input']>>;
  emailLT?: InputMaybe<Scalars['String']['input']>;
  emailLTE?: InputMaybe<Scalars['String']['input']>;
  emailNEQ?: InputMaybe<Scalars['String']['input']>;
  emailNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** first_name field predicates */
  firstName?: InputMaybe<Scalars['String']['input']>;
  firstNameContains?: InputMaybe<Scalars['String']['input']>;
  firstNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  firstNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  firstNameGT?: InputMaybe<Scalars['String']['input']>;
  firstNameGTE?: InputMaybe<Scalars['String']['input']>;
  firstNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  firstNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  firstNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  firstNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  firstNameLT?: InputMaybe<Scalars['String']['input']>;
  firstNameLTE?: InputMaybe<Scalars['String']['input']>;
  firstNameNEQ?: InputMaybe<Scalars['String']['input']>;
  firstNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  firstNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** principal edge predicates */
  hasPrincipal?: InputMaybe<Scalars['Boolean']['input']>;
  hasPrincipalWith?: InputMaybe<Array<BrokerDealerPrincipalWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** last_name field predicates */
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastNameContains?: InputMaybe<Scalars['String']['input']>;
  lastNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  lastNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  lastNameGT?: InputMaybe<Scalars['String']['input']>;
  lastNameGTE?: InputMaybe<Scalars['String']['input']>;
  lastNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  lastNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  lastNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  lastNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  lastNameLT?: InputMaybe<Scalars['String']['input']>;
  lastNameLTE?: InputMaybe<Scalars['String']['input']>;
  lastNameNEQ?: InputMaybe<Scalars['String']['input']>;
  lastNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  lastNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<AdminWhereInput>;
  or?: InputMaybe<Array<AdminWhereInput>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type Advisory = Node & {
  __typename?: 'Advisory';
  broker: IntroducingBroker;
  id: Scalars['ID']['output'];
  /** isClearingHouseIB indicates if the introducing broker is the same entity as the clearing house. */
  isClearingHouseIB: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  plaidRelationshipOwner: PlaidRelationshipOwner;
  regulatedEntity: RegulatedEntity;
  /** requiresExtendedOnboarding indicates if the advisory onboarding process requires additional questions. */
  requiresExtendedOnboarding: Scalars['Boolean']['output'];
};

/**
 * AdvisoryWhereInput is used for filtering Advisory objects.
 * Input was generated by ent.
 */
export type AdvisoryWhereInput = {
  and?: InputMaybe<Array<AdvisoryWhereInput>>;
  /** broker edge predicates */
  hasBroker?: InputMaybe<Scalars['Boolean']['input']>;
  hasBrokerWith?: InputMaybe<Array<IntroducingBrokerWhereInput>>;
  /** plaid_relationship_owner edge predicates */
  hasPlaidRelationshipOwner?: InputMaybe<Scalars['Boolean']['input']>;
  hasPlaidRelationshipOwnerWith?: InputMaybe<Array<PlaidRelationshipOwnerWhereInput>>;
  /** regulated_entity edge predicates */
  hasRegulatedEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasRegulatedEntityWith?: InputMaybe<Array<RegulatedEntityWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<AdvisoryWhereInput>;
  or?: InputMaybe<Array<AdvisoryWhereInput>>;
};

export type AffiliatedApproval = {
  __typename?: 'AffiliatedApproval';
  documentID?: Maybe<Scalars['String']['output']>;
};

export type AffiliatedExchangeOrFinraDetails = {
  __typename?: 'AffiliatedExchangeOrFINRADetails';
  /** @deprecated Use `firmName`. */
  FirmName: Scalars['String']['output'];
  firmName?: Maybe<Scalars['String']['output']>;
  isAffiliated: Scalars['Boolean']['output'];
  isAffiliatedDiscretionaryRegulatedEntity: Scalars['Boolean']['output'];
  isAffiliatedNonDiscretionaryRegulatedEntity: Scalars['Boolean']['output'];
};

export type ApexAccountFile = Node & {
  __typename?: 'ApexAccountFile';
  apexFile?: Maybe<Array<ApexFiles>>;
  createTime: Scalars['Time']['output'];
  /** Type of the document */
  documentType: ApexAccountFileAccountDocumentType;
  /** External id of the file once is uploaded to apex */
  externalID?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** reason in case of rejection from Apex */
  reason?: Maybe<Scalars['String']['output']>;
  /** Tag of the document */
  status: ApexAccountFileAccountDocumentStatus;
  updateTime: Scalars['Time']['output'];
};

/** ApexAccountFileAccountDocumentStatus is enum for the field status */
export enum ApexAccountFileAccountDocumentStatus {
  AccountDocumentStatusCompleted = 'ACCOUNT_DOCUMENT_STATUS_COMPLETED',
  AccountDocumentStatusError = 'ACCOUNT_DOCUMENT_STATUS_ERROR',
  AccountDocumentStatusPending = 'ACCOUNT_DOCUMENT_STATUS_PENDING',
}

/** ApexAccountFileAccountDocumentType is enum for the field document_type */
export enum ApexAccountFileAccountDocumentType {
  AccountDocumentTypeAccountTransferForm = 'ACCOUNT_DOCUMENT_TYPE_ACCOUNT_TRANSFER_FORM',
  AccountDocumentTypeAffiliatedApproval = 'ACCOUNT_DOCUMENT_TYPE_AFFILIATED_APPROVAL',
  AccountDocumentTypeDepositExplanation = 'ACCOUNT_DOCUMENT_TYPE_DEPOSIT_EXPLANATION',
  AccountDocumentTypeDriversLicense = 'ACCOUNT_DOCUMENT_TYPE_DRIVERS_LICENSE',
  AccountDocumentTypeIdentificationCard = 'ACCOUNT_DOCUMENT_TYPE_IDENTIFICATION_CARD',
  AccountDocumentTypeIraDepositSlip = 'ACCOUNT_DOCUMENT_TYPE_IRA_DEPOSIT_SLIP',
  AccountDocumentTypeLimitedMarginAgreement = 'ACCOUNT_DOCUMENT_TYPE_LIMITED_MARGIN_AGREEMENT',
  AccountDocumentTypeNationalIdCard = 'ACCOUNT_DOCUMENT_TYPE_NATIONAL_ID_CARD',
  AccountDocumentTypeOther = 'ACCOUNT_DOCUMENT_TYPE_OTHER',
  AccountDocumentTypePassport = 'ACCOUNT_DOCUMENT_TYPE_PASSPORT',
  AccountDocumentTypeSignatureImage = 'ACCOUNT_DOCUMENT_TYPE_SIGNATURE_IMAGE',
  AccountDocumentTypeSocialSecurityCard = 'ACCOUNT_DOCUMENT_TYPE_SOCIAL_SECURITY_CARD',
  AccountDocumentTypeStateIdCard = 'ACCOUNT_DOCUMENT_TYPE_STATE_ID_CARD',
  AccountDocumentTypeTransferOnDeathAgreement = 'ACCOUNT_DOCUMENT_TYPE_TRANSFER_ON_DEATH_AGREEMENT',
}

/** Ordering options for ApexAccountFile connections */
export type ApexAccountFileOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ApexAccountFiles. */
  field: ApexAccountFileOrderField;
};

/** Properties by which ApexAccountFile connections can be ordered. */
export enum ApexAccountFileOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * ApexAccountFileWhereInput is used for filtering ApexAccountFile objects.
 * Input was generated by ent.
 */
export type ApexAccountFileWhereInput = {
  and?: InputMaybe<Array<ApexAccountFileWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** document_type field predicates */
  documentType?: InputMaybe<ApexAccountFileAccountDocumentType>;
  documentTypeIn?: InputMaybe<Array<ApexAccountFileAccountDocumentType>>;
  documentTypeNEQ?: InputMaybe<ApexAccountFileAccountDocumentType>;
  documentTypeNotIn?: InputMaybe<Array<ApexAccountFileAccountDocumentType>>;
  /** external_id field predicates */
  externalID?: InputMaybe<Scalars['String']['input']>;
  externalIDContains?: InputMaybe<Scalars['String']['input']>;
  externalIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  externalIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  externalIDGT?: InputMaybe<Scalars['String']['input']>;
  externalIDGTE?: InputMaybe<Scalars['String']['input']>;
  externalIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  externalIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  externalIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  externalIDLT?: InputMaybe<Scalars['String']['input']>;
  externalIDLTE?: InputMaybe<Scalars['String']['input']>;
  externalIDNEQ?: InputMaybe<Scalars['String']['input']>;
  externalIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** apex_file edge predicates */
  hasApexFile?: InputMaybe<Scalars['Boolean']['input']>;
  hasApexFileWith?: InputMaybe<Array<ApexFilesWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<ApexAccountFileWhereInput>;
  or?: InputMaybe<Array<ApexAccountFileWhereInput>>;
  /** reason field predicates */
  reason?: InputMaybe<Scalars['String']['input']>;
  reasonContains?: InputMaybe<Scalars['String']['input']>;
  reasonContainsFold?: InputMaybe<Scalars['String']['input']>;
  reasonEqualFold?: InputMaybe<Scalars['String']['input']>;
  reasonGT?: InputMaybe<Scalars['String']['input']>;
  reasonGTE?: InputMaybe<Scalars['String']['input']>;
  reasonHasPrefix?: InputMaybe<Scalars['String']['input']>;
  reasonHasSuffix?: InputMaybe<Scalars['String']['input']>;
  reasonIn?: InputMaybe<Array<Scalars['String']['input']>>;
  reasonIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  reasonLT?: InputMaybe<Scalars['String']['input']>;
  reasonLTE?: InputMaybe<Scalars['String']['input']>;
  reasonNEQ?: InputMaybe<Scalars['String']['input']>;
  reasonNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  reasonNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** status field predicates */
  status?: InputMaybe<ApexAccountFileAccountDocumentStatus>;
  statusIn?: InputMaybe<Array<ApexAccountFileAccountDocumentStatus>>;
  statusNEQ?: InputMaybe<ApexAccountFileAccountDocumentStatus>;
  statusNotIn?: InputMaybe<Array<ApexAccountFileAccountDocumentStatus>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type ApexAccountInvestigation = Node & {
  __typename?: 'ApexAccountInvestigation';
  adminActions?: Maybe<Array<AdminAction>>;
  /** Apex Meta for this investigation */
  apexAccountMeta: ApexAccountMeta;
  /** Apex Account Request for this investigation */
  apexAccountRequest?: Maybe<ApexAccountRequest>;
  apexInvestigationFiles?: Maybe<Array<ApexInvestigationFile>>;
  createTime: Scalars['Time']['output'];
  /** Base64 encoded JSON details for the investigation */
  detailsBase64?: Maybe<Scalars['String']['output']>;
  /** External Request ID for the account application submitted to Apex */
  externalRequestID: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Status for User's Apex Account investigation. This is directly given to us by Apex. */
  investigationStatus: ApexAccountInvestigationApexInvestigationStatus;
  updateTime: Scalars['Time']['output'];
};

/** ApexAccountInvestigationApexInvestigationStatus is enum for the field investigation_status */
export enum ApexAccountInvestigationApexInvestigationStatus {
  ApexInvestigationStatusAccepted = 'APEX_INVESTIGATION_STATUS_ACCEPTED',
  ApexInvestigationStatusAppealed = 'APEX_INVESTIGATION_STATUS_APPEALED',
  ApexInvestigationStatusCanceled = 'APEX_INVESTIGATION_STATUS_CANCELED',
  ApexInvestigationStatusError = 'APEX_INVESTIGATION_STATUS_ERROR',
  ApexInvestigationStatusIndeterminate = 'APEX_INVESTIGATION_STATUS_INDETERMINATE',
  ApexInvestigationStatusPending = 'APEX_INVESTIGATION_STATUS_PENDING',
  ApexInvestigationStatusRejected = 'APEX_INVESTIGATION_STATUS_REJECTED',
}

/** Ordering options for ApexAccountInvestigation connections */
export type ApexAccountInvestigationOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ApexAccountInvestigations. */
  field: ApexAccountInvestigationOrderField;
};

/** Properties by which ApexAccountInvestigation connections can be ordered. */
export enum ApexAccountInvestigationOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * ApexAccountInvestigationWhereInput is used for filtering ApexAccountInvestigation objects.
 * Input was generated by ent.
 */
export type ApexAccountInvestigationWhereInput = {
  and?: InputMaybe<Array<ApexAccountInvestigationWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** details_base64 field predicates */
  detailsBase64?: InputMaybe<Scalars['String']['input']>;
  detailsBase64Contains?: InputMaybe<Scalars['String']['input']>;
  detailsBase64ContainsFold?: InputMaybe<Scalars['String']['input']>;
  detailsBase64EqualFold?: InputMaybe<Scalars['String']['input']>;
  detailsBase64GT?: InputMaybe<Scalars['String']['input']>;
  detailsBase64GTE?: InputMaybe<Scalars['String']['input']>;
  detailsBase64HasPrefix?: InputMaybe<Scalars['String']['input']>;
  detailsBase64HasSuffix?: InputMaybe<Scalars['String']['input']>;
  detailsBase64In?: InputMaybe<Array<Scalars['String']['input']>>;
  detailsBase64IsNil?: InputMaybe<Scalars['Boolean']['input']>;
  detailsBase64LT?: InputMaybe<Scalars['String']['input']>;
  detailsBase64LTE?: InputMaybe<Scalars['String']['input']>;
  detailsBase64NEQ?: InputMaybe<Scalars['String']['input']>;
  detailsBase64NotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  detailsBase64NotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** external_request_id field predicates */
  externalRequestID?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDContains?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDGT?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDGTE?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalRequestIDLT?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDLTE?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDNEQ?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** admin_actions edge predicates */
  hasAdminActions?: InputMaybe<Scalars['Boolean']['input']>;
  hasAdminActionsWith?: InputMaybe<Array<AdminActionWhereInput>>;
  /** apex_account_meta edge predicates */
  hasApexAccountMeta?: InputMaybe<Scalars['Boolean']['input']>;
  hasApexAccountMetaWith?: InputMaybe<Array<ApexAccountMetaWhereInput>>;
  /** apex_account_request edge predicates */
  hasApexAccountRequest?: InputMaybe<Scalars['Boolean']['input']>;
  hasApexAccountRequestWith?: InputMaybe<Array<ApexAccountRequestWhereInput>>;
  /** apex_investigation_files edge predicates */
  hasApexInvestigationFiles?: InputMaybe<Scalars['Boolean']['input']>;
  hasApexInvestigationFilesWith?: InputMaybe<Array<ApexInvestigationFileWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** investigation_status field predicates */
  investigationStatus?: InputMaybe<ApexAccountInvestigationApexInvestigationStatus>;
  investigationStatusIn?: InputMaybe<Array<ApexAccountInvestigationApexInvestigationStatus>>;
  investigationStatusNEQ?: InputMaybe<ApexAccountInvestigationApexInvestigationStatus>;
  investigationStatusNotIn?: InputMaybe<Array<ApexAccountInvestigationApexInvestigationStatus>>;
  not?: InputMaybe<ApexAccountInvestigationWhereInput>;
  or?: InputMaybe<Array<ApexAccountInvestigationWhereInput>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type ApexAccountMeta = Node & {
  __typename?: 'ApexAccountMeta';
  account: UserAccount;
  adminActions?: Maybe<Array<AdminAction>>;
  /** Apex Account Investigations for this account */
  apexAccountInvestigations?: Maybe<Array<ApexAccountInvestigation>>;
  /** Apex Account Update Queue for this account */
  apexAccountUpdateQueue?: Maybe<Array<ApexAccountUpdateQueue>>;
  /** Status for User's Apex Account. This is directly given to us by Apex. */
  applicationStatus: ApexAccountMetaApexApplicationStatus;
  /** Status for User's Apex Account. This is directly given to us by Apex. New represents an account that's not submitted to Apex yet. */
  applicationStatusOld: ApexAccountMetaApplicationStatusOld;
  createTime: Scalars['Time']['output'];
  /** External account identifier if the account is an external account */
  externalID: Scalars['String']['output'];
  /** External Request ID for the account application submitted to Apex */
  externalRequestID?: Maybe<Scalars['String']['output']>;
  /** CAT reporting for the Account Holder Type/If the account is affiliated with Finra/Exchange */
  holderType: ApexAccountMetaHolderType;
  id: Scalars['ID']['output'];
  updateTime: Scalars['Time']['output'];
  user: User;
};

/** ApexAccountMetaApexApplicationStatus is enum for the field application_status */
export enum ApexAccountMetaApexApplicationStatus {
  ApexApplicationStatusAccountSetup = 'APEX_APPLICATION_STATUS_ACCOUNT_SETUP',
  ApexApplicationStatusActionRequired = 'APEX_APPLICATION_STATUS_ACTION_REQUIRED',
  ApexApplicationStatusBackOffice = 'APEX_APPLICATION_STATUS_BACK_OFFICE',
  ApexApplicationStatusCanceled = 'APEX_APPLICATION_STATUS_CANCELED',
  ApexApplicationStatusComplete = 'APEX_APPLICATION_STATUS_COMPLETE',
  ApexApplicationStatusError = 'APEX_APPLICATION_STATUS_ERROR',
  ApexApplicationStatusInvestigationSubmitted = 'APEX_APPLICATION_STATUS_INVESTIGATION_SUBMITTED',
  ApexApplicationStatusNew = 'APEX_APPLICATION_STATUS_NEW',
  ApexApplicationStatusPending = 'APEX_APPLICATION_STATUS_PENDING',
  ApexApplicationStatusReadyForBackOffice = 'APEX_APPLICATION_STATUS_READY_FOR_BACK_OFFICE',
  ApexApplicationStatusRejected = 'APEX_APPLICATION_STATUS_REJECTED',
  ApexApplicationStatusSuspended = 'APEX_APPLICATION_STATUS_SUSPENDED',
  ApexApplicationStatusVendorRequestSubmitted = 'APEX_APPLICATION_STATUS_VENDOR_REQUEST_SUBMITTED',
}

/** ApexAccountMetaApplicationStatusOld is enum for the field application_status_old */
export enum ApexAccountMetaApplicationStatusOld {
  AccountSetup = 'ACCOUNT_SETUP',
  ActionRequired = 'ACTION_REQUIRED',
  BackOffice = 'BACK_OFFICE',
  Canceled = 'CANCELED',
  Closed = 'CLOSED',
  Complete = 'COMPLETE',
  Error = 'ERROR',
  InvestigationSubmitted = 'INVESTIGATION_SUBMITTED',
  New = 'NEW',
  Pending = 'PENDING',
  ReadyForBackOffice = 'READY_FOR_BACK_OFFICE',
  Rejected = 'REJECTED',
  Suspended = 'SUSPENDED',
}

/** ApexAccountMetaHolderType is enum for the field holder_type */
export enum ApexAccountMetaHolderType {
  Employee = 'EMPLOYEE',
  Error = 'ERROR',
  Foreign = 'FOREIGN',
  Individual = 'INDIVIDUAL',
  Institutional = 'INSTITUTIONAL',
  MarketMaking = 'MARKET_MAKING',
  Proprietary = 'PROPRIETARY',
}

/** Ordering options for ApexAccountMeta connections */
export type ApexAccountMetaOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ApexAccountMetaSlice. */
  field: ApexAccountMetaOrderField;
};

/** Properties by which ApexAccountMeta connections can be ordered. */
export enum ApexAccountMetaOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * ApexAccountMetaWhereInput is used for filtering ApexAccountMeta objects.
 * Input was generated by ent.
 */
export type ApexAccountMetaWhereInput = {
  and?: InputMaybe<Array<ApexAccountMetaWhereInput>>;
  /** application_status field predicates */
  applicationStatus?: InputMaybe<ApexAccountMetaApexApplicationStatus>;
  applicationStatusIn?: InputMaybe<Array<ApexAccountMetaApexApplicationStatus>>;
  applicationStatusNEQ?: InputMaybe<ApexAccountMetaApexApplicationStatus>;
  applicationStatusNotIn?: InputMaybe<Array<ApexAccountMetaApexApplicationStatus>>;
  /** application_status_old field predicates */
  applicationStatusOld?: InputMaybe<ApexAccountMetaApplicationStatusOld>;
  applicationStatusOldIn?: InputMaybe<Array<ApexAccountMetaApplicationStatusOld>>;
  applicationStatusOldNEQ?: InputMaybe<ApexAccountMetaApplicationStatusOld>;
  applicationStatusOldNotIn?: InputMaybe<Array<ApexAccountMetaApplicationStatusOld>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** external_id field predicates */
  externalID?: InputMaybe<Scalars['String']['input']>;
  externalIDContains?: InputMaybe<Scalars['String']['input']>;
  externalIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  externalIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  externalIDGT?: InputMaybe<Scalars['String']['input']>;
  externalIDGTE?: InputMaybe<Scalars['String']['input']>;
  externalIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  externalIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  externalIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalIDLT?: InputMaybe<Scalars['String']['input']>;
  externalIDLTE?: InputMaybe<Scalars['String']['input']>;
  externalIDNEQ?: InputMaybe<Scalars['String']['input']>;
  externalIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** external_request_id field predicates */
  externalRequestID?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDContains?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDGT?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDGTE?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalRequestIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  externalRequestIDLT?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDLTE?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDNEQ?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalRequestIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** account edge predicates */
  hasAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountWith?: InputMaybe<Array<UserAccountWhereInput>>;
  /** admin_actions edge predicates */
  hasAdminActions?: InputMaybe<Scalars['Boolean']['input']>;
  hasAdminActionsWith?: InputMaybe<Array<AdminActionWhereInput>>;
  /** apex_account_investigations edge predicates */
  hasApexAccountInvestigations?: InputMaybe<Scalars['Boolean']['input']>;
  hasApexAccountInvestigationsWith?: InputMaybe<Array<ApexAccountInvestigationWhereInput>>;
  /** apex_account_update_queue edge predicates */
  hasApexAccountUpdateQueue?: InputMaybe<Scalars['Boolean']['input']>;
  hasApexAccountUpdateQueueWith?: InputMaybe<Array<ApexAccountUpdateQueueWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** holder_type field predicates */
  holderType?: InputMaybe<ApexAccountMetaHolderType>;
  holderTypeIn?: InputMaybe<Array<ApexAccountMetaHolderType>>;
  holderTypeNEQ?: InputMaybe<ApexAccountMetaHolderType>;
  holderTypeNotIn?: InputMaybe<Array<ApexAccountMetaHolderType>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<ApexAccountMetaWhereInput>;
  or?: InputMaybe<Array<ApexAccountMetaWhereInput>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type ApexAccountRequest = Node & {
  __typename?: 'ApexAccountRequest';
  adminActions?: Maybe<Array<AdminAction>>;
  /** Apex account id for the account created through this request */
  apexAccountID?: Maybe<Scalars['String']['output']>;
  /** Apex Account Investigations for this request */
  apexAccountInvestigations?: Maybe<Array<ApexAccountInvestigation>>;
  createTime: Scalars['Time']['output'];
  /** Request ID generated on Apex's end and provided in the response */
  externalRequestID?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Reason for the account request failure */
  reason?: Maybe<Scalars['String']['output']>;
  /** Apex account request */
  request: Scalars['Map']['output'];
  /** Request ID generated on our end and provided in the request */
  requestID: Scalars['String']['output'];
  /** Status for the Request */
  requestStatus: ApexAccountRequestApexAccountRequestStatus;
  /** Apex's response for the submitted request */
  response?: Maybe<Scalars['Map']['output']>;
  updateTime: Scalars['Time']['output'];
  /** User Account for this Account Request.User Account must exist before creating an Account Request */
  userAccount: UserAccount;
};

/** ApexAccountRequestApexAccountRequestStatus is enum for the field request_status */
export enum ApexAccountRequestApexAccountRequestStatus {
  ApexAccountRequestStatusCanceled = 'APEX_ACCOUNT_REQUEST_STATUS_CANCELED',
  ApexAccountRequestStatusCompleted = 'APEX_ACCOUNT_REQUEST_STATUS_COMPLETED',
  ApexAccountRequestStatusEditedAndResubmitted = 'APEX_ACCOUNT_REQUEST_STATUS_EDITED_AND_RESUBMITTED',
  ApexAccountRequestStatusFailed = 'APEX_ACCOUNT_REQUEST_STATUS_FAILED',
  ApexAccountRequestStatusPending = 'APEX_ACCOUNT_REQUEST_STATUS_PENDING',
}

/** A connection to a list of items. */
export type ApexAccountRequestConnection = {
  __typename?: 'ApexAccountRequestConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ApexAccountRequestEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ApexAccountRequestEdge = {
  __typename?: 'ApexAccountRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ApexAccountRequest>;
};

/** Ordering options for ApexAccountRequest connections */
export type ApexAccountRequestOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ApexAccountRequests. */
  field: ApexAccountRequestOrderField;
};

/** Properties by which ApexAccountRequest connections can be ordered. */
export enum ApexAccountRequestOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * ApexAccountRequestWhereInput is used for filtering ApexAccountRequest objects.
 * Input was generated by ent.
 */
export type ApexAccountRequestWhereInput = {
  and?: InputMaybe<Array<ApexAccountRequestWhereInput>>;
  /** apex_account_id field predicates */
  apexAccountID?: InputMaybe<Scalars['String']['input']>;
  apexAccountIDContains?: InputMaybe<Scalars['String']['input']>;
  apexAccountIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  apexAccountIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  apexAccountIDGT?: InputMaybe<Scalars['String']['input']>;
  apexAccountIDGTE?: InputMaybe<Scalars['String']['input']>;
  apexAccountIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  apexAccountIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  apexAccountIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  apexAccountIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  apexAccountIDLT?: InputMaybe<Scalars['String']['input']>;
  apexAccountIDLTE?: InputMaybe<Scalars['String']['input']>;
  apexAccountIDNEQ?: InputMaybe<Scalars['String']['input']>;
  apexAccountIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  apexAccountIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** external_request_id field predicates */
  externalRequestID?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDContains?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDGT?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDGTE?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalRequestIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  externalRequestIDLT?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDLTE?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDNEQ?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalRequestIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** admin_actions edge predicates */
  hasAdminActions?: InputMaybe<Scalars['Boolean']['input']>;
  hasAdminActionsWith?: InputMaybe<Array<AdminActionWhereInput>>;
  /** apex_account_investigations edge predicates */
  hasApexAccountInvestigations?: InputMaybe<Scalars['Boolean']['input']>;
  hasApexAccountInvestigationsWith?: InputMaybe<Array<ApexAccountInvestigationWhereInput>>;
  /** user_account edge predicates */
  hasUserAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserAccountWith?: InputMaybe<Array<UserAccountWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<ApexAccountRequestWhereInput>;
  or?: InputMaybe<Array<ApexAccountRequestWhereInput>>;
  /** reason field predicates */
  reason?: InputMaybe<Scalars['String']['input']>;
  reasonContains?: InputMaybe<Scalars['String']['input']>;
  reasonContainsFold?: InputMaybe<Scalars['String']['input']>;
  reasonEqualFold?: InputMaybe<Scalars['String']['input']>;
  reasonGT?: InputMaybe<Scalars['String']['input']>;
  reasonGTE?: InputMaybe<Scalars['String']['input']>;
  reasonHasPrefix?: InputMaybe<Scalars['String']['input']>;
  reasonHasSuffix?: InputMaybe<Scalars['String']['input']>;
  reasonIn?: InputMaybe<Array<Scalars['String']['input']>>;
  reasonIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  reasonLT?: InputMaybe<Scalars['String']['input']>;
  reasonLTE?: InputMaybe<Scalars['String']['input']>;
  reasonNEQ?: InputMaybe<Scalars['String']['input']>;
  reasonNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  reasonNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** request_id field predicates */
  requestID?: InputMaybe<Scalars['String']['input']>;
  requestIDContains?: InputMaybe<Scalars['String']['input']>;
  requestIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  requestIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  requestIDGT?: InputMaybe<Scalars['String']['input']>;
  requestIDGTE?: InputMaybe<Scalars['String']['input']>;
  requestIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  requestIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  requestIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  requestIDLT?: InputMaybe<Scalars['String']['input']>;
  requestIDLTE?: InputMaybe<Scalars['String']['input']>;
  requestIDNEQ?: InputMaybe<Scalars['String']['input']>;
  requestIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** request_status field predicates */
  requestStatus?: InputMaybe<ApexAccountRequestApexAccountRequestStatus>;
  requestStatusIn?: InputMaybe<Array<ApexAccountRequestApexAccountRequestStatus>>;
  requestStatusNEQ?: InputMaybe<ApexAccountRequestApexAccountRequestStatus>;
  requestStatusNotIn?: InputMaybe<Array<ApexAccountRequestApexAccountRequestStatus>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type ApexAccountUpdateQueue = Node & {
  __typename?: 'ApexAccountUpdateQueue';
  ApexAccountMeta?: Maybe<ApexAccountMeta>;
  /** Apex Meta for this investigation */
  apexAccountMeta?: Maybe<ApexAccountMeta>;
  /** Apex request associated with this request. */
  apexUpdateRequests?: Maybe<ApexUpdateRequest>;
  /** Ascend Meta for this investigation */
  ascendMeta?: Maybe<AscendMeta>;
  ascendMetaID?: Maybe<Scalars['ID']['output']>;
  createTime: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  /** The status of the update request */
  status: ApexAccountUpdateQueueUpdateRequestStatus;
  updateTime: Scalars['Time']['output'];
  /** User Profile Update Request record for this request */
  userProfileUpdateRequest: UserProfileUpdateRequest;
};

/** A connection to a list of items. */
export type ApexAccountUpdateQueueConnection = {
  __typename?: 'ApexAccountUpdateQueueConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ApexAccountUpdateQueueEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ApexAccountUpdateQueueEdge = {
  __typename?: 'ApexAccountUpdateQueueEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ApexAccountUpdateQueue>;
};

/** Ordering options for ApexAccountUpdateQueue connections */
export type ApexAccountUpdateQueueOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ApexAccountUpdateQueues. */
  field: ApexAccountUpdateQueueOrderField;
};

/** Properties by which ApexAccountUpdateQueue connections can be ordered. */
export enum ApexAccountUpdateQueueOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/** ApexAccountUpdateQueueUpdateRequestStatus is enum for the field status */
export enum ApexAccountUpdateQueueUpdateRequestStatus {
  UpdateRequestStatusApproved = 'UPDATE_REQUEST_STATUS_APPROVED',
  UpdateRequestStatusContacated = 'UPDATE_REQUEST_STATUS_CONTACATED',
  UpdateRequestStatusPending = 'UPDATE_REQUEST_STATUS_PENDING',
  UpdateRequestStatusRejected = 'UPDATE_REQUEST_STATUS_REJECTED',
  UpdateRequestStatusSubmitted = 'UPDATE_REQUEST_STATUS_SUBMITTED',
}

/**
 * ApexAccountUpdateQueueWhereInput is used for filtering ApexAccountUpdateQueue objects.
 * Input was generated by ent.
 */
export type ApexAccountUpdateQueueWhereInput = {
  and?: InputMaybe<Array<ApexAccountUpdateQueueWhereInput>>;
  /** ascend_meta_id field predicates */
  ascendMetaID?: InputMaybe<Scalars['ID']['input']>;
  ascendMetaIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  ascendMetaIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  ascendMetaIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  ascendMetaIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  ascendMetaIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** apex_account_meta edge predicates */
  hasApexAccountMeta?: InputMaybe<Scalars['Boolean']['input']>;
  hasApexAccountMetaWith?: InputMaybe<Array<ApexAccountMetaWhereInput>>;
  /** apex_update_requests edge predicates */
  hasApexUpdateRequests?: InputMaybe<Scalars['Boolean']['input']>;
  hasApexUpdateRequestsWith?: InputMaybe<Array<ApexUpdateRequestWhereInput>>;
  /** ascend_meta edge predicates */
  hasAscendMeta?: InputMaybe<Scalars['Boolean']['input']>;
  hasAscendMetaWith?: InputMaybe<Array<AscendMetaWhereInput>>;
  /** user_profile_update_request edge predicates */
  hasUserProfileUpdateRequest?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserProfileUpdateRequestWith?: InputMaybe<Array<UserProfileUpdateRequestWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<ApexAccountUpdateQueueWhereInput>;
  or?: InputMaybe<Array<ApexAccountUpdateQueueWhereInput>>;
  /** status field predicates */
  status?: InputMaybe<ApexAccountUpdateQueueUpdateRequestStatus>;
  statusIn?: InputMaybe<Array<ApexAccountUpdateQueueUpdateRequestStatus>>;
  statusNEQ?: InputMaybe<ApexAccountUpdateQueueUpdateRequestStatus>;
  statusNotIn?: InputMaybe<Array<ApexAccountUpdateQueueUpdateRequestStatus>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type ApexFiles = Node & {
  __typename?: 'ApexFiles';
  createTime: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  /** name of the document */
  name: Scalars['String']['output'];
  /** Path of the file in the S3 storage */
  path: Scalars['String']['output'];
  /** The time at which the action was requested. */
  requestTime: Scalars['Time']['output'];
  requestUUID?: Maybe<Scalars['UUID']['output']>;
  requestor: Requestor;
  /** Is when the FE has taken the photo */
  takeOn: Scalars['Time']['output'];
  updateTime: Scalars['Time']['output'];
  user?: Maybe<User>;
};

/** Ordering options for ApexFiles connections */
export type ApexFilesOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ApexFilesSlice. */
  field: ApexFilesOrderField;
};

/** Properties by which ApexFiles connections can be ordered. */
export enum ApexFilesOrderField {
  RequestTime = 'REQUEST_TIME',
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * ApexFilesWhereInput is used for filtering ApexFiles objects.
 * Input was generated by ent.
 */
export type ApexFilesWhereInput = {
  and?: InputMaybe<Array<ApexFilesWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<ApexFilesWhereInput>;
  or?: InputMaybe<Array<ApexFilesWhereInput>>;
  /** path field predicates */
  path?: InputMaybe<Scalars['String']['input']>;
  pathContains?: InputMaybe<Scalars['String']['input']>;
  pathContainsFold?: InputMaybe<Scalars['String']['input']>;
  pathEqualFold?: InputMaybe<Scalars['String']['input']>;
  pathGT?: InputMaybe<Scalars['String']['input']>;
  pathGTE?: InputMaybe<Scalars['String']['input']>;
  pathHasPrefix?: InputMaybe<Scalars['String']['input']>;
  pathHasSuffix?: InputMaybe<Scalars['String']['input']>;
  pathIn?: InputMaybe<Array<Scalars['String']['input']>>;
  pathLT?: InputMaybe<Scalars['String']['input']>;
  pathLTE?: InputMaybe<Scalars['String']['input']>;
  pathNEQ?: InputMaybe<Scalars['String']['input']>;
  pathNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** request_time field predicates */
  requestTime?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  requestTimeLT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** request_uuid field predicates */
  requestUUID?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  requestUUIDLT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDLTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNEQ?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNotIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** requestor field predicates */
  requestor?: InputMaybe<Requestor>;
  requestorIn?: InputMaybe<Array<Requestor>>;
  requestorNEQ?: InputMaybe<Requestor>;
  requestorNotIn?: InputMaybe<Array<Requestor>>;
  /** take_on field predicates */
  takeOn?: InputMaybe<Scalars['Time']['input']>;
  takeOnGT?: InputMaybe<Scalars['Time']['input']>;
  takeOnGTE?: InputMaybe<Scalars['Time']['input']>;
  takeOnIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  takeOnLT?: InputMaybe<Scalars['Time']['input']>;
  takeOnLTE?: InputMaybe<Scalars['Time']['input']>;
  takeOnNEQ?: InputMaybe<Scalars['Time']['input']>;
  takeOnNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type ApexInvestigationFile = Node & {
  __typename?: 'ApexInvestigationFile';
  apexInvestigation?: Maybe<ApexAccountInvestigation>;
  createTime: Scalars['Time']['output'];
  documentSet?: Maybe<UserDocumentSet>;
  /** Type of the document */
  documentType: ApexInvestigationFileInvestigationDocumentsType;
  /** External id of the file once is uploaded to apex */
  externalID?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** reason in case of rejection from Apex */
  reason?: Maybe<Scalars['String']['output']>;
  /** The time at which the action was requested. */
  requestTime: Scalars['Time']['output'];
  requestUUID?: Maybe<Scalars['UUID']['output']>;
  requestor: Requestor;
  /** Tag of the document */
  status: ApexInvestigationFileAccountDocumentStatus;
  /** Tag of the document */
  tag: ApexInvestigationFileInvestigationDocumentTag;
  updateTime: Scalars['Time']['output'];
  userDocument?: Maybe<UserDocument>;
};

/** ApexInvestigationFileAccountDocumentStatus is enum for the field status */
export enum ApexInvestigationFileAccountDocumentStatus {
  AccountDocumentStatusCompleted = 'ACCOUNT_DOCUMENT_STATUS_COMPLETED',
  AccountDocumentStatusError = 'ACCOUNT_DOCUMENT_STATUS_ERROR',
  AccountDocumentStatusPending = 'ACCOUNT_DOCUMENT_STATUS_PENDING',
}

/** ApexInvestigationFileInvestigationDocumentTag is enum for the field tag */
export enum ApexInvestigationFileInvestigationDocumentTag {
  InvestigationDocumentTagAccountTransferForm = 'INVESTIGATION_DOCUMENT_TAG_ACCOUNT_TRANSFER_FORM',
  InvestigationDocumentTagAffiliatedApproval = 'INVESTIGATION_DOCUMENT_TAG_AFFILIATED_APPROVAL',
  InvestigationDocumentTagIdDocument = 'INVESTIGATION_DOCUMENT_TAG_ID_DOCUMENT',
  InvestigationDocumentTagOther = 'INVESTIGATION_DOCUMENT_TAG_OTHER',
  InvestigationDocumentTagSignatureImage = 'INVESTIGATION_DOCUMENT_TAG_SIGNATURE_IMAGE',
}

/** ApexInvestigationFileInvestigationDocumentsType is enum for the field document_type */
export enum ApexInvestigationFileInvestigationDocumentsType {
  InvestigationDocumentsTypeAllPassingCipResults = 'INVESTIGATION_DOCUMENTS_TYPE_ALL_PASSING_CIP_RESULTS',
  InvestigationDocumentsTypeCddDocument = 'INVESTIGATION_DOCUMENTS_TYPE_CDD_DOCUMENT',
  InvestigationDocumentsTypeDriversLicense = 'INVESTIGATION_DOCUMENTS_TYPE_DRIVERS_LICENSE',
  InvestigationDocumentsTypeIdDocument = 'INVESTIGATION_DOCUMENTS_TYPE_ID_DOCUMENT',
  InvestigationDocumentsTypeIrsItinLetter = 'INVESTIGATION_DOCUMENTS_TYPE_IRS_ITIN_LETTER',
  InvestigationDocumentsTypeMilitaryId = 'INVESTIGATION_DOCUMENTS_TYPE_MILITARY_ID',
  InvestigationDocumentsTypeOtherGovernmentId = 'INVESTIGATION_DOCUMENTS_TYPE_OTHER_GOVERNMENT_ID',
  InvestigationDocumentsTypePassport = 'INVESTIGATION_DOCUMENTS_TYPE_PASSPORT',
  InvestigationDocumentsTypeSsaLetter = 'INVESTIGATION_DOCUMENTS_TYPE_SSA_LETTER',
  InvestigationDocumentsTypeSsnCard = 'INVESTIGATION_DOCUMENTS_TYPE_SSN_CARD',
  InvestigationDocumentsTypeStateIdCard = 'INVESTIGATION_DOCUMENTS_TYPE_STATE_ID_CARD',
}

/** Ordering options for ApexInvestigationFile connections */
export type ApexInvestigationFileOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ApexInvestigationFiles. */
  field: ApexInvestigationFileOrderField;
};

/** Properties by which ApexInvestigationFile connections can be ordered. */
export enum ApexInvestigationFileOrderField {
  RequestTime = 'REQUEST_TIME',
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * ApexInvestigationFileWhereInput is used for filtering ApexInvestigationFile objects.
 * Input was generated by ent.
 */
export type ApexInvestigationFileWhereInput = {
  and?: InputMaybe<Array<ApexInvestigationFileWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** document_type field predicates */
  documentType?: InputMaybe<ApexInvestigationFileInvestigationDocumentsType>;
  documentTypeIn?: InputMaybe<Array<ApexInvestigationFileInvestigationDocumentsType>>;
  documentTypeNEQ?: InputMaybe<ApexInvestigationFileInvestigationDocumentsType>;
  documentTypeNotIn?: InputMaybe<Array<ApexInvestigationFileInvestigationDocumentsType>>;
  /** external_id field predicates */
  externalID?: InputMaybe<Scalars['String']['input']>;
  externalIDContains?: InputMaybe<Scalars['String']['input']>;
  externalIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  externalIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  externalIDGT?: InputMaybe<Scalars['String']['input']>;
  externalIDGTE?: InputMaybe<Scalars['String']['input']>;
  externalIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  externalIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  externalIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  externalIDLT?: InputMaybe<Scalars['String']['input']>;
  externalIDLTE?: InputMaybe<Scalars['String']['input']>;
  externalIDNEQ?: InputMaybe<Scalars['String']['input']>;
  externalIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** apex_investigation edge predicates */
  hasApexInvestigation?: InputMaybe<Scalars['Boolean']['input']>;
  hasApexInvestigationWith?: InputMaybe<Array<ApexAccountInvestigationWhereInput>>;
  /** document_set edge predicates */
  hasDocumentSet?: InputMaybe<Scalars['Boolean']['input']>;
  hasDocumentSetWith?: InputMaybe<Array<UserDocumentSetWhereInput>>;
  /** user_document edge predicates */
  hasUserDocument?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserDocumentWith?: InputMaybe<Array<UserDocumentWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<ApexInvestigationFileWhereInput>;
  or?: InputMaybe<Array<ApexInvestigationFileWhereInput>>;
  /** reason field predicates */
  reason?: InputMaybe<Scalars['String']['input']>;
  reasonContains?: InputMaybe<Scalars['String']['input']>;
  reasonContainsFold?: InputMaybe<Scalars['String']['input']>;
  reasonEqualFold?: InputMaybe<Scalars['String']['input']>;
  reasonGT?: InputMaybe<Scalars['String']['input']>;
  reasonGTE?: InputMaybe<Scalars['String']['input']>;
  reasonHasPrefix?: InputMaybe<Scalars['String']['input']>;
  reasonHasSuffix?: InputMaybe<Scalars['String']['input']>;
  reasonIn?: InputMaybe<Array<Scalars['String']['input']>>;
  reasonIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  reasonLT?: InputMaybe<Scalars['String']['input']>;
  reasonLTE?: InputMaybe<Scalars['String']['input']>;
  reasonNEQ?: InputMaybe<Scalars['String']['input']>;
  reasonNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  reasonNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** request_time field predicates */
  requestTime?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  requestTimeLT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** request_uuid field predicates */
  requestUUID?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  requestUUIDLT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDLTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNEQ?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNotIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** requestor field predicates */
  requestor?: InputMaybe<Requestor>;
  requestorIn?: InputMaybe<Array<Requestor>>;
  requestorNEQ?: InputMaybe<Requestor>;
  requestorNotIn?: InputMaybe<Array<Requestor>>;
  /** status field predicates */
  status?: InputMaybe<ApexInvestigationFileAccountDocumentStatus>;
  statusIn?: InputMaybe<Array<ApexInvestigationFileAccountDocumentStatus>>;
  statusNEQ?: InputMaybe<ApexInvestigationFileAccountDocumentStatus>;
  statusNotIn?: InputMaybe<Array<ApexInvestigationFileAccountDocumentStatus>>;
  /** tag field predicates */
  tag?: InputMaybe<ApexInvestigationFileInvestigationDocumentTag>;
  tagIn?: InputMaybe<Array<ApexInvestigationFileInvestigationDocumentTag>>;
  tagNEQ?: InputMaybe<ApexInvestigationFileInvestigationDocumentTag>;
  tagNotIn?: InputMaybe<Array<ApexInvestigationFileInvestigationDocumentTag>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type ApexUpdateRequest = Node & {
  __typename?: 'ApexUpdateRequest';
  /** Apex account id for the account created through this request */
  apexAccountID: Scalars['String']['output'];
  apexAccountUpdateQueue?: Maybe<ApexAccountUpdateQueue>;
  createTime: Scalars['Time']['output'];
  discretionaryUpdateRequests?: Maybe<DiscretionaryUpdateRequest>;
  /** Request ID generated on Apex's end and provided in the response */
  externalRequestID?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  nonDiscretionaryUpdateRequests?: Maybe<NonDiscretionaryUpdateRequest>;
  programEnrollmentRequests?: Maybe<ProgramEnrollmentHistory>;
  /** Reason for the account request failure */
  reason?: Maybe<Scalars['String']['output']>;
  /** Apex account request */
  request: Scalars['Map']['output'];
  /** Request ID generated on our end and provided in the request */
  requestID: Scalars['String']['output'];
  /** Status for the Request */
  requestStatus: ApexUpdateRequestApexAccountRequestStatus;
  /** The time at which the action was requested. */
  requestTime: Scalars['Time']['output'];
  requestUUID?: Maybe<Scalars['UUID']['output']>;
  requestor: Requestor;
  /** Apex's response for the submitted request */
  response?: Maybe<Scalars['Map']['output']>;
  updateTime: Scalars['Time']['output'];
  /** User Account for this Update Request */
  userAccount: UserAccount;
};

/** ApexUpdateRequestApexAccountRequestStatus is enum for the field request_status */
export enum ApexUpdateRequestApexAccountRequestStatus {
  ApexAccountRequestStatusCanceled = 'APEX_ACCOUNT_REQUEST_STATUS_CANCELED',
  ApexAccountRequestStatusCompleted = 'APEX_ACCOUNT_REQUEST_STATUS_COMPLETED',
  ApexAccountRequestStatusEditedAndResubmitted = 'APEX_ACCOUNT_REQUEST_STATUS_EDITED_AND_RESUBMITTED',
  ApexAccountRequestStatusFailed = 'APEX_ACCOUNT_REQUEST_STATUS_FAILED',
  ApexAccountRequestStatusPending = 'APEX_ACCOUNT_REQUEST_STATUS_PENDING',
}

/** A connection to a list of items. */
export type ApexUpdateRequestConnection = {
  __typename?: 'ApexUpdateRequestConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ApexUpdateRequestEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ApexUpdateRequestEdge = {
  __typename?: 'ApexUpdateRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ApexUpdateRequest>;
};

/** Ordering options for ApexUpdateRequest connections */
export type ApexUpdateRequestOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ApexUpdateRequests. */
  field: ApexUpdateRequestOrderField;
};

/** Properties by which ApexUpdateRequest connections can be ordered. */
export enum ApexUpdateRequestOrderField {
  RequestTime = 'REQUEST_TIME',
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * ApexUpdateRequestWhereInput is used for filtering ApexUpdateRequest objects.
 * Input was generated by ent.
 */
export type ApexUpdateRequestWhereInput = {
  and?: InputMaybe<Array<ApexUpdateRequestWhereInput>>;
  /** apex_account_id field predicates */
  apexAccountID?: InputMaybe<Scalars['String']['input']>;
  apexAccountIDContains?: InputMaybe<Scalars['String']['input']>;
  apexAccountIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  apexAccountIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  apexAccountIDGT?: InputMaybe<Scalars['String']['input']>;
  apexAccountIDGTE?: InputMaybe<Scalars['String']['input']>;
  apexAccountIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  apexAccountIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  apexAccountIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  apexAccountIDLT?: InputMaybe<Scalars['String']['input']>;
  apexAccountIDLTE?: InputMaybe<Scalars['String']['input']>;
  apexAccountIDNEQ?: InputMaybe<Scalars['String']['input']>;
  apexAccountIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** external_request_id field predicates */
  externalRequestID?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDContains?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDGT?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDGTE?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalRequestIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  externalRequestIDLT?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDLTE?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDNEQ?: InputMaybe<Scalars['String']['input']>;
  externalRequestIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalRequestIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** apex_account_update_queue edge predicates */
  hasApexAccountUpdateQueue?: InputMaybe<Scalars['Boolean']['input']>;
  hasApexAccountUpdateQueueWith?: InputMaybe<Array<ApexAccountUpdateQueueWhereInput>>;
  /** discretionary_update_requests edge predicates */
  hasDiscretionaryUpdateRequests?: InputMaybe<Scalars['Boolean']['input']>;
  hasDiscretionaryUpdateRequestsWith?: InputMaybe<Array<DiscretionaryUpdateRequestWhereInput>>;
  /** non_discretionary_update_requests edge predicates */
  hasNonDiscretionaryUpdateRequests?: InputMaybe<Scalars['Boolean']['input']>;
  hasNonDiscretionaryUpdateRequestsWith?: InputMaybe<
    Array<NonDiscretionaryUpdateRequestWhereInput>
  >;
  /** program_enrollment_requests edge predicates */
  hasProgramEnrollmentRequests?: InputMaybe<Scalars['Boolean']['input']>;
  hasProgramEnrollmentRequestsWith?: InputMaybe<Array<ProgramEnrollmentHistoryWhereInput>>;
  /** user_account edge predicates */
  hasUserAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserAccountWith?: InputMaybe<Array<UserAccountWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<ApexUpdateRequestWhereInput>;
  or?: InputMaybe<Array<ApexUpdateRequestWhereInput>>;
  /** reason field predicates */
  reason?: InputMaybe<Scalars['String']['input']>;
  reasonContains?: InputMaybe<Scalars['String']['input']>;
  reasonContainsFold?: InputMaybe<Scalars['String']['input']>;
  reasonEqualFold?: InputMaybe<Scalars['String']['input']>;
  reasonGT?: InputMaybe<Scalars['String']['input']>;
  reasonGTE?: InputMaybe<Scalars['String']['input']>;
  reasonHasPrefix?: InputMaybe<Scalars['String']['input']>;
  reasonHasSuffix?: InputMaybe<Scalars['String']['input']>;
  reasonIn?: InputMaybe<Array<Scalars['String']['input']>>;
  reasonIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  reasonLT?: InputMaybe<Scalars['String']['input']>;
  reasonLTE?: InputMaybe<Scalars['String']['input']>;
  reasonNEQ?: InputMaybe<Scalars['String']['input']>;
  reasonNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  reasonNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** request_id field predicates */
  requestID?: InputMaybe<Scalars['String']['input']>;
  requestIDContains?: InputMaybe<Scalars['String']['input']>;
  requestIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  requestIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  requestIDGT?: InputMaybe<Scalars['String']['input']>;
  requestIDGTE?: InputMaybe<Scalars['String']['input']>;
  requestIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  requestIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  requestIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  requestIDLT?: InputMaybe<Scalars['String']['input']>;
  requestIDLTE?: InputMaybe<Scalars['String']['input']>;
  requestIDNEQ?: InputMaybe<Scalars['String']['input']>;
  requestIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** request_status field predicates */
  requestStatus?: InputMaybe<ApexUpdateRequestApexAccountRequestStatus>;
  requestStatusIn?: InputMaybe<Array<ApexUpdateRequestApexAccountRequestStatus>>;
  requestStatusNEQ?: InputMaybe<ApexUpdateRequestApexAccountRequestStatus>;
  requestStatusNotIn?: InputMaybe<Array<ApexUpdateRequestApexAccountRequestStatus>>;
  /** request_time field predicates */
  requestTime?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  requestTimeLT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** request_uuid field predicates */
  requestUUID?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  requestUUIDLT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDLTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNEQ?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNotIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** requestor field predicates */
  requestor?: InputMaybe<Requestor>;
  requestorIn?: InputMaybe<Array<Requestor>>;
  requestorNEQ?: InputMaybe<Requestor>;
  requestorNotIn?: InputMaybe<Array<Requestor>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type AscendInvestigation = Node & {
  __typename?: 'AscendInvestigation';
  createTime: Scalars['Time']['output'];
  /** base64 encoded details of the investigation */
  detailsBase64?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** the identity verification status, as reported by Ascend */
  identityVerificationStatus: AscendInvestigationIdentityVerificationStatus;
  /** Foreign key to the User's external_id */
  legalNaturalPersonID: Scalars['String']['output'];
  updateTime: Scalars['Time']['output'];
  user: User;
  /** the watchlist screen status, as reported by Ascend */
  watchlistScreenStatus: AscendInvestigationWatchlistScreenStatus;
};

/** AscendInvestigationIdentityVerificationStatus is enum for the field identity_verification_status */
export enum AscendInvestigationIdentityVerificationStatus {
  IdentityVerificationStatusDeferredReview = 'IDENTITY_VERIFICATION_STATUS_DEFERRED_REVIEW',
  IdentityVerificationStatusFailed = 'IDENTITY_VERIFICATION_STATUS_FAILED',
  IdentityVerificationStatusNeedsReview = 'IDENTITY_VERIFICATION_STATUS_NEEDS_REVIEW',
  IdentityVerificationStatusOutOfScope = 'IDENTITY_VERIFICATION_STATUS_OUT_OF_SCOPE',
  IdentityVerificationStatusPassed = 'IDENTITY_VERIFICATION_STATUS_PASSED',
  IdentityVerificationStatusPending = 'IDENTITY_VERIFICATION_STATUS_PENDING',
}

/** Ordering options for AscendInvestigation connections */
export type AscendInvestigationOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order AscendInvestigations. */
  field: AscendInvestigationOrderField;
};

/** Properties by which AscendInvestigation connections can be ordered. */
export enum AscendInvestigationOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/** AscendInvestigationWatchlistScreenStatus is enum for the field watchlist_screen_status */
export enum AscendInvestigationWatchlistScreenStatus {
  WatchlistScreenStatusFailed = 'WATCHLIST_SCREEN_STATUS_FAILED',
}

/**
 * AscendInvestigationWhereInput is used for filtering AscendInvestigation objects.
 * Input was generated by ent.
 */
export type AscendInvestigationWhereInput = {
  and?: InputMaybe<Array<AscendInvestigationWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** details_base64 field predicates */
  detailsBase64?: InputMaybe<Scalars['String']['input']>;
  detailsBase64Contains?: InputMaybe<Scalars['String']['input']>;
  detailsBase64ContainsFold?: InputMaybe<Scalars['String']['input']>;
  detailsBase64EqualFold?: InputMaybe<Scalars['String']['input']>;
  detailsBase64GT?: InputMaybe<Scalars['String']['input']>;
  detailsBase64GTE?: InputMaybe<Scalars['String']['input']>;
  detailsBase64HasPrefix?: InputMaybe<Scalars['String']['input']>;
  detailsBase64HasSuffix?: InputMaybe<Scalars['String']['input']>;
  detailsBase64In?: InputMaybe<Array<Scalars['String']['input']>>;
  detailsBase64IsNil?: InputMaybe<Scalars['Boolean']['input']>;
  detailsBase64LT?: InputMaybe<Scalars['String']['input']>;
  detailsBase64LTE?: InputMaybe<Scalars['String']['input']>;
  detailsBase64NEQ?: InputMaybe<Scalars['String']['input']>;
  detailsBase64NotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  detailsBase64NotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** identity_verification_status field predicates */
  identityVerificationStatus?: InputMaybe<AscendInvestigationIdentityVerificationStatus>;
  identityVerificationStatusIn?: InputMaybe<Array<AscendInvestigationIdentityVerificationStatus>>;
  identityVerificationStatusNEQ?: InputMaybe<AscendInvestigationIdentityVerificationStatus>;
  identityVerificationStatusNotIn?: InputMaybe<
    Array<AscendInvestigationIdentityVerificationStatus>
  >;
  /** legal_natural_person_id field predicates */
  legalNaturalPersonID?: InputMaybe<Scalars['String']['input']>;
  legalNaturalPersonIDContains?: InputMaybe<Scalars['String']['input']>;
  legalNaturalPersonIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  legalNaturalPersonIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  legalNaturalPersonIDGT?: InputMaybe<Scalars['String']['input']>;
  legalNaturalPersonIDGTE?: InputMaybe<Scalars['String']['input']>;
  legalNaturalPersonIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  legalNaturalPersonIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  legalNaturalPersonIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNaturalPersonIDLT?: InputMaybe<Scalars['String']['input']>;
  legalNaturalPersonIDLTE?: InputMaybe<Scalars['String']['input']>;
  legalNaturalPersonIDNEQ?: InputMaybe<Scalars['String']['input']>;
  legalNaturalPersonIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<AscendInvestigationWhereInput>;
  or?: InputMaybe<Array<AscendInvestigationWhereInput>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** watchlist_screen_status field predicates */
  watchlistScreenStatus?: InputMaybe<AscendInvestigationWatchlistScreenStatus>;
  watchlistScreenStatusIn?: InputMaybe<Array<AscendInvestigationWatchlistScreenStatus>>;
  watchlistScreenStatusNEQ?: InputMaybe<AscendInvestigationWatchlistScreenStatus>;
  watchlistScreenStatusNotIn?: InputMaybe<Array<AscendInvestigationWatchlistScreenStatus>>;
};

export type AscendMeta = Node & {
  __typename?: 'AscendMeta';
  /** the account ID as provided by Ascend */
  accountID: Scalars['String']['output'];
  /** the account number as provided by Ascend */
  accountNumber: Scalars['String']['output'];
  /** Apex Account Update Queue for this account */
  apexAccountUpdateQueue?: Maybe<Array<ApexAccountUpdateQueue>>;
  /** Status for User's Ascend Account. This is directly given to us by Ascend. */
  applicationStatus: AscendMetaAscendApplicationStatus;
  createTime: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  updateTime: Scalars['Time']['output'];
  user: User;
  userAccount: UserAccount;
};

/** AscendMetaAscendApplicationStatus is enum for the field application_status */
export enum AscendMetaAscendApplicationStatus {
  AscendApplicationStatusClosed = 'ASCEND_APPLICATION_STATUS_CLOSED',
  AscendApplicationStatusOpen = 'ASCEND_APPLICATION_STATUS_OPEN',
  AscendApplicationStatusPending = 'ASCEND_APPLICATION_STATUS_PENDING',
}

/** Ordering options for AscendMeta connections */
export type AscendMetaOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order AscendMetaSlice. */
  field: AscendMetaOrderField;
};

/** Properties by which AscendMeta connections can be ordered. */
export enum AscendMetaOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * AscendMetaWhereInput is used for filtering AscendMeta objects.
 * Input was generated by ent.
 */
export type AscendMetaWhereInput = {
  /** account_id field predicates */
  accountID?: InputMaybe<Scalars['String']['input']>;
  accountIDContains?: InputMaybe<Scalars['String']['input']>;
  accountIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  accountIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  accountIDGT?: InputMaybe<Scalars['String']['input']>;
  accountIDGTE?: InputMaybe<Scalars['String']['input']>;
  accountIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  accountIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  accountIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  accountIDLT?: InputMaybe<Scalars['String']['input']>;
  accountIDLTE?: InputMaybe<Scalars['String']['input']>;
  accountIDNEQ?: InputMaybe<Scalars['String']['input']>;
  accountIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** account_number field predicates */
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  accountNumberContains?: InputMaybe<Scalars['String']['input']>;
  accountNumberContainsFold?: InputMaybe<Scalars['String']['input']>;
  accountNumberEqualFold?: InputMaybe<Scalars['String']['input']>;
  accountNumberGT?: InputMaybe<Scalars['String']['input']>;
  accountNumberGTE?: InputMaybe<Scalars['String']['input']>;
  accountNumberHasPrefix?: InputMaybe<Scalars['String']['input']>;
  accountNumberHasSuffix?: InputMaybe<Scalars['String']['input']>;
  accountNumberIn?: InputMaybe<Array<Scalars['String']['input']>>;
  accountNumberLT?: InputMaybe<Scalars['String']['input']>;
  accountNumberLTE?: InputMaybe<Scalars['String']['input']>;
  accountNumberNEQ?: InputMaybe<Scalars['String']['input']>;
  accountNumberNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  and?: InputMaybe<Array<AscendMetaWhereInput>>;
  /** application_status field predicates */
  applicationStatus?: InputMaybe<AscendMetaAscendApplicationStatus>;
  applicationStatusIn?: InputMaybe<Array<AscendMetaAscendApplicationStatus>>;
  applicationStatusNEQ?: InputMaybe<AscendMetaAscendApplicationStatus>;
  applicationStatusNotIn?: InputMaybe<Array<AscendMetaAscendApplicationStatus>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** apex_account_update_queue edge predicates */
  hasApexAccountUpdateQueue?: InputMaybe<Scalars['Boolean']['input']>;
  hasApexAccountUpdateQueueWith?: InputMaybe<Array<ApexAccountUpdateQueueWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  /** user_account edge predicates */
  hasUserAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserAccountWith?: InputMaybe<Array<UserAccountWhereInput>>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<AscendMetaWhereInput>;
  or?: InputMaybe<Array<AscendMetaWhereInput>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** AssetAssetType is enum for the types of assets that are supported. */
export enum AssetAssetType {
  AssetTypeCrypto = 'ASSET_TYPE_CRYPTO',
  AssetTypeFiat = 'ASSET_TYPE_FIAT',
  AssetTypeSecurity = 'ASSET_TYPE_SECURITY',
}

export type AssetId = {
  id?: InputMaybe<Scalars['ID']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
};

export type AssetLockup = Node & {
  __typename?: 'AssetLockup';
  /** The amount of the asset that is locked up. */
  amount: Scalars['Decimal']['output'];
  /** The asset account that the lockup is associated with. */
  assetAccount: UserAccountPortfolioAsset;
  /** The order that is associated with this lockup. Optional. */
  associatedOrder?: Maybe<Order>;
  /** indicates if the lock up prevents funds from being used in a trade */
  canTrade: Scalars['Boolean']['output'];
  /** indicates if the lock up prevents funds from being withdrawn */
  canWithdraw: Scalars['Boolean']['output'];
  /** The date that the lockup is cleared. If nil, the lockup must be explicitly cleared by an external process. */
  clearDate?: Maybe<Scalars['Date']['output']>;
  createTime: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  /** Once a lockup is clear, this is set to false. */
  isActive: Scalars['Boolean']['output'];
  type: AssetLockupType;
  updateTime: Scalars['Time']['output'];
};

/** Ordering options for AssetLockup connections */
export type AssetLockupOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order AssetLockups. */
  field: AssetLockupOrderField;
};

/** Properties by which AssetLockup connections can be ordered. */
export enum AssetLockupOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/** AssetLockupType is enum for the field type */
export enum AssetLockupType {
  Credit = 'CREDIT',
  Deposit = 'DEPOSIT',
  Other = 'OTHER',
  Principal = 'PRINCIPAL',
}

/**
 * AssetLockupWhereInput is used for filtering AssetLockup objects.
 * Input was generated by ent.
 */
export type AssetLockupWhereInput = {
  /** amount field predicates */
  amount?: InputMaybe<Scalars['Decimal']['input']>;
  amountGT?: InputMaybe<Scalars['Decimal']['input']>;
  amountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  amountLT?: InputMaybe<Scalars['Decimal']['input']>;
  amountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  amountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  and?: InputMaybe<Array<AssetLockupWhereInput>>;
  /** can_trade field predicates */
  canTrade?: InputMaybe<Scalars['Boolean']['input']>;
  canTradeNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** can_withdraw field predicates */
  canWithdraw?: InputMaybe<Scalars['Boolean']['input']>;
  canWithdrawNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** clear_date field predicates */
  clearDate?: InputMaybe<Scalars['Date']['input']>;
  clearDateGT?: InputMaybe<Scalars['Date']['input']>;
  clearDateGTE?: InputMaybe<Scalars['Date']['input']>;
  clearDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  clearDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  clearDateLT?: InputMaybe<Scalars['Date']['input']>;
  clearDateLTE?: InputMaybe<Scalars['Date']['input']>;
  clearDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  clearDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  clearDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** asset_account edge predicates */
  hasAssetAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssetAccountWith?: InputMaybe<Array<UserAccountPortfolioAssetWhereInput>>;
  /** associated_order edge predicates */
  hasAssociatedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssociatedOrderWith?: InputMaybe<Array<OrderWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_active field predicates */
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isActiveNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<AssetLockupWhereInput>;
  or?: InputMaybe<Array<AssetLockupWhereInput>>;
  /** type field predicates */
  type?: InputMaybe<AssetLockupType>;
  typeIn?: InputMaybe<Array<AssetLockupType>>;
  typeNEQ?: InputMaybe<AssetLockupType>;
  typeNotIn?: InputMaybe<Array<AssetLockupType>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type AssetMarketData = {
  __typename?: 'AssetMarketData';
  /** ask is the current best ask price. */
  ask: Scalars['Decimal']['output'];
  /**
   * askSize represents the number of round lot orders at the given ask price. The normal round lot size is
   *   100 shares. An ask size of 2 means there are 200 shares for purchase at the given bid price.
   */
  askSize: Scalars['Decimal']['output'];
  /** baseMin is the minimum allowed amount allowed in a buy order. */
  baseMin?: Maybe<Scalars['Decimal']['output']>;
  /** bid is the current best bid price. */
  bid: Scalars['Decimal']['output'];
  /**
   * bidSize represents the number of round lot orders at the given bid price. The normal round lot size is
   *   100 shares. A bid size of 2 means there are 200 shares for purchase at the given bid price.
   */
  bidSize: Scalars['Decimal']['output'];
  /** close is the weighted average of all prices during the last 30 minutes of the trading hours. */
  close: Scalars['Decimal']['output'];
  /** high is the highest price for the current trading day. */
  high: Scalars['Decimal']['output'];
  /** historical returns a time series of the OHLCV for the asset */
  historical: Ohlcv;
  /** last is the latest trade price of the asset. */
  last: Scalars['Decimal']['output'];
  /** low is the lowest price for the current trading day. */
  low: Scalars['Decimal']['output'];
  /** open is the opening price for the current trading day. */
  open: Scalars['Decimal']['output'];
  /** previousDayClose is the closing price for the previous trading day. */
  previousDayClose: Scalars['Decimal']['output'];
  /** quoteMin is the minimum allowed amount allowed in a sell order. */
  quoteMin?: Maybe<Scalars['Decimal']['output']>;
  /** volumn is the volumn traded so far for the current trading day. */
  volume: Scalars['Decimal']['output'];
};

export type AssetMarketDataHistoricalArgs = {
  range?: InputMaybe<TimeSeriesRange>;
};

export type AssetNews = {
  __typename?: 'AssetNews';
  datetime: Scalars['Time']['output'];
  description: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  source: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type AssetSubtypeTotal = {
  __typename?: 'AssetSubtypeTotal';
  subType: Scalars['String']['output'];
  total: Scalars['Decimal']['output'];
};

export type AssetTypeTotal = {
  __typename?: 'AssetTypeTotal';
  subtypes?: Maybe<Array<AssetSubtypeTotal>>;
  total: Scalars['Decimal']['output'];
};

export type BaseAsset = {
  active: Scalars['Boolean']['output'];
  createTime: Scalars['Time']['output'];
  hidden: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  symbol: Scalars['String']['output'];
  type: AssetAssetType;
  updateTime: Scalars['Time']['output'];
};

export type BaseAssetWhereInput = {
  /** active field predicates */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  activeNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<BaseAssetWhereInput>>;
  /** hidden field predicates */
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  hiddenNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** includeHidden indicates if the response should include hidden values. If not set, it defaults to false. */
  includeHidden?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<BaseAssetWhereInput>;
  or?: InputMaybe<Array<BaseAssetWhereInput>>;
  /** symbol field predicates */
  symbol?: InputMaybe<Scalars['String']['input']>;
  symbolContains?: InputMaybe<Scalars['String']['input']>;
  symbolContainsFold?: InputMaybe<Scalars['String']['input']>;
  symbolEqualFold?: InputMaybe<Scalars['String']['input']>;
  symbolGT?: InputMaybe<Scalars['String']['input']>;
  symbolGTE?: InputMaybe<Scalars['String']['input']>;
  symbolHasPrefix?: InputMaybe<Scalars['String']['input']>;
  symbolHasSuffix?: InputMaybe<Scalars['String']['input']>;
  symbolIn?: InputMaybe<Array<Scalars['String']['input']>>;
  symbolLT?: InputMaybe<Scalars['String']['input']>;
  symbolLTE?: InputMaybe<Scalars['String']['input']>;
  symbolNEQ?: InputMaybe<Scalars['String']['input']>;
  symbolNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** type field predicates */
  type?: InputMaybe<AssetAssetType>;
  typeIn?: InputMaybe<Array<AssetAssetType>>;
  typeNEQ?: InputMaybe<AssetAssetType>;
  typeNotIn?: InputMaybe<Array<AssetAssetType>>;
};

/** BaseOrder is the base implementation of an order in the system. */
export type BaseOrder = {
  createTime: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  orderStatus: OrderOrderStatus;
  orderType: OrderOrderType;
  requestDetails: RequestDetails;
  updateTime: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type BaseOrderConnection = {
  __typename?: 'BaseOrderConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BaseOrderEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type BaseOrderEdge = {
  __typename?: 'BaseOrderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node: BaseOrder;
};

export type Beneficiary = Node & {
  __typename?: 'Beneficiary';
  additionalStreetAddress?: Maybe<Scalars['String']['output']>;
  beneficiarySet: BeneficiarySet;
  city: Scalars['String']['output'];
  /** beneficiary's country code */
  country?: Maybe<Scalars['CountryCode']['output']>;
  createTime: Scalars['Time']['output'];
  dateOfBirth: Scalars['Date']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  /** beneficiary's middle name */
  middleName?: Maybe<Scalars['String']['output']>;
  /** Percentage of the account allocated to this beneficiary */
  percentage: Scalars['Int']['output'];
  phoneNumber?: Maybe<Scalars['PhoneNumber']['output']>;
  /** The relationship of the beneficiary to the account owner */
  relationship?: Maybe<BeneficiaryBeneficiaryRelationType>;
  /** Social Security Number */
  ssn?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  streetAddress?: Maybe<Scalars['String']['output']>;
  updateTime: Scalars['Time']['output'];
  zipCode: Scalars['String']['output'];
};

/** BeneficiaryBeneficiaryRelationType is enum for the field relationship */
export enum BeneficiaryBeneficiaryRelationType {
  BeneficiaryRelationTypeOther = 'BENEFICIARY_RELATION_TYPE_OTHER',
  BeneficiaryRelationTypeSpouse = 'BENEFICIARY_RELATION_TYPE_SPOUSE',
  BeneficiaryRelationTypeTrust = 'BENEFICIARY_RELATION_TYPE_TRUST',
}

/** Ordering options for Beneficiary connections */
export type BeneficiaryOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Beneficiaries. */
  field: BeneficiaryOrderField;
};

/** Properties by which Beneficiary connections can be ordered. */
export enum BeneficiaryOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

export type BeneficiarySet = Node & {
  __typename?: 'BeneficiarySet';
  beneficiaries?: Maybe<Array<Beneficiary>>;
  contingent?: Maybe<UserAccount>;
  createTime: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  /** Indicates if this is the currently active set */
  isActive: Scalars['Boolean']['output'];
  /** Indicates that the data is stored but has not been submitted to apex yet */
  isPending: Scalars['Boolean']['output'];
  /** Indicates if this is the primary or contingent beneficiary set */
  isPrimary: Scalars['Boolean']['output'];
  primary?: Maybe<UserAccount>;
  /** The time at which the action was requested. */
  requestTime: Scalars['Time']['output'];
  requestUUID?: Maybe<Scalars['UUID']['output']>;
  requestor: Requestor;
  /** The time the beneficiary set was submitted to apex */
  submissionTime?: Maybe<Scalars['Time']['output']>;
  updateTime: Scalars['Time']['output'];
  userAccount: UserAccount;
  /** The user account that this beneficiary set belongs to */
  userAccountID: Scalars['ID']['output'];
};

/** Ordering options for BeneficiarySet connections */
export type BeneficiarySetOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order BeneficiarySets. */
  field: BeneficiarySetOrderField;
};

/** Properties by which BeneficiarySet connections can be ordered. */
export enum BeneficiarySetOrderField {
  RequestTime = 'REQUEST_TIME',
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * BeneficiarySetWhereInput is used for filtering BeneficiarySet objects.
 * Input was generated by ent.
 */
export type BeneficiarySetWhereInput = {
  and?: InputMaybe<Array<BeneficiarySetWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** beneficiaries edge predicates */
  hasBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  hasBeneficiariesWith?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** contingent edge predicates */
  hasContingent?: InputMaybe<Scalars['Boolean']['input']>;
  hasContingentWith?: InputMaybe<Array<UserAccountWhereInput>>;
  /** primary edge predicates */
  hasPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  hasPrimaryWith?: InputMaybe<Array<UserAccountWhereInput>>;
  /** user_account edge predicates */
  hasUserAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserAccountWith?: InputMaybe<Array<UserAccountWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_active field predicates */
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isActiveNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** is_pending field predicates */
  isPending?: InputMaybe<Scalars['Boolean']['input']>;
  isPendingNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** is_primary field predicates */
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  isPrimaryNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<BeneficiarySetWhereInput>;
  or?: InputMaybe<Array<BeneficiarySetWhereInput>>;
  /** request_time field predicates */
  requestTime?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  requestTimeLT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** request_uuid field predicates */
  requestUUID?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  requestUUIDLT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDLTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNEQ?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNotIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** requestor field predicates */
  requestor?: InputMaybe<Requestor>;
  requestorIn?: InputMaybe<Array<Requestor>>;
  requestorNEQ?: InputMaybe<Requestor>;
  requestorNotIn?: InputMaybe<Array<Requestor>>;
  /** submission_time field predicates */
  submissionTime?: InputMaybe<Scalars['Time']['input']>;
  submissionTimeGT?: InputMaybe<Scalars['Time']['input']>;
  submissionTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  submissionTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  submissionTimeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  submissionTimeLT?: InputMaybe<Scalars['Time']['input']>;
  submissionTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  submissionTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  submissionTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  submissionTimeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** user_account_id field predicates */
  userAccountID?: InputMaybe<Scalars['ID']['input']>;
  userAccountIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  userAccountIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  userAccountIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * BeneficiaryWhereInput is used for filtering Beneficiary objects.
 * Input was generated by ent.
 */
export type BeneficiaryWhereInput = {
  /** additional_street_address field predicates */
  additionalStreetAddress?: InputMaybe<Scalars['String']['input']>;
  additionalStreetAddressContains?: InputMaybe<Scalars['String']['input']>;
  additionalStreetAddressContainsFold?: InputMaybe<Scalars['String']['input']>;
  additionalStreetAddressEqualFold?: InputMaybe<Scalars['String']['input']>;
  additionalStreetAddressGT?: InputMaybe<Scalars['String']['input']>;
  additionalStreetAddressGTE?: InputMaybe<Scalars['String']['input']>;
  additionalStreetAddressHasPrefix?: InputMaybe<Scalars['String']['input']>;
  additionalStreetAddressHasSuffix?: InputMaybe<Scalars['String']['input']>;
  additionalStreetAddressIn?: InputMaybe<Array<Scalars['String']['input']>>;
  additionalStreetAddressIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  additionalStreetAddressLT?: InputMaybe<Scalars['String']['input']>;
  additionalStreetAddressLTE?: InputMaybe<Scalars['String']['input']>;
  additionalStreetAddressNEQ?: InputMaybe<Scalars['String']['input']>;
  additionalStreetAddressNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  additionalStreetAddressNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** city field predicates */
  city?: InputMaybe<Scalars['String']['input']>;
  cityContains?: InputMaybe<Scalars['String']['input']>;
  cityContainsFold?: InputMaybe<Scalars['String']['input']>;
  cityEqualFold?: InputMaybe<Scalars['String']['input']>;
  cityGT?: InputMaybe<Scalars['String']['input']>;
  cityGTE?: InputMaybe<Scalars['String']['input']>;
  cityHasPrefix?: InputMaybe<Scalars['String']['input']>;
  cityHasSuffix?: InputMaybe<Scalars['String']['input']>;
  cityIn?: InputMaybe<Array<Scalars['String']['input']>>;
  cityLT?: InputMaybe<Scalars['String']['input']>;
  cityLTE?: InputMaybe<Scalars['String']['input']>;
  cityNEQ?: InputMaybe<Scalars['String']['input']>;
  cityNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** country field predicates */
  country?: InputMaybe<Scalars['CountryCode']['input']>;
  countryGT?: InputMaybe<Scalars['CountryCode']['input']>;
  countryGTE?: InputMaybe<Scalars['CountryCode']['input']>;
  countryIn?: InputMaybe<Array<Scalars['CountryCode']['input']>>;
  countryIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  countryLT?: InputMaybe<Scalars['CountryCode']['input']>;
  countryLTE?: InputMaybe<Scalars['CountryCode']['input']>;
  countryNEQ?: InputMaybe<Scalars['CountryCode']['input']>;
  countryNotIn?: InputMaybe<Array<Scalars['CountryCode']['input']>>;
  countryNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** date_of_birth field predicates */
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirthGT?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirthGTE?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirthIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  dateOfBirthLT?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirthLTE?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirthNEQ?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirthNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  /** email field predicates */
  email?: InputMaybe<Scalars['String']['input']>;
  emailContains?: InputMaybe<Scalars['String']['input']>;
  emailContainsFold?: InputMaybe<Scalars['String']['input']>;
  emailEqualFold?: InputMaybe<Scalars['String']['input']>;
  emailGT?: InputMaybe<Scalars['String']['input']>;
  emailGTE?: InputMaybe<Scalars['String']['input']>;
  emailHasPrefix?: InputMaybe<Scalars['String']['input']>;
  emailHasSuffix?: InputMaybe<Scalars['String']['input']>;
  emailIn?: InputMaybe<Array<Scalars['String']['input']>>;
  emailIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  emailLT?: InputMaybe<Scalars['String']['input']>;
  emailLTE?: InputMaybe<Scalars['String']['input']>;
  emailNEQ?: InputMaybe<Scalars['String']['input']>;
  emailNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  emailNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** first_name field predicates */
  firstName?: InputMaybe<Scalars['String']['input']>;
  firstNameContains?: InputMaybe<Scalars['String']['input']>;
  firstNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  firstNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  firstNameGT?: InputMaybe<Scalars['String']['input']>;
  firstNameGTE?: InputMaybe<Scalars['String']['input']>;
  firstNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  firstNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  firstNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  firstNameLT?: InputMaybe<Scalars['String']['input']>;
  firstNameLTE?: InputMaybe<Scalars['String']['input']>;
  firstNameNEQ?: InputMaybe<Scalars['String']['input']>;
  firstNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** beneficiary_set edge predicates */
  hasBeneficiarySet?: InputMaybe<Scalars['Boolean']['input']>;
  hasBeneficiarySetWith?: InputMaybe<Array<BeneficiarySetWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** last_name field predicates */
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastNameContains?: InputMaybe<Scalars['String']['input']>;
  lastNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  lastNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  lastNameGT?: InputMaybe<Scalars['String']['input']>;
  lastNameGTE?: InputMaybe<Scalars['String']['input']>;
  lastNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  lastNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  lastNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  lastNameLT?: InputMaybe<Scalars['String']['input']>;
  lastNameLTE?: InputMaybe<Scalars['String']['input']>;
  lastNameNEQ?: InputMaybe<Scalars['String']['input']>;
  lastNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** middle_name field predicates */
  middleName?: InputMaybe<Scalars['String']['input']>;
  middleNameContains?: InputMaybe<Scalars['String']['input']>;
  middleNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  middleNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  middleNameGT?: InputMaybe<Scalars['String']['input']>;
  middleNameGTE?: InputMaybe<Scalars['String']['input']>;
  middleNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  middleNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  middleNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  middleNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  middleNameLT?: InputMaybe<Scalars['String']['input']>;
  middleNameLTE?: InputMaybe<Scalars['String']['input']>;
  middleNameNEQ?: InputMaybe<Scalars['String']['input']>;
  middleNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  middleNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<BeneficiaryWhereInput>;
  or?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** percentage field predicates */
  percentage?: InputMaybe<Scalars['Int']['input']>;
  percentageGT?: InputMaybe<Scalars['Int']['input']>;
  percentageGTE?: InputMaybe<Scalars['Int']['input']>;
  percentageIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  percentageLT?: InputMaybe<Scalars['Int']['input']>;
  percentageLTE?: InputMaybe<Scalars['Int']['input']>;
  percentageNEQ?: InputMaybe<Scalars['Int']['input']>;
  percentageNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** phone_number field predicates */
  phoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>;
  phoneNumberContains?: InputMaybe<Scalars['PhoneNumber']['input']>;
  phoneNumberContainsFold?: InputMaybe<Scalars['PhoneNumber']['input']>;
  phoneNumberEqualFold?: InputMaybe<Scalars['PhoneNumber']['input']>;
  phoneNumberGT?: InputMaybe<Scalars['PhoneNumber']['input']>;
  phoneNumberGTE?: InputMaybe<Scalars['PhoneNumber']['input']>;
  phoneNumberHasPrefix?: InputMaybe<Scalars['PhoneNumber']['input']>;
  phoneNumberHasSuffix?: InputMaybe<Scalars['PhoneNumber']['input']>;
  phoneNumberIn?: InputMaybe<Array<Scalars['PhoneNumber']['input']>>;
  phoneNumberIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumberLT?: InputMaybe<Scalars['PhoneNumber']['input']>;
  phoneNumberLTE?: InputMaybe<Scalars['PhoneNumber']['input']>;
  phoneNumberNEQ?: InputMaybe<Scalars['PhoneNumber']['input']>;
  phoneNumberNotIn?: InputMaybe<Array<Scalars['PhoneNumber']['input']>>;
  phoneNumberNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** relationship field predicates */
  relationship?: InputMaybe<BeneficiaryBeneficiaryRelationType>;
  relationshipIn?: InputMaybe<Array<BeneficiaryBeneficiaryRelationType>>;
  relationshipIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  relationshipNEQ?: InputMaybe<BeneficiaryBeneficiaryRelationType>;
  relationshipNotIn?: InputMaybe<Array<BeneficiaryBeneficiaryRelationType>>;
  relationshipNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** ssn field predicates */
  ssn?: InputMaybe<Scalars['String']['input']>;
  ssnGT?: InputMaybe<Scalars['String']['input']>;
  ssnGTE?: InputMaybe<Scalars['String']['input']>;
  ssnIn?: InputMaybe<Array<Scalars['String']['input']>>;
  ssnIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  ssnLT?: InputMaybe<Scalars['String']['input']>;
  ssnLTE?: InputMaybe<Scalars['String']['input']>;
  ssnNEQ?: InputMaybe<Scalars['String']['input']>;
  ssnNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  ssnNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** state field predicates */
  state?: InputMaybe<Scalars['String']['input']>;
  stateContains?: InputMaybe<Scalars['String']['input']>;
  stateContainsFold?: InputMaybe<Scalars['String']['input']>;
  stateEqualFold?: InputMaybe<Scalars['String']['input']>;
  stateGT?: InputMaybe<Scalars['String']['input']>;
  stateGTE?: InputMaybe<Scalars['String']['input']>;
  stateHasPrefix?: InputMaybe<Scalars['String']['input']>;
  stateHasSuffix?: InputMaybe<Scalars['String']['input']>;
  stateIn?: InputMaybe<Array<Scalars['String']['input']>>;
  stateLT?: InputMaybe<Scalars['String']['input']>;
  stateLTE?: InputMaybe<Scalars['String']['input']>;
  stateNEQ?: InputMaybe<Scalars['String']['input']>;
  stateNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** street_address field predicates */
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  streetAddressContains?: InputMaybe<Scalars['String']['input']>;
  streetAddressContainsFold?: InputMaybe<Scalars['String']['input']>;
  streetAddressEqualFold?: InputMaybe<Scalars['String']['input']>;
  streetAddressGT?: InputMaybe<Scalars['String']['input']>;
  streetAddressGTE?: InputMaybe<Scalars['String']['input']>;
  streetAddressHasPrefix?: InputMaybe<Scalars['String']['input']>;
  streetAddressHasSuffix?: InputMaybe<Scalars['String']['input']>;
  streetAddressIn?: InputMaybe<Array<Scalars['String']['input']>>;
  streetAddressIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  streetAddressLT?: InputMaybe<Scalars['String']['input']>;
  streetAddressLTE?: InputMaybe<Scalars['String']['input']>;
  streetAddressNEQ?: InputMaybe<Scalars['String']['input']>;
  streetAddressNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  streetAddressNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** zip_code field predicates */
  zipCode?: InputMaybe<Scalars['String']['input']>;
  zipCodeContains?: InputMaybe<Scalars['String']['input']>;
  zipCodeContainsFold?: InputMaybe<Scalars['String']['input']>;
  zipCodeEqualFold?: InputMaybe<Scalars['String']['input']>;
  zipCodeGT?: InputMaybe<Scalars['String']['input']>;
  zipCodeGTE?: InputMaybe<Scalars['String']['input']>;
  zipCodeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  zipCodeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  zipCodeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  zipCodeLT?: InputMaybe<Scalars['String']['input']>;
  zipCodeLTE?: InputMaybe<Scalars['String']['input']>;
  zipCodeNEQ?: InputMaybe<Scalars['String']['input']>;
  zipCodeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type BrokerDtcc = Node & {
  __typename?: 'BrokerDTCC';
  createTime: Scalars['Time']['output'];
  dtccNumber: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updateTime: Scalars['Time']['output'];
};

/** Ordering options for BrokerDTCC connections */
export type BrokerDtccOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order BrokerDTCCs. */
  field: BrokerDtccOrderField;
};

/** Properties by which BrokerDTCC connections can be ordered. */
export enum BrokerDtccOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * BrokerDTCCWhereInput is used for filtering BrokerDTCC objects.
 * Input was generated by ent.
 */
export type BrokerDtccWhereInput = {
  and?: InputMaybe<Array<BrokerDtccWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** dtcc_number field predicates */
  dtccNumber?: InputMaybe<Scalars['Int']['input']>;
  dtccNumberGT?: InputMaybe<Scalars['Int']['input']>;
  dtccNumberGTE?: InputMaybe<Scalars['Int']['input']>;
  dtccNumberIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  dtccNumberLT?: InputMaybe<Scalars['Int']['input']>;
  dtccNumberLTE?: InputMaybe<Scalars['Int']['input']>;
  dtccNumberNEQ?: InputMaybe<Scalars['Int']['input']>;
  dtccNumberNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<BrokerDtccWhereInput>;
  or?: InputMaybe<Array<BrokerDtccWhereInput>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type BrokerDealer = Node & {
  __typename?: 'BrokerDealer';
  broker: IntroducingBroker;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  plaidRelationshipOwner: PlaidRelationshipOwner;
  regulatedEntity: RegulatedEntity;
};

export type BrokerDealerPrincipal = Node & {
  __typename?: 'BrokerDealerPrincipal';
  /** CRD number of the principal */
  crdNumber?: Maybe<Scalars['String']['output']>;
  createTime: Scalars['Time']['output'];
  /** external ID of the principal, as provided by the clearing house */
  externalID?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  updateTime: Scalars['Time']['output'];
  /** this field is set to true when the accounts are Auto submitted and this principal is the one that should be set */
  useAsDefault?: Maybe<Scalars['Boolean']['output']>;
};

/** Ordering options for BrokerDealerPrincipal connections */
export type BrokerDealerPrincipalOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order BrokerDealerPrincipals. */
  field: BrokerDealerPrincipalOrderField;
};

/** Properties by which BrokerDealerPrincipal connections can be ordered. */
export enum BrokerDealerPrincipalOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * BrokerDealerPrincipalWhereInput is used for filtering BrokerDealerPrincipal objects.
 * Input was generated by ent.
 */
export type BrokerDealerPrincipalWhereInput = {
  and?: InputMaybe<Array<BrokerDealerPrincipalWhereInput>>;
  /** crd_number field predicates */
  crdNumber?: InputMaybe<Scalars['String']['input']>;
  crdNumberContains?: InputMaybe<Scalars['String']['input']>;
  crdNumberContainsFold?: InputMaybe<Scalars['String']['input']>;
  crdNumberEqualFold?: InputMaybe<Scalars['String']['input']>;
  crdNumberGT?: InputMaybe<Scalars['String']['input']>;
  crdNumberGTE?: InputMaybe<Scalars['String']['input']>;
  crdNumberHasPrefix?: InputMaybe<Scalars['String']['input']>;
  crdNumberHasSuffix?: InputMaybe<Scalars['String']['input']>;
  crdNumberIn?: InputMaybe<Array<Scalars['String']['input']>>;
  crdNumberIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  crdNumberLT?: InputMaybe<Scalars['String']['input']>;
  crdNumberLTE?: InputMaybe<Scalars['String']['input']>;
  crdNumberNEQ?: InputMaybe<Scalars['String']['input']>;
  crdNumberNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  crdNumberNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** external_id field predicates */
  externalID?: InputMaybe<Scalars['String']['input']>;
  externalIDContains?: InputMaybe<Scalars['String']['input']>;
  externalIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  externalIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  externalIDGT?: InputMaybe<Scalars['String']['input']>;
  externalIDGTE?: InputMaybe<Scalars['String']['input']>;
  externalIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  externalIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  externalIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  externalIDLT?: InputMaybe<Scalars['String']['input']>;
  externalIDLTE?: InputMaybe<Scalars['String']['input']>;
  externalIDNEQ?: InputMaybe<Scalars['String']['input']>;
  externalIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<BrokerDealerPrincipalWhereInput>;
  or?: InputMaybe<Array<BrokerDealerPrincipalWhereInput>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** use_as_default field predicates */
  useAsDefault?: InputMaybe<Scalars['Boolean']['input']>;
  useAsDefaultIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  useAsDefaultNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  useAsDefaultNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * BrokerDealerWhereInput is used for filtering BrokerDealer objects.
 * Input was generated by ent.
 */
export type BrokerDealerWhereInput = {
  and?: InputMaybe<Array<BrokerDealerWhereInput>>;
  /** broker edge predicates */
  hasBroker?: InputMaybe<Scalars['Boolean']['input']>;
  hasBrokerWith?: InputMaybe<Array<IntroducingBrokerWhereInput>>;
  /** plaid_relationship_owner edge predicates */
  hasPlaidRelationshipOwner?: InputMaybe<Scalars['Boolean']['input']>;
  hasPlaidRelationshipOwnerWith?: InputMaybe<Array<PlaidRelationshipOwnerWhereInput>>;
  /** regulated_entity edge predicates */
  hasRegulatedEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasRegulatedEntityWith?: InputMaybe<Array<RegulatedEntityWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<BrokerDealerWhereInput>;
  or?: InputMaybe<Array<BrokerDealerWhereInput>>;
};

export type BrokerageAccountParameters = {
  __typename?: 'BrokerageAccountParameters';
  annualIncome?: Maybe<NonDiscretionaryAccountKycUserAnnualIncome>;
  employmentDetails?: Maybe<EmploymentDetails>;
  employmentStatus?: Maybe<NonDiscretionaryAccountKycUserEmploymentStatus>;
  investmentExperience?: Maybe<NonDiscretionaryAccountKycUserInvestmentExperience>;
  investmentObjective?: Maybe<NonDiscretionaryAccountKycUserInvestmentObjective>;
  liquidityNeeds?: Maybe<NonDiscretionaryAccountKycUserLiquidityNeeds>;
  maritalStatus?: Maybe<NonDiscretionaryAccountKycUserMaritalStatus>;
  netWorth?: Maybe<NonDiscretionaryAccountKycUserNetWorth>;
  riskTolerance?: Maybe<UserRiskTolerance>;
  timeHorizon?: Maybe<NonDiscretionaryAccountKycUserTimeHorizon>;
};

export type BrokerageDiscretionaryAccountParameters = {
  __typename?: 'BrokerageDiscretionaryAccountParameters';
  annualIncome?: Maybe<DiscretionaryAccountKycUserAnnualIncome>;
  employmentDetails?: Maybe<EmploymentDetails>;
  employmentStatus?: Maybe<DiscretionaryAccountKycUserEmploymentStatus>;
  investmentObjective?: Maybe<DiscretionaryAccountKycUserInvestmentObjective>;
  netWorth?: Maybe<DiscretionaryAccountKycUserNetWorth>;
  riskTolerance?: Maybe<UserRiskTolerance>;
};

export type CddDocument = {
  __typename?: 'CDDDocument';
  documentID?: Maybe<Scalars['String']['output']>;
};

export type CalculatedBracket = {
  __typename?: 'CalculatedBracket';
  aum?: Maybe<Scalars['Decimal']['output']>;
  cutoff?: Maybe<Scalars['Decimal']['output']>;
  fee?: Maybe<Scalars['Decimal']['output']>;
  proratedRate?: Maybe<Scalars['Decimal']['output']>;
  rate?: Maybe<Scalars['Decimal']['output']>;
};

export type CapitalGains = BaseOrder &
  Node & {
    __typename?: 'CapitalGains';
    account: UserAccount;
    createTime: Scalars['Time']['output'];
    distributions?: Maybe<Array<CapitalGainsDistribution>>;
    id: Scalars['ID']['output'];
    /**
     * isReinvested returns true if the value of the distribution is invested into another
     * asset aside from USD.
     */
    isReinvested: Scalars['Boolean']['output'];
    /** Notes is the notes of the order intended for the principal. */
    note?: Maybe<Scalars['String']['output']>;
    orderStatus: OrderOrderStatus;
    orderType: OrderOrderType;
    /** Date the distribution was issued */
    payDate: Scalars['Date']['output'];
    /** Date the distribution was recorded */
    recordDate: Scalars['Date']['output'];
    requestDetails: RequestDetails;
    /** Amount of the distribution asset being added to an account */
    totalAmount: Scalars['Decimal']['output'];
    /** Value of the distribution */
    totalValue: Scalars['Decimal']['output'];
    updateTime: Scalars['Time']['output'];
  };

/** A connection to a list of items. */
export type CapitalGainsConnection = {
  __typename?: 'CapitalGainsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CapitalGainsEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CapitalGainsDistribution = BaseOrder &
  Node & {
    __typename?: 'CapitalGainsDistribution';
    /** Amount of the distribution asset being added to the portfolio */
    amount: Scalars['Decimal']['output'];
    capitalGains: CapitalGains;
    createTime: Scalars['Time']['output'];
    id: Scalars['ID']['output'];
    /**
     * isReinvested returns true if the value of the distribution is invested into another
     * asset aside from USD.
     */
    isReinvested: Scalars['Boolean']['output'];
    /** Notes is the notes of the order intended for the principal. */
    note?: Maybe<Scalars['String']['output']>;
    orderStatus: OrderOrderStatus;
    orderType: OrderOrderType;
    /** Date the distribution was issued */
    payDate: Scalars['Date']['output'];
    portfolio: UserAccountPortfolio;
    requestDetails: RequestDetails;
    /** Time that the row was settled */
    settlementTime?: Maybe<Scalars['Time']['output']>;
    updateTime: Scalars['Time']['output'];
    /** Value of the distribution */
    value: Scalars['Decimal']['output'];
  };

/** A connection to a list of items. */
export type CapitalGainsDistributionConnection = {
  __typename?: 'CapitalGainsDistributionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CapitalGainsDistributionEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CapitalGainsDistributionEdge = {
  __typename?: 'CapitalGainsDistributionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CapitalGainsDistribution>;
};

/** Ordering options for CapitalGainsDistribution connections */
export type CapitalGainsDistributionOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order CapitalGainsDistributions. */
  field: CapitalGainsDistributionOrderField;
};

/** Properties by which CapitalGainsDistribution connections can be ordered. */
export enum CapitalGainsDistributionOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * CapitalGainsDistributionWhereInput is used for filtering CapitalGainsDistribution objects.
 * Input was generated by ent.
 */
export type CapitalGainsDistributionWhereInput = {
  /** amount field predicates */
  amount?: InputMaybe<Scalars['Decimal']['input']>;
  amountGT?: InputMaybe<Scalars['Decimal']['input']>;
  amountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  amountLT?: InputMaybe<Scalars['Decimal']['input']>;
  amountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  amountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  and?: InputMaybe<Array<CapitalGainsDistributionWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** capital_gains edge predicates */
  hasCapitalGains?: InputMaybe<Scalars['Boolean']['input']>;
  hasCapitalGainsWith?: InputMaybe<Array<CapitalGainsWhereInput>>;
  /** portfolio edge predicates */
  hasPortfolio?: InputMaybe<Scalars['Boolean']['input']>;
  hasPortfolioWith?: InputMaybe<Array<UserAccountPortfolioWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<CapitalGainsDistributionWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<CapitalGainsDistributionWhereInput>>;
  /** pay_date field predicates */
  payDate?: InputMaybe<Scalars['Date']['input']>;
  payDateGT?: InputMaybe<Scalars['Date']['input']>;
  payDateGTE?: InputMaybe<Scalars['Date']['input']>;
  payDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  payDateLT?: InputMaybe<Scalars['Date']['input']>;
  payDateLTE?: InputMaybe<Scalars['Date']['input']>;
  payDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  payDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  /** settlement_time field predicates */
  settlementTime?: InputMaybe<Scalars['Time']['input']>;
  settlementTimeGT?: InputMaybe<Scalars['Time']['input']>;
  settlementTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  settlementTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  settlementTimeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  settlementTimeLT?: InputMaybe<Scalars['Time']['input']>;
  settlementTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  settlementTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  settlementTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  settlementTimeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** value field predicates */
  value?: InputMaybe<Scalars['Decimal']['input']>;
  valueGT?: InputMaybe<Scalars['Decimal']['input']>;
  valueGTE?: InputMaybe<Scalars['Decimal']['input']>;
  valueIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  valueLT?: InputMaybe<Scalars['Decimal']['input']>;
  valueLTE?: InputMaybe<Scalars['Decimal']['input']>;
  valueNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  valueNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

/** An edge in a connection. */
export type CapitalGainsEdge = {
  __typename?: 'CapitalGainsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CapitalGains>;
};

/** Ordering options for CapitalGains connections */
export type CapitalGainsOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order CapitalGainsSlice. */
  field: CapitalGainsOrderField;
};

/** Properties by which CapitalGains connections can be ordered. */
export enum CapitalGainsOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * CapitalGainsWhereInput is used for filtering CapitalGains objects.
 * Input was generated by ent.
 */
export type CapitalGainsWhereInput = {
  and?: InputMaybe<Array<CapitalGainsWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** account edge predicates */
  hasAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountWith?: InputMaybe<Array<UserAccountWhereInput>>;
  /** distributions edge predicates */
  hasDistributions?: InputMaybe<Scalars['Boolean']['input']>;
  hasDistributionsWith?: InputMaybe<Array<CapitalGainsDistributionWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<CapitalGainsWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<CapitalGainsWhereInput>>;
  /** pay_date field predicates */
  payDate?: InputMaybe<Scalars['Date']['input']>;
  payDateGT?: InputMaybe<Scalars['Date']['input']>;
  payDateGTE?: InputMaybe<Scalars['Date']['input']>;
  payDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  payDateLT?: InputMaybe<Scalars['Date']['input']>;
  payDateLTE?: InputMaybe<Scalars['Date']['input']>;
  payDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  payDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  /** record_date field predicates */
  recordDate?: InputMaybe<Scalars['Date']['input']>;
  recordDateGT?: InputMaybe<Scalars['Date']['input']>;
  recordDateGTE?: InputMaybe<Scalars['Date']['input']>;
  recordDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  recordDateLT?: InputMaybe<Scalars['Date']['input']>;
  recordDateLTE?: InputMaybe<Scalars['Date']['input']>;
  recordDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  recordDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  /** total_amount field predicates */
  totalAmount?: InputMaybe<Scalars['Decimal']['input']>;
  totalAmountGT?: InputMaybe<Scalars['Decimal']['input']>;
  totalAmountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalAmountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  totalAmountLT?: InputMaybe<Scalars['Decimal']['input']>;
  totalAmountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalAmountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  totalAmountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** total_value field predicates */
  totalValue?: InputMaybe<Scalars['Decimal']['input']>;
  totalValueGT?: InputMaybe<Scalars['Decimal']['input']>;
  totalValueGTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalValueIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  totalValueLT?: InputMaybe<Scalars['Decimal']['input']>;
  totalValueLTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalValueNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  totalValueNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type CheckTransfer = BaseOrder &
  Node & {
    __typename?: 'CheckTransfer';
    /** Amount is the amount of the asset to be transferred. */
    amount: Scalars['Decimal']['output'];
    createTime: Scalars['Time']['output'];
    /** ErrorCode is the error code of the ach transfer if it failed */
    errorCode?: Maybe<CheckTransferTransferErrorCode>;
    /** ExpectedSettlement is the date that the transfer is expected to settle. */
    expectedSettlementDate?: Maybe<Scalars['Date']['output']>;
    /** external id of the transfer provided by the entity performing the transfer. */
    externalID?: Maybe<Scalars['String']['output']>;
    /** FeeAndCreditOrder is the fee that was assessed on this order. */
    feeOrder?: Maybe<FeeAndCreditOrder>;
    holdReason?: Maybe<CheckTransferTransferHoldReason>;
    id: Scalars['ID']['output'];
    /** Amount of a deposit that was immediately cleared as an instant deposit. */
    instantDepositAmount?: Maybe<Scalars['Decimal']['output']>;
    isReturned: Scalars['Boolean']['output'];
    /** Lockup is the lockup that was created for this transfer. */
    lockup?: Maybe<AssetLockup>;
    /** Notes is the notes of the order intended for the principal. */
    note?: Maybe<Scalars['String']['output']>;
    orderStatus: OrderOrderStatus;
    orderType: OrderOrderType;
    portfolio: UserAccountPortfolio;
    /** Reason is a description as to why the transfer failed. */
    reason?: Maybe<Scalars['String']['output']>;
    requestDetails: RequestDetails;
    retirementContributionInfo?: Maybe<RetirementContributionInfo>;
    retirementDistributionInfo?: Maybe<RetirementDistributionInfo>;
    /** Fee assessed because of a returned transfer. */
    returnFee?: Maybe<Scalars['Decimal']['output']>;
    status: CheckTransferTransferStatus;
    /** Fee assessed by fintron. */
    transferFee?: Maybe<Scalars['Decimal']['output']>;
    type: CheckTransferTransferType;
    updateTime: Scalars['Time']['output'];
    user: User;
  };

/** A connection to a list of items. */
export type CheckTransferConnection = {
  __typename?: 'CheckTransferConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CheckTransferEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CheckTransferEdge = {
  __typename?: 'CheckTransferEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CheckTransfer>;
};

/** Ordering options for CheckTransfer connections */
export type CheckTransferOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order CheckTransfers. */
  field: CheckTransferOrderField;
};

/** Properties by which CheckTransfer connections can be ordered. */
export enum CheckTransferOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/** CheckTransferTransferErrorCode is enum for the field error_code */
export enum CheckTransferTransferErrorCode {
  TransferErrorCodeExceedsDailyLimit = 'TRANSFER_ERROR_CODE_EXCEEDS_DAILY_LIMIT',
  TransferErrorCodeInsufficientFunds = 'TRANSFER_ERROR_CODE_INSUFFICIENT_FUNDS',
  TransferErrorCodeInvalidAccountStatus = 'TRANSFER_ERROR_CODE_INVALID_ACCOUNT_STATUS',
  TransferErrorCodeInvalidAmount = 'TRANSFER_ERROR_CODE_INVALID_AMOUNT',
  TransferErrorCodeInvalidOrder = 'TRANSFER_ERROR_CODE_INVALID_ORDER',
  TransferErrorCodeOther = 'TRANSFER_ERROR_CODE_OTHER',
  TransferErrorCodeServerError = 'TRANSFER_ERROR_CODE_SERVER_ERROR',
  TransferErrorCodeUnapproved = 'TRANSFER_ERROR_CODE_UNAPPROVED',
  TransferErrorCodeUnsupported = 'TRANSFER_ERROR_CODE_UNSUPPORTED',
  TransferErrorCodeValidationFailed = 'TRANSFER_ERROR_CODE_VALIDATION_FAILED',
}

/** CheckTransferTransferHoldReason is enum for the field hold_reason */
export enum CheckTransferTransferHoldReason {
  TransferHoldReasonAccountNeedsAttention = 'TRANSFER_HOLD_REASON_ACCOUNT_NEEDS_ATTENTION',
  TransferHoldReasonAccountOpenPending = 'TRANSFER_HOLD_REASON_ACCOUNT_OPEN_PENDING',
  TransferHoldReasonWithdrawLockup = 'TRANSFER_HOLD_REASON_WITHDRAW_LOCKUP',
}

/** CheckTransferTransferStatus is enum for the field status */
export enum CheckTransferTransferStatus {
  TransferStatusCanceled = 'TRANSFER_STATUS_CANCELED',
  TransferStatusCanceling = 'TRANSFER_STATUS_CANCELING',
  TransferStatusFailed = 'TRANSFER_STATUS_FAILED',
  TransferStatusFunded = 'TRANSFER_STATUS_FUNDED',
  TransferStatusHold = 'TRANSFER_STATUS_HOLD',
  TransferStatusIssued = 'TRANSFER_STATUS_ISSUED',
  TransferStatusNew = 'TRANSFER_STATUS_NEW',
  TransferStatusSettled = 'TRANSFER_STATUS_SETTLED',
  TransferStatusUnsettled = 'TRANSFER_STATUS_UNSETTLED',
}

/** CheckTransferTransferType is enum for the field type */
export enum CheckTransferTransferType {
  TransferTypeDeposit = 'TRANSFER_TYPE_DEPOSIT',
  TransferTypeWithdraw = 'TRANSFER_TYPE_WITHDRAW',
}

/**
 * CheckTransferWhereInput is used for filtering CheckTransfer objects.
 * Input was generated by ent.
 */
export type CheckTransferWhereInput = {
  /** amount field predicates */
  amount?: InputMaybe<Scalars['Decimal']['input']>;
  amountGT?: InputMaybe<Scalars['Decimal']['input']>;
  amountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  amountLT?: InputMaybe<Scalars['Decimal']['input']>;
  amountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  amountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  and?: InputMaybe<Array<CheckTransferWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** error_code field predicates */
  errorCode?: InputMaybe<CheckTransferTransferErrorCode>;
  errorCodeIn?: InputMaybe<Array<CheckTransferTransferErrorCode>>;
  errorCodeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  errorCodeNEQ?: InputMaybe<CheckTransferTransferErrorCode>;
  errorCodeNotIn?: InputMaybe<Array<CheckTransferTransferErrorCode>>;
  errorCodeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** expected_settlement_date field predicates */
  expectedSettlementDate?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateGT?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateGTE?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  expectedSettlementDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  expectedSettlementDateLT?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateLTE?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  expectedSettlementDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** external_id field predicates */
  externalID?: InputMaybe<Scalars['String']['input']>;
  externalIDContains?: InputMaybe<Scalars['String']['input']>;
  externalIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  externalIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  externalIDGT?: InputMaybe<Scalars['String']['input']>;
  externalIDGTE?: InputMaybe<Scalars['String']['input']>;
  externalIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  externalIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  externalIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  externalIDLT?: InputMaybe<Scalars['String']['input']>;
  externalIDLTE?: InputMaybe<Scalars['String']['input']>;
  externalIDNEQ?: InputMaybe<Scalars['String']['input']>;
  externalIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** fee_order edge predicates */
  hasFeeOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasFeeOrderWith?: InputMaybe<Array<FeeAndCreditOrderWhereInput>>;
  /** lockup edge predicates */
  hasLockup?: InputMaybe<Scalars['Boolean']['input']>;
  hasLockupWith?: InputMaybe<Array<AssetLockupWhereInput>>;
  /** portfolio edge predicates */
  hasPortfolio?: InputMaybe<Scalars['Boolean']['input']>;
  hasPortfolioWith?: InputMaybe<Array<UserAccountPortfolioWhereInput>>;
  /** retirement_contribution_info edge predicates */
  hasRetirementContributionInfo?: InputMaybe<Scalars['Boolean']['input']>;
  hasRetirementContributionInfoWith?: InputMaybe<Array<RetirementContributionInfoWhereInput>>;
  /** retirement_distribution_info edge predicates */
  hasRetirementDistributionInfo?: InputMaybe<Scalars['Boolean']['input']>;
  hasRetirementDistributionInfoWith?: InputMaybe<Array<RetirementDistributionInfoWhereInput>>;
  hasUserWith?: InputMaybe<UserWhereInput>;
  /** hold_reason field predicates */
  holdReason?: InputMaybe<CheckTransferTransferHoldReason>;
  holdReasonIn?: InputMaybe<Array<CheckTransferTransferHoldReason>>;
  holdReasonIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  holdReasonNEQ?: InputMaybe<CheckTransferTransferHoldReason>;
  holdReasonNotIn?: InputMaybe<Array<CheckTransferTransferHoldReason>>;
  holdReasonNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** instant_deposit_amount field predicates */
  instantDepositAmount?: InputMaybe<Scalars['Decimal']['input']>;
  instantDepositAmountGT?: InputMaybe<Scalars['Decimal']['input']>;
  instantDepositAmountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  instantDepositAmountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  instantDepositAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  instantDepositAmountLT?: InputMaybe<Scalars['Decimal']['input']>;
  instantDepositAmountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  instantDepositAmountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  instantDepositAmountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  instantDepositAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** is_returned field predicates */
  isReturned?: InputMaybe<Scalars['Boolean']['input']>;
  isReturnedNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<CheckTransferWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<CheckTransferWhereInput>>;
  /** reason field predicates */
  reason?: InputMaybe<Scalars['String']['input']>;
  reasonContains?: InputMaybe<Scalars['String']['input']>;
  reasonContainsFold?: InputMaybe<Scalars['String']['input']>;
  reasonEqualFold?: InputMaybe<Scalars['String']['input']>;
  reasonGT?: InputMaybe<Scalars['String']['input']>;
  reasonGTE?: InputMaybe<Scalars['String']['input']>;
  reasonHasPrefix?: InputMaybe<Scalars['String']['input']>;
  reasonHasSuffix?: InputMaybe<Scalars['String']['input']>;
  reasonIn?: InputMaybe<Array<Scalars['String']['input']>>;
  reasonIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  reasonLT?: InputMaybe<Scalars['String']['input']>;
  reasonLTE?: InputMaybe<Scalars['String']['input']>;
  reasonNEQ?: InputMaybe<Scalars['String']['input']>;
  reasonNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  reasonNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** return_fee field predicates */
  returnFee?: InputMaybe<Scalars['Decimal']['input']>;
  returnFeeGT?: InputMaybe<Scalars['Decimal']['input']>;
  returnFeeGTE?: InputMaybe<Scalars['Decimal']['input']>;
  returnFeeIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  returnFeeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  returnFeeLT?: InputMaybe<Scalars['Decimal']['input']>;
  returnFeeLTE?: InputMaybe<Scalars['Decimal']['input']>;
  returnFeeNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  returnFeeNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  returnFeeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** status field predicates */
  status?: InputMaybe<CheckTransferTransferStatus>;
  statusIn?: InputMaybe<Array<CheckTransferTransferStatus>>;
  statusNEQ?: InputMaybe<CheckTransferTransferStatus>;
  statusNotIn?: InputMaybe<Array<CheckTransferTransferStatus>>;
  /** transfer_fee field predicates */
  transferFee?: InputMaybe<Scalars['Decimal']['input']>;
  transferFeeGT?: InputMaybe<Scalars['Decimal']['input']>;
  transferFeeGTE?: InputMaybe<Scalars['Decimal']['input']>;
  transferFeeIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  transferFeeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  transferFeeLT?: InputMaybe<Scalars['Decimal']['input']>;
  transferFeeLTE?: InputMaybe<Scalars['Decimal']['input']>;
  transferFeeNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  transferFeeNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  transferFeeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** type field predicates */
  type?: InputMaybe<CheckTransferTransferType>;
  typeIn?: InputMaybe<Array<CheckTransferTransferType>>;
  typeNEQ?: InputMaybe<CheckTransferTransferType>;
  typeNotIn?: InputMaybe<Array<CheckTransferTransferType>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type CloseAccountRequest = Node & {
  __typename?: 'CloseAccountRequest';
  createTime: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  /** The provided reason for the account closure */
  reason: Scalars['String']['output'];
  /** The time at which the action was requested. */
  requestTime: Scalars['Time']['output'];
  requestUUID?: Maybe<Scalars['UUID']['output']>;
  requestor: Requestor;
  updateTime: Scalars['Time']['output'];
  /** The account that is being closed */
  userAccount: UserAccount;
};

/** Ordering options for CloseAccountRequest connections */
export type CloseAccountRequestOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order CloseAccountRequests. */
  field: CloseAccountRequestOrderField;
};

/** Properties by which CloseAccountRequest connections can be ordered. */
export enum CloseAccountRequestOrderField {
  RequestTime = 'REQUEST_TIME',
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * CloseAccountRequestWhereInput is used for filtering CloseAccountRequest objects.
 * Input was generated by ent.
 */
export type CloseAccountRequestWhereInput = {
  and?: InputMaybe<Array<CloseAccountRequestWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** user_account edge predicates */
  hasUserAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserAccountWith?: InputMaybe<Array<UserAccountWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<CloseAccountRequestWhereInput>;
  or?: InputMaybe<Array<CloseAccountRequestWhereInput>>;
  /** reason field predicates */
  reason?: InputMaybe<Scalars['String']['input']>;
  reasonContains?: InputMaybe<Scalars['String']['input']>;
  reasonContainsFold?: InputMaybe<Scalars['String']['input']>;
  reasonEqualFold?: InputMaybe<Scalars['String']['input']>;
  reasonGT?: InputMaybe<Scalars['String']['input']>;
  reasonGTE?: InputMaybe<Scalars['String']['input']>;
  reasonHasPrefix?: InputMaybe<Scalars['String']['input']>;
  reasonHasSuffix?: InputMaybe<Scalars['String']['input']>;
  reasonIn?: InputMaybe<Array<Scalars['String']['input']>>;
  reasonLT?: InputMaybe<Scalars['String']['input']>;
  reasonLTE?: InputMaybe<Scalars['String']['input']>;
  reasonNEQ?: InputMaybe<Scalars['String']['input']>;
  reasonNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** request_time field predicates */
  requestTime?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  requestTimeLT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** request_uuid field predicates */
  requestUUID?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  requestUUIDLT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDLTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNEQ?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNotIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** requestor field predicates */
  requestor?: InputMaybe<Requestor>;
  requestorIn?: InputMaybe<Array<Requestor>>;
  requestorNEQ?: InputMaybe<Requestor>;
  requestorNotIn?: InputMaybe<Array<Requestor>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type ControlPersonDetails = {
  __typename?: 'ControlPersonDetails';
  /** @deprecated Use `companySymbols`. */
  CompanySymbols: Array<Scalars['String']['output']>;
  companySymbols?: Maybe<Array<Scalars['String']['output']>>;
  isControlPerson: Scalars['Boolean']['output'];
};

export type CorporateAction = Node & {
  __typename?: 'CorporateAction';
  asset: SecurityAsset;
  createTime: Scalars['Time']['output'];
  effectiveDate: Scalars['Date']['output'];
  /** time the action was completed */
  executionComplete?: Maybe<Scalars['Time']['output']>;
  /** time the action was started */
  executionStart?: Maybe<Scalars['Time']['output']>;
  grants?: Maybe<Array<CorporateActionGrant>>;
  id: Scalars['ID']['output'];
  /** if true, the post action ratio will be treated as a split. */
  isSplit: Scalars['Boolean']['output'];
  metadataChange?: Maybe<CorporateActionMetadataChange>;
  /** notes left by the principal to justify or contextualize the action */
  notes: Scalars['String']['output'];
  /** price to use as the cost basis in calculating cost basis adjustments. */
  postActionCostBasis?: Maybe<Scalars['Decimal']['output']>;
  /** the numerator of the ratio to use for the post action ratio */
  postActionRatioAntecedent: Scalars['Decimal']['output'];
  /** the denominator of the ratio to use for the post action ratio */
  postActionRatioConsequent: Scalars['Decimal']['output'];
  recordDate: Scalars['Date']['output'];
  roundingType: CorporateActionRoundingType;
  /** contains the general type of the corporate action */
  type: CorporateActionCorporateActionType;
  updateTime: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type CorporateActionConnection = {
  __typename?: 'CorporateActionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CorporateActionEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** CorporateActionCorporateActionType is enum for the field type */
export enum CorporateActionCorporateActionType {
  CorporateActionTypeCashBuyout = 'CORPORATE_ACTION_TYPE_CASH_BUYOUT',
  CorporateActionTypeMetadataChange = 'CORPORATE_ACTION_TYPE_METADATA_CHANGE',
  CorporateActionTypeReverseSplit = 'CORPORATE_ACTION_TYPE_REVERSE_SPLIT',
  CorporateActionTypeSplit = 'CORPORATE_ACTION_TYPE_SPLIT',
  CorporateActionTypeStockMerger = 'CORPORATE_ACTION_TYPE_STOCK_MERGER',
  CorporateActionTypeStockOther = 'CORPORATE_ACTION_TYPE_STOCK_OTHER',
  CorporateActionTypeStockSpinoff = 'CORPORATE_ACTION_TYPE_STOCK_SPINOFF',
}

/** An edge in a connection. */
export type CorporateActionEdge = {
  __typename?: 'CorporateActionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CorporateAction>;
};

export type CorporateActionGrant = Node & {
  __typename?: 'CorporateActionGrant';
  action: CorporateAction;
  asset: BaseAsset;
  /** price to used as the cost basis for the granted asset  at the time of the grant (in USD). To get current cost basis, divide by adjusted_ratio */
  costBasis: Scalars['Decimal']['output'];
  hasTransferRights: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** ratio of input shares to output shares at the time of the grant. */
  ratio: Scalars['Decimal']['output'];
  roundingType: CorporateActionGrantRoundingType;
  settlementDate?: Maybe<Scalars['Date']['output']>;
};

/** CorporateActionGrantRoundingType is enum for the field rounding_type */
export enum CorporateActionGrantRoundingType {
  RoundingTypeCeil_0 = 'ROUNDING_TYPE_CEIL_0',
  RoundingTypeCeil_5 = 'ROUNDING_TYPE_CEIL_5',
  RoundingTypeFloor_0 = 'ROUNDING_TYPE_FLOOR_0',
  RoundingTypeFloor_5 = 'ROUNDING_TYPE_FLOOR_5',
  RoundingTypeRound_0 = 'ROUNDING_TYPE_ROUND_0',
  RoundingTypeRound_5 = 'ROUNDING_TYPE_ROUND_5',
}

/**
 * CorporateActionGrantWhereInput is used for filtering CorporateActionGrant objects.
 * Input was generated by ent.
 */
export type CorporateActionGrantWhereInput = {
  and?: InputMaybe<Array<CorporateActionGrantWhereInput>>;
  /** cost_basis field predicates */
  costBasis?: InputMaybe<Scalars['Decimal']['input']>;
  costBasisGT?: InputMaybe<Scalars['Decimal']['input']>;
  costBasisGTE?: InputMaybe<Scalars['Decimal']['input']>;
  costBasisIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  costBasisLT?: InputMaybe<Scalars['Decimal']['input']>;
  costBasisLTE?: InputMaybe<Scalars['Decimal']['input']>;
  costBasisNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  costBasisNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** action edge predicates */
  hasAction?: InputMaybe<Scalars['Boolean']['input']>;
  hasActionWith?: InputMaybe<Array<CorporateActionWhereInput>>;
  /** has_transfer_rights field predicates */
  hasTransferRights?: InputMaybe<Scalars['Boolean']['input']>;
  hasTransferRightsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<CorporateActionGrantWhereInput>;
  or?: InputMaybe<Array<CorporateActionGrantWhereInput>>;
  /** ratio field predicates */
  ratio?: InputMaybe<Scalars['Decimal']['input']>;
  ratioGT?: InputMaybe<Scalars['Decimal']['input']>;
  ratioGTE?: InputMaybe<Scalars['Decimal']['input']>;
  ratioIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  ratioLT?: InputMaybe<Scalars['Decimal']['input']>;
  ratioLTE?: InputMaybe<Scalars['Decimal']['input']>;
  ratioNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  ratioNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** rounding_type field predicates */
  roundingType?: InputMaybe<CorporateActionGrantRoundingType>;
  roundingTypeIn?: InputMaybe<Array<CorporateActionGrantRoundingType>>;
  roundingTypeNEQ?: InputMaybe<CorporateActionGrantRoundingType>;
  roundingTypeNotIn?: InputMaybe<Array<CorporateActionGrantRoundingType>>;
  /** settlement_date field predicates */
  settlementDate?: InputMaybe<Scalars['Date']['input']>;
  settlementDateGT?: InputMaybe<Scalars['Date']['input']>;
  settlementDateGTE?: InputMaybe<Scalars['Date']['input']>;
  settlementDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  settlementDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  settlementDateLT?: InputMaybe<Scalars['Date']['input']>;
  settlementDateLTE?: InputMaybe<Scalars['Date']['input']>;
  settlementDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  settlementDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  settlementDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CorporateActionMetadataChange = {
  __typename?: 'CorporateActionMetadataChange';
  cusip?: Maybe<Scalars['String']['output']>;
  symbol?: Maybe<Scalars['String']['output']>;
};

export type CorporateActionModification = BaseOrder &
  Node & {
    __typename?: 'CorporateActionModification';
    /** if the corporate action changes past orders they should be linked here */
    affectedOrders?: Maybe<Array<Order>>;
    /** calculated effective amount for input asset on the effective date. */
    amountOnEffectiveDate: Scalars['Decimal']['output'];
    /** calculated effective amount for input asset on the record date. */
    amountOnRecordDate: Scalars['Decimal']['output'];
    corporateAction: CorporateAction;
    createTime: Scalars['Time']['output'];
    effectiveDate?: Maybe<Scalars['Date']['output']>;
    id: Scalars['ID']['output'];
    /** Notes is the notes of the order intended for the principal. */
    note?: Maybe<Scalars['String']['output']>;
    orderStatus: OrderOrderStatus;
    orderType: OrderOrderType;
    requestDetails: RequestDetails;
    status: CorporateActionModificationOrderStatus;
    updateTime: Scalars['Time']['output'];
    user: User;
  };

/** A connection to a list of items. */
export type CorporateActionModificationConnection = {
  __typename?: 'CorporateActionModificationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CorporateActionModificationEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CorporateActionModificationEdge = {
  __typename?: 'CorporateActionModificationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CorporateActionModification>;
};

/** Ordering options for CorporateActionModification connections */
export type CorporateActionModificationOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order CorporateActionModifications. */
  field: CorporateActionModificationOrderField;
};

/** Properties by which CorporateActionModification connections can be ordered. */
export enum CorporateActionModificationOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/** CorporateActionModificationOrderStatus is enum for the field status */
export enum CorporateActionModificationOrderStatus {
  OrderStatusCanceled = 'ORDER_STATUS_CANCELED',
  OrderStatusCanceling = 'ORDER_STATUS_CANCELING',
  OrderStatusDone = 'ORDER_STATUS_DONE',
  OrderStatusFailed = 'ORDER_STATUS_FAILED',
  OrderStatusFinalizing = 'ORDER_STATUS_FINALIZING',
  OrderStatusProcessing = 'ORDER_STATUS_PROCESSING',
}

/**
 * CorporateActionModificationWhereInput is used for filtering CorporateActionModification objects.
 * Input was generated by ent.
 */
export type CorporateActionModificationWhereInput = {
  /** amount_on_effective_date field predicates */
  amountOnEffectiveDate?: InputMaybe<Scalars['Decimal']['input']>;
  amountOnEffectiveDateGT?: InputMaybe<Scalars['Decimal']['input']>;
  amountOnEffectiveDateGTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountOnEffectiveDateIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  amountOnEffectiveDateLT?: InputMaybe<Scalars['Decimal']['input']>;
  amountOnEffectiveDateLTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountOnEffectiveDateNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  amountOnEffectiveDateNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** amount_on_record_date field predicates */
  amountOnRecordDate?: InputMaybe<Scalars['Decimal']['input']>;
  amountOnRecordDateGT?: InputMaybe<Scalars['Decimal']['input']>;
  amountOnRecordDateGTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountOnRecordDateIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  amountOnRecordDateLT?: InputMaybe<Scalars['Decimal']['input']>;
  amountOnRecordDateLTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountOnRecordDateNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  amountOnRecordDateNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  and?: InputMaybe<Array<CorporateActionModificationWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** effective_date field predicates */
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateGT?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateGTE?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  effectiveDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  effectiveDateLT?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateLTE?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  effectiveDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** affected_orders edge predicates */
  hasAffectedOrders?: InputMaybe<Scalars['Boolean']['input']>;
  hasAffectedOrdersWith?: InputMaybe<Array<OrderWhereInput>>;
  /** corporate_action edge predicates */
  hasCorporateAction?: InputMaybe<Scalars['Boolean']['input']>;
  hasCorporateActionWith?: InputMaybe<Array<CorporateActionWhereInput>>;
  hasUserWith?: InputMaybe<UserWhereInput>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<CorporateActionModificationWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<CorporateActionModificationWhereInput>>;
  /** status field predicates */
  status?: InputMaybe<CorporateActionModificationOrderStatus>;
  statusIn?: InputMaybe<Array<CorporateActionModificationOrderStatus>>;
  statusNEQ?: InputMaybe<CorporateActionModificationOrderStatus>;
  statusNotIn?: InputMaybe<Array<CorporateActionModificationOrderStatus>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** Ordering options for CorporateAction connections */
export type CorporateActionOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order CorporateActions. */
  field: CorporateActionOrderField;
};

/** Properties by which CorporateAction connections can be ordered. */
export enum CorporateActionOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/** CorporateActionRoundingType is enum for the field rounding_type */
export enum CorporateActionRoundingType {
  RoundingTypeCeil_0 = 'ROUNDING_TYPE_CEIL_0',
  RoundingTypeCeil_5 = 'ROUNDING_TYPE_CEIL_5',
  RoundingTypeFloor_0 = 'ROUNDING_TYPE_FLOOR_0',
  RoundingTypeFloor_5 = 'ROUNDING_TYPE_FLOOR_5',
  RoundingTypeRound_0 = 'ROUNDING_TYPE_ROUND_0',
  RoundingTypeRound_5 = 'ROUNDING_TYPE_ROUND_5',
}

/**
 * CorporateActionWhereInput is used for filtering CorporateAction objects.
 * Input was generated by ent.
 */
export type CorporateActionWhereInput = {
  and?: InputMaybe<Array<CorporateActionWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** effective_date field predicates */
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateGT?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateGTE?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  effectiveDateLT?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateLTE?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  /** execution_complete field predicates */
  executionComplete?: InputMaybe<Scalars['Time']['input']>;
  executionCompleteGT?: InputMaybe<Scalars['Time']['input']>;
  executionCompleteGTE?: InputMaybe<Scalars['Time']['input']>;
  executionCompleteIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  executionCompleteIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  executionCompleteLT?: InputMaybe<Scalars['Time']['input']>;
  executionCompleteLTE?: InputMaybe<Scalars['Time']['input']>;
  executionCompleteNEQ?: InputMaybe<Scalars['Time']['input']>;
  executionCompleteNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  executionCompleteNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** execution_start field predicates */
  executionStart?: InputMaybe<Scalars['Time']['input']>;
  executionStartGT?: InputMaybe<Scalars['Time']['input']>;
  executionStartGTE?: InputMaybe<Scalars['Time']['input']>;
  executionStartIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  executionStartIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  executionStartLT?: InputMaybe<Scalars['Time']['input']>;
  executionStartLTE?: InputMaybe<Scalars['Time']['input']>;
  executionStartNEQ?: InputMaybe<Scalars['Time']['input']>;
  executionStartNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  executionStartNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** asset edge predicates */
  hasAsset?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssetWith?: InputMaybe<Array<SecurityAssetWhereInput>>;
  /** grants edge predicates */
  hasGrants?: InputMaybe<Scalars['Boolean']['input']>;
  hasGrantsWith?: InputMaybe<Array<CorporateActionGrantWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_split field predicates */
  isSplit?: InputMaybe<Scalars['Boolean']['input']>;
  isSplitNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<CorporateActionWhereInput>;
  /** notes field predicates */
  notes?: InputMaybe<Scalars['String']['input']>;
  notesContains?: InputMaybe<Scalars['String']['input']>;
  notesContainsFold?: InputMaybe<Scalars['String']['input']>;
  notesEqualFold?: InputMaybe<Scalars['String']['input']>;
  notesGT?: InputMaybe<Scalars['String']['input']>;
  notesGTE?: InputMaybe<Scalars['String']['input']>;
  notesHasPrefix?: InputMaybe<Scalars['String']['input']>;
  notesHasSuffix?: InputMaybe<Scalars['String']['input']>;
  notesIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notesLT?: InputMaybe<Scalars['String']['input']>;
  notesLTE?: InputMaybe<Scalars['String']['input']>;
  notesNEQ?: InputMaybe<Scalars['String']['input']>;
  notesNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  or?: InputMaybe<Array<CorporateActionWhereInput>>;
  /** post_action_cost_basis field predicates */
  postActionCostBasis?: InputMaybe<Scalars['Decimal']['input']>;
  postActionCostBasisGT?: InputMaybe<Scalars['Decimal']['input']>;
  postActionCostBasisGTE?: InputMaybe<Scalars['Decimal']['input']>;
  postActionCostBasisIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  postActionCostBasisIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  postActionCostBasisLT?: InputMaybe<Scalars['Decimal']['input']>;
  postActionCostBasisLTE?: InputMaybe<Scalars['Decimal']['input']>;
  postActionCostBasisNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  postActionCostBasisNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  postActionCostBasisNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** post_action_ratio_antecedent field predicates */
  postActionRatioAntecedent?: InputMaybe<Scalars['Decimal']['input']>;
  postActionRatioAntecedentGT?: InputMaybe<Scalars['Decimal']['input']>;
  postActionRatioAntecedentGTE?: InputMaybe<Scalars['Decimal']['input']>;
  postActionRatioAntecedentIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  postActionRatioAntecedentLT?: InputMaybe<Scalars['Decimal']['input']>;
  postActionRatioAntecedentLTE?: InputMaybe<Scalars['Decimal']['input']>;
  postActionRatioAntecedentNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  postActionRatioAntecedentNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** post_action_ratio_consequent field predicates */
  postActionRatioConsequent?: InputMaybe<Scalars['Decimal']['input']>;
  postActionRatioConsequentGT?: InputMaybe<Scalars['Decimal']['input']>;
  postActionRatioConsequentGTE?: InputMaybe<Scalars['Decimal']['input']>;
  postActionRatioConsequentIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  postActionRatioConsequentLT?: InputMaybe<Scalars['Decimal']['input']>;
  postActionRatioConsequentLTE?: InputMaybe<Scalars['Decimal']['input']>;
  postActionRatioConsequentNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  postActionRatioConsequentNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** record_date field predicates */
  recordDate?: InputMaybe<Scalars['Date']['input']>;
  recordDateGT?: InputMaybe<Scalars['Date']['input']>;
  recordDateGTE?: InputMaybe<Scalars['Date']['input']>;
  recordDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  recordDateLT?: InputMaybe<Scalars['Date']['input']>;
  recordDateLTE?: InputMaybe<Scalars['Date']['input']>;
  recordDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  recordDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  /** rounding_type field predicates */
  roundingType?: InputMaybe<CorporateActionRoundingType>;
  roundingTypeIn?: InputMaybe<Array<CorporateActionRoundingType>>;
  roundingTypeNEQ?: InputMaybe<CorporateActionRoundingType>;
  roundingTypeNotIn?: InputMaybe<Array<CorporateActionRoundingType>>;
  /** type field predicates */
  type?: InputMaybe<CorporateActionCorporateActionType>;
  typeIn?: InputMaybe<Array<CorporateActionCorporateActionType>>;
  typeNEQ?: InputMaybe<CorporateActionCorporateActionType>;
  typeNotIn?: InputMaybe<Array<CorporateActionCorporateActionType>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type Country = Node & {
  __typename?: 'Country';
  alpha2Code?: Maybe<Scalars['String']['output']>;
  alpha3Code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type CountryConnection = {
  __typename?: 'CountryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CountryEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CountryEdge = {
  __typename?: 'CountryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Country>;
};

/** Ordering options for Country connections */
export type CountryOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Countries. */
  field: CountryOrderField;
};

/** Properties by which Country connections can be ordered. */
export enum CountryOrderField {
  Alpha2Code = 'ALPHA2_CODE',
  Alpha3Code = 'ALPHA3_CODE',
  Name = 'NAME',
}

/**
 * CountryWhereInput is used for filtering Country objects.
 * Input was generated by ent.
 */
export type CountryWhereInput = {
  /** alpha2_code field predicates */
  alpha2Code?: InputMaybe<Scalars['String']['input']>;
  alpha2CodeContains?: InputMaybe<Scalars['String']['input']>;
  alpha2CodeContainsFold?: InputMaybe<Scalars['String']['input']>;
  alpha2CodeEqualFold?: InputMaybe<Scalars['String']['input']>;
  alpha2CodeGT?: InputMaybe<Scalars['String']['input']>;
  alpha2CodeGTE?: InputMaybe<Scalars['String']['input']>;
  alpha2CodeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  alpha2CodeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  alpha2CodeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  alpha2CodeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  alpha2CodeLT?: InputMaybe<Scalars['String']['input']>;
  alpha2CodeLTE?: InputMaybe<Scalars['String']['input']>;
  alpha2CodeNEQ?: InputMaybe<Scalars['String']['input']>;
  alpha2CodeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  alpha2CodeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** alpha3_code field predicates */
  alpha3Code?: InputMaybe<Scalars['String']['input']>;
  alpha3CodeContains?: InputMaybe<Scalars['String']['input']>;
  alpha3CodeContainsFold?: InputMaybe<Scalars['String']['input']>;
  alpha3CodeEqualFold?: InputMaybe<Scalars['String']['input']>;
  alpha3CodeGT?: InputMaybe<Scalars['String']['input']>;
  alpha3CodeGTE?: InputMaybe<Scalars['String']['input']>;
  alpha3CodeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  alpha3CodeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  alpha3CodeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  alpha3CodeLT?: InputMaybe<Scalars['String']['input']>;
  alpha3CodeLTE?: InputMaybe<Scalars['String']['input']>;
  alpha3CodeNEQ?: InputMaybe<Scalars['String']['input']>;
  alpha3CodeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  and?: InputMaybe<Array<CountryWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<CountryWhereInput>;
  or?: InputMaybe<Array<CountryWhereInput>>;
};

export type CryptoAsset = BaseAsset &
  Node & {
    __typename?: 'CryptoAsset';
    /** active indicates if the asset is currently active/exists. Examples of an inactive asset would be a delisted stock or a token that no longer exists. */
    active: Scalars['Boolean']['output'];
    circulatingSupply: Scalars['Int']['output'];
    /** Background color to use when displaying in the app */
    color?: Maybe<Scalars['String']['output']>;
    createTime: Scalars['Time']['output'];
    /** Description of the asset */
    description?: Maybe<Scalars['String']['output']>;
    /** hidden is used to determine if the asset should be displayed in the app */
    hidden: Scalars['Boolean']['output'];
    /** image to show when displaying the asset */
    iconURI?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    /**
     * image to show when displaying the asset
     * @deprecated No longer used.
     */
    imageLogo?: Maybe<Scalars['String']['output']>;
    isDefi: Scalars['Boolean']['output'];
    isEthereumToken: Scalars['Boolean']['output'];
    isLayer1: Scalars['Boolean']['output'];
    isSmartContract: Scalars['Boolean']['output'];
    isWeb3: Scalars['Boolean']['output'];
    /** image to show when displaying the asset */
    logoURI?: Maybe<Scalars['String']['output']>;
    market?: Maybe<AssetMarketData>;
    marketCap: Scalars['Decimal']['output'];
    name: Scalars['String']['output'];
    news: Array<AssetNews>;
    symbol: Scalars['String']['output'];
    type: AssetAssetType;
    updateTime: Scalars['Time']['output'];
    website: Scalars['String']['output'];
  };

export type CryptoAssetNewsArgs = {
  limit: Scalars['Int']['input'];
};

/** A connection to a list of items. */
export type CryptoAssetConnection = {
  __typename?: 'CryptoAssetConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CryptoAssetEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CryptoAssetEdge = {
  __typename?: 'CryptoAssetEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CryptoAsset>;
};

/** Ordering options for CryptoAsset connections */
export type CryptoAssetOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order CryptoAssets. */
  field: CryptoAssetOrderField;
};

/** Properties by which CryptoAsset connections can be ordered. */
export enum CryptoAssetOrderField {
  CreateTime = 'create_time',
  Name = 'name',
  Symbol = 'symbol',
  UpdateTime = 'update_time',
}

/**
 * CryptoAssetWhereInput is used for filtering CryptoAsset objects.
 * Input was generated by ent.
 */
export type CryptoAssetWhereInput = {
  /** active field predicates */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  activeNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<CryptoAssetWhereInput>>;
  /** circulating_supply field predicates */
  circulatingSupply?: InputMaybe<Scalars['Int']['input']>;
  circulatingSupplyGT?: InputMaybe<Scalars['Int']['input']>;
  circulatingSupplyGTE?: InputMaybe<Scalars['Int']['input']>;
  circulatingSupplyIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  circulatingSupplyLT?: InputMaybe<Scalars['Int']['input']>;
  circulatingSupplyLTE?: InputMaybe<Scalars['Int']['input']>;
  circulatingSupplyNEQ?: InputMaybe<Scalars['Int']['input']>;
  circulatingSupplyNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** color field predicates */
  color?: InputMaybe<Scalars['String']['input']>;
  colorContains?: InputMaybe<Scalars['String']['input']>;
  colorContainsFold?: InputMaybe<Scalars['String']['input']>;
  colorEqualFold?: InputMaybe<Scalars['String']['input']>;
  colorGT?: InputMaybe<Scalars['String']['input']>;
  colorGTE?: InputMaybe<Scalars['String']['input']>;
  colorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  colorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  colorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  colorIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  colorLT?: InputMaybe<Scalars['String']['input']>;
  colorLTE?: InputMaybe<Scalars['String']['input']>;
  colorNEQ?: InputMaybe<Scalars['String']['input']>;
  colorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  colorNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** hidden field predicates */
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  hiddenNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** icon_uri field predicates */
  iconURI?: InputMaybe<Scalars['String']['input']>;
  iconURIContains?: InputMaybe<Scalars['String']['input']>;
  iconURIContainsFold?: InputMaybe<Scalars['String']['input']>;
  iconURIEqualFold?: InputMaybe<Scalars['String']['input']>;
  iconURIGT?: InputMaybe<Scalars['String']['input']>;
  iconURIGTE?: InputMaybe<Scalars['String']['input']>;
  iconURIHasPrefix?: InputMaybe<Scalars['String']['input']>;
  iconURIHasSuffix?: InputMaybe<Scalars['String']['input']>;
  iconURIIn?: InputMaybe<Array<Scalars['String']['input']>>;
  iconURIIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  iconURILT?: InputMaybe<Scalars['String']['input']>;
  iconURILTE?: InputMaybe<Scalars['String']['input']>;
  iconURINEQ?: InputMaybe<Scalars['String']['input']>;
  iconURINotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  iconURINotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** image_logo field predicates */
  imageLogo?: InputMaybe<Scalars['String']['input']>;
  imageLogoContains?: InputMaybe<Scalars['String']['input']>;
  imageLogoContainsFold?: InputMaybe<Scalars['String']['input']>;
  imageLogoEqualFold?: InputMaybe<Scalars['String']['input']>;
  imageLogoGT?: InputMaybe<Scalars['String']['input']>;
  imageLogoGTE?: InputMaybe<Scalars['String']['input']>;
  imageLogoHasPrefix?: InputMaybe<Scalars['String']['input']>;
  imageLogoHasSuffix?: InputMaybe<Scalars['String']['input']>;
  imageLogoIn?: InputMaybe<Array<Scalars['String']['input']>>;
  imageLogoIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  imageLogoLT?: InputMaybe<Scalars['String']['input']>;
  imageLogoLTE?: InputMaybe<Scalars['String']['input']>;
  imageLogoNEQ?: InputMaybe<Scalars['String']['input']>;
  imageLogoNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  imageLogoNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** includeHidden indicates if the response should include hidden values. If not set, it defaults to false. */
  includeHidden?: InputMaybe<Scalars['Boolean']['input']>;
  /** is_defi field predicates */
  isDefi?: InputMaybe<Scalars['Boolean']['input']>;
  isDefiNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** is_ethereum_token field predicates */
  isEthereumToken?: InputMaybe<Scalars['Boolean']['input']>;
  isEthereumTokenNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** is_layer1 field predicates */
  isLayer1?: InputMaybe<Scalars['Boolean']['input']>;
  isLayer1NEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** is_smart_contract field predicates */
  isSmartContract?: InputMaybe<Scalars['Boolean']['input']>;
  isSmartContractNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** is_web3 field predicates */
  isWeb3?: InputMaybe<Scalars['Boolean']['input']>;
  isWeb3NEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** logo_uri field predicates */
  logoURI?: InputMaybe<Scalars['String']['input']>;
  logoURIContains?: InputMaybe<Scalars['String']['input']>;
  logoURIContainsFold?: InputMaybe<Scalars['String']['input']>;
  logoURIEqualFold?: InputMaybe<Scalars['String']['input']>;
  logoURIGT?: InputMaybe<Scalars['String']['input']>;
  logoURIGTE?: InputMaybe<Scalars['String']['input']>;
  logoURIHasPrefix?: InputMaybe<Scalars['String']['input']>;
  logoURIHasSuffix?: InputMaybe<Scalars['String']['input']>;
  logoURIIn?: InputMaybe<Array<Scalars['String']['input']>>;
  logoURIIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  logoURILT?: InputMaybe<Scalars['String']['input']>;
  logoURILTE?: InputMaybe<Scalars['String']['input']>;
  logoURINEQ?: InputMaybe<Scalars['String']['input']>;
  logoURINotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  logoURINotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<CryptoAssetWhereInput>;
  or?: InputMaybe<Array<CryptoAssetWhereInput>>;
  /** symbol field predicates */
  symbol?: InputMaybe<Scalars['String']['input']>;
  symbolContains?: InputMaybe<Scalars['String']['input']>;
  symbolContainsFold?: InputMaybe<Scalars['String']['input']>;
  symbolEqualFold?: InputMaybe<Scalars['String']['input']>;
  symbolGT?: InputMaybe<Scalars['String']['input']>;
  symbolGTE?: InputMaybe<Scalars['String']['input']>;
  symbolHasPrefix?: InputMaybe<Scalars['String']['input']>;
  symbolHasSuffix?: InputMaybe<Scalars['String']['input']>;
  symbolIn?: InputMaybe<Array<Scalars['String']['input']>>;
  symbolLT?: InputMaybe<Scalars['String']['input']>;
  symbolLTE?: InputMaybe<Scalars['String']['input']>;
  symbolNEQ?: InputMaybe<Scalars['String']['input']>;
  symbolNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** website field predicates */
  website?: InputMaybe<Scalars['String']['input']>;
  websiteContains?: InputMaybe<Scalars['String']['input']>;
  websiteContainsFold?: InputMaybe<Scalars['String']['input']>;
  websiteEqualFold?: InputMaybe<Scalars['String']['input']>;
  websiteGT?: InputMaybe<Scalars['String']['input']>;
  websiteGTE?: InputMaybe<Scalars['String']['input']>;
  websiteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  websiteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  websiteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  websiteLT?: InputMaybe<Scalars['String']['input']>;
  websiteLTE?: InputMaybe<Scalars['String']['input']>;
  websiteNEQ?: InputMaybe<Scalars['String']['input']>;
  websiteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DepositExplanation = {
  __typename?: 'DepositExplanation';
  documentID?: Maybe<Scalars['String']['output']>;
};

export type DiscretionaryAccountKyc = Node & {
  __typename?: 'DiscretionaryAccountKyc';
  /** Whether to accept issuer direct communication */
  acceptIssuerDirectCommunication: Scalars['Boolean']['output'];
  account?: Maybe<Array<UserAccount>>;
  annualIncome?: Maybe<DiscretionaryAccountKycUserAnnualIncome>;
  employmentDetails?: Maybe<EmploymentDetails>;
  employmentStatus?: Maybe<DiscretionaryAccountKycUserEmploymentStatus>;
  /** Whether to hold or send security sale proceeds */
  holdSecuritySaleProceeds: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  investmentObjective?: Maybe<DiscretionaryAccountKycUserInvestmentObjective>;
  netWorth?: Maybe<DiscretionaryAccountKycUserNetWorth>;
  /** Whether to reinvest dividends */
  reinvestDividend: Scalars['Boolean']['output'];
  riskTolerance?: Maybe<UserRiskTolerance>;
  /** Whether user is enrolled in sweep program */
  sweep: Scalars['Boolean']['output'];
  /** Update requests for this KYC record */
  updateRequests?: Maybe<Array<DiscretionaryUpdateRequest>>;
  user: User;
};

/** A connection to a list of items. */
export type DiscretionaryAccountKycConnection = {
  __typename?: 'DiscretionaryAccountKycConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DiscretionaryAccountKycEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DiscretionaryAccountKycEdge = {
  __typename?: 'DiscretionaryAccountKycEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DiscretionaryAccountKyc>;
};

/** DiscretionaryAccountKycUserAnnualIncome is enum for the field annual_income */
export enum DiscretionaryAccountKycUserAnnualIncome {
  UserAnnualIncome_0_25000 = 'USER_ANNUAL_INCOME_0_25000',
  UserAnnualIncome_25001_50000 = 'USER_ANNUAL_INCOME_25001_50000',
  UserAnnualIncome_50001_100000 = 'USER_ANNUAL_INCOME_50001_100000',
  UserAnnualIncome_100001_200000 = 'USER_ANNUAL_INCOME_100001_200000',
  UserAnnualIncome_200001_300000 = 'USER_ANNUAL_INCOME_200001_300000',
  UserAnnualIncome_300001_500000 = 'USER_ANNUAL_INCOME_300001_500000',
  UserAnnualIncome_500001_1000000 = 'USER_ANNUAL_INCOME_500001_1000000',
  UserAnnualIncome_500001_1200000 = 'USER_ANNUAL_INCOME_500001_1200000',
  UserAnnualIncome_1000001_5000000 = 'USER_ANNUAL_INCOME_1000001_5000000',
  UserAnnualIncome_1200001_9999999 = 'USER_ANNUAL_INCOME_1200001_9999999',
  UserAnnualIncome_5000001_9999999 = 'USER_ANNUAL_INCOME_5000001_9999999',
}

/** DiscretionaryAccountKycUserEmploymentStatus is enum for the field employment_status */
export enum DiscretionaryAccountKycUserEmploymentStatus {
  UserEmploymentStatusEmployed = 'USER_EMPLOYMENT_STATUS_EMPLOYED',
  UserEmploymentStatusRetired = 'USER_EMPLOYMENT_STATUS_RETIRED',
  UserEmploymentStatusSelfEmployed = 'USER_EMPLOYMENT_STATUS_SELF_EMPLOYED',
  UserEmploymentStatusStudent = 'USER_EMPLOYMENT_STATUS_STUDENT',
  UserEmploymentStatusUnemployed = 'USER_EMPLOYMENT_STATUS_UNEMPLOYED',
}

/** DiscretionaryAccountKycUserInvestmentObjective is enum for the field investment_objective */
export enum DiscretionaryAccountKycUserInvestmentObjective {
  UserInvestmentObjectiveBalanced = 'USER_INVESTMENT_OBJECTIVE_BALANCED',
  UserInvestmentObjectiveCapitalAppreciation = 'USER_INVESTMENT_OBJECTIVE_CAPITAL_APPRECIATION',
  UserInvestmentObjectiveCapitalPreservation = 'USER_INVESTMENT_OBJECTIVE_CAPITAL_PRESERVATION',
  UserInvestmentObjectiveGrowth = 'USER_INVESTMENT_OBJECTIVE_GROWTH',
  UserInvestmentObjectiveGrowthAndIncome = 'USER_INVESTMENT_OBJECTIVE_GROWTH_AND_INCOME',
  UserInvestmentObjectiveGrowthIncome = 'USER_INVESTMENT_OBJECTIVE_GROWTH_INCOME',
  UserInvestmentObjectiveIncome = 'USER_INVESTMENT_OBJECTIVE_INCOME',
  UserInvestmentObjectiveLongTermGrowthWithGreaterRisk = 'USER_INVESTMENT_OBJECTIVE_LONG_TERM_GROWTH_WITH_GREATER_RISK',
  UserInvestmentObjectiveLongTermGrowthWithSafety = 'USER_INVESTMENT_OBJECTIVE_LONG_TERM_GROWTH_WITH_SAFETY',
  UserInvestmentObjectiveMaximumGrowth = 'USER_INVESTMENT_OBJECTIVE_MAXIMUM_GROWTH',
  UserInvestmentObjectiveOther = 'USER_INVESTMENT_OBJECTIVE_OTHER',
  UserInvestmentObjectiveShortTermGrowthWithRisk = 'USER_INVESTMENT_OBJECTIVE_SHORT_TERM_GROWTH_WITH_RISK',
  UserInvestmentObjectiveSpeculation = 'USER_INVESTMENT_OBJECTIVE_SPECULATION',
}

/** DiscretionaryAccountKycUserNetWorth is enum for the field net_worth */
export enum DiscretionaryAccountKycUserNetWorth {
  UserNetWorth_0_25000 = 'USER_NET_WORTH_0_25000',
  UserNetWorth_0_50000 = 'USER_NET_WORTH_0_50000',
  UserNetWorth_25001_50000 = 'USER_NET_WORTH_25001_50000',
  UserNetWorth_50001_100000 = 'USER_NET_WORTH_50001_100000',
  UserNetWorth_100001_200000 = 'USER_NET_WORTH_100001_200000',
  UserNetWorth_200001_300000 = 'USER_NET_WORTH_200001_300000',
  UserNetWorth_200001_500000 = 'USER_NET_WORTH_200001_500000',
  UserNetWorth_300001_500000 = 'USER_NET_WORTH_300001_500000',
  UserNetWorth_500001_1000000 = 'USER_NET_WORTH_500001_1000000',
  UserNetWorth_1000001_5000000 = 'USER_NET_WORTH_1000001_5000000',
  UserNetWorth_5000001_9999999 = 'USER_NET_WORTH_5000001_9999999',
}

/**
 * DiscretionaryAccountKycWhereInput is used for filtering DiscretionaryAccountKyc objects.
 * Input was generated by ent.
 */
export type DiscretionaryAccountKycWhereInput = {
  /** accept_issuer_direct_communication field predicates */
  acceptIssuerDirectCommunication?: InputMaybe<Scalars['Boolean']['input']>;
  acceptIssuerDirectCommunicationNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<DiscretionaryAccountKycWhereInput>>;
  /** annual_income field predicates */
  annualIncome?: InputMaybe<DiscretionaryAccountKycUserAnnualIncome>;
  annualIncomeIn?: InputMaybe<Array<DiscretionaryAccountKycUserAnnualIncome>>;
  annualIncomeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  annualIncomeNEQ?: InputMaybe<DiscretionaryAccountKycUserAnnualIncome>;
  annualIncomeNotIn?: InputMaybe<Array<DiscretionaryAccountKycUserAnnualIncome>>;
  annualIncomeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** employment_status field predicates */
  employmentStatus?: InputMaybe<DiscretionaryAccountKycUserEmploymentStatus>;
  employmentStatusIn?: InputMaybe<Array<DiscretionaryAccountKycUserEmploymentStatus>>;
  employmentStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  employmentStatusNEQ?: InputMaybe<DiscretionaryAccountKycUserEmploymentStatus>;
  employmentStatusNotIn?: InputMaybe<Array<DiscretionaryAccountKycUserEmploymentStatus>>;
  employmentStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** account edge predicates */
  hasAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountWith?: InputMaybe<Array<UserAccountWhereInput>>;
  /** update_requests edge predicates */
  hasUpdateRequests?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdateRequestsWith?: InputMaybe<Array<DiscretionaryUpdateRequestWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** hold_security_sale_proceeds field predicates */
  holdSecuritySaleProceeds?: InputMaybe<Scalars['Boolean']['input']>;
  holdSecuritySaleProceedsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** investment_objective field predicates */
  investmentObjective?: InputMaybe<DiscretionaryAccountKycUserInvestmentObjective>;
  investmentObjectiveIn?: InputMaybe<Array<DiscretionaryAccountKycUserInvestmentObjective>>;
  investmentObjectiveIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  investmentObjectiveNEQ?: InputMaybe<DiscretionaryAccountKycUserInvestmentObjective>;
  investmentObjectiveNotIn?: InputMaybe<Array<DiscretionaryAccountKycUserInvestmentObjective>>;
  investmentObjectiveNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** net_worth field predicates */
  netWorth?: InputMaybe<DiscretionaryAccountKycUserNetWorth>;
  netWorthIn?: InputMaybe<Array<DiscretionaryAccountKycUserNetWorth>>;
  netWorthIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  netWorthNEQ?: InputMaybe<DiscretionaryAccountKycUserNetWorth>;
  netWorthNotIn?: InputMaybe<Array<DiscretionaryAccountKycUserNetWorth>>;
  netWorthNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<DiscretionaryAccountKycWhereInput>;
  or?: InputMaybe<Array<DiscretionaryAccountKycWhereInput>>;
  /** reinvest_dividend field predicates */
  reinvestDividend?: InputMaybe<Scalars['Boolean']['input']>;
  reinvestDividendNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** risk_tolerance field predicates */
  riskTolerance?: InputMaybe<UserRiskTolerance>;
  riskToleranceIn?: InputMaybe<Array<UserRiskTolerance>>;
  riskToleranceIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  riskToleranceNEQ?: InputMaybe<UserRiskTolerance>;
  riskToleranceNotIn?: InputMaybe<Array<UserRiskTolerance>>;
  riskToleranceNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** sweep field predicates */
  sweep?: InputMaybe<Scalars['Boolean']['input']>;
  sweepNEQ?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DiscretionaryUpdateRequest = Node & {
  __typename?: 'DiscretionaryUpdateRequest';
  /** Apex request associated with this request. */
  apexUpdateRequests?: Maybe<ApexUpdateRequest>;
  createTime: Scalars['Time']['output'];
  /** The snapshot of the discretionary kyc at the time of this request. */
  currentDiscretionaryKyc?: Maybe<BrokerageDiscretionaryAccountParameters>;
  /** The discretionary account kyc that this update request is associated with. */
  discretionaryAccountKyc: DiscretionaryAccountKyc;
  id: Scalars['ID']['output'];
  requestDetails: RequestDetails;
  /** The discretionary parameters that are being updated. */
  requestParameters?: Maybe<BrokerageDiscretionaryAccountParameters>;
  /** The time at which the action was requested. */
  requestTime: Scalars['Time']['output'];
  requestUUID?: Maybe<Scalars['UUID']['output']>;
  requestor: Requestor;
  /** The status of the update request. */
  status: DiscretionaryUpdateRequestUpdateRequestStatus;
  submittedAt?: Maybe<Scalars['Time']['output']>;
  /** Trusted contact update request associated with this request. */
  trustedContactRequest?: Maybe<TrustedContactUpdateRequest>;
  updateTime: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type DiscretionaryUpdateRequestConnection = {
  __typename?: 'DiscretionaryUpdateRequestConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DiscretionaryUpdateRequestEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DiscretionaryUpdateRequestEdge = {
  __typename?: 'DiscretionaryUpdateRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DiscretionaryUpdateRequest>;
};

/** Ordering options for DiscretionaryUpdateRequest connections */
export type DiscretionaryUpdateRequestOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order DiscretionaryUpdateRequests. */
  field: DiscretionaryUpdateRequestOrderField;
};

/** Properties by which DiscretionaryUpdateRequest connections can be ordered. */
export enum DiscretionaryUpdateRequestOrderField {
  RequestTime = 'REQUEST_TIME',
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/** DiscretionaryUpdateRequestUpdateRequestStatus is enum for the field status */
export enum DiscretionaryUpdateRequestUpdateRequestStatus {
  UpdateRequestStatusApproved = 'UPDATE_REQUEST_STATUS_APPROVED',
  UpdateRequestStatusContacated = 'UPDATE_REQUEST_STATUS_CONTACATED',
  UpdateRequestStatusPending = 'UPDATE_REQUEST_STATUS_PENDING',
  UpdateRequestStatusRejected = 'UPDATE_REQUEST_STATUS_REJECTED',
  UpdateRequestStatusSubmitted = 'UPDATE_REQUEST_STATUS_SUBMITTED',
}

/**
 * DiscretionaryUpdateRequestWhereInput is used for filtering DiscretionaryUpdateRequest objects.
 * Input was generated by ent.
 */
export type DiscretionaryUpdateRequestWhereInput = {
  and?: InputMaybe<Array<DiscretionaryUpdateRequestWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** apex_update_requests edge predicates */
  hasApexUpdateRequests?: InputMaybe<Scalars['Boolean']['input']>;
  hasApexUpdateRequestsWith?: InputMaybe<Array<ApexUpdateRequestWhereInput>>;
  /** discretionary_account_kyc edge predicates */
  hasDiscretionaryAccountKyc?: InputMaybe<Scalars['Boolean']['input']>;
  hasDiscretionaryAccountKycWith?: InputMaybe<Array<DiscretionaryAccountKycWhereInput>>;
  /** trusted_contact_request edge predicates */
  hasTrustedContactRequest?: InputMaybe<Scalars['Boolean']['input']>;
  hasTrustedContactRequestWith?: InputMaybe<Array<TrustedContactUpdateRequestWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<DiscretionaryUpdateRequestWhereInput>;
  or?: InputMaybe<Array<DiscretionaryUpdateRequestWhereInput>>;
  /** request_time field predicates */
  requestTime?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  requestTimeLT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** request_uuid field predicates */
  requestUUID?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  requestUUIDLT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDLTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNEQ?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNotIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** requestor field predicates */
  requestor?: InputMaybe<Requestor>;
  requestorIn?: InputMaybe<Array<Requestor>>;
  requestorNEQ?: InputMaybe<Requestor>;
  requestorNotIn?: InputMaybe<Array<Requestor>>;
  /** status field predicates */
  status?: InputMaybe<DiscretionaryUpdateRequestUpdateRequestStatus>;
  statusIn?: InputMaybe<Array<DiscretionaryUpdateRequestUpdateRequestStatus>>;
  statusNEQ?: InputMaybe<DiscretionaryUpdateRequestUpdateRequestStatus>;
  statusNotIn?: InputMaybe<Array<DiscretionaryUpdateRequestUpdateRequestStatus>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** Describes issued dividends by the company */
export type Dividend = {
  __typename?: 'Dividend';
  /** cash amount of the dividend per share owned. */
  cashAmount: Scalars['Decimal']['output'];
  /** currency in which the dividend is paid. */
  currency: Scalars['String']['output'];
  /** date that the dividend was announced. */
  declarationDate: Scalars['Date']['output'];
  /** type of dividend. Dividends that have been paid and/or are expected to be paid on consistent schedules are denoted as CD. Special Cash dividends that have been paid that are infrequent or unusual, and/or can not be expected to occur in the future are denoted as SC. Long-Term and Short-Term capital gain distributions are denoted as LT and ST, respectively. */
  dividendType: Scalars['String']['output'];
  /** date that the stock first trades without the dividend, determined by the exchange. */
  exDividendDate: Scalars['Date']['output'];
  /** number of times per year the dividend is paid out. Possible values are 0 (one-time), 1 (annually), 2 (bi-annually), 4 (quarterly), and 12 (monthly). */
  frequency: Scalars['Int']['output'];
  /** date that the dividend is paid out. */
  payDate: Scalars['Date']['output'];
  /** date that the stock must be held to receive the dividend, set by the company. */
  recordDate: Scalars['Date']['output'];
};

export type DividendDistribution = BaseOrder &
  Node & {
    __typename?: 'DividendDistribution';
    /** Total amount of the dividend asset distributed to the portfolio. */
    amount: Scalars['Decimal']['output'];
    /** asset that is owned by the user to earn the dividend. */
    asset: SecurityAsset;
    createTime: Scalars['Time']['output'];
    /** asset that is paid out to the user. */
    distributionAsset?: Maybe<BaseAsset>;
    /** Interest rate amount for the dividend held, decimal (16,6) */
    dividendInterest?: Maybe<Scalars['Decimal']['output']>;
    /** Rate for the dividend */
    dividendRate?: Maybe<Scalars['Decimal']['output']>;
    id: Scalars['ID']['output'];
    /** Meets the criteria to be taxed at capital gains tax rates */
    isQualified: Scalars['Boolean']['output'];
    isReinvested: Scalars['Boolean']['output'];
    /** Notes is the notes of the order intended for the principal. */
    note?: Maybe<Scalars['String']['output']>;
    orderStatus: OrderOrderStatus;
    orderType: OrderOrderType;
    /** The date on which the dividend is paid to shareholders. */
    payDate?: Maybe<Scalars['Date']['output']>;
    portfolio: UserAccountPortfolio;
    /** Shares of the underlying asset on the record date used to calculated the distribution of dividends. */
    position?: Maybe<Scalars['Decimal']['output']>;
    /** The quantity of shares bought, sold, or moved for a given entry/activity. */
    quantity?: Maybe<Scalars['Decimal']['output']>;
    /** Date the dividend was recorded */
    recordDate: Scalars['Date']['output'];
    requestDetails: RequestDetails;
    unadjustedAmount?: Maybe<Scalars['Decimal']['output']>;
    updateTime: Scalars['Time']['output'];
    user: User;
  };

/** A connection to a list of items. */
export type DividendDistributionConnection = {
  __typename?: 'DividendDistributionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DividendDistributionEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DividendDistributionEdge = {
  __typename?: 'DividendDistributionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DividendDistribution>;
};

/** Ordering options for DividendDistribution connections */
export type DividendDistributionOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order DividendDistributions. */
  field: DividendDistributionOrderField;
};

/** Properties by which DividendDistribution connections can be ordered. */
export enum DividendDistributionOrderField {
  CreateTime = 'create_time',
  RecordDate = 'recordDate',
  UpdateTime = 'update_time',
}

/**
 * DividendDistributionWhereInput is used for filtering DividendDistribution objects.
 * Input was generated by ent.
 */
export type DividendDistributionWhereInput = {
  /** amount field predicates */
  amount?: InputMaybe<Scalars['Decimal']['input']>;
  amountGT?: InputMaybe<Scalars['Decimal']['input']>;
  amountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  amountLT?: InputMaybe<Scalars['Decimal']['input']>;
  amountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  amountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  and?: InputMaybe<Array<DividendDistributionWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** dividend_interest field predicates */
  dividendInterest?: InputMaybe<Scalars['Decimal']['input']>;
  dividendInterestGT?: InputMaybe<Scalars['Decimal']['input']>;
  dividendInterestGTE?: InputMaybe<Scalars['Decimal']['input']>;
  dividendInterestIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  dividendInterestIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  dividendInterestLT?: InputMaybe<Scalars['Decimal']['input']>;
  dividendInterestLTE?: InputMaybe<Scalars['Decimal']['input']>;
  dividendInterestNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  dividendInterestNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  dividendInterestNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** dividend_rate field predicates */
  dividendRate?: InputMaybe<Scalars['Decimal']['input']>;
  dividendRateGT?: InputMaybe<Scalars['Decimal']['input']>;
  dividendRateGTE?: InputMaybe<Scalars['Decimal']['input']>;
  dividendRateIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  dividendRateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  dividendRateLT?: InputMaybe<Scalars['Decimal']['input']>;
  dividendRateLTE?: InputMaybe<Scalars['Decimal']['input']>;
  dividendRateNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  dividendRateNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  dividendRateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** portfolio edge predicates */
  hasPortfolio?: InputMaybe<Scalars['Boolean']['input']>;
  hasPortfolioWith?: InputMaybe<Array<UserAccountPortfolioWhereInput>>;
  hasUserWith?: InputMaybe<UserWhereInput>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_qualified field predicates */
  isQualified?: InputMaybe<Scalars['Boolean']['input']>;
  isQualifiedNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<DividendDistributionWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<DividendDistributionWhereInput>>;
  /** pay_date field predicates */
  payDate?: InputMaybe<Scalars['Date']['input']>;
  payDateGT?: InputMaybe<Scalars['Date']['input']>;
  payDateGTE?: InputMaybe<Scalars['Date']['input']>;
  payDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  payDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  payDateLT?: InputMaybe<Scalars['Date']['input']>;
  payDateLTE?: InputMaybe<Scalars['Date']['input']>;
  payDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  payDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  payDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** position field predicates */
  position?: InputMaybe<Scalars['Decimal']['input']>;
  positionGT?: InputMaybe<Scalars['Decimal']['input']>;
  positionGTE?: InputMaybe<Scalars['Decimal']['input']>;
  positionIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  positionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  positionLT?: InputMaybe<Scalars['Decimal']['input']>;
  positionLTE?: InputMaybe<Scalars['Decimal']['input']>;
  positionNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  positionNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  positionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** quantity field predicates */
  quantity?: InputMaybe<Scalars['Decimal']['input']>;
  quantityGT?: InputMaybe<Scalars['Decimal']['input']>;
  quantityGTE?: InputMaybe<Scalars['Decimal']['input']>;
  quantityIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  quantityIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  quantityLT?: InputMaybe<Scalars['Decimal']['input']>;
  quantityLTE?: InputMaybe<Scalars['Decimal']['input']>;
  quantityNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  quantityNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  quantityNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** record_date field predicates */
  recordDate?: InputMaybe<Scalars['Date']['input']>;
  recordDateGT?: InputMaybe<Scalars['Date']['input']>;
  recordDateGTE?: InputMaybe<Scalars['Date']['input']>;
  recordDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  recordDateLT?: InputMaybe<Scalars['Date']['input']>;
  recordDateLTE?: InputMaybe<Scalars['Date']['input']>;
  recordDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  recordDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  /** unadjusted_amount field predicates */
  unadjustedAmount?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedAmountGT?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedAmountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedAmountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  unadjustedAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  unadjustedAmountLT?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedAmountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedAmountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedAmountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  unadjustedAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type Document =
  | AccountTransferForm
  | AffiliatedApproval
  | CddDocument
  | DepositExplanation
  | IdentificationCard
  | IraDepositSlip
  | IrsItinLetter
  | License
  | LimitedMarginAgreement
  | MilitaryId
  | NationalIdCard
  | Other
  | OtherGovernmentId
  | PassingCipResults
  | Passport
  | SsaLetter
  | SsnCard
  | SignatureImage
  | Visa;

export type EtfComposition = Node & {
  __typename?: 'ETFComposition';
  etfDetails: EtfDetails;
  id: Scalars['ID']['output'];
  percent: Scalars['Float']['output'];
  /** the security that is part of the ETF */
  security: SecurityAsset;
};

/**
 * ETFCompositionWhereInput is used for filtering ETFComposition objects.
 * Input was generated by ent.
 */
export type EtfCompositionWhereInput = {
  and?: InputMaybe<Array<EtfCompositionWhereInput>>;
  /** etf_details edge predicates */
  hasEtfDetails?: InputMaybe<Scalars['Boolean']['input']>;
  hasEtfDetailsWith?: InputMaybe<Array<EtfDetailsWhereInput>>;
  /** security edge predicates */
  hasSecurity?: InputMaybe<Scalars['Boolean']['input']>;
  hasSecurityWith?: InputMaybe<Array<SecurityAssetWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<EtfCompositionWhereInput>;
  or?: InputMaybe<Array<EtfCompositionWhereInput>>;
  /** percent field predicates */
  percent?: InputMaybe<Scalars['Float']['input']>;
  percentGT?: InputMaybe<Scalars['Float']['input']>;
  percentGTE?: InputMaybe<Scalars['Float']['input']>;
  percentIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  percentLT?: InputMaybe<Scalars['Float']['input']>;
  percentLTE?: InputMaybe<Scalars['Float']['input']>;
  percentNEQ?: InputMaybe<Scalars['Float']['input']>;
  percentNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type EtfDetails = Node & {
  __typename?: 'ETFDetails';
  annualizedMeanReturn?: Maybe<Scalars['Float']['output']>;
  annualizedStdDev?: Maybe<Scalars['Float']['output']>;
  composition?: Maybe<Array<EtfComposition>>;
  /** compositions are the securities that make up the ETF */
  compositions: Array<EtfComposition>;
  etf: SecurityAsset;
  expenseRatio: Scalars['Decimal']['output'];
  id: Scalars['ID']['output'];
  sectors: Array<EtfSector>;
  yield30Day: Scalars['Decimal']['output'];
};

/** A connection to a list of items. */
export type EtfDetailsConnection = {
  __typename?: 'ETFDetailsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EtfDetailsEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EtfDetailsEdge = {
  __typename?: 'ETFDetailsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EtfDetails>;
};

/** Ordering options for ETFDetails connections */
export type EtfDetailsOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ETFDetailsSlice. */
  field: EtfDetailsOrderField;
};

/** Properties by which ETFDetails connections can be ordered. */
export enum EtfDetailsOrderField {
  ExpenseRatio = 'expense_ratio',
}

/**
 * ETFDetailsWhereInput is used for filtering ETFDetails objects.
 * Input was generated by ent.
 */
export type EtfDetailsWhereInput = {
  and?: InputMaybe<Array<EtfDetailsWhereInput>>;
  /** annualized_mean_return field predicates */
  annualizedMeanReturn?: InputMaybe<Scalars['Float']['input']>;
  annualizedMeanReturnGT?: InputMaybe<Scalars['Float']['input']>;
  annualizedMeanReturnGTE?: InputMaybe<Scalars['Float']['input']>;
  annualizedMeanReturnIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  annualizedMeanReturnIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  annualizedMeanReturnLT?: InputMaybe<Scalars['Float']['input']>;
  annualizedMeanReturnLTE?: InputMaybe<Scalars['Float']['input']>;
  annualizedMeanReturnNEQ?: InputMaybe<Scalars['Float']['input']>;
  annualizedMeanReturnNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  annualizedMeanReturnNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** annualized_std_dev field predicates */
  annualizedStdDev?: InputMaybe<Scalars['Float']['input']>;
  annualizedStdDevGT?: InputMaybe<Scalars['Float']['input']>;
  annualizedStdDevGTE?: InputMaybe<Scalars['Float']['input']>;
  annualizedStdDevIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  annualizedStdDevIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  annualizedStdDevLT?: InputMaybe<Scalars['Float']['input']>;
  annualizedStdDevLTE?: InputMaybe<Scalars['Float']['input']>;
  annualizedStdDevNEQ?: InputMaybe<Scalars['Float']['input']>;
  annualizedStdDevNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  annualizedStdDevNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** expense_ratio field predicates */
  expenseRatio?: InputMaybe<Scalars['Decimal']['input']>;
  expenseRatioGT?: InputMaybe<Scalars['Decimal']['input']>;
  expenseRatioGTE?: InputMaybe<Scalars['Decimal']['input']>;
  expenseRatioIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  expenseRatioLT?: InputMaybe<Scalars['Decimal']['input']>;
  expenseRatioLTE?: InputMaybe<Scalars['Decimal']['input']>;
  expenseRatioNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  expenseRatioNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** composition edge predicates */
  hasComposition?: InputMaybe<Scalars['Boolean']['input']>;
  hasCompositionWith?: InputMaybe<Array<EtfCompositionWhereInput>>;
  /** etf edge predicates */
  hasEtf?: InputMaybe<Scalars['Boolean']['input']>;
  hasEtfWith?: InputMaybe<Array<SecurityAssetWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<EtfDetailsWhereInput>;
  or?: InputMaybe<Array<EtfDetailsWhereInput>>;
  /** yield_30_day field predicates */
  yield30Day?: InputMaybe<Scalars['Decimal']['input']>;
  yield30DayGT?: InputMaybe<Scalars['Decimal']['input']>;
  yield30DayGTE?: InputMaybe<Scalars['Decimal']['input']>;
  yield30DayIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  yield30DayLT?: InputMaybe<Scalars['Decimal']['input']>;
  yield30DayLTE?: InputMaybe<Scalars['Decimal']['input']>;
  yield30DayNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  yield30DayNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export type EtfHolding = {
  __typename?: 'ETFHolding';
  asset: HoldingAsset;
  percentage: Scalars['Float']['output'];
};

export type EtfSector = {
  __typename?: 'ETFSector';
  name: Scalars['String']['output'];
  percentage: Scalars['Float']['output'];
};

export type EmploymentDetails = {
  __typename?: 'EmploymentDetails';
  EmployerName: Scalars['String']['output'];
  Position: Scalars['String']['output'];
};

export type ExternalAccount = Node & {
  __typename?: 'ExternalAccount';
  accountNumber: Scalars['String']['output'];
  /** whether the user can use this external account in relation to advisory operations */
  advisoryCanUse: Scalars['Boolean']['output'];
  /** whether the user can use this external account in relation to brokerage operations */
  brokerageCanUse: Scalars['Boolean']['output'];
  connectionDetails?: Maybe<ExternalAccountConnectionDetails>;
  /** Status of the connection with the external account, through Plaid or MX. */
  connectionStatus: ExternalAccountConnectionStatus;
  connectionStatusCode?: Maybe<ExternalAccountConnectionStatusCode>;
  connectionType: ExternalAccountExternalAccountConnectionType;
  createTime: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  /** institution that holds the account */
  institution: Institution;
  /** Mask is the displayable indicator of the external account number. */
  mask: Scalars['String']['output'];
  type: ExternalAccountExternalAccountType;
  updateTime: Scalars['Time']['output'];
  /** user that owns this external account */
  user: User;
};

/** A connection to a list of items. */
export type ExternalAccountConnection = {
  __typename?: 'ExternalAccountConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ExternalAccountEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/**
 * ExternalAccountConnectionDetails is the details of the connection with the external account,
 * different for each connection type.
 */
export type ExternalAccountConnectionDetails =
  | ExternalAccountConnectionDetailsIct
  | ExternalAccountConnectionDetailsPlaid;

export type ExternalAccountConnectionDetailsIct = {
  __typename?: 'ExternalAccountConnectionDetailsICT';
  /** account number at the originating financial institution. */
  accountNumber: Scalars['String']['output'];
  partnerInstitution: IctPartnerInstitution;
};

export type ExternalAccountConnectionDetailsPlaid = {
  __typename?: 'ExternalAccountConnectionDetailsPlaid';
  bankOwnerName?: Maybe<Array<Scalars['String']['output']>>;
  /** The regulated entity that owns the relationship Plaid relationship. */
  relationshipOwner: PlaidRelationshipOwner;
};

export enum ExternalAccountConnectionStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Error = 'ERROR',
  NotConnected = 'NOT_CONNECTED',
  Rejected = 'REJECTED',
  Stale = 'STALE',
}

/** ExternalAccountConnectionStatusCode is enum for the field connection_status_code */
export enum ExternalAccountConnectionStatusCode {
  AbaRoutingBlocked = 'ABA_ROUTING_BLOCKED',
  AccountClosedRestriction = 'ACCOUNT_CLOSED_RESTRICTION',
  BankAccountBlacklisted = 'BANK_ACCOUNT_BLACKLISTED',
  DuplicatedAccountNumber = 'DUPLICATED_ACCOUNT_NUMBER',
  InvalidRoutingNumber = 'INVALID_ROUTING_NUMBER',
  LegalRestriction = 'LEGAL_RESTRICTION',
  PendingAchRelationshipsLimitReached = 'PENDING_ACH_RELATIONSHIPS_LIMIT_REACHED',
  RelationshipsLimitReached = 'RELATIONSHIPS_LIMIT_REACHED',
  SocialSecurityMismatch = 'SOCIAL_SECURITY_MISMATCH',
}

/** An edge in a connection. */
export type ExternalAccountEdge = {
  __typename?: 'ExternalAccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ExternalAccount>;
};

/** ExternalAccountExternalAccountConnectionType is enum for the field connection_type */
export enum ExternalAccountExternalAccountConnectionType {
  ExternalAccountConnectionTypeIct = 'EXTERNAL_ACCOUNT_CONNECTION_TYPE_ICT',
  ExternalAccountConnectionTypeNone = 'EXTERNAL_ACCOUNT_CONNECTION_TYPE_NONE',
  ExternalAccountConnectionTypePlaid = 'EXTERNAL_ACCOUNT_CONNECTION_TYPE_PLAID',
  ExternalAccountConnectionTypeRadius = 'EXTERNAL_ACCOUNT_CONNECTION_TYPE_RADIUS',
}

/** ExternalAccountExternalAccountType is enum for the field type */
export enum ExternalAccountExternalAccountType {
  ExternalAccountTypeChecking = 'EXTERNAL_ACCOUNT_TYPE_CHECKING',
  ExternalAccountTypeSavings = 'EXTERNAL_ACCOUNT_TYPE_SAVINGS',
}

/** Ordering options for ExternalAccount connections */
export type ExternalAccountOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ExternalAccounts. */
  field: ExternalAccountOrderField;
};

/** Properties by which ExternalAccount connections can be ordered. */
export enum ExternalAccountOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * ExternalAccountWhereInput is used for filtering ExternalAccount objects.
 * Input was generated by ent.
 */
export type ExternalAccountWhereInput = {
  /** account_number field predicates */
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  accountNumberContains?: InputMaybe<Scalars['String']['input']>;
  accountNumberContainsFold?: InputMaybe<Scalars['String']['input']>;
  accountNumberEqualFold?: InputMaybe<Scalars['String']['input']>;
  accountNumberGT?: InputMaybe<Scalars['String']['input']>;
  accountNumberGTE?: InputMaybe<Scalars['String']['input']>;
  accountNumberHasPrefix?: InputMaybe<Scalars['String']['input']>;
  accountNumberHasSuffix?: InputMaybe<Scalars['String']['input']>;
  accountNumberIn?: InputMaybe<Array<Scalars['String']['input']>>;
  accountNumberLT?: InputMaybe<Scalars['String']['input']>;
  accountNumberLTE?: InputMaybe<Scalars['String']['input']>;
  accountNumberNEQ?: InputMaybe<Scalars['String']['input']>;
  accountNumberNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** advisory_can_use field predicates */
  advisoryCanUse?: InputMaybe<Scalars['Boolean']['input']>;
  advisoryCanUseNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<ExternalAccountWhereInput>>;
  /** brokerage_can_use field predicates */
  brokerageCanUse?: InputMaybe<Scalars['Boolean']['input']>;
  brokerageCanUseNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** connection_type field predicates */
  connectionType?: InputMaybe<ExternalAccountExternalAccountConnectionType>;
  connectionTypeIn?: InputMaybe<Array<ExternalAccountExternalAccountConnectionType>>;
  connectionTypeNEQ?: InputMaybe<ExternalAccountExternalAccountConnectionType>;
  connectionTypeNotIn?: InputMaybe<Array<ExternalAccountExternalAccountConnectionType>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  hasActiveConnection?: InputMaybe<Scalars['Boolean']['input']>;
  /** institution edge predicates */
  hasInstitution?: InputMaybe<Scalars['Boolean']['input']>;
  hasInstitutionWith?: InputMaybe<Array<InstitutionWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<ExternalAccountWhereInput>;
  or?: InputMaybe<Array<ExternalAccountWhereInput>>;
  status?: InputMaybe<ExternalAccountConnectionStatus>;
  statusIn?: InputMaybe<Array<ExternalAccountConnectionStatus>>;
  statusNEQ?: InputMaybe<ExternalAccountConnectionStatus>;
  statusNotIn?: InputMaybe<Array<ExternalAccountConnectionStatus>>;
  /** type field predicates */
  type?: InputMaybe<ExternalAccountExternalAccountType>;
  typeIn?: InputMaybe<Array<ExternalAccountExternalAccountType>>;
  typeNEQ?: InputMaybe<ExternalAccountExternalAccountType>;
  typeNotIn?: InputMaybe<Array<ExternalAccountExternalAccountType>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type FederalWithholdingFundamentalInfo = {
  __typename?: 'FederalWithholdingFundamentalInfo';
  rate: Scalars['Decimal']['output'];
};

export type FederatedIdentityProvider = Node & {
  __typename?: 'FederatedIdentityProvider';
  /** Email domain corresponding to the federated identity provider */
  domain: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Name of federated identity provider */
  name: Scalars['String']['output'];
};

/**
 * FederatedIdentityProviderWhereInput is used for filtering FederatedIdentityProvider objects.
 * Input was generated by ent.
 */
export type FederatedIdentityProviderWhereInput = {
  and?: InputMaybe<Array<FederatedIdentityProviderWhereInput>>;
  /** domain field predicates */
  domain?: InputMaybe<Scalars['String']['input']>;
  domainContains?: InputMaybe<Scalars['String']['input']>;
  domainContainsFold?: InputMaybe<Scalars['String']['input']>;
  domainEqualFold?: InputMaybe<Scalars['String']['input']>;
  domainGT?: InputMaybe<Scalars['String']['input']>;
  domainGTE?: InputMaybe<Scalars['String']['input']>;
  domainHasPrefix?: InputMaybe<Scalars['String']['input']>;
  domainHasSuffix?: InputMaybe<Scalars['String']['input']>;
  domainIn?: InputMaybe<Array<Scalars['String']['input']>>;
  domainLT?: InputMaybe<Scalars['String']['input']>;
  domainLTE?: InputMaybe<Scalars['String']['input']>;
  domainNEQ?: InputMaybe<Scalars['String']['input']>;
  domainNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<FederatedIdentityProviderWhereInput>;
  or?: InputMaybe<Array<FederatedIdentityProviderWhereInput>>;
};

export type FeeAndCreditError = {
  __typename?: 'FeeAndCreditError';
  code: FeeAndCreditErrorCode;
  message: Scalars['String']['output'];
};

export enum FeeAndCreditErrorCode {
  FeeAndCreditErrorCodeInsufficientFunds = 'FEE_AND_CREDIT_ERROR_CODE_INSUFFICIENT_FUNDS',
  FeeAndCreditErrorCodeInvalidOrder = 'FEE_AND_CREDIT_ERROR_CODE_INVALID_ORDER',
  FeeAndCreditErrorCodeOther = 'FEE_AND_CREDIT_ERROR_CODE_OTHER',
}

export type FeeAndCreditOrder = BaseOrder &
  Node & {
    __typename?: 'FeeAndCreditOrder';
    achTransfer?: Maybe<AchTransfer>;
    /** The fee amount to be charged for this user account */
    amount: Scalars['Decimal']['output'];
    createTime: Scalars['Time']['output'];
    /** Description for order displayed to user */
    description: Scalars['String']['output'];
    ictTransfer?: Maybe<IctTransfer>;
    id: Scalars['ID']['output'];
    /** Notes is the notes of the order intended for the principal. */
    note?: Maybe<Scalars['String']['output']>;
    orderStatus: OrderOrderStatus;
    orderType: OrderOrderType;
    originalOrder?: Maybe<FeeAndCreditOrder>;
    /** Portfolio associated with the fee calculation */
    portfolio: UserAccountPortfolio;
    requestDetails: RequestDetails;
    reversalOrder?: Maybe<Array<FeeAndCreditOrder>>;
    status: FeeAndCreditOrderFeeAndCreditStatus;
    statusChangeLog: Array<FeeAndCreditOrderStatusChangeLog>;
    /** Apex submission associated with the fees. */
    submission?: Maybe<FeeAndCreditSubmission>;
    /** Fee and credit bulk order associated with the monthly fee */
    submissionID?: Maybe<Scalars['ID']['output']>;
    /** Title for order displayed to user */
    title: Scalars['String']['output'];
    type: FeeAndCreditOrderFeeAndCreditType;
    updateTime: Scalars['Time']['output'];
  };

export type FeeAndCreditOrderStatusChangeLogArgs = {
  status?: InputMaybe<FeeAndCreditOrderFeeAndCreditStatus>;
  statusIn?: InputMaybe<Array<FeeAndCreditOrderFeeAndCreditStatus>>;
};

/** A connection to a list of items. */
export type FeeAndCreditOrderConnection = {
  __typename?: 'FeeAndCreditOrderConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FeeAndCreditOrderEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type FeeAndCreditOrderEdge = {
  __typename?: 'FeeAndCreditOrderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<FeeAndCreditOrder>;
};

/** FeeAndCreditOrderFeeAndCreditStatus is enum for the field status */
export enum FeeAndCreditOrderFeeAndCreditStatus {
  FeeAndCreditStatusBundled = 'FEE_AND_CREDIT_STATUS_BUNDLED',
  FeeAndCreditStatusCanceled = 'FEE_AND_CREDIT_STATUS_CANCELED',
  FeeAndCreditStatusCanceling = 'FEE_AND_CREDIT_STATUS_CANCELING',
  FeeAndCreditStatusFailed = 'FEE_AND_CREDIT_STATUS_FAILED',
  FeeAndCreditStatusFunded = 'FEE_AND_CREDIT_STATUS_FUNDED',
  FeeAndCreditStatusIncomplete = 'FEE_AND_CREDIT_STATUS_INCOMPLETE',
  FeeAndCreditStatusIssued = 'FEE_AND_CREDIT_STATUS_ISSUED',
  FeeAndCreditStatusNew = 'FEE_AND_CREDIT_STATUS_NEW',
  FeeAndCreditStatusSettled = 'FEE_AND_CREDIT_STATUS_SETTLED',
}

/** FeeAndCreditOrderFeeAndCreditType is enum for the field type */
export enum FeeAndCreditOrderFeeAndCreditType {
  FeeAndCreditTypeAdministrativeFee = 'FEE_AND_CREDIT_TYPE_ADMINISTRATIVE_FEE',
  FeeAndCreditTypeAdvisoryFee = 'FEE_AND_CREDIT_TYPE_ADVISORY_FEE',
  FeeAndCreditTypeCourtesyCredit = 'FEE_AND_CREDIT_TYPE_COURTESY_CREDIT',
  FeeAndCreditTypeExchangeFee = 'FEE_AND_CREDIT_TYPE_EXCHANGE_FEE',
  FeeAndCreditTypeFeeReversalCredit = 'FEE_AND_CREDIT_TYPE_FEE_REVERSAL_CREDIT',
  FeeAndCreditTypeInactivityFee = 'FEE_AND_CREDIT_TYPE_INACTIVITY_FEE',
  FeeAndCreditTypeManagementFee = 'FEE_AND_CREDIT_TYPE_MANAGEMENT_FEE',
  FeeAndCreditTypeMarginInterestFee = 'FEE_AND_CREDIT_TYPE_MARGIN_INTEREST_FEE',
  FeeAndCreditTypePlatformFee = 'FEE_AND_CREDIT_TYPE_PLATFORM_FEE',
  FeeAndCreditTypePromotionalCredit = 'FEE_AND_CREDIT_TYPE_PROMOTIONAL_CREDIT',
  FeeAndCreditTypeRebateCredit = 'FEE_AND_CREDIT_TYPE_REBATE_CREDIT',
  FeeAndCreditTypeTechFee = 'FEE_AND_CREDIT_TYPE_TECH_FEE',
  FeeAndCreditTypeZeroingCredit = 'FEE_AND_CREDIT_TYPE_ZEROING_CREDIT',
}

/** Ordering options for FeeAndCreditOrder connections */
export type FeeAndCreditOrderOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order FeeAndCreditOrders. */
  field: FeeAndCreditOrderOrderField;
};

/** Properties by which FeeAndCreditOrder connections can be ordered. */
export enum FeeAndCreditOrderOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

export type FeeAndCreditOrderStatusChangeLog = {
  __typename?: 'FeeAndCreditOrderStatusChangeLog';
  eventTime: Scalars['Time']['output'];
  note: Scalars['String']['output'];
  status: FeeAndCreditOrderFeeAndCreditStatus;
};

/**
 * FeeAndCreditOrderWhereInput is used for filtering FeeAndCreditOrder objects.
 * Input was generated by ent.
 */
export type FeeAndCreditOrderWhereInput = {
  /** amount field predicates */
  amount?: InputMaybe<Scalars['Decimal']['input']>;
  amountGT?: InputMaybe<Scalars['Decimal']['input']>;
  amountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  amountLT?: InputMaybe<Scalars['Decimal']['input']>;
  amountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  amountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  and?: InputMaybe<Array<FeeAndCreditOrderWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** ach_transfer edge predicates */
  hasAchTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  hasAchTransferWith?: InputMaybe<Array<AchTransferWhereInput>>;
  /** ict_transfer edge predicates */
  hasIctTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  hasIctTransferWith?: InputMaybe<Array<IctTransferWhereInput>>;
  /** original_order edge predicates */
  hasOriginalOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasOriginalOrderWith?: InputMaybe<Array<FeeAndCreditOrderWhereInput>>;
  /** portfolio edge predicates */
  hasPortfolio?: InputMaybe<Scalars['Boolean']['input']>;
  hasPortfolioWith?: InputMaybe<Array<UserAccountPortfolioWhereInput>>;
  /** reversal_order edge predicates */
  hasReversalOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasReversalOrderWith?: InputMaybe<Array<FeeAndCreditOrderWhereInput>>;
  /** submission edge predicates */
  hasSubmission?: InputMaybe<Scalars['Boolean']['input']>;
  hasSubmissionWith?: InputMaybe<Array<FeeAndCreditSubmissionWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<FeeAndCreditOrderWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<FeeAndCreditOrderWhereInput>>;
  /** status field predicates */
  status?: InputMaybe<FeeAndCreditOrderFeeAndCreditStatus>;
  statusIn?: InputMaybe<Array<FeeAndCreditOrderFeeAndCreditStatus>>;
  statusNEQ?: InputMaybe<FeeAndCreditOrderFeeAndCreditStatus>;
  statusNotIn?: InputMaybe<Array<FeeAndCreditOrderFeeAndCreditStatus>>;
  /** submission_id field predicates */
  submissionID?: InputMaybe<Scalars['ID']['input']>;
  submissionIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  submissionIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  submissionIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  submissionIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  submissionIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** title field predicates */
  title?: InputMaybe<Scalars['String']['input']>;
  titleContains?: InputMaybe<Scalars['String']['input']>;
  titleContainsFold?: InputMaybe<Scalars['String']['input']>;
  titleEqualFold?: InputMaybe<Scalars['String']['input']>;
  titleGT?: InputMaybe<Scalars['String']['input']>;
  titleGTE?: InputMaybe<Scalars['String']['input']>;
  titleHasPrefix?: InputMaybe<Scalars['String']['input']>;
  titleHasSuffix?: InputMaybe<Scalars['String']['input']>;
  titleIn?: InputMaybe<Array<Scalars['String']['input']>>;
  titleLT?: InputMaybe<Scalars['String']['input']>;
  titleLTE?: InputMaybe<Scalars['String']['input']>;
  titleNEQ?: InputMaybe<Scalars['String']['input']>;
  titleNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** type field predicates */
  type?: InputMaybe<FeeAndCreditOrderFeeAndCreditType>;
  typeIn?: InputMaybe<Array<FeeAndCreditOrderFeeAndCreditType>>;
  typeNEQ?: InputMaybe<FeeAndCreditOrderFeeAndCreditType>;
  typeNotIn?: InputMaybe<Array<FeeAndCreditOrderFeeAndCreditType>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type FeeAndCreditProgramChangeLog = Node & {
  __typename?: 'FeeAndCreditProgramChangeLog';
  /** Description for order sent to Apex */
  accountDescription: Scalars['String']['output'];
  active: Scalars['Boolean']['output'];
  /** value of the program */
  amount: Scalars['Decimal']['output'];
  createTime: Scalars['Time']['output'];
  /** Description for order displayed to user */
  description: Scalars['String']['output'];
  end?: Maybe<Scalars['Time']['output']>;
  extras: Scalars['Map']['output'];
  id: Scalars['ID']['output'];
  isCreditVsFee: Scalars['Boolean']['output'];
  /** number of times a user can take advantage of this program. */
  limit: Scalars['Int']['output'];
  /** name of the program */
  name: Scalars['String']['output'];
  /** Reason for update */
  reason: Scalars['String']['output'];
  start: Scalars['Time']['output'];
  /** Title for order displayed to user */
  title: Scalars['String']['output'];
  type: FeeAndCreditProgramChangeLogFeeAndCreditType;
  updateTime: Scalars['Time']['output'];
  version: Scalars['Int']['output'];
};

/** FeeAndCreditProgramChangeLogFeeAndCreditType is enum for the field type */
export enum FeeAndCreditProgramChangeLogFeeAndCreditType {
  FeeAndCreditTypeAdministrativeFee = 'FEE_AND_CREDIT_TYPE_ADMINISTRATIVE_FEE',
  FeeAndCreditTypeAdvisoryFee = 'FEE_AND_CREDIT_TYPE_ADVISORY_FEE',
  FeeAndCreditTypeCourtesyCredit = 'FEE_AND_CREDIT_TYPE_COURTESY_CREDIT',
  FeeAndCreditTypeExchangeFee = 'FEE_AND_CREDIT_TYPE_EXCHANGE_FEE',
  FeeAndCreditTypeFeeReversalCredit = 'FEE_AND_CREDIT_TYPE_FEE_REVERSAL_CREDIT',
  FeeAndCreditTypeInactivityFee = 'FEE_AND_CREDIT_TYPE_INACTIVITY_FEE',
  FeeAndCreditTypeManagementFee = 'FEE_AND_CREDIT_TYPE_MANAGEMENT_FEE',
  FeeAndCreditTypeMarginInterestFee = 'FEE_AND_CREDIT_TYPE_MARGIN_INTEREST_FEE',
  FeeAndCreditTypePlatformFee = 'FEE_AND_CREDIT_TYPE_PLATFORM_FEE',
  FeeAndCreditTypePromotionalCredit = 'FEE_AND_CREDIT_TYPE_PROMOTIONAL_CREDIT',
  FeeAndCreditTypeRebateCredit = 'FEE_AND_CREDIT_TYPE_REBATE_CREDIT',
  FeeAndCreditTypeTechFee = 'FEE_AND_CREDIT_TYPE_TECH_FEE',
  FeeAndCreditTypeZeroingCredit = 'FEE_AND_CREDIT_TYPE_ZEROING_CREDIT',
}

/** Ordering options for FeeAndCreditProgramChangeLog connections */
export type FeeAndCreditProgramChangeLogOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order FeeAndCreditProgramChangeLogs. */
  field: FeeAndCreditProgramChangeLogOrderField;
};

/** Properties by which FeeAndCreditProgramChangeLog connections can be ordered. */
export enum FeeAndCreditProgramChangeLogOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * FeeAndCreditProgramChangeLogWhereInput is used for filtering FeeAndCreditProgramChangeLog objects.
 * Input was generated by ent.
 */
export type FeeAndCreditProgramChangeLogWhereInput = {
  /** account_description field predicates */
  accountDescription?: InputMaybe<Scalars['String']['input']>;
  accountDescriptionContains?: InputMaybe<Scalars['String']['input']>;
  accountDescriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  accountDescriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  accountDescriptionGT?: InputMaybe<Scalars['String']['input']>;
  accountDescriptionGTE?: InputMaybe<Scalars['String']['input']>;
  accountDescriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  accountDescriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  accountDescriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  accountDescriptionLT?: InputMaybe<Scalars['String']['input']>;
  accountDescriptionLTE?: InputMaybe<Scalars['String']['input']>;
  accountDescriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  accountDescriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** active field predicates */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  activeNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** amount field predicates */
  amount?: InputMaybe<Scalars['Decimal']['input']>;
  amountGT?: InputMaybe<Scalars['Decimal']['input']>;
  amountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  amountLT?: InputMaybe<Scalars['Decimal']['input']>;
  amountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  amountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  and?: InputMaybe<Array<FeeAndCreditProgramChangeLogWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** end field predicates */
  end?: InputMaybe<Scalars['Time']['input']>;
  endGT?: InputMaybe<Scalars['Time']['input']>;
  endGTE?: InputMaybe<Scalars['Time']['input']>;
  endIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  endIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  endLT?: InputMaybe<Scalars['Time']['input']>;
  endLTE?: InputMaybe<Scalars['Time']['input']>;
  endNEQ?: InputMaybe<Scalars['Time']['input']>;
  endNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  endNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_credit_vs_fee field predicates */
  isCreditVsFee?: InputMaybe<Scalars['Boolean']['input']>;
  isCreditVsFeeNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** limit field predicates */
  limit?: InputMaybe<Scalars['Int']['input']>;
  limitGT?: InputMaybe<Scalars['Int']['input']>;
  limitGTE?: InputMaybe<Scalars['Int']['input']>;
  limitIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  limitLT?: InputMaybe<Scalars['Int']['input']>;
  limitLTE?: InputMaybe<Scalars['Int']['input']>;
  limitNEQ?: InputMaybe<Scalars['Int']['input']>;
  limitNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<FeeAndCreditProgramChangeLogWhereInput>;
  or?: InputMaybe<Array<FeeAndCreditProgramChangeLogWhereInput>>;
  /** reason field predicates */
  reason?: InputMaybe<Scalars['String']['input']>;
  reasonContains?: InputMaybe<Scalars['String']['input']>;
  reasonContainsFold?: InputMaybe<Scalars['String']['input']>;
  reasonEqualFold?: InputMaybe<Scalars['String']['input']>;
  reasonGT?: InputMaybe<Scalars['String']['input']>;
  reasonGTE?: InputMaybe<Scalars['String']['input']>;
  reasonHasPrefix?: InputMaybe<Scalars['String']['input']>;
  reasonHasSuffix?: InputMaybe<Scalars['String']['input']>;
  reasonIn?: InputMaybe<Array<Scalars['String']['input']>>;
  reasonLT?: InputMaybe<Scalars['String']['input']>;
  reasonLTE?: InputMaybe<Scalars['String']['input']>;
  reasonNEQ?: InputMaybe<Scalars['String']['input']>;
  reasonNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** start field predicates */
  start?: InputMaybe<Scalars['Time']['input']>;
  startGT?: InputMaybe<Scalars['Time']['input']>;
  startGTE?: InputMaybe<Scalars['Time']['input']>;
  startIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  startLT?: InputMaybe<Scalars['Time']['input']>;
  startLTE?: InputMaybe<Scalars['Time']['input']>;
  startNEQ?: InputMaybe<Scalars['Time']['input']>;
  startNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** title field predicates */
  title?: InputMaybe<Scalars['String']['input']>;
  titleContains?: InputMaybe<Scalars['String']['input']>;
  titleContainsFold?: InputMaybe<Scalars['String']['input']>;
  titleEqualFold?: InputMaybe<Scalars['String']['input']>;
  titleGT?: InputMaybe<Scalars['String']['input']>;
  titleGTE?: InputMaybe<Scalars['String']['input']>;
  titleHasPrefix?: InputMaybe<Scalars['String']['input']>;
  titleHasSuffix?: InputMaybe<Scalars['String']['input']>;
  titleIn?: InputMaybe<Array<Scalars['String']['input']>>;
  titleLT?: InputMaybe<Scalars['String']['input']>;
  titleLTE?: InputMaybe<Scalars['String']['input']>;
  titleNEQ?: InputMaybe<Scalars['String']['input']>;
  titleNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** type field predicates */
  type?: InputMaybe<FeeAndCreditProgramChangeLogFeeAndCreditType>;
  typeIn?: InputMaybe<Array<FeeAndCreditProgramChangeLogFeeAndCreditType>>;
  typeNEQ?: InputMaybe<FeeAndCreditProgramChangeLogFeeAndCreditType>;
  typeNotIn?: InputMaybe<Array<FeeAndCreditProgramChangeLogFeeAndCreditType>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** version field predicates */
  version?: InputMaybe<Scalars['Int']['input']>;
  versionGT?: InputMaybe<Scalars['Int']['input']>;
  versionGTE?: InputMaybe<Scalars['Int']['input']>;
  versionIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  versionLT?: InputMaybe<Scalars['Int']['input']>;
  versionLTE?: InputMaybe<Scalars['Int']['input']>;
  versionNEQ?: InputMaybe<Scalars['Int']['input']>;
  versionNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type FeeAndCreditSubmission = Node & {
  __typename?: 'FeeAndCreditSubmission';
  /** User account associated with the fee submission */
  account: UserAccount;
  /** Description for order displayed to user */
  accountDescription: Scalars['String']['output'];
  /** value of the fee/credit order */
  amount: Scalars['Decimal']['output'];
  /** Receiving party's description of the fee */
  contraDescription: Scalars['String']['output'];
  createTime: Scalars['Time']['output'];
  error?: Maybe<FeeAndCreditError>;
  /** request id from Apex */
  externalID?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isFee: Scalars['Boolean']['output'];
  /** Note added to the submission for internal use */
  note?: Maybe<Scalars['String']['output']>;
  /** The individual parts for this order. */
  orders?: Maybe<Array<FeeAndCreditOrder>>;
  /** The time at which the action was requested. */
  requestTime: Scalars['Time']['output'];
  requestUUID?: Maybe<Scalars['UUID']['output']>;
  requestor: Requestor;
  status: FeeAndCreditSubmissionFeeAndCreditStatus;
  /** Title for order displayed to user */
  title: Scalars['String']['output'];
  type: FeeAndCreditSubmissionFeeAndCreditType;
  updateTime: Scalars['Time']['output'];
  /** User associated with the fee submission */
  user: User;
};

/** A connection to a list of items. */
export type FeeAndCreditSubmissionConnection = {
  __typename?: 'FeeAndCreditSubmissionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FeeAndCreditSubmissionEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type FeeAndCreditSubmissionEdge = {
  __typename?: 'FeeAndCreditSubmissionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<FeeAndCreditSubmission>;
};

/** FeeAndCreditSubmissionFeeAndCreditStatus is enum for the field status */
export enum FeeAndCreditSubmissionFeeAndCreditStatus {
  FeeAndCreditStatusBundled = 'FEE_AND_CREDIT_STATUS_BUNDLED',
  FeeAndCreditStatusCanceled = 'FEE_AND_CREDIT_STATUS_CANCELED',
  FeeAndCreditStatusCanceling = 'FEE_AND_CREDIT_STATUS_CANCELING',
  FeeAndCreditStatusFailed = 'FEE_AND_CREDIT_STATUS_FAILED',
  FeeAndCreditStatusFunded = 'FEE_AND_CREDIT_STATUS_FUNDED',
  FeeAndCreditStatusIncomplete = 'FEE_AND_CREDIT_STATUS_INCOMPLETE',
  FeeAndCreditStatusIssued = 'FEE_AND_CREDIT_STATUS_ISSUED',
  FeeAndCreditStatusNew = 'FEE_AND_CREDIT_STATUS_NEW',
  FeeAndCreditStatusSettled = 'FEE_AND_CREDIT_STATUS_SETTLED',
}

/** FeeAndCreditSubmissionFeeAndCreditType is enum for the field type */
export enum FeeAndCreditSubmissionFeeAndCreditType {
  FeeAndCreditTypeAdministrativeFee = 'FEE_AND_CREDIT_TYPE_ADMINISTRATIVE_FEE',
  FeeAndCreditTypeAdvisoryFee = 'FEE_AND_CREDIT_TYPE_ADVISORY_FEE',
  FeeAndCreditTypeCourtesyCredit = 'FEE_AND_CREDIT_TYPE_COURTESY_CREDIT',
  FeeAndCreditTypeExchangeFee = 'FEE_AND_CREDIT_TYPE_EXCHANGE_FEE',
  FeeAndCreditTypeFeeReversalCredit = 'FEE_AND_CREDIT_TYPE_FEE_REVERSAL_CREDIT',
  FeeAndCreditTypeInactivityFee = 'FEE_AND_CREDIT_TYPE_INACTIVITY_FEE',
  FeeAndCreditTypeManagementFee = 'FEE_AND_CREDIT_TYPE_MANAGEMENT_FEE',
  FeeAndCreditTypeMarginInterestFee = 'FEE_AND_CREDIT_TYPE_MARGIN_INTEREST_FEE',
  FeeAndCreditTypePlatformFee = 'FEE_AND_CREDIT_TYPE_PLATFORM_FEE',
  FeeAndCreditTypePromotionalCredit = 'FEE_AND_CREDIT_TYPE_PROMOTIONAL_CREDIT',
  FeeAndCreditTypeRebateCredit = 'FEE_AND_CREDIT_TYPE_REBATE_CREDIT',
  FeeAndCreditTypeTechFee = 'FEE_AND_CREDIT_TYPE_TECH_FEE',
  FeeAndCreditTypeZeroingCredit = 'FEE_AND_CREDIT_TYPE_ZEROING_CREDIT',
}

/** Ordering options for FeeAndCreditSubmission connections */
export type FeeAndCreditSubmissionOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order FeeAndCreditSubmissions. */
  field: FeeAndCreditSubmissionOrderField;
};

/** Properties by which FeeAndCreditSubmission connections can be ordered. */
export enum FeeAndCreditSubmissionOrderField {
  RequestTime = 'REQUEST_TIME',
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * FeeAndCreditSubmissionWhereInput is used for filtering FeeAndCreditSubmission objects.
 * Input was generated by ent.
 */
export type FeeAndCreditSubmissionWhereInput = {
  /** account_description field predicates */
  accountDescription?: InputMaybe<Scalars['String']['input']>;
  accountDescriptionContains?: InputMaybe<Scalars['String']['input']>;
  accountDescriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  accountDescriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  accountDescriptionGT?: InputMaybe<Scalars['String']['input']>;
  accountDescriptionGTE?: InputMaybe<Scalars['String']['input']>;
  accountDescriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  accountDescriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  accountDescriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  accountDescriptionLT?: InputMaybe<Scalars['String']['input']>;
  accountDescriptionLTE?: InputMaybe<Scalars['String']['input']>;
  accountDescriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  accountDescriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** amount field predicates */
  amount?: InputMaybe<Scalars['Decimal']['input']>;
  amountGT?: InputMaybe<Scalars['Decimal']['input']>;
  amountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  amountLT?: InputMaybe<Scalars['Decimal']['input']>;
  amountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  amountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  and?: InputMaybe<Array<FeeAndCreditSubmissionWhereInput>>;
  /** contra_description field predicates */
  contraDescription?: InputMaybe<Scalars['String']['input']>;
  contraDescriptionContains?: InputMaybe<Scalars['String']['input']>;
  contraDescriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  contraDescriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  contraDescriptionGT?: InputMaybe<Scalars['String']['input']>;
  contraDescriptionGTE?: InputMaybe<Scalars['String']['input']>;
  contraDescriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  contraDescriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  contraDescriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  contraDescriptionLT?: InputMaybe<Scalars['String']['input']>;
  contraDescriptionLTE?: InputMaybe<Scalars['String']['input']>;
  contraDescriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  contraDescriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** external_id field predicates */
  externalID?: InputMaybe<Scalars['String']['input']>;
  externalIDContains?: InputMaybe<Scalars['String']['input']>;
  externalIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  externalIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  externalIDGT?: InputMaybe<Scalars['String']['input']>;
  externalIDGTE?: InputMaybe<Scalars['String']['input']>;
  externalIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  externalIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  externalIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  externalIDLT?: InputMaybe<Scalars['String']['input']>;
  externalIDLTE?: InputMaybe<Scalars['String']['input']>;
  externalIDNEQ?: InputMaybe<Scalars['String']['input']>;
  externalIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** account edge predicates */
  hasAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountWith?: InputMaybe<Array<UserAccountWhereInput>>;
  /** orders edge predicates */
  hasOrders?: InputMaybe<Scalars['Boolean']['input']>;
  hasOrdersWith?: InputMaybe<Array<FeeAndCreditOrderWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_fee field predicates */
  isFee?: InputMaybe<Scalars['Boolean']['input']>;
  isFeeNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<FeeAndCreditSubmissionWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<FeeAndCreditSubmissionWhereInput>>;
  /** request_time field predicates */
  requestTime?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  requestTimeLT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** request_uuid field predicates */
  requestUUID?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  requestUUIDLT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDLTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNEQ?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNotIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** requestor field predicates */
  requestor?: InputMaybe<Requestor>;
  requestorIn?: InputMaybe<Array<Requestor>>;
  requestorNEQ?: InputMaybe<Requestor>;
  requestorNotIn?: InputMaybe<Array<Requestor>>;
  /** status field predicates */
  status?: InputMaybe<FeeAndCreditSubmissionFeeAndCreditStatus>;
  statusIn?: InputMaybe<Array<FeeAndCreditSubmissionFeeAndCreditStatus>>;
  statusNEQ?: InputMaybe<FeeAndCreditSubmissionFeeAndCreditStatus>;
  statusNotIn?: InputMaybe<Array<FeeAndCreditSubmissionFeeAndCreditStatus>>;
  /** title field predicates */
  title?: InputMaybe<Scalars['String']['input']>;
  titleContains?: InputMaybe<Scalars['String']['input']>;
  titleContainsFold?: InputMaybe<Scalars['String']['input']>;
  titleEqualFold?: InputMaybe<Scalars['String']['input']>;
  titleGT?: InputMaybe<Scalars['String']['input']>;
  titleGTE?: InputMaybe<Scalars['String']['input']>;
  titleHasPrefix?: InputMaybe<Scalars['String']['input']>;
  titleHasSuffix?: InputMaybe<Scalars['String']['input']>;
  titleIn?: InputMaybe<Array<Scalars['String']['input']>>;
  titleLT?: InputMaybe<Scalars['String']['input']>;
  titleLTE?: InputMaybe<Scalars['String']['input']>;
  titleNEQ?: InputMaybe<Scalars['String']['input']>;
  titleNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** type field predicates */
  type?: InputMaybe<FeeAndCreditSubmissionFeeAndCreditType>;
  typeIn?: InputMaybe<Array<FeeAndCreditSubmissionFeeAndCreditType>>;
  typeNEQ?: InputMaybe<FeeAndCreditSubmissionFeeAndCreditType>;
  typeNotIn?: InputMaybe<Array<FeeAndCreditSubmissionFeeAndCreditType>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type FiatAsset = BaseAsset &
  Node & {
    __typename?: 'FiatAsset';
    /** active indicates if the asset is currently active/exists. Examples of an inactive asset would be a delisted stock or a token that no longer exists. */
    active: Scalars['Boolean']['output'];
    /** Background color to use when displaying in the app */
    color?: Maybe<Scalars['String']['output']>;
    createTime: Scalars['Time']['output'];
    /** Description of the asset */
    description?: Maybe<Scalars['String']['output']>;
    /** hidden is used to determine if the asset should be displayed in the app */
    hidden: Scalars['Boolean']['output'];
    /** image to show when displaying the asset */
    iconURI?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    /**
     * image to show when displaying the asset
     * @deprecated No longer used.
     */
    imageLogo?: Maybe<Scalars['String']['output']>;
    /** image to show when displaying the asset */
    logoURI?: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
    symbol: Scalars['String']['output'];
    type: AssetAssetType;
    updateTime: Scalars['Time']['output'];
  };

/** A connection to a list of items. */
export type FiatAssetConnection = {
  __typename?: 'FiatAssetConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FiatAssetEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type FiatAssetEdge = {
  __typename?: 'FiatAssetEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<FiatAsset>;
};

/** Ordering options for FiatAsset connections */
export type FiatAssetOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order FiatAssets. */
  field: FiatAssetOrderField;
};

/** Properties by which FiatAsset connections can be ordered. */
export enum FiatAssetOrderField {
  CreateTime = 'create_time',
  Name = 'name',
  Symbol = 'symbol',
  UpdateTime = 'update_time',
}

/**
 * FiatAssetWhereInput is used for filtering FiatAsset objects.
 * Input was generated by ent.
 */
export type FiatAssetWhereInput = {
  /** active field predicates */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  activeNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<FiatAssetWhereInput>>;
  /** color field predicates */
  color?: InputMaybe<Scalars['String']['input']>;
  colorContains?: InputMaybe<Scalars['String']['input']>;
  colorContainsFold?: InputMaybe<Scalars['String']['input']>;
  colorEqualFold?: InputMaybe<Scalars['String']['input']>;
  colorGT?: InputMaybe<Scalars['String']['input']>;
  colorGTE?: InputMaybe<Scalars['String']['input']>;
  colorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  colorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  colorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  colorIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  colorLT?: InputMaybe<Scalars['String']['input']>;
  colorLTE?: InputMaybe<Scalars['String']['input']>;
  colorNEQ?: InputMaybe<Scalars['String']['input']>;
  colorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  colorNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** hidden field predicates */
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  hiddenNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** icon_uri field predicates */
  iconURI?: InputMaybe<Scalars['String']['input']>;
  iconURIContains?: InputMaybe<Scalars['String']['input']>;
  iconURIContainsFold?: InputMaybe<Scalars['String']['input']>;
  iconURIEqualFold?: InputMaybe<Scalars['String']['input']>;
  iconURIGT?: InputMaybe<Scalars['String']['input']>;
  iconURIGTE?: InputMaybe<Scalars['String']['input']>;
  iconURIHasPrefix?: InputMaybe<Scalars['String']['input']>;
  iconURIHasSuffix?: InputMaybe<Scalars['String']['input']>;
  iconURIIn?: InputMaybe<Array<Scalars['String']['input']>>;
  iconURIIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  iconURILT?: InputMaybe<Scalars['String']['input']>;
  iconURILTE?: InputMaybe<Scalars['String']['input']>;
  iconURINEQ?: InputMaybe<Scalars['String']['input']>;
  iconURINotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  iconURINotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** image_logo field predicates */
  imageLogo?: InputMaybe<Scalars['String']['input']>;
  imageLogoContains?: InputMaybe<Scalars['String']['input']>;
  imageLogoContainsFold?: InputMaybe<Scalars['String']['input']>;
  imageLogoEqualFold?: InputMaybe<Scalars['String']['input']>;
  imageLogoGT?: InputMaybe<Scalars['String']['input']>;
  imageLogoGTE?: InputMaybe<Scalars['String']['input']>;
  imageLogoHasPrefix?: InputMaybe<Scalars['String']['input']>;
  imageLogoHasSuffix?: InputMaybe<Scalars['String']['input']>;
  imageLogoIn?: InputMaybe<Array<Scalars['String']['input']>>;
  imageLogoIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  imageLogoLT?: InputMaybe<Scalars['String']['input']>;
  imageLogoLTE?: InputMaybe<Scalars['String']['input']>;
  imageLogoNEQ?: InputMaybe<Scalars['String']['input']>;
  imageLogoNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  imageLogoNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** includeHidden indicates if the response should include hidden values. If not set, it defaults to false. */
  includeHidden?: InputMaybe<Scalars['Boolean']['input']>;
  /** logo_uri field predicates */
  logoURI?: InputMaybe<Scalars['String']['input']>;
  logoURIContains?: InputMaybe<Scalars['String']['input']>;
  logoURIContainsFold?: InputMaybe<Scalars['String']['input']>;
  logoURIEqualFold?: InputMaybe<Scalars['String']['input']>;
  logoURIGT?: InputMaybe<Scalars['String']['input']>;
  logoURIGTE?: InputMaybe<Scalars['String']['input']>;
  logoURIHasPrefix?: InputMaybe<Scalars['String']['input']>;
  logoURIHasSuffix?: InputMaybe<Scalars['String']['input']>;
  logoURIIn?: InputMaybe<Array<Scalars['String']['input']>>;
  logoURIIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  logoURILT?: InputMaybe<Scalars['String']['input']>;
  logoURILTE?: InputMaybe<Scalars['String']['input']>;
  logoURINEQ?: InputMaybe<Scalars['String']['input']>;
  logoURINotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  logoURINotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<FiatAssetWhereInput>;
  or?: InputMaybe<Array<FiatAssetWhereInput>>;
  /** symbol field predicates */
  symbol?: InputMaybe<Scalars['String']['input']>;
  symbolContains?: InputMaybe<Scalars['String']['input']>;
  symbolContainsFold?: InputMaybe<Scalars['String']['input']>;
  symbolEqualFold?: InputMaybe<Scalars['String']['input']>;
  symbolGT?: InputMaybe<Scalars['String']['input']>;
  symbolGTE?: InputMaybe<Scalars['String']['input']>;
  symbolHasPrefix?: InputMaybe<Scalars['String']['input']>;
  symbolHasSuffix?: InputMaybe<Scalars['String']['input']>;
  symbolIn?: InputMaybe<Array<Scalars['String']['input']>>;
  symbolLT?: InputMaybe<Scalars['String']['input']>;
  symbolLTE?: InputMaybe<Scalars['String']['input']>;
  symbolNEQ?: InputMaybe<Scalars['String']['input']>;
  symbolNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type FinancialStatement = {
  __typename?: 'FinancialStatement';
  /** Book value per share (BVPS) is the ratio of equity available to common shareholders divided by the number of outstanding shares. This figure represents the minimum value of a company's equity and measures the book value of a firm on a per-share basis. */
  bookValuePerShare: Scalars['Decimal']['output'];
  date: Scalars['Time']['output'];
  /** earningsPerShare is calculated as a company's profit divided by the outstanding shares of its common stock. */
  earningsPerShare: Scalars['Decimal']['output'];
  /**
   * EBIT (Earnings Before Interest and Taxes) represents the company's core operating
   *   income before the deduction of interest and taxes.
   */
  ebit: Scalars['Decimal']['output'];
  /** EBITDA is a non-GAAP accounting metric that is widely used when assessing the performance of companies, calculated by adding Depreciation/Amortization back to Earnings before interest and taxes */
  ebitda: Scalars['Decimal']['output'];
  /** EBITDA margin is the ratio of EBITDA to total revenue. */
  ebitdaMargin: Scalars['Decimal']['output'];
  /** Long-term debt includes the total amount of debt obligations the company has that are due in more than one year. */
  longTermDebt: Scalars['Decimal']['output'];
  /** peRatio is the ratio of a company's share price to the company's earnings per share. */
  peRatio?: Maybe<Scalars['Decimal']['output']>;
  /** PEG ratio (Price/Earnings to Growth) is calculated by dividing the P/E (Price-to-Earnings) ratio by the company’s expected earnings growth rate. */
  pegRatio: Scalars['Decimal']['output'];
  /** profitMargin represents what percentage of sales has turned into profits. */
  profitMargin: Scalars['Decimal']['output'];
  /** revenues is the last reported revenues for the security. */
  revenue: Scalars['Decimal']['output'];
  /** Total cash represents the amount of cash and cash equivalents that a company holds on its balance sheet. */
  totalCash: Scalars['Decimal']['output'];
  /**
   * totalEnterpriseValue is the total value of a company, considering not only its equity
   *   but also its debt and cash. It is calculated as the market capitalization
   *     (equity value) - total debt + total cash.
   */
  totalEnterpriseValue: Scalars['Decimal']['output'];
};

export type FixedIncomeMarketData = {
  __typename?: 'FixedIncomeMarketData';
  /** ask is the current best ask price. */
  ask: Scalars['Decimal']['output'];
  /**
   * askSize represents the number of round lot orders at the given ask price. The normal round lot size is
   * 100 shares. An ask size of 2 means there are 200 shares for purchase at the given bid price.
   */
  askSize: Scalars['Decimal']['output'];
  /** bid is the current best bid price. */
  bid: Scalars['Decimal']['output'];
  /**
   * bidSize represents the number of round lot orders at the given bid price. The normal round lot size is
   * 100 shares. A bid size of 2 means there are 200 shares for purchase at the given bid price.
   */
  bidSize: Scalars['Decimal']['output'];
  /** last is the latest price of the asset. */
  last: Scalars['Decimal']['output'];
};

export type FnpMetadata = Node & {
  __typename?: 'FnpMetadata';
  bankingRelationships?: Maybe<Scalars['String']['output']>;
  directOrIndirectRelatedPeps?: Maybe<Scalars['Boolean']['output']>;
  foreignBondTrading?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  initialDepositAmount?: Maybe<Scalars['Decimal']['output']>;
  initialDepositSource?: Maybe<Scalars['String']['output']>;
  lowPricedSecurities?: Maybe<Scalars['Boolean']['output']>;
  ownerHasOtherAccountsAtApex?: Maybe<Scalars['Boolean']['output']>;
  primaryAccountActivityType?: Maybe<FnpMetadataUserPrimaryAccountActivityType>;
  userProfile?: Maybe<UserProfile>;
  withdrawalFrequency?: Maybe<FnpMetadataUserWithdrawalFrequency>;
};

/** FnpMetadataUserPrimaryAccountActivityType is enum for the field primary_account_activity_type */
export enum FnpMetadataUserPrimaryAccountActivityType {
  UserPrimaryAccountActivityTypeActiveTrading = 'USER_PRIMARY_ACCOUNT_ACTIVITY_TYPE_ACTIVE_TRADING',
  UserPrimaryAccountActivityTypeLongTermInvesting = 'USER_PRIMARY_ACCOUNT_ACTIVITY_TYPE_LONG_TERM_INVESTING',
  UserPrimaryAccountActivityTypeShortTermInvesting = 'USER_PRIMARY_ACCOUNT_ACTIVITY_TYPE_SHORT_TERM_INVESTING',
}

/** FnpMetadataUserWithdrawalFrequency is enum for the field withdrawal_frequency */
export enum FnpMetadataUserWithdrawalFrequency {
  UserWithdrawalFrequencyFrequent = 'USER_WITHDRAWAL_FREQUENCY_FREQUENT',
  UserWithdrawalFrequencyOccasional = 'USER_WITHDRAWAL_FREQUENCY_OCCASIONAL',
  UserWithdrawalFrequencyRare = 'USER_WITHDRAWAL_FREQUENCY_RARE',
}

/**
 * FnpMetadataWhereInput is used for filtering FnpMetadata objects.
 * Input was generated by ent.
 */
export type FnpMetadataWhereInput = {
  and?: InputMaybe<Array<FnpMetadataWhereInput>>;
  /** banking_relationships field predicates */
  bankingRelationships?: InputMaybe<Scalars['String']['input']>;
  bankingRelationshipsContains?: InputMaybe<Scalars['String']['input']>;
  bankingRelationshipsContainsFold?: InputMaybe<Scalars['String']['input']>;
  bankingRelationshipsEqualFold?: InputMaybe<Scalars['String']['input']>;
  bankingRelationshipsGT?: InputMaybe<Scalars['String']['input']>;
  bankingRelationshipsGTE?: InputMaybe<Scalars['String']['input']>;
  bankingRelationshipsHasPrefix?: InputMaybe<Scalars['String']['input']>;
  bankingRelationshipsHasSuffix?: InputMaybe<Scalars['String']['input']>;
  bankingRelationshipsIn?: InputMaybe<Array<Scalars['String']['input']>>;
  bankingRelationshipsIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  bankingRelationshipsLT?: InputMaybe<Scalars['String']['input']>;
  bankingRelationshipsLTE?: InputMaybe<Scalars['String']['input']>;
  bankingRelationshipsNEQ?: InputMaybe<Scalars['String']['input']>;
  bankingRelationshipsNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  bankingRelationshipsNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** direct_or_indirect_related_peps field predicates */
  directOrIndirectRelatedPeps?: InputMaybe<Scalars['Boolean']['input']>;
  directOrIndirectRelatedPepsIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  directOrIndirectRelatedPepsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  directOrIndirectRelatedPepsNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** foreign_bond_trading field predicates */
  foreignBondTrading?: InputMaybe<Scalars['Boolean']['input']>;
  foreignBondTradingIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  foreignBondTradingNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  foreignBondTradingNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** user_profile edge predicates */
  hasUserProfile?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserProfileWith?: InputMaybe<Array<UserProfileWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** initial_deposit_amount field predicates */
  initialDepositAmount?: InputMaybe<Scalars['Decimal']['input']>;
  initialDepositAmountGT?: InputMaybe<Scalars['Decimal']['input']>;
  initialDepositAmountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  initialDepositAmountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  initialDepositAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  initialDepositAmountLT?: InputMaybe<Scalars['Decimal']['input']>;
  initialDepositAmountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  initialDepositAmountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  initialDepositAmountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  initialDepositAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** initial_deposit_source field predicates */
  initialDepositSource?: InputMaybe<Scalars['String']['input']>;
  initialDepositSourceContains?: InputMaybe<Scalars['String']['input']>;
  initialDepositSourceContainsFold?: InputMaybe<Scalars['String']['input']>;
  initialDepositSourceEqualFold?: InputMaybe<Scalars['String']['input']>;
  initialDepositSourceGT?: InputMaybe<Scalars['String']['input']>;
  initialDepositSourceGTE?: InputMaybe<Scalars['String']['input']>;
  initialDepositSourceHasPrefix?: InputMaybe<Scalars['String']['input']>;
  initialDepositSourceHasSuffix?: InputMaybe<Scalars['String']['input']>;
  initialDepositSourceIn?: InputMaybe<Array<Scalars['String']['input']>>;
  initialDepositSourceIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  initialDepositSourceLT?: InputMaybe<Scalars['String']['input']>;
  initialDepositSourceLTE?: InputMaybe<Scalars['String']['input']>;
  initialDepositSourceNEQ?: InputMaybe<Scalars['String']['input']>;
  initialDepositSourceNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  initialDepositSourceNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** low_priced_securities field predicates */
  lowPricedSecurities?: InputMaybe<Scalars['Boolean']['input']>;
  lowPricedSecuritiesIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  lowPricedSecuritiesNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  lowPricedSecuritiesNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<FnpMetadataWhereInput>;
  or?: InputMaybe<Array<FnpMetadataWhereInput>>;
  /** owner_has_other_accounts_at_apex field predicates */
  ownerHasOtherAccountsAtApex?: InputMaybe<Scalars['Boolean']['input']>;
  ownerHasOtherAccountsAtApexIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  ownerHasOtherAccountsAtApexNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  ownerHasOtherAccountsAtApexNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** primary_account_activity_type field predicates */
  primaryAccountActivityType?: InputMaybe<FnpMetadataUserPrimaryAccountActivityType>;
  primaryAccountActivityTypeIn?: InputMaybe<Array<FnpMetadataUserPrimaryAccountActivityType>>;
  primaryAccountActivityTypeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  primaryAccountActivityTypeNEQ?: InputMaybe<FnpMetadataUserPrimaryAccountActivityType>;
  primaryAccountActivityTypeNotIn?: InputMaybe<Array<FnpMetadataUserPrimaryAccountActivityType>>;
  primaryAccountActivityTypeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** withdrawal_frequency field predicates */
  withdrawalFrequency?: InputMaybe<FnpMetadataUserWithdrawalFrequency>;
  withdrawalFrequencyIn?: InputMaybe<Array<FnpMetadataUserWithdrawalFrequency>>;
  withdrawalFrequencyIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  withdrawalFrequencyNEQ?: InputMaybe<FnpMetadataUserWithdrawalFrequency>;
  withdrawalFrequencyNotIn?: InputMaybe<Array<FnpMetadataUserWithdrawalFrequency>>;
  withdrawalFrequencyNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GoePortfolioMapping = Node & {
  __typename?: 'GOEPortfolioMapping';
  createTime: Scalars['Time']['output'];
  /** the id of the GOE portfolio */
  goePortfolioID: Scalars['Int']['output'];
  /** the model portfolio that was uploaded */
  history?: Maybe<Array<ModelPortfolio>>;
  id: Scalars['ID']['output'];
  /** the model portfolio that was uploaded */
  modelPortfolio: ModelPortfolio;
  updateTime: Scalars['Time']['output'];
};

/** Ordering options for GOEPortfolioMapping connections */
export type GoePortfolioMappingOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order GOEPortfolioMappings. */
  field: GoePortfolioMappingOrderField;
};

/** Properties by which GOEPortfolioMapping connections can be ordered. */
export enum GoePortfolioMappingOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * GOEPortfolioMappingWhereInput is used for filtering GOEPortfolioMapping objects.
 * Input was generated by ent.
 */
export type GoePortfolioMappingWhereInput = {
  and?: InputMaybe<Array<GoePortfolioMappingWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** goe_portfolio_id field predicates */
  goePortfolioID?: InputMaybe<Scalars['Int']['input']>;
  goePortfolioIDGT?: InputMaybe<Scalars['Int']['input']>;
  goePortfolioIDGTE?: InputMaybe<Scalars['Int']['input']>;
  goePortfolioIDIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  goePortfolioIDLT?: InputMaybe<Scalars['Int']['input']>;
  goePortfolioIDLTE?: InputMaybe<Scalars['Int']['input']>;
  goePortfolioIDNEQ?: InputMaybe<Scalars['Int']['input']>;
  goePortfolioIDNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** history edge predicates */
  hasHistory?: InputMaybe<Scalars['Boolean']['input']>;
  hasHistoryWith?: InputMaybe<Array<ModelPortfolioWhereInput>>;
  /** model_portfolio edge predicates */
  hasModelPortfolio?: InputMaybe<Scalars['Boolean']['input']>;
  hasModelPortfolioWith?: InputMaybe<Array<ModelPortfolioWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<GoePortfolioMappingWhereInput>;
  or?: InputMaybe<Array<GoePortfolioMappingWhereInput>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/**
 * GeneralInvestmentPortfolios contains the mappings from general investment risk levels
 * to the portfolios that are recommended for those risk levels.
 */
export type GeneralInvestmentPortfolios = {
  __typename?: 'GeneralInvestmentPortfolios';
  /** Recommended portfolio for the aggressive risk level. */
  aggressive: ModelPortfolio;
  /** Recommended portfolio for the conservative risk level. */
  conservative: ModelPortfolio;
  /** Recommended portfolio for the moderate risk level. */
  moderate: ModelPortfolio;
  /** Recommended portfolio for the moderately aggressive risk level. */
  moderatelyAggressive: ModelPortfolio;
  /** Recommended portfolio for the moderately conservative risk level. */
  moderatelyConservative: ModelPortfolio;
};

/**
 * GoalTenureRecommendationProbability is the probabilty of a hitting a goal if the tenure
 * where to change by a certain amount.
 */
export type GoalTenureRecommendationProbability = {
  __typename?: 'GoalTenureRecommendationProbability';
  /** Probability is the probability of hitting the goal. */
  probability: Scalars['Float']['output'];
  /** TenureChange is the change in tenure in years. */
  tenureChange: Scalars['Int']['output'];
};

export type GoalValueProjection = {
  __typename?: 'GoalValueProjection';
  /**
   * probability is the estimated probability of the goal investment following
   * the wealth path.
   */
  probability: Scalars['Int']['output'];
  /** wealthPath is the wealth path of the goal investment per year. */
  wealthPath: Array<Scalars['Float']['output']>;
};

/** GoalValueProjections is an estimate for the value of a goal at different probabilities for a given year. */
export type GoalValueProjections = {
  __typename?: 'GoalValueProjections';
  /** date markers of the estimates. */
  dates: Array<Scalars['Date']['output']>;
  /**
   * default is the expected wealth path of the goal investment, in line with the
   * default risk profile chosen.
   */
  default?: Maybe<GoalValueProjection>;
  /**
   * optimistic is the expected wealth path of the goal investment given if a lower Probability
   * of hitting the goal is desired.
   */
  optimistic?: Maybe<GoalValueProjection>;
  /**
   * pessimistic is the expected wealth path of the goal investment given if a higher probability
   * of hitting the goal is desired.
   */
  pessimistic?: Maybe<GoalValueProjection>;
  /** @deprecated Use dates. */
  years: Array<Scalars['Int']['output']>;
};

export type GoalsWhereInput = {
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type HoldingAsset = {
  __typename?: 'HoldingAsset';
  name: Scalars['String']['output'];
  symbol: Scalars['String']['output'];
};

export type IctPartnerInstitution = Node & {
  __typename?: 'ICTPartnerInstitution';
  createTime: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  institution?: Maybe<Institution>;
  partnerProgramType: IctPartnerInstitutionIctProgramType;
  /** The name of the originating sdk to use in supporting the API. */
  sdkName: Scalars['String']['output'];
  /** The name of the originating financial institution. */
  title: Scalars['String']['output'];
  updateTime: Scalars['Time']['output'];
};

/** ICTPartnerInstitutionICTProgramType is enum for the field partner_program_type */
export enum IctPartnerInstitutionIctProgramType {
  IctProgramTypeBrokerPartner = 'ICT_PROGRAM_TYPE_BROKER_PARTNER',
  IctProgramTypeDepositOnly = 'ICT_PROGRAM_TYPE_DEPOSIT_ONLY',
}

/** Ordering options for ICTPartnerInstitution connections */
export type IctPartnerInstitutionOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ICTPartnerInstitutions. */
  field: IctPartnerInstitutionOrderField;
};

/** Properties by which ICTPartnerInstitution connections can be ordered. */
export enum IctPartnerInstitutionOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * ICTPartnerInstitutionWhereInput is used for filtering ICTPartnerInstitution objects.
 * Input was generated by ent.
 */
export type IctPartnerInstitutionWhereInput = {
  and?: InputMaybe<Array<IctPartnerInstitutionWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** institution edge predicates */
  hasInstitution?: InputMaybe<Scalars['Boolean']['input']>;
  hasInstitutionWith?: InputMaybe<Array<InstitutionWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<IctPartnerInstitutionWhereInput>;
  or?: InputMaybe<Array<IctPartnerInstitutionWhereInput>>;
  /** partner_program_type field predicates */
  partnerProgramType?: InputMaybe<IctPartnerInstitutionIctProgramType>;
  partnerProgramTypeIn?: InputMaybe<Array<IctPartnerInstitutionIctProgramType>>;
  partnerProgramTypeNEQ?: InputMaybe<IctPartnerInstitutionIctProgramType>;
  partnerProgramTypeNotIn?: InputMaybe<Array<IctPartnerInstitutionIctProgramType>>;
  /** sdk_name field predicates */
  sdkName?: InputMaybe<Scalars['String']['input']>;
  sdkNameContains?: InputMaybe<Scalars['String']['input']>;
  sdkNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  sdkNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  sdkNameGT?: InputMaybe<Scalars['String']['input']>;
  sdkNameGTE?: InputMaybe<Scalars['String']['input']>;
  sdkNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  sdkNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  sdkNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  sdkNameLT?: InputMaybe<Scalars['String']['input']>;
  sdkNameLTE?: InputMaybe<Scalars['String']['input']>;
  sdkNameNEQ?: InputMaybe<Scalars['String']['input']>;
  sdkNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** title field predicates */
  title?: InputMaybe<Scalars['String']['input']>;
  titleContains?: InputMaybe<Scalars['String']['input']>;
  titleContainsFold?: InputMaybe<Scalars['String']['input']>;
  titleEqualFold?: InputMaybe<Scalars['String']['input']>;
  titleGT?: InputMaybe<Scalars['String']['input']>;
  titleGTE?: InputMaybe<Scalars['String']['input']>;
  titleHasPrefix?: InputMaybe<Scalars['String']['input']>;
  titleHasSuffix?: InputMaybe<Scalars['String']['input']>;
  titleIn?: InputMaybe<Array<Scalars['String']['input']>>;
  titleLT?: InputMaybe<Scalars['String']['input']>;
  titleLTE?: InputMaybe<Scalars['String']['input']>;
  titleNEQ?: InputMaybe<Scalars['String']['input']>;
  titleNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type IctTransfer = BaseOrder &
  Node & {
    __typename?: 'ICTTransfer';
    /** Amount is the amount of the asset to be transferred. */
    amount: Scalars['Decimal']['output'];
    createTime: Scalars['Time']['output'];
    /** ErrorCode is the error code of the ach transfer if it failed */
    errorCode?: Maybe<IctTransferTransferErrorCode>;
    /** ExpectedSettlement is the date that the transfer is expected to settle. */
    expectedSettlementDate?: Maybe<Scalars['Date']['output']>;
    /** ExternalAccount is the external account that the transfer was made to or from. */
    externalAccount: ExternalAccount;
    /** external id of the transfer provided by the entity performing the transfer. */
    externalID?: Maybe<Scalars['String']['output']>;
    /** FeeAndCreditOrder is the fee that was assessed on this order. */
    feeOrder?: Maybe<FeeAndCreditOrder>;
    holdReason?: Maybe<IctTransferTransferHoldReason>;
    id: Scalars['ID']['output'];
    /** Amount of a deposit that was immediately cleared as an instant deposit. */
    instantDepositAmount?: Maybe<Scalars['Decimal']['output']>;
    isReturned: Scalars['Boolean']['output'];
    /** the time that the transfer was issued to the clearing house */
    issuedTime?: Maybe<Scalars['Time']['output']>;
    /** Lockup is the lockup that was created for this transfer. */
    lockup?: Maybe<AssetLockup>;
    /** Notes is the notes of the order intended for the principal. */
    note?: Maybe<Scalars['String']['output']>;
    orderStatus: OrderOrderStatus;
    orderType: OrderOrderType;
    portfolio: UserAccountPortfolio;
    /** Reason is a description as to why the transfer failed. */
    reason?: Maybe<Scalars['String']['output']>;
    /** RecurringRuleInstance is the recurring rule instance that created this transfer. */
    recurringRuleInstance?: Maybe<RecurringRuleInstance>;
    requestDetails: RequestDetails;
    retirementContributionInfo?: Maybe<RetirementContributionInfo>;
    retirementDistributionInfo?: Maybe<RetirementDistributionInfo>;
    /** Fee assessed because of a returned transfer. */
    returnFee?: Maybe<Scalars['Decimal']['output']>;
    /** the time that the transfer was settled. */
    settledTime?: Maybe<Scalars['Time']['output']>;
    status: IctTransferTransferStatus;
    statusChangeLog: Array<IctTransferStatusChangeLog>;
    /** Fee assessed by fintron. */
    transferFee?: Maybe<Scalars['Decimal']['output']>;
    type: IctTransferTransferType;
    /** the time that confirmation for the transfer was received. */
    unsettledTime?: Maybe<Scalars['Time']['output']>;
    updateTime: Scalars['Time']['output'];
    user: User;
  };

export type IctTransferStatusChangeLogArgs = {
  status?: InputMaybe<IctTransferTransferStatus>;
  statusIn?: InputMaybe<Array<IctTransferTransferStatus>>;
};

/** A connection to a list of items. */
export type IctTransferConnection = {
  __typename?: 'ICTTransferConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IctTransferEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IctTransferEdge = {
  __typename?: 'ICTTransferEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IctTransfer>;
};

/** Ordering options for ICTTransfer connections */
export type IctTransferOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ICTTransfers. */
  field: IctTransferOrderField;
};

/** Properties by which ICTTransfer connections can be ordered. */
export enum IctTransferOrderField {
  CreateTime = 'create_time',
  IssuedTime = 'issued_time',
  SettledTime = 'settled_time',
  UnsettledTime = 'unsettled_time',
  UpdateTime = 'update_time',
}

export type IctTransferStatusChangeLog = {
  __typename?: 'ICTTransferStatusChangeLog';
  eventTime: Scalars['Time']['output'];
  note: Scalars['String']['output'];
  status: IctTransferTransferStatus;
};

/** ICTTransferTransferErrorCode is enum for the field error_code */
export enum IctTransferTransferErrorCode {
  TransferErrorCodeExceedsDailyLimit = 'TRANSFER_ERROR_CODE_EXCEEDS_DAILY_LIMIT',
  TransferErrorCodeInsufficientFunds = 'TRANSFER_ERROR_CODE_INSUFFICIENT_FUNDS',
  TransferErrorCodeInvalidAccountStatus = 'TRANSFER_ERROR_CODE_INVALID_ACCOUNT_STATUS',
  TransferErrorCodeInvalidAmount = 'TRANSFER_ERROR_CODE_INVALID_AMOUNT',
  TransferErrorCodeInvalidOrder = 'TRANSFER_ERROR_CODE_INVALID_ORDER',
  TransferErrorCodeOther = 'TRANSFER_ERROR_CODE_OTHER',
  TransferErrorCodeServerError = 'TRANSFER_ERROR_CODE_SERVER_ERROR',
  TransferErrorCodeUnapproved = 'TRANSFER_ERROR_CODE_UNAPPROVED',
  TransferErrorCodeUnsupported = 'TRANSFER_ERROR_CODE_UNSUPPORTED',
  TransferErrorCodeValidationFailed = 'TRANSFER_ERROR_CODE_VALIDATION_FAILED',
}

/** ICTTransferTransferHoldReason is enum for the field hold_reason */
export enum IctTransferTransferHoldReason {
  TransferHoldReasonAccountNeedsAttention = 'TRANSFER_HOLD_REASON_ACCOUNT_NEEDS_ATTENTION',
  TransferHoldReasonAccountOpenPending = 'TRANSFER_HOLD_REASON_ACCOUNT_OPEN_PENDING',
  TransferHoldReasonWithdrawLockup = 'TRANSFER_HOLD_REASON_WITHDRAW_LOCKUP',
}

/** ICTTransferTransferStatus is enum for the field status */
export enum IctTransferTransferStatus {
  TransferStatusCanceled = 'TRANSFER_STATUS_CANCELED',
  TransferStatusCanceling = 'TRANSFER_STATUS_CANCELING',
  TransferStatusFailed = 'TRANSFER_STATUS_FAILED',
  TransferStatusFunded = 'TRANSFER_STATUS_FUNDED',
  TransferStatusHold = 'TRANSFER_STATUS_HOLD',
  TransferStatusIssued = 'TRANSFER_STATUS_ISSUED',
  TransferStatusNew = 'TRANSFER_STATUS_NEW',
  TransferStatusSettled = 'TRANSFER_STATUS_SETTLED',
  TransferStatusUnsettled = 'TRANSFER_STATUS_UNSETTLED',
}

/** ICTTransferTransferType is enum for the field type */
export enum IctTransferTransferType {
  TransferTypeDeposit = 'TRANSFER_TYPE_DEPOSIT',
  TransferTypeWithdraw = 'TRANSFER_TYPE_WITHDRAW',
}

/**
 * ICTTransferWhereInput is used for filtering ICTTransfer objects.
 * Input was generated by ent.
 */
export type IctTransferWhereInput = {
  /** amount field predicates */
  amount?: InputMaybe<Scalars['Decimal']['input']>;
  amountGT?: InputMaybe<Scalars['Decimal']['input']>;
  amountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  amountLT?: InputMaybe<Scalars['Decimal']['input']>;
  amountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  amountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  and?: InputMaybe<Array<IctTransferWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** error_code field predicates */
  errorCode?: InputMaybe<IctTransferTransferErrorCode>;
  errorCodeIn?: InputMaybe<Array<IctTransferTransferErrorCode>>;
  errorCodeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  errorCodeNEQ?: InputMaybe<IctTransferTransferErrorCode>;
  errorCodeNotIn?: InputMaybe<Array<IctTransferTransferErrorCode>>;
  errorCodeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** expected_settlement_date field predicates */
  expectedSettlementDate?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateGT?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateGTE?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  expectedSettlementDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  expectedSettlementDateLT?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateLTE?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  expectedSettlementDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** external_id field predicates */
  externalID?: InputMaybe<Scalars['String']['input']>;
  externalIDContains?: InputMaybe<Scalars['String']['input']>;
  externalIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  externalIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  externalIDGT?: InputMaybe<Scalars['String']['input']>;
  externalIDGTE?: InputMaybe<Scalars['String']['input']>;
  externalIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  externalIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  externalIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  externalIDLT?: InputMaybe<Scalars['String']['input']>;
  externalIDLTE?: InputMaybe<Scalars['String']['input']>;
  externalIDNEQ?: InputMaybe<Scalars['String']['input']>;
  externalIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** external_account edge predicates */
  hasExternalAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasExternalAccountWith?: InputMaybe<Array<ExternalAccountWhereInput>>;
  /** fee_order edge predicates */
  hasFeeOrder?: InputMaybe<Scalars['Boolean']['input']>;
  hasFeeOrderWith?: InputMaybe<Array<FeeAndCreditOrderWhereInput>>;
  /** lockup edge predicates */
  hasLockup?: InputMaybe<Scalars['Boolean']['input']>;
  hasLockupWith?: InputMaybe<Array<AssetLockupWhereInput>>;
  /** portfolio edge predicates */
  hasPortfolio?: InputMaybe<Scalars['Boolean']['input']>;
  hasPortfolioWith?: InputMaybe<Array<UserAccountPortfolioWhereInput>>;
  /** recurring_rule_instance edge predicates */
  hasRecurringRuleInstance?: InputMaybe<Scalars['Boolean']['input']>;
  hasRecurringRuleInstanceWith?: InputMaybe<Array<RecurringRuleInstanceWhereInput>>;
  /** retirement_contribution_info edge predicates */
  hasRetirementContributionInfo?: InputMaybe<Scalars['Boolean']['input']>;
  hasRetirementContributionInfoWith?: InputMaybe<Array<RetirementContributionInfoWhereInput>>;
  /** retirement_distribution_info edge predicates */
  hasRetirementDistributionInfo?: InputMaybe<Scalars['Boolean']['input']>;
  hasRetirementDistributionInfoWith?: InputMaybe<Array<RetirementDistributionInfoWhereInput>>;
  hasUserWith?: InputMaybe<UserWhereInput>;
  /** hold_reason field predicates */
  holdReason?: InputMaybe<IctTransferTransferHoldReason>;
  holdReasonIn?: InputMaybe<Array<IctTransferTransferHoldReason>>;
  holdReasonIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  holdReasonNEQ?: InputMaybe<IctTransferTransferHoldReason>;
  holdReasonNotIn?: InputMaybe<Array<IctTransferTransferHoldReason>>;
  holdReasonNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** instant_deposit_amount field predicates */
  instantDepositAmount?: InputMaybe<Scalars['Decimal']['input']>;
  instantDepositAmountGT?: InputMaybe<Scalars['Decimal']['input']>;
  instantDepositAmountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  instantDepositAmountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  instantDepositAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  instantDepositAmountLT?: InputMaybe<Scalars['Decimal']['input']>;
  instantDepositAmountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  instantDepositAmountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  instantDepositAmountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  instantDepositAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** is_returned field predicates */
  isReturned?: InputMaybe<Scalars['Boolean']['input']>;
  isReturnedNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** issued_time field predicates */
  issuedTime?: InputMaybe<Scalars['Time']['input']>;
  issuedTimeGT?: InputMaybe<Scalars['Time']['input']>;
  issuedTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  issuedTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  issuedTimeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  issuedTimeLT?: InputMaybe<Scalars['Time']['input']>;
  issuedTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  issuedTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  issuedTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  issuedTimeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<IctTransferWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<IctTransferWhereInput>>;
  /** reason field predicates */
  reason?: InputMaybe<Scalars['String']['input']>;
  reasonContains?: InputMaybe<Scalars['String']['input']>;
  reasonContainsFold?: InputMaybe<Scalars['String']['input']>;
  reasonEqualFold?: InputMaybe<Scalars['String']['input']>;
  reasonGT?: InputMaybe<Scalars['String']['input']>;
  reasonGTE?: InputMaybe<Scalars['String']['input']>;
  reasonHasPrefix?: InputMaybe<Scalars['String']['input']>;
  reasonHasSuffix?: InputMaybe<Scalars['String']['input']>;
  reasonIn?: InputMaybe<Array<Scalars['String']['input']>>;
  reasonIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  reasonLT?: InputMaybe<Scalars['String']['input']>;
  reasonLTE?: InputMaybe<Scalars['String']['input']>;
  reasonNEQ?: InputMaybe<Scalars['String']['input']>;
  reasonNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  reasonNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** return_fee field predicates */
  returnFee?: InputMaybe<Scalars['Decimal']['input']>;
  returnFeeGT?: InputMaybe<Scalars['Decimal']['input']>;
  returnFeeGTE?: InputMaybe<Scalars['Decimal']['input']>;
  returnFeeIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  returnFeeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  returnFeeLT?: InputMaybe<Scalars['Decimal']['input']>;
  returnFeeLTE?: InputMaybe<Scalars['Decimal']['input']>;
  returnFeeNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  returnFeeNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  returnFeeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** settled_time field predicates */
  settledTime?: InputMaybe<Scalars['Time']['input']>;
  settledTimeGT?: InputMaybe<Scalars['Time']['input']>;
  settledTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  settledTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  settledTimeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  settledTimeLT?: InputMaybe<Scalars['Time']['input']>;
  settledTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  settledTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  settledTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  settledTimeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** status field predicates */
  status?: InputMaybe<IctTransferTransferStatus>;
  statusIn?: InputMaybe<Array<IctTransferTransferStatus>>;
  statusNEQ?: InputMaybe<IctTransferTransferStatus>;
  statusNotIn?: InputMaybe<Array<IctTransferTransferStatus>>;
  /** transfer_fee field predicates */
  transferFee?: InputMaybe<Scalars['Decimal']['input']>;
  transferFeeGT?: InputMaybe<Scalars['Decimal']['input']>;
  transferFeeGTE?: InputMaybe<Scalars['Decimal']['input']>;
  transferFeeIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  transferFeeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  transferFeeLT?: InputMaybe<Scalars['Decimal']['input']>;
  transferFeeLTE?: InputMaybe<Scalars['Decimal']['input']>;
  transferFeeNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  transferFeeNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  transferFeeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** type field predicates */
  type?: InputMaybe<IctTransferTransferType>;
  typeIn?: InputMaybe<Array<IctTransferTransferType>>;
  typeNEQ?: InputMaybe<IctTransferTransferType>;
  typeNotIn?: InputMaybe<Array<IctTransferTransferType>>;
  /** unsettled_time field predicates */
  unsettledTime?: InputMaybe<Scalars['Time']['input']>;
  unsettledTimeGT?: InputMaybe<Scalars['Time']['input']>;
  unsettledTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  unsettledTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  unsettledTimeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  unsettledTimeLT?: InputMaybe<Scalars['Time']['input']>;
  unsettledTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  unsettledTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  unsettledTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  unsettledTimeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type IdentificationCard = {
  __typename?: 'IdentificationCard';
  back?: Maybe<Scalars['String']['output']>;
  front?: Maybe<Scalars['String']['output']>;
};

export type Institution = Node & {
  __typename?: 'Institution';
  createTime: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  unusualCount: Scalars['Int']['output'];
  updateTime: Scalars['Time']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

/** Ordering options for Institution connections */
export type InstitutionOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Institutions. */
  field: InstitutionOrderField;
};

/** Properties by which Institution connections can be ordered. */
export enum InstitutionOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * InstitutionWhereInput is used for filtering Institution objects.
 * Input was generated by ent.
 */
export type InstitutionWhereInput = {
  and?: InputMaybe<Array<InstitutionWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** logo field predicates */
  logo?: InputMaybe<Scalars['String']['input']>;
  logoContains?: InputMaybe<Scalars['String']['input']>;
  logoContainsFold?: InputMaybe<Scalars['String']['input']>;
  logoEqualFold?: InputMaybe<Scalars['String']['input']>;
  logoGT?: InputMaybe<Scalars['String']['input']>;
  logoGTE?: InputMaybe<Scalars['String']['input']>;
  logoHasPrefix?: InputMaybe<Scalars['String']['input']>;
  logoHasSuffix?: InputMaybe<Scalars['String']['input']>;
  logoIn?: InputMaybe<Array<Scalars['String']['input']>>;
  logoIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  logoLT?: InputMaybe<Scalars['String']['input']>;
  logoLTE?: InputMaybe<Scalars['String']['input']>;
  logoNEQ?: InputMaybe<Scalars['String']['input']>;
  logoNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  logoNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<InstitutionWhereInput>;
  or?: InputMaybe<Array<InstitutionWhereInput>>;
  /** unusual_count field predicates */
  unusualCount?: InputMaybe<Scalars['Int']['input']>;
  unusualCountGT?: InputMaybe<Scalars['Int']['input']>;
  unusualCountGTE?: InputMaybe<Scalars['Int']['input']>;
  unusualCountIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  unusualCountLT?: InputMaybe<Scalars['Int']['input']>;
  unusualCountLTE?: InputMaybe<Scalars['Int']['input']>;
  unusualCountNEQ?: InputMaybe<Scalars['Int']['input']>;
  unusualCountNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** website field predicates */
  website?: InputMaybe<Scalars['String']['input']>;
  websiteContains?: InputMaybe<Scalars['String']['input']>;
  websiteContainsFold?: InputMaybe<Scalars['String']['input']>;
  websiteEqualFold?: InputMaybe<Scalars['String']['input']>;
  websiteGT?: InputMaybe<Scalars['String']['input']>;
  websiteGTE?: InputMaybe<Scalars['String']['input']>;
  websiteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  websiteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  websiteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  websiteIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  websiteLT?: InputMaybe<Scalars['String']['input']>;
  websiteLTE?: InputMaybe<Scalars['String']['input']>;
  websiteNEQ?: InputMaybe<Scalars['String']['input']>;
  websiteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  websiteNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InterestDistribution = BaseOrder &
  Node & {
    __typename?: 'InterestDistribution';
    /** Total amount of the interest asset distributed to the portfolio. */
    amount: Scalars['Decimal']['output'];
    /** asset that is owned by the user to earn the interest. */
    asset: BaseAsset;
    createTime: Scalars['Time']['output'];
    /** asset that is paid out to the user. */
    distributionAsset?: Maybe<BaseAsset>;
    /** The date interest is applied to the user's account. */
    effectiveDate: Scalars['Date']['output'];
    id: Scalars['ID']['output'];
    isReinvested: Scalars['Boolean']['output'];
    /** Notes is the notes of the order intended for the principal. */
    note?: Maybe<Scalars['String']['output']>;
    orderStatus: OrderOrderStatus;
    orderType: OrderOrderType;
    portfolio: UserAccountPortfolio;
    /** Shares of the underlying asset on the record date used to calculated the distribution of interest. */
    position?: Maybe<Scalars['Decimal']['output']>;
    /** Date the interest was processed */
    processDate: Scalars['Date']['output'];
    /** Date the interest was recorded */
    recordDate: Scalars['Date']['output'];
    requestDetails: RequestDetails;
    /** Program that generated the interest. */
    source: InterestDistributionInterestSource;
    /** Whether the interest is a fee (debit) or a credit. */
    type: InterestDistributionInterestType;
    updateTime: Scalars['Time']['output'];
    user: User;
  };

/** A connection to a list of items. */
export type InterestDistributionConnection = {
  __typename?: 'InterestDistributionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InterestDistributionEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InterestDistributionEdge = {
  __typename?: 'InterestDistributionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<InterestDistribution>;
};

/** InterestDistributionInterestSource is enum for the field source */
export enum InterestDistributionInterestSource {
  InterestSourceCashSweepCredit = 'INTEREST_SOURCE_CASH_SWEEP_CREDIT',
  InterestSourceFpslCredit = 'INTEREST_SOURCE_FPSL_CREDIT',
}

/** InterestDistributionInterestType is enum for the field type */
export enum InterestDistributionInterestType {
  InterestTypeCredit = 'INTEREST_TYPE_CREDIT',
  InterestTypeFee = 'INTEREST_TYPE_FEE',
}

/** Ordering options for InterestDistribution connections */
export type InterestDistributionOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order InterestDistributions. */
  field: InterestDistributionOrderField;
};

/** Properties by which InterestDistribution connections can be ordered. */
export enum InterestDistributionOrderField {
  CreateTime = 'create_time',
  EffectiveDate = 'effectiveDate',
  ProcessDate = 'processDate',
  RecordDate = 'recordDate',
  UpdateTime = 'update_time',
}

/**
 * InterestDistributionWhereInput is used for filtering InterestDistribution objects.
 * Input was generated by ent.
 */
export type InterestDistributionWhereInput = {
  /** amount field predicates */
  amount?: InputMaybe<Scalars['Decimal']['input']>;
  amountGT?: InputMaybe<Scalars['Decimal']['input']>;
  amountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  amountLT?: InputMaybe<Scalars['Decimal']['input']>;
  amountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  amountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  and?: InputMaybe<Array<InterestDistributionWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** effective_date field predicates */
  effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateGT?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateGTE?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  effectiveDateLT?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateLTE?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  effectiveDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  /** portfolio edge predicates */
  hasPortfolio?: InputMaybe<Scalars['Boolean']['input']>;
  hasPortfolioWith?: InputMaybe<Array<UserAccountPortfolioWhereInput>>;
  hasUserWith?: InputMaybe<UserWhereInput>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<InterestDistributionWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InterestDistributionWhereInput>>;
  /** position field predicates */
  position?: InputMaybe<Scalars['Decimal']['input']>;
  positionGT?: InputMaybe<Scalars['Decimal']['input']>;
  positionGTE?: InputMaybe<Scalars['Decimal']['input']>;
  positionIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  positionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  positionLT?: InputMaybe<Scalars['Decimal']['input']>;
  positionLTE?: InputMaybe<Scalars['Decimal']['input']>;
  positionNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  positionNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  positionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** process_date field predicates */
  processDate?: InputMaybe<Scalars['Date']['input']>;
  processDateGT?: InputMaybe<Scalars['Date']['input']>;
  processDateGTE?: InputMaybe<Scalars['Date']['input']>;
  processDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  processDateLT?: InputMaybe<Scalars['Date']['input']>;
  processDateLTE?: InputMaybe<Scalars['Date']['input']>;
  processDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  processDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  /** record_date field predicates */
  recordDate?: InputMaybe<Scalars['Date']['input']>;
  recordDateGT?: InputMaybe<Scalars['Date']['input']>;
  recordDateGTE?: InputMaybe<Scalars['Date']['input']>;
  recordDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  recordDateLT?: InputMaybe<Scalars['Date']['input']>;
  recordDateLTE?: InputMaybe<Scalars['Date']['input']>;
  recordDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  recordDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  /** source field predicates */
  source?: InputMaybe<InterestDistributionInterestSource>;
  sourceIn?: InputMaybe<Array<InterestDistributionInterestSource>>;
  sourceNEQ?: InputMaybe<InterestDistributionInterestSource>;
  sourceNotIn?: InputMaybe<Array<InterestDistributionInterestSource>>;
  /** type field predicates */
  type?: InputMaybe<InterestDistributionInterestType>;
  typeIn?: InputMaybe<Array<InterestDistributionInterestType>>;
  typeNEQ?: InputMaybe<InterestDistributionInterestType>;
  typeNotIn?: InputMaybe<Array<InterestDistributionInterestType>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type IntroducingBroker = Node & {
  __typename?: 'IntroducingBroker';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  regulatedEntity: RegulatedEntity;
};

/**
 * IntroducingBrokerWhereInput is used for filtering IntroducingBroker objects.
 * Input was generated by ent.
 */
export type IntroducingBrokerWhereInput = {
  and?: InputMaybe<Array<IntroducingBrokerWhereInput>>;
  /** regulated_entity edge predicates */
  hasRegulatedEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasRegulatedEntityWith?: InputMaybe<Array<RegulatedEntityWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<IntroducingBrokerWhereInput>;
  or?: InputMaybe<Array<IntroducingBrokerWhereInput>>;
};

export type InvestorDocument = Node & {
  __typename?: 'InvestorDocument';
  /** @deprecated Use userAccount instead */
  Account?: Maybe<UserAccount>;
  /** @deprecated Use category instead */
  DocumentCategory?: Maybe<InvestorDocumentCategory>;
  /** @deprecated Use date instead */
  DocumentDate: Scalars['Date']['output'];
  /** @deprecated Use type instead */
  DocumentType: InvestorDocumentType;
  /** @deprecated Use url instead */
  DocumentUrl?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use name instead */
  FileName?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use taxesIds instead */
  TaxIds: Array<Scalars['String']['output']>;
  category?: Maybe<InvestorDocumentCategory>;
  date: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  taxIDs: Array<Scalars['String']['output']>;
  type: InvestorDocumentType;
  url?: Maybe<Scalars['String']['output']>;
  userAccount?: Maybe<UserAccount>;
};

export enum InvestorDocumentCategory {
  Statement = 'STATEMENT',
  TaxForm = 'TAX_FORM',
  TradeConfirmation = 'TRADE_CONFIRMATION',
}

export type InvestorDocumentOrder = {
  direction?: OrderDirection;
  field: InvestorDocumentOrderField;
};

export enum InvestorDocumentOrderField {
  Date = 'date',
}

export enum InvestorDocumentType {
  BirthCertificate = 'BIRTH_CERTIFICATE',
  Consolidated_1099 = 'CONSOLIDATED_1099',
  CustomerIdentificationProcess = 'CUSTOMER_IDENTIFICATION_PROCESS',
  DailyConfirm = 'DAILY_CONFIRM',
  DeathCertificate = 'DEATH_CERTIFICATE',
  DrivingLicense = 'DRIVING_LICENSE',
  EmployerConsentForm_407Letter = 'EMPLOYER_CONSENT_FORM_407_LETTER',
  ExecutorCertification = 'EXECUTOR_CERTIFICATION',
  Form_480_6A = 'FORM_480_6A',
  Form_480_6B = 'FORM_480_6B',
  Form_1042S = 'FORM_1042_S',
  Form_1099B = 'FORM_1099_B',
  Form_1099Div = 'FORM_1099_DIV',
  Form_1099Int = 'FORM_1099_INT',
  Form_1099Misc = 'FORM_1099_MISC',
  Form_1099Q = 'FORM_1099_Q',
  Form_1099R = 'FORM_1099_R',
  Form_1099Remic = 'FORM_1099_REMIC',
  Form_2439 = 'FORM_2439',
  Form_5498 = 'FORM_5498',
  IrisItinLetter = 'IRIS_ITIN_LETTER',
  LeaseAgreement = 'LEASE_AGREEMENT',
  MarriageCertificate = 'MARRIAGE_CERTIFICATE',
  MilitaryIdCard = 'MILITARY_ID_CARD',
  MonthlyConfirm = 'MONTHLY_CONFIRM',
  MortgageStatement = 'MORTGAGE_STATEMENT',
  NationalIdCard = 'NATIONAL_ID_CARD',
  Passport = 'PASSPORT',
  PermanentResidentVisa = 'PERMANENT_RESIDENT_VISA',
  ProofOfTaxId = 'PROOF_OF_TAX_ID',
  SocialSecurityDocument = 'SOCIAL_SECURITY_DOCUMENT',
  Statement = 'STATEMENT',
  StateIdCard = 'STATE_ID_CARD',
  ThirdPartyCipResults = 'THIRD_PARTY_CIP_RESULTS',
  TrusteeCertification = 'TRUSTEE_CERTIFICATION',
  UtilityBill = 'UTILITY_BILL',
}

export type InvestorDocumentWhereInput = {
  documentCategory?: InputMaybe<InvestorDocumentCategory>;
  /**
   * ID of the document to query
   * @deprecated Use type instead
   */
  documentType?: InputMaybe<InvestorDocumentType>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  type?: InputMaybe<InvestorDocumentType>;
  /** ID of the account to get documents for */
  userAccountID: Scalars['ID']['input'];
};

export type IraDepositSlip = {
  __typename?: 'IraDepositSlip';
  documentID?: Maybe<Scalars['String']['output']>;
};

export type IrsItinLetter = {
  __typename?: 'IrsItinLetter';
  documentID?: Maybe<Scalars['String']['output']>;
};

export type Liability = Node & {
  __typename?: 'Liability';
  /** The account that is responsible for the liability */
  account?: Maybe<UserAccount>;
  /** The amount of the liability that was owed at the time of creation. */
  amountOwed: Scalars['Decimal']['output'];
  /** The amount of the liability that has been paid. */
  amountPaid: Scalars['Decimal']['output'];
  /** The amount of the liability that remains to be paid. */
  amountRemaining: Scalars['Decimal']['output'];
  createTime: Scalars['Time']['output'];
  /** The institution that is owed the liability */
  creditor: Institution;
  /** The time that the liability is due if not due immediately. */
  dueDate?: Maybe<Scalars['Time']['output']>;
  id: Scalars['ID']['output'];
  isSatisfied: Scalars['Boolean']['output'];
  /** Indicates that the value of the liability was transferred out of the account automatically from an external process. */
  isSwept: Scalars['Boolean']['output'];
  /** Additional notes about the liability for internal use. */
  notes?: Maybe<Scalars['String']['output']>;
  /** link to all orders involved in satisfying this liability */
  orders?: Maybe<Array<Order>>;
  /** The time that the liabiltitiy has been owed since. */
  owedSince: Scalars['Time']['output'];
  /** The portfolio that is responsible for the liability */
  portfolio?: Maybe<UserAccountPortfolio>;
  /** The reason for the liability */
  reason: Scalars['String']['output'];
  /** The time that the liability was satisfied. */
  satisfiedAt?: Maybe<Scalars['Time']['output']>;
  updateTime: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type LiabilityConnection = {
  __typename?: 'LiabilityConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LiabilityEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LiabilityEdge = {
  __typename?: 'LiabilityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Liability>;
};

/** Ordering options for Liability connections */
export type LiabilityOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Liabilities. */
  field: LiabilityOrderField;
};

/** Properties by which Liability connections can be ordered. */
export enum LiabilityOrderField {
  AmountOwed = 'amountOwed',
  AmountRemaining = 'amountRemaining',
  CreateTime = 'create_time',
  DueDate = 'dueDate',
  OwedSince = 'owedSince',
  SatisfiedAt = 'satisfiedAt',
  UpdateTime = 'update_time',
}

/**
 * LiabilityWhereInput is used for filtering Liability objects.
 * Input was generated by ent.
 */
export type LiabilityWhereInput = {
  /** amount_owed field predicates */
  amountOwed?: InputMaybe<Scalars['Decimal']['input']>;
  amountOwedGT?: InputMaybe<Scalars['Decimal']['input']>;
  amountOwedGTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountOwedIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  amountOwedLT?: InputMaybe<Scalars['Decimal']['input']>;
  amountOwedLTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountOwedNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  amountOwedNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** amount_paid field predicates */
  amountPaid?: InputMaybe<Scalars['Decimal']['input']>;
  amountPaidGT?: InputMaybe<Scalars['Decimal']['input']>;
  amountPaidGTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountPaidIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  amountPaidLT?: InputMaybe<Scalars['Decimal']['input']>;
  amountPaidLTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountPaidNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  amountPaidNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** amount_remaining field predicates */
  amountRemaining?: InputMaybe<Scalars['Decimal']['input']>;
  amountRemainingGT?: InputMaybe<Scalars['Decimal']['input']>;
  amountRemainingGTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountRemainingIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  amountRemainingLT?: InputMaybe<Scalars['Decimal']['input']>;
  amountRemainingLTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountRemainingNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  amountRemainingNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  and?: InputMaybe<Array<LiabilityWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** due_date field predicates */
  dueDate?: InputMaybe<Scalars['Time']['input']>;
  dueDateGT?: InputMaybe<Scalars['Time']['input']>;
  dueDateGTE?: InputMaybe<Scalars['Time']['input']>;
  dueDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  dueDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  dueDateLT?: InputMaybe<Scalars['Time']['input']>;
  dueDateLTE?: InputMaybe<Scalars['Time']['input']>;
  dueDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  dueDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  dueDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** account edge predicates */
  hasAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountWith?: InputMaybe<Array<UserAccountWhereInput>>;
  /** creditor edge predicates */
  hasCreditor?: InputMaybe<Scalars['Boolean']['input']>;
  hasCreditorWith?: InputMaybe<Array<InstitutionWhereInput>>;
  /** orders edge predicates */
  hasOrders?: InputMaybe<Scalars['Boolean']['input']>;
  hasOrdersWith?: InputMaybe<Array<OrderWhereInput>>;
  /** portfolio edge predicates */
  hasPortfolio?: InputMaybe<Scalars['Boolean']['input']>;
  hasPortfolioWith?: InputMaybe<Array<UserAccountPortfolioWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_satisfied field predicates */
  isSatisfied?: InputMaybe<Scalars['Boolean']['input']>;
  isSatisfiedNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** is_swept field predicates */
  isSwept?: InputMaybe<Scalars['Boolean']['input']>;
  isSweptNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<LiabilityWhereInput>;
  or?: InputMaybe<Array<LiabilityWhereInput>>;
  /** owed_since field predicates */
  owedSince?: InputMaybe<Scalars['Time']['input']>;
  owedSinceGT?: InputMaybe<Scalars['Time']['input']>;
  owedSinceGTE?: InputMaybe<Scalars['Time']['input']>;
  owedSinceIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  owedSinceLT?: InputMaybe<Scalars['Time']['input']>;
  owedSinceLTE?: InputMaybe<Scalars['Time']['input']>;
  owedSinceNEQ?: InputMaybe<Scalars['Time']['input']>;
  owedSinceNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** reason field predicates */
  reason?: InputMaybe<Scalars['String']['input']>;
  reasonContains?: InputMaybe<Scalars['String']['input']>;
  reasonContainsFold?: InputMaybe<Scalars['String']['input']>;
  reasonEqualFold?: InputMaybe<Scalars['String']['input']>;
  reasonGT?: InputMaybe<Scalars['String']['input']>;
  reasonGTE?: InputMaybe<Scalars['String']['input']>;
  reasonHasPrefix?: InputMaybe<Scalars['String']['input']>;
  reasonHasSuffix?: InputMaybe<Scalars['String']['input']>;
  reasonIn?: InputMaybe<Array<Scalars['String']['input']>>;
  reasonLT?: InputMaybe<Scalars['String']['input']>;
  reasonLTE?: InputMaybe<Scalars['String']['input']>;
  reasonNEQ?: InputMaybe<Scalars['String']['input']>;
  reasonNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** satisfied_at field predicates */
  satisfiedAt?: InputMaybe<Scalars['Time']['input']>;
  satisfiedAtGT?: InputMaybe<Scalars['Time']['input']>;
  satisfiedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  satisfiedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  satisfiedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  satisfiedAtLT?: InputMaybe<Scalars['Time']['input']>;
  satisfiedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  satisfiedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  satisfiedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  satisfiedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type License = {
  __typename?: 'License';
  back?: Maybe<Scalars['String']['output']>;
  front?: Maybe<Scalars['String']['output']>;
};

export type LimitedMarginAgreement = {
  __typename?: 'LimitedMarginAgreement';
  documentID?: Maybe<Scalars['String']['output']>;
};

export type Liquidation = BaseOrder &
  Node & {
    __typename?: 'Liquidation';
    account: UserAccount;
    /** Total cash value that is expected to be consumed via autosweep. */
    anticipatedAutosweepAmount: Scalars['Decimal']['output'];
    /** indicates the way that the request indicated which assets to liquidate. */
    assetLoadingStrategyType: LiquidationAssetLoadingStrategyType;
    createTime: Scalars['Time']['output'];
    errorReason?: Maybe<Scalars['String']['output']>;
    /** Total cash value of the liquidated assets that are expected to be liquidated, calculated from the assets. */
    expectedLiquidatedAmount: Scalars['Decimal']['output'];
    /** Total number of successful trades. */
    failedTradeCount: Scalars['Int']['output'];
    /** FailureCode is the code that indicates the reason for the failure of the liquidation. */
    failureCode?: Maybe<LiquidationLiquidationFailureCode>;
    id: Scalars['ID']['output'];
    liquidationPortfolio: UserAccountPortfolio;
    /** indicates that the liquidation still requires assets  */
    needsTrades: Scalars['Boolean']['output'];
    /** indicates that the liquidation still requires assets to be transferred */
    needsTransfers: Scalars['Boolean']['output'];
    /** Notes is the notes of the order intended for the principal. */
    note?: Maybe<Scalars['String']['output']>;
    orderStatus: OrderOrderStatus;
    orderType: OrderOrderType;
    portfolioLiquidations?: Maybe<Array<PortfolioLiquidation>>;
    /** Reason is the description of the liquidation intended for the user */
    reason: Scalars['String']['output'];
    requestDetails: RequestDetails;
    status: LiquidationOrderStatus;
    /** Total number of successful trades. */
    successfulTradeCount: Scalars['Int']['output'];
    /** Total expected cash value of the liquidated assets privided by the requestor. */
    targetAmount?: Maybe<Scalars['Decimal']['output']>;
    /** Total cash value that was included at the start of the operation. */
    totalCashIncluded: Scalars['Decimal']['output'];
    /** Total cash value of the liquidated assets that were consumed by auto-swept liabilities. */
    totalConsumedByAutoSweptLiabilities: Scalars['Decimal']['output'];
    /** Total cash value of the liquidated assets that failed to be transferred post liquidation. */
    totalErroredAmount: Scalars['Decimal']['output'];
    /** Total cash value of the liquidated assets. */
    totalLiquidatedAmount: Scalars['Decimal']['output'];
    /** Total number of issued trades. */
    totalTradeCount: Scalars['Int']['output'];
    /** Total cash value of the liquidated assets. */
    totalTradeFee: Scalars['Decimal']['output'];
    /** Total cash value of the liquidated assets that have been transferred post liquidation. */
    totalTransferredAmount: Scalars['Decimal']['output'];
    trades?: Maybe<Array<Trade>>;
    updateTime: Scalars['Time']['output'];
    user: User;
  };

/** LiquidationAssetLoadingStrategyType is enum for the field asset_loading_strategy_type */
export enum LiquidationAssetLoadingStrategyType {
  ByAccount = 'BY_ACCOUNT',
  ByAsset = 'BY_ASSET',
  ByPortfolio = 'BY_PORTFOLIO',
}

/** A connection to a list of items. */
export type LiquidationConnection = {
  __typename?: 'LiquidationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LiquidationEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LiquidationEdge = {
  __typename?: 'LiquidationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Liquidation>;
};

/** LiquidationLiquidationFailureCode is enum for the field failure_code */
export enum LiquidationLiquidationFailureCode {
  LiquidationFailureCodeIncompleteLiquidation = 'LIQUIDATION_FAILURE_CODE_INCOMPLETE_LIQUIDATION',
  LiquidationFailureCodeIncompleteTransferTarget = 'LIQUIDATION_FAILURE_CODE_INCOMPLETE_TRANSFER_TARGET',
  LiquidationFailureCodeInsufficientLiquidity = 'LIQUIDATION_FAILURE_CODE_INSUFFICIENT_LIQUIDITY',
  LiquidationFailureCodeOther = 'LIQUIDATION_FAILURE_CODE_OTHER',
}

/** Ordering options for Liquidation connections */
export type LiquidationOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Liquidations. */
  field: LiquidationOrderField;
};

/** Properties by which Liquidation connections can be ordered. */
export enum LiquidationOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/** LiquidationOrderStatus is enum for the field status */
export enum LiquidationOrderStatus {
  OrderStatusCanceled = 'ORDER_STATUS_CANCELED',
  OrderStatusCanceling = 'ORDER_STATUS_CANCELING',
  OrderStatusDone = 'ORDER_STATUS_DONE',
  OrderStatusFailed = 'ORDER_STATUS_FAILED',
  OrderStatusFinalizing = 'ORDER_STATUS_FINALIZING',
  OrderStatusProcessing = 'ORDER_STATUS_PROCESSING',
}

/**
 * LiquidationWhereInput is used for filtering Liquidation objects.
 * Input was generated by ent.
 */
export type LiquidationWhereInput = {
  and?: InputMaybe<Array<LiquidationWhereInput>>;
  /** anticipated_autosweep_amount field predicates */
  anticipatedAutosweepAmount?: InputMaybe<Scalars['Decimal']['input']>;
  anticipatedAutosweepAmountGT?: InputMaybe<Scalars['Decimal']['input']>;
  anticipatedAutosweepAmountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  anticipatedAutosweepAmountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  anticipatedAutosweepAmountLT?: InputMaybe<Scalars['Decimal']['input']>;
  anticipatedAutosweepAmountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  anticipatedAutosweepAmountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  anticipatedAutosweepAmountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** asset_loading_strategy_type field predicates */
  assetLoadingStrategyType?: InputMaybe<LiquidationAssetLoadingStrategyType>;
  assetLoadingStrategyTypeIn?: InputMaybe<Array<LiquidationAssetLoadingStrategyType>>;
  assetLoadingStrategyTypeNEQ?: InputMaybe<LiquidationAssetLoadingStrategyType>;
  assetLoadingStrategyTypeNotIn?: InputMaybe<Array<LiquidationAssetLoadingStrategyType>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** error_reason field predicates */
  errorReason?: InputMaybe<Scalars['String']['input']>;
  errorReasonContains?: InputMaybe<Scalars['String']['input']>;
  errorReasonContainsFold?: InputMaybe<Scalars['String']['input']>;
  errorReasonEqualFold?: InputMaybe<Scalars['String']['input']>;
  errorReasonGT?: InputMaybe<Scalars['String']['input']>;
  errorReasonGTE?: InputMaybe<Scalars['String']['input']>;
  errorReasonHasPrefix?: InputMaybe<Scalars['String']['input']>;
  errorReasonHasSuffix?: InputMaybe<Scalars['String']['input']>;
  errorReasonIn?: InputMaybe<Array<Scalars['String']['input']>>;
  errorReasonIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  errorReasonLT?: InputMaybe<Scalars['String']['input']>;
  errorReasonLTE?: InputMaybe<Scalars['String']['input']>;
  errorReasonNEQ?: InputMaybe<Scalars['String']['input']>;
  errorReasonNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  errorReasonNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** expected_liquidated_amount field predicates */
  expectedLiquidatedAmount?: InputMaybe<Scalars['Decimal']['input']>;
  expectedLiquidatedAmountGT?: InputMaybe<Scalars['Decimal']['input']>;
  expectedLiquidatedAmountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  expectedLiquidatedAmountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  expectedLiquidatedAmountLT?: InputMaybe<Scalars['Decimal']['input']>;
  expectedLiquidatedAmountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  expectedLiquidatedAmountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  expectedLiquidatedAmountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** failed_trade_count field predicates */
  failedTradeCount?: InputMaybe<Scalars['Int']['input']>;
  failedTradeCountGT?: InputMaybe<Scalars['Int']['input']>;
  failedTradeCountGTE?: InputMaybe<Scalars['Int']['input']>;
  failedTradeCountIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  failedTradeCountLT?: InputMaybe<Scalars['Int']['input']>;
  failedTradeCountLTE?: InputMaybe<Scalars['Int']['input']>;
  failedTradeCountNEQ?: InputMaybe<Scalars['Int']['input']>;
  failedTradeCountNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** failure_code field predicates */
  failureCode?: InputMaybe<LiquidationLiquidationFailureCode>;
  failureCodeIn?: InputMaybe<Array<LiquidationLiquidationFailureCode>>;
  failureCodeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  failureCodeNEQ?: InputMaybe<LiquidationLiquidationFailureCode>;
  failureCodeNotIn?: InputMaybe<Array<LiquidationLiquidationFailureCode>>;
  failureCodeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** account edge predicates */
  hasAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountWith?: InputMaybe<Array<UserAccountWhereInput>>;
  /** liquidation_portfolio edge predicates */
  hasLiquidationPortfolio?: InputMaybe<Scalars['Boolean']['input']>;
  hasLiquidationPortfolioWith?: InputMaybe<Array<UserAccountPortfolioWhereInput>>;
  /** portfolio_liquidations edge predicates */
  hasPortfolioLiquidations?: InputMaybe<Scalars['Boolean']['input']>;
  hasPortfolioLiquidationsWith?: InputMaybe<Array<PortfolioLiquidationWhereInput>>;
  /** trades edge predicates */
  hasTrades?: InputMaybe<Scalars['Boolean']['input']>;
  hasTradesWith?: InputMaybe<Array<TradeWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** needs_trades field predicates */
  needsTrades?: InputMaybe<Scalars['Boolean']['input']>;
  needsTradesNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** needs_transfers field predicates */
  needsTransfers?: InputMaybe<Scalars['Boolean']['input']>;
  needsTransfersNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<LiquidationWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<LiquidationWhereInput>>;
  /** reason field predicates */
  reason?: InputMaybe<Scalars['String']['input']>;
  reasonContains?: InputMaybe<Scalars['String']['input']>;
  reasonContainsFold?: InputMaybe<Scalars['String']['input']>;
  reasonEqualFold?: InputMaybe<Scalars['String']['input']>;
  reasonGT?: InputMaybe<Scalars['String']['input']>;
  reasonGTE?: InputMaybe<Scalars['String']['input']>;
  reasonHasPrefix?: InputMaybe<Scalars['String']['input']>;
  reasonHasSuffix?: InputMaybe<Scalars['String']['input']>;
  reasonIn?: InputMaybe<Array<Scalars['String']['input']>>;
  reasonLT?: InputMaybe<Scalars['String']['input']>;
  reasonLTE?: InputMaybe<Scalars['String']['input']>;
  reasonNEQ?: InputMaybe<Scalars['String']['input']>;
  reasonNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** status field predicates */
  status?: InputMaybe<LiquidationOrderStatus>;
  statusIn?: InputMaybe<Array<LiquidationOrderStatus>>;
  statusNEQ?: InputMaybe<LiquidationOrderStatus>;
  statusNotIn?: InputMaybe<Array<LiquidationOrderStatus>>;
  /** successful_trade_count field predicates */
  successfulTradeCount?: InputMaybe<Scalars['Int']['input']>;
  successfulTradeCountGT?: InputMaybe<Scalars['Int']['input']>;
  successfulTradeCountGTE?: InputMaybe<Scalars['Int']['input']>;
  successfulTradeCountIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  successfulTradeCountLT?: InputMaybe<Scalars['Int']['input']>;
  successfulTradeCountLTE?: InputMaybe<Scalars['Int']['input']>;
  successfulTradeCountNEQ?: InputMaybe<Scalars['Int']['input']>;
  successfulTradeCountNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** target_amount field predicates */
  targetAmount?: InputMaybe<Scalars['Decimal']['input']>;
  targetAmountGT?: InputMaybe<Scalars['Decimal']['input']>;
  targetAmountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  targetAmountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  targetAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  targetAmountLT?: InputMaybe<Scalars['Decimal']['input']>;
  targetAmountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  targetAmountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  targetAmountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  targetAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** total_cash_included field predicates */
  totalCashIncluded?: InputMaybe<Scalars['Decimal']['input']>;
  totalCashIncludedGT?: InputMaybe<Scalars['Decimal']['input']>;
  totalCashIncludedGTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalCashIncludedIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  totalCashIncludedLT?: InputMaybe<Scalars['Decimal']['input']>;
  totalCashIncludedLTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalCashIncludedNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  totalCashIncludedNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** total_consumed_by_auto_swept_liabilities field predicates */
  totalConsumedByAutoSweptLiabilities?: InputMaybe<Scalars['Decimal']['input']>;
  totalConsumedByAutoSweptLiabilitiesGT?: InputMaybe<Scalars['Decimal']['input']>;
  totalConsumedByAutoSweptLiabilitiesGTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalConsumedByAutoSweptLiabilitiesIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  totalConsumedByAutoSweptLiabilitiesLT?: InputMaybe<Scalars['Decimal']['input']>;
  totalConsumedByAutoSweptLiabilitiesLTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalConsumedByAutoSweptLiabilitiesNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  totalConsumedByAutoSweptLiabilitiesNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** total_errored_amount field predicates */
  totalErroredAmount?: InputMaybe<Scalars['Decimal']['input']>;
  totalErroredAmountGT?: InputMaybe<Scalars['Decimal']['input']>;
  totalErroredAmountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalErroredAmountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  totalErroredAmountLT?: InputMaybe<Scalars['Decimal']['input']>;
  totalErroredAmountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalErroredAmountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  totalErroredAmountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** total_liquidated_amount field predicates */
  totalLiquidatedAmount?: InputMaybe<Scalars['Decimal']['input']>;
  totalLiquidatedAmountGT?: InputMaybe<Scalars['Decimal']['input']>;
  totalLiquidatedAmountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalLiquidatedAmountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  totalLiquidatedAmountLT?: InputMaybe<Scalars['Decimal']['input']>;
  totalLiquidatedAmountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalLiquidatedAmountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  totalLiquidatedAmountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** total_trade_count field predicates */
  totalTradeCount?: InputMaybe<Scalars['Int']['input']>;
  totalTradeCountGT?: InputMaybe<Scalars['Int']['input']>;
  totalTradeCountGTE?: InputMaybe<Scalars['Int']['input']>;
  totalTradeCountIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalTradeCountLT?: InputMaybe<Scalars['Int']['input']>;
  totalTradeCountLTE?: InputMaybe<Scalars['Int']['input']>;
  totalTradeCountNEQ?: InputMaybe<Scalars['Int']['input']>;
  totalTradeCountNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** total_trade_fee field predicates */
  totalTradeFee?: InputMaybe<Scalars['Decimal']['input']>;
  totalTradeFeeGT?: InputMaybe<Scalars['Decimal']['input']>;
  totalTradeFeeGTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalTradeFeeIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  totalTradeFeeLT?: InputMaybe<Scalars['Decimal']['input']>;
  totalTradeFeeLTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalTradeFeeNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  totalTradeFeeNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** total_transferred_amount field predicates */
  totalTransferredAmount?: InputMaybe<Scalars['Decimal']['input']>;
  totalTransferredAmountGT?: InputMaybe<Scalars['Decimal']['input']>;
  totalTransferredAmountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalTransferredAmountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  totalTransferredAmountLT?: InputMaybe<Scalars['Decimal']['input']>;
  totalTransferredAmountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalTransferredAmountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  totalTransferredAmountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** MarketCalendarType is the status of a market for a given date */
export enum MarketCalendarType {
  MarketTypeBanking = 'MARKET_TYPE_BANKING',
  MarketTypeSecurities = 'MARKET_TYPE_SECURITIES',
}

export type MarketDataAnswers = Node & {
  __typename?: 'MarketDataAnswers';
  /** Whether the user is an advisor or consultant */
  advisorOrConsultant: Scalars['Boolean']['output'];
  /** Whether the user receives stock market data from an external source */
  gettingDataFromExternalSource: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** Whether the user invests with someone else's money */
  investForOther: Scalars['Boolean']['output'];
  /** Whether the user invests for the benefit of another person */
  investForTheBenefitOfOther: Scalars['Boolean']['output'];
  /** Whether the user is using the market data for personal use */
  personalUse: Scalars['Boolean']['output'];
  /** Whether the user is registered with a security entity, exchange or commodity/future entity */
  registeredWithEntity: Scalars['Boolean']['output'];
  /** Whether the user is registered with SEC or CFTC */
  registeredWithSecOrCftc: Scalars['Boolean']['output'];
  /** Whether the user requires SEC or CFTC registration */
  requiresSecOrCftcRegistration: Scalars['Boolean']['output'];
  /** Whether the user shares in the profits with someone else */
  sharesProfit: Scalars['Boolean']['output'];
  userProfile?: Maybe<UserProfile>;
};

/**
 * MarketDataAnswersWhereInput is used for filtering MarketDataAnswers objects.
 * Input was generated by ent.
 */
export type MarketDataAnswersWhereInput = {
  /** advisor_or_consultant field predicates */
  advisorOrConsultant?: InputMaybe<Scalars['Boolean']['input']>;
  advisorOrConsultantNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<MarketDataAnswersWhereInput>>;
  /** getting_data_from_external_source field predicates */
  gettingDataFromExternalSource?: InputMaybe<Scalars['Boolean']['input']>;
  gettingDataFromExternalSourceNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** user_profile edge predicates */
  hasUserProfile?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserProfileWith?: InputMaybe<Array<UserProfileWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** invest_for_other field predicates */
  investForOther?: InputMaybe<Scalars['Boolean']['input']>;
  investForOtherNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** invest_for_the_benefit_of_other field predicates */
  investForTheBenefitOfOther?: InputMaybe<Scalars['Boolean']['input']>;
  investForTheBenefitOfOtherNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<MarketDataAnswersWhereInput>;
  or?: InputMaybe<Array<MarketDataAnswersWhereInput>>;
  /** personal_use field predicates */
  personalUse?: InputMaybe<Scalars['Boolean']['input']>;
  personalUseNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** registered_with_entity field predicates */
  registeredWithEntity?: InputMaybe<Scalars['Boolean']['input']>;
  registeredWithEntityNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** registered_with_sec_or_cftc field predicates */
  registeredWithSecOrCftc?: InputMaybe<Scalars['Boolean']['input']>;
  registeredWithSecOrCftcNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** requires_sec_or_cftc_registration field predicates */
  requiresSecOrCftcRegistration?: InputMaybe<Scalars['Boolean']['input']>;
  requiresSecOrCftcRegistrationNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** shares_profit field predicates */
  sharesProfit?: InputMaybe<Scalars['Boolean']['input']>;
  sharesProfitNEQ?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MarketHours = {
  __typename?: 'MarketHours';
  /**
   * next returns the next nth open market day for the market provided.
   * If date is not provided, the current date (in Eastern Time) is used.
   * If market is not provided, all markets are returned.
   * Count must be >0
   */
  next: MarketHoursStatus;
  /**
   * previous returns the previous nth open market day for the market provided.
   * If date is not provided, the current date (in Eastern Time) is used.
   * If market is not provided, all markets are returned.
   * Count must be >0
   */
  previous: MarketHoursStatus;
  /**
   * status returns the market status for the date provided.
   * If date is not provided, the current date (in Eastern Time) is used.
   * If market is not provided, all markets are returned.
   */
  status: MarketHoursStatus;
};

export type MarketHoursNextArgs = {
  calendar: MarketCalendarType;
  count: Scalars['Int']['input'];
  date?: InputMaybe<Scalars['Date']['input']>;
};

export type MarketHoursPreviousArgs = {
  calendar: MarketCalendarType;
  count: Scalars['Int']['input'];
  date?: InputMaybe<Scalars['Date']['input']>;
};

export type MarketHoursStatusArgs = {
  calendar: MarketCalendarType;
  date?: InputMaybe<Scalars['Date']['input']>;
};

export type MarketHoursAdditional = {
  __typename?: 'MarketHoursAdditional';
  end: Scalars['Time']['output'];
  start: Scalars['Time']['output'];
  status: MarketHoursSpecialHourStatus;
};

export enum MarketHoursSpecialHourStatus {
  SpecialHoursStatusAfterMarket = 'SPECIAL_HOURS_STATUS_AFTER_MARKET',
  SpecialHoursStatusMaintenance = 'SPECIAL_HOURS_STATUS_MAINTENANCE',
  SpecialHoursStatusPreMarket = 'SPECIAL_HOURS_STATUS_PRE_MARKET',
}

export type MarketHoursStatus = {
  __typename?: 'MarketHoursStatus';
  /**
   * additional contains additional information about the market status, such as temporary closures, pre-market hours,
   * and maintenance windows.
   */
  additional?: Maybe<Array<MarketHoursAdditional>>;
  /** calendar is the type of market this status applies to */
  calendar: MarketCalendarType;
  /** close is the time the market closes. If the market does not open on this date, this field is null */
  close?: Maybe<Scalars['Time']['output']>;
  /** date is the date of the market status */
  date: Scalars['Date']['output'];
  /** open is the time the market opens. If the market does not open on this date, this field is null */
  open?: Maybe<Scalars['Time']['output']>;
  /** status is the market status for the date */
  status: MarketStatus;
};

/** MarketStatus is the status of a market for a given date */
export enum MarketStatus {
  /** MARKET_STATUS_CLOSED indicates the market is closed for any reason. */
  MarketStatusClosed = 'MARKET_STATUS_CLOSED',
  /** MARKET_STATUS_MODIFIED indicates the market is open but operating on atypical hours. */
  MarketStatusModified = 'MARKET_STATUS_MODIFIED',
  /** MARKET_STATUS_OPEN indicates the market is open during the typical hours for that market. */
  MarketStatusOpen = 'MARKET_STATUS_OPEN',
}

export type MilitaryId = {
  __typename?: 'MilitaryId';
  back?: Maybe<Scalars['String']['output']>;
  front?: Maybe<Scalars['String']['output']>;
};

export type ModelPortfolio = Node & {
  __typename?: 'ModelPortfolio';
  /** link to the assets that are contained in the portfolio. */
  assets?: Maybe<Array<ModelPortfolioAssetComposition>>;
  /** Contains a list of available substitutions for the assets in the portfolio. */
  availableSubstitutions?: Maybe<Array<ModelPortfolioAssetSubstitution>>;
  /** Percentage of the portfolio in bonds. */
  bondPct: Scalars['Decimal']['output'];
  createTime: Scalars['Time']['output'];
  /** ID used by the external provider */
  externalReferenceID?: Maybe<Scalars['String']['output']>;
  /** Percentage of the portfolio in fiat currency. */
  fiatPct: Scalars['Decimal']['output'];
  id: Scalars['ID']['output'];
  /** Institution that designed and manages the portfolio. */
  provider: Institution;
  /** link to the sectors that are contained in the portfolio. */
  sectors?: Maybe<Array<ModelPortfolioSectorComposition>>;
  /** Percentage of the portfolio in stocks. */
  stockPct: Scalars['Decimal']['output'];
  updateTime: Scalars['Time']['output'];
};

export type ModelPortfolioAssetComposition = Node & {
  __typename?: 'ModelPortfolioAssetComposition';
  asset: BaseAsset;
  createTime: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  /** target percentage of the asset relative to the owning body. i.e. if part of a ModelPortfolio then it is the overall percentage in the portfolio vs if it's in a sector than it is the percentage of the sector which is probably not the entire portfolio. */
  targetPct: Scalars['Decimal']['output'];
  updateTime: Scalars['Time']['output'];
};

/** Ordering options for ModelPortfolioAssetComposition connections */
export type ModelPortfolioAssetCompositionOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ModelPortfolioAssetCompositions. */
  field: ModelPortfolioAssetCompositionOrderField;
};

/** Properties by which ModelPortfolioAssetComposition connections can be ordered. */
export enum ModelPortfolioAssetCompositionOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * ModelPortfolioAssetCompositionWhereInput is used for filtering ModelPortfolioAssetComposition objects.
 * Input was generated by ent.
 */
export type ModelPortfolioAssetCompositionWhereInput = {
  and?: InputMaybe<Array<ModelPortfolioAssetCompositionWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<ModelPortfolioAssetCompositionWhereInput>;
  or?: InputMaybe<Array<ModelPortfolioAssetCompositionWhereInput>>;
  /** target_pct field predicates */
  targetPct?: InputMaybe<Scalars['Decimal']['input']>;
  targetPctGT?: InputMaybe<Scalars['Decimal']['input']>;
  targetPctGTE?: InputMaybe<Scalars['Decimal']['input']>;
  targetPctIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  targetPctLT?: InputMaybe<Scalars['Decimal']['input']>;
  targetPctLTE?: InputMaybe<Scalars['Decimal']['input']>;
  targetPctNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  targetPctNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type ModelPortfolioAssetSubstitution = Node & {
  __typename?: 'ModelPortfolioAssetSubstitution';
  createTime: Scalars['Time']['output'];
  /** asset to be replaced. */
  default: BaseAsset;
  id: Scalars['ID']['output'];
  roboGeneralInvestment?: Maybe<Array<RoboGeneralInvestment>>;
  roboGoalParameters?: Maybe<Array<RoboGoalParameters>>;
  /** asset to be substituted. */
  substitution: BaseAsset;
  updateTime: Scalars['Time']['output'];
};

/** Ordering options for ModelPortfolioAssetSubstitution connections */
export type ModelPortfolioAssetSubstitutionOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ModelPortfolioAssetSubstitutions. */
  field: ModelPortfolioAssetSubstitutionOrderField;
};

/** Properties by which ModelPortfolioAssetSubstitution connections can be ordered. */
export enum ModelPortfolioAssetSubstitutionOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * ModelPortfolioAssetSubstitutionWhereInput is used for filtering ModelPortfolioAssetSubstitution objects.
 * Input was generated by ent.
 */
export type ModelPortfolioAssetSubstitutionWhereInput = {
  and?: InputMaybe<Array<ModelPortfolioAssetSubstitutionWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** robo_general_investment edge predicates */
  hasRoboGeneralInvestment?: InputMaybe<Scalars['Boolean']['input']>;
  hasRoboGeneralInvestmentWith?: InputMaybe<Array<RoboGeneralInvestmentWhereInput>>;
  /** robo_goal_parameters edge predicates */
  hasRoboGoalParameters?: InputMaybe<Scalars['Boolean']['input']>;
  hasRoboGoalParametersWith?: InputMaybe<Array<RoboGoalParametersWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<ModelPortfolioAssetSubstitutionWhereInput>;
  or?: InputMaybe<Array<ModelPortfolioAssetSubstitutionWhereInput>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** A connection to a list of items. */
export type ModelPortfolioConnection = {
  __typename?: 'ModelPortfolioConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ModelPortfolioEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ModelPortfolioEdge = {
  __typename?: 'ModelPortfolioEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ModelPortfolio>;
};

/** Ordering options for ModelPortfolio connections */
export type ModelPortfolioOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ModelPortfolios. */
  field: ModelPortfolioOrderField;
};

/** Properties by which ModelPortfolio connections can be ordered. */
export enum ModelPortfolioOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

export type ModelPortfolioSector = Node & {
  __typename?: 'ModelPortfolioSector';
  assets: Array<ModelPortfolioAssetComposition>;
  /** category of the sector can be any designation, such as asset class */
  category: ModelPortfolioSectorCategory;
  createTime: Scalars['Time']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updateTime: Scalars['Time']['output'];
  /** dictate which UX users will see when they review a sector */
  uxType: ModelPortfolioSectorUxType;
};

/** ModelPortfolioSectorCategory is enum for the field category */
export enum ModelPortfolioSectorCategory {
  Bond = 'BOND',
  Fiat = 'FIAT',
  Stock = 'STOCK',
}

export type ModelPortfolioSectorComposition = Node & {
  __typename?: 'ModelPortfolioSectorComposition';
  createTime: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  sector: ModelPortfolioSector;
  /** target percentage of the sector relative to the portfolio. */
  targetPct: Scalars['Decimal']['output'];
  updateTime: Scalars['Time']['output'];
};

/** Ordering options for ModelPortfolioSectorComposition connections */
export type ModelPortfolioSectorCompositionOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ModelPortfolioSectorCompositions. */
  field: ModelPortfolioSectorCompositionOrderField;
};

/** Properties by which ModelPortfolioSectorComposition connections can be ordered. */
export enum ModelPortfolioSectorCompositionOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * ModelPortfolioSectorCompositionWhereInput is used for filtering ModelPortfolioSectorComposition objects.
 * Input was generated by ent.
 */
export type ModelPortfolioSectorCompositionWhereInput = {
  and?: InputMaybe<Array<ModelPortfolioSectorCompositionWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** sector edge predicates */
  hasSector?: InputMaybe<Scalars['Boolean']['input']>;
  hasSectorWith?: InputMaybe<Array<ModelPortfolioSectorWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<ModelPortfolioSectorCompositionWhereInput>;
  or?: InputMaybe<Array<ModelPortfolioSectorCompositionWhereInput>>;
  /** target_pct field predicates */
  targetPct?: InputMaybe<Scalars['Decimal']['input']>;
  targetPctGT?: InputMaybe<Scalars['Decimal']['input']>;
  targetPctGTE?: InputMaybe<Scalars['Decimal']['input']>;
  targetPctIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  targetPctLT?: InputMaybe<Scalars['Decimal']['input']>;
  targetPctLTE?: InputMaybe<Scalars['Decimal']['input']>;
  targetPctNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  targetPctNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** A connection to a list of items. */
export type ModelPortfolioSectorConnection = {
  __typename?: 'ModelPortfolioSectorConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ModelPortfolioSectorEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ModelPortfolioSectorEdge = {
  __typename?: 'ModelPortfolioSectorEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ModelPortfolioSector>;
};

/** Ordering options for ModelPortfolioSector connections */
export type ModelPortfolioSectorOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ModelPortfolioSectors. */
  field: ModelPortfolioSectorOrderField;
};

/** Properties by which ModelPortfolioSector connections can be ordered. */
export enum ModelPortfolioSectorOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/** ModelPortfolioSectorUxType is enum for the field ux_type */
export enum ModelPortfolioSectorUxType {
  FundView = 'FUND_VIEW',
  StockView = 'STOCK_VIEW',
}

/**
 * ModelPortfolioSectorWhereInput is used for filtering ModelPortfolioSector objects.
 * Input was generated by ent.
 */
export type ModelPortfolioSectorWhereInput = {
  and?: InputMaybe<Array<ModelPortfolioSectorWhereInput>>;
  /** category field predicates */
  category?: InputMaybe<ModelPortfolioSectorCategory>;
  categoryIn?: InputMaybe<Array<ModelPortfolioSectorCategory>>;
  categoryNEQ?: InputMaybe<ModelPortfolioSectorCategory>;
  categoryNotIn?: InputMaybe<Array<ModelPortfolioSectorCategory>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** assets edge predicates */
  hasAssets?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssetsWith?: InputMaybe<Array<ModelPortfolioAssetCompositionWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<ModelPortfolioSectorWhereInput>;
  or?: InputMaybe<Array<ModelPortfolioSectorWhereInput>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** ux_type field predicates */
  uxType?: InputMaybe<ModelPortfolioSectorUxType>;
  uxTypeIn?: InputMaybe<Array<ModelPortfolioSectorUxType>>;
  uxTypeNEQ?: InputMaybe<ModelPortfolioSectorUxType>;
  uxTypeNotIn?: InputMaybe<Array<ModelPortfolioSectorUxType>>;
};

/** ModelPortfolioSubstitution is a list of assets that can be substituted for the default assets in a goal portfolio. */
export type ModelPortfolioSubstitution = {
  __typename?: 'ModelPortfolioSubstitution';
  /** default is the default portfolion for allocation in a robo investment. */
  default: SecurityAsset;
  /**
   * replacements is the list of available replacements for the
   * default SecurityAsset.
   */
  replacements: Array<SecurityAsset>;
};

/**
 * ModelPortfolioWhereInput is used for filtering ModelPortfolio objects.
 * Input was generated by ent.
 */
export type ModelPortfolioWhereInput = {
  and?: InputMaybe<Array<ModelPortfolioWhereInput>>;
  /** bond_pct field predicates */
  bondPct?: InputMaybe<Scalars['Decimal']['input']>;
  bondPctGT?: InputMaybe<Scalars['Decimal']['input']>;
  bondPctGTE?: InputMaybe<Scalars['Decimal']['input']>;
  bondPctIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  bondPctLT?: InputMaybe<Scalars['Decimal']['input']>;
  bondPctLTE?: InputMaybe<Scalars['Decimal']['input']>;
  bondPctNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  bondPctNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** external_reference_id field predicates */
  externalReferenceID?: InputMaybe<Scalars['String']['input']>;
  externalReferenceIDContains?: InputMaybe<Scalars['String']['input']>;
  externalReferenceIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  externalReferenceIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  externalReferenceIDGT?: InputMaybe<Scalars['String']['input']>;
  externalReferenceIDGTE?: InputMaybe<Scalars['String']['input']>;
  externalReferenceIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  externalReferenceIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  externalReferenceIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalReferenceIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  externalReferenceIDLT?: InputMaybe<Scalars['String']['input']>;
  externalReferenceIDLTE?: InputMaybe<Scalars['String']['input']>;
  externalReferenceIDNEQ?: InputMaybe<Scalars['String']['input']>;
  externalReferenceIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalReferenceIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** fiat_pct field predicates */
  fiatPct?: InputMaybe<Scalars['Decimal']['input']>;
  fiatPctGT?: InputMaybe<Scalars['Decimal']['input']>;
  fiatPctGTE?: InputMaybe<Scalars['Decimal']['input']>;
  fiatPctIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  fiatPctLT?: InputMaybe<Scalars['Decimal']['input']>;
  fiatPctLTE?: InputMaybe<Scalars['Decimal']['input']>;
  fiatPctNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  fiatPctNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** assets edge predicates */
  hasAssets?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssetsWith?: InputMaybe<Array<ModelPortfolioAssetCompositionWhereInput>>;
  /** available_substitutions edge predicates */
  hasAvailableSubstitutions?: InputMaybe<Scalars['Boolean']['input']>;
  hasAvailableSubstitutionsWith?: InputMaybe<Array<ModelPortfolioAssetSubstitutionWhereInput>>;
  /** provider edge predicates */
  hasProvider?: InputMaybe<Scalars['Boolean']['input']>;
  hasProviderWith?: InputMaybe<Array<InstitutionWhereInput>>;
  /** sectors edge predicates */
  hasSectors?: InputMaybe<Scalars['Boolean']['input']>;
  hasSectorsWith?: InputMaybe<Array<ModelPortfolioSectorCompositionWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<ModelPortfolioWhereInput>;
  or?: InputMaybe<Array<ModelPortfolioWhereInput>>;
  /** stock_pct field predicates */
  stockPct?: InputMaybe<Scalars['Decimal']['input']>;
  stockPctGT?: InputMaybe<Scalars['Decimal']['input']>;
  stockPctGTE?: InputMaybe<Scalars['Decimal']['input']>;
  stockPctIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  stockPctLT?: InputMaybe<Scalars['Decimal']['input']>;
  stockPctLTE?: InputMaybe<Scalars['Decimal']['input']>;
  stockPctNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  stockPctNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type MutualFundDetails = Node & {
  __typename?: 'MutualFundDetails';
  additionalGroupRetirementInvestmentMinimum: Scalars['Decimal']['output'];
  additionalInvestmentMinimum: Scalars['Decimal']['output'];
  additionalIraInvestmentMinimum: Scalars['Decimal']['output'];
  annualizedStdDev: Scalars['Decimal']['output'];
  /** dated */
  contractualExpenseWaiver: Scalars['Decimal']['output'];
  /** 12b-1 */
  distributionServiceFee: Scalars['Decimal']['output'];
  expenseRatioGross: Scalars['Decimal']['output'];
  expenseRatioNet: Scalars['Decimal']['output'];
  feeClass: MutualFundDetailsFeeClass;
  fundCategory: Scalars['String']['output'];
  /** name of the fund company */
  fundCompany: Scalars['String']['output'];
  fundInception: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  initialGroupRetirementInvestmentMinimum: Scalars['Decimal']['output'];
  initialInvestmentMinimum: Scalars['Decimal']['output'];
  initialIraInvestmentMinimum: Scalars['Decimal']['output'];
  loadPct: Scalars['Decimal']['output'];
  loadType: Scalars['String']['output'];
  mutualFund: SecurityAsset;
  mutualFundType: MutualFundDetailsMutualFundType;
  portfolioNetAssetValue: Scalars['Decimal']['output'];
  rSquared: Scalars['Decimal']['output'];
  shareClassNetAssetValue: Scalars['Decimal']['output'];
  sharpeRatio: Scalars['Decimal']['output'];
  transactionFee: Scalars['Decimal']['output'];
  turnoverRate: Scalars['Decimal']['output'];
  twelvemonthhighvalue: Scalars['Decimal']['output'];
  twelvemonthlowvalue: Scalars['Decimal']['output'];
  yearToDateReturns: Scalars['Decimal']['output'];
  yield30Day: Scalars['Decimal']['output'];
};

/** MutualFundDetailsFeeClass is enum for the field fee_class */
export enum MutualFundDetailsFeeClass {
  A = 'A',
  B = 'B',
  C = 'C',
}

/** MutualFundDetailsMutualFundType is enum for the field mutual_fund_type */
export enum MutualFundDetailsMutualFundType {
  Bond = 'BOND',
  Equity = 'EQUITY',
  MoneyMarketFund = 'MONEY_MARKET_FUND',
}

/**
 * MutualFundDetailsWhereInput is used for filtering MutualFundDetails objects.
 * Input was generated by ent.
 */
export type MutualFundDetailsWhereInput = {
  /** additional_group_retirement_investment_minimum field predicates */
  additionalGroupRetirementInvestmentMinimum?: InputMaybe<Scalars['Decimal']['input']>;
  additionalGroupRetirementInvestmentMinimumGT?: InputMaybe<Scalars['Decimal']['input']>;
  additionalGroupRetirementInvestmentMinimumGTE?: InputMaybe<Scalars['Decimal']['input']>;
  additionalGroupRetirementInvestmentMinimumIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  additionalGroupRetirementInvestmentMinimumLT?: InputMaybe<Scalars['Decimal']['input']>;
  additionalGroupRetirementInvestmentMinimumLTE?: InputMaybe<Scalars['Decimal']['input']>;
  additionalGroupRetirementInvestmentMinimumNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  additionalGroupRetirementInvestmentMinimumNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** additional_investment_minimum field predicates */
  additionalInvestmentMinimum?: InputMaybe<Scalars['Decimal']['input']>;
  additionalInvestmentMinimumGT?: InputMaybe<Scalars['Decimal']['input']>;
  additionalInvestmentMinimumGTE?: InputMaybe<Scalars['Decimal']['input']>;
  additionalInvestmentMinimumIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  additionalInvestmentMinimumLT?: InputMaybe<Scalars['Decimal']['input']>;
  additionalInvestmentMinimumLTE?: InputMaybe<Scalars['Decimal']['input']>;
  additionalInvestmentMinimumNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  additionalInvestmentMinimumNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** additional_ira_investment_minimum field predicates */
  additionalIraInvestmentMinimum?: InputMaybe<Scalars['Decimal']['input']>;
  additionalIraInvestmentMinimumGT?: InputMaybe<Scalars['Decimal']['input']>;
  additionalIraInvestmentMinimumGTE?: InputMaybe<Scalars['Decimal']['input']>;
  additionalIraInvestmentMinimumIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  additionalIraInvestmentMinimumLT?: InputMaybe<Scalars['Decimal']['input']>;
  additionalIraInvestmentMinimumLTE?: InputMaybe<Scalars['Decimal']['input']>;
  additionalIraInvestmentMinimumNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  additionalIraInvestmentMinimumNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  and?: InputMaybe<Array<MutualFundDetailsWhereInput>>;
  /** annualized_std_dev field predicates */
  annualizedStdDev?: InputMaybe<Scalars['Decimal']['input']>;
  annualizedStdDevGT?: InputMaybe<Scalars['Decimal']['input']>;
  annualizedStdDevGTE?: InputMaybe<Scalars['Decimal']['input']>;
  annualizedStdDevIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  annualizedStdDevLT?: InputMaybe<Scalars['Decimal']['input']>;
  annualizedStdDevLTE?: InputMaybe<Scalars['Decimal']['input']>;
  annualizedStdDevNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  annualizedStdDevNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** contractual_expense_waiver field predicates */
  contractualExpenseWaiver?: InputMaybe<Scalars['Decimal']['input']>;
  contractualExpenseWaiverGT?: InputMaybe<Scalars['Decimal']['input']>;
  contractualExpenseWaiverGTE?: InputMaybe<Scalars['Decimal']['input']>;
  contractualExpenseWaiverIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  contractualExpenseWaiverLT?: InputMaybe<Scalars['Decimal']['input']>;
  contractualExpenseWaiverLTE?: InputMaybe<Scalars['Decimal']['input']>;
  contractualExpenseWaiverNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  contractualExpenseWaiverNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** distribution_service_fee field predicates */
  distributionServiceFee?: InputMaybe<Scalars['Decimal']['input']>;
  distributionServiceFeeGT?: InputMaybe<Scalars['Decimal']['input']>;
  distributionServiceFeeGTE?: InputMaybe<Scalars['Decimal']['input']>;
  distributionServiceFeeIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  distributionServiceFeeLT?: InputMaybe<Scalars['Decimal']['input']>;
  distributionServiceFeeLTE?: InputMaybe<Scalars['Decimal']['input']>;
  distributionServiceFeeNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  distributionServiceFeeNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** expense_ratio_gross field predicates */
  expenseRatioGross?: InputMaybe<Scalars['Decimal']['input']>;
  expenseRatioGrossGT?: InputMaybe<Scalars['Decimal']['input']>;
  expenseRatioGrossGTE?: InputMaybe<Scalars['Decimal']['input']>;
  expenseRatioGrossIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  expenseRatioGrossLT?: InputMaybe<Scalars['Decimal']['input']>;
  expenseRatioGrossLTE?: InputMaybe<Scalars['Decimal']['input']>;
  expenseRatioGrossNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  expenseRatioGrossNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** expense_ratio_net field predicates */
  expenseRatioNet?: InputMaybe<Scalars['Decimal']['input']>;
  expenseRatioNetGT?: InputMaybe<Scalars['Decimal']['input']>;
  expenseRatioNetGTE?: InputMaybe<Scalars['Decimal']['input']>;
  expenseRatioNetIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  expenseRatioNetLT?: InputMaybe<Scalars['Decimal']['input']>;
  expenseRatioNetLTE?: InputMaybe<Scalars['Decimal']['input']>;
  expenseRatioNetNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  expenseRatioNetNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** fee_class field predicates */
  feeClass?: InputMaybe<MutualFundDetailsFeeClass>;
  feeClassIn?: InputMaybe<Array<MutualFundDetailsFeeClass>>;
  feeClassNEQ?: InputMaybe<MutualFundDetailsFeeClass>;
  feeClassNotIn?: InputMaybe<Array<MutualFundDetailsFeeClass>>;
  /** fund_category field predicates */
  fundCategory?: InputMaybe<Scalars['String']['input']>;
  fundCategoryContains?: InputMaybe<Scalars['String']['input']>;
  fundCategoryContainsFold?: InputMaybe<Scalars['String']['input']>;
  fundCategoryEqualFold?: InputMaybe<Scalars['String']['input']>;
  fundCategoryGT?: InputMaybe<Scalars['String']['input']>;
  fundCategoryGTE?: InputMaybe<Scalars['String']['input']>;
  fundCategoryHasPrefix?: InputMaybe<Scalars['String']['input']>;
  fundCategoryHasSuffix?: InputMaybe<Scalars['String']['input']>;
  fundCategoryIn?: InputMaybe<Array<Scalars['String']['input']>>;
  fundCategoryLT?: InputMaybe<Scalars['String']['input']>;
  fundCategoryLTE?: InputMaybe<Scalars['String']['input']>;
  fundCategoryNEQ?: InputMaybe<Scalars['String']['input']>;
  fundCategoryNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** fund_company field predicates */
  fundCompany?: InputMaybe<Scalars['String']['input']>;
  fundCompanyContains?: InputMaybe<Scalars['String']['input']>;
  fundCompanyContainsFold?: InputMaybe<Scalars['String']['input']>;
  fundCompanyEqualFold?: InputMaybe<Scalars['String']['input']>;
  fundCompanyGT?: InputMaybe<Scalars['String']['input']>;
  fundCompanyGTE?: InputMaybe<Scalars['String']['input']>;
  fundCompanyHasPrefix?: InputMaybe<Scalars['String']['input']>;
  fundCompanyHasSuffix?: InputMaybe<Scalars['String']['input']>;
  fundCompanyIn?: InputMaybe<Array<Scalars['String']['input']>>;
  fundCompanyLT?: InputMaybe<Scalars['String']['input']>;
  fundCompanyLTE?: InputMaybe<Scalars['String']['input']>;
  fundCompanyNEQ?: InputMaybe<Scalars['String']['input']>;
  fundCompanyNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** fund_inception field predicates */
  fundInception?: InputMaybe<Scalars['Date']['input']>;
  fundInceptionGT?: InputMaybe<Scalars['Date']['input']>;
  fundInceptionGTE?: InputMaybe<Scalars['Date']['input']>;
  fundInceptionIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  fundInceptionLT?: InputMaybe<Scalars['Date']['input']>;
  fundInceptionLTE?: InputMaybe<Scalars['Date']['input']>;
  fundInceptionNEQ?: InputMaybe<Scalars['Date']['input']>;
  fundInceptionNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  /** mutual_fund edge predicates */
  hasMutualFund?: InputMaybe<Scalars['Boolean']['input']>;
  hasMutualFundWith?: InputMaybe<Array<SecurityAssetWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** initial_group_retirement_investment_minimum field predicates */
  initialGroupRetirementInvestmentMinimum?: InputMaybe<Scalars['Decimal']['input']>;
  initialGroupRetirementInvestmentMinimumGT?: InputMaybe<Scalars['Decimal']['input']>;
  initialGroupRetirementInvestmentMinimumGTE?: InputMaybe<Scalars['Decimal']['input']>;
  initialGroupRetirementInvestmentMinimumIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  initialGroupRetirementInvestmentMinimumLT?: InputMaybe<Scalars['Decimal']['input']>;
  initialGroupRetirementInvestmentMinimumLTE?: InputMaybe<Scalars['Decimal']['input']>;
  initialGroupRetirementInvestmentMinimumNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  initialGroupRetirementInvestmentMinimumNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** initial_investment_minimum field predicates */
  initialInvestmentMinimum?: InputMaybe<Scalars['Decimal']['input']>;
  initialInvestmentMinimumGT?: InputMaybe<Scalars['Decimal']['input']>;
  initialInvestmentMinimumGTE?: InputMaybe<Scalars['Decimal']['input']>;
  initialInvestmentMinimumIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  initialInvestmentMinimumLT?: InputMaybe<Scalars['Decimal']['input']>;
  initialInvestmentMinimumLTE?: InputMaybe<Scalars['Decimal']['input']>;
  initialInvestmentMinimumNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  initialInvestmentMinimumNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** initial_ira_investment_minimum field predicates */
  initialIraInvestmentMinimum?: InputMaybe<Scalars['Decimal']['input']>;
  initialIraInvestmentMinimumGT?: InputMaybe<Scalars['Decimal']['input']>;
  initialIraInvestmentMinimumGTE?: InputMaybe<Scalars['Decimal']['input']>;
  initialIraInvestmentMinimumIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  initialIraInvestmentMinimumLT?: InputMaybe<Scalars['Decimal']['input']>;
  initialIraInvestmentMinimumLTE?: InputMaybe<Scalars['Decimal']['input']>;
  initialIraInvestmentMinimumNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  initialIraInvestmentMinimumNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** load_pct field predicates */
  loadPct?: InputMaybe<Scalars['Decimal']['input']>;
  loadPctGT?: InputMaybe<Scalars['Decimal']['input']>;
  loadPctGTE?: InputMaybe<Scalars['Decimal']['input']>;
  loadPctIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  loadPctLT?: InputMaybe<Scalars['Decimal']['input']>;
  loadPctLTE?: InputMaybe<Scalars['Decimal']['input']>;
  loadPctNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  loadPctNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** load_type field predicates */
  loadType?: InputMaybe<Scalars['String']['input']>;
  loadTypeContains?: InputMaybe<Scalars['String']['input']>;
  loadTypeContainsFold?: InputMaybe<Scalars['String']['input']>;
  loadTypeEqualFold?: InputMaybe<Scalars['String']['input']>;
  loadTypeGT?: InputMaybe<Scalars['String']['input']>;
  loadTypeGTE?: InputMaybe<Scalars['String']['input']>;
  loadTypeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  loadTypeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  loadTypeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  loadTypeLT?: InputMaybe<Scalars['String']['input']>;
  loadTypeLTE?: InputMaybe<Scalars['String']['input']>;
  loadTypeNEQ?: InputMaybe<Scalars['String']['input']>;
  loadTypeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** mutual_fund_type field predicates */
  mutualFundType?: InputMaybe<MutualFundDetailsMutualFundType>;
  mutualFundTypeIn?: InputMaybe<Array<MutualFundDetailsMutualFundType>>;
  mutualFundTypeNEQ?: InputMaybe<MutualFundDetailsMutualFundType>;
  mutualFundTypeNotIn?: InputMaybe<Array<MutualFundDetailsMutualFundType>>;
  not?: InputMaybe<MutualFundDetailsWhereInput>;
  or?: InputMaybe<Array<MutualFundDetailsWhereInput>>;
  /** portfolio_net_asset_value field predicates */
  portfolioNetAssetValue?: InputMaybe<Scalars['Decimal']['input']>;
  portfolioNetAssetValueGT?: InputMaybe<Scalars['Decimal']['input']>;
  portfolioNetAssetValueGTE?: InputMaybe<Scalars['Decimal']['input']>;
  portfolioNetAssetValueIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  portfolioNetAssetValueLT?: InputMaybe<Scalars['Decimal']['input']>;
  portfolioNetAssetValueLTE?: InputMaybe<Scalars['Decimal']['input']>;
  portfolioNetAssetValueNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  portfolioNetAssetValueNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** r_squared field predicates */
  rSquared?: InputMaybe<Scalars['Decimal']['input']>;
  rSquaredGT?: InputMaybe<Scalars['Decimal']['input']>;
  rSquaredGTE?: InputMaybe<Scalars['Decimal']['input']>;
  rSquaredIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  rSquaredLT?: InputMaybe<Scalars['Decimal']['input']>;
  rSquaredLTE?: InputMaybe<Scalars['Decimal']['input']>;
  rSquaredNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  rSquaredNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** share_class_net_asset_value field predicates */
  shareClassNetAssetValue?: InputMaybe<Scalars['Decimal']['input']>;
  shareClassNetAssetValueGT?: InputMaybe<Scalars['Decimal']['input']>;
  shareClassNetAssetValueGTE?: InputMaybe<Scalars['Decimal']['input']>;
  shareClassNetAssetValueIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  shareClassNetAssetValueLT?: InputMaybe<Scalars['Decimal']['input']>;
  shareClassNetAssetValueLTE?: InputMaybe<Scalars['Decimal']['input']>;
  shareClassNetAssetValueNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  shareClassNetAssetValueNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** sharpe_ratio field predicates */
  sharpeRatio?: InputMaybe<Scalars['Decimal']['input']>;
  sharpeRatioGT?: InputMaybe<Scalars['Decimal']['input']>;
  sharpeRatioGTE?: InputMaybe<Scalars['Decimal']['input']>;
  sharpeRatioIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  sharpeRatioLT?: InputMaybe<Scalars['Decimal']['input']>;
  sharpeRatioLTE?: InputMaybe<Scalars['Decimal']['input']>;
  sharpeRatioNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  sharpeRatioNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** transaction_fee field predicates */
  transactionFee?: InputMaybe<Scalars['Decimal']['input']>;
  transactionFeeGT?: InputMaybe<Scalars['Decimal']['input']>;
  transactionFeeGTE?: InputMaybe<Scalars['Decimal']['input']>;
  transactionFeeIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  transactionFeeLT?: InputMaybe<Scalars['Decimal']['input']>;
  transactionFeeLTE?: InputMaybe<Scalars['Decimal']['input']>;
  transactionFeeNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  transactionFeeNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** turnover_rate field predicates */
  turnoverRate?: InputMaybe<Scalars['Decimal']['input']>;
  turnoverRateGT?: InputMaybe<Scalars['Decimal']['input']>;
  turnoverRateGTE?: InputMaybe<Scalars['Decimal']['input']>;
  turnoverRateIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  turnoverRateLT?: InputMaybe<Scalars['Decimal']['input']>;
  turnoverRateLTE?: InputMaybe<Scalars['Decimal']['input']>;
  turnoverRateNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  turnoverRateNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** twelveMonthHighValue field predicates */
  twelvemonthhighvalue?: InputMaybe<Scalars['Decimal']['input']>;
  twelvemonthhighvalueGT?: InputMaybe<Scalars['Decimal']['input']>;
  twelvemonthhighvalueGTE?: InputMaybe<Scalars['Decimal']['input']>;
  twelvemonthhighvalueIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  twelvemonthhighvalueLT?: InputMaybe<Scalars['Decimal']['input']>;
  twelvemonthhighvalueLTE?: InputMaybe<Scalars['Decimal']['input']>;
  twelvemonthhighvalueNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  twelvemonthhighvalueNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** twelveMonthLowValue field predicates */
  twelvemonthlowvalue?: InputMaybe<Scalars['Decimal']['input']>;
  twelvemonthlowvalueGT?: InputMaybe<Scalars['Decimal']['input']>;
  twelvemonthlowvalueGTE?: InputMaybe<Scalars['Decimal']['input']>;
  twelvemonthlowvalueIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  twelvemonthlowvalueLT?: InputMaybe<Scalars['Decimal']['input']>;
  twelvemonthlowvalueLTE?: InputMaybe<Scalars['Decimal']['input']>;
  twelvemonthlowvalueNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  twelvemonthlowvalueNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** year_to_date_returns field predicates */
  yearToDateReturns?: InputMaybe<Scalars['Decimal']['input']>;
  yearToDateReturnsGT?: InputMaybe<Scalars['Decimal']['input']>;
  yearToDateReturnsGTE?: InputMaybe<Scalars['Decimal']['input']>;
  yearToDateReturnsIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  yearToDateReturnsLT?: InputMaybe<Scalars['Decimal']['input']>;
  yearToDateReturnsLTE?: InputMaybe<Scalars['Decimal']['input']>;
  yearToDateReturnsNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  yearToDateReturnsNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** yield_30_day field predicates */
  yield30Day?: InputMaybe<Scalars['Decimal']['input']>;
  yield30DayGT?: InputMaybe<Scalars['Decimal']['input']>;
  yield30DayGTE?: InputMaybe<Scalars['Decimal']['input']>;
  yield30DayIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  yield30DayLT?: InputMaybe<Scalars['Decimal']['input']>;
  yield30DayLTE?: InputMaybe<Scalars['Decimal']['input']>;
  yield30DayNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  yield30DayNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export type MutualFundHolding = {
  __typename?: 'MutualFundHolding';
  asset: HoldingAsset;
  percentage: Scalars['Float']['output'];
};

export type NationalIdCard = {
  __typename?: 'NationalIdCard';
  back?: Maybe<Scalars['String']['output']>;
  front?: Maybe<Scalars['String']['output']>;
};

/** Last trade details of a asset */
export type NbboLastTrade = {
  __typename?: 'NbboLastTrade';
  /** Price of the last sale of the asset */
  price: Scalars['Decimal']['output'];
  /** Exchange where the last sale was made */
  securityExchange: SecurityExchange;
  /** Time of the last refresh date of the last sale */
  timestamp: Scalars['Time']['output'];
  /** Volume of the last sale of the asset */
  volume: Scalars['Decimal']['output'];
};

export type NbboQuote = {
  __typename?: 'NbboQuote';
  AskExchange: SecurityExchange;
  AskPrice: Scalars['Decimal']['output'];
  AskSize: Scalars['Decimal']['output'];
  BidExchange: SecurityExchange;
  BidPrice: Scalars['Decimal']['output'];
  BidSize: Scalars['Decimal']['output'];
  Conditions?: Maybe<Array<Scalars['Int']['output']>>;
  Indicators?: Maybe<Array<Scalars['Int']['output']>>;
  ParticipantTimestamp: Scalars['Time']['output'];
  SequenceNumber: Scalars['Int']['output'];
  SipTimestamp: Scalars['Time']['output'];
  Tape: Scalars['Int']['output'];
  TrfTimestamp?: Maybe<Scalars['Time']['output']>;
  lastTrade?: Maybe<NbboLastTrade>;
};

/**
 * An object with an ID.
 * Follows the [Relay Global Object Identification Specification](https://relay.dev/graphql/objectidentification.htm)
 */
export type Node = {
  /** The id of the object. */
  id: Scalars['ID']['output'];
};

export type NonDiscretionaryAccountKyc = Node & {
  __typename?: 'NonDiscretionaryAccountKyc';
  /** Whether to accept issuer direct communication */
  acceptIssuerDirectCommunication: Scalars['Boolean']['output'];
  account?: Maybe<Array<UserAccount>>;
  annualIncome?: Maybe<NonDiscretionaryAccountKycUserAnnualIncome>;
  employmentDetails?: Maybe<EmploymentDetails>;
  employmentStatus?: Maybe<NonDiscretionaryAccountKycUserEmploymentStatus>;
  /** Whether to hold or send security sale proceeds */
  holdSecuritySaleProceeds: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  investmentExperience?: Maybe<NonDiscretionaryAccountKycUserInvestmentExperience>;
  investmentObjective?: Maybe<NonDiscretionaryAccountKycUserInvestmentObjective>;
  liquidityNeeds?: Maybe<NonDiscretionaryAccountKycUserLiquidityNeeds>;
  maritalStatus?: Maybe<NonDiscretionaryAccountKycUserMaritalStatus>;
  netWorth?: Maybe<NonDiscretionaryAccountKycUserNetWorth>;
  /** Whether to reinvest dividends */
  reinvestDividend: Scalars['Boolean']['output'];
  riskTolerance?: Maybe<UserRiskTolerance>;
  /** Whether user is enrolled in sweep program */
  sweep: Scalars['Boolean']['output'];
  timeHorizon?: Maybe<NonDiscretionaryAccountKycUserTimeHorizon>;
  /** Update requests for this KYC record */
  updateRequests?: Maybe<Array<NonDiscretionaryUpdateRequest>>;
  user: User;
};

/** A connection to a list of items. */
export type NonDiscretionaryAccountKycConnection = {
  __typename?: 'NonDiscretionaryAccountKycConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NonDiscretionaryAccountKycEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type NonDiscretionaryAccountKycEdge = {
  __typename?: 'NonDiscretionaryAccountKycEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<NonDiscretionaryAccountKyc>;
};

/** NonDiscretionaryAccountKycUserAnnualIncome is enum for the field annual_income */
export enum NonDiscretionaryAccountKycUserAnnualIncome {
  UserAnnualIncome_0_25000 = 'USER_ANNUAL_INCOME_0_25000',
  UserAnnualIncome_25001_50000 = 'USER_ANNUAL_INCOME_25001_50000',
  UserAnnualIncome_50001_100000 = 'USER_ANNUAL_INCOME_50001_100000',
  UserAnnualIncome_100001_200000 = 'USER_ANNUAL_INCOME_100001_200000',
  UserAnnualIncome_200001_300000 = 'USER_ANNUAL_INCOME_200001_300000',
  UserAnnualIncome_300001_500000 = 'USER_ANNUAL_INCOME_300001_500000',
  UserAnnualIncome_500001_1000000 = 'USER_ANNUAL_INCOME_500001_1000000',
  UserAnnualIncome_500001_1200000 = 'USER_ANNUAL_INCOME_500001_1200000',
  UserAnnualIncome_1000001_5000000 = 'USER_ANNUAL_INCOME_1000001_5000000',
  UserAnnualIncome_1200001_9999999 = 'USER_ANNUAL_INCOME_1200001_9999999',
  UserAnnualIncome_5000001_9999999 = 'USER_ANNUAL_INCOME_5000001_9999999',
}

/** NonDiscretionaryAccountKycUserEmploymentStatus is enum for the field employment_status */
export enum NonDiscretionaryAccountKycUserEmploymentStatus {
  UserEmploymentStatusEmployed = 'USER_EMPLOYMENT_STATUS_EMPLOYED',
  UserEmploymentStatusRetired = 'USER_EMPLOYMENT_STATUS_RETIRED',
  UserEmploymentStatusSelfEmployed = 'USER_EMPLOYMENT_STATUS_SELF_EMPLOYED',
  UserEmploymentStatusStudent = 'USER_EMPLOYMENT_STATUS_STUDENT',
  UserEmploymentStatusUnemployed = 'USER_EMPLOYMENT_STATUS_UNEMPLOYED',
}

/** NonDiscretionaryAccountKycUserInvestmentExperience is enum for the field investment_experience */
export enum NonDiscretionaryAccountKycUserInvestmentExperience {
  UserInvestmentExperienceExtensive = 'USER_INVESTMENT_EXPERIENCE_EXTENSIVE',
  UserInvestmentExperienceGood = 'USER_INVESTMENT_EXPERIENCE_GOOD',
  UserInvestmentExperienceLimited = 'USER_INVESTMENT_EXPERIENCE_LIMITED',
  UserInvestmentExperienceNone = 'USER_INVESTMENT_EXPERIENCE_NONE',
}

/** NonDiscretionaryAccountKycUserInvestmentObjective is enum for the field investment_objective */
export enum NonDiscretionaryAccountKycUserInvestmentObjective {
  UserInvestmentObjectiveBalanced = 'USER_INVESTMENT_OBJECTIVE_BALANCED',
  UserInvestmentObjectiveCapitalAppreciation = 'USER_INVESTMENT_OBJECTIVE_CAPITAL_APPRECIATION',
  UserInvestmentObjectiveCapitalPreservation = 'USER_INVESTMENT_OBJECTIVE_CAPITAL_PRESERVATION',
  UserInvestmentObjectiveGrowth = 'USER_INVESTMENT_OBJECTIVE_GROWTH',
  UserInvestmentObjectiveGrowthAndIncome = 'USER_INVESTMENT_OBJECTIVE_GROWTH_AND_INCOME',
  UserInvestmentObjectiveGrowthIncome = 'USER_INVESTMENT_OBJECTIVE_GROWTH_INCOME',
  UserInvestmentObjectiveIncome = 'USER_INVESTMENT_OBJECTIVE_INCOME',
  UserInvestmentObjectiveLongTermGrowthWithGreaterRisk = 'USER_INVESTMENT_OBJECTIVE_LONG_TERM_GROWTH_WITH_GREATER_RISK',
  UserInvestmentObjectiveLongTermGrowthWithSafety = 'USER_INVESTMENT_OBJECTIVE_LONG_TERM_GROWTH_WITH_SAFETY',
  UserInvestmentObjectiveMaximumGrowth = 'USER_INVESTMENT_OBJECTIVE_MAXIMUM_GROWTH',
  UserInvestmentObjectiveOther = 'USER_INVESTMENT_OBJECTIVE_OTHER',
  UserInvestmentObjectiveShortTermGrowthWithRisk = 'USER_INVESTMENT_OBJECTIVE_SHORT_TERM_GROWTH_WITH_RISK',
  UserInvestmentObjectiveSpeculation = 'USER_INVESTMENT_OBJECTIVE_SPECULATION',
}

/** NonDiscretionaryAccountKycUserLiquidityNeeds is enum for the field liquidity_needs */
export enum NonDiscretionaryAccountKycUserLiquidityNeeds {
  UserLiquidityNeedsNotImportant = 'USER_LIQUIDITY_NEEDS_NOT_IMPORTANT',
  UserLiquidityNeedsSomewhatImportant = 'USER_LIQUIDITY_NEEDS_SOMEWHAT_IMPORTANT',
  UserLiquidityNeedsVeryImportant = 'USER_LIQUIDITY_NEEDS_VERY_IMPORTANT',
}

/** NonDiscretionaryAccountKycUserMaritalStatus is enum for the field marital_status */
export enum NonDiscretionaryAccountKycUserMaritalStatus {
  UserMaritalStatusDivorced = 'USER_MARITAL_STATUS_DIVORCED',
  UserMaritalStatusMarried = 'USER_MARITAL_STATUS_MARRIED',
  UserMaritalStatusSingle = 'USER_MARITAL_STATUS_SINGLE',
  UserMaritalStatusWidowed = 'USER_MARITAL_STATUS_WIDOWED',
}

/** NonDiscretionaryAccountKycUserNetWorth is enum for the field net_worth */
export enum NonDiscretionaryAccountKycUserNetWorth {
  UserNetWorth_0_25000 = 'USER_NET_WORTH_0_25000',
  UserNetWorth_0_50000 = 'USER_NET_WORTH_0_50000',
  UserNetWorth_25001_50000 = 'USER_NET_WORTH_25001_50000',
  UserNetWorth_50001_100000 = 'USER_NET_WORTH_50001_100000',
  UserNetWorth_100001_200000 = 'USER_NET_WORTH_100001_200000',
  UserNetWorth_200001_300000 = 'USER_NET_WORTH_200001_300000',
  UserNetWorth_200001_500000 = 'USER_NET_WORTH_200001_500000',
  UserNetWorth_300001_500000 = 'USER_NET_WORTH_300001_500000',
  UserNetWorth_500001_1000000 = 'USER_NET_WORTH_500001_1000000',
  UserNetWorth_1000001_5000000 = 'USER_NET_WORTH_1000001_5000000',
  UserNetWorth_5000001_9999999 = 'USER_NET_WORTH_5000001_9999999',
}

/** NonDiscretionaryAccountKycUserTimeHorizon is enum for the field time_horizon */
export enum NonDiscretionaryAccountKycUserTimeHorizon {
  UserTimeHorizonAverage = 'USER_TIME_HORIZON_AVERAGE',
  UserTimeHorizonLongest = 'USER_TIME_HORIZON_LONGEST',
  UserTimeHorizonShort = 'USER_TIME_HORIZON_SHORT',
}

/**
 * NonDiscretionaryAccountKycWhereInput is used for filtering NonDiscretionaryAccountKyc objects.
 * Input was generated by ent.
 */
export type NonDiscretionaryAccountKycWhereInput = {
  /** accept_issuer_direct_communication field predicates */
  acceptIssuerDirectCommunication?: InputMaybe<Scalars['Boolean']['input']>;
  acceptIssuerDirectCommunicationNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<NonDiscretionaryAccountKycWhereInput>>;
  /** annual_income field predicates */
  annualIncome?: InputMaybe<NonDiscretionaryAccountKycUserAnnualIncome>;
  annualIncomeIn?: InputMaybe<Array<NonDiscretionaryAccountKycUserAnnualIncome>>;
  annualIncomeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  annualIncomeNEQ?: InputMaybe<NonDiscretionaryAccountKycUserAnnualIncome>;
  annualIncomeNotIn?: InputMaybe<Array<NonDiscretionaryAccountKycUserAnnualIncome>>;
  annualIncomeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** employment_status field predicates */
  employmentStatus?: InputMaybe<NonDiscretionaryAccountKycUserEmploymentStatus>;
  employmentStatusIn?: InputMaybe<Array<NonDiscretionaryAccountKycUserEmploymentStatus>>;
  employmentStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  employmentStatusNEQ?: InputMaybe<NonDiscretionaryAccountKycUserEmploymentStatus>;
  employmentStatusNotIn?: InputMaybe<Array<NonDiscretionaryAccountKycUserEmploymentStatus>>;
  employmentStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** account edge predicates */
  hasAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountWith?: InputMaybe<Array<UserAccountWhereInput>>;
  /** update_requests edge predicates */
  hasUpdateRequests?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdateRequestsWith?: InputMaybe<Array<NonDiscretionaryUpdateRequestWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** hold_security_sale_proceeds field predicates */
  holdSecuritySaleProceeds?: InputMaybe<Scalars['Boolean']['input']>;
  holdSecuritySaleProceedsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** investment_experience field predicates */
  investmentExperience?: InputMaybe<NonDiscretionaryAccountKycUserInvestmentExperience>;
  investmentExperienceIn?: InputMaybe<Array<NonDiscretionaryAccountKycUserInvestmentExperience>>;
  investmentExperienceIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  investmentExperienceNEQ?: InputMaybe<NonDiscretionaryAccountKycUserInvestmentExperience>;
  investmentExperienceNotIn?: InputMaybe<Array<NonDiscretionaryAccountKycUserInvestmentExperience>>;
  investmentExperienceNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** investment_objective field predicates */
  investmentObjective?: InputMaybe<NonDiscretionaryAccountKycUserInvestmentObjective>;
  investmentObjectiveIn?: InputMaybe<Array<NonDiscretionaryAccountKycUserInvestmentObjective>>;
  investmentObjectiveIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  investmentObjectiveNEQ?: InputMaybe<NonDiscretionaryAccountKycUserInvestmentObjective>;
  investmentObjectiveNotIn?: InputMaybe<Array<NonDiscretionaryAccountKycUserInvestmentObjective>>;
  investmentObjectiveNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** liquidity_needs field predicates */
  liquidityNeeds?: InputMaybe<NonDiscretionaryAccountKycUserLiquidityNeeds>;
  liquidityNeedsIn?: InputMaybe<Array<NonDiscretionaryAccountKycUserLiquidityNeeds>>;
  liquidityNeedsIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  liquidityNeedsNEQ?: InputMaybe<NonDiscretionaryAccountKycUserLiquidityNeeds>;
  liquidityNeedsNotIn?: InputMaybe<Array<NonDiscretionaryAccountKycUserLiquidityNeeds>>;
  liquidityNeedsNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** marital_status field predicates */
  maritalStatus?: InputMaybe<NonDiscretionaryAccountKycUserMaritalStatus>;
  maritalStatusIn?: InputMaybe<Array<NonDiscretionaryAccountKycUserMaritalStatus>>;
  maritalStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  maritalStatusNEQ?: InputMaybe<NonDiscretionaryAccountKycUserMaritalStatus>;
  maritalStatusNotIn?: InputMaybe<Array<NonDiscretionaryAccountKycUserMaritalStatus>>;
  maritalStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** net_worth field predicates */
  netWorth?: InputMaybe<NonDiscretionaryAccountKycUserNetWorth>;
  netWorthIn?: InputMaybe<Array<NonDiscretionaryAccountKycUserNetWorth>>;
  netWorthIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  netWorthNEQ?: InputMaybe<NonDiscretionaryAccountKycUserNetWorth>;
  netWorthNotIn?: InputMaybe<Array<NonDiscretionaryAccountKycUserNetWorth>>;
  netWorthNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NonDiscretionaryAccountKycWhereInput>;
  or?: InputMaybe<Array<NonDiscretionaryAccountKycWhereInput>>;
  /** reinvest_dividend field predicates */
  reinvestDividend?: InputMaybe<Scalars['Boolean']['input']>;
  reinvestDividendNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** risk_tolerance field predicates */
  riskTolerance?: InputMaybe<UserRiskTolerance>;
  riskToleranceIn?: InputMaybe<Array<UserRiskTolerance>>;
  riskToleranceIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  riskToleranceNEQ?: InputMaybe<UserRiskTolerance>;
  riskToleranceNotIn?: InputMaybe<Array<UserRiskTolerance>>;
  riskToleranceNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** sweep field predicates */
  sweep?: InputMaybe<Scalars['Boolean']['input']>;
  sweepNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** time_horizon field predicates */
  timeHorizon?: InputMaybe<NonDiscretionaryAccountKycUserTimeHorizon>;
  timeHorizonIn?: InputMaybe<Array<NonDiscretionaryAccountKycUserTimeHorizon>>;
  timeHorizonIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  timeHorizonNEQ?: InputMaybe<NonDiscretionaryAccountKycUserTimeHorizon>;
  timeHorizonNotIn?: InputMaybe<Array<NonDiscretionaryAccountKycUserTimeHorizon>>;
  timeHorizonNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NonDiscretionaryUpdateRequest = Node & {
  __typename?: 'NonDiscretionaryUpdateRequest';
  /** Apex request associated with this request. */
  apexUpdateRequests?: Maybe<ApexUpdateRequest>;
  createTime: Scalars['Time']['output'];
  /** The snapshot of the brokerage kyc at the time of this request. */
  currentBrokerageKyc?: Maybe<BrokerageAccountParameters>;
  id: Scalars['ID']['output'];
  /** The non-discretionary account kyc that this update request is associated with. */
  nonDiscretionaryAccountKyc: NonDiscretionaryAccountKyc;
  requestDetails: RequestDetails;
  /** The brokerage parameters that are being updated. */
  requestParameters?: Maybe<BrokerageAccountParameters>;
  /** The time at which the action was requested. */
  requestTime: Scalars['Time']['output'];
  requestUUID?: Maybe<Scalars['UUID']['output']>;
  requestor: Requestor;
  /** The status of the update request. */
  status: NonDiscretionaryUpdateRequestUpdateRequestStatus;
  submittedAt?: Maybe<Scalars['Time']['output']>;
  /** Trusted contact update request associated with this request. */
  trustedContactRequest?: Maybe<TrustedContactUpdateRequest>;
  updateTime: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type NonDiscretionaryUpdateRequestConnection = {
  __typename?: 'NonDiscretionaryUpdateRequestConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NonDiscretionaryUpdateRequestEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type NonDiscretionaryUpdateRequestEdge = {
  __typename?: 'NonDiscretionaryUpdateRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<NonDiscretionaryUpdateRequest>;
};

/** Ordering options for NonDiscretionaryUpdateRequest connections */
export type NonDiscretionaryUpdateRequestOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order NonDiscretionaryUpdateRequests. */
  field: NonDiscretionaryUpdateRequestOrderField;
};

/** Properties by which NonDiscretionaryUpdateRequest connections can be ordered. */
export enum NonDiscretionaryUpdateRequestOrderField {
  RequestTime = 'REQUEST_TIME',
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/** NonDiscretionaryUpdateRequestUpdateRequestStatus is enum for the field status */
export enum NonDiscretionaryUpdateRequestUpdateRequestStatus {
  UpdateRequestStatusApproved = 'UPDATE_REQUEST_STATUS_APPROVED',
  UpdateRequestStatusContacated = 'UPDATE_REQUEST_STATUS_CONTACATED',
  UpdateRequestStatusPending = 'UPDATE_REQUEST_STATUS_PENDING',
  UpdateRequestStatusRejected = 'UPDATE_REQUEST_STATUS_REJECTED',
  UpdateRequestStatusSubmitted = 'UPDATE_REQUEST_STATUS_SUBMITTED',
}

/**
 * NonDiscretionaryUpdateRequestWhereInput is used for filtering NonDiscretionaryUpdateRequest objects.
 * Input was generated by ent.
 */
export type NonDiscretionaryUpdateRequestWhereInput = {
  and?: InputMaybe<Array<NonDiscretionaryUpdateRequestWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** apex_update_requests edge predicates */
  hasApexUpdateRequests?: InputMaybe<Scalars['Boolean']['input']>;
  hasApexUpdateRequestsWith?: InputMaybe<Array<ApexUpdateRequestWhereInput>>;
  /** non_discretionary_account_kyc edge predicates */
  hasNonDiscretionaryAccountKyc?: InputMaybe<Scalars['Boolean']['input']>;
  hasNonDiscretionaryAccountKycWith?: InputMaybe<Array<NonDiscretionaryAccountKycWhereInput>>;
  /** trusted_contact_request edge predicates */
  hasTrustedContactRequest?: InputMaybe<Scalars['Boolean']['input']>;
  hasTrustedContactRequestWith?: InputMaybe<Array<TrustedContactUpdateRequestWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<NonDiscretionaryUpdateRequestWhereInput>;
  or?: InputMaybe<Array<NonDiscretionaryUpdateRequestWhereInput>>;
  /** request_time field predicates */
  requestTime?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  requestTimeLT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** request_uuid field predicates */
  requestUUID?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  requestUUIDLT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDLTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNEQ?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNotIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** requestor field predicates */
  requestor?: InputMaybe<Requestor>;
  requestorIn?: InputMaybe<Array<Requestor>>;
  requestorNEQ?: InputMaybe<Requestor>;
  requestorNotIn?: InputMaybe<Array<Requestor>>;
  /** status field predicates */
  status?: InputMaybe<NonDiscretionaryUpdateRequestUpdateRequestStatus>;
  statusIn?: InputMaybe<Array<NonDiscretionaryUpdateRequestUpdateRequestStatus>>;
  statusNEQ?: InputMaybe<NonDiscretionaryUpdateRequestUpdateRequestStatus>;
  statusNotIn?: InputMaybe<Array<NonDiscretionaryUpdateRequestUpdateRequestStatus>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type Ohlcv = {
  __typename?: 'OHLCV';
  avgPrice: Array<Scalars['Decimal']['output']>;
  close: Array<Scalars['Decimal']['output']>;
  count: Scalars['Int']['output'];
  end: Scalars['Time']['output'];
  high: Array<Scalars['Decimal']['output']>;
  limit: Scalars['Int']['output'];
  low: Array<Scalars['Decimal']['output']>;
  open: Array<Scalars['Decimal']['output']>;
  period?: Maybe<TimePeriod>;
  start: Scalars['Time']['output'];
  timestamps: Array<Scalars['Time']['output']>;
  volume: Array<Scalars['Decimal']['output']>;
};

export type Order = Node & {
  __typename?: 'Order';
  acatOrder?: Maybe<AcatTransfer>;
  accountReconAdjustment?: Maybe<AccountReconAdjustment>;
  achTransferOrder?: Maybe<AchTransfer>;
  adminOrder?: Maybe<AdminOrder>;
  capitalGains?: Maybe<CapitalGains>;
  capitalGainsDistribution?: Maybe<CapitalGainsDistribution>;
  checkTransferOrder?: Maybe<CheckTransfer>;
  /** The client request ID is the submitted request ID from the requestor to ensure that a transaction is not duplicated. */
  clientRequestID?: Maybe<Scalars['String']['output']>;
  corporateActionModification?: Maybe<CorporateActionModification>;
  corporateActions?: Maybe<Array<CorporateActionModification>>;
  createTime: Scalars['Time']['output'];
  dividendOrder?: Maybe<DividendDistribution>;
  /** The transaction ID that is sent to the clearing house. */
  externalID?: Maybe<Scalars['String']['output']>;
  feeAndCreditOrder?: Maybe<FeeAndCreditOrder>;
  ictTransferOrder?: Maybe<IctTransfer>;
  id: Scalars['ID']['output'];
  interestOrder?: Maybe<InterestDistribution>;
  liquidation?: Maybe<Liquidation>;
  portfolioLedger?: Maybe<PortfolioLedger>;
  portfolioLiquidation?: Maybe<PortfolioLiquidation>;
  recurringRuleInstance?: Maybe<RecurringRuleInstance>;
  /** The time at which the action was requested. */
  requestTime: Scalars['Time']['output'];
  requestUUID?: Maybe<Scalars['UUID']['output']>;
  requestor: Requestor;
  returnOfCapital?: Maybe<ReturnOfCapital>;
  returnOfCapitalDistribution?: Maybe<ReturnOfCapitalDistribution>;
  /** refers to whether or not we can consider the order settled. */
  settled: Scalars['Boolean']['output'];
  status: OrderOrderStatus;
  tradeOrder?: Maybe<Trade>;
  type: OrderOrderType;
  updateTime: Scalars['Time']['output'];
  user: User;
};

/** A connection to a list of items. */
export type OrderConnection = {
  __typename?: 'OrderConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrderEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum OrderDirection {
  /** Specifies an ascending order for a given `orderBy` argument. */
  Asc = 'ASC',
  /** Specifies a descending order for a given `orderBy` argument. */
  Desc = 'DESC',
}

/** An edge in a connection. */
export type OrderEdge = {
  __typename?: 'OrderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Order>;
};

/** Ordering options for Order connections */
export type OrderOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Orders. */
  field: OrderOrderField;
};

/** Properties by which Order connections can be ordered. */
export enum OrderOrderField {
  RequestTime = 'REQUEST_TIME',
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/** OrderOrderStatus is enum for the field status */
export enum OrderOrderStatus {
  OrderStatusCanceled = 'ORDER_STATUS_CANCELED',
  OrderStatusCanceling = 'ORDER_STATUS_CANCELING',
  OrderStatusDone = 'ORDER_STATUS_DONE',
  OrderStatusFailed = 'ORDER_STATUS_FAILED',
  OrderStatusFinalizing = 'ORDER_STATUS_FINALIZING',
  OrderStatusProcessing = 'ORDER_STATUS_PROCESSING',
}

/** OrderOrderType is enum for the field type */
export enum OrderOrderType {
  OrderTypeAcattransfer = 'ORDER_TYPE_ACATTRANSFER',
  OrderTypeAccountReconAdjustment = 'ORDER_TYPE_ACCOUNT_RECON_ADJUSTMENT',
  OrderTypeAchtransfer = 'ORDER_TYPE_ACHTRANSFER',
  OrderTypeAdmin = 'ORDER_TYPE_ADMIN',
  OrderTypeCapitalGains = 'ORDER_TYPE_CAPITAL_GAINS',
  OrderTypeCapitalGainsDistribution = 'ORDER_TYPE_CAPITAL_GAINS_DISTRIBUTION',
  OrderTypeChecktransfer = 'ORDER_TYPE_CHECKTRANSFER',
  OrderTypeCorporateAction = 'ORDER_TYPE_CORPORATE_ACTION',
  OrderTypeDividend = 'ORDER_TYPE_DIVIDEND',
  OrderTypeFeeCredit = 'ORDER_TYPE_FEE_CREDIT',
  OrderTypeIcttransfer = 'ORDER_TYPE_ICTTRANSFER',
  OrderTypeInterest = 'ORDER_TYPE_INTEREST',
  OrderTypeLiquidation = 'ORDER_TYPE_LIQUIDATION',
  OrderTypePortfolioLedger = 'ORDER_TYPE_PORTFOLIO_LEDGER',
  OrderTypePortfolioLiquidation = 'ORDER_TYPE_PORTFOLIO_LIQUIDATION',
  OrderTypeRecurringRuleInstance = 'ORDER_TYPE_RECURRING_RULE_INSTANCE',
  OrderTypeReturnOfCapital = 'ORDER_TYPE_RETURN_OF_CAPITAL',
  OrderTypeReturnOfCapitalDistribution = 'ORDER_TYPE_RETURN_OF_CAPITAL_DISTRIBUTION',
  OrderTypeTrade = 'ORDER_TYPE_TRADE',
}

/**
 * OrderWhereInput is used for filtering Order objects.
 * Input was generated by ent.
 */
export type OrderWhereInput = {
  and?: InputMaybe<Array<OrderWhereInput>>;
  /** client_request_id field predicates */
  clientRequestID?: InputMaybe<Scalars['String']['input']>;
  clientRequestIDContains?: InputMaybe<Scalars['String']['input']>;
  clientRequestIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  clientRequestIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  clientRequestIDGT?: InputMaybe<Scalars['String']['input']>;
  clientRequestIDGTE?: InputMaybe<Scalars['String']['input']>;
  clientRequestIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  clientRequestIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  clientRequestIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  clientRequestIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  clientRequestIDLT?: InputMaybe<Scalars['String']['input']>;
  clientRequestIDLTE?: InputMaybe<Scalars['String']['input']>;
  clientRequestIDNEQ?: InputMaybe<Scalars['String']['input']>;
  clientRequestIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  clientRequestIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** external_id field predicates */
  externalID?: InputMaybe<Scalars['String']['input']>;
  externalIDContains?: InputMaybe<Scalars['String']['input']>;
  externalIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  externalIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  externalIDGT?: InputMaybe<Scalars['String']['input']>;
  externalIDGTE?: InputMaybe<Scalars['String']['input']>;
  externalIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  externalIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  externalIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  externalIDLT?: InputMaybe<Scalars['String']['input']>;
  externalIDLTE?: InputMaybe<Scalars['String']['input']>;
  externalIDNEQ?: InputMaybe<Scalars['String']['input']>;
  externalIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** admin_requestor edge predicates */
  hasAdminRequestor?: InputMaybe<Scalars['Boolean']['input']>;
  hasAdminRequestorWith?: InputMaybe<Array<AdminWhereInput>>;
  hasPortfolioWith?: InputMaybe<UserAccountPortfolioWhereInput>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<OrderWhereInput>;
  or?: InputMaybe<Array<OrderWhereInput>>;
  requestDetails?: InputMaybe<RequestDetailsWhereInput>;
  /** request_time field predicates */
  requestTime?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  requestTimeLT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** request_uuid field predicates */
  requestUUID?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  requestUUIDLT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDLTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNEQ?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNotIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** requestor field predicates */
  requestor?: InputMaybe<Requestor>;
  requestorIn?: InputMaybe<Array<Requestor>>;
  requestorNEQ?: InputMaybe<Requestor>;
  requestorNotIn?: InputMaybe<Array<Requestor>>;
  /** settled field predicates */
  settled?: InputMaybe<Scalars['Boolean']['input']>;
  settledNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** status field predicates */
  status?: InputMaybe<OrderOrderStatus>;
  statusIn?: InputMaybe<Array<OrderOrderStatus>>;
  statusNEQ?: InputMaybe<OrderOrderStatus>;
  statusNotIn?: InputMaybe<Array<OrderOrderStatus>>;
  /** type field predicates */
  type?: InputMaybe<OrderOrderType>;
  typeIn?: InputMaybe<Array<OrderOrderType>>;
  typeNEQ?: InputMaybe<OrderOrderType>;
  typeNotIn?: InputMaybe<Array<OrderOrderType>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type OrdersWhereInput = {
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type Other = {
  __typename?: 'Other';
  documentIDs?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type OtherGovernmentId = {
  __typename?: 'OtherGovernmentId';
  back?: Maybe<Scalars['String']['output']>;
  front?: Maybe<Scalars['String']['output']>;
};

export type OtherSourcesOfWealth = {
  __typename?: 'OtherSourcesOfWealth';
  hasOtherSourcesOfWealth: Scalars['Boolean']['output'];
  otherSourcesOfWealth?: Maybe<Scalars['String']['output']>;
};

/**
 * Information about pagination in a connection.
 * https://relay.dev/graphql/connections.htm#sec-undefined.PageInfo
 */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']['output']>;
};

export type PassingCipResults = {
  __typename?: 'PassingCipResults';
  back?: Maybe<Scalars['String']['output']>;
};

export type Passport = {
  __typename?: 'Passport';
  back?: Maybe<Scalars['String']['output']>;
  front?: Maybe<Scalars['String']['output']>;
};

export type PlaidRelationshipOwner = Node & {
  __typename?: 'PlaidRelationshipOwner';
  androidPackageName: Scalars['String']['output'];
  createTime: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  /** name is a human readable identifier referencing the entity that owns the plaid relationship */
  name: Scalars['String']['output'];
  redirectURI: Scalars['String']['output'];
  updateTime: Scalars['Time']['output'];
  webhookURL: Scalars['String']['output'];
};

/** Ordering options for PlaidRelationshipOwner connections */
export type PlaidRelationshipOwnerOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order PlaidRelationshipOwners. */
  field: PlaidRelationshipOwnerOrderField;
};

/** Properties by which PlaidRelationshipOwner connections can be ordered. */
export enum PlaidRelationshipOwnerOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * PlaidRelationshipOwnerWhereInput is used for filtering PlaidRelationshipOwner objects.
 * Input was generated by ent.
 */
export type PlaidRelationshipOwnerWhereInput = {
  and?: InputMaybe<Array<PlaidRelationshipOwnerWhereInput>>;
  /** android_package_name field predicates */
  androidPackageName?: InputMaybe<Scalars['String']['input']>;
  androidPackageNameContains?: InputMaybe<Scalars['String']['input']>;
  androidPackageNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  androidPackageNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  androidPackageNameGT?: InputMaybe<Scalars['String']['input']>;
  androidPackageNameGTE?: InputMaybe<Scalars['String']['input']>;
  androidPackageNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  androidPackageNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  androidPackageNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  androidPackageNameLT?: InputMaybe<Scalars['String']['input']>;
  androidPackageNameLTE?: InputMaybe<Scalars['String']['input']>;
  androidPackageNameNEQ?: InputMaybe<Scalars['String']['input']>;
  androidPackageNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<PlaidRelationshipOwnerWhereInput>;
  or?: InputMaybe<Array<PlaidRelationshipOwnerWhereInput>>;
  /** redirect_uri field predicates */
  redirectURI?: InputMaybe<Scalars['String']['input']>;
  redirectURIContains?: InputMaybe<Scalars['String']['input']>;
  redirectURIContainsFold?: InputMaybe<Scalars['String']['input']>;
  redirectURIEqualFold?: InputMaybe<Scalars['String']['input']>;
  redirectURIGT?: InputMaybe<Scalars['String']['input']>;
  redirectURIGTE?: InputMaybe<Scalars['String']['input']>;
  redirectURIHasPrefix?: InputMaybe<Scalars['String']['input']>;
  redirectURIHasSuffix?: InputMaybe<Scalars['String']['input']>;
  redirectURIIn?: InputMaybe<Array<Scalars['String']['input']>>;
  redirectURILT?: InputMaybe<Scalars['String']['input']>;
  redirectURILTE?: InputMaybe<Scalars['String']['input']>;
  redirectURINEQ?: InputMaybe<Scalars['String']['input']>;
  redirectURINotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** webhook_url field predicates */
  webhookURL?: InputMaybe<Scalars['String']['input']>;
  webhookURLContains?: InputMaybe<Scalars['String']['input']>;
  webhookURLContainsFold?: InputMaybe<Scalars['String']['input']>;
  webhookURLEqualFold?: InputMaybe<Scalars['String']['input']>;
  webhookURLGT?: InputMaybe<Scalars['String']['input']>;
  webhookURLGTE?: InputMaybe<Scalars['String']['input']>;
  webhookURLHasPrefix?: InputMaybe<Scalars['String']['input']>;
  webhookURLHasSuffix?: InputMaybe<Scalars['String']['input']>;
  webhookURLIn?: InputMaybe<Array<Scalars['String']['input']>>;
  webhookURLLT?: InputMaybe<Scalars['String']['input']>;
  webhookURLLTE?: InputMaybe<Scalars['String']['input']>;
  webhookURLNEQ?: InputMaybe<Scalars['String']['input']>;
  webhookURLNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PoliticallyExposedDetails = {
  __typename?: 'PoliticallyExposedDetails';
  /** @deprecated Use `familyMembers`. */
  FamilyMembers: Array<Scalars['String']['output']>;
  /** @deprecated Use `organizationName`. */
  OrganizationName: Scalars['String']['output'];
  familyMembers?: Maybe<Array<Scalars['String']['output']>>;
  isPoliticallyExposed: Scalars['Boolean']['output'];
  organizationName?: Maybe<Scalars['String']['output']>;
};

/** Pong is the response to a ping */
export type Pong = {
  __typename?: 'Pong';
  /** The current time */
  time: Scalars['Time']['output'];
  /** The current version of the API */
  version: Scalars['String']['output'];
};

export type PortfolioLedger = BaseOrder &
  Node & {
    __typename?: 'PortfolioLedger';
    account: UserAccount;
    createTime: Scalars['Time']['output'];
    description?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    involvedPortfolios: Array<UserAccountPortfolio>;
    /** Notes is the notes of the order intended for the principal. */
    note?: Maybe<Scalars['String']['output']>;
    orderStatus: OrderOrderStatus;
    orderType: OrderOrderType;
    requestDetails: RequestDetails;
    updateTime: Scalars['Time']['output'];
  };

/** A connection to a list of items. */
export type PortfolioLedgerConnection = {
  __typename?: 'PortfolioLedgerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PortfolioLedgerEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PortfolioLedgerEdge = {
  __typename?: 'PortfolioLedgerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<PortfolioLedger>;
};

/** Ordering options for PortfolioLedger connections */
export type PortfolioLedgerOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order PortfolioLedgers. */
  field: PortfolioLedgerOrderField;
};

/** Properties by which PortfolioLedger connections can be ordered. */
export enum PortfolioLedgerOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * PortfolioLedgerWhereInput is used for filtering PortfolioLedger objects.
 * Input was generated by ent.
 */
export type PortfolioLedgerWhereInput = {
  and?: InputMaybe<Array<PortfolioLedgerWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** account edge predicates */
  hasAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountWith?: InputMaybe<Array<UserAccountWhereInput>>;
  /** involved_portfolios edge predicates */
  hasInvolvedPortfolios?: InputMaybe<Scalars['Boolean']['input']>;
  hasInvolvedPortfoliosWith?: InputMaybe<Array<UserAccountPortfolioWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<PortfolioLedgerWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<PortfolioLedgerWhereInput>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type PortfolioLiquidation = BaseOrder &
  Node & {
    __typename?: 'PortfolioLiquidation';
    createTime: Scalars['Time']['output'];
    id: Scalars['ID']['output'];
    liquidation: Liquidation;
    /** Notes is the notes of the order intended for the principal. */
    note?: Maybe<Scalars['String']['output']>;
    orderStatus: OrderOrderStatus;
    orderType: OrderOrderType;
    portfolio: UserAccountPortfolio;
    requestDetails: RequestDetails;
    /** Total cash value of the liquidated assets. */
    totalLiquidatedAmount: Scalars['Decimal']['output'];
    updateTime: Scalars['Time']['output'];
  };

/** A connection to a list of items. */
export type PortfolioLiquidationConnection = {
  __typename?: 'PortfolioLiquidationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PortfolioLiquidationEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PortfolioLiquidationEdge = {
  __typename?: 'PortfolioLiquidationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<PortfolioLiquidation>;
};

/** Ordering options for PortfolioLiquidation connections */
export type PortfolioLiquidationOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order PortfolioLiquidations. */
  field: PortfolioLiquidationOrderField;
};

/** Properties by which PortfolioLiquidation connections can be ordered. */
export enum PortfolioLiquidationOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * PortfolioLiquidationWhereInput is used for filtering PortfolioLiquidation objects.
 * Input was generated by ent.
 */
export type PortfolioLiquidationWhereInput = {
  and?: InputMaybe<Array<PortfolioLiquidationWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** liquidation edge predicates */
  hasLiquidation?: InputMaybe<Scalars['Boolean']['input']>;
  hasLiquidationWith?: InputMaybe<Array<LiquidationWhereInput>>;
  /** portfolio edge predicates */
  hasPortfolio?: InputMaybe<Scalars['Boolean']['input']>;
  hasPortfolioWith?: InputMaybe<Array<UserAccountPortfolioWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<PortfolioLiquidationWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<PortfolioLiquidationWhereInput>>;
  /** total_liquidated_amount field predicates */
  totalLiquidatedAmount?: InputMaybe<Scalars['Decimal']['input']>;
  totalLiquidatedAmountGT?: InputMaybe<Scalars['Decimal']['input']>;
  totalLiquidatedAmountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalLiquidatedAmountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  totalLiquidatedAmountLT?: InputMaybe<Scalars['Decimal']['input']>;
  totalLiquidatedAmountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalLiquidatedAmountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  totalLiquidatedAmountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type ProgramEnrollmentHistory = Node & {
  __typename?: 'ProgramEnrollmentHistory';
  /** Apex request associated with this request. */
  apexUpdateRequests?: Maybe<ApexUpdateRequest>;
  approvalStatus: ProgramEnrollmentHistoryProgramApprovalStatus;
  createTime: Scalars['Time']['output'];
  electionPreference: ProgramEnrollmentHistoryProgramElectionPreference;
  id: Scalars['ID']['output'];
  programType: ProgramEnrollmentHistoryProgramType;
  /** used by admins to provide reason for rejecting or updating enrollment */
  reason?: Maybe<Scalars['String']['output']>;
  /** The time at which the action was requested. */
  requestTime: Scalars['Time']['output'];
  requestUUID?: Maybe<Scalars['UUID']['output']>;
  requestor: Requestor;
  reviewedAt?: Maybe<Scalars['Time']['output']>;
  submissionStatus: ProgramEnrollmentHistoryProgramSubmissionStatus;
  /** the time the enrollment was submitted to Apex */
  submittedAt?: Maybe<Scalars['Time']['output']>;
  updateTime: Scalars['Time']['output'];
  userAccount: UserAccount;
  userAccountID: Scalars['ID']['output'];
};

/** Ordering options for ProgramEnrollmentHistory connections */
export type ProgramEnrollmentHistoryOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ProgramEnrollmentHistories. */
  field: ProgramEnrollmentHistoryOrderField;
};

/** Properties by which ProgramEnrollmentHistory connections can be ordered. */
export enum ProgramEnrollmentHistoryOrderField {
  RequestTime = 'REQUEST_TIME',
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/** ProgramEnrollmentHistoryProgramApprovalStatus is enum for the field approval_status */
export enum ProgramEnrollmentHistoryProgramApprovalStatus {
  ProgramApprovalStatusApproved = 'PROGRAM_APPROVAL_STATUS_APPROVED',
  ProgramApprovalStatusNotRequired = 'PROGRAM_APPROVAL_STATUS_NOT_REQUIRED',
  ProgramApprovalStatusPending = 'PROGRAM_APPROVAL_STATUS_PENDING',
  ProgramApprovalStatusRejected = 'PROGRAM_APPROVAL_STATUS_REJECTED',
}

/** ProgramEnrollmentHistoryProgramElectionPreference is enum for the field election_preference */
export enum ProgramEnrollmentHistoryProgramElectionPreference {
  ProgramElectionPreferenceOptIn = 'PROGRAM_ELECTION_PREFERENCE_OPT_IN',
  ProgramElectionPreferenceOptOut = 'PROGRAM_ELECTION_PREFERENCE_OPT_OUT',
}

/** ProgramEnrollmentHistoryProgramSubmissionStatus is enum for the field submission_status */
export enum ProgramEnrollmentHistoryProgramSubmissionStatus {
  ProgramSubmissionStatusCompleted = 'PROGRAM_SUBMISSION_STATUS_COMPLETED',
  ProgramSubmissionStatusNotRequired = 'PROGRAM_SUBMISSION_STATUS_NOT_REQUIRED',
  ProgramSubmissionStatusPendingReview = 'PROGRAM_SUBMISSION_STATUS_PENDING_REVIEW',
  ProgramSubmissionStatusPendingSubmission = 'PROGRAM_SUBMISSION_STATUS_PENDING_SUBMISSION',
  ProgramSubmissionStatusRejected = 'PROGRAM_SUBMISSION_STATUS_REJECTED',
  ProgramSubmissionStatusSubmitted = 'PROGRAM_SUBMISSION_STATUS_SUBMITTED',
}

/** ProgramEnrollmentHistoryProgramType is enum for the field program_type */
export enum ProgramEnrollmentHistoryProgramType {
  ProgramTypeCashSweep = 'PROGRAM_TYPE_CASH_SWEEP',
  ProgramTypeFpsl = 'PROGRAM_TYPE_FPSL',
}

/**
 * ProgramEnrollmentHistoryWhereInput is used for filtering ProgramEnrollmentHistory objects.
 * Input was generated by ent.
 */
export type ProgramEnrollmentHistoryWhereInput = {
  and?: InputMaybe<Array<ProgramEnrollmentHistoryWhereInput>>;
  /** approval_status field predicates */
  approvalStatus?: InputMaybe<ProgramEnrollmentHistoryProgramApprovalStatus>;
  approvalStatusIn?: InputMaybe<Array<ProgramEnrollmentHistoryProgramApprovalStatus>>;
  approvalStatusNEQ?: InputMaybe<ProgramEnrollmentHistoryProgramApprovalStatus>;
  approvalStatusNotIn?: InputMaybe<Array<ProgramEnrollmentHistoryProgramApprovalStatus>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** election_preference field predicates */
  electionPreference?: InputMaybe<ProgramEnrollmentHistoryProgramElectionPreference>;
  electionPreferenceIn?: InputMaybe<Array<ProgramEnrollmentHistoryProgramElectionPreference>>;
  electionPreferenceNEQ?: InputMaybe<ProgramEnrollmentHistoryProgramElectionPreference>;
  electionPreferenceNotIn?: InputMaybe<Array<ProgramEnrollmentHistoryProgramElectionPreference>>;
  /** apex_update_requests edge predicates */
  hasApexUpdateRequests?: InputMaybe<Scalars['Boolean']['input']>;
  hasApexUpdateRequestsWith?: InputMaybe<Array<ApexUpdateRequestWhereInput>>;
  /** user_account edge predicates */
  hasUserAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserAccountWith?: InputMaybe<Array<UserAccountWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<ProgramEnrollmentHistoryWhereInput>;
  or?: InputMaybe<Array<ProgramEnrollmentHistoryWhereInput>>;
  /** program_type field predicates */
  programType?: InputMaybe<ProgramEnrollmentHistoryProgramType>;
  programTypeIn?: InputMaybe<Array<ProgramEnrollmentHistoryProgramType>>;
  programTypeNEQ?: InputMaybe<ProgramEnrollmentHistoryProgramType>;
  programTypeNotIn?: InputMaybe<Array<ProgramEnrollmentHistoryProgramType>>;
  /** reason field predicates */
  reason?: InputMaybe<Scalars['String']['input']>;
  reasonContains?: InputMaybe<Scalars['String']['input']>;
  reasonContainsFold?: InputMaybe<Scalars['String']['input']>;
  reasonEqualFold?: InputMaybe<Scalars['String']['input']>;
  reasonGT?: InputMaybe<Scalars['String']['input']>;
  reasonGTE?: InputMaybe<Scalars['String']['input']>;
  reasonHasPrefix?: InputMaybe<Scalars['String']['input']>;
  reasonHasSuffix?: InputMaybe<Scalars['String']['input']>;
  reasonIn?: InputMaybe<Array<Scalars['String']['input']>>;
  reasonIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  reasonLT?: InputMaybe<Scalars['String']['input']>;
  reasonLTE?: InputMaybe<Scalars['String']['input']>;
  reasonNEQ?: InputMaybe<Scalars['String']['input']>;
  reasonNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  reasonNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** request_time field predicates */
  requestTime?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  requestTimeLT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** request_uuid field predicates */
  requestUUID?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  requestUUIDLT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDLTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNEQ?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNotIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** requestor field predicates */
  requestor?: InputMaybe<Requestor>;
  requestorIn?: InputMaybe<Array<Requestor>>;
  requestorNEQ?: InputMaybe<Requestor>;
  requestorNotIn?: InputMaybe<Array<Requestor>>;
  /** reviewed_at field predicates */
  reviewedAt?: InputMaybe<Scalars['Time']['input']>;
  reviewedAtGT?: InputMaybe<Scalars['Time']['input']>;
  reviewedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  reviewedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  reviewedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  reviewedAtLT?: InputMaybe<Scalars['Time']['input']>;
  reviewedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  reviewedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  reviewedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  reviewedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** submission_status field predicates */
  submissionStatus?: InputMaybe<ProgramEnrollmentHistoryProgramSubmissionStatus>;
  submissionStatusIn?: InputMaybe<Array<ProgramEnrollmentHistoryProgramSubmissionStatus>>;
  submissionStatusNEQ?: InputMaybe<ProgramEnrollmentHistoryProgramSubmissionStatus>;
  submissionStatusNotIn?: InputMaybe<Array<ProgramEnrollmentHistoryProgramSubmissionStatus>>;
  /** submitted_at field predicates */
  submittedAt?: InputMaybe<Scalars['Time']['input']>;
  submittedAtGT?: InputMaybe<Scalars['Time']['input']>;
  submittedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  submittedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  submittedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  submittedAtLT?: InputMaybe<Scalars['Time']['input']>;
  submittedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  submittedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  submittedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  submittedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** user_account_id field predicates */
  userAccountID?: InputMaybe<Scalars['ID']['input']>;
  userAccountIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  userAccountIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  userAccountIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type Query = {
  __typename?: 'Query';
  acatTransfers: AcatTransferConnection;
  accountReconAdjustments: AccountReconAdjustmentConnection;
  achTransfers: AchTransferConnection;
  adminOrders: AdminOrderConnection;
  apexAccountRequests: ApexAccountRequestConnection;
  apexAccountUpdateQueues: ApexAccountUpdateQueueConnection;
  apexUpdateRequests: ApexUpdateRequestConnection;
  /** Returns all the assets in all asset types available in the system. */
  assets: Array<BaseAsset>;
  capitalGainsDistributions: CapitalGainsDistributionConnection;
  capitalGainsSlice: CapitalGainsConnection;
  checkTransfers: CheckTransferConnection;
  corporateActionModifications: CorporateActionModificationConnection;
  corporateActions: CorporateActionConnection;
  countries: CountryConnection;
  cryptoAssets: CryptoAssetConnection;
  discretionaryAccountKycs: DiscretionaryAccountKycConnection;
  discretionaryUpdateRequests: DiscretionaryUpdateRequestConnection;
  dividendDistributions: DividendDistributionConnection;
  etfDetailsSlice: EtfDetailsConnection;
  externalAccounts: ExternalAccountConnection;
  feeAndCreditOrders: FeeAndCreditOrderConnection;
  feeAndCreditSubmissions: FeeAndCreditSubmissionConnection;
  fiatAssets: FiatAssetConnection;
  generalInvestmentPortfolios: GeneralInvestmentPortfolios;
  goePortfolioMappings: Array<GoePortfolioMapping>;
  ictTransfers: IctTransferConnection;
  interestDistributions: InterestDistributionConnection;
  /** Returns a list of investor documents for the given account. */
  investorDocuments: Array<InvestorDocument>;
  liabilities: LiabilityConnection;
  liquidations: LiquidationConnection;
  /** contains endpoints for getting the status of the market on different dates. */
  marketHours: MarketHours;
  modelPortfolios: ModelPortfolioConnection;
  /** Fetches an object given its ID. */
  node?: Maybe<Node>;
  /** Lookup nodes by a list of IDs. */
  nodes: Array<Maybe<Node>>;
  nonDiscretionaryAccountKycs: NonDiscretionaryAccountKycConnection;
  nonDiscretionaryUpdateRequests: NonDiscretionaryUpdateRequestConnection;
  orders: BaseOrderConnection;
  /** Returns the current time and other system information. */
  ping: Pong;
  portfolioLedgers: PortfolioLedgerConnection;
  portfolioLiquidations: PortfolioLiquidationConnection;
  recurringRuleInstances: RecurringRuleInstanceConnection;
  recurringRules: RecurringRuleConnection;
  referralRecords: ReferralRecordConnection;
  repeatInvestmentSectors: Array<RepeatInvestmentSector>;
  retirementFundamentalInfo?: Maybe<RetirementFundamentalInfo>;
  returnOfCapitalDistributions: ReturnOfCapitalDistributionConnection;
  returnOfCapitals: ReturnOfCapitalConnection;
  roboGeneralInvestments: RoboGeneralInvestmentConnection;
  roboGoals: RoboGoalConnection;
  roboInvestmentActions: RoboInvestmentActionConnection;
  rtqPortfolioLevelSets: RtqPortfolioLevelSetConnection;
  rtqQuestionnaires: RtqQuestionnaireConnection;
  rtqSubmissions: RtqSubmissionConnection;
  securityAssets: SecurityAssetConnection;
  securitySectors: SecuritySectorConnection;
  sodDiscrepancyTracks: SodDiscrepancyTrackConnection;
  supportFaQs: Array<SupportFaq>;
  supportFaqCategories: Array<SupportFaqCategory>;
  /** Returns information on the tenant. */
  tenant: Tenant;
  trades: TradeConnection;
  trustedContactUpdateRequests: TrustedContactUpdateRequestConnection;
  trustedContacts: TrustedContactConnection;
  userAccountPortfolioAssets: UserAccountPortfolioAssetConnection;
  userAccountPortfolios: UserAccountPortfolioConnection;
  userAccountRestrictions: UserAccountRestrictionConnection;
  userAccounts: UserAccountConnection;
  userDocumentSets: UserDocumentSetConnection;
  userDocuments: UserDocumentConnection;
  userProfileUpdateRequests: UserProfileUpdateRequestConnection;
  userProfiles: UserProfileConnection;
  /** Get retirement contribution info for a user. */
  userRetirementContributions?: Maybe<Array<Maybe<UserRetirementContributionInfo>>>;
  userSettings: UserSettings;
  users: UserConnection;
  /** Returns a list of watchlists or a single watchlist by name */
  watchlists: Array<Watchlist>;
};

export type QueryAcatTransfersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AcatTransferOrder>;
  where?: InputMaybe<AcatTransferWhereInput>;
};

export type QueryAccountReconAdjustmentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AccountReconAdjustmentOrder>;
  where?: InputMaybe<AccountReconAdjustmentWhereInput>;
};

export type QueryAchTransfersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AchTransferOrder>;
  where?: InputMaybe<AchTransferWhereInput>;
};

export type QueryAdminOrdersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AdminOrderOrder>;
  where?: InputMaybe<AdminOrderWhereInput>;
};

export type QueryApexAccountRequestsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ApexAccountRequestOrder>;
  where?: InputMaybe<ApexAccountRequestWhereInput>;
};

export type QueryApexAccountUpdateQueuesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ApexAccountUpdateQueueOrder>;
  where?: InputMaybe<ApexAccountUpdateQueueWhereInput>;
};

export type QueryApexUpdateRequestsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ApexUpdateRequestOrder>;
  where?: InputMaybe<ApexUpdateRequestWhereInput>;
};

export type QueryAssetsArgs = {
  where?: InputMaybe<BaseAssetWhereInput>;
};

export type QueryCapitalGainsDistributionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CapitalGainsDistributionOrder>;
  where?: InputMaybe<CapitalGainsDistributionWhereInput>;
};

export type QueryCapitalGainsSliceArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CapitalGainsOrder>;
  where?: InputMaybe<CapitalGainsWhereInput>;
};

export type QueryCheckTransfersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CheckTransferOrder>;
  where?: InputMaybe<CheckTransferWhereInput>;
};

export type QueryCorporateActionModificationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CorporateActionModificationOrder>;
  where?: InputMaybe<CorporateActionModificationWhereInput>;
};

export type QueryCorporateActionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CorporateActionOrder>;
  where?: InputMaybe<CorporateActionWhereInput>;
};

export type QueryCountriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CountryOrder>;
  where?: InputMaybe<CountryWhereInput>;
};

export type QueryCryptoAssetsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CryptoAssetOrder>;
  where?: InputMaybe<CryptoAssetWhereInput>;
};

export type QueryDiscretionaryAccountKycsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DiscretionaryAccountKycWhereInput>;
};

export type QueryDiscretionaryUpdateRequestsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DiscretionaryUpdateRequestOrder>;
  where?: InputMaybe<DiscretionaryUpdateRequestWhereInput>;
};

export type QueryDividendDistributionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DividendDistributionOrder>;
  where?: InputMaybe<DividendDistributionWhereInput>;
};

export type QueryEtfDetailsSliceArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<EtfDetailsOrder>;
  where?: InputMaybe<EtfDetailsWhereInput>;
};

export type QueryExternalAccountsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ExternalAccountOrder>;
  where?: InputMaybe<ExternalAccountWhereInput>;
};

export type QueryFeeAndCreditOrdersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FeeAndCreditOrderOrder>;
  where?: InputMaybe<FeeAndCreditOrderWhereInput>;
};

export type QueryFeeAndCreditSubmissionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FeeAndCreditSubmissionOrder>;
  where?: InputMaybe<FeeAndCreditSubmissionWhereInput>;
};

export type QueryFiatAssetsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FiatAssetOrder>;
  where?: InputMaybe<FiatAssetWhereInput>;
};

export type QueryIctTransfersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IctTransferOrder>;
  where?: InputMaybe<IctTransferWhereInput>;
};

export type QueryInterestDistributionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<InterestDistributionOrder>;
  where?: InputMaybe<InterestDistributionWhereInput>;
};

export type QueryInvestorDocumentsArgs = {
  orderBy?: InputMaybe<InvestorDocumentOrder>;
  where: InvestorDocumentWhereInput;
};

export type QueryLiabilitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LiabilityOrder>>;
  where?: InputMaybe<LiabilityWhereInput>;
};

export type QueryLiquidationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<LiquidationOrder>;
  where?: InputMaybe<LiquidationWhereInput>;
};

export type QueryModelPortfoliosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ModelPortfolioOrder>;
  where?: InputMaybe<ModelPortfolioWhereInput>;
};

export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryNodesArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryNonDiscretionaryAccountKycsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NonDiscretionaryAccountKycWhereInput>;
};

export type QueryNonDiscretionaryUpdateRequestsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<NonDiscretionaryUpdateRequestOrder>;
  where?: InputMaybe<NonDiscretionaryUpdateRequestWhereInput>;
};

export type QueryOrdersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderOrder>;
  where?: InputMaybe<OrderWhereInput>;
};

export type QueryPortfolioLedgersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PortfolioLedgerOrder>;
  where?: InputMaybe<PortfolioLedgerWhereInput>;
};

export type QueryPortfolioLiquidationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PortfolioLiquidationOrder>;
  where?: InputMaybe<PortfolioLiquidationWhereInput>;
};

export type QueryRecurringRuleInstancesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RecurringRuleInstanceOrder>;
  where?: InputMaybe<RecurringRuleInstanceWhereInput>;
};

export type QueryRecurringRulesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RecurringRuleOrder>;
  where?: InputMaybe<RecurringRuleWhereInput>;
};

export type QueryReferralRecordsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ReferralRecordOrder>;
  where?: InputMaybe<ReferralRecordWhereInput>;
};

export type QueryReturnOfCapitalDistributionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ReturnOfCapitalDistributionOrder>;
  where?: InputMaybe<ReturnOfCapitalDistributionWhereInput>;
};

export type QueryReturnOfCapitalsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ReturnOfCapitalOrder>;
  where?: InputMaybe<ReturnOfCapitalWhereInput>;
};

export type QueryRoboGeneralInvestmentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RoboGeneralInvestmentOrder>;
  where?: InputMaybe<RoboGeneralInvestmentWhereInput>;
};

export type QueryRoboGoalsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RoboGoalOrder>;
  where?: InputMaybe<RoboGoalWhereInput>;
};

export type QueryRoboInvestmentActionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RoboInvestmentActionOrder>;
  where?: InputMaybe<RoboInvestmentActionWhereInput>;
};

export type QueryRtqPortfolioLevelSetsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RtqPortfolioLevelSetWhereInput>;
};

export type QueryRtqQuestionnairesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RtqQuestionnaireWhereInput>;
};

export type QueryRtqSubmissionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RtqSubmissionWhereInput>;
};

export type QuerySecurityAssetsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SecurityAssetOrder>;
  where?: InputMaybe<SecurityAssetWhereInput>;
};

export type QuerySecuritySectorsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SecuritySectorWhereInput>;
};

export type QuerySodDiscrepancyTracksArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SodDiscrepancyTrackOrder>;
  where?: InputMaybe<SodDiscrepancyTrackWhereInput>;
};

export type QueryTradesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TradeOrder>;
  where?: InputMaybe<TradeWhereInput>;
};

export type QueryTrustedContactUpdateRequestsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TrustedContactUpdateRequestOrder>;
  where?: InputMaybe<TrustedContactUpdateRequestWhereInput>;
};

export type QueryTrustedContactsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TrustedContactWhereInput>;
};

export type QueryUserAccountPortfolioAssetsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserAccountPortfolioAssetWhereInput>;
};

export type QueryUserAccountPortfoliosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserAccountPortfolioWhereInput>;
};

export type QueryUserAccountRestrictionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserAccountRestrictionOrder>;
  where?: InputMaybe<UserAccountRestrictionWhereInput>;
};

export type QueryUserAccountsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserAccountOrder>;
  where?: InputMaybe<UserAccountWhereInput>;
};

export type QueryUserDocumentSetsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserDocumentSetOrder>;
  where?: InputMaybe<UserDocumentSetWhereInput>;
};

export type QueryUserDocumentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserDocumentOrder>;
  where?: InputMaybe<UserDocumentWhereInput>;
};

export type QueryUserProfileUpdateRequestsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserProfileUpdateRequestOrder>;
  where?: InputMaybe<UserProfileUpdateRequestWhereInput>;
};

export type QueryUserProfilesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserProfileOrder>;
  where?: InputMaybe<UserProfileWhereInput>;
};

export type QueryUserRetirementContributionsArgs = {
  where?: InputMaybe<UserRetirementContributionsWhereInput>;
};

export type QueryUserSettingsArgs = {
  where?: InputMaybe<UserSettingsWhereInput>;
};

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserOrder>;
  where?: InputMaybe<UserWhereInput>;
};

export type QueryWatchlistsArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type RtqLevelScoreRange = {
  __typename?: 'RTQLevelScoreRange';
  max: Scalars['Int']['output'];
  min: Scalars['Int']['output'];
};

export type RtqPortfolioLevel = Node & {
  __typename?: 'RTQPortfolioLevel';
  assignments?: Maybe<Array<RtqPortfolioLevelModelAssignment>>;
  createTime: Scalars['Time']['output'];
  /** Brief summary of the portfolio level */
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Ordered index of the portfolio level */
  index: Scalars['Int']['output'];
  /** Display name of the portfolio level */
  name: Scalars['String']['output'];
  portfolioSet: RtqPortfolioLevelSet;
};

export type RtqPortfolioLevelAssignmentsArgs = {
  includeInactive?: Scalars['Boolean']['input'];
};

export type RtqPortfolioLevelModelAssignment = Node & {
  __typename?: 'RTQPortfolioLevelModelAssignment';
  createTime: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  /** Returns true if the portfolio level model assignment is currently active one */
  isActive?: Maybe<Scalars['Boolean']['output']>;
  modelPortfolio: ModelPortfolio;
  portfolioLevel: RtqPortfolioLevel;
};

/**
 * RTQPortfolioLevelModelAssignmentWhereInput is used for filtering RTQPortfolioLevelModelAssignment objects.
 * Input was generated by ent.
 */
export type RtqPortfolioLevelModelAssignmentWhereInput = {
  and?: InputMaybe<Array<RtqPortfolioLevelModelAssignmentWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** model_portfolio edge predicates */
  hasModelPortfolio?: InputMaybe<Scalars['Boolean']['input']>;
  hasModelPortfolioWith?: InputMaybe<Array<ModelPortfolioWhereInput>>;
  /** portfolio_level edge predicates */
  hasPortfolioLevel?: InputMaybe<Scalars['Boolean']['input']>;
  hasPortfolioLevelWith?: InputMaybe<Array<RtqPortfolioLevelWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_active field predicates */
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isActiveIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  isActiveNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  isActiveNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<RtqPortfolioLevelModelAssignmentWhereInput>;
  or?: InputMaybe<Array<RtqPortfolioLevelModelAssignmentWhereInput>>;
};

export type RtqPortfolioLevelScore = {
  __typename?: 'RTQPortfolioLevelScore';
  /** The risk level that applies to the scores. */
  level: RtqPortfolioLevel;
  /** The range of scores for risk capacity that the portfolio level is applicable for. */
  riskCapacity: RtqLevelScoreRange;
  /** The range of scores for risk tolerance that the portfolio level is applicable for. */
  riskTolerance: RtqLevelScoreRange;
};

export type RtqPortfolioLevelSet = Node & {
  __typename?: 'RTQPortfolioLevelSet';
  createTime: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  levels?: Maybe<Array<RtqPortfolioLevel>>;
};

/** A connection to a list of items. */
export type RtqPortfolioLevelSetConnection = {
  __typename?: 'RTQPortfolioLevelSetConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RtqPortfolioLevelSetEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RtqPortfolioLevelSetEdge = {
  __typename?: 'RTQPortfolioLevelSetEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<RtqPortfolioLevelSet>;
};

/**
 * RTQPortfolioLevelSetWhereInput is used for filtering RTQPortfolioLevelSet objects.
 * Input was generated by ent.
 */
export type RtqPortfolioLevelSetWhereInput = {
  and?: InputMaybe<Array<RtqPortfolioLevelSetWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** levels edge predicates */
  hasLevels?: InputMaybe<Scalars['Boolean']['input']>;
  hasLevelsWith?: InputMaybe<Array<RtqPortfolioLevelWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<RtqPortfolioLevelSetWhereInput>;
  or?: InputMaybe<Array<RtqPortfolioLevelSetWhereInput>>;
};

/**
 * RTQPortfolioLevelWhereInput is used for filtering RTQPortfolioLevel objects.
 * Input was generated by ent.
 */
export type RtqPortfolioLevelWhereInput = {
  and?: InputMaybe<Array<RtqPortfolioLevelWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** portfolio_set edge predicates */
  hasPortfolioSet?: InputMaybe<Scalars['Boolean']['input']>;
  hasPortfolioSetWith?: InputMaybe<Array<RtqPortfolioLevelSetWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** index field predicates */
  index?: InputMaybe<Scalars['Int']['input']>;
  indexGT?: InputMaybe<Scalars['Int']['input']>;
  indexGTE?: InputMaybe<Scalars['Int']['input']>;
  indexIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  indexLT?: InputMaybe<Scalars['Int']['input']>;
  indexLTE?: InputMaybe<Scalars['Int']['input']>;
  indexNEQ?: InputMaybe<Scalars['Int']['input']>;
  indexNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<RtqPortfolioLevelWhereInput>;
  or?: InputMaybe<Array<RtqPortfolioLevelWhereInput>>;
};

export type RtqQuestion = Node & {
  __typename?: 'RTQQuestion';
  category: RtqQuestionRtqRiskCategory;
  createTime: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  /** Display text of the RTQ question */
  label: Scalars['String']['output'];
  options?: Maybe<Array<RtqResponseOption>>;
  questionnaire: RtqQuestionnaire;
  type: RtqQuestionRtqQuestionType;
};

/** RTQQuestionRTQQuestionType is enum for the field type */
export enum RtqQuestionRtqQuestionType {
  RtqQuestionTypeSingleSelection = 'RTQ_QUESTION_TYPE_SINGLE_SELECTION',
}

/** RTQQuestionRTQRiskCategory is enum for the field category */
export enum RtqQuestionRtqRiskCategory {
  RtqRiskCategoryRiskCapacity = 'RTQ_RISK_CATEGORY_RISK_CAPACITY',
  RtqRiskCategoryRiskTolerance = 'RTQ_RISK_CATEGORY_RISK_TOLERANCE',
}

/**
 * RTQQuestionWhereInput is used for filtering RTQQuestion objects.
 * Input was generated by ent.
 */
export type RtqQuestionWhereInput = {
  and?: InputMaybe<Array<RtqQuestionWhereInput>>;
  /** category field predicates */
  category?: InputMaybe<RtqQuestionRtqRiskCategory>;
  categoryIn?: InputMaybe<Array<RtqQuestionRtqRiskCategory>>;
  categoryNEQ?: InputMaybe<RtqQuestionRtqRiskCategory>;
  categoryNotIn?: InputMaybe<Array<RtqQuestionRtqRiskCategory>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** options edge predicates */
  hasOptions?: InputMaybe<Scalars['Boolean']['input']>;
  hasOptionsWith?: InputMaybe<Array<RtqResponseOptionWhereInput>>;
  /** questionnaire edge predicates */
  hasQuestionnaire?: InputMaybe<Scalars['Boolean']['input']>;
  hasQuestionnaireWith?: InputMaybe<Array<RtqQuestionnaireWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** label field predicates */
  label?: InputMaybe<Scalars['String']['input']>;
  labelContains?: InputMaybe<Scalars['String']['input']>;
  labelContainsFold?: InputMaybe<Scalars['String']['input']>;
  labelEqualFold?: InputMaybe<Scalars['String']['input']>;
  labelGT?: InputMaybe<Scalars['String']['input']>;
  labelGTE?: InputMaybe<Scalars['String']['input']>;
  labelHasPrefix?: InputMaybe<Scalars['String']['input']>;
  labelHasSuffix?: InputMaybe<Scalars['String']['input']>;
  labelIn?: InputMaybe<Array<Scalars['String']['input']>>;
  labelLT?: InputMaybe<Scalars['String']['input']>;
  labelLTE?: InputMaybe<Scalars['String']['input']>;
  labelNEQ?: InputMaybe<Scalars['String']['input']>;
  labelNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<RtqQuestionWhereInput>;
  or?: InputMaybe<Array<RtqQuestionWhereInput>>;
  /** type field predicates */
  type?: InputMaybe<RtqQuestionRtqQuestionType>;
  typeIn?: InputMaybe<Array<RtqQuestionRtqQuestionType>>;
  typeNEQ?: InputMaybe<RtqQuestionRtqQuestionType>;
  typeNotIn?: InputMaybe<Array<RtqQuestionRtqQuestionType>>;
};

export type RtqQuestionnaire = Node & {
  __typename?: 'RTQQuestionnaire';
  createTime: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  /** Returns true if the RTQ questionnaire is the current one display to client during onboarding */
  isActive?: Maybe<Scalars['Boolean']['output']>;
  /** List of risk category score range mappings to portfolio level */
  portfolioLevelScores: Array<RtqPortfolioLevelScore>;
  questions?: Maybe<Array<RtqQuestion>>;
};

/** A connection to a list of items. */
export type RtqQuestionnaireConnection = {
  __typename?: 'RTQQuestionnaireConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RtqQuestionnaireEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RtqQuestionnaireEdge = {
  __typename?: 'RTQQuestionnaireEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<RtqQuestionnaire>;
};

/**
 * RTQQuestionnaireWhereInput is used for filtering RTQQuestionnaire objects.
 * Input was generated by ent.
 */
export type RtqQuestionnaireWhereInput = {
  and?: InputMaybe<Array<RtqQuestionnaireWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** questions edge predicates */
  hasQuestions?: InputMaybe<Scalars['Boolean']['input']>;
  hasQuestionsWith?: InputMaybe<Array<RtqQuestionWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_active field predicates */
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isActiveIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  isActiveNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  isActiveNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<RtqQuestionnaireWhereInput>;
  or?: InputMaybe<Array<RtqQuestionnaireWhereInput>>;
};

export type RtqResponse = Node & {
  __typename?: 'RTQResponse';
  createTime: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  option: RtqResponseOption;
  question: RtqQuestion;
  submission: RtqSubmission;
};

export type RtqResponseOption = Node & {
  __typename?: 'RTQResponseOption';
  createTime: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  /** Display text of the RTQ Answer */
  label: Scalars['String']['output'];
  question: RtqQuestion;
  /** Score given to RTQ answer against the risk category of the associated question */
  score: Scalars['Int']['output'];
};

/**
 * RTQResponseOptionWhereInput is used for filtering RTQResponseOption objects.
 * Input was generated by ent.
 */
export type RtqResponseOptionWhereInput = {
  and?: InputMaybe<Array<RtqResponseOptionWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** question edge predicates */
  hasQuestion?: InputMaybe<Scalars['Boolean']['input']>;
  hasQuestionWith?: InputMaybe<Array<RtqQuestionWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** label field predicates */
  label?: InputMaybe<Scalars['String']['input']>;
  labelContains?: InputMaybe<Scalars['String']['input']>;
  labelContainsFold?: InputMaybe<Scalars['String']['input']>;
  labelEqualFold?: InputMaybe<Scalars['String']['input']>;
  labelGT?: InputMaybe<Scalars['String']['input']>;
  labelGTE?: InputMaybe<Scalars['String']['input']>;
  labelHasPrefix?: InputMaybe<Scalars['String']['input']>;
  labelHasSuffix?: InputMaybe<Scalars['String']['input']>;
  labelIn?: InputMaybe<Array<Scalars['String']['input']>>;
  labelLT?: InputMaybe<Scalars['String']['input']>;
  labelLTE?: InputMaybe<Scalars['String']['input']>;
  labelNEQ?: InputMaybe<Scalars['String']['input']>;
  labelNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<RtqResponseOptionWhereInput>;
  or?: InputMaybe<Array<RtqResponseOptionWhereInput>>;
  /** score field predicates */
  score?: InputMaybe<Scalars['Int']['input']>;
  scoreGT?: InputMaybe<Scalars['Int']['input']>;
  scoreGTE?: InputMaybe<Scalars['Int']['input']>;
  scoreIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  scoreLT?: InputMaybe<Scalars['Int']['input']>;
  scoreLTE?: InputMaybe<Scalars['Int']['input']>;
  scoreNEQ?: InputMaybe<Scalars['Int']['input']>;
  scoreNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/**
 * RTQResponseWhereInput is used for filtering RTQResponse objects.
 * Input was generated by ent.
 */
export type RtqResponseWhereInput = {
  and?: InputMaybe<Array<RtqResponseWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** option edge predicates */
  hasOption?: InputMaybe<Scalars['Boolean']['input']>;
  hasOptionWith?: InputMaybe<Array<RtqResponseOptionWhereInput>>;
  /** question edge predicates */
  hasQuestion?: InputMaybe<Scalars['Boolean']['input']>;
  hasQuestionWith?: InputMaybe<Array<RtqQuestionWhereInput>>;
  /** submission edge predicates */
  hasSubmission?: InputMaybe<Scalars['Boolean']['input']>;
  hasSubmissionWith?: InputMaybe<Array<RtqSubmissionWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<RtqResponseWhereInput>;
  or?: InputMaybe<Array<RtqResponseWhereInput>>;
};

export type RtqSubmission = Node & {
  __typename?: 'RTQSubmission';
  createTime: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  /** Returns true if the RTQ submission is currently active one */
  isActive?: Maybe<Scalars['Boolean']['output']>;
  questionnaire: RtqQuestionnaire;
  responses?: Maybe<Array<RtqResponse>>;
  user: User;
};

/** A connection to a list of items. */
export type RtqSubmissionConnection = {
  __typename?: 'RTQSubmissionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RtqSubmissionEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RtqSubmissionEdge = {
  __typename?: 'RTQSubmissionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<RtqSubmission>;
};

/**
 * RTQSubmissionWhereInput is used for filtering RTQSubmission objects.
 * Input was generated by ent.
 */
export type RtqSubmissionWhereInput = {
  and?: InputMaybe<Array<RtqSubmissionWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** questionnaire edge predicates */
  hasQuestionnaire?: InputMaybe<Scalars['Boolean']['input']>;
  hasQuestionnaireWith?: InputMaybe<Array<RtqQuestionnaireWhereInput>>;
  /** responses edge predicates */
  hasResponses?: InputMaybe<Scalars['Boolean']['input']>;
  hasResponsesWith?: InputMaybe<Array<RtqResponseWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_active field predicates */
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isActiveIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  isActiveNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  isActiveNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<RtqSubmissionWhereInput>;
  or?: InputMaybe<Array<RtqSubmissionWhereInput>>;
};

export type RecurringRule = Node & {
  __typename?: 'RecurringRule';
  /** Amount in USD to use in rule. */
  amount: Scalars['Decimal']['output'];
  /** asset that is being bought in the rule. */
  asset: BaseAsset;
  createTime: Scalars['Time']['output'];
  end?: Maybe<Scalars['Date']['output']>;
  fundingAccount: ExternalAccount;
  fundingMethod: RecurringRuleFundingMethod;
  id: Scalars['ID']['output'];
  /** Instances of the rule. */
  instances?: Maybe<Array<RecurringRuleInstance>>;
  interval: RecurringRuleRecurringRuleInterval;
  isActive: Scalars['Boolean']['output'];
  /** Amount in USD that the rule has invested. */
  lifetimeAmount: Scalars['Decimal']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** Defines the next day the rule will trigger. */
  next?: Maybe<Scalars['Date']['output']>;
  /**
   * nextSkipped is the expected date that the next execution will occur incorporating
   * any skipped executions.
   */
  nextSkipped?: Maybe<Scalars['Date']['output']>;
  /** Stores the next date the rule would be executed ignoring market/banking closures. */
  nextTarget?: Maybe<Scalars['Date']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  onHold: Scalars['Boolean']['output'];
  /** Portfolio to apply the rule to. */
  portfolio: UserAccountPortfolio;
  reason?: Maybe<Scalars['String']['output']>;
  requestor: RecurringRuleRequestor;
  sector?: Maybe<RepeatInvestmentSector>;
  /** skip requests for the rule. */
  skipRequests?: Maybe<Array<RecurringRuleSkipRequest>>;
  start: Scalars['Date']['output'];
  type: RecurringRuleType;
  updateTime: Scalars['Time']['output'];
  updates: Array<RecurringRuleUpdateLog>;
  user: User;
};

export type RecurringRuleSkipRequestsArgs = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A connection to a list of items. */
export type RecurringRuleConnection = {
  __typename?: 'RecurringRuleConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RecurringRuleEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RecurringRuleEdge = {
  __typename?: 'RecurringRuleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<RecurringRule>;
};

/** RecurringRuleFundingMethod is enum for the field funding_method */
export enum RecurringRuleFundingMethod {
  FundingMethodAlwaysDeposit = 'FUNDING_METHOD_ALWAYS_DEPOSIT',
  FundingMethodDepositIfNeeded = 'FUNDING_METHOD_DEPOSIT_IF_NEEDED',
  FundingMethodNeverDeposit = 'FUNDING_METHOD_NEVER_DEPOSIT',
}

export type RecurringRuleInstance = BaseOrder &
  Node & {
    __typename?: 'RecurringRuleInstance';
    achTransfer?: Maybe<AchTransfer>;
    /** Amount in USD triggered to pass through the rule. Note: may differ from the rule if the rule amount was modified. */
    amount: Scalars['Decimal']['output'];
    createTime: Scalars['Time']['output'];
    /** this is the date that rule should run */
    executionDate: Scalars['Time']['output'];
    expectedTradeDate?: Maybe<Scalars['Date']['output']>;
    failureStage?: Maybe<RecurringRuleInstanceFailureStage>;
    ictTransfer?: Maybe<IctTransfer>;
    id: Scalars['ID']['output'];
    /** Notes is the notes of the order intended for the principal. */
    note?: Maybe<Scalars['String']['output']>;
    orderStatus: OrderOrderStatus;
    orderType: OrderOrderType;
    reason?: Maybe<Scalars['String']['output']>;
    requestDetails: RequestDetails;
    /** If true, this instance requires a trade to be executed. */
    requiresTrade: Scalars['Boolean']['output'];
    rule: RecurringRule;
    status: RecurringRuleInstanceOrderStatus;
    statusChangeLog: Array<RecurringRuleInstanceStatusChangeLog>;
    trade?: Maybe<Array<Trade>>;
    tradeDate?: Maybe<Scalars['Time']['output']>;
    transferDate?: Maybe<Scalars['Time']['output']>;
    updateTime: Scalars['Time']['output'];
    user: User;
  };

export type RecurringRuleInstanceStatusChangeLogArgs = {
  status?: InputMaybe<OrderOrderStatus>;
  statusIn?: InputMaybe<Array<OrderOrderStatus>>;
};

/** A connection to a list of items. */
export type RecurringRuleInstanceConnection = {
  __typename?: 'RecurringRuleInstanceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RecurringRuleInstanceEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RecurringRuleInstanceEdge = {
  __typename?: 'RecurringRuleInstanceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<RecurringRuleInstance>;
};

/** RecurringRuleInstanceFailureStage is enum for the field failure_stage */
export enum RecurringRuleInstanceFailureStage {
  TradeFailed = 'TRADE_FAILED',
  TransferFailed = 'TRANSFER_FAILED',
}

/** Ordering options for RecurringRuleInstance connections */
export type RecurringRuleInstanceOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order RecurringRuleInstances. */
  field: RecurringRuleInstanceOrderField;
};

/** Properties by which RecurringRuleInstance connections can be ordered. */
export enum RecurringRuleInstanceOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/** RecurringRuleInstanceOrderStatus is enum for the field status */
export enum RecurringRuleInstanceOrderStatus {
  OrderStatusCanceled = 'ORDER_STATUS_CANCELED',
  OrderStatusCanceling = 'ORDER_STATUS_CANCELING',
  OrderStatusDone = 'ORDER_STATUS_DONE',
  OrderStatusFailed = 'ORDER_STATUS_FAILED',
  OrderStatusFinalizing = 'ORDER_STATUS_FINALIZING',
  OrderStatusProcessing = 'ORDER_STATUS_PROCESSING',
}

export type RecurringRuleInstanceStatusChangeLog = {
  __typename?: 'RecurringRuleInstanceStatusChangeLog';
  eventTime: Scalars['Time']['output'];
  note: Scalars['String']['output'];
  status: OrderOrderStatus;
};

/**
 * RecurringRuleInstanceWhereInput is used for filtering RecurringRuleInstance objects.
 * Input was generated by ent.
 */
export type RecurringRuleInstanceWhereInput = {
  /** amount field predicates */
  amount?: InputMaybe<Scalars['Decimal']['input']>;
  amountGT?: InputMaybe<Scalars['Decimal']['input']>;
  amountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  amountLT?: InputMaybe<Scalars['Decimal']['input']>;
  amountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  amountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  and?: InputMaybe<Array<RecurringRuleInstanceWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** execution_date field predicates */
  executionDate?: InputMaybe<Scalars['Time']['input']>;
  executionDateGT?: InputMaybe<Scalars['Time']['input']>;
  executionDateGTE?: InputMaybe<Scalars['Time']['input']>;
  executionDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  executionDateLT?: InputMaybe<Scalars['Time']['input']>;
  executionDateLTE?: InputMaybe<Scalars['Time']['input']>;
  executionDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  executionDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** expected_trade_date field predicates */
  expectedTradeDate?: InputMaybe<Scalars['Date']['input']>;
  expectedTradeDateGT?: InputMaybe<Scalars['Date']['input']>;
  expectedTradeDateGTE?: InputMaybe<Scalars['Date']['input']>;
  expectedTradeDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  expectedTradeDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  expectedTradeDateLT?: InputMaybe<Scalars['Date']['input']>;
  expectedTradeDateLTE?: InputMaybe<Scalars['Date']['input']>;
  expectedTradeDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  expectedTradeDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  expectedTradeDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** failure_stage field predicates */
  failureStage?: InputMaybe<RecurringRuleInstanceFailureStage>;
  failureStageIn?: InputMaybe<Array<RecurringRuleInstanceFailureStage>>;
  failureStageIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  failureStageNEQ?: InputMaybe<RecurringRuleInstanceFailureStage>;
  failureStageNotIn?: InputMaybe<Array<RecurringRuleInstanceFailureStage>>;
  failureStageNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** ach_transfer edge predicates */
  hasAchTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  hasAchTransferWith?: InputMaybe<Array<AchTransferWhereInput>>;
  /** ict_transfer edge predicates */
  hasIctTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  hasIctTransferWith?: InputMaybe<Array<IctTransferWhereInput>>;
  /** rule edge predicates */
  hasRule?: InputMaybe<Scalars['Boolean']['input']>;
  hasRuleWith?: InputMaybe<Array<RecurringRuleWhereInput>>;
  /** trade edge predicates */
  hasTrade?: InputMaybe<Scalars['Boolean']['input']>;
  hasTradeWith?: InputMaybe<Array<TradeWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<RecurringRuleInstanceWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<RecurringRuleInstanceWhereInput>>;
  /** reason field predicates */
  reason?: InputMaybe<Scalars['String']['input']>;
  reasonContains?: InputMaybe<Scalars['String']['input']>;
  reasonContainsFold?: InputMaybe<Scalars['String']['input']>;
  reasonEqualFold?: InputMaybe<Scalars['String']['input']>;
  reasonGT?: InputMaybe<Scalars['String']['input']>;
  reasonGTE?: InputMaybe<Scalars['String']['input']>;
  reasonHasPrefix?: InputMaybe<Scalars['String']['input']>;
  reasonHasSuffix?: InputMaybe<Scalars['String']['input']>;
  reasonIn?: InputMaybe<Array<Scalars['String']['input']>>;
  reasonIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  reasonLT?: InputMaybe<Scalars['String']['input']>;
  reasonLTE?: InputMaybe<Scalars['String']['input']>;
  reasonNEQ?: InputMaybe<Scalars['String']['input']>;
  reasonNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  reasonNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** requires_trade field predicates */
  requiresTrade?: InputMaybe<Scalars['Boolean']['input']>;
  requiresTradeNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** status field predicates */
  status?: InputMaybe<RecurringRuleInstanceOrderStatus>;
  statusIn?: InputMaybe<Array<RecurringRuleInstanceOrderStatus>>;
  statusNEQ?: InputMaybe<RecurringRuleInstanceOrderStatus>;
  statusNotIn?: InputMaybe<Array<RecurringRuleInstanceOrderStatus>>;
  /** trade_date field predicates */
  tradeDate?: InputMaybe<Scalars['Time']['input']>;
  tradeDateGT?: InputMaybe<Scalars['Time']['input']>;
  tradeDateGTE?: InputMaybe<Scalars['Time']['input']>;
  tradeDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  tradeDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  tradeDateLT?: InputMaybe<Scalars['Time']['input']>;
  tradeDateLTE?: InputMaybe<Scalars['Time']['input']>;
  tradeDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  tradeDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  tradeDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** transfer_date field predicates */
  transferDate?: InputMaybe<Scalars['Time']['input']>;
  transferDateGT?: InputMaybe<Scalars['Time']['input']>;
  transferDateGTE?: InputMaybe<Scalars['Time']['input']>;
  transferDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  transferDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  transferDateLT?: InputMaybe<Scalars['Time']['input']>;
  transferDateLTE?: InputMaybe<Scalars['Time']['input']>;
  transferDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  transferDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  transferDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** Ordering options for RecurringRule connections */
export type RecurringRuleOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order RecurringRules. */
  field: RecurringRuleOrderField;
};

/** Properties by which RecurringRule connections can be ordered. */
export enum RecurringRuleOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/** RecurringRuleRecurringRuleInterval is enum for the field interval */
export enum RecurringRuleRecurringRuleInterval {
  RecurringRuleIntervalEveryFourWeeks = 'RECURRING_RULE_INTERVAL_EVERY_FOUR_WEEKS',
  RecurringRuleIntervalEveryMonth = 'RECURRING_RULE_INTERVAL_EVERY_MONTH',
  RecurringRuleIntervalEveryThreeMonths = 'RECURRING_RULE_INTERVAL_EVERY_THREE_MONTHS',
  RecurringRuleIntervalEveryTwoMonths = 'RECURRING_RULE_INTERVAL_EVERY_TWO_MONTHS',
  RecurringRuleIntervalEveryTwoWeeks = 'RECURRING_RULE_INTERVAL_EVERY_TWO_WEEKS',
  RecurringRuleIntervalEveryWeek = 'RECURRING_RULE_INTERVAL_EVERY_WEEK',
  RecurringRuleIntervalEveryYear = 'RECURRING_RULE_INTERVAL_EVERY_YEAR',
  RecurringRuleIntervalOnce = 'RECURRING_RULE_INTERVAL_ONCE',
  RecurringRuleIntervalSixMonths = 'RECURRING_RULE_INTERVAL_SIX_MONTHS',
  RecurringRuleIntervalTwiceEveryMonth = 'RECURRING_RULE_INTERVAL_TWICE_EVERY_MONTH',
}

/** RecurringRuleRequestor is enum for the field requestor */
export enum RecurringRuleRequestor {
  RequestorAdmin = 'REQUESTOR_ADMIN',
  RequestorPrincipal = 'REQUESTOR_PRINCIPAL',
  RequestorRobo = 'REQUESTOR_ROBO',
  RequestorScheduler = 'REQUESTOR_SCHEDULER',
  RequestorSystem = 'REQUESTOR_SYSTEM',
  RequestorUser = 'REQUESTOR_USER',
}

export type RecurringRuleSkipRequest = Node & {
  __typename?: 'RecurringRuleSkipRequest';
  /** Number of times to skip. */
  count: Scalars['Int']['output'];
  createTime: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  requestID: Scalars['UUID']['output'];
  rule: RecurringRule;
  /** Number of times skipped. */
  skipped: Scalars['Int']['output'];
  updateTime: Scalars['Time']['output'];
  user: User;
};

/** Ordering options for RecurringRuleSkipRequest connections */
export type RecurringRuleSkipRequestOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order RecurringRuleSkipRequests. */
  field: RecurringRuleSkipRequestOrderField;
};

/** Properties by which RecurringRuleSkipRequest connections can be ordered. */
export enum RecurringRuleSkipRequestOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * RecurringRuleSkipRequestWhereInput is used for filtering RecurringRuleSkipRequest objects.
 * Input was generated by ent.
 */
export type RecurringRuleSkipRequestWhereInput = {
  and?: InputMaybe<Array<RecurringRuleSkipRequestWhereInput>>;
  /** count field predicates */
  count?: InputMaybe<Scalars['Int']['input']>;
  countGT?: InputMaybe<Scalars['Int']['input']>;
  countGTE?: InputMaybe<Scalars['Int']['input']>;
  countIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  countLT?: InputMaybe<Scalars['Int']['input']>;
  countLTE?: InputMaybe<Scalars['Int']['input']>;
  countNEQ?: InputMaybe<Scalars['Int']['input']>;
  countNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** rule edge predicates */
  hasRule?: InputMaybe<Scalars['Boolean']['input']>;
  hasRuleWith?: InputMaybe<Array<RecurringRuleWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_active field predicates */
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isActiveNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<RecurringRuleSkipRequestWhereInput>;
  or?: InputMaybe<Array<RecurringRuleSkipRequestWhereInput>>;
  /** request_id field predicates */
  requestID?: InputMaybe<Scalars['UUID']['input']>;
  requestIDGT?: InputMaybe<Scalars['UUID']['input']>;
  requestIDGTE?: InputMaybe<Scalars['UUID']['input']>;
  requestIDIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestIDLT?: InputMaybe<Scalars['UUID']['input']>;
  requestIDLTE?: InputMaybe<Scalars['UUID']['input']>;
  requestIDNEQ?: InputMaybe<Scalars['UUID']['input']>;
  requestIDNotIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** skipped field predicates */
  skipped?: InputMaybe<Scalars['Int']['input']>;
  skippedGT?: InputMaybe<Scalars['Int']['input']>;
  skippedGTE?: InputMaybe<Scalars['Int']['input']>;
  skippedIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  skippedLT?: InputMaybe<Scalars['Int']['input']>;
  skippedLTE?: InputMaybe<Scalars['Int']['input']>;
  skippedNEQ?: InputMaybe<Scalars['Int']['input']>;
  skippedNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** RecurringRuleType is enum for the field type */
export enum RecurringRuleType {
  MasterBudget = 'MASTER_BUDGET',
  MealCalculator = 'MEAL_CALCULATOR',
  RoboInfusion = 'ROBO_INFUSION',
  User = 'USER',
}

export type RecurringRuleUpdateChanges = {
  __typename?: 'RecurringRuleUpdateChanges';
  field: Scalars['String']['output'];
  newValue: Scalars['String']['output'];
  oldValue: Scalars['String']['output'];
};

export type RecurringRuleUpdateLog = {
  __typename?: 'RecurringRuleUpdateLog';
  createTime: Scalars['Time']['output'];
  diff: Array<RecurringRuleUpdateChanges>;
  requestor: Requestor;
  updateTime: Scalars['Time']['output'];
};

/**
 * RecurringRuleWhereInput is used for filtering RecurringRule objects.
 * Input was generated by ent.
 */
export type RecurringRuleWhereInput = {
  /** amount field predicates */
  amount?: InputMaybe<Scalars['Decimal']['input']>;
  amountGT?: InputMaybe<Scalars['Decimal']['input']>;
  amountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  amountLT?: InputMaybe<Scalars['Decimal']['input']>;
  amountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  amountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  and?: InputMaybe<Array<RecurringRuleWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** end field predicates */
  end?: InputMaybe<Scalars['Date']['input']>;
  endGT?: InputMaybe<Scalars['Date']['input']>;
  endGTE?: InputMaybe<Scalars['Date']['input']>;
  endIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  endIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  endLT?: InputMaybe<Scalars['Date']['input']>;
  endLTE?: InputMaybe<Scalars['Date']['input']>;
  endNEQ?: InputMaybe<Scalars['Date']['input']>;
  endNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  endNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** funding_method field predicates */
  fundingMethod?: InputMaybe<RecurringRuleFundingMethod>;
  fundingMethodIn?: InputMaybe<Array<RecurringRuleFundingMethod>>;
  fundingMethodNEQ?: InputMaybe<RecurringRuleFundingMethod>;
  fundingMethodNotIn?: InputMaybe<Array<RecurringRuleFundingMethod>>;
  hasAsset?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssetWith?: InputMaybe<BaseAssetWhereInput>;
  /** funding_account edge predicates */
  hasFundingAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasFundingAccountWith?: InputMaybe<Array<ExternalAccountWhereInput>>;
  /** instances edge predicates */
  hasInstances?: InputMaybe<Scalars['Boolean']['input']>;
  hasInstancesWith?: InputMaybe<Array<RecurringRuleInstanceWhereInput>>;
  /** portfolio edge predicates */
  hasPortfolio?: InputMaybe<Scalars['Boolean']['input']>;
  hasPortfolioWith?: InputMaybe<Array<UserAccountPortfolioWhereInput>>;
  /** sector edge predicates */
  hasSector?: InputMaybe<Scalars['Boolean']['input']>;
  hasSectorWith?: InputMaybe<Array<RepeatInvestmentSectorWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** interval field predicates */
  interval?: InputMaybe<RecurringRuleRecurringRuleInterval>;
  intervalIn?: InputMaybe<Array<RecurringRuleRecurringRuleInterval>>;
  intervalNEQ?: InputMaybe<RecurringRuleRecurringRuleInterval>;
  intervalNotIn?: InputMaybe<Array<RecurringRuleRecurringRuleInterval>>;
  /** is_active field predicates */
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isActiveNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** lifetime_amount field predicates */
  lifetimeAmount?: InputMaybe<Scalars['Decimal']['input']>;
  lifetimeAmountGT?: InputMaybe<Scalars['Decimal']['input']>;
  lifetimeAmountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  lifetimeAmountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lifetimeAmountLT?: InputMaybe<Scalars['Decimal']['input']>;
  lifetimeAmountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  lifetimeAmountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  lifetimeAmountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** next field predicates */
  next?: InputMaybe<Scalars['Date']['input']>;
  nextGT?: InputMaybe<Scalars['Date']['input']>;
  nextGTE?: InputMaybe<Scalars['Date']['input']>;
  nextIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  nextIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  nextLT?: InputMaybe<Scalars['Date']['input']>;
  nextLTE?: InputMaybe<Scalars['Date']['input']>;
  nextNEQ?: InputMaybe<Scalars['Date']['input']>;
  nextNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  nextNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** next_target field predicates */
  nextTarget?: InputMaybe<Scalars['Date']['input']>;
  nextTargetGT?: InputMaybe<Scalars['Date']['input']>;
  nextTargetGTE?: InputMaybe<Scalars['Date']['input']>;
  nextTargetIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  nextTargetIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  nextTargetLT?: InputMaybe<Scalars['Date']['input']>;
  nextTargetLTE?: InputMaybe<Scalars['Date']['input']>;
  nextTargetNEQ?: InputMaybe<Scalars['Date']['input']>;
  nextTargetNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  nextTargetNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<RecurringRuleWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** on_hold field predicates */
  onHold?: InputMaybe<Scalars['Boolean']['input']>;
  onHoldNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<RecurringRuleWhereInput>>;
  /** reason field predicates */
  reason?: InputMaybe<Scalars['String']['input']>;
  reasonContains?: InputMaybe<Scalars['String']['input']>;
  reasonContainsFold?: InputMaybe<Scalars['String']['input']>;
  reasonEqualFold?: InputMaybe<Scalars['String']['input']>;
  reasonGT?: InputMaybe<Scalars['String']['input']>;
  reasonGTE?: InputMaybe<Scalars['String']['input']>;
  reasonHasPrefix?: InputMaybe<Scalars['String']['input']>;
  reasonHasSuffix?: InputMaybe<Scalars['String']['input']>;
  reasonIn?: InputMaybe<Array<Scalars['String']['input']>>;
  reasonIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  reasonLT?: InputMaybe<Scalars['String']['input']>;
  reasonLTE?: InputMaybe<Scalars['String']['input']>;
  reasonNEQ?: InputMaybe<Scalars['String']['input']>;
  reasonNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  reasonNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** requestor field predicates */
  requestor?: InputMaybe<RecurringRuleRequestor>;
  requestorIn?: InputMaybe<Array<RecurringRuleRequestor>>;
  requestorNEQ?: InputMaybe<RecurringRuleRequestor>;
  requestorNotIn?: InputMaybe<Array<RecurringRuleRequestor>>;
  /** start field predicates */
  start?: InputMaybe<Scalars['Date']['input']>;
  startGT?: InputMaybe<Scalars['Date']['input']>;
  startGTE?: InputMaybe<Scalars['Date']['input']>;
  startIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  startLT?: InputMaybe<Scalars['Date']['input']>;
  startLTE?: InputMaybe<Scalars['Date']['input']>;
  startNEQ?: InputMaybe<Scalars['Date']['input']>;
  startNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  /** type field predicates */
  type?: InputMaybe<RecurringRuleType>;
  typeIn?: InputMaybe<Array<RecurringRuleType>>;
  typeNEQ?: InputMaybe<RecurringRuleType>;
  typeNotIn?: InputMaybe<Array<RecurringRuleType>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type ReferralRecord = Node & {
  __typename?: 'ReferralRecord';
  code: Scalars['String']['output'];
  createTime: Scalars['Time']['output'];
  friend: User;
  id: Scalars['ID']['output'];
  reward?: Maybe<FeeAndCreditOrder>;
  status: ReferralRecordStatus;
  updateTime: Scalars['Time']['output'];
  user: User;
};

/** A connection to a list of items. */
export type ReferralRecordConnection = {
  __typename?: 'ReferralRecordConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ReferralRecordEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ReferralRecordEdge = {
  __typename?: 'ReferralRecordEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ReferralRecord>;
};

/** Ordering options for ReferralRecord connections */
export type ReferralRecordOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ReferralRecords. */
  field: ReferralRecordOrderField;
};

/** Properties by which ReferralRecord connections can be ordered. */
export enum ReferralRecordOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/** ReferralRecordStatus is enum for the field status */
export enum ReferralRecordStatus {
  Complete = 'COMPLETE',
  Failed = 'FAILED',
  Inactive = 'INACTIVE',
  Pending = 'PENDING',
}

/**
 * ReferralRecordWhereInput is used for filtering ReferralRecord objects.
 * Input was generated by ent.
 */
export type ReferralRecordWhereInput = {
  and?: InputMaybe<Array<ReferralRecordWhereInput>>;
  /** code field predicates */
  code?: InputMaybe<Scalars['String']['input']>;
  codeContains?: InputMaybe<Scalars['String']['input']>;
  codeContainsFold?: InputMaybe<Scalars['String']['input']>;
  codeEqualFold?: InputMaybe<Scalars['String']['input']>;
  codeGT?: InputMaybe<Scalars['String']['input']>;
  codeGTE?: InputMaybe<Scalars['String']['input']>;
  codeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  codeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  codeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  codeLT?: InputMaybe<Scalars['String']['input']>;
  codeLTE?: InputMaybe<Scalars['String']['input']>;
  codeNEQ?: InputMaybe<Scalars['String']['input']>;
  codeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** friend edge predicates */
  hasFriend?: InputMaybe<Scalars['Boolean']['input']>;
  hasFriendWith?: InputMaybe<Array<UserWhereInput>>;
  /** reward edge predicates */
  hasReward?: InputMaybe<Scalars['Boolean']['input']>;
  hasRewardWith?: InputMaybe<Array<FeeAndCreditOrderWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<ReferralRecordWhereInput>;
  or?: InputMaybe<Array<ReferralRecordWhereInput>>;
  /** status field predicates */
  status?: InputMaybe<ReferralRecordStatus>;
  statusIn?: InputMaybe<Array<ReferralRecordStatus>>;
  statusNEQ?: InputMaybe<ReferralRecordStatus>;
  statusNotIn?: InputMaybe<Array<ReferralRecordStatus>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type RegulatedEntity = Node & {
  __typename?: 'RegulatedEntity';
  advisory?: Maybe<Advisory>;
  brokerDealer?: Maybe<BrokerDealer>;
  entityType: RegulatedEntityEntityType;
  id: Scalars['ID']['output'];
  introducingBroker?: Maybe<IntroducingBroker>;
  name: Scalars['String']['output'];
};

/** RegulatedEntityEntityType is enum for the field entity_type */
export enum RegulatedEntityEntityType {
  Advisory = 'advisory',
  BrokerDealer = 'broker_dealer',
  IntroducingBroker = 'introducing_broker',
}

/**
 * RegulatedEntityWhereInput is used for filtering RegulatedEntity objects.
 * Input was generated by ent.
 */
export type RegulatedEntityWhereInput = {
  and?: InputMaybe<Array<RegulatedEntityWhereInput>>;
  /** entity_type field predicates */
  entityType?: InputMaybe<RegulatedEntityEntityType>;
  entityTypeIn?: InputMaybe<Array<RegulatedEntityEntityType>>;
  entityTypeNEQ?: InputMaybe<RegulatedEntityEntityType>;
  entityTypeNotIn?: InputMaybe<Array<RegulatedEntityEntityType>>;
  /** advisory edge predicates */
  hasAdvisory?: InputMaybe<Scalars['Boolean']['input']>;
  hasAdvisoryWith?: InputMaybe<Array<AdvisoryWhereInput>>;
  /** broker_dealer edge predicates */
  hasBrokerDealer?: InputMaybe<Scalars['Boolean']['input']>;
  hasBrokerDealerWith?: InputMaybe<Array<BrokerDealerWhereInput>>;
  /** introducing_broker edge predicates */
  hasIntroducingBroker?: InputMaybe<Scalars['Boolean']['input']>;
  hasIntroducingBrokerWith?: InputMaybe<Array<IntroducingBrokerWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<RegulatedEntityWhereInput>;
  or?: InputMaybe<Array<RegulatedEntityWhereInput>>;
};

export type RepeatInvestmentSector = Node & {
  __typename?: 'RepeatInvestmentSector';
  active: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  largeImage: Scalars['String']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  security: SecurityAsset;
  smallImage: Scalars['String']['output'];
  type: RepeatInvestmentSectorType;
};

/** RepeatInvestmentSectorType is enum for the field type */
export enum RepeatInvestmentSectorType {
  Goal = 'GOAL',
  Index = 'INDEX',
  Sector = 'SECTOR',
}

/**
 * RepeatInvestmentSectorWhereInput is used for filtering RepeatInvestmentSector objects.
 * Input was generated by ent.
 */
export type RepeatInvestmentSectorWhereInput = {
  /** active field predicates */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  activeNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<RepeatInvestmentSectorWhereInput>>;
  /** security edge predicates */
  hasSecurity?: InputMaybe<Scalars['Boolean']['input']>;
  hasSecurityWith?: InputMaybe<Array<SecurityAssetWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** large_image field predicates */
  largeImage?: InputMaybe<Scalars['String']['input']>;
  largeImageContains?: InputMaybe<Scalars['String']['input']>;
  largeImageContainsFold?: InputMaybe<Scalars['String']['input']>;
  largeImageEqualFold?: InputMaybe<Scalars['String']['input']>;
  largeImageGT?: InputMaybe<Scalars['String']['input']>;
  largeImageGTE?: InputMaybe<Scalars['String']['input']>;
  largeImageHasPrefix?: InputMaybe<Scalars['String']['input']>;
  largeImageHasSuffix?: InputMaybe<Scalars['String']['input']>;
  largeImageIn?: InputMaybe<Array<Scalars['String']['input']>>;
  largeImageLT?: InputMaybe<Scalars['String']['input']>;
  largeImageLTE?: InputMaybe<Scalars['String']['input']>;
  largeImageNEQ?: InputMaybe<Scalars['String']['input']>;
  largeImageNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<RepeatInvestmentSectorWhereInput>;
  or?: InputMaybe<Array<RepeatInvestmentSectorWhereInput>>;
  /** order field predicates */
  order?: InputMaybe<Scalars['Int']['input']>;
  orderGT?: InputMaybe<Scalars['Int']['input']>;
  orderGTE?: InputMaybe<Scalars['Int']['input']>;
  orderIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  orderLT?: InputMaybe<Scalars['Int']['input']>;
  orderLTE?: InputMaybe<Scalars['Int']['input']>;
  orderNEQ?: InputMaybe<Scalars['Int']['input']>;
  orderNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** small_image field predicates */
  smallImage?: InputMaybe<Scalars['String']['input']>;
  smallImageContains?: InputMaybe<Scalars['String']['input']>;
  smallImageContainsFold?: InputMaybe<Scalars['String']['input']>;
  smallImageEqualFold?: InputMaybe<Scalars['String']['input']>;
  smallImageGT?: InputMaybe<Scalars['String']['input']>;
  smallImageGTE?: InputMaybe<Scalars['String']['input']>;
  smallImageHasPrefix?: InputMaybe<Scalars['String']['input']>;
  smallImageHasSuffix?: InputMaybe<Scalars['String']['input']>;
  smallImageIn?: InputMaybe<Array<Scalars['String']['input']>>;
  smallImageLT?: InputMaybe<Scalars['String']['input']>;
  smallImageLTE?: InputMaybe<Scalars['String']['input']>;
  smallImageNEQ?: InputMaybe<Scalars['String']['input']>;
  smallImageNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** type field predicates */
  type?: InputMaybe<RepeatInvestmentSectorType>;
  typeIn?: InputMaybe<Array<RepeatInvestmentSectorType>>;
  typeNEQ?: InputMaybe<RepeatInvestmentSectorType>;
  typeNotIn?: InputMaybe<Array<RepeatInvestmentSectorType>>;
};

export type RequestDetails = {
  __typename?: 'RequestDetails';
  /**
   * If the request was made by an admin, this field will be populated with the admin information.
   * Only admins can query this field.
   */
  admin?: Maybe<Admin>;
  requestUUID?: Maybe<Scalars['UUID']['output']>;
  requestor: Requestor;
  timestamp: Scalars['Time']['output'];
};

export type RequestDetailsWhereInput = {
  /** admin_requestor edge predicates */
  hasAdminRequestor?: InputMaybe<Scalars['Boolean']['input']>;
  hasAdminRequestorWith?: InputMaybe<Array<AdminWhereInput>>;
  /** request_time field predicates */
  requestTime?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeLT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  /** request_uuid field predicates */
  requestUUID?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  requestUUIDLT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDLTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNEQ?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNotIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** requestor field predicates */
  requestor?: InputMaybe<Requestor>;
  requestorIn?: InputMaybe<Array<Requestor>>;
  requestorNEQ?: InputMaybe<Requestor>;
  requestorNotIn?: InputMaybe<Array<Requestor>>;
};

/** Requestor is enum for the field requestor */
export enum Requestor {
  RequestorAdmin = 'REQUESTOR_ADMIN',
  RequestorPrincipal = 'REQUESTOR_PRINCIPAL',
  RequestorRobo = 'REQUESTOR_ROBO',
  RequestorScheduler = 'REQUESTOR_SCHEDULER',
  RequestorSystem = 'REQUESTOR_SYSTEM',
  RequestorUser = 'REQUESTOR_USER',
}

export type RetirementContributionFundamentalInfo = {
  __typename?: 'RetirementContributionFundamentalInfo';
  /** limits returens the contribution limits for the user for the given year. */
  limits?: Maybe<RetirementContributionLimits>;
};

export type RetirementContributionFundamentalInfoLimitsArgs = {
  age: Scalars['Int']['input'];
  annualGrossIncome: Scalars['Int']['input'];
  filingStatus: UserFilingStatus;
  year?: InputMaybe<Scalars['Uint']['input']>;
};

export type RetirementContributionInfo = Node & {
  __typename?: 'RetirementContributionInfo';
  id: Scalars['ID']['output'];
  taxYear: Scalars['Int']['output'];
  type: RetirementContributionInfoRetirementContributionType;
};

/** RetirementContributionInfoRetirementContributionType is enum for the field type */
export enum RetirementContributionInfoRetirementContributionType {
  RetirementContributionTypeConversion = 'RETIREMENT_CONTRIBUTION_TYPE_CONVERSION',
  RetirementContributionTypeEmployee = 'RETIREMENT_CONTRIBUTION_TYPE_EMPLOYEE',
  RetirementContributionTypeEmployer = 'RETIREMENT_CONTRIBUTION_TYPE_EMPLOYER',
  RetirementContributionTypeRecharacterization = 'RETIREMENT_CONTRIBUTION_TYPE_RECHARACTERIZATION',
  RetirementContributionTypeRegular = 'RETIREMENT_CONTRIBUTION_TYPE_REGULAR',
  RetirementContributionTypeRepayment = 'RETIREMENT_CONTRIBUTION_TYPE_REPAYMENT',
  RetirementContributionTypeRollover_60Day = 'RETIREMENT_CONTRIBUTION_TYPE_ROLLOVER_60_DAY',
  RetirementContributionTypeRolloverDirect = 'RETIREMENT_CONTRIBUTION_TYPE_ROLLOVER_DIRECT',
  RetirementContributionTypeTransfer = 'RETIREMENT_CONTRIBUTION_TYPE_TRANSFER',
  RetirementContributionTypeTrusteeFee = 'RETIREMENT_CONTRIBUTION_TYPE_TRUSTEE_FEE',
}

/**
 * RetirementContributionInfoWhereInput is used for filtering RetirementContributionInfo objects.
 * Input was generated by ent.
 */
export type RetirementContributionInfoWhereInput = {
  and?: InputMaybe<Array<RetirementContributionInfoWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<RetirementContributionInfoWhereInput>;
  or?: InputMaybe<Array<RetirementContributionInfoWhereInput>>;
  /** tax_year field predicates */
  taxYear?: InputMaybe<Scalars['Int']['input']>;
  taxYearGT?: InputMaybe<Scalars['Int']['input']>;
  taxYearGTE?: InputMaybe<Scalars['Int']['input']>;
  taxYearIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  taxYearLT?: InputMaybe<Scalars['Int']['input']>;
  taxYearLTE?: InputMaybe<Scalars['Int']['input']>;
  taxYearNEQ?: InputMaybe<Scalars['Int']['input']>;
  taxYearNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** type field predicates */
  type?: InputMaybe<RetirementContributionInfoRetirementContributionType>;
  typeIn?: InputMaybe<Array<RetirementContributionInfoRetirementContributionType>>;
  typeNEQ?: InputMaybe<RetirementContributionInfoRetirementContributionType>;
  typeNotIn?: InputMaybe<Array<RetirementContributionInfoRetirementContributionType>>;
};

export type RetirementContributionLimits = {
  __typename?: 'RetirementContributionLimits';
  /** rothIRALimit is the allowed contribution amount to the Roth IRA account. */
  rothIRALimit: Scalars['Decimal']['output'];
  /** totalLimit is the allowed contribution amount across all retirement accounts. */
  totalLimit: Scalars['Decimal']['output'];
  /** traditionalIRALimit is the allowed contribution amount to the traditional IRA account. */
  traditionalIRALimit: Scalars['Decimal']['output'];
  year: Scalars['Uint']['output'];
};

export type RetirementDistributionInfo = Node & {
  __typename?: 'RetirementDistributionInfo';
  federalTaxAmount?: Maybe<Scalars['Decimal']['output']>;
  federalTaxIsPercentage?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  stateTaxAmount?: Maybe<Scalars['Decimal']['output']>;
  stateTaxIsPercentage?: Maybe<Scalars['Boolean']['output']>;
  type: RetirementDistributionInfoRetirementDistributionType;
};

/** RetirementDistributionInfoRetirementDistributionType is enum for the field type */
export enum RetirementDistributionInfoRetirementDistributionType {
  RetirementDistributionTypeDeath = 'RETIREMENT_DISTRIBUTION_TYPE_DEATH',
  RetirementDistributionTypeDisability = 'RETIREMENT_DISTRIBUTION_TYPE_DISABILITY',
  RetirementDistributionTypeDistConversion = 'RETIREMENT_DISTRIBUTION_TYPE_DIST_CONVERSION',
  RetirementDistributionTypeDistTransfer = 'RETIREMENT_DISTRIBUTION_TYPE_DIST_TRANSFER',
  RetirementDistributionTypeExcessContributionRemovalAfterTaxDeadline = 'RETIREMENT_DISTRIBUTION_TYPE_EXCESS_CONTRIBUTION_REMOVAL_AFTER_TAX_DEADLINE',
  RetirementDistributionTypeExcessContributionRemovalBeforeTaxDeadline = 'RETIREMENT_DISTRIBUTION_TYPE_EXCESS_CONTRIBUTION_REMOVAL_BEFORE_TAX_DEADLINE',
  RetirementDistributionTypeManagementFee = 'RETIREMENT_DISTRIBUTION_TYPE_MANAGEMENT_FEE',
  RetirementDistributionTypeNetIncomeAttributable = 'RETIREMENT_DISTRIBUTION_TYPE_NET_INCOME_ATTRIBUTABLE',
  RetirementDistributionTypeNonReportable = 'RETIREMENT_DISTRIBUTION_TYPE_NON_REPORTABLE',
  RetirementDistributionTypeNormal = 'RETIREMENT_DISTRIBUTION_TYPE_NORMAL',
  RetirementDistributionTypeNormalRothIraGreaterThan_5Years = 'RETIREMENT_DISTRIBUTION_TYPE_NORMAL_ROTH_IRA_GREATER_THAN_5_YEARS',
  RetirementDistributionTypePlanLoan_401K = 'RETIREMENT_DISTRIBUTION_TYPE_PLAN_LOAN_401K',
  RetirementDistributionTypePremature = 'RETIREMENT_DISTRIBUTION_TYPE_PREMATURE',
  RetirementDistributionTypePrematureSimpleIraLessThan_2Years = 'RETIREMENT_DISTRIBUTION_TYPE_PREMATURE_SIMPLE_IRA_LESS_THAN_2_YEARS',
  RetirementDistributionTypeRecharacterizationCurrentYear = 'RETIREMENT_DISTRIBUTION_TYPE_RECHARACTERIZATION_CURRENT_YEAR',
  RetirementDistributionTypeRecharacterizationPriorYear = 'RETIREMENT_DISTRIBUTION_TYPE_RECHARACTERIZATION_PRIOR_YEAR',
  RetirementDistributionTypeRevocation = 'RETIREMENT_DISTRIBUTION_TYPE_REVOCATION',
  RetirementDistributionTypeRolloverToIra = 'RETIREMENT_DISTRIBUTION_TYPE_ROLLOVER_TO_IRA',
  RetirementDistributionTypeRolloverToQualifiedPlan = 'RETIREMENT_DISTRIBUTION_TYPE_ROLLOVER_TO_QUALIFIED_PLAN',
  RetirementDistributionTypeSosepp = 'RETIREMENT_DISTRIBUTION_TYPE_SOSEPP',
}

/**
 * RetirementDistributionInfoWhereInput is used for filtering RetirementDistributionInfo objects.
 * Input was generated by ent.
 */
export type RetirementDistributionInfoWhereInput = {
  and?: InputMaybe<Array<RetirementDistributionInfoWhereInput>>;
  /** federal_tax_amount field predicates */
  federalTaxAmount?: InputMaybe<Scalars['Decimal']['input']>;
  federalTaxAmountGT?: InputMaybe<Scalars['Decimal']['input']>;
  federalTaxAmountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  federalTaxAmountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  federalTaxAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  federalTaxAmountLT?: InputMaybe<Scalars['Decimal']['input']>;
  federalTaxAmountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  federalTaxAmountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  federalTaxAmountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  federalTaxAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** federal_tax_is_percentage field predicates */
  federalTaxIsPercentage?: InputMaybe<Scalars['Boolean']['input']>;
  federalTaxIsPercentageIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  federalTaxIsPercentageNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  federalTaxIsPercentageNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<RetirementDistributionInfoWhereInput>;
  or?: InputMaybe<Array<RetirementDistributionInfoWhereInput>>;
  /** state_tax_amount field predicates */
  stateTaxAmount?: InputMaybe<Scalars['Decimal']['input']>;
  stateTaxAmountGT?: InputMaybe<Scalars['Decimal']['input']>;
  stateTaxAmountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  stateTaxAmountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  stateTaxAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  stateTaxAmountLT?: InputMaybe<Scalars['Decimal']['input']>;
  stateTaxAmountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  stateTaxAmountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  stateTaxAmountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  stateTaxAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** state_tax_is_percentage field predicates */
  stateTaxIsPercentage?: InputMaybe<Scalars['Boolean']['input']>;
  stateTaxIsPercentageIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  stateTaxIsPercentageNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  stateTaxIsPercentageNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** type field predicates */
  type?: InputMaybe<RetirementDistributionInfoRetirementDistributionType>;
  typeIn?: InputMaybe<Array<RetirementDistributionInfoRetirementDistributionType>>;
  typeNEQ?: InputMaybe<RetirementDistributionInfoRetirementDistributionType>;
  typeNotIn?: InputMaybe<Array<RetirementDistributionInfoRetirementDistributionType>>;
};

export type RetirementFundamentalInfo = {
  __typename?: 'RetirementFundamentalInfo';
  contribution?: Maybe<RetirementContributionFundamentalInfo>;
  withholding?: Maybe<RetirementWithholdingFundamentalInfo>;
};

export type RetirementUserSettings = {
  __typename?: 'RetirementUserSettings';
  defaultWithholding: RetirementWithholdingSettings;
};

export type RetirementWithholdingElection = {
  __typename?: 'RetirementWithholdingElection';
  percentage?: Maybe<Scalars['Decimal']['output']>;
  waive: Scalars['Boolean']['output'];
};

export type RetirementWithholdingFundamentalInfo = {
  __typename?: 'RetirementWithholdingFundamentalInfo';
  federalWithholding?: Maybe<FederalWithholdingFundamentalInfo>;
  stateWithholding?: Maybe<Array<Maybe<StateWithholdingFundamentalInfo>>>;
};

export type RetirementWithholdingFundamentalInfoFederalWithholdingArgs = {
  year?: InputMaybe<Scalars['Uint']['input']>;
};

export type RetirementWithholdingFundamentalInfoStateWithholdingArgs = {
  state?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Uint']['input']>;
};

export type RetirementWithholdingSettings = {
  __typename?: 'RetirementWithholdingSettings';
  federalWithholding: RetirementWithholdingElection;
  state: Scalars['String']['output'];
  stateWithholding: RetirementWithholdingElection;
};

export type ReturnOfCapital = BaseOrder &
  Node & {
    __typename?: 'ReturnOfCapital';
    account: UserAccount;
    createTime: Scalars['Time']['output'];
    distributions?: Maybe<Array<ReturnOfCapitalDistribution>>;
    id: Scalars['ID']['output'];
    /**
     * isReinvested returns true if the value of the distribution is invested into another
     * asset aside from USD.
     */
    isReinvested: Scalars['Boolean']['output'];
    /** Notes is the notes of the order intended for the principal. */
    note?: Maybe<Scalars['String']['output']>;
    orderStatus: OrderOrderStatus;
    orderType: OrderOrderType;
    /** Date the distribution was issued */
    payDate: Scalars['Date']['output'];
    /** Date the distribution was recorded */
    recordDate: Scalars['Date']['output'];
    requestDetails: RequestDetails;
    /** Amount of the distribution asset being added to an account */
    totalAmount: Scalars['Decimal']['output'];
    /** Value of the distribution */
    totalValue: Scalars['Decimal']['output'];
    updateTime: Scalars['Time']['output'];
  };

/** A connection to a list of items. */
export type ReturnOfCapitalConnection = {
  __typename?: 'ReturnOfCapitalConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ReturnOfCapitalEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type ReturnOfCapitalDistribution = BaseOrder &
  Node & {
    __typename?: 'ReturnOfCapitalDistribution';
    /** Amount of the distribution asset being added to the portfolio */
    amount: Scalars['Decimal']['output'];
    createTime: Scalars['Time']['output'];
    id: Scalars['ID']['output'];
    /**
     * isReinvested returns true if the value of the distribution is invested into another
     * asset aside from USD.
     */
    isReinvested: Scalars['Boolean']['output'];
    /** Notes is the notes of the order intended for the principal. */
    note?: Maybe<Scalars['String']['output']>;
    orderStatus: OrderOrderStatus;
    orderType: OrderOrderType;
    /** Date the distribution was issued */
    payDate: Scalars['Date']['output'];
    portfolio: UserAccountPortfolio;
    requestDetails: RequestDetails;
    returnOfCapital: ReturnOfCapital;
    /** Time that the row was settled */
    settlementTime?: Maybe<Scalars['Time']['output']>;
    updateTime: Scalars['Time']['output'];
    /** Value of the distribution */
    value: Scalars['Decimal']['output'];
  };

/** A connection to a list of items. */
export type ReturnOfCapitalDistributionConnection = {
  __typename?: 'ReturnOfCapitalDistributionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ReturnOfCapitalDistributionEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ReturnOfCapitalDistributionEdge = {
  __typename?: 'ReturnOfCapitalDistributionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ReturnOfCapitalDistribution>;
};

/** Ordering options for ReturnOfCapitalDistribution connections */
export type ReturnOfCapitalDistributionOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ReturnOfCapitalDistributions. */
  field: ReturnOfCapitalDistributionOrderField;
};

/** Properties by which ReturnOfCapitalDistribution connections can be ordered. */
export enum ReturnOfCapitalDistributionOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * ReturnOfCapitalDistributionWhereInput is used for filtering ReturnOfCapitalDistribution objects.
 * Input was generated by ent.
 */
export type ReturnOfCapitalDistributionWhereInput = {
  /** amount field predicates */
  amount?: InputMaybe<Scalars['Decimal']['input']>;
  amountGT?: InputMaybe<Scalars['Decimal']['input']>;
  amountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  amountLT?: InputMaybe<Scalars['Decimal']['input']>;
  amountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  amountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  amountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  and?: InputMaybe<Array<ReturnOfCapitalDistributionWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** portfolio edge predicates */
  hasPortfolio?: InputMaybe<Scalars['Boolean']['input']>;
  hasPortfolioWith?: InputMaybe<Array<UserAccountPortfolioWhereInput>>;
  /** return_of_capital edge predicates */
  hasReturnOfCapital?: InputMaybe<Scalars['Boolean']['input']>;
  hasReturnOfCapitalWith?: InputMaybe<Array<ReturnOfCapitalWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<ReturnOfCapitalDistributionWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<ReturnOfCapitalDistributionWhereInput>>;
  /** pay_date field predicates */
  payDate?: InputMaybe<Scalars['Date']['input']>;
  payDateGT?: InputMaybe<Scalars['Date']['input']>;
  payDateGTE?: InputMaybe<Scalars['Date']['input']>;
  payDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  payDateLT?: InputMaybe<Scalars['Date']['input']>;
  payDateLTE?: InputMaybe<Scalars['Date']['input']>;
  payDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  payDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  /** settlement_time field predicates */
  settlementTime?: InputMaybe<Scalars['Time']['input']>;
  settlementTimeGT?: InputMaybe<Scalars['Time']['input']>;
  settlementTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  settlementTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  settlementTimeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  settlementTimeLT?: InputMaybe<Scalars['Time']['input']>;
  settlementTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  settlementTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  settlementTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  settlementTimeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** value field predicates */
  value?: InputMaybe<Scalars['Decimal']['input']>;
  valueGT?: InputMaybe<Scalars['Decimal']['input']>;
  valueGTE?: InputMaybe<Scalars['Decimal']['input']>;
  valueIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  valueLT?: InputMaybe<Scalars['Decimal']['input']>;
  valueLTE?: InputMaybe<Scalars['Decimal']['input']>;
  valueNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  valueNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

/** An edge in a connection. */
export type ReturnOfCapitalEdge = {
  __typename?: 'ReturnOfCapitalEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ReturnOfCapital>;
};

/** Ordering options for ReturnOfCapital connections */
export type ReturnOfCapitalOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ReturnOfCapitals. */
  field: ReturnOfCapitalOrderField;
};

/** Properties by which ReturnOfCapital connections can be ordered. */
export enum ReturnOfCapitalOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * ReturnOfCapitalWhereInput is used for filtering ReturnOfCapital objects.
 * Input was generated by ent.
 */
export type ReturnOfCapitalWhereInput = {
  and?: InputMaybe<Array<ReturnOfCapitalWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** account edge predicates */
  hasAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountWith?: InputMaybe<Array<UserAccountWhereInput>>;
  /** distributions edge predicates */
  hasDistributions?: InputMaybe<Scalars['Boolean']['input']>;
  hasDistributionsWith?: InputMaybe<Array<ReturnOfCapitalDistributionWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<ReturnOfCapitalWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<ReturnOfCapitalWhereInput>>;
  /** pay_date field predicates */
  payDate?: InputMaybe<Scalars['Date']['input']>;
  payDateGT?: InputMaybe<Scalars['Date']['input']>;
  payDateGTE?: InputMaybe<Scalars['Date']['input']>;
  payDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  payDateLT?: InputMaybe<Scalars['Date']['input']>;
  payDateLTE?: InputMaybe<Scalars['Date']['input']>;
  payDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  payDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  /** record_date field predicates */
  recordDate?: InputMaybe<Scalars['Date']['input']>;
  recordDateGT?: InputMaybe<Scalars['Date']['input']>;
  recordDateGTE?: InputMaybe<Scalars['Date']['input']>;
  recordDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  recordDateLT?: InputMaybe<Scalars['Date']['input']>;
  recordDateLTE?: InputMaybe<Scalars['Date']['input']>;
  recordDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  recordDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  /** total_amount field predicates */
  totalAmount?: InputMaybe<Scalars['Decimal']['input']>;
  totalAmountGT?: InputMaybe<Scalars['Decimal']['input']>;
  totalAmountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalAmountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  totalAmountLT?: InputMaybe<Scalars['Decimal']['input']>;
  totalAmountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalAmountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  totalAmountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** total_value field predicates */
  totalValue?: InputMaybe<Scalars['Decimal']['input']>;
  totalValueGT?: InputMaybe<Scalars['Decimal']['input']>;
  totalValueGTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalValueIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  totalValueLT?: InputMaybe<Scalars['Decimal']['input']>;
  totalValueLTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalValueNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  totalValueNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type RoboActionsAggregationsTimeSeries = {
  __typename?: 'RoboActionsAggregationsTimeSeries';
  dividends: Array<Scalars['Decimal']['output']>;
  /** end is the start datetime of the series */
  end: Scalars['Time']['output'];
  fees: Array<Scalars['Decimal']['output']>;
  infusions: Array<Scalars['Decimal']['output']>;
  /** limit is the maximum number of datapoint used when creating the series */
  limit: Scalars['Int']['output'];
  /** period is the desired amount of time between data points */
  period: TimePeriod;
  /** start is the start datetime of the series */
  start: Scalars['Time']['output'];
  /** The time of each data point in the series */
  timestamps: Array<Scalars['Time']['output']>;
  withdrawals: Array<Scalars['Decimal']['output']>;
};

/** Contains information on the user's holdings allocated towards the goal. */
export type RoboAllocation = {
  __typename?: 'RoboAllocation';
  /** list of holdings held for the robo investment. */
  allocation: Array<RoboAllocationAsset>;
  /** list of sectors held for the robo investment. */
  allocationBySector: Array<RoboAllocationSector>;
  /** Percentage of the portfolio in bonds. */
  bondPct: Scalars['Decimal']['output'];
  /** drift is a measure of the difference between the target allocation and the current allocation. */
  drift: Scalars['Float']['output'];
  /** Percentage of the portfolio in fiat currency. */
  fiatPct: Scalars['Decimal']['output'];
  /** Percentage of the portfolio in stocks. */
  stockPct: Scalars['Decimal']['output'];
  /** current value of the held assets */
  totalValue: Scalars['Decimal']['output'];
  /** returns the historical sum total of the account value over time */
  valueHistory: UserAccountPortfolioValueHistory;
  /** total value of the portfolio that is withdrawable. */
  withdrawableValue: Scalars['Decimal']['output'];
};

/** Contains information on the user's holdings allocated towards the goal. */
export type RoboAllocationAllocationArgs = {
  where?: InputMaybe<RoboAllocationAssetWhereInput>;
};

/** Contains information on the user's holdings allocated towards the goal. */
export type RoboAllocationValueHistoryArgs = {
  range?: InputMaybe<TimeSeriesRange>;
};

/** RoboAllocationAsset contains information on the existing allocation of assets in the robo investment. */
export type RoboAllocationAsset = {
  __typename?: 'RoboAllocationAsset';
  asset: BaseAsset;
  /** balance is the amount of the asset held in the robo investment. */
  balance: Scalars['Decimal']['output'];
  /** percentage by value of the portfolio. */
  percentage: Scalars['Decimal']['output'];
  /** value is the value of the asset held in the robo investment, calculated by balance x asset price. */
  value: Scalars['Decimal']['output'];
};

export type RoboAllocationAssetWhereInput = {
  asset?: InputMaybe<BaseAssetWhereInput>;
  /** balance field predicates */
  balance?: InputMaybe<Scalars['Decimal']['input']>;
  balanceGT?: InputMaybe<Scalars['Decimal']['input']>;
  balanceGTE?: InputMaybe<Scalars['Decimal']['input']>;
  balanceLT?: InputMaybe<Scalars['Decimal']['input']>;
  balanceLTE?: InputMaybe<Scalars['Decimal']['input']>;
  balanceNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  /** percentage field predicates */
  percentage?: InputMaybe<Scalars['Decimal']['input']>;
  percentageGT?: InputMaybe<Scalars['Decimal']['input']>;
  percentageGTE?: InputMaybe<Scalars['Decimal']['input']>;
  percentageLT?: InputMaybe<Scalars['Decimal']['input']>;
  percentageLTE?: InputMaybe<Scalars['Decimal']['input']>;
  percentageNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  sector?: InputMaybe<ModelPortfolioSectorWhereInput>;
  /** targetPercentage field predicates */
  targetPercentage?: InputMaybe<Scalars['Decimal']['input']>;
  targetPercentageGT?: InputMaybe<Scalars['Decimal']['input']>;
  targetPercentageGTE?: InputMaybe<Scalars['Decimal']['input']>;
  targetPercentageLT?: InputMaybe<Scalars['Decimal']['input']>;
  targetPercentageLTE?: InputMaybe<Scalars['Decimal']['input']>;
  targetPercentageNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  /** value field predicates */
  value?: InputMaybe<Scalars['Decimal']['input']>;
  valueGT?: InputMaybe<Scalars['Decimal']['input']>;
  valueGTE?: InputMaybe<Scalars['Decimal']['input']>;
  valueLT?: InputMaybe<Scalars['Decimal']['input']>;
  valueLTE?: InputMaybe<Scalars['Decimal']['input']>;
  valueNEQ?: InputMaybe<Scalars['Decimal']['input']>;
};

export type RoboAllocationSector = {
  __typename?: 'RoboAllocationSector';
  /** list of assets in the sector. */
  assets: Array<RoboAllocationAsset>;
  /** percentage by value of the portfolio. */
  percentage: Scalars['Decimal']['output'];
  sector: ModelPortfolioSector;
  /** value is the value of the asset held in the robo investment, calculated by balance x asset price. */
  value: Scalars['Decimal']['output'];
};

/** RoboClosureDetails is an event where the robo investment is closed. */
export type RoboClosureDetails = {
  __typename?: 'RoboClosureDetails';
  reason: Scalars['String']['output'];
};

/** RoboDividendDetails is a dividend distribution that is reinvested back into the robo investment. */
export type RoboDividendDetails = {
  __typename?: 'RoboDividendDetails';
  amount: Scalars['Decimal']['output'];
};

/** RoboFeeDetails is a fee that is charged to the robo investment. */
export type RoboFeeDetails = {
  __typename?: 'RoboFeeDetails';
  amount: Scalars['Decimal']['output'];
  type: RoboFeeType;
};

/** RoboFeeType is the various types of fees that can be charged on a Robo. */
export enum RoboFeeType {
  RoboFeeTypeAdvisory = 'ROBO_FEE_TYPE_ADVISORY',
}

export type RoboGeneralInvestment = Node & {
  __typename?: 'RoboGeneralInvestment';
  /** UserAccountPortfolio associated with the robo investment. This is the source for the RoboAllocation information. */
  accountPortfolio: UserAccountPortfolio;
  /** The account portfolio id for this robo investment. */
  accountPortfolioID: Scalars['ID']['output'];
  actions: RoboInvestmentActionConnection;
  /** Aggregates actions data over the time period requested for the investment. */
  actionsAggregation: RoboActionsAggregationsTimeSeries;
  /** Contains information on the user's holdings allocated towards the goal. */
  allocation: RoboAllocation;
  /** Indicates whether or not auto infusions are enabled. Since not all tenants have this enabled by default, no default value is set and this field is optional for backward-compatibility reasons. */
  autoInfusionEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Sets the date if the robo investment has been completed. */
  completeAt?: Maybe<Scalars['Time']['output']>;
  createTime: Scalars['Time']['output'];
  fundingAccount?: Maybe<ExternalAccount>;
  id: Scalars['ID']['output'];
  infusionFrequency: RoboGeneralInvestmentRoboInfusionFreq;
  /** Link to the recurring rule for the infusion */
  infusionRecurringRule?: Maybe<RecurringRule>;
  /** Link to the recurring rule history for the infusion */
  infusionRecurringRuleHistory?: Maybe<Array<RecurringRule>>;
  /** initial amount added to the general investment */
  initialInvestmentAmount: Scalars['Decimal']['output'];
  /** Returns true if the robo investment is still being actively maintained and holds assets. */
  isActive: Scalars['Boolean']['output'];
  /** Returns true if the robo investment automatic infusions are paused indefinitely. */
  isAutoInfusionPaused: Scalars['Boolean']['output'];
  /** The last time the robo investment was rebalanced. */
  lastRebalanceTime?: Maybe<Scalars['Time']['output']>;
  /** Number of times the user has manually rebalanced the robo investment. */
  manualRebalanceCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  nextScheduledInfusion: Scalars['Date']['output'];
  /** Indicates the reason to update a portfolio changes. */
  reason?: Maybe<Scalars['String']['output']>;
  /** recurring infusion to the general investment */
  recurringInfusion: Scalars['Decimal']['output'];
  /** date to start the first infusion */
  recurringInfusionStart: Scalars['Time']['output'];
  rtqSubmission?: Maybe<RtqSubmission>;
  /** list of substitutions to apply to the recommended portfolio. */
  substitutions?: Maybe<Array<ModelPortfolioAssetSubstitution>>;
  /** Link to current target portfolio */
  targetPortfolio: ModelPortfolio;
  /** The target portfolio id for this robo investment. */
  targetPortfolioID: Scalars['ID']['output'];
  targetScheduleInfusion: Scalars['Date']['output'];
  /** Total lifetime dividends earned for this robo investment. */
  totalDividends: Scalars['Decimal']['output'];
  /** Total lifetime fees charged for this robo investment. */
  totalFees: Scalars['Decimal']['output'];
  /** Total lifetime infusions deposited for this robo investment. */
  totalInfusions: Scalars['Decimal']['output'];
  /** Total lifetime interest earned for this robo investment. */
  totalInterests: Scalars['Decimal']['output'];
  /** Total lifetime withdrawals for this robo investment. */
  totalWithdrawals: Scalars['Decimal']['output'];
  updateTime: Scalars['Time']['output'];
  user: User;
};

export type RoboGeneralInvestmentActionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RoboInvestmentActionOrder>;
  where?: InputMaybe<RoboInvestmentActionWhereInput>;
};

export type RoboGeneralInvestmentActionsAggregationArgs = {
  range?: InputMaybe<TimeSeriesRange>;
};

/** A connection to a list of items. */
export type RoboGeneralInvestmentConnection = {
  __typename?: 'RoboGeneralInvestmentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RoboGeneralInvestmentEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RoboGeneralInvestmentEdge = {
  __typename?: 'RoboGeneralInvestmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<RoboGeneralInvestment>;
};

/** Ordering options for RoboGeneralInvestment connections */
export type RoboGeneralInvestmentOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order RoboGeneralInvestments. */
  field: RoboGeneralInvestmentOrderField;
};

/** Properties by which RoboGeneralInvestment connections can be ordered. */
export enum RoboGeneralInvestmentOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/** RoboGeneralInvestmentRoboInfusionFreq is enum for the field infusion_frequency */
export enum RoboGeneralInvestmentRoboInfusionFreq {
  RoboInfusionFreqBiWeekly = 'ROBO_INFUSION_FREQ_BI_WEEKLY',
  RoboInfusionFreqMonthly = 'ROBO_INFUSION_FREQ_MONTHLY',
  RoboInfusionFreqSemiAnnually = 'ROBO_INFUSION_FREQ_SEMI_ANNUALLY',
  RoboInfusionFreqSemiMonthly = 'ROBO_INFUSION_FREQ_SEMI_MONTHLY',
  RoboInfusionFreqWeekly = 'ROBO_INFUSION_FREQ_WEEKLY',
  RoboInfusionFreqYearly = 'ROBO_INFUSION_FREQ_YEARLY',
}

/**
 * RoboGeneralInvestmentWhereInput is used for filtering RoboGeneralInvestment objects.
 * Input was generated by ent.
 */
export type RoboGeneralInvestmentWhereInput = {
  /** account_portfolio_id field predicates */
  accountPortfolioID?: InputMaybe<Scalars['ID']['input']>;
  accountPortfolioIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  accountPortfolioIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  accountPortfolioIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  and?: InputMaybe<Array<RoboGeneralInvestmentWhereInput>>;
  /** auto_infusion_enabled field predicates */
  autoInfusionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  autoInfusionEnabledIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  autoInfusionEnabledNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  autoInfusionEnabledNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** complete_at field predicates */
  completeAt?: InputMaybe<Scalars['Time']['input']>;
  completeAtGT?: InputMaybe<Scalars['Time']['input']>;
  completeAtGTE?: InputMaybe<Scalars['Time']['input']>;
  completeAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  completeAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  completeAtLT?: InputMaybe<Scalars['Time']['input']>;
  completeAtLTE?: InputMaybe<Scalars['Time']['input']>;
  completeAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  completeAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  completeAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** account_portfolio edge predicates */
  hasAccountPortfolio?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountPortfolioWith?: InputMaybe<Array<UserAccountPortfolioWhereInput>>;
  /** actions edge predicates */
  hasActions?: InputMaybe<Scalars['Boolean']['input']>;
  hasActionsWith?: InputMaybe<Array<RoboInvestmentActionWhereInput>>;
  /** funding_account edge predicates */
  hasFundingAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasFundingAccountWith?: InputMaybe<Array<ExternalAccountWhereInput>>;
  /** infusion_recurring_rule edge predicates */
  hasInfusionRecurringRule?: InputMaybe<Scalars['Boolean']['input']>;
  /** infusion_recurring_rule_history edge predicates */
  hasInfusionRecurringRuleHistory?: InputMaybe<Scalars['Boolean']['input']>;
  hasInfusionRecurringRuleHistoryWith?: InputMaybe<Array<RecurringRuleWhereInput>>;
  hasInfusionRecurringRuleWith?: InputMaybe<Array<RecurringRuleWhereInput>>;
  /** rtq_submission edge predicates */
  hasRtqSubmission?: InputMaybe<Scalars['Boolean']['input']>;
  hasRtqSubmissionWith?: InputMaybe<Array<RtqSubmissionWhereInput>>;
  /** substitutions edge predicates */
  hasSubstitutions?: InputMaybe<Scalars['Boolean']['input']>;
  hasSubstitutionsWith?: InputMaybe<Array<ModelPortfolioAssetSubstitutionWhereInput>>;
  /** target_portfolio edge predicates */
  hasTargetPortfolio?: InputMaybe<Scalars['Boolean']['input']>;
  hasTargetPortfolioWith?: InputMaybe<Array<ModelPortfolioWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** infusion_frequency field predicates */
  infusionFrequency?: InputMaybe<RoboGeneralInvestmentRoboInfusionFreq>;
  infusionFrequencyIn?: InputMaybe<Array<RoboGeneralInvestmentRoboInfusionFreq>>;
  infusionFrequencyNEQ?: InputMaybe<RoboGeneralInvestmentRoboInfusionFreq>;
  infusionFrequencyNotIn?: InputMaybe<Array<RoboGeneralInvestmentRoboInfusionFreq>>;
  /** initial_investment_amount field predicates */
  initialInvestmentAmount?: InputMaybe<Scalars['Decimal']['input']>;
  initialInvestmentAmountGT?: InputMaybe<Scalars['Decimal']['input']>;
  initialInvestmentAmountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  initialInvestmentAmountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  initialInvestmentAmountLT?: InputMaybe<Scalars['Decimal']['input']>;
  initialInvestmentAmountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  initialInvestmentAmountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  initialInvestmentAmountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** is_active field predicates */
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isActiveNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** is_auto_infusion_paused field predicates */
  isAutoInfusionPaused?: InputMaybe<Scalars['Boolean']['input']>;
  isAutoInfusionPausedNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** last_rebalance_time field predicates */
  lastRebalanceTime?: InputMaybe<Scalars['Time']['input']>;
  lastRebalanceTimeGT?: InputMaybe<Scalars['Time']['input']>;
  lastRebalanceTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  lastRebalanceTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  lastRebalanceTimeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  lastRebalanceTimeLT?: InputMaybe<Scalars['Time']['input']>;
  lastRebalanceTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  lastRebalanceTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  lastRebalanceTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  lastRebalanceTimeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** manual_rebalance_count field predicates */
  manualRebalanceCount?: InputMaybe<Scalars['Int']['input']>;
  manualRebalanceCountGT?: InputMaybe<Scalars['Int']['input']>;
  manualRebalanceCountGTE?: InputMaybe<Scalars['Int']['input']>;
  manualRebalanceCountIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  manualRebalanceCountLT?: InputMaybe<Scalars['Int']['input']>;
  manualRebalanceCountLTE?: InputMaybe<Scalars['Int']['input']>;
  manualRebalanceCountNEQ?: InputMaybe<Scalars['Int']['input']>;
  manualRebalanceCountNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** next_scheduled_infusion field predicates */
  nextScheduledInfusion?: InputMaybe<Scalars['Date']['input']>;
  nextScheduledInfusionGT?: InputMaybe<Scalars['Date']['input']>;
  nextScheduledInfusionGTE?: InputMaybe<Scalars['Date']['input']>;
  nextScheduledInfusionIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  nextScheduledInfusionLT?: InputMaybe<Scalars['Date']['input']>;
  nextScheduledInfusionLTE?: InputMaybe<Scalars['Date']['input']>;
  nextScheduledInfusionNEQ?: InputMaybe<Scalars['Date']['input']>;
  nextScheduledInfusionNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  not?: InputMaybe<RoboGeneralInvestmentWhereInput>;
  or?: InputMaybe<Array<RoboGeneralInvestmentWhereInput>>;
  /** reason field predicates */
  reason?: InputMaybe<Scalars['String']['input']>;
  reasonContains?: InputMaybe<Scalars['String']['input']>;
  reasonContainsFold?: InputMaybe<Scalars['String']['input']>;
  reasonEqualFold?: InputMaybe<Scalars['String']['input']>;
  reasonGT?: InputMaybe<Scalars['String']['input']>;
  reasonGTE?: InputMaybe<Scalars['String']['input']>;
  reasonHasPrefix?: InputMaybe<Scalars['String']['input']>;
  reasonHasSuffix?: InputMaybe<Scalars['String']['input']>;
  reasonIn?: InputMaybe<Array<Scalars['String']['input']>>;
  reasonIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  reasonLT?: InputMaybe<Scalars['String']['input']>;
  reasonLTE?: InputMaybe<Scalars['String']['input']>;
  reasonNEQ?: InputMaybe<Scalars['String']['input']>;
  reasonNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  reasonNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** recurring_infusion field predicates */
  recurringInfusion?: InputMaybe<Scalars['Decimal']['input']>;
  recurringInfusionGT?: InputMaybe<Scalars['Decimal']['input']>;
  recurringInfusionGTE?: InputMaybe<Scalars['Decimal']['input']>;
  recurringInfusionIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  recurringInfusionLT?: InputMaybe<Scalars['Decimal']['input']>;
  recurringInfusionLTE?: InputMaybe<Scalars['Decimal']['input']>;
  recurringInfusionNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  recurringInfusionNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** recurring_infusion_start field predicates */
  recurringInfusionStart?: InputMaybe<Scalars['Time']['input']>;
  recurringInfusionStartGT?: InputMaybe<Scalars['Time']['input']>;
  recurringInfusionStartGTE?: InputMaybe<Scalars['Time']['input']>;
  recurringInfusionStartIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  recurringInfusionStartLT?: InputMaybe<Scalars['Time']['input']>;
  recurringInfusionStartLTE?: InputMaybe<Scalars['Time']['input']>;
  recurringInfusionStartNEQ?: InputMaybe<Scalars['Time']['input']>;
  recurringInfusionStartNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** target_portfolio_id field predicates */
  targetPortfolioID?: InputMaybe<Scalars['ID']['input']>;
  targetPortfolioIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  targetPortfolioIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  targetPortfolioIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** target_schedule_infusion field predicates */
  targetScheduleInfusion?: InputMaybe<Scalars['Date']['input']>;
  targetScheduleInfusionGT?: InputMaybe<Scalars['Date']['input']>;
  targetScheduleInfusionGTE?: InputMaybe<Scalars['Date']['input']>;
  targetScheduleInfusionIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  targetScheduleInfusionLT?: InputMaybe<Scalars['Date']['input']>;
  targetScheduleInfusionLTE?: InputMaybe<Scalars['Date']['input']>;
  targetScheduleInfusionNEQ?: InputMaybe<Scalars['Date']['input']>;
  targetScheduleInfusionNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  /** total_dividends field predicates */
  totalDividends?: InputMaybe<Scalars['Decimal']['input']>;
  totalDividendsGT?: InputMaybe<Scalars['Decimal']['input']>;
  totalDividendsGTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalDividendsIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  totalDividendsLT?: InputMaybe<Scalars['Decimal']['input']>;
  totalDividendsLTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalDividendsNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  totalDividendsNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** total_fees field predicates */
  totalFees?: InputMaybe<Scalars['Decimal']['input']>;
  totalFeesGT?: InputMaybe<Scalars['Decimal']['input']>;
  totalFeesGTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalFeesIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  totalFeesLT?: InputMaybe<Scalars['Decimal']['input']>;
  totalFeesLTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalFeesNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  totalFeesNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** total_infusions field predicates */
  totalInfusions?: InputMaybe<Scalars['Decimal']['input']>;
  totalInfusionsGT?: InputMaybe<Scalars['Decimal']['input']>;
  totalInfusionsGTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalInfusionsIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  totalInfusionsLT?: InputMaybe<Scalars['Decimal']['input']>;
  totalInfusionsLTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalInfusionsNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  totalInfusionsNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** total_interests field predicates */
  totalInterests?: InputMaybe<Scalars['Decimal']['input']>;
  totalInterestsGT?: InputMaybe<Scalars['Decimal']['input']>;
  totalInterestsGTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalInterestsIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  totalInterestsLT?: InputMaybe<Scalars['Decimal']['input']>;
  totalInterestsLTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalInterestsNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  totalInterestsNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** total_withdrawals field predicates */
  totalWithdrawals?: InputMaybe<Scalars['Decimal']['input']>;
  totalWithdrawalsGT?: InputMaybe<Scalars['Decimal']['input']>;
  totalWithdrawalsGTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalWithdrawalsIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  totalWithdrawalsLT?: InputMaybe<Scalars['Decimal']['input']>;
  totalWithdrawalsLTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalWithdrawalsNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  totalWithdrawalsNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type RoboGoal = Node & {
  __typename?: 'RoboGoal';
  /** UserAccountPortfolio associated with the robo investment. This is the source for the RoboAllocation information. */
  accountPortfolio: UserAccountPortfolio;
  /** The account portfolio id for this robo investment. */
  accountPortfolioID: Scalars['ID']['output'];
  accountType: RoboGoalRoboAccountType;
  actions: RoboInvestmentActionConnection;
  /** Aggregates actions data over the time period requested for the investment. */
  actionsAggregation: RoboActionsAggregationsTimeSeries;
  algorithmExecutionHistory: RoboGoalAlgorithmExecutionConnection;
  /** Contains information on the user's holdings allocated towards the goal. */
  allocation: RoboAllocation;
  /** Indicates whether or not auto infusions are enabled. Since not all tenants have this enabled by default, no default value is set and this field is optional for backward-compatibility reasons. */
  autoInfusionEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Sets the date if the robo investment has been completed. */
  completeAt?: Maybe<Scalars['Time']['output']>;
  createTime: Scalars['Time']['output'];
  /** Link to the funding account for this goal */
  fundingAccount?: Maybe<ExternalAccount>;
  id: Scalars['ID']['output'];
  /** Link to the recurring rule for the infusion */
  infusionRecurringRule?: Maybe<RecurringRule>;
  /** Link to the recurring rule history for the infusion */
  infusionRecurringRuleHistory?: Maybe<Array<RecurringRule>>;
  /** Returns true if the robo investment is still being actively maintained and holds assets. */
  isActive: Scalars['Boolean']['output'];
  /** Returns true if the robo investment automatic infusions are paused indefinitely. */
  isAutoInfusionPaused: Scalars['Boolean']['output'];
  lastProjectionRefresh?: Maybe<Scalars['Time']['output']>;
  /** The last time the robo investment was rebalanced. */
  lastRebalanceTime?: Maybe<Scalars['Time']['output']>;
  /** Number of times the user has manually reallocated the robo investment. */
  manualReallocationCount: Scalars['Int']['output'];
  /** Number of times the user has manually rebalanced the robo investment. */
  manualRebalanceCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  nextScheduledInfusion: Scalars['Time']['output'];
  /** Link to the most recent parameters used for the algorithm execution */
  parameters?: Maybe<RoboGoalParameters>;
  /** Link to the most recent results of the algorithm execution */
  projection?: Maybe<RoboGoalProjection>;
  /** Link to current target portfolio */
  targetPortfolio: ModelPortfolio;
  /** The target portfolio id for this robo investment. */
  targetPortfolioID: Scalars['ID']['output'];
  targetScheduleInfusion: Scalars['Time']['output'];
  /** Total lifetime dividends earned for this robo investment. */
  totalDividends: Scalars['Decimal']['output'];
  /** Total lifetime fees charged for this robo investment. */
  totalFees: Scalars['Decimal']['output'];
  /** Total lifetime infusions deposited for this robo investment. */
  totalInfusions: Scalars['Decimal']['output'];
  /** Total lifetime interest earned for this robo investment. */
  totalInterests: Scalars['Decimal']['output'];
  /** Total lifetime withdrawals for this robo investment. */
  totalWithdrawals: Scalars['Decimal']['output'];
  updateTime: Scalars['Time']['output'];
  user: User;
};

export type RoboGoalActionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RoboInvestmentActionOrder>;
  where?: InputMaybe<RoboInvestmentActionWhereInput>;
};

export type RoboGoalActionsAggregationArgs = {
  range?: InputMaybe<TimeSeriesRange>;
};

export type RoboGoalAlgorithmExecutionHistoryArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RoboGoalAlgorithmExecutionOrder>;
  where?: InputMaybe<RoboGoalAlgorithmExecutionWhereInput>;
};

export type RoboGoalAlgorithmExecution = Node & {
  __typename?: 'RoboGoalAlgorithmExecution';
  createTime: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  /** Link to the parameters used for the algorithm execution */
  parameters?: Maybe<RoboGoalParameters>;
  /** Link to the results of the algorithm execution */
  projection?: Maybe<RoboGoalProjection>;
  /** The time at which the action was requested. */
  requestTime: Scalars['Time']['output'];
  requestUUID?: Maybe<Scalars['UUID']['output']>;
  requestor: Requestor;
  /** Link to the target portfolio for the algorithm execution */
  targetPortfolio?: Maybe<ModelPortfolio>;
  updateTime: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type RoboGoalAlgorithmExecutionConnection = {
  __typename?: 'RoboGoalAlgorithmExecutionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RoboGoalAlgorithmExecutionEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RoboGoalAlgorithmExecutionEdge = {
  __typename?: 'RoboGoalAlgorithmExecutionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<RoboGoalAlgorithmExecution>;
};

/** Ordering options for RoboGoalAlgorithmExecution connections */
export type RoboGoalAlgorithmExecutionOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order RoboGoalAlgorithmExecutions. */
  field: RoboGoalAlgorithmExecutionOrderField;
};

/** Properties by which RoboGoalAlgorithmExecution connections can be ordered. */
export enum RoboGoalAlgorithmExecutionOrderField {
  RequestTime = 'REQUEST_TIME',
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * RoboGoalAlgorithmExecutionWhereInput is used for filtering RoboGoalAlgorithmExecution objects.
 * Input was generated by ent.
 */
export type RoboGoalAlgorithmExecutionWhereInput = {
  and?: InputMaybe<Array<RoboGoalAlgorithmExecutionWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** parameters edge predicates */
  hasParameters?: InputMaybe<Scalars['Boolean']['input']>;
  hasParametersWith?: InputMaybe<Array<RoboGoalParametersWhereInput>>;
  /** projection edge predicates */
  hasProjection?: InputMaybe<Scalars['Boolean']['input']>;
  hasProjectionWith?: InputMaybe<Array<RoboGoalProjectionWhereInput>>;
  /** target_portfolio edge predicates */
  hasTargetPortfolio?: InputMaybe<Scalars['Boolean']['input']>;
  hasTargetPortfolioWith?: InputMaybe<Array<ModelPortfolioWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<RoboGoalAlgorithmExecutionWhereInput>;
  or?: InputMaybe<Array<RoboGoalAlgorithmExecutionWhereInput>>;
  /** request_time field predicates */
  requestTime?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  requestTimeLT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** request_uuid field predicates */
  requestUUID?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  requestUUIDLT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDLTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNEQ?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNotIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** requestor field predicates */
  requestor?: InputMaybe<Requestor>;
  requestorIn?: InputMaybe<Array<Requestor>>;
  requestorNEQ?: InputMaybe<Requestor>;
  requestorNotIn?: InputMaybe<Array<Requestor>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** A connection to a list of items. */
export type RoboGoalConnection = {
  __typename?: 'RoboGoalConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RoboGoalEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RoboGoalEdge = {
  __typename?: 'RoboGoalEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<RoboGoal>;
};

/** Ordering options for RoboGoal connections */
export type RoboGoalOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order RoboGoals. */
  field: RoboGoalOrderField;
};

/** Properties by which RoboGoal connections can be ordered. */
export enum RoboGoalOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

export type RoboGoalParameters = Node & {
  __typename?: 'RoboGoalParameters';
  /** Setting this parameter to true will ensure that GOE runs a calibration logic for the recommendations so that incorporating The recommendations would result in reaching the goal probability target. */
  calibrateRecommendations?: Maybe<Scalars['Boolean']['output']>;
  /** Defines whether the probability of mortality should be accounted for while calculating the probability of reaching the goal and the allocation advice */
  considerMortality: Scalars['Boolean']['output'];
  createTime: Scalars['Time']['output'];
  /** Defines the end date of goal. */
  endDate: Scalars['Date']['output'];
  /** executions where this parameter set was used. May be used in multiple executions. */
  execution?: Maybe<Array<RoboGoalAlgorithmExecution>>;
  /** Defines the importance a goal holds for a specific user. Order of priority is Need > Want > Wish > Dream. */
  goalPriority: RoboGoalParametersGoalPriority;
  id: Scalars['ID']['output'];
  /** the planned regular infusions amount */
  infusionAmount: Scalars['Decimal']['output'];
  /** the frequency of the planned regular infusions */
  infusionFreq: RoboGoalParametersRoboInfusionFreq;
  /** the date (year is ignored) on which infusions/withdrawals would be realized for the goal. */
  infusionStartDate?: Maybe<Scalars['Date']['output']>;
  /** Defines the initial investment amount to the goal. */
  initialInvestment: Scalars['Decimal']['output'];
  /** applies only to retirement use cases which specifies whether a participant is engaged or unengaged with a default value of true. */
  isEngagedParticipant?: Maybe<Scalars['Boolean']['output']>;
  /** MaxDrift is the maximium amount of drift the portfolio is allowed to make before an automatic rebalance is performed. */
  maxDrift?: Maybe<Scalars['Float']['output']>;
  /** indicates to disable GOE’s default de-risking logic. */
  overrideRisk?: Maybe<Scalars['Boolean']['output']>;
  /** the frequency at which the goal is reallocated. */
  reallocationFreq: RoboGoalParametersRoboReallocationFreq;
  /** The time at which the action was requested. */
  requestTime: Scalars['Time']['output'];
  requestUUID?: Maybe<Scalars['UUID']['output']>;
  requestor: Requestor;
  /** Captures the retirement age of the investor. */
  retirementAge: Scalars['Int']['output'];
  /** the risk profile override associated with this goal. */
  riskProfile: UserRiskTolerance;
  /** the type of the scenario, the suggested portfolio and the wealth glide path is created accordingly. 'regular' for regular goals accumulation goals where cash flows are positive (contributions) and with a typical target wealth 'retirement' for scenarios where a decumulation period is included. */
  scenarioType: RoboGoalParametersGoalScenarioType;
  /** Defines the start date of goal. */
  startDate: Scalars['Date']['output'];
  /** list of substitutions to apply to the recommended portfolio. */
  substitutions?: Maybe<Array<ModelPortfolioAssetSubstitution>>;
  /** Defines the target wealth value associated with the goal at end of goal tenure. */
  targetAmount: Scalars['Decimal']['output'];
  updateTime: Scalars['Time']['output'];
};

/** RoboGoalParametersGoalPriority is enum for the field goal_priority */
export enum RoboGoalParametersGoalPriority {
  GoalPriorityDream = 'GOAL_PRIORITY_DREAM',
  GoalPriorityNeed = 'GOAL_PRIORITY_NEED',
  GoalPriorityWant = 'GOAL_PRIORITY_WANT',
  GoalPriorityWish = 'GOAL_PRIORITY_WISH',
}

/** RoboGoalParametersGoalScenarioType is enum for the field scenario_type */
export enum RoboGoalParametersGoalScenarioType {
  GoalScenarioTypeRegular = 'GOAL_SCENARIO_TYPE_REGULAR',
  GoalScenarioTypeRetirement = 'GOAL_SCENARIO_TYPE_RETIREMENT',
}

/** Ordering options for RoboGoalParameters connections */
export type RoboGoalParametersOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order RoboGoalParametersSlice. */
  field: RoboGoalParametersOrderField;
};

/** Properties by which RoboGoalParameters connections can be ordered. */
export enum RoboGoalParametersOrderField {
  RequestTime = 'REQUEST_TIME',
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/** RoboGoalParametersRoboInfusionFreq is enum for the field infusion_freq */
export enum RoboGoalParametersRoboInfusionFreq {
  RoboInfusionFreqBiWeekly = 'ROBO_INFUSION_FREQ_BI_WEEKLY',
  RoboInfusionFreqMonthly = 'ROBO_INFUSION_FREQ_MONTHLY',
  RoboInfusionFreqSemiAnnually = 'ROBO_INFUSION_FREQ_SEMI_ANNUALLY',
  RoboInfusionFreqSemiMonthly = 'ROBO_INFUSION_FREQ_SEMI_MONTHLY',
  RoboInfusionFreqWeekly = 'ROBO_INFUSION_FREQ_WEEKLY',
  RoboInfusionFreqYearly = 'ROBO_INFUSION_FREQ_YEARLY',
}

/** RoboGoalParametersRoboReallocationFreq is enum for the field reallocation_freq */
export enum RoboGoalParametersRoboReallocationFreq {
  RoboReallocationFreqAnnually = 'ROBO_REALLOCATION_FREQ_ANNUALLY',
  RoboReallocationFreqQuarterly = 'ROBO_REALLOCATION_FREQ_QUARTERLY',
  RoboReallocationFreqSemiannually = 'ROBO_REALLOCATION_FREQ_SEMIANNUALLY',
}

/**
 * RoboGoalParametersWhereInput is used for filtering RoboGoalParameters objects.
 * Input was generated by ent.
 */
export type RoboGoalParametersWhereInput = {
  and?: InputMaybe<Array<RoboGoalParametersWhereInput>>;
  /** calibrate_recommendations field predicates */
  calibrateRecommendations?: InputMaybe<Scalars['Boolean']['input']>;
  calibrateRecommendationsIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  calibrateRecommendationsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  calibrateRecommendationsNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** consider_mortality field predicates */
  considerMortality?: InputMaybe<Scalars['Boolean']['input']>;
  considerMortalityNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** end_date field predicates */
  endDate?: InputMaybe<Scalars['Date']['input']>;
  endDateGT?: InputMaybe<Scalars['Date']['input']>;
  endDateGTE?: InputMaybe<Scalars['Date']['input']>;
  endDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  endDateLT?: InputMaybe<Scalars['Date']['input']>;
  endDateLTE?: InputMaybe<Scalars['Date']['input']>;
  endDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  endDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  /** goal_priority field predicates */
  goalPriority?: InputMaybe<RoboGoalParametersGoalPriority>;
  goalPriorityIn?: InputMaybe<Array<RoboGoalParametersGoalPriority>>;
  goalPriorityNEQ?: InputMaybe<RoboGoalParametersGoalPriority>;
  goalPriorityNotIn?: InputMaybe<Array<RoboGoalParametersGoalPriority>>;
  /** execution edge predicates */
  hasExecution?: InputMaybe<Scalars['Boolean']['input']>;
  hasExecutionWith?: InputMaybe<Array<RoboGoalAlgorithmExecutionWhereInput>>;
  /** substitutions edge predicates */
  hasSubstitutions?: InputMaybe<Scalars['Boolean']['input']>;
  hasSubstitutionsWith?: InputMaybe<Array<ModelPortfolioAssetSubstitutionWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** infusion_amount field predicates */
  infusionAmount?: InputMaybe<Scalars['Decimal']['input']>;
  infusionAmountGT?: InputMaybe<Scalars['Decimal']['input']>;
  infusionAmountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  infusionAmountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  infusionAmountLT?: InputMaybe<Scalars['Decimal']['input']>;
  infusionAmountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  infusionAmountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  infusionAmountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** infusion_freq field predicates */
  infusionFreq?: InputMaybe<RoboGoalParametersRoboInfusionFreq>;
  infusionFreqIn?: InputMaybe<Array<RoboGoalParametersRoboInfusionFreq>>;
  infusionFreqNEQ?: InputMaybe<RoboGoalParametersRoboInfusionFreq>;
  infusionFreqNotIn?: InputMaybe<Array<RoboGoalParametersRoboInfusionFreq>>;
  /** infusion_start_date field predicates */
  infusionStartDate?: InputMaybe<Scalars['Date']['input']>;
  infusionStartDateGT?: InputMaybe<Scalars['Date']['input']>;
  infusionStartDateGTE?: InputMaybe<Scalars['Date']['input']>;
  infusionStartDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  infusionStartDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  infusionStartDateLT?: InputMaybe<Scalars['Date']['input']>;
  infusionStartDateLTE?: InputMaybe<Scalars['Date']['input']>;
  infusionStartDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  infusionStartDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  infusionStartDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** initial_investment field predicates */
  initialInvestment?: InputMaybe<Scalars['Decimal']['input']>;
  initialInvestmentGT?: InputMaybe<Scalars['Decimal']['input']>;
  initialInvestmentGTE?: InputMaybe<Scalars['Decimal']['input']>;
  initialInvestmentIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  initialInvestmentLT?: InputMaybe<Scalars['Decimal']['input']>;
  initialInvestmentLTE?: InputMaybe<Scalars['Decimal']['input']>;
  initialInvestmentNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  initialInvestmentNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** is_engaged_participant field predicates */
  isEngagedParticipant?: InputMaybe<Scalars['Boolean']['input']>;
  isEngagedParticipantIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  isEngagedParticipantNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  isEngagedParticipantNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** max_drift field predicates */
  maxDrift?: InputMaybe<Scalars['Float']['input']>;
  maxDriftGT?: InputMaybe<Scalars['Float']['input']>;
  maxDriftGTE?: InputMaybe<Scalars['Float']['input']>;
  maxDriftIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  maxDriftIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  maxDriftLT?: InputMaybe<Scalars['Float']['input']>;
  maxDriftLTE?: InputMaybe<Scalars['Float']['input']>;
  maxDriftNEQ?: InputMaybe<Scalars['Float']['input']>;
  maxDriftNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  maxDriftNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<RoboGoalParametersWhereInput>;
  or?: InputMaybe<Array<RoboGoalParametersWhereInput>>;
  /** override_risk field predicates */
  overrideRisk?: InputMaybe<Scalars['Boolean']['input']>;
  overrideRiskIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  overrideRiskNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  overrideRiskNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** reallocation_freq field predicates */
  reallocationFreq?: InputMaybe<RoboGoalParametersRoboReallocationFreq>;
  reallocationFreqIn?: InputMaybe<Array<RoboGoalParametersRoboReallocationFreq>>;
  reallocationFreqNEQ?: InputMaybe<RoboGoalParametersRoboReallocationFreq>;
  reallocationFreqNotIn?: InputMaybe<Array<RoboGoalParametersRoboReallocationFreq>>;
  /** request_time field predicates */
  requestTime?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  requestTimeLT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** request_uuid field predicates */
  requestUUID?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  requestUUIDLT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDLTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNEQ?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNotIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** requestor field predicates */
  requestor?: InputMaybe<Requestor>;
  requestorIn?: InputMaybe<Array<Requestor>>;
  requestorNEQ?: InputMaybe<Requestor>;
  requestorNotIn?: InputMaybe<Array<Requestor>>;
  /** retirement_age field predicates */
  retirementAge?: InputMaybe<Scalars['Int']['input']>;
  retirementAgeGT?: InputMaybe<Scalars['Int']['input']>;
  retirementAgeGTE?: InputMaybe<Scalars['Int']['input']>;
  retirementAgeIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  retirementAgeLT?: InputMaybe<Scalars['Int']['input']>;
  retirementAgeLTE?: InputMaybe<Scalars['Int']['input']>;
  retirementAgeNEQ?: InputMaybe<Scalars['Int']['input']>;
  retirementAgeNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** risk_profile field predicates */
  riskProfile?: InputMaybe<UserRiskTolerance>;
  riskProfileIn?: InputMaybe<Array<UserRiskTolerance>>;
  riskProfileNEQ?: InputMaybe<UserRiskTolerance>;
  riskProfileNotIn?: InputMaybe<Array<UserRiskTolerance>>;
  /** scenario_type field predicates */
  scenarioType?: InputMaybe<RoboGoalParametersGoalScenarioType>;
  scenarioTypeIn?: InputMaybe<Array<RoboGoalParametersGoalScenarioType>>;
  scenarioTypeNEQ?: InputMaybe<RoboGoalParametersGoalScenarioType>;
  scenarioTypeNotIn?: InputMaybe<Array<RoboGoalParametersGoalScenarioType>>;
  /** start_date field predicates */
  startDate?: InputMaybe<Scalars['Date']['input']>;
  startDateGT?: InputMaybe<Scalars['Date']['input']>;
  startDateGTE?: InputMaybe<Scalars['Date']['input']>;
  startDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  startDateLT?: InputMaybe<Scalars['Date']['input']>;
  startDateLTE?: InputMaybe<Scalars['Date']['input']>;
  startDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  startDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  /** target_amount field predicates */
  targetAmount?: InputMaybe<Scalars['Decimal']['input']>;
  targetAmountGT?: InputMaybe<Scalars['Decimal']['input']>;
  targetAmountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  targetAmountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  targetAmountLT?: InputMaybe<Scalars['Decimal']['input']>;
  targetAmountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  targetAmountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  targetAmountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type RoboGoalProjection = Node & {
  __typename?: 'RoboGoalProjection';
  /** indicates the year relative to the goal start that goal is expected to bankrupt. Nil if not likley */
  bankruptcyPotentialYear?: Maybe<Scalars['Int']['output']>;
  createTime: Scalars['Time']['output'];
  execution: RoboGoalAlgorithmExecution;
  /** estimated probability to achieve the goal target wealth. */
  goalProbability: Scalars['Float']['output'];
  /** the desired probably of hitting the goal given the user's priority preference. */
  goalTargetProbability: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  /** To understand if the goal can be met with a reasonable probability. If current goal probability < desired target goal probability (defined by the goal priority), the goal is termed unrealistic. */
  isRealistic: Scalars['Boolean']['output'];
  /** the wealth amount that the investor does not want to end up below at the end of the goal tenure. */
  lossThreshold: Scalars['Float']['output'];
  /** estimated probability to remain above the loss threshold amount by the end of the goal tenure. */
  lossThresholdProbability: Scalars['Float']['output'];
  /** If target wealth were to be reduced by 50% of the difference between the target and the current wealth. */
  reduced50FinalWealth?: Maybe<Scalars['Float']['output']>;
  /** Goal probability corresponding to ‘reduced 50% final wealth’ above. */
  reduced50Prob?: Maybe<Scalars['Float']['output']>;
  /** If the target wealth were to be reduced by 25% of the difference between the target and the current wealth. */
  reduced75FinalWealth?: Maybe<Scalars['Float']['output']>;
  /** Goal probability corresponding to ‘reduced 75% final wealth’ above. */
  reduced75Prob?: Maybe<Scalars['Float']['output']>;
  /** Suggested recurring monthly top-up amount that aims to improve the goal probability to meet the target goal probability. */
  suggestedMonthlyTopUpAccumulation: Scalars['Float']['output'];
  /** Suggested recurring monthly decumulation amount that aims to improve the goal probability to meet the target goal probability. */
  suggestedMonthlyTopUpDecumulation: Scalars['Float']['output'];
  /** Suggested one-time top-up amount that aims to improve the goal probability to meet the target goal probability. */
  suggestedOneTimeTopUp: Scalars['Float']['output'];
  /** Suggested recurring yearly top-up amount that aims to improve the goal probability to meet the target goal probability. */
  suggestedYearlyTopUpAccumulation: Scalars['Float']['output'];
  /** Suggested recurring yearly decumulation amount that aims to improve the goal probability to meet the target goal probability. */
  suggestedYearlyTopUpDecumulation: Scalars['Float']['output'];
  /** probability changes based on tenure changes. Map is from increase in tenure years to updated probability. */
  tenureChangeRecommendations: Array<GoalTenureRecommendationProbability>;
  updateTime: Scalars['Time']['output'];
  /** the wealth path for the goal. */
  wealthPath: GoalValueProjections;
};

/** Ordering options for RoboGoalProjection connections */
export type RoboGoalProjectionOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order RoboGoalProjections. */
  field: RoboGoalProjectionOrderField;
};

/** Properties by which RoboGoalProjection connections can be ordered. */
export enum RoboGoalProjectionOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * RoboGoalProjectionWhereInput is used for filtering RoboGoalProjection objects.
 * Input was generated by ent.
 */
export type RoboGoalProjectionWhereInput = {
  and?: InputMaybe<Array<RoboGoalProjectionWhereInput>>;
  /** bankruptcy_potential_year field predicates */
  bankruptcyPotentialYear?: InputMaybe<Scalars['Int']['input']>;
  bankruptcyPotentialYearGT?: InputMaybe<Scalars['Int']['input']>;
  bankruptcyPotentialYearGTE?: InputMaybe<Scalars['Int']['input']>;
  bankruptcyPotentialYearIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  bankruptcyPotentialYearIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  bankruptcyPotentialYearLT?: InputMaybe<Scalars['Int']['input']>;
  bankruptcyPotentialYearLTE?: InputMaybe<Scalars['Int']['input']>;
  bankruptcyPotentialYearNEQ?: InputMaybe<Scalars['Int']['input']>;
  bankruptcyPotentialYearNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  bankruptcyPotentialYearNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** goal_probability field predicates */
  goalProbability?: InputMaybe<Scalars['Float']['input']>;
  goalProbabilityGT?: InputMaybe<Scalars['Float']['input']>;
  goalProbabilityGTE?: InputMaybe<Scalars['Float']['input']>;
  goalProbabilityIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  goalProbabilityLT?: InputMaybe<Scalars['Float']['input']>;
  goalProbabilityLTE?: InputMaybe<Scalars['Float']['input']>;
  goalProbabilityNEQ?: InputMaybe<Scalars['Float']['input']>;
  goalProbabilityNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** goal_target_probability field predicates */
  goalTargetProbability?: InputMaybe<Scalars['Float']['input']>;
  goalTargetProbabilityGT?: InputMaybe<Scalars['Float']['input']>;
  goalTargetProbabilityGTE?: InputMaybe<Scalars['Float']['input']>;
  goalTargetProbabilityIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  goalTargetProbabilityLT?: InputMaybe<Scalars['Float']['input']>;
  goalTargetProbabilityLTE?: InputMaybe<Scalars['Float']['input']>;
  goalTargetProbabilityNEQ?: InputMaybe<Scalars['Float']['input']>;
  goalTargetProbabilityNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** execution edge predicates */
  hasExecution?: InputMaybe<Scalars['Boolean']['input']>;
  hasExecutionWith?: InputMaybe<Array<RoboGoalAlgorithmExecutionWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_realistic field predicates */
  isRealistic?: InputMaybe<Scalars['Boolean']['input']>;
  isRealisticNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** loss_threshold field predicates */
  lossThreshold?: InputMaybe<Scalars['Float']['input']>;
  lossThresholdGT?: InputMaybe<Scalars['Float']['input']>;
  lossThresholdGTE?: InputMaybe<Scalars['Float']['input']>;
  lossThresholdIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  lossThresholdLT?: InputMaybe<Scalars['Float']['input']>;
  lossThresholdLTE?: InputMaybe<Scalars['Float']['input']>;
  lossThresholdNEQ?: InputMaybe<Scalars['Float']['input']>;
  lossThresholdNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** loss_threshold_probability field predicates */
  lossThresholdProbability?: InputMaybe<Scalars['Float']['input']>;
  lossThresholdProbabilityGT?: InputMaybe<Scalars['Float']['input']>;
  lossThresholdProbabilityGTE?: InputMaybe<Scalars['Float']['input']>;
  lossThresholdProbabilityIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  lossThresholdProbabilityLT?: InputMaybe<Scalars['Float']['input']>;
  lossThresholdProbabilityLTE?: InputMaybe<Scalars['Float']['input']>;
  lossThresholdProbabilityNEQ?: InputMaybe<Scalars['Float']['input']>;
  lossThresholdProbabilityNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  not?: InputMaybe<RoboGoalProjectionWhereInput>;
  or?: InputMaybe<Array<RoboGoalProjectionWhereInput>>;
  /** reduced_50_final_wealth field predicates */
  reduced50FinalWealth?: InputMaybe<Scalars['Float']['input']>;
  reduced50FinalWealthGT?: InputMaybe<Scalars['Float']['input']>;
  reduced50FinalWealthGTE?: InputMaybe<Scalars['Float']['input']>;
  reduced50FinalWealthIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  reduced50FinalWealthIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  reduced50FinalWealthLT?: InputMaybe<Scalars['Float']['input']>;
  reduced50FinalWealthLTE?: InputMaybe<Scalars['Float']['input']>;
  reduced50FinalWealthNEQ?: InputMaybe<Scalars['Float']['input']>;
  reduced50FinalWealthNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  reduced50FinalWealthNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** reduced_50_prob field predicates */
  reduced50Prob?: InputMaybe<Scalars['Float']['input']>;
  reduced50ProbGT?: InputMaybe<Scalars['Float']['input']>;
  reduced50ProbGTE?: InputMaybe<Scalars['Float']['input']>;
  reduced50ProbIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  reduced50ProbIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  reduced50ProbLT?: InputMaybe<Scalars['Float']['input']>;
  reduced50ProbLTE?: InputMaybe<Scalars['Float']['input']>;
  reduced50ProbNEQ?: InputMaybe<Scalars['Float']['input']>;
  reduced50ProbNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  reduced50ProbNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** reduced_75_final_wealth field predicates */
  reduced75FinalWealth?: InputMaybe<Scalars['Float']['input']>;
  reduced75FinalWealthGT?: InputMaybe<Scalars['Float']['input']>;
  reduced75FinalWealthGTE?: InputMaybe<Scalars['Float']['input']>;
  reduced75FinalWealthIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  reduced75FinalWealthIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  reduced75FinalWealthLT?: InputMaybe<Scalars['Float']['input']>;
  reduced75FinalWealthLTE?: InputMaybe<Scalars['Float']['input']>;
  reduced75FinalWealthNEQ?: InputMaybe<Scalars['Float']['input']>;
  reduced75FinalWealthNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  reduced75FinalWealthNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** reduced_75_prob field predicates */
  reduced75Prob?: InputMaybe<Scalars['Float']['input']>;
  reduced75ProbGT?: InputMaybe<Scalars['Float']['input']>;
  reduced75ProbGTE?: InputMaybe<Scalars['Float']['input']>;
  reduced75ProbIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  reduced75ProbIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  reduced75ProbLT?: InputMaybe<Scalars['Float']['input']>;
  reduced75ProbLTE?: InputMaybe<Scalars['Float']['input']>;
  reduced75ProbNEQ?: InputMaybe<Scalars['Float']['input']>;
  reduced75ProbNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  reduced75ProbNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** suggested_monthly_top_up_accumulation field predicates */
  suggestedMonthlyTopUpAccumulation?: InputMaybe<Scalars['Float']['input']>;
  suggestedMonthlyTopUpAccumulationGT?: InputMaybe<Scalars['Float']['input']>;
  suggestedMonthlyTopUpAccumulationGTE?: InputMaybe<Scalars['Float']['input']>;
  suggestedMonthlyTopUpAccumulationIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  suggestedMonthlyTopUpAccumulationLT?: InputMaybe<Scalars['Float']['input']>;
  suggestedMonthlyTopUpAccumulationLTE?: InputMaybe<Scalars['Float']['input']>;
  suggestedMonthlyTopUpAccumulationNEQ?: InputMaybe<Scalars['Float']['input']>;
  suggestedMonthlyTopUpAccumulationNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** suggested_monthly_top_up_decumulation field predicates */
  suggestedMonthlyTopUpDecumulation?: InputMaybe<Scalars['Float']['input']>;
  suggestedMonthlyTopUpDecumulationGT?: InputMaybe<Scalars['Float']['input']>;
  suggestedMonthlyTopUpDecumulationGTE?: InputMaybe<Scalars['Float']['input']>;
  suggestedMonthlyTopUpDecumulationIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  suggestedMonthlyTopUpDecumulationLT?: InputMaybe<Scalars['Float']['input']>;
  suggestedMonthlyTopUpDecumulationLTE?: InputMaybe<Scalars['Float']['input']>;
  suggestedMonthlyTopUpDecumulationNEQ?: InputMaybe<Scalars['Float']['input']>;
  suggestedMonthlyTopUpDecumulationNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** suggested_one_time_top_up field predicates */
  suggestedOneTimeTopUp?: InputMaybe<Scalars['Float']['input']>;
  suggestedOneTimeTopUpGT?: InputMaybe<Scalars['Float']['input']>;
  suggestedOneTimeTopUpGTE?: InputMaybe<Scalars['Float']['input']>;
  suggestedOneTimeTopUpIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  suggestedOneTimeTopUpLT?: InputMaybe<Scalars['Float']['input']>;
  suggestedOneTimeTopUpLTE?: InputMaybe<Scalars['Float']['input']>;
  suggestedOneTimeTopUpNEQ?: InputMaybe<Scalars['Float']['input']>;
  suggestedOneTimeTopUpNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** suggested_yearly_top_up_accumulation field predicates */
  suggestedYearlyTopUpAccumulation?: InputMaybe<Scalars['Float']['input']>;
  suggestedYearlyTopUpAccumulationGT?: InputMaybe<Scalars['Float']['input']>;
  suggestedYearlyTopUpAccumulationGTE?: InputMaybe<Scalars['Float']['input']>;
  suggestedYearlyTopUpAccumulationIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  suggestedYearlyTopUpAccumulationLT?: InputMaybe<Scalars['Float']['input']>;
  suggestedYearlyTopUpAccumulationLTE?: InputMaybe<Scalars['Float']['input']>;
  suggestedYearlyTopUpAccumulationNEQ?: InputMaybe<Scalars['Float']['input']>;
  suggestedYearlyTopUpAccumulationNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** suggested_yearly_top_up_decumulation field predicates */
  suggestedYearlyTopUpDecumulation?: InputMaybe<Scalars['Float']['input']>;
  suggestedYearlyTopUpDecumulationGT?: InputMaybe<Scalars['Float']['input']>;
  suggestedYearlyTopUpDecumulationGTE?: InputMaybe<Scalars['Float']['input']>;
  suggestedYearlyTopUpDecumulationIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  suggestedYearlyTopUpDecumulationLT?: InputMaybe<Scalars['Float']['input']>;
  suggestedYearlyTopUpDecumulationLTE?: InputMaybe<Scalars['Float']['input']>;
  suggestedYearlyTopUpDecumulationNEQ?: InputMaybe<Scalars['Float']['input']>;
  suggestedYearlyTopUpDecumulationNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** RoboGoalRoboAccountType is enum for the field account_type */
export enum RoboGoalRoboAccountType {
  RoboAccountTypeGeneral = 'ROBO_ACCOUNT_TYPE_GENERAL',
  RoboAccountTypeRetirmentIra = 'ROBO_ACCOUNT_TYPE_RETIRMENT_IRA',
  RoboAccountTypeRetirmentRothIra = 'ROBO_ACCOUNT_TYPE_RETIRMENT_ROTH_IRA',
}

/**
 * RoboGoalWhereInput is used for filtering RoboGoal objects.
 * Input was generated by ent.
 */
export type RoboGoalWhereInput = {
  /** account_portfolio_id field predicates */
  accountPortfolioID?: InputMaybe<Scalars['ID']['input']>;
  accountPortfolioIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  accountPortfolioIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  accountPortfolioIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** account_type field predicates */
  accountType?: InputMaybe<RoboGoalRoboAccountType>;
  accountTypeIn?: InputMaybe<Array<RoboGoalRoboAccountType>>;
  accountTypeNEQ?: InputMaybe<RoboGoalRoboAccountType>;
  accountTypeNotIn?: InputMaybe<Array<RoboGoalRoboAccountType>>;
  and?: InputMaybe<Array<RoboGoalWhereInput>>;
  /** auto_infusion_enabled field predicates */
  autoInfusionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  autoInfusionEnabledIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  autoInfusionEnabledNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  autoInfusionEnabledNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** complete_at field predicates */
  completeAt?: InputMaybe<Scalars['Time']['input']>;
  completeAtGT?: InputMaybe<Scalars['Time']['input']>;
  completeAtGTE?: InputMaybe<Scalars['Time']['input']>;
  completeAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  completeAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  completeAtLT?: InputMaybe<Scalars['Time']['input']>;
  completeAtLTE?: InputMaybe<Scalars['Time']['input']>;
  completeAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  completeAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  completeAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** account_portfolio edge predicates */
  hasAccountPortfolio?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountPortfolioWith?: InputMaybe<Array<UserAccountPortfolioWhereInput>>;
  /** actions edge predicates */
  hasActions?: InputMaybe<Scalars['Boolean']['input']>;
  hasActionsWith?: InputMaybe<Array<RoboInvestmentActionWhereInput>>;
  /** algorithm_execution_history edge predicates */
  hasAlgorithmExecutionHistory?: InputMaybe<Scalars['Boolean']['input']>;
  hasAlgorithmExecutionHistoryWith?: InputMaybe<Array<RoboGoalAlgorithmExecutionWhereInput>>;
  /** funding_account edge predicates */
  hasFundingAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasFundingAccountWith?: InputMaybe<Array<ExternalAccountWhereInput>>;
  /** infusion_recurring_rule edge predicates */
  hasInfusionRecurringRule?: InputMaybe<Scalars['Boolean']['input']>;
  /** infusion_recurring_rule_history edge predicates */
  hasInfusionRecurringRuleHistory?: InputMaybe<Scalars['Boolean']['input']>;
  hasInfusionRecurringRuleHistoryWith?: InputMaybe<Array<RecurringRuleWhereInput>>;
  hasInfusionRecurringRuleWith?: InputMaybe<Array<RecurringRuleWhereInput>>;
  /** parameters edge predicates */
  hasParameters?: InputMaybe<Scalars['Boolean']['input']>;
  hasParametersWith?: InputMaybe<Array<RoboGoalParametersWhereInput>>;
  /** projection edge predicates */
  hasProjection?: InputMaybe<Scalars['Boolean']['input']>;
  hasProjectionWith?: InputMaybe<Array<RoboGoalProjectionWhereInput>>;
  /** target_portfolio edge predicates */
  hasTargetPortfolio?: InputMaybe<Scalars['Boolean']['input']>;
  hasTargetPortfolioWith?: InputMaybe<Array<ModelPortfolioWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_active field predicates */
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isActiveNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** is_auto_infusion_paused field predicates */
  isAutoInfusionPaused?: InputMaybe<Scalars['Boolean']['input']>;
  isAutoInfusionPausedNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** last_projection_refresh field predicates */
  lastProjectionRefresh?: InputMaybe<Scalars['Time']['input']>;
  lastProjectionRefreshGT?: InputMaybe<Scalars['Time']['input']>;
  lastProjectionRefreshGTE?: InputMaybe<Scalars['Time']['input']>;
  lastProjectionRefreshIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  lastProjectionRefreshIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  lastProjectionRefreshLT?: InputMaybe<Scalars['Time']['input']>;
  lastProjectionRefreshLTE?: InputMaybe<Scalars['Time']['input']>;
  lastProjectionRefreshNEQ?: InputMaybe<Scalars['Time']['input']>;
  lastProjectionRefreshNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  lastProjectionRefreshNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** last_rebalance_time field predicates */
  lastRebalanceTime?: InputMaybe<Scalars['Time']['input']>;
  lastRebalanceTimeGT?: InputMaybe<Scalars['Time']['input']>;
  lastRebalanceTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  lastRebalanceTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  lastRebalanceTimeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  lastRebalanceTimeLT?: InputMaybe<Scalars['Time']['input']>;
  lastRebalanceTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  lastRebalanceTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  lastRebalanceTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  lastRebalanceTimeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** manual_reallocation_count field predicates */
  manualReallocationCount?: InputMaybe<Scalars['Int']['input']>;
  manualReallocationCountGT?: InputMaybe<Scalars['Int']['input']>;
  manualReallocationCountGTE?: InputMaybe<Scalars['Int']['input']>;
  manualReallocationCountIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  manualReallocationCountLT?: InputMaybe<Scalars['Int']['input']>;
  manualReallocationCountLTE?: InputMaybe<Scalars['Int']['input']>;
  manualReallocationCountNEQ?: InputMaybe<Scalars['Int']['input']>;
  manualReallocationCountNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** manual_rebalance_count field predicates */
  manualRebalanceCount?: InputMaybe<Scalars['Int']['input']>;
  manualRebalanceCountGT?: InputMaybe<Scalars['Int']['input']>;
  manualRebalanceCountGTE?: InputMaybe<Scalars['Int']['input']>;
  manualRebalanceCountIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  manualRebalanceCountLT?: InputMaybe<Scalars['Int']['input']>;
  manualRebalanceCountLTE?: InputMaybe<Scalars['Int']['input']>;
  manualRebalanceCountNEQ?: InputMaybe<Scalars['Int']['input']>;
  manualRebalanceCountNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** next_scheduled_infusion field predicates */
  nextScheduledInfusion?: InputMaybe<Scalars['Time']['input']>;
  nextScheduledInfusionGT?: InputMaybe<Scalars['Time']['input']>;
  nextScheduledInfusionGTE?: InputMaybe<Scalars['Time']['input']>;
  nextScheduledInfusionIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  nextScheduledInfusionLT?: InputMaybe<Scalars['Time']['input']>;
  nextScheduledInfusionLTE?: InputMaybe<Scalars['Time']['input']>;
  nextScheduledInfusionNEQ?: InputMaybe<Scalars['Time']['input']>;
  nextScheduledInfusionNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  not?: InputMaybe<RoboGoalWhereInput>;
  or?: InputMaybe<Array<RoboGoalWhereInput>>;
  /** target_portfolio_id field predicates */
  targetPortfolioID?: InputMaybe<Scalars['ID']['input']>;
  targetPortfolioIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  targetPortfolioIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  targetPortfolioIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** target_schedule_infusion field predicates */
  targetScheduleInfusion?: InputMaybe<Scalars['Time']['input']>;
  targetScheduleInfusionGT?: InputMaybe<Scalars['Time']['input']>;
  targetScheduleInfusionGTE?: InputMaybe<Scalars['Time']['input']>;
  targetScheduleInfusionIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  targetScheduleInfusionLT?: InputMaybe<Scalars['Time']['input']>;
  targetScheduleInfusionLTE?: InputMaybe<Scalars['Time']['input']>;
  targetScheduleInfusionNEQ?: InputMaybe<Scalars['Time']['input']>;
  targetScheduleInfusionNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** total_dividends field predicates */
  totalDividends?: InputMaybe<Scalars['Decimal']['input']>;
  totalDividendsGT?: InputMaybe<Scalars['Decimal']['input']>;
  totalDividendsGTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalDividendsIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  totalDividendsLT?: InputMaybe<Scalars['Decimal']['input']>;
  totalDividendsLTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalDividendsNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  totalDividendsNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** total_fees field predicates */
  totalFees?: InputMaybe<Scalars['Decimal']['input']>;
  totalFeesGT?: InputMaybe<Scalars['Decimal']['input']>;
  totalFeesGTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalFeesIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  totalFeesLT?: InputMaybe<Scalars['Decimal']['input']>;
  totalFeesLTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalFeesNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  totalFeesNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** total_infusions field predicates */
  totalInfusions?: InputMaybe<Scalars['Decimal']['input']>;
  totalInfusionsGT?: InputMaybe<Scalars['Decimal']['input']>;
  totalInfusionsGTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalInfusionsIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  totalInfusionsLT?: InputMaybe<Scalars['Decimal']['input']>;
  totalInfusionsLTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalInfusionsNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  totalInfusionsNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** total_interests field predicates */
  totalInterests?: InputMaybe<Scalars['Decimal']['input']>;
  totalInterestsGT?: InputMaybe<Scalars['Decimal']['input']>;
  totalInterestsGTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalInterestsIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  totalInterestsLT?: InputMaybe<Scalars['Decimal']['input']>;
  totalInterestsLTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalInterestsNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  totalInterestsNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** total_withdrawals field predicates */
  totalWithdrawals?: InputMaybe<Scalars['Decimal']['input']>;
  totalWithdrawalsGT?: InputMaybe<Scalars['Decimal']['input']>;
  totalWithdrawalsGTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalWithdrawalsIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  totalWithdrawalsLT?: InputMaybe<Scalars['Decimal']['input']>;
  totalWithdrawalsLTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalWithdrawalsNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  totalWithdrawalsNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** RoboInterestDetails is an interest distribution that is reinvested back into the robo investment. */
export type RoboInterestDetails = {
  __typename?: 'RoboInterestDetails';
  amount: Scalars['Decimal']['output'];
  source: InterestDistributionInterestSource;
};

export type RoboInvestmentAction = Node & {
  __typename?: 'RoboInvestmentAction';
  createTime: Scalars['Time']['output'];
  /** details contains extra information about the action. */
  details?: Maybe<RoboInvestmentActionDetails>;
  generalInvestment?: Maybe<RoboGeneralInvestment>;
  goal?: Maybe<RoboGoal>;
  id: Scalars['ID']['output'];
  /** Optional Note about the action taken */
  note?: Maybe<Scalars['String']['output']>;
  orders: Array<BaseOrder>;
  /** The time at which the action was requested. */
  requestTime: Scalars['Time']['output'];
  requestUUID?: Maybe<Scalars['UUID']['output']>;
  requestor: Requestor;
  /** Status of the action */
  status: RoboInvestmentActionRoboActionStatus;
  type: RoboInvestmentActionRoboActivityType;
  updateTime: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type RoboInvestmentActionConnection = {
  __typename?: 'RoboInvestmentActionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RoboInvestmentActionEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** RoboActivity is the union of all possible activities that can affect a robo investment */
export type RoboInvestmentActionDetails =
  | RoboClosureDetails
  | RoboDividendDetails
  | RoboFeeDetails
  | RoboGoalAlgorithmExecution
  | RoboInterestDetails
  | RoboTransferDetails;

/** An edge in a connection. */
export type RoboInvestmentActionEdge = {
  __typename?: 'RoboInvestmentActionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<RoboInvestmentAction>;
};

/** Ordering options for RoboInvestmentAction connections */
export type RoboInvestmentActionOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order RoboInvestmentActions. */
  field: RoboInvestmentActionOrderField;
};

/** Properties by which RoboInvestmentAction connections can be ordered. */
export enum RoboInvestmentActionOrderField {
  RequestTime = 'REQUEST_TIME',
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/** RoboInvestmentActionRoboActionStatus is enum for the field status */
export enum RoboInvestmentActionRoboActionStatus {
  RoboActionStatusCanceled = 'ROBO_ACTION_STATUS_CANCELED',
  RoboActionStatusCanceling = 'ROBO_ACTION_STATUS_CANCELING',
  RoboActionStatusDone = 'ROBO_ACTION_STATUS_DONE',
  RoboActionStatusFailed = 'ROBO_ACTION_STATUS_FAILED',
  RoboActionStatusInNew = 'ROBO_ACTION_STATUS_IN_NEW',
  RoboActionStatusInProgress = 'ROBO_ACTION_STATUS_IN_PROGRESS',
}

/** RoboInvestmentActionRoboActivityType is enum for the field type */
export enum RoboInvestmentActionRoboActivityType {
  RoboActivityTypeClosure = 'ROBO_ACTIVITY_TYPE_CLOSURE',
  RoboActivityTypeCreation = 'ROBO_ACTIVITY_TYPE_CREATION',
  RoboActivityTypeDividend = 'ROBO_ACTIVITY_TYPE_DIVIDEND',
  RoboActivityTypeFee = 'ROBO_ACTIVITY_TYPE_FEE',
  RoboActivityTypeGoalComplete = 'ROBO_ACTIVITY_TYPE_GOAL_COMPLETE',
  RoboActivityTypeInfusion = 'ROBO_ACTIVITY_TYPE_INFUSION',
  RoboActivityTypeInterest = 'ROBO_ACTIVITY_TYPE_INTEREST',
  RoboActivityTypeModelPortfolioChange = 'ROBO_ACTIVITY_TYPE_MODEL_PORTFOLIO_CHANGE',
  RoboActivityTypeParameterChange = 'ROBO_ACTIVITY_TYPE_PARAMETER_CHANGE',
  RoboActivityTypeReallocation = 'ROBO_ACTIVITY_TYPE_REALLOCATION',
  RoboActivityTypeRebalance = 'ROBO_ACTIVITY_TYPE_REBALANCE',
  RoboActivityTypeWithdraw = 'ROBO_ACTIVITY_TYPE_WITHDRAW',
}

/**
 * RoboInvestmentActionWhereInput is used for filtering RoboInvestmentAction objects.
 * Input was generated by ent.
 */
export type RoboInvestmentActionWhereInput = {
  and?: InputMaybe<Array<RoboInvestmentActionWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** general_investment edge predicates */
  hasGeneralInvestment?: InputMaybe<Scalars['Boolean']['input']>;
  hasGeneralInvestmentWith?: InputMaybe<Array<RoboGeneralInvestmentWhereInput>>;
  /** goal edge predicates */
  hasGoal?: InputMaybe<Scalars['Boolean']['input']>;
  hasGoalWith?: InputMaybe<Array<RoboGoalWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<RoboInvestmentActionWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<RoboInvestmentActionWhereInput>>;
  /** request_time field predicates */
  requestTime?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  requestTimeLT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** request_uuid field predicates */
  requestUUID?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  requestUUIDLT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDLTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNEQ?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNotIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** requestor field predicates */
  requestor?: InputMaybe<Requestor>;
  requestorIn?: InputMaybe<Array<Requestor>>;
  requestorNEQ?: InputMaybe<Requestor>;
  requestorNotIn?: InputMaybe<Array<Requestor>>;
  /** status field predicates */
  status?: InputMaybe<RoboInvestmentActionRoboActionStatus>;
  statusIn?: InputMaybe<Array<RoboInvestmentActionRoboActionStatus>>;
  statusNEQ?: InputMaybe<RoboInvestmentActionRoboActionStatus>;
  statusNotIn?: InputMaybe<Array<RoboInvestmentActionRoboActionStatus>>;
  /** type field predicates */
  type?: InputMaybe<RoboInvestmentActionRoboActivityType>;
  typeIn?: InputMaybe<Array<RoboInvestmentActionRoboActivityType>>;
  typeNEQ?: InputMaybe<RoboInvestmentActionRoboActivityType>;
  typeNotIn?: InputMaybe<Array<RoboInvestmentActionRoboActivityType>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** RoboTransferDetails is any event where value is transfered into or out of a robo investment. */
export type RoboTransferDetails = {
  __typename?: 'RoboTransferDetails';
  /** the amount that was transferred into or out of the robo investment */
  amount: Scalars['Decimal']['output'];
  /** ExpectedSettlement is the date that the transfer is expected to settle. */
  expectedSettlementDate?: Maybe<Scalars['Date']['output']>;
  /** expectedTradeDate is the date that the proceeeds of the transfer will be involved trades. */
  expectedTradeDate?: Maybe<Scalars['Date']['output']>;
};

export enum RoleRestriction {
  Admin = 'ADMIN',
  User = 'USER',
}

export type SsaLetter = {
  __typename?: 'SSALetter';
  documentID?: Maybe<Scalars['String']['output']>;
};

export type SsnCard = {
  __typename?: 'SSNCard';
  back?: Maybe<Scalars['String']['output']>;
  front?: Maybe<Scalars['String']['output']>;
};

export type SecurityAsset = BaseAsset &
  Node & {
    __typename?: 'SecurityAsset';
    /** active indicates if the asset is currently active/exists. Examples of an inactive asset would be a delisted stock or a token that no longer exists. */
    active: Scalars['Boolean']['output'];
    askExchange?: Maybe<SecurityExchange>;
    /** beta is a measure of the volatility, or systematic risk, of a security or a portfolio in comparison to the market as a whole. */
    beta: Scalars['Decimal']['output'];
    bidExchange?: Maybe<SecurityExchange>;
    /** Background color to use when displaying in the app */
    color?: Maybe<Scalars['String']['output']>;
    complexSecurity: Scalars['Boolean']['output'];
    createTime: Scalars['Time']['output'];
    /** CUSIP is a 9 character alphanumeric that identifies a security. */
    cusip: Scalars['String']['output'];
    /** Description of the asset */
    description?: Maybe<Scalars['String']['output']>;
    /** contains extra information relevant to the security subtype */
    details?: Maybe<SecurityAssetDetails>;
    distributionFrequency: SecurityAssetSecurityDistributionFrequency;
    dividendTrailingTwelveMonths?: Maybe<Scalars['Decimal']['output']>;
    dividendYield?: Maybe<Scalars['Decimal']['output']>;
    etfDetails?: Maybe<EtfDetails>;
    financialStatementDate?: Maybe<Scalars['Time']['output']>;
    financials?: Maybe<SecurityFinancials>;
    fixedIncomeDetails?: Maybe<SecurityFixedIncomeDetails>;
    fixedIncomeMarket?: Maybe<FixedIncomeMarketData>;
    /** hidden is used to determine if the asset should be displayed in the app */
    hidden: Scalars['Boolean']['output'];
    highlyVolatile: Scalars['Boolean']['output'];
    /** image to show when displaying the asset */
    iconURI?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    /**
     * image to show when displaying the asset
     * @deprecated No longer used.
     */
    imageLogo?: Maybe<Scalars['String']['output']>;
    isFractionable: Scalars['Boolean']['output'];
    legacyID?: Maybe<Scalars['Int']['output']>;
    /** image to show when displaying the asset */
    logoURI?: Maybe<Scalars['String']['output']>;
    market?: Maybe<AssetMarketData>;
    /** market cap is the total market value of a company's outstanding shares of stock. */
    marketCap?: Maybe<Scalars['Decimal']['output']>;
    mutualFundDetails?: Maybe<MutualFundDetails>;
    name: Scalars['String']['output'];
    nbboQuote?: Maybe<NbboQuote>;
    news: Array<AssetNews>;
    outstandingShares: Scalars['Int']['output'];
    peRatio?: Maybe<Scalars['Decimal']['output']>;
    revenue?: Maybe<Scalars['Decimal']['output']>;
    sector?: Maybe<SecuritySector>;
    securityType: SecurityAssetSecurityType;
    symbol: Scalars['String']['output'];
    type: AssetAssetType;
    updateTime: Scalars['Time']['output'];
    website: Scalars['String']['output'];
  };

export type SecurityAssetNbboQuoteArgs = {
  isBuy: Scalars['Boolean']['input'];
};

export type SecurityAssetNewsArgs = {
  limit: Scalars['Int']['input'];
};

/** A connection to a list of items. */
export type SecurityAssetConnection = {
  __typename?: 'SecurityAssetConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SecurityAssetEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** SecurityAssetDetails are extra details in the SecurityAsset */
export type SecurityAssetDetails =
  | SecurityEtfDetails
  | SecurityFixedIncomeDetails
  | SecurityMutualFundDetails;

/** An edge in a connection. */
export type SecurityAssetEdge = {
  __typename?: 'SecurityAssetEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SecurityAsset>;
};

/** Ordering options for SecurityAsset connections */
export type SecurityAssetOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order SecurityAssets. */
  field: SecurityAssetOrderField;
};

/** Properties by which SecurityAsset connections can be ordered. */
export enum SecurityAssetOrderField {
  FixedIncomeDetailsIsCallable = 'FIXED_INCOME_DETAILS_isCallable',
  CreateTime = 'create_time',
  DividendYield = 'dividend_yield',
  MarketCap = 'market_cap',
  Name = 'name',
  PeRatio = 'pe_ratio',
  Revenue = 'revenue',
  Symbol = 'symbol',
  UpdateTime = 'update_time',
}

/** SecurityAssetSecurityDistributionFrequency is enum for the field distribution_frequency */
export enum SecurityAssetSecurityDistributionFrequency {
  SecurityDistributionFrequencyAnnually = 'SECURITY_DISTRIBUTION_FREQUENCY_ANNUALLY',
  SecurityDistributionFrequencyBiannually = 'SECURITY_DISTRIBUTION_FREQUENCY_BIANNUALLY',
  SecurityDistributionFrequencyMonthly = 'SECURITY_DISTRIBUTION_FREQUENCY_MONTHLY',
  SecurityDistributionFrequencyNever = 'SECURITY_DISTRIBUTION_FREQUENCY_NEVER',
  SecurityDistributionFrequencyOnce = 'SECURITY_DISTRIBUTION_FREQUENCY_ONCE',
  SecurityDistributionFrequencyQuarterly = 'SECURITY_DISTRIBUTION_FREQUENCY_QUARTERLY',
}

/** SecurityAssetSecurityType is enum for the field security_type */
export enum SecurityAssetSecurityType {
  Cs = 'CS',
  Cvr = 'CVR',
  Etf = 'ETF',
  Etn = 'ETN',
  FixedIncome = 'FIXED_INCOME',
  MutualFund = 'MUTUAL_FUND',
  Pfd = 'PFD',
}

/**
 * SecurityAssetWhereInput is used for filtering SecurityAsset objects.
 * Input was generated by ent.
 */
export type SecurityAssetWhereInput = {
  /** active field predicates */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  activeNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<SecurityAssetWhereInput>>;
  /** color field predicates */
  color?: InputMaybe<Scalars['String']['input']>;
  colorContains?: InputMaybe<Scalars['String']['input']>;
  colorContainsFold?: InputMaybe<Scalars['String']['input']>;
  colorEqualFold?: InputMaybe<Scalars['String']['input']>;
  colorGT?: InputMaybe<Scalars['String']['input']>;
  colorGTE?: InputMaybe<Scalars['String']['input']>;
  colorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  colorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  colorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  colorIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  colorLT?: InputMaybe<Scalars['String']['input']>;
  colorLTE?: InputMaybe<Scalars['String']['input']>;
  colorNEQ?: InputMaybe<Scalars['String']['input']>;
  colorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  colorNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** complex_security field predicates */
  complexSecurity?: InputMaybe<Scalars['Boolean']['input']>;
  complexSecurityNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** CUSIP field predicates */
  cusip?: InputMaybe<Scalars['String']['input']>;
  cusipContains?: InputMaybe<Scalars['String']['input']>;
  cusipContainsFold?: InputMaybe<Scalars['String']['input']>;
  cusipEqualFold?: InputMaybe<Scalars['String']['input']>;
  cusipGT?: InputMaybe<Scalars['String']['input']>;
  cusipGTE?: InputMaybe<Scalars['String']['input']>;
  cusipHasPrefix?: InputMaybe<Scalars['String']['input']>;
  cusipHasSuffix?: InputMaybe<Scalars['String']['input']>;
  cusipIn?: InputMaybe<Array<Scalars['String']['input']>>;
  cusipLT?: InputMaybe<Scalars['String']['input']>;
  cusipLTE?: InputMaybe<Scalars['String']['input']>;
  cusipNEQ?: InputMaybe<Scalars['String']['input']>;
  cusipNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** distribution_frequency field predicates */
  distributionFrequency?: InputMaybe<SecurityAssetSecurityDistributionFrequency>;
  distributionFrequencyIn?: InputMaybe<Array<SecurityAssetSecurityDistributionFrequency>>;
  distributionFrequencyNEQ?: InputMaybe<SecurityAssetSecurityDistributionFrequency>;
  distributionFrequencyNotIn?: InputMaybe<Array<SecurityAssetSecurityDistributionFrequency>>;
  /** dividend_trailing_twelve_months field predicates */
  dividendTrailingTwelveMonths?: InputMaybe<Scalars['Decimal']['input']>;
  dividendTrailingTwelveMonthsGT?: InputMaybe<Scalars['Decimal']['input']>;
  dividendTrailingTwelveMonthsGTE?: InputMaybe<Scalars['Decimal']['input']>;
  dividendTrailingTwelveMonthsIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  dividendTrailingTwelveMonthsIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  dividendTrailingTwelveMonthsLT?: InputMaybe<Scalars['Decimal']['input']>;
  dividendTrailingTwelveMonthsLTE?: InputMaybe<Scalars['Decimal']['input']>;
  dividendTrailingTwelveMonthsNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  dividendTrailingTwelveMonthsNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  dividendTrailingTwelveMonthsNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** dividend_yield field predicates */
  dividendYield?: InputMaybe<Scalars['Decimal']['input']>;
  dividendYieldGT?: InputMaybe<Scalars['Decimal']['input']>;
  dividendYieldGTE?: InputMaybe<Scalars['Decimal']['input']>;
  dividendYieldIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  dividendYieldIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  dividendYieldLT?: InputMaybe<Scalars['Decimal']['input']>;
  dividendYieldLTE?: InputMaybe<Scalars['Decimal']['input']>;
  dividendYieldNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  dividendYieldNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  dividendYieldNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** financial_statement_date field predicates */
  financialStatementDate?: InputMaybe<Scalars['Time']['input']>;
  financialStatementDateGT?: InputMaybe<Scalars['Time']['input']>;
  financialStatementDateGTE?: InputMaybe<Scalars['Time']['input']>;
  financialStatementDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  financialStatementDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  financialStatementDateLT?: InputMaybe<Scalars['Time']['input']>;
  financialStatementDateLTE?: InputMaybe<Scalars['Time']['input']>;
  financialStatementDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  financialStatementDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  financialStatementDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** ask_exchange edge predicates */
  hasAskExchange?: InputMaybe<Scalars['Boolean']['input']>;
  hasAskExchangeWith?: InputMaybe<Array<SecurityExchangeWhereInput>>;
  /** bid_exchange edge predicates */
  hasBidExchange?: InputMaybe<Scalars['Boolean']['input']>;
  hasBidExchangeWith?: InputMaybe<Array<SecurityExchangeWhereInput>>;
  /** etf_details edge predicates */
  hasEtfDetails?: InputMaybe<Scalars['Boolean']['input']>;
  hasEtfDetailsWith?: InputMaybe<Array<EtfDetailsWhereInput>>;
  /** fixed_income_details edge predicates */
  hasFixedIncomeDetails?: InputMaybe<Scalars['Boolean']['input']>;
  hasFixedIncomeDetailsWith?: InputMaybe<Array<SecurityFixedIncomeDetailsWhereInput>>;
  /** mutual_fund_details edge predicates */
  hasMutualFundDetails?: InputMaybe<Scalars['Boolean']['input']>;
  hasMutualFundDetailsWith?: InputMaybe<Array<MutualFundDetailsWhereInput>>;
  /** sector edge predicates */
  hasSector?: InputMaybe<Scalars['Boolean']['input']>;
  hasSectorWith?: InputMaybe<Array<SecuritySectorWhereInput>>;
  /** hidden field predicates */
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  hiddenNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** highly_volatile field predicates */
  highlyVolatile?: InputMaybe<Scalars['Boolean']['input']>;
  highlyVolatileNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** icon_uri field predicates */
  iconURI?: InputMaybe<Scalars['String']['input']>;
  iconURIContains?: InputMaybe<Scalars['String']['input']>;
  iconURIContainsFold?: InputMaybe<Scalars['String']['input']>;
  iconURIEqualFold?: InputMaybe<Scalars['String']['input']>;
  iconURIGT?: InputMaybe<Scalars['String']['input']>;
  iconURIGTE?: InputMaybe<Scalars['String']['input']>;
  iconURIHasPrefix?: InputMaybe<Scalars['String']['input']>;
  iconURIHasSuffix?: InputMaybe<Scalars['String']['input']>;
  iconURIIn?: InputMaybe<Array<Scalars['String']['input']>>;
  iconURIIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  iconURILT?: InputMaybe<Scalars['String']['input']>;
  iconURILTE?: InputMaybe<Scalars['String']['input']>;
  iconURINEQ?: InputMaybe<Scalars['String']['input']>;
  iconURINotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  iconURINotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** image_logo field predicates */
  imageLogo?: InputMaybe<Scalars['String']['input']>;
  imageLogoContains?: InputMaybe<Scalars['String']['input']>;
  imageLogoContainsFold?: InputMaybe<Scalars['String']['input']>;
  imageLogoEqualFold?: InputMaybe<Scalars['String']['input']>;
  imageLogoGT?: InputMaybe<Scalars['String']['input']>;
  imageLogoGTE?: InputMaybe<Scalars['String']['input']>;
  imageLogoHasPrefix?: InputMaybe<Scalars['String']['input']>;
  imageLogoHasSuffix?: InputMaybe<Scalars['String']['input']>;
  imageLogoIn?: InputMaybe<Array<Scalars['String']['input']>>;
  imageLogoIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  imageLogoLT?: InputMaybe<Scalars['String']['input']>;
  imageLogoLTE?: InputMaybe<Scalars['String']['input']>;
  imageLogoNEQ?: InputMaybe<Scalars['String']['input']>;
  imageLogoNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  imageLogoNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** includeHidden indicates if the response should include hidden values. If not set, it defaults to false. */
  includeHidden?: InputMaybe<Scalars['Boolean']['input']>;
  /** is_fractionable field predicates */
  isFractionable?: InputMaybe<Scalars['Boolean']['input']>;
  isFractionableNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** legacy_id field predicates */
  legacyID?: InputMaybe<Scalars['Int']['input']>;
  legacyIDGT?: InputMaybe<Scalars['Int']['input']>;
  legacyIDGTE?: InputMaybe<Scalars['Int']['input']>;
  legacyIDIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  legacyIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  legacyIDLT?: InputMaybe<Scalars['Int']['input']>;
  legacyIDLTE?: InputMaybe<Scalars['Int']['input']>;
  legacyIDNEQ?: InputMaybe<Scalars['Int']['input']>;
  legacyIDNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  legacyIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** logo_uri field predicates */
  logoURI?: InputMaybe<Scalars['String']['input']>;
  logoURIContains?: InputMaybe<Scalars['String']['input']>;
  logoURIContainsFold?: InputMaybe<Scalars['String']['input']>;
  logoURIEqualFold?: InputMaybe<Scalars['String']['input']>;
  logoURIGT?: InputMaybe<Scalars['String']['input']>;
  logoURIGTE?: InputMaybe<Scalars['String']['input']>;
  logoURIHasPrefix?: InputMaybe<Scalars['String']['input']>;
  logoURIHasSuffix?: InputMaybe<Scalars['String']['input']>;
  logoURIIn?: InputMaybe<Array<Scalars['String']['input']>>;
  logoURIIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  logoURILT?: InputMaybe<Scalars['String']['input']>;
  logoURILTE?: InputMaybe<Scalars['String']['input']>;
  logoURINEQ?: InputMaybe<Scalars['String']['input']>;
  logoURINotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  logoURINotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** market_cap field predicates */
  marketCap?: InputMaybe<Scalars['Decimal']['input']>;
  marketCapGT?: InputMaybe<Scalars['Decimal']['input']>;
  marketCapGTE?: InputMaybe<Scalars['Decimal']['input']>;
  marketCapIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  marketCapIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  marketCapLT?: InputMaybe<Scalars['Decimal']['input']>;
  marketCapLTE?: InputMaybe<Scalars['Decimal']['input']>;
  marketCapNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  marketCapNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  marketCapNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<SecurityAssetWhereInput>;
  or?: InputMaybe<Array<SecurityAssetWhereInput>>;
  /** outstanding_shares field predicates */
  outstandingShares?: InputMaybe<Scalars['Int']['input']>;
  outstandingSharesGT?: InputMaybe<Scalars['Int']['input']>;
  outstandingSharesGTE?: InputMaybe<Scalars['Int']['input']>;
  outstandingSharesIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  outstandingSharesLT?: InputMaybe<Scalars['Int']['input']>;
  outstandingSharesLTE?: InputMaybe<Scalars['Int']['input']>;
  outstandingSharesNEQ?: InputMaybe<Scalars['Int']['input']>;
  outstandingSharesNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** pe_ratio field predicates */
  peRatio?: InputMaybe<Scalars['Decimal']['input']>;
  peRatioGT?: InputMaybe<Scalars['Decimal']['input']>;
  peRatioGTE?: InputMaybe<Scalars['Decimal']['input']>;
  peRatioIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  peRatioIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  peRatioLT?: InputMaybe<Scalars['Decimal']['input']>;
  peRatioLTE?: InputMaybe<Scalars['Decimal']['input']>;
  peRatioNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  peRatioNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  peRatioNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** revenue field predicates */
  revenue?: InputMaybe<Scalars['Decimal']['input']>;
  revenueGT?: InputMaybe<Scalars['Decimal']['input']>;
  revenueGTE?: InputMaybe<Scalars['Decimal']['input']>;
  revenueIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  revenueIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  revenueLT?: InputMaybe<Scalars['Decimal']['input']>;
  revenueLTE?: InputMaybe<Scalars['Decimal']['input']>;
  revenueNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  revenueNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  revenueNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** security_type field predicates */
  securityType?: InputMaybe<SecurityAssetSecurityType>;
  securityTypeIn?: InputMaybe<Array<SecurityAssetSecurityType>>;
  securityTypeNEQ?: InputMaybe<SecurityAssetSecurityType>;
  securityTypeNotIn?: InputMaybe<Array<SecurityAssetSecurityType>>;
  /** symbol field predicates */
  symbol?: InputMaybe<Scalars['String']['input']>;
  symbolContains?: InputMaybe<Scalars['String']['input']>;
  symbolContainsFold?: InputMaybe<Scalars['String']['input']>;
  symbolEqualFold?: InputMaybe<Scalars['String']['input']>;
  symbolGT?: InputMaybe<Scalars['String']['input']>;
  symbolGTE?: InputMaybe<Scalars['String']['input']>;
  symbolHasPrefix?: InputMaybe<Scalars['String']['input']>;
  symbolHasSuffix?: InputMaybe<Scalars['String']['input']>;
  symbolIn?: InputMaybe<Array<Scalars['String']['input']>>;
  symbolLT?: InputMaybe<Scalars['String']['input']>;
  symbolLTE?: InputMaybe<Scalars['String']['input']>;
  symbolNEQ?: InputMaybe<Scalars['String']['input']>;
  symbolNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** website field predicates */
  website?: InputMaybe<Scalars['String']['input']>;
  websiteContains?: InputMaybe<Scalars['String']['input']>;
  websiteContainsFold?: InputMaybe<Scalars['String']['input']>;
  websiteEqualFold?: InputMaybe<Scalars['String']['input']>;
  websiteGT?: InputMaybe<Scalars['String']['input']>;
  websiteGTE?: InputMaybe<Scalars['String']['input']>;
  websiteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  websiteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  websiteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  websiteLT?: InputMaybe<Scalars['String']['input']>;
  websiteLTE?: InputMaybe<Scalars['String']['input']>;
  websiteNEQ?: InputMaybe<Scalars['String']['input']>;
  websiteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** SecurityETFDetails contains extra information on the security. */
export type SecurityEtfDetails = {
  __typename?: 'SecurityETFDetails';
  /** @deprecated Use `expenseRatioNet`. */
  expenseRatio: Scalars['Decimal']['output'];
  expenseRatioNet: Scalars['Decimal']['output'];
  holdings?: Maybe<Array<EtfHolding>>;
  holdingsCount: Scalars['Int']['output'];
  /** net value of the securities held in the ETF. */
  netAssetValue: Scalars['Decimal']['output'];
  sectorCount: Scalars['Int']['output'];
  sectors?: Maybe<Array<EtfSector>>;
  /** 30-Day SEC Yield */
  yield30Day: Scalars['Decimal']['output'];
};

export type SecurityExchange = Node & {
  __typename?: 'SecurityExchange';
  code?: Maybe<Scalars['String']['output']>;
  createTime: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  legacyID?: Maybe<Scalars['Int']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  market: Scalars['String']['output'];
  mic?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  polygonID?: Maybe<Scalars['Int']['output']>;
  tape?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  updateTime: Scalars['Time']['output'];
};

/** Ordering options for SecurityExchange connections */
export type SecurityExchangeOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order SecurityExchanges. */
  field: SecurityExchangeOrderField;
};

/** Properties by which SecurityExchange connections can be ordered. */
export enum SecurityExchangeOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * SecurityExchangeWhereInput is used for filtering SecurityExchange objects.
 * Input was generated by ent.
 */
export type SecurityExchangeWhereInput = {
  and?: InputMaybe<Array<SecurityExchangeWhereInput>>;
  /** code field predicates */
  code?: InputMaybe<Scalars['String']['input']>;
  codeContains?: InputMaybe<Scalars['String']['input']>;
  codeContainsFold?: InputMaybe<Scalars['String']['input']>;
  codeEqualFold?: InputMaybe<Scalars['String']['input']>;
  codeGT?: InputMaybe<Scalars['String']['input']>;
  codeGTE?: InputMaybe<Scalars['String']['input']>;
  codeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  codeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  codeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  codeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  codeLT?: InputMaybe<Scalars['String']['input']>;
  codeLTE?: InputMaybe<Scalars['String']['input']>;
  codeNEQ?: InputMaybe<Scalars['String']['input']>;
  codeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  codeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** legacy_id field predicates */
  legacyID?: InputMaybe<Scalars['Int']['input']>;
  legacyIDGT?: InputMaybe<Scalars['Int']['input']>;
  legacyIDGTE?: InputMaybe<Scalars['Int']['input']>;
  legacyIDIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  legacyIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  legacyIDLT?: InputMaybe<Scalars['Int']['input']>;
  legacyIDLTE?: InputMaybe<Scalars['Int']['input']>;
  legacyIDNEQ?: InputMaybe<Scalars['Int']['input']>;
  legacyIDNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  legacyIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** logo field predicates */
  logo?: InputMaybe<Scalars['String']['input']>;
  logoContains?: InputMaybe<Scalars['String']['input']>;
  logoContainsFold?: InputMaybe<Scalars['String']['input']>;
  logoEqualFold?: InputMaybe<Scalars['String']['input']>;
  logoGT?: InputMaybe<Scalars['String']['input']>;
  logoGTE?: InputMaybe<Scalars['String']['input']>;
  logoHasPrefix?: InputMaybe<Scalars['String']['input']>;
  logoHasSuffix?: InputMaybe<Scalars['String']['input']>;
  logoIn?: InputMaybe<Array<Scalars['String']['input']>>;
  logoIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  logoLT?: InputMaybe<Scalars['String']['input']>;
  logoLTE?: InputMaybe<Scalars['String']['input']>;
  logoNEQ?: InputMaybe<Scalars['String']['input']>;
  logoNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  logoNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** market field predicates */
  market?: InputMaybe<Scalars['String']['input']>;
  marketContains?: InputMaybe<Scalars['String']['input']>;
  marketContainsFold?: InputMaybe<Scalars['String']['input']>;
  marketEqualFold?: InputMaybe<Scalars['String']['input']>;
  marketGT?: InputMaybe<Scalars['String']['input']>;
  marketGTE?: InputMaybe<Scalars['String']['input']>;
  marketHasPrefix?: InputMaybe<Scalars['String']['input']>;
  marketHasSuffix?: InputMaybe<Scalars['String']['input']>;
  marketIn?: InputMaybe<Array<Scalars['String']['input']>>;
  marketLT?: InputMaybe<Scalars['String']['input']>;
  marketLTE?: InputMaybe<Scalars['String']['input']>;
  marketNEQ?: InputMaybe<Scalars['String']['input']>;
  marketNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** mic field predicates */
  mic?: InputMaybe<Scalars['String']['input']>;
  micContains?: InputMaybe<Scalars['String']['input']>;
  micContainsFold?: InputMaybe<Scalars['String']['input']>;
  micEqualFold?: InputMaybe<Scalars['String']['input']>;
  micGT?: InputMaybe<Scalars['String']['input']>;
  micGTE?: InputMaybe<Scalars['String']['input']>;
  micHasPrefix?: InputMaybe<Scalars['String']['input']>;
  micHasSuffix?: InputMaybe<Scalars['String']['input']>;
  micIn?: InputMaybe<Array<Scalars['String']['input']>>;
  micIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  micLT?: InputMaybe<Scalars['String']['input']>;
  micLTE?: InputMaybe<Scalars['String']['input']>;
  micNEQ?: InputMaybe<Scalars['String']['input']>;
  micNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  micNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<SecurityExchangeWhereInput>;
  or?: InputMaybe<Array<SecurityExchangeWhereInput>>;
  /** polygon_id field predicates */
  polygonID?: InputMaybe<Scalars['Int']['input']>;
  polygonIDGT?: InputMaybe<Scalars['Int']['input']>;
  polygonIDGTE?: InputMaybe<Scalars['Int']['input']>;
  polygonIDIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  polygonIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  polygonIDLT?: InputMaybe<Scalars['Int']['input']>;
  polygonIDLTE?: InputMaybe<Scalars['Int']['input']>;
  polygonIDNEQ?: InputMaybe<Scalars['Int']['input']>;
  polygonIDNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  polygonIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tape field predicates */
  tape?: InputMaybe<Scalars['String']['input']>;
  tapeContains?: InputMaybe<Scalars['String']['input']>;
  tapeContainsFold?: InputMaybe<Scalars['String']['input']>;
  tapeEqualFold?: InputMaybe<Scalars['String']['input']>;
  tapeGT?: InputMaybe<Scalars['String']['input']>;
  tapeGTE?: InputMaybe<Scalars['String']['input']>;
  tapeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  tapeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  tapeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  tapeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  tapeLT?: InputMaybe<Scalars['String']['input']>;
  tapeLTE?: InputMaybe<Scalars['String']['input']>;
  tapeNEQ?: InputMaybe<Scalars['String']['input']>;
  tapeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  tapeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** type field predicates */
  type?: InputMaybe<Scalars['String']['input']>;
  typeContains?: InputMaybe<Scalars['String']['input']>;
  typeContainsFold?: InputMaybe<Scalars['String']['input']>;
  typeEqualFold?: InputMaybe<Scalars['String']['input']>;
  typeGT?: InputMaybe<Scalars['String']['input']>;
  typeGTE?: InputMaybe<Scalars['String']['input']>;
  typeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  typeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  typeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  typeLT?: InputMaybe<Scalars['String']['input']>;
  typeLTE?: InputMaybe<Scalars['String']['input']>;
  typeNEQ?: InputMaybe<Scalars['String']['input']>;
  typeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type SecurityFinancials = {
  __typename?: 'SecurityFinancials';
  /**
   * Dividend Trailing 12 months (TTM) represents the total amount of dividends the company has
   *   paid to shareholders over the past 12 months.
   */
  dividendTrailingTwelveMonths: Scalars['Decimal']['output'];
  /**
   * Dividend yield is the percentage of the stock price that is paid out as
   *   dividends annually.
   */
  dividendYield?: Maybe<Scalars['Decimal']['output']>;
  /** dividends are the dividends paid by the company */
  dividends?: Maybe<Array<Dividend>>;
  /**
   * peRatio is the ratio of a company's share price to the company's earnings per share.
   * @deprecated Use `peRatio` in the statements.
   */
  peRatio?: Maybe<Scalars['Decimal']['output']>;
  /** statements returns the period financial statements made by a company */
  statements?: Maybe<Array<FinancialStatement>>;
};

export type SecurityFinancialsDividendsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type SecurityFinancialsStatementsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type SecurityFixedIncomeDetails = Node & {
  __typename?: 'SecurityFixedIncomeDetails';
  couponFrequency: SecurityFixedIncomeDetailsCouponFrequency;
  couponRate?: Maybe<Scalars['Decimal']['output']>;
  couponType: SecurityFixedIncomeDetailsCouponType;
  firstCouponDate?: Maybe<Scalars['Date']['output']>;
  fixedIncome: SecurityAsset;
  id: Scalars['ID']['output'];
  isCallable: Scalars['Boolean']['output'];
  isSinkingFund: Scalars['Boolean']['output'];
  issueDate?: Maybe<Scalars['Date']['output']>;
  issuePrice?: Maybe<Scalars['Decimal']['output']>;
  issueSize: Scalars['Int']['output'];
  issuer: Scalars['String']['output'];
  issuerCountry: Scalars['String']['output'];
  lastCouponDate?: Maybe<Scalars['Date']['output']>;
  maturityDate?: Maybe<Scalars['Date']['output']>;
  nextCallDate?: Maybe<Scalars['Date']['output']>;
  nextCouponDate: Scalars['Date']['output'];
  parValue?: Maybe<Scalars['Decimal']['output']>;
  subType: SecurityFixedIncomeDetailsSubType;
  type: SecurityFixedIncomeDetailsType;
  yieldMaturity: Scalars['Decimal']['output'];
};

/** SecurityFixedIncomeDetailsCouponFrequency is enum for the field coupon_frequency */
export enum SecurityFixedIncomeDetailsCouponFrequency {
  Annual = 'ANNUAL',
  AtMaturity = 'AT_MATURITY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  SemiAnnual = 'SEMI_ANNUAL',
  Zero = 'ZERO',
}

/** SecurityFixedIncomeDetailsCouponType is enum for the field coupon_type */
export enum SecurityFixedIncomeDetailsCouponType {
  CouponTypeFixed = 'COUPON_TYPE_FIXED',
  CouponTypeZero = 'COUPON_TYPE_ZERO',
}

/** Ordering options for SecurityFixedIncomeDetails connections */
export type SecurityFixedIncomeDetailsOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order SecurityFixedIncomeDetailsSlice. */
  field: SecurityFixedIncomeDetailsOrderField;
};

/** Properties by which SecurityFixedIncomeDetails connections can be ordered. */
export enum SecurityFixedIncomeDetailsOrderField {
  CouponRate = 'couponRate',
  IsCallable = 'isCallable',
  IssuePrice = 'issuePrice',
  MaturityDate = 'maturityDate',
  SubType = 'subType',
  Type = 'type',
  YieldMaturity = 'yieldMaturity',
}

/** SecurityFixedIncomeDetailsSubType is enum for the field sub_type */
export enum SecurityFixedIncomeDetailsSubType {
  Bill = 'BILL',
  Bond = 'BOND',
  Note = 'NOTE',
  Strips = 'STRIPS',
  Tips = 'TIPS',
}

/** SecurityFixedIncomeDetailsType is enum for the field type */
export enum SecurityFixedIncomeDetailsType {
  Agency = 'AGENCY',
  Cd = 'CD',
  Corporate = 'CORPORATE',
  ForeignGovernment = 'FOREIGN_GOVERNMENT',
  Government = 'GOVERNMENT',
  Municipal = 'MUNICIPAL',
  Treasury = 'TREASURY',
}

/**
 * SecurityFixedIncomeDetailsWhereInput is used for filtering FixedIncomeDetails objects.
 * Input was generated by ent.
 */
export type SecurityFixedIncomeDetailsWhereInput = {
  and?: InputMaybe<Array<SecurityFixedIncomeDetailsWhereInput>>;
  /** coupon_frequency field predicates */
  couponFrequency?: InputMaybe<SecurityFixedIncomeDetailsCouponFrequency>;
  couponFrequencyIn?: InputMaybe<Array<SecurityFixedIncomeDetailsCouponFrequency>>;
  couponFrequencyNEQ?: InputMaybe<SecurityFixedIncomeDetailsCouponFrequency>;
  couponFrequencyNotIn?: InputMaybe<Array<SecurityFixedIncomeDetailsCouponFrequency>>;
  /** coupon_rate field predicates */
  couponRate?: InputMaybe<Scalars['Decimal']['input']>;
  couponRateGT?: InputMaybe<Scalars['Decimal']['input']>;
  couponRateGTE?: InputMaybe<Scalars['Decimal']['input']>;
  couponRateIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  couponRateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  couponRateLT?: InputMaybe<Scalars['Decimal']['input']>;
  couponRateLTE?: InputMaybe<Scalars['Decimal']['input']>;
  couponRateNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  couponRateNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  couponRateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** coupon_type field predicates */
  couponType?: InputMaybe<SecurityFixedIncomeDetailsCouponType>;
  couponTypeIn?: InputMaybe<Array<SecurityFixedIncomeDetailsCouponType>>;
  couponTypeNEQ?: InputMaybe<SecurityFixedIncomeDetailsCouponType>;
  couponTypeNotIn?: InputMaybe<Array<SecurityFixedIncomeDetailsCouponType>>;
  /** first_coupon_date field predicates */
  firstCouponDate?: InputMaybe<Scalars['Date']['input']>;
  firstCouponDateGT?: InputMaybe<Scalars['Date']['input']>;
  firstCouponDateGTE?: InputMaybe<Scalars['Date']['input']>;
  firstCouponDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  firstCouponDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  firstCouponDateLT?: InputMaybe<Scalars['Date']['input']>;
  firstCouponDateLTE?: InputMaybe<Scalars['Date']['input']>;
  firstCouponDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  firstCouponDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  firstCouponDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** fixed_income edge predicates */
  hasFixedIncome?: InputMaybe<Scalars['Boolean']['input']>;
  hasFixedIncomeWith?: InputMaybe<Array<SecurityAssetWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_callable field predicates */
  isCallable?: InputMaybe<Scalars['Boolean']['input']>;
  isCallableNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** is_sinking_fund field predicates */
  isSinkingFund?: InputMaybe<Scalars['Boolean']['input']>;
  isSinkingFundNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** issue_date field predicates */
  issueDate?: InputMaybe<Scalars['Date']['input']>;
  issueDateGT?: InputMaybe<Scalars['Date']['input']>;
  issueDateGTE?: InputMaybe<Scalars['Date']['input']>;
  issueDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  issueDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  issueDateLT?: InputMaybe<Scalars['Date']['input']>;
  issueDateLTE?: InputMaybe<Scalars['Date']['input']>;
  issueDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  issueDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  issueDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** issue_price field predicates */
  issuePrice?: InputMaybe<Scalars['Decimal']['input']>;
  issuePriceGT?: InputMaybe<Scalars['Decimal']['input']>;
  issuePriceGTE?: InputMaybe<Scalars['Decimal']['input']>;
  issuePriceIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  issuePriceIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  issuePriceLT?: InputMaybe<Scalars['Decimal']['input']>;
  issuePriceLTE?: InputMaybe<Scalars['Decimal']['input']>;
  issuePriceNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  issuePriceNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  issuePriceNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** issue_size field predicates */
  issueSize?: InputMaybe<Scalars['Int']['input']>;
  issueSizeGT?: InputMaybe<Scalars['Int']['input']>;
  issueSizeGTE?: InputMaybe<Scalars['Int']['input']>;
  issueSizeIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  issueSizeLT?: InputMaybe<Scalars['Int']['input']>;
  issueSizeLTE?: InputMaybe<Scalars['Int']['input']>;
  issueSizeNEQ?: InputMaybe<Scalars['Int']['input']>;
  issueSizeNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** issuer field predicates */
  issuer?: InputMaybe<Scalars['String']['input']>;
  issuerContains?: InputMaybe<Scalars['String']['input']>;
  issuerContainsFold?: InputMaybe<Scalars['String']['input']>;
  /** issuer_country field predicates */
  issuerCountry?: InputMaybe<Scalars['String']['input']>;
  issuerCountryContains?: InputMaybe<Scalars['String']['input']>;
  issuerCountryContainsFold?: InputMaybe<Scalars['String']['input']>;
  issuerCountryEqualFold?: InputMaybe<Scalars['String']['input']>;
  issuerCountryGT?: InputMaybe<Scalars['String']['input']>;
  issuerCountryGTE?: InputMaybe<Scalars['String']['input']>;
  issuerCountryHasPrefix?: InputMaybe<Scalars['String']['input']>;
  issuerCountryHasSuffix?: InputMaybe<Scalars['String']['input']>;
  issuerCountryIn?: InputMaybe<Array<Scalars['String']['input']>>;
  issuerCountryLT?: InputMaybe<Scalars['String']['input']>;
  issuerCountryLTE?: InputMaybe<Scalars['String']['input']>;
  issuerCountryNEQ?: InputMaybe<Scalars['String']['input']>;
  issuerCountryNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  issuerEqualFold?: InputMaybe<Scalars['String']['input']>;
  issuerGT?: InputMaybe<Scalars['String']['input']>;
  issuerGTE?: InputMaybe<Scalars['String']['input']>;
  issuerHasPrefix?: InputMaybe<Scalars['String']['input']>;
  issuerHasSuffix?: InputMaybe<Scalars['String']['input']>;
  issuerIn?: InputMaybe<Array<Scalars['String']['input']>>;
  issuerLT?: InputMaybe<Scalars['String']['input']>;
  issuerLTE?: InputMaybe<Scalars['String']['input']>;
  issuerNEQ?: InputMaybe<Scalars['String']['input']>;
  issuerNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** last_coupon_date field predicates */
  lastCouponDate?: InputMaybe<Scalars['Date']['input']>;
  lastCouponDateGT?: InputMaybe<Scalars['Date']['input']>;
  lastCouponDateGTE?: InputMaybe<Scalars['Date']['input']>;
  lastCouponDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  lastCouponDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  lastCouponDateLT?: InputMaybe<Scalars['Date']['input']>;
  lastCouponDateLTE?: InputMaybe<Scalars['Date']['input']>;
  lastCouponDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  lastCouponDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  lastCouponDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** maturity_date field predicates */
  maturityDate?: InputMaybe<Scalars['Date']['input']>;
  maturityDateGT?: InputMaybe<Scalars['Date']['input']>;
  maturityDateGTE?: InputMaybe<Scalars['Date']['input']>;
  maturityDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  maturityDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  maturityDateLT?: InputMaybe<Scalars['Date']['input']>;
  maturityDateLTE?: InputMaybe<Scalars['Date']['input']>;
  maturityDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  maturityDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  maturityDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** next_call_date field predicates */
  nextCallDate?: InputMaybe<Scalars['Date']['input']>;
  nextCallDateGT?: InputMaybe<Scalars['Date']['input']>;
  nextCallDateGTE?: InputMaybe<Scalars['Date']['input']>;
  nextCallDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  nextCallDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  nextCallDateLT?: InputMaybe<Scalars['Date']['input']>;
  nextCallDateLTE?: InputMaybe<Scalars['Date']['input']>;
  nextCallDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  nextCallDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  nextCallDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** next_coupon_date field predicates */
  nextCouponDate?: InputMaybe<Scalars['Date']['input']>;
  nextCouponDateGT?: InputMaybe<Scalars['Date']['input']>;
  nextCouponDateGTE?: InputMaybe<Scalars['Date']['input']>;
  nextCouponDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  nextCouponDateLT?: InputMaybe<Scalars['Date']['input']>;
  nextCouponDateLTE?: InputMaybe<Scalars['Date']['input']>;
  nextCouponDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  nextCouponDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  not?: InputMaybe<SecurityFixedIncomeDetailsWhereInput>;
  or?: InputMaybe<Array<SecurityFixedIncomeDetailsWhereInput>>;
  /** par_value field predicates */
  parValue?: InputMaybe<Scalars['Decimal']['input']>;
  parValueGT?: InputMaybe<Scalars['Decimal']['input']>;
  parValueGTE?: InputMaybe<Scalars['Decimal']['input']>;
  parValueIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  parValueIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  parValueLT?: InputMaybe<Scalars['Decimal']['input']>;
  parValueLTE?: InputMaybe<Scalars['Decimal']['input']>;
  parValueNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  parValueNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  parValueNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** sub_type field predicates */
  subType?: InputMaybe<SecurityFixedIncomeDetailsSubType>;
  subTypeIn?: InputMaybe<Array<SecurityFixedIncomeDetailsSubType>>;
  subTypeNEQ?: InputMaybe<SecurityFixedIncomeDetailsSubType>;
  subTypeNotIn?: InputMaybe<Array<SecurityFixedIncomeDetailsSubType>>;
  /** type field predicates */
  type?: InputMaybe<SecurityFixedIncomeDetailsType>;
  typeIn?: InputMaybe<Array<SecurityFixedIncomeDetailsType>>;
  typeNEQ?: InputMaybe<SecurityFixedIncomeDetailsType>;
  typeNotIn?: InputMaybe<Array<SecurityFixedIncomeDetailsType>>;
  /** yield_maturity field predicates */
  yieldMaturity?: InputMaybe<Scalars['Decimal']['input']>;
  yieldMaturityGT?: InputMaybe<Scalars['Decimal']['input']>;
  yieldMaturityGTE?: InputMaybe<Scalars['Decimal']['input']>;
  yieldMaturityIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  yieldMaturityLT?: InputMaybe<Scalars['Decimal']['input']>;
  yieldMaturityLTE?: InputMaybe<Scalars['Decimal']['input']>;
  yieldMaturityNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  yieldMaturityNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

/** SecurityMutualFundDetails contains extra information on the security. */
export type SecurityMutualFundDetails = {
  __typename?: 'SecurityMutualFundDetails';
  additionalGroupRetirementInvestmentMinimum: Scalars['Decimal']['output'];
  additionalInvestmentMinimum: Scalars['Decimal']['output'];
  additionalIraInvestmentMinimum: Scalars['Decimal']['output'];
  annualizedStdDev: Scalars['Decimal']['output'];
  contractualExpenseWaiver: Scalars['Decimal']['output'];
  distributionServiceFee: Scalars['Decimal']['output'];
  expenseRatioGross: Scalars['Decimal']['output'];
  expenseRatioNet: Scalars['Decimal']['output'];
  feeClass: Scalars['String']['output'];
  fiveYearTotalReturn: Scalars['Decimal']['output'];
  fundCategory: Scalars['String']['output'];
  fundCompany: Scalars['String']['output'];
  fundInception: Scalars['Date']['output'];
  holdings?: Maybe<Array<MutualFundHolding>>;
  initialGroupRetirementInvestmentMinimum: Scalars['Decimal']['output'];
  initialInvestmentMinimum: Scalars['Decimal']['output'];
  initialIraInvestmentMinimum: Scalars['Decimal']['output'];
  loadPct: Scalars['Decimal']['output'];
  loadType: Scalars['String']['output'];
  mutualFundType: Scalars['String']['output'];
  netAssetValue: Scalars['Decimal']['output'];
  netAssetValueChange: Scalars['Decimal']['output'];
  oneYearTotalReturn: Scalars['Decimal']['output'];
  portfolioNetAssetValue: Scalars['Decimal']['output'];
  rSquared: Scalars['Decimal']['output'];
  shareClassNetAssetValue: Scalars['Decimal']['output'];
  sharpeRatio: Scalars['Decimal']['output'];
  tenYearTotalReturn: Scalars['Decimal']['output'];
  threeYearTotalReturn: Scalars['Decimal']['output'];
  transactionFee: Scalars['Decimal']['output'];
  turnoverRate: Scalars['Decimal']['output'];
  twelveMonthHighValue: Scalars['Decimal']['output'];
  twelveMonthLowValue: Scalars['Decimal']['output'];
  yearToDateReturns: Scalars['Decimal']['output'];
  yield30Day: Scalars['Decimal']['output'];
};

export type SecuritySector = Node & {
  __typename?: 'SecuritySector';
  category?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  /** security sectors are grouped together by the entity that created the sectors. Searching for sectors in certain contexts only makes sense within a particular group. E.g. Fintron Sectors */
  grouping?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imagePrimary: Scalars['String']['output'];
  imageSector: Scalars['String']['output'];
  name: Scalars['String']['output'];
  portfolioAvgReturn?: Maybe<Scalars['Float']['output']>;
  portfolioExpRetLb?: Maybe<Scalars['Float']['output']>;
  portfolioExpRetUb?: Maybe<Scalars['Float']['output']>;
  portfolioRealTimePerf?: Maybe<Scalars['Float']['output']>;
  portfolioStdDev?: Maybe<Scalars['Float']['output']>;
  portfolioVariance?: Maybe<Scalars['Float']['output']>;
  portfolioYearlyPerf?: Maybe<Scalars['Float']['output']>;
  securities?: Maybe<Array<SecurityAsset>>;
};

/** A connection to a list of items. */
export type SecuritySectorConnection = {
  __typename?: 'SecuritySectorConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SecuritySectorEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SecuritySectorEdge = {
  __typename?: 'SecuritySectorEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SecuritySector>;
};

/**
 * SecuritySectorWhereInput is used for filtering SecuritySector objects.
 * Input was generated by ent.
 */
export type SecuritySectorWhereInput = {
  and?: InputMaybe<Array<SecuritySectorWhereInput>>;
  /** category field predicates */
  category?: InputMaybe<Scalars['String']['input']>;
  categoryContains?: InputMaybe<Scalars['String']['input']>;
  categoryContainsFold?: InputMaybe<Scalars['String']['input']>;
  categoryEqualFold?: InputMaybe<Scalars['String']['input']>;
  categoryGT?: InputMaybe<Scalars['String']['input']>;
  categoryGTE?: InputMaybe<Scalars['String']['input']>;
  categoryHasPrefix?: InputMaybe<Scalars['String']['input']>;
  categoryHasSuffix?: InputMaybe<Scalars['String']['input']>;
  categoryIn?: InputMaybe<Array<Scalars['String']['input']>>;
  categoryIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  categoryLT?: InputMaybe<Scalars['String']['input']>;
  categoryLTE?: InputMaybe<Scalars['String']['input']>;
  categoryNEQ?: InputMaybe<Scalars['String']['input']>;
  categoryNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  categoryNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** grouping field predicates */
  grouping?: InputMaybe<Scalars['String']['input']>;
  groupingContains?: InputMaybe<Scalars['String']['input']>;
  groupingContainsFold?: InputMaybe<Scalars['String']['input']>;
  groupingEqualFold?: InputMaybe<Scalars['String']['input']>;
  groupingGT?: InputMaybe<Scalars['String']['input']>;
  groupingGTE?: InputMaybe<Scalars['String']['input']>;
  groupingHasPrefix?: InputMaybe<Scalars['String']['input']>;
  groupingHasSuffix?: InputMaybe<Scalars['String']['input']>;
  groupingIn?: InputMaybe<Array<Scalars['String']['input']>>;
  groupingIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  groupingLT?: InputMaybe<Scalars['String']['input']>;
  groupingLTE?: InputMaybe<Scalars['String']['input']>;
  groupingNEQ?: InputMaybe<Scalars['String']['input']>;
  groupingNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  groupingNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** securities edge predicates */
  hasSecurities?: InputMaybe<Scalars['Boolean']['input']>;
  hasSecuritiesWith?: InputMaybe<Array<SecurityAssetWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** image_primary field predicates */
  imagePrimary?: InputMaybe<Scalars['String']['input']>;
  imagePrimaryContains?: InputMaybe<Scalars['String']['input']>;
  imagePrimaryContainsFold?: InputMaybe<Scalars['String']['input']>;
  imagePrimaryEqualFold?: InputMaybe<Scalars['String']['input']>;
  imagePrimaryGT?: InputMaybe<Scalars['String']['input']>;
  imagePrimaryGTE?: InputMaybe<Scalars['String']['input']>;
  imagePrimaryHasPrefix?: InputMaybe<Scalars['String']['input']>;
  imagePrimaryHasSuffix?: InputMaybe<Scalars['String']['input']>;
  imagePrimaryIn?: InputMaybe<Array<Scalars['String']['input']>>;
  imagePrimaryLT?: InputMaybe<Scalars['String']['input']>;
  imagePrimaryLTE?: InputMaybe<Scalars['String']['input']>;
  imagePrimaryNEQ?: InputMaybe<Scalars['String']['input']>;
  imagePrimaryNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** image_sector field predicates */
  imageSector?: InputMaybe<Scalars['String']['input']>;
  imageSectorContains?: InputMaybe<Scalars['String']['input']>;
  imageSectorContainsFold?: InputMaybe<Scalars['String']['input']>;
  imageSectorEqualFold?: InputMaybe<Scalars['String']['input']>;
  imageSectorGT?: InputMaybe<Scalars['String']['input']>;
  imageSectorGTE?: InputMaybe<Scalars['String']['input']>;
  imageSectorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  imageSectorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  imageSectorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  imageSectorLT?: InputMaybe<Scalars['String']['input']>;
  imageSectorLTE?: InputMaybe<Scalars['String']['input']>;
  imageSectorNEQ?: InputMaybe<Scalars['String']['input']>;
  imageSectorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<SecuritySectorWhereInput>;
  or?: InputMaybe<Array<SecuritySectorWhereInput>>;
  /** portfolio_avg_return field predicates */
  portfolioAvgReturn?: InputMaybe<Scalars['Float']['input']>;
  portfolioAvgReturnGT?: InputMaybe<Scalars['Float']['input']>;
  portfolioAvgReturnGTE?: InputMaybe<Scalars['Float']['input']>;
  portfolioAvgReturnIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  portfolioAvgReturnIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  portfolioAvgReturnLT?: InputMaybe<Scalars['Float']['input']>;
  portfolioAvgReturnLTE?: InputMaybe<Scalars['Float']['input']>;
  portfolioAvgReturnNEQ?: InputMaybe<Scalars['Float']['input']>;
  portfolioAvgReturnNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  portfolioAvgReturnNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** portfolio_exp_ret_lb field predicates */
  portfolioExpRetLb?: InputMaybe<Scalars['Float']['input']>;
  portfolioExpRetLbGT?: InputMaybe<Scalars['Float']['input']>;
  portfolioExpRetLbGTE?: InputMaybe<Scalars['Float']['input']>;
  portfolioExpRetLbIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  portfolioExpRetLbIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  portfolioExpRetLbLT?: InputMaybe<Scalars['Float']['input']>;
  portfolioExpRetLbLTE?: InputMaybe<Scalars['Float']['input']>;
  portfolioExpRetLbNEQ?: InputMaybe<Scalars['Float']['input']>;
  portfolioExpRetLbNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  portfolioExpRetLbNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** portfolio_exp_ret_ub field predicates */
  portfolioExpRetUb?: InputMaybe<Scalars['Float']['input']>;
  portfolioExpRetUbGT?: InputMaybe<Scalars['Float']['input']>;
  portfolioExpRetUbGTE?: InputMaybe<Scalars['Float']['input']>;
  portfolioExpRetUbIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  portfolioExpRetUbIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  portfolioExpRetUbLT?: InputMaybe<Scalars['Float']['input']>;
  portfolioExpRetUbLTE?: InputMaybe<Scalars['Float']['input']>;
  portfolioExpRetUbNEQ?: InputMaybe<Scalars['Float']['input']>;
  portfolioExpRetUbNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  portfolioExpRetUbNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** portfolio_real_time_perf field predicates */
  portfolioRealTimePerf?: InputMaybe<Scalars['Float']['input']>;
  portfolioRealTimePerfGT?: InputMaybe<Scalars['Float']['input']>;
  portfolioRealTimePerfGTE?: InputMaybe<Scalars['Float']['input']>;
  portfolioRealTimePerfIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  portfolioRealTimePerfIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  portfolioRealTimePerfLT?: InputMaybe<Scalars['Float']['input']>;
  portfolioRealTimePerfLTE?: InputMaybe<Scalars['Float']['input']>;
  portfolioRealTimePerfNEQ?: InputMaybe<Scalars['Float']['input']>;
  portfolioRealTimePerfNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  portfolioRealTimePerfNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** portfolio_std_dev field predicates */
  portfolioStdDev?: InputMaybe<Scalars['Float']['input']>;
  portfolioStdDevGT?: InputMaybe<Scalars['Float']['input']>;
  portfolioStdDevGTE?: InputMaybe<Scalars['Float']['input']>;
  portfolioStdDevIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  portfolioStdDevIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  portfolioStdDevLT?: InputMaybe<Scalars['Float']['input']>;
  portfolioStdDevLTE?: InputMaybe<Scalars['Float']['input']>;
  portfolioStdDevNEQ?: InputMaybe<Scalars['Float']['input']>;
  portfolioStdDevNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  portfolioStdDevNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** portfolio_variance field predicates */
  portfolioVariance?: InputMaybe<Scalars['Float']['input']>;
  portfolioVarianceGT?: InputMaybe<Scalars['Float']['input']>;
  portfolioVarianceGTE?: InputMaybe<Scalars['Float']['input']>;
  portfolioVarianceIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  portfolioVarianceIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  portfolioVarianceLT?: InputMaybe<Scalars['Float']['input']>;
  portfolioVarianceLTE?: InputMaybe<Scalars['Float']['input']>;
  portfolioVarianceNEQ?: InputMaybe<Scalars['Float']['input']>;
  portfolioVarianceNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  portfolioVarianceNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** portfolio_yearly_perf field predicates */
  portfolioYearlyPerf?: InputMaybe<Scalars['Float']['input']>;
  portfolioYearlyPerfGT?: InputMaybe<Scalars['Float']['input']>;
  portfolioYearlyPerfGTE?: InputMaybe<Scalars['Float']['input']>;
  portfolioYearlyPerfIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  portfolioYearlyPerfIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  portfolioYearlyPerfLT?: InputMaybe<Scalars['Float']['input']>;
  portfolioYearlyPerfLTE?: InputMaybe<Scalars['Float']['input']>;
  portfolioYearlyPerfNEQ?: InputMaybe<Scalars['Float']['input']>;
  portfolioYearlyPerfNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  portfolioYearlyPerfNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SignatureImage = {
  __typename?: 'SignatureImage';
  documentID?: Maybe<Scalars['String']['output']>;
};

export type SodDiscrepancyTrack = Node & {
  __typename?: 'SodDiscrepancyTrack';
  account?: Maybe<Array<UserAccount>>;
  apexAccount?: Maybe<Array<ApexAccountMeta>>;
  createTime: Scalars['Time']['output'];
  /** Error message if any */
  errorDetails?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Notes if any */
  principalNotes?: Maybe<Scalars['String']['output']>;
  /** The name of the report */
  reportName: Scalars['String']['output'];
  /** The rows that have discrepancies */
  rowsID: Array<Scalars['String']['output']>;
  updateTime: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type SodDiscrepancyTrackConnection = {
  __typename?: 'SodDiscrepancyTrackConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SodDiscrepancyTrackEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SodDiscrepancyTrackEdge = {
  __typename?: 'SodDiscrepancyTrackEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SodDiscrepancyTrack>;
};

/** Ordering options for SodDiscrepancyTrack connections */
export type SodDiscrepancyTrackOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order SodDiscrepancyTracks. */
  field: SodDiscrepancyTrackOrderField;
};

/** Properties by which SodDiscrepancyTrack connections can be ordered. */
export enum SodDiscrepancyTrackOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * SodDiscrepancyTrackWhereInput is used for filtering SodDiscrepancyTrack objects.
 * Input was generated by ent.
 */
export type SodDiscrepancyTrackWhereInput = {
  and?: InputMaybe<Array<SodDiscrepancyTrackWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** error_details field predicates */
  errorDetails?: InputMaybe<Scalars['String']['input']>;
  errorDetailsContains?: InputMaybe<Scalars['String']['input']>;
  errorDetailsContainsFold?: InputMaybe<Scalars['String']['input']>;
  errorDetailsEqualFold?: InputMaybe<Scalars['String']['input']>;
  errorDetailsGT?: InputMaybe<Scalars['String']['input']>;
  errorDetailsGTE?: InputMaybe<Scalars['String']['input']>;
  errorDetailsHasPrefix?: InputMaybe<Scalars['String']['input']>;
  errorDetailsHasSuffix?: InputMaybe<Scalars['String']['input']>;
  errorDetailsIn?: InputMaybe<Array<Scalars['String']['input']>>;
  errorDetailsIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  errorDetailsLT?: InputMaybe<Scalars['String']['input']>;
  errorDetailsLTE?: InputMaybe<Scalars['String']['input']>;
  errorDetailsNEQ?: InputMaybe<Scalars['String']['input']>;
  errorDetailsNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  errorDetailsNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** account edge predicates */
  hasAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountWith?: InputMaybe<Array<UserAccountWhereInput>>;
  /** apex_account edge predicates */
  hasApexAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasApexAccountWith?: InputMaybe<Array<ApexAccountMetaWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<SodDiscrepancyTrackWhereInput>;
  or?: InputMaybe<Array<SodDiscrepancyTrackWhereInput>>;
  /** principal_notes field predicates */
  principalNotes?: InputMaybe<Scalars['String']['input']>;
  principalNotesContains?: InputMaybe<Scalars['String']['input']>;
  principalNotesContainsFold?: InputMaybe<Scalars['String']['input']>;
  principalNotesEqualFold?: InputMaybe<Scalars['String']['input']>;
  principalNotesGT?: InputMaybe<Scalars['String']['input']>;
  principalNotesGTE?: InputMaybe<Scalars['String']['input']>;
  principalNotesHasPrefix?: InputMaybe<Scalars['String']['input']>;
  principalNotesHasSuffix?: InputMaybe<Scalars['String']['input']>;
  principalNotesIn?: InputMaybe<Array<Scalars['String']['input']>>;
  principalNotesIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  principalNotesLT?: InputMaybe<Scalars['String']['input']>;
  principalNotesLTE?: InputMaybe<Scalars['String']['input']>;
  principalNotesNEQ?: InputMaybe<Scalars['String']['input']>;
  principalNotesNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  principalNotesNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** report_name field predicates */
  reportName?: InputMaybe<Scalars['String']['input']>;
  reportNameContains?: InputMaybe<Scalars['String']['input']>;
  reportNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  reportNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  reportNameGT?: InputMaybe<Scalars['String']['input']>;
  reportNameGTE?: InputMaybe<Scalars['String']['input']>;
  reportNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  reportNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  reportNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  reportNameLT?: InputMaybe<Scalars['String']['input']>;
  reportNameLTE?: InputMaybe<Scalars['String']['input']>;
  reportNameNEQ?: InputMaybe<Scalars['String']['input']>;
  reportNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export enum StateWithholdingClass {
  StateWithholdingClassMandatory = 'STATE_WITHHOLDING_CLASS_MANDATORY',
  StateWithholdingClassMandatoryOptOut = 'STATE_WITHHOLDING_CLASS_MANDATORY_OPT_OUT',
  StateWithholdingClassMayNotBeElected = 'STATE_WITHHOLDING_CLASS_MAY_NOT_BE_ELECTED',
  StateWithholdingClassVoluntary = 'STATE_WITHHOLDING_CLASS_VOLUNTARY',
}

export type StateWithholdingFundamentalInfo = {
  __typename?: 'StateWithholdingFundamentalInfo';
  class: StateWithholdingClass;
  /** rate is the minimum withholding rate for the state if an election is made. */
  rate?: Maybe<Scalars['Decimal']['output']>;
  rateType?: Maybe<WithholdingRateType>;
  state: Scalars['String']['output'];
};

export type SupportFaq = Node & {
  __typename?: 'SupportFAQ';
  description: Scalars['String']['output'];
  faqCategory?: Maybe<SupportFaqCategory>;
  id: Scalars['ID']['output'];
  /** url to display with the image */
  image: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type SupportFaqCategory = Node & {
  __typename?: 'SupportFAQCategory';
  faqs?: Maybe<Array<SupportFaq>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** order is the order that this category should appear in the front ent */
  order: Scalars['Int']['output'];
};

/**
 * SupportFAQCategoryWhereInput is used for filtering SupportFAQCategory objects.
 * Input was generated by ent.
 */
export type SupportFaqCategoryWhereInput = {
  and?: InputMaybe<Array<SupportFaqCategoryWhereInput>>;
  /** faqs edge predicates */
  hasFaqs?: InputMaybe<Scalars['Boolean']['input']>;
  hasFaqsWith?: InputMaybe<Array<SupportFaqWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<SupportFaqCategoryWhereInput>;
  or?: InputMaybe<Array<SupportFaqCategoryWhereInput>>;
  /** order field predicates */
  order?: InputMaybe<Scalars['Int']['input']>;
  orderGT?: InputMaybe<Scalars['Int']['input']>;
  orderGTE?: InputMaybe<Scalars['Int']['input']>;
  orderIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  orderLT?: InputMaybe<Scalars['Int']['input']>;
  orderLTE?: InputMaybe<Scalars['Int']['input']>;
  orderNEQ?: InputMaybe<Scalars['Int']['input']>;
  orderNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/**
 * SupportFAQWhereInput is used for filtering SupportFAQ objects.
 * Input was generated by ent.
 */
export type SupportFaqWhereInput = {
  and?: InputMaybe<Array<SupportFaqWhereInput>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** faq_category edge predicates */
  hasFaqCategory?: InputMaybe<Scalars['Boolean']['input']>;
  hasFaqCategoryWith?: InputMaybe<Array<SupportFaqCategoryWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** image field predicates */
  image?: InputMaybe<Scalars['String']['input']>;
  imageContains?: InputMaybe<Scalars['String']['input']>;
  imageContainsFold?: InputMaybe<Scalars['String']['input']>;
  imageEqualFold?: InputMaybe<Scalars['String']['input']>;
  imageGT?: InputMaybe<Scalars['String']['input']>;
  imageGTE?: InputMaybe<Scalars['String']['input']>;
  imageHasPrefix?: InputMaybe<Scalars['String']['input']>;
  imageHasSuffix?: InputMaybe<Scalars['String']['input']>;
  imageIn?: InputMaybe<Array<Scalars['String']['input']>>;
  imageLT?: InputMaybe<Scalars['String']['input']>;
  imageLTE?: InputMaybe<Scalars['String']['input']>;
  imageNEQ?: InputMaybe<Scalars['String']['input']>;
  imageNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<SupportFaqWhereInput>;
  or?: InputMaybe<Array<SupportFaqWhereInput>>;
  /** title field predicates */
  title?: InputMaybe<Scalars['String']['input']>;
  titleContains?: InputMaybe<Scalars['String']['input']>;
  titleContainsFold?: InputMaybe<Scalars['String']['input']>;
  titleEqualFold?: InputMaybe<Scalars['String']['input']>;
  titleGT?: InputMaybe<Scalars['String']['input']>;
  titleGTE?: InputMaybe<Scalars['String']['input']>;
  titleHasPrefix?: InputMaybe<Scalars['String']['input']>;
  titleHasSuffix?: InputMaybe<Scalars['String']['input']>;
  titleIn?: InputMaybe<Array<Scalars['String']['input']>>;
  titleLT?: InputMaybe<Scalars['String']['input']>;
  titleLTE?: InputMaybe<Scalars['String']['input']>;
  titleNEQ?: InputMaybe<Scalars['String']['input']>;
  titleNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** type field predicates */
  type?: InputMaybe<Scalars['String']['input']>;
  typeContains?: InputMaybe<Scalars['String']['input']>;
  typeContainsFold?: InputMaybe<Scalars['String']['input']>;
  typeEqualFold?: InputMaybe<Scalars['String']['input']>;
  typeGT?: InputMaybe<Scalars['String']['input']>;
  typeGTE?: InputMaybe<Scalars['String']['input']>;
  typeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  typeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  typeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  typeLT?: InputMaybe<Scalars['String']['input']>;
  typeLTE?: InputMaybe<Scalars['String']['input']>;
  typeNEQ?: InputMaybe<Scalars['String']['input']>;
  typeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SupportTicket = Node & {
  __typename?: 'SupportTicket';
  agent?: Maybe<Array<User>>;
  category: Scalars['String']['output'];
  createTime: Scalars['Time']['output'];
  description: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  productType: Scalars['String']['output'];
  status: SupportTicketStatus;
  ticketType: Scalars['String']['output'];
  updateTime: Scalars['Time']['output'];
  user?: Maybe<User>;
};

/** Ordering options for SupportTicket connections */
export type SupportTicketOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order SupportTickets. */
  field: SupportTicketOrderField;
};

/** Properties by which SupportTicket connections can be ordered. */
export enum SupportTicketOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/** SupportTicketStatus is enum for the field status */
export enum SupportTicketStatus {
  Completed = 'Completed',
  Received = 'Received',
  Viewed = 'Viewed',
}

/**
 * SupportTicketWhereInput is used for filtering SupportTicket objects.
 * Input was generated by ent.
 */
export type SupportTicketWhereInput = {
  and?: InputMaybe<Array<SupportTicketWhereInput>>;
  /** category field predicates */
  category?: InputMaybe<Scalars['String']['input']>;
  categoryContains?: InputMaybe<Scalars['String']['input']>;
  categoryContainsFold?: InputMaybe<Scalars['String']['input']>;
  categoryEqualFold?: InputMaybe<Scalars['String']['input']>;
  categoryGT?: InputMaybe<Scalars['String']['input']>;
  categoryGTE?: InputMaybe<Scalars['String']['input']>;
  categoryHasPrefix?: InputMaybe<Scalars['String']['input']>;
  categoryHasSuffix?: InputMaybe<Scalars['String']['input']>;
  categoryIn?: InputMaybe<Array<Scalars['String']['input']>>;
  categoryLT?: InputMaybe<Scalars['String']['input']>;
  categoryLTE?: InputMaybe<Scalars['String']['input']>;
  categoryNEQ?: InputMaybe<Scalars['String']['input']>;
  categoryNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** email field predicates */
  email?: InputMaybe<Scalars['String']['input']>;
  emailContains?: InputMaybe<Scalars['String']['input']>;
  emailContainsFold?: InputMaybe<Scalars['String']['input']>;
  emailEqualFold?: InputMaybe<Scalars['String']['input']>;
  emailGT?: InputMaybe<Scalars['String']['input']>;
  emailGTE?: InputMaybe<Scalars['String']['input']>;
  emailHasPrefix?: InputMaybe<Scalars['String']['input']>;
  emailHasSuffix?: InputMaybe<Scalars['String']['input']>;
  emailIn?: InputMaybe<Array<Scalars['String']['input']>>;
  emailLT?: InputMaybe<Scalars['String']['input']>;
  emailLTE?: InputMaybe<Scalars['String']['input']>;
  emailNEQ?: InputMaybe<Scalars['String']['input']>;
  emailNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** first_name field predicates */
  firstName?: InputMaybe<Scalars['String']['input']>;
  firstNameContains?: InputMaybe<Scalars['String']['input']>;
  firstNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  firstNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  firstNameGT?: InputMaybe<Scalars['String']['input']>;
  firstNameGTE?: InputMaybe<Scalars['String']['input']>;
  firstNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  firstNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  firstNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  firstNameLT?: InputMaybe<Scalars['String']['input']>;
  firstNameLTE?: InputMaybe<Scalars['String']['input']>;
  firstNameNEQ?: InputMaybe<Scalars['String']['input']>;
  firstNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** agent edge predicates */
  hasAgent?: InputMaybe<Scalars['Boolean']['input']>;
  hasAgentWith?: InputMaybe<Array<UserWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** last_name field predicates */
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastNameContains?: InputMaybe<Scalars['String']['input']>;
  lastNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  lastNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  lastNameGT?: InputMaybe<Scalars['String']['input']>;
  lastNameGTE?: InputMaybe<Scalars['String']['input']>;
  lastNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  lastNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  lastNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  lastNameLT?: InputMaybe<Scalars['String']['input']>;
  lastNameLTE?: InputMaybe<Scalars['String']['input']>;
  lastNameNEQ?: InputMaybe<Scalars['String']['input']>;
  lastNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<SupportTicketWhereInput>;
  or?: InputMaybe<Array<SupportTicketWhereInput>>;
  /** phone_number field predicates */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberContains?: InputMaybe<Scalars['String']['input']>;
  phoneNumberContainsFold?: InputMaybe<Scalars['String']['input']>;
  phoneNumberEqualFold?: InputMaybe<Scalars['String']['input']>;
  phoneNumberGT?: InputMaybe<Scalars['String']['input']>;
  phoneNumberGTE?: InputMaybe<Scalars['String']['input']>;
  phoneNumberHasPrefix?: InputMaybe<Scalars['String']['input']>;
  phoneNumberHasSuffix?: InputMaybe<Scalars['String']['input']>;
  phoneNumberIn?: InputMaybe<Array<Scalars['String']['input']>>;
  phoneNumberIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumberLT?: InputMaybe<Scalars['String']['input']>;
  phoneNumberLTE?: InputMaybe<Scalars['String']['input']>;
  phoneNumberNEQ?: InputMaybe<Scalars['String']['input']>;
  phoneNumberNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  phoneNumberNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** product_type field predicates */
  productType?: InputMaybe<Scalars['String']['input']>;
  productTypeContains?: InputMaybe<Scalars['String']['input']>;
  productTypeContainsFold?: InputMaybe<Scalars['String']['input']>;
  productTypeEqualFold?: InputMaybe<Scalars['String']['input']>;
  productTypeGT?: InputMaybe<Scalars['String']['input']>;
  productTypeGTE?: InputMaybe<Scalars['String']['input']>;
  productTypeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  productTypeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  productTypeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  productTypeLT?: InputMaybe<Scalars['String']['input']>;
  productTypeLTE?: InputMaybe<Scalars['String']['input']>;
  productTypeNEQ?: InputMaybe<Scalars['String']['input']>;
  productTypeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** status field predicates */
  status?: InputMaybe<SupportTicketStatus>;
  statusIn?: InputMaybe<Array<SupportTicketStatus>>;
  statusNEQ?: InputMaybe<SupportTicketStatus>;
  statusNotIn?: InputMaybe<Array<SupportTicketStatus>>;
  /** ticket_type field predicates */
  ticketType?: InputMaybe<Scalars['String']['input']>;
  ticketTypeContains?: InputMaybe<Scalars['String']['input']>;
  ticketTypeContainsFold?: InputMaybe<Scalars['String']['input']>;
  ticketTypeEqualFold?: InputMaybe<Scalars['String']['input']>;
  ticketTypeGT?: InputMaybe<Scalars['String']['input']>;
  ticketTypeGTE?: InputMaybe<Scalars['String']['input']>;
  ticketTypeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  ticketTypeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  ticketTypeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  ticketTypeLT?: InputMaybe<Scalars['String']['input']>;
  ticketTypeLTE?: InputMaybe<Scalars['String']['input']>;
  ticketTypeNEQ?: InputMaybe<Scalars['String']['input']>;
  ticketTypeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type TaxFilingInfo = {
  __typename?: 'TaxFilingInfo';
  adjustGrossIncome?: Maybe<Scalars['Int']['output']>;
  filingStatus?: Maybe<UserFilingStatus>;
};

export type Tenant = Node & {
  __typename?: 'Tenant';
  advisory?: Maybe<Advisory>;
  alias: Scalars['String']['output'];
  brokerage?: Maybe<BrokerDealer>;
  id: Scalars['ID']['output'];
};

/**
 * TenantWhereInput is used for filtering Tenant objects.
 * Input was generated by ent.
 */
export type TenantWhereInput = {
  /** alias field predicates */
  alias?: InputMaybe<Scalars['String']['input']>;
  aliasContains?: InputMaybe<Scalars['String']['input']>;
  aliasContainsFold?: InputMaybe<Scalars['String']['input']>;
  aliasEqualFold?: InputMaybe<Scalars['String']['input']>;
  aliasGT?: InputMaybe<Scalars['String']['input']>;
  aliasGTE?: InputMaybe<Scalars['String']['input']>;
  aliasHasPrefix?: InputMaybe<Scalars['String']['input']>;
  aliasHasSuffix?: InputMaybe<Scalars['String']['input']>;
  aliasIn?: InputMaybe<Array<Scalars['String']['input']>>;
  aliasLT?: InputMaybe<Scalars['String']['input']>;
  aliasLTE?: InputMaybe<Scalars['String']['input']>;
  aliasNEQ?: InputMaybe<Scalars['String']['input']>;
  aliasNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  and?: InputMaybe<Array<TenantWhereInput>>;
  /** advisory edge predicates */
  hasAdvisory?: InputMaybe<Scalars['Boolean']['input']>;
  hasAdvisoryWith?: InputMaybe<Array<AdvisoryWhereInput>>;
  /** brokerage edge predicates */
  hasBrokerage?: InputMaybe<Scalars['Boolean']['input']>;
  hasBrokerageWith?: InputMaybe<Array<BrokerDealerWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<TenantWhereInput>;
  or?: InputMaybe<Array<TenantWhereInput>>;
};

/** TimePeriod is the amount of time between data points */
export enum TimePeriod {
  TimePeriodDay = 'TIME_PERIOD_DAY',
  TimePeriodHour = 'TIME_PERIOD_HOUR',
  TimePeriodMinute = 'TIME_PERIOD_MINUTE',
  TimePeriodMinute_5 = 'TIME_PERIOD_MINUTE_5',
  TimePeriodMinute_15 = 'TIME_PERIOD_MINUTE_15',
  TimePeriodMonth = 'TIME_PERIOD_MONTH',
  TimePeriodQuarter = 'TIME_PERIOD_QUARTER',
  TimePeriodWeek = 'TIME_PERIOD_WEEK',
  TimePeriodYear = 'TIME_PERIOD_YEAR',
}

/** TimeSeries is a list of data points with a time associated with each point */
export type TimeSeries = {
  __typename?: 'TimeSeries';
  /** end is the start datetime of the series */
  end: Scalars['Time']['output'];
  /** limit is the maximum number of datapoint used when creating the series */
  limit: Scalars['Int']['output'];
  /** period is the desired amount of time between data points */
  period: TimePeriod;
  /** start is the start datetime of the series */
  start: Scalars['Time']['output'];
  /** The time of each data point in the series */
  timestamps: Array<Scalars['Time']['output']>;
  /** The data points in the series */
  values: Array<Scalars['Decimal']['output']>;
};

/** TimeSeriesRange describes the time steps desired in a time-series output */
export type TimeSeriesRange = {
  /** end  is the start datetime of the series */
  end?: InputMaybe<Scalars['Time']['input']>;
  /** limit is the maximum number of datapoint returned in the series */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** period is the desired amount of time between data points */
  period: TimePeriod;
  /** start is the start datetime of the series */
  start?: InputMaybe<Scalars['Time']['input']>;
};

export type Trade = BaseOrder &
  Node & {
    __typename?: 'Trade';
    /** ApexFeeAmount is the total amount of fees taken by Apex */
    apexFeeAmount?: Maybe<Scalars['Decimal']['output']>;
    /** base is the base asset of the trade */
    base: BaseAsset;
    /** QuoteAmount is the final amount of the base asset that was involved in the trade. */
    baseAmount?: Maybe<Scalars['Decimal']['output']>;
    createTime: Scalars['Time']['output'];
    creditAccount: UserAccountPortfolioAsset;
    debitAccount: UserAccountPortfolioAsset;
    /** ErrorCode is the error code of the trade if it failed */
    errorCode?: Maybe<TradeTradeErrorCode>;
    /** ExpectedSettlement is the date that the transfer is expected to settle. */
    expectedSettlementDate?: Maybe<Scalars['Date']['output']>;
    expiration?: Maybe<Scalars['Time']['output']>;
    expirationType: TradeTradeExpirationType;
    /** external id of the trade provided by the entity performing the trade. */
    externalID?: Maybe<Scalars['String']['output']>;
    /** FeeAmount is the total amount of fees taken by Fintron */
    feeAmount?: Maybe<Scalars['Decimal']['output']>;
    /** returns true if the trade was made as part of a liquidation. */
    fromLiquidation: Scalars['Boolean']['output'];
    /** the time that the trade was approved for issuance. */
    fundedTime?: Maybe<Scalars['Time']['output']>;
    id: Scalars['ID']['output'];
    /** true if the trade values have been adjusted by a corporate action */
    isAdjusted: Scalars['Boolean']['output'];
    isAll: Scalars['Boolean']['output'];
    /** the time that the trade was issued to the clearing house */
    issuedTime?: Maybe<Scalars['Time']['output']>;
    /** Limit is the limit price for the trade */
    limit?: Maybe<Scalars['Decimal']['output']>;
    /** link to the liquidation that created this trade if this was part of a liquidation */
    liquidation?: Maybe<Liquidation>;
    /** Notes is the notes of the order intended for the principal. */
    note?: Maybe<Scalars['String']['output']>;
    orderStatus: OrderOrderStatus;
    orderType: OrderOrderType;
    portfolio: UserAccountPortfolio;
    /** quote is the quote asset of the trade */
    quote: BaseAsset;
    /** BaseAmount is the final amount of the quote asset that was involved in the trade. */
    quoteAmount?: Maybe<Scalars['Decimal']['output']>;
    /** Reason will be extra explanation for the final state if canceled or failed. */
    reason?: Maybe<Scalars['String']['output']>;
    /** RecurringRuleInstance is the recurring rule instance that created this trade */
    recurringRuleInstance?: Maybe<RecurringRuleInstance>;
    /** RequestAmount is the value given in the trade request, or the full value of the trade's quote or credit account */
    requestAmount: Scalars['Decimal']['output'];
    requestDetails: RequestDetails;
    requestInQuote: Scalars['Boolean']['output'];
    /** the time that the trade was settled. */
    settledTime?: Maybe<Scalars['Time']['output']>;
    side: TradeMarketSide;
    status: TradeTradeStatus;
    statusChangeLog: Array<TradeStatusChangeLog>;
    /** Stop is the stop price for the trade */
    stop?: Maybe<Scalars['Decimal']['output']>;
    type: TradeTradeType;
    /** QuoteAmount is the final amount of the base asset that was involved in the trade. */
    unadjustedBaseAmount?: Maybe<Scalars['Decimal']['output']>;
    /** Limit is the limit price for the trade */
    unadjustedLimit?: Maybe<Scalars['Decimal']['output']>;
    /** request amount value at the time the trade was created. If nil, same a request_amount */
    unadjustedRequestAmount?: Maybe<Scalars['Decimal']['output']>;
    /** Stop is the stop price for the trade */
    unadjustedStop?: Maybe<Scalars['Decimal']['output']>;
    /** the time that confirmation for the trade was received. */
    unsettledTime?: Maybe<Scalars['Time']['output']>;
    updateTime: Scalars['Time']['output'];
    usedUnsettledFunds: Scalars['Boolean']['output'];
    user: User;
  };

export type TradeStatusChangeLogArgs = {
  status?: InputMaybe<TradeTradeStatus>;
  statusIn?: InputMaybe<Array<TradeTradeStatus>>;
};

/** A connection to a list of items. */
export type TradeConnection = {
  __typename?: 'TradeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TradeEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type TradeEdge = {
  __typename?: 'TradeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Trade>;
};

/** TradeMarketSide is enum for the field side */
export enum TradeMarketSide {
  MarketSideBuy = 'MARKET_SIDE_BUY',
  MarketSideSell = 'MARKET_SIDE_SELL',
}

/** Ordering options for Trade connections */
export type TradeOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Trades. */
  field: TradeOrderField;
};

/** Properties by which Trade connections can be ordered. */
export enum TradeOrderField {
  CreateTime = 'create_time',
  FundedTime = 'funded_time',
  IssuedTime = 'issued_time',
  SettledTime = 'settled_time',
  UnsettledTime = 'unsettled_time',
  UpdateTime = 'update_time',
}

export type TradeStatusChangeLog = {
  __typename?: 'TradeStatusChangeLog';
  eventTime: Scalars['Time']['output'];
  note: Scalars['String']['output'];
  status: TradeTradeStatus;
};

/** TradeTradeErrorCode is enum for the field error_code */
export enum TradeTradeErrorCode {
  TradeErrorCodeAfterHours = 'TRADE_ERROR_CODE_AFTER_HOURS',
  TradeErrorCodeInstrumentUnavailable = 'TRADE_ERROR_CODE_INSTRUMENT_UNAVAILABLE',
  TradeErrorCodeInsufficientFunds = 'TRADE_ERROR_CODE_INSUFFICIENT_FUNDS',
  TradeErrorCodeInvalidAmount = 'TRADE_ERROR_CODE_INVALID_AMOUNT',
  TradeErrorCodeInvalidInstrument = 'TRADE_ERROR_CODE_INVALID_INSTRUMENT',
  TradeErrorCodeInvalidOrder = 'TRADE_ERROR_CODE_INVALID_ORDER',
  TradeErrorCodeOther = 'TRADE_ERROR_CODE_OTHER',
  TradeErrorCodeServerError = 'TRADE_ERROR_CODE_SERVER_ERROR',
  TradeErrorCodeShareLimitExceeded = 'TRADE_ERROR_CODE_SHARE_LIMIT_EXCEEDED',
}

/** TradeTradeExpirationType is enum for the field expiration_type */
export enum TradeTradeExpirationType {
  TradeExpirationTypeDay = 'TRADE_EXPIRATION_TYPE_DAY',
  TradeExpirationTypeGoodTilCancel = 'TRADE_EXPIRATION_TYPE_GOOD_TIL_CANCEL',
}

/** TradeTradeStatus is enum for the field status */
export enum TradeTradeStatus {
  TradeStatusCanceled = 'TRADE_STATUS_CANCELED',
  TradeStatusCanceling = 'TRADE_STATUS_CANCELING',
  TradeStatusFailed = 'TRADE_STATUS_FAILED',
  TradeStatusFunded = 'TRADE_STATUS_FUNDED',
  TradeStatusHold = 'TRADE_STATUS_HOLD',
  TradeStatusIssued = 'TRADE_STATUS_ISSUED',
  TradeStatusNew = 'TRADE_STATUS_NEW',
  TradeStatusSettled = 'TRADE_STATUS_SETTLED',
  TradeStatusUnsettled = 'TRADE_STATUS_UNSETTLED',
}

/** TradeTradeType is enum for the field type */
export enum TradeTradeType {
  TradeTypeLimit = 'TRADE_TYPE_LIMIT',
  TradeTypeMarket = 'TRADE_TYPE_MARKET',
  TradeTypeMit = 'TRADE_TYPE_MIT',
  TradeTypeStopLimit = 'TRADE_TYPE_STOP_LIMIT',
  TradeTypeStopLoss = 'TRADE_TYPE_STOP_LOSS',
}

/**
 * TradeWhereInput is used for filtering Trade objects.
 * Input was generated by ent.
 */
export type TradeWhereInput = {
  and?: InputMaybe<Array<TradeWhereInput>>;
  /** apex_fee_amount field predicates */
  apexFeeAmount?: InputMaybe<Scalars['Decimal']['input']>;
  apexFeeAmountGT?: InputMaybe<Scalars['Decimal']['input']>;
  apexFeeAmountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  apexFeeAmountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  apexFeeAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  apexFeeAmountLT?: InputMaybe<Scalars['Decimal']['input']>;
  apexFeeAmountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  apexFeeAmountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  apexFeeAmountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  apexFeeAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** base_amount field predicates */
  baseAmount?: InputMaybe<Scalars['Decimal']['input']>;
  baseAmountGT?: InputMaybe<Scalars['Decimal']['input']>;
  baseAmountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  baseAmountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  baseAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  baseAmountLT?: InputMaybe<Scalars['Decimal']['input']>;
  baseAmountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  baseAmountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  baseAmountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  baseAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** error_code field predicates */
  errorCode?: InputMaybe<TradeTradeErrorCode>;
  errorCodeIn?: InputMaybe<Array<TradeTradeErrorCode>>;
  errorCodeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  errorCodeNEQ?: InputMaybe<TradeTradeErrorCode>;
  errorCodeNotIn?: InputMaybe<Array<TradeTradeErrorCode>>;
  errorCodeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** expected_settlement_date field predicates */
  expectedSettlementDate?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateGT?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateGTE?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  expectedSettlementDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  expectedSettlementDateLT?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateLTE?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  expectedSettlementDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  expectedSettlementDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** expiration field predicates */
  expiration?: InputMaybe<Scalars['Time']['input']>;
  expirationGT?: InputMaybe<Scalars['Time']['input']>;
  expirationGTE?: InputMaybe<Scalars['Time']['input']>;
  expirationIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  expirationIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  expirationLT?: InputMaybe<Scalars['Time']['input']>;
  expirationLTE?: InputMaybe<Scalars['Time']['input']>;
  expirationNEQ?: InputMaybe<Scalars['Time']['input']>;
  expirationNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  expirationNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** expiration_type field predicates */
  expirationType?: InputMaybe<TradeTradeExpirationType>;
  expirationTypeIn?: InputMaybe<Array<TradeTradeExpirationType>>;
  expirationTypeNEQ?: InputMaybe<TradeTradeExpirationType>;
  expirationTypeNotIn?: InputMaybe<Array<TradeTradeExpirationType>>;
  /** external_id field predicates */
  externalID?: InputMaybe<Scalars['String']['input']>;
  externalIDContains?: InputMaybe<Scalars['String']['input']>;
  externalIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  externalIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  externalIDGT?: InputMaybe<Scalars['String']['input']>;
  externalIDGTE?: InputMaybe<Scalars['String']['input']>;
  externalIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  externalIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  externalIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  externalIDLT?: InputMaybe<Scalars['String']['input']>;
  externalIDLTE?: InputMaybe<Scalars['String']['input']>;
  externalIDNEQ?: InputMaybe<Scalars['String']['input']>;
  externalIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** fee_amount field predicates */
  feeAmount?: InputMaybe<Scalars['Decimal']['input']>;
  feeAmountGT?: InputMaybe<Scalars['Decimal']['input']>;
  feeAmountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  feeAmountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  feeAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  feeAmountLT?: InputMaybe<Scalars['Decimal']['input']>;
  feeAmountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  feeAmountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  feeAmountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  feeAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** funded_time field predicates */
  fundedTime?: InputMaybe<Scalars['Time']['input']>;
  fundedTimeGT?: InputMaybe<Scalars['Time']['input']>;
  fundedTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  fundedTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  fundedTimeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  fundedTimeLT?: InputMaybe<Scalars['Time']['input']>;
  fundedTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  fundedTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  fundedTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  fundedTimeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  hasBaseAssetWith?: InputMaybe<BaseAssetWhereInput>;
  /** credit_account edge predicates */
  hasCreditAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasCreditAccountWith?: InputMaybe<Array<UserAccountPortfolioAssetWhereInput>>;
  /** debit_account edge predicates */
  hasDebitAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasDebitAccountWith?: InputMaybe<Array<UserAccountPortfolioAssetWhereInput>>;
  /** liquidation edge predicates */
  hasLiquidation?: InputMaybe<Scalars['Boolean']['input']>;
  hasLiquidationWith?: InputMaybe<Array<LiquidationWhereInput>>;
  /** portfolio edge predicates */
  hasPortfolio?: InputMaybe<Scalars['Boolean']['input']>;
  hasPortfolioWith?: InputMaybe<Array<UserAccountPortfolioWhereInput>>;
  hasQuoteAssetWith?: InputMaybe<BaseAssetWhereInput>;
  /** recurring_rule_instance edge predicates */
  hasRecurringRuleInstance?: InputMaybe<Scalars['Boolean']['input']>;
  hasRecurringRuleInstanceWith?: InputMaybe<Array<RecurringRuleInstanceWhereInput>>;
  hasUserWith?: InputMaybe<UserWhereInput>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_adjusted field predicates */
  isAdjusted?: InputMaybe<Scalars['Boolean']['input']>;
  isAdjustedNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** is_all field predicates */
  isAll?: InputMaybe<Scalars['Boolean']['input']>;
  isAllNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** issued_time field predicates */
  issuedTime?: InputMaybe<Scalars['Time']['input']>;
  issuedTimeGT?: InputMaybe<Scalars['Time']['input']>;
  issuedTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  issuedTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  issuedTimeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  issuedTimeLT?: InputMaybe<Scalars['Time']['input']>;
  issuedTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  issuedTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  issuedTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  issuedTimeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** limit field predicates */
  limit?: InputMaybe<Scalars['Decimal']['input']>;
  limitGT?: InputMaybe<Scalars['Decimal']['input']>;
  limitGTE?: InputMaybe<Scalars['Decimal']['input']>;
  limitIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  limitIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  limitLT?: InputMaybe<Scalars['Decimal']['input']>;
  limitLTE?: InputMaybe<Scalars['Decimal']['input']>;
  limitNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  limitNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  limitNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<TradeWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<TradeWhereInput>>;
  /** quote_amount field predicates */
  quoteAmount?: InputMaybe<Scalars['Decimal']['input']>;
  quoteAmountGT?: InputMaybe<Scalars['Decimal']['input']>;
  quoteAmountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  quoteAmountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  quoteAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  quoteAmountLT?: InputMaybe<Scalars['Decimal']['input']>;
  quoteAmountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  quoteAmountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  quoteAmountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  quoteAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** reason field predicates */
  reason?: InputMaybe<Scalars['String']['input']>;
  reasonContains?: InputMaybe<Scalars['String']['input']>;
  reasonContainsFold?: InputMaybe<Scalars['String']['input']>;
  reasonEqualFold?: InputMaybe<Scalars['String']['input']>;
  reasonGT?: InputMaybe<Scalars['String']['input']>;
  reasonGTE?: InputMaybe<Scalars['String']['input']>;
  reasonHasPrefix?: InputMaybe<Scalars['String']['input']>;
  reasonHasSuffix?: InputMaybe<Scalars['String']['input']>;
  reasonIn?: InputMaybe<Array<Scalars['String']['input']>>;
  reasonIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  reasonLT?: InputMaybe<Scalars['String']['input']>;
  reasonLTE?: InputMaybe<Scalars['String']['input']>;
  reasonNEQ?: InputMaybe<Scalars['String']['input']>;
  reasonNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  reasonNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** request_amount field predicates */
  requestAmount?: InputMaybe<Scalars['Decimal']['input']>;
  requestAmountGT?: InputMaybe<Scalars['Decimal']['input']>;
  requestAmountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  requestAmountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  requestAmountLT?: InputMaybe<Scalars['Decimal']['input']>;
  requestAmountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  requestAmountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  requestAmountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** request_in_quote field predicates */
  requestInQuote?: InputMaybe<Scalars['Boolean']['input']>;
  requestInQuoteNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** settled_time field predicates */
  settledTime?: InputMaybe<Scalars['Time']['input']>;
  settledTimeGT?: InputMaybe<Scalars['Time']['input']>;
  settledTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  settledTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  settledTimeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  settledTimeLT?: InputMaybe<Scalars['Time']['input']>;
  settledTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  settledTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  settledTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  settledTimeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** side field predicates */
  side?: InputMaybe<TradeMarketSide>;
  sideIn?: InputMaybe<Array<TradeMarketSide>>;
  sideNEQ?: InputMaybe<TradeMarketSide>;
  sideNotIn?: InputMaybe<Array<TradeMarketSide>>;
  /** status field predicates */
  status?: InputMaybe<TradeTradeStatus>;
  statusIn?: InputMaybe<Array<TradeTradeStatus>>;
  statusNEQ?: InputMaybe<TradeTradeStatus>;
  statusNotIn?: InputMaybe<Array<TradeTradeStatus>>;
  /** stop field predicates */
  stop?: InputMaybe<Scalars['Decimal']['input']>;
  stopGT?: InputMaybe<Scalars['Decimal']['input']>;
  stopGTE?: InputMaybe<Scalars['Decimal']['input']>;
  stopIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  stopIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  stopLT?: InputMaybe<Scalars['Decimal']['input']>;
  stopLTE?: InputMaybe<Scalars['Decimal']['input']>;
  stopNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  stopNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  stopNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** type field predicates */
  type?: InputMaybe<TradeTradeType>;
  typeIn?: InputMaybe<Array<TradeTradeType>>;
  typeNEQ?: InputMaybe<TradeTradeType>;
  typeNotIn?: InputMaybe<Array<TradeTradeType>>;
  /** unadjusted_base_amount field predicates */
  unadjustedBaseAmount?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedBaseAmountGT?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedBaseAmountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedBaseAmountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  unadjustedBaseAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  unadjustedBaseAmountLT?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedBaseAmountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedBaseAmountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedBaseAmountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  unadjustedBaseAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** unadjusted_limit field predicates */
  unadjustedLimit?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedLimitGT?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedLimitGTE?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedLimitIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  unadjustedLimitIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  unadjustedLimitLT?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedLimitLTE?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedLimitNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedLimitNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  unadjustedLimitNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** unadjusted_request_amount field predicates */
  unadjustedRequestAmount?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedRequestAmountGT?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedRequestAmountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedRequestAmountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  unadjustedRequestAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  unadjustedRequestAmountLT?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedRequestAmountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedRequestAmountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedRequestAmountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  unadjustedRequestAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** unadjusted_stop field predicates */
  unadjustedStop?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedStopGT?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedStopGTE?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedStopIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  unadjustedStopIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  unadjustedStopLT?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedStopLTE?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedStopNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  unadjustedStopNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  unadjustedStopNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** unsettled_time field predicates */
  unsettledTime?: InputMaybe<Scalars['Time']['input']>;
  unsettledTimeGT?: InputMaybe<Scalars['Time']['input']>;
  unsettledTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  unsettledTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  unsettledTimeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  unsettledTimeLT?: InputMaybe<Scalars['Time']['input']>;
  unsettledTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  unsettledTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  unsettledTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  unsettledTimeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** used_unsettled_funds field predicates */
  usedUnsettledFunds?: InputMaybe<Scalars['Boolean']['input']>;
  usedUnsettledFundsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TrustedContact = Node & {
  __typename?: 'TrustedContact';
  dateOfBirth: Scalars['Date']['output'];
  email: Scalars['String']['output'];
  externalID?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated No longer used. */
  isActive?: Maybe<Scalars['Boolean']['output']>;
  lastName: Scalars['String']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['PhoneNumber']['output']>;
  phoneNumberType?: Maybe<TrustedContactUserPhoneNumberType>;
  removeRequestedTimestamp?: Maybe<Scalars['Time']['output']>;
  /** The status of the trusted contact. */
  status?: Maybe<TrustedContactTrustedContactStatus>;
  /** Update requests for this trusted contact record */
  updateRequests?: Maybe<Array<TrustedContactUpdateRequest>>;
  userAccount?: Maybe<UserAccount>;
};

/** A connection to a list of items. */
export type TrustedContactConnection = {
  __typename?: 'TrustedContactConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TrustedContactEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type TrustedContactEdge = {
  __typename?: 'TrustedContactEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<TrustedContact>;
};

export type TrustedContactParameters = {
  __typename?: 'TrustedContactParameters';
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

/** TrustedContactTrustedContactStatus is enum for the field status */
export enum TrustedContactTrustedContactStatus {
  TrustedContactStatusActive = 'TRUSTED_CONTACT_STATUS_ACTIVE',
  TrustedContactStatusInactive = 'TRUSTED_CONTACT_STATUS_INACTIVE',
  TrustedContactStatusPending = 'TRUSTED_CONTACT_STATUS_PENDING',
}

export type TrustedContactUpdateRequest = Node & {
  __typename?: 'TrustedContactUpdateRequest';
  createTime: Scalars['Time']['output'];
  /** The snapshot of the trusted contact at the time of this request. */
  currentTrustedContact?: Maybe<TrustedContactParameters>;
  discretionaryUpdateRequest?: Maybe<DiscretionaryUpdateRequest>;
  id: Scalars['ID']['output'];
  nonDiscretionaryUpdateRequest?: Maybe<NonDiscretionaryUpdateRequest>;
  requestDetails: RequestDetails;
  /** The trusted contact parameters that are being updated. */
  requestParameters: TrustedContactParameters;
  /** The time at which the action was requested. */
  requestTime: Scalars['Time']['output'];
  requestUUID?: Maybe<Scalars['UUID']['output']>;
  requestor: Requestor;
  /** The status of the update request. */
  status: TrustedContactUpdateRequestUpdateRequestStatus;
  submittedAt?: Maybe<Scalars['Time']['output']>;
  /** The trusted contact that this update request is associated with. */
  trustedContact?: Maybe<TrustedContact>;
  updateTime: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type TrustedContactUpdateRequestConnection = {
  __typename?: 'TrustedContactUpdateRequestConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TrustedContactUpdateRequestEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type TrustedContactUpdateRequestEdge = {
  __typename?: 'TrustedContactUpdateRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<TrustedContactUpdateRequest>;
};

/** Ordering options for TrustedContactUpdateRequest connections */
export type TrustedContactUpdateRequestOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order TrustedContactUpdateRequests. */
  field: TrustedContactUpdateRequestOrderField;
};

/** Properties by which TrustedContactUpdateRequest connections can be ordered. */
export enum TrustedContactUpdateRequestOrderField {
  RequestTime = 'REQUEST_TIME',
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/** TrustedContactUpdateRequestUpdateRequestStatus is enum for the field status */
export enum TrustedContactUpdateRequestUpdateRequestStatus {
  UpdateRequestStatusApproved = 'UPDATE_REQUEST_STATUS_APPROVED',
  UpdateRequestStatusContacated = 'UPDATE_REQUEST_STATUS_CONTACATED',
  UpdateRequestStatusPending = 'UPDATE_REQUEST_STATUS_PENDING',
  UpdateRequestStatusRejected = 'UPDATE_REQUEST_STATUS_REJECTED',
  UpdateRequestStatusSubmitted = 'UPDATE_REQUEST_STATUS_SUBMITTED',
}

/**
 * TrustedContactUpdateRequestWhereInput is used for filtering TrustedContactUpdateRequest objects.
 * Input was generated by ent.
 */
export type TrustedContactUpdateRequestWhereInput = {
  and?: InputMaybe<Array<TrustedContactUpdateRequestWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** discretionary_update_request edge predicates */
  hasDiscretionaryUpdateRequest?: InputMaybe<Scalars['Boolean']['input']>;
  hasDiscretionaryUpdateRequestWith?: InputMaybe<Array<DiscretionaryUpdateRequestWhereInput>>;
  /** non_discretionary_update_request edge predicates */
  hasNonDiscretionaryUpdateRequest?: InputMaybe<Scalars['Boolean']['input']>;
  hasNonDiscretionaryUpdateRequestWith?: InputMaybe<Array<NonDiscretionaryUpdateRequestWhereInput>>;
  /** trusted_contact edge predicates */
  hasTrustedContact?: InputMaybe<Scalars['Boolean']['input']>;
  hasTrustedContactWith?: InputMaybe<Array<TrustedContactWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<TrustedContactUpdateRequestWhereInput>;
  or?: InputMaybe<Array<TrustedContactUpdateRequestWhereInput>>;
  /** request_time field predicates */
  requestTime?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  requestTimeLT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** request_uuid field predicates */
  requestUUID?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  requestUUIDLT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDLTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNEQ?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNotIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** requestor field predicates */
  requestor?: InputMaybe<Requestor>;
  requestorIn?: InputMaybe<Array<Requestor>>;
  requestorNEQ?: InputMaybe<Requestor>;
  requestorNotIn?: InputMaybe<Array<Requestor>>;
  /** status field predicates */
  status?: InputMaybe<TrustedContactUpdateRequestUpdateRequestStatus>;
  statusIn?: InputMaybe<Array<TrustedContactUpdateRequestUpdateRequestStatus>>;
  statusNEQ?: InputMaybe<TrustedContactUpdateRequestUpdateRequestStatus>;
  statusNotIn?: InputMaybe<Array<TrustedContactUpdateRequestUpdateRequestStatus>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** TrustedContactUserPhoneNumberType is enum for the field phone_number_type */
export enum TrustedContactUserPhoneNumberType {
  UserPhoneNumberTypeFax = 'USER_PHONE_NUMBER_TYPE_FAX',
  UserPhoneNumberTypeHome = 'USER_PHONE_NUMBER_TYPE_HOME',
  UserPhoneNumberTypeMobile = 'USER_PHONE_NUMBER_TYPE_MOBILE',
  UserPhoneNumberTypeOther = 'USER_PHONE_NUMBER_TYPE_OTHER',
  UserPhoneNumberTypeWork = 'USER_PHONE_NUMBER_TYPE_WORK',
}

/**
 * TrustedContactWhereInput is used for filtering TrustedContact objects.
 * Input was generated by ent.
 */
export type TrustedContactWhereInput = {
  and?: InputMaybe<Array<TrustedContactWhereInput>>;
  /** date_of_birth field predicates */
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirthGT?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirthGTE?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirthIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  dateOfBirthLT?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirthLTE?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirthNEQ?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirthNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  /** email field predicates */
  email?: InputMaybe<Scalars['String']['input']>;
  emailContains?: InputMaybe<Scalars['String']['input']>;
  emailContainsFold?: InputMaybe<Scalars['String']['input']>;
  emailEqualFold?: InputMaybe<Scalars['String']['input']>;
  emailGT?: InputMaybe<Scalars['String']['input']>;
  emailGTE?: InputMaybe<Scalars['String']['input']>;
  emailHasPrefix?: InputMaybe<Scalars['String']['input']>;
  emailHasSuffix?: InputMaybe<Scalars['String']['input']>;
  emailIn?: InputMaybe<Array<Scalars['String']['input']>>;
  emailLT?: InputMaybe<Scalars['String']['input']>;
  emailLTE?: InputMaybe<Scalars['String']['input']>;
  emailNEQ?: InputMaybe<Scalars['String']['input']>;
  emailNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** external_ID field predicates */
  externalID?: InputMaybe<Scalars['String']['input']>;
  externalIDContains?: InputMaybe<Scalars['String']['input']>;
  externalIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  externalIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  externalIDGT?: InputMaybe<Scalars['String']['input']>;
  externalIDGTE?: InputMaybe<Scalars['String']['input']>;
  externalIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  externalIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  externalIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  externalIDLT?: InputMaybe<Scalars['String']['input']>;
  externalIDLTE?: InputMaybe<Scalars['String']['input']>;
  externalIDNEQ?: InputMaybe<Scalars['String']['input']>;
  externalIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** first_name field predicates */
  firstName?: InputMaybe<Scalars['String']['input']>;
  firstNameContains?: InputMaybe<Scalars['String']['input']>;
  firstNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  firstNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  firstNameGT?: InputMaybe<Scalars['String']['input']>;
  firstNameGTE?: InputMaybe<Scalars['String']['input']>;
  firstNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  firstNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  firstNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  firstNameLT?: InputMaybe<Scalars['String']['input']>;
  firstNameLTE?: InputMaybe<Scalars['String']['input']>;
  firstNameNEQ?: InputMaybe<Scalars['String']['input']>;
  firstNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** update_requests edge predicates */
  hasUpdateRequests?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdateRequestsWith?: InputMaybe<Array<TrustedContactUpdateRequestWhereInput>>;
  /** user_account edge predicates */
  hasUserAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserAccountWith?: InputMaybe<Array<UserAccountWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_active field predicates */
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isActiveIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  isActiveNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  isActiveNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** last_name field predicates */
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastNameContains?: InputMaybe<Scalars['String']['input']>;
  lastNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  lastNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  lastNameGT?: InputMaybe<Scalars['String']['input']>;
  lastNameGTE?: InputMaybe<Scalars['String']['input']>;
  lastNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  lastNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  lastNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  lastNameLT?: InputMaybe<Scalars['String']['input']>;
  lastNameLTE?: InputMaybe<Scalars['String']['input']>;
  lastNameNEQ?: InputMaybe<Scalars['String']['input']>;
  lastNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** middle_name field predicates */
  middleName?: InputMaybe<Scalars['String']['input']>;
  middleNameContains?: InputMaybe<Scalars['String']['input']>;
  middleNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  middleNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  middleNameGT?: InputMaybe<Scalars['String']['input']>;
  middleNameGTE?: InputMaybe<Scalars['String']['input']>;
  middleNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  middleNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  middleNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  middleNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  middleNameLT?: InputMaybe<Scalars['String']['input']>;
  middleNameLTE?: InputMaybe<Scalars['String']['input']>;
  middleNameNEQ?: InputMaybe<Scalars['String']['input']>;
  middleNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  middleNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<TrustedContactWhereInput>;
  or?: InputMaybe<Array<TrustedContactWhereInput>>;
  /** phone_number field predicates */
  phoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>;
  phoneNumberContains?: InputMaybe<Scalars['PhoneNumber']['input']>;
  phoneNumberContainsFold?: InputMaybe<Scalars['PhoneNumber']['input']>;
  phoneNumberEqualFold?: InputMaybe<Scalars['PhoneNumber']['input']>;
  phoneNumberGT?: InputMaybe<Scalars['PhoneNumber']['input']>;
  phoneNumberGTE?: InputMaybe<Scalars['PhoneNumber']['input']>;
  phoneNumberHasPrefix?: InputMaybe<Scalars['PhoneNumber']['input']>;
  phoneNumberHasSuffix?: InputMaybe<Scalars['PhoneNumber']['input']>;
  phoneNumberIn?: InputMaybe<Array<Scalars['PhoneNumber']['input']>>;
  phoneNumberIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumberLT?: InputMaybe<Scalars['PhoneNumber']['input']>;
  phoneNumberLTE?: InputMaybe<Scalars['PhoneNumber']['input']>;
  phoneNumberNEQ?: InputMaybe<Scalars['PhoneNumber']['input']>;
  phoneNumberNotIn?: InputMaybe<Array<Scalars['PhoneNumber']['input']>>;
  phoneNumberNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** phone_number_type field predicates */
  phoneNumberType?: InputMaybe<TrustedContactUserPhoneNumberType>;
  phoneNumberTypeIn?: InputMaybe<Array<TrustedContactUserPhoneNumberType>>;
  phoneNumberTypeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumberTypeNEQ?: InputMaybe<TrustedContactUserPhoneNumberType>;
  phoneNumberTypeNotIn?: InputMaybe<Array<TrustedContactUserPhoneNumberType>>;
  phoneNumberTypeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** remove_requested_timestamp field predicates */
  removeRequestedTimestamp?: InputMaybe<Scalars['Time']['input']>;
  removeRequestedTimestampGT?: InputMaybe<Scalars['Time']['input']>;
  removeRequestedTimestampGTE?: InputMaybe<Scalars['Time']['input']>;
  removeRequestedTimestampIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  removeRequestedTimestampIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  removeRequestedTimestampLT?: InputMaybe<Scalars['Time']['input']>;
  removeRequestedTimestampLTE?: InputMaybe<Scalars['Time']['input']>;
  removeRequestedTimestampNEQ?: InputMaybe<Scalars['Time']['input']>;
  removeRequestedTimestampNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  removeRequestedTimestampNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** status field predicates */
  status?: InputMaybe<TrustedContactTrustedContactStatus>;
  statusIn?: InputMaybe<Array<TrustedContactTrustedContactStatus>>;
  statusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  statusNEQ?: InputMaybe<TrustedContactTrustedContactStatus>;
  statusNotIn?: InputMaybe<Array<TrustedContactTrustedContactStatus>>;
  statusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

export type User = Node & {
  __typename?: 'User';
  acatTransfers: AcatTransferConnection;
  accounts: UserAccountConnection;
  achTransfers: AchTransferConnection;
  adminOrders: AdminOrderConnection;
  allowedAchRelationships: Scalars['Int']['output'];
  ascendInvestigation?: Maybe<Array<AscendInvestigation>>;
  corporateActionModifications: CorporateActionModificationConnection;
  createTime: Scalars['Time']['output'];
  discretionaryAccountKyc?: Maybe<DiscretionaryAccountKyc>;
  dividendDistributions: DividendDistributionConnection;
  email: Scalars['String']['output'];
  enabledCrypto: Scalars['Boolean']['output'];
  enabledRobo: Scalars['Boolean']['output'];
  externalAccounts: ExternalAccountConnection;
  /** Identifier used by clearing house. Should be prefixed with a valid client prefix. */
  externalID?: Maybe<Scalars['String']['output']>;
  feeAndCreditOrders: FeeAndCreditOrderConnection;
  firstName: Scalars['String']['output'];
  hasReturn: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** Is the user is in the application queue? */
  inQueue: Scalars['Boolean']['output'];
  isActive: Scalars['Boolean']['output'];
  isLoginMfaEnabled: Scalars['Boolean']['output'];
  /** @deprecated No longer used. */
  isStaff: Scalars['Boolean']['output'];
  isSubscription: Scalars['Boolean']['output'];
  isWithdrawalMfaEnabled: Scalars['Boolean']['output'];
  lastLoginTime?: Maybe<Scalars['Time']['output']>;
  lastName: Scalars['String']['output'];
  legacyID?: Maybe<Scalars['Int']['output']>;
  liquidations: LiquidationConnection;
  nonDiscretionaryAccountKyc?: Maybe<NonDiscretionaryAccountKyc>;
  orders: BaseOrderConnection;
  portfolios: UserAccountPortfolioConnection;
  profile?: Maybe<UserProfile>;
  recurringRules: RecurringRuleConnection;
  restrictionStatus: UserUserRestrictionStatus;
  retirementContributions?: Maybe<Array<UserRetirementContribution>>;
  roboGeneralInvestments: RoboGeneralInvestmentConnection;
  roboGoals: RoboGoalConnection;
  rtqResponses?: Maybe<Array<RtqResponse>>;
  settings?: Maybe<Array<UserSetting>>;
  skipReturnBuffer: Scalars['Boolean']['output'];
  submissions?: Maybe<Array<RtqSubmission>>;
  trades: TradeConnection;
  updateTime: Scalars['Time']['output'];
  username?: Maybe<Scalars['String']['output']>;
  /** Returns a list of watchlists or a single watchlist by name */
  watchlists: Array<Watchlist>;
};

export type UserAcatTransfersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AcatTransferWhereInput>;
};

export type UserAccountsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserAccountOrder>;
  where?: InputMaybe<UserAccountWhereInput>;
};

export type UserAchTransfersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AchTransferOrder>;
  where?: InputMaybe<AchTransferWhereInput>;
};

export type UserAdminOrdersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AdminOrderWhereInput>;
};

export type UserCorporateActionModificationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CorporateActionModificationWhereInput>;
};

export type UserDividendDistributionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DividendDistributionWhereInput>;
};

export type UserExternalAccountsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ExternalAccountOrder>;
  where?: InputMaybe<ExternalAccountWhereInput>;
};

export type UserFeeAndCreditOrdersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FeeAndCreditOrderWhereInput>;
};

export type UserLiquidationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<LiquidationOrder>;
  where?: InputMaybe<LiquidationWhereInput>;
};

export type UserOrdersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderOrder>;
  where?: InputMaybe<OrderWhereInput>;
};

export type UserPortfoliosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserAccountPortfolioWhereInput>;
};

export type UserRecurringRulesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RecurringRuleOrder>;
  where?: InputMaybe<RecurringRuleWhereInput>;
};

export type UserRoboGeneralInvestmentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RoboGeneralInvestmentOrder>;
  where?: InputMaybe<RoboGeneralInvestmentWhereInput>;
};

export type UserRoboGoalsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RoboGoalOrder>;
  where?: InputMaybe<RoboGoalWhereInput>;
};

export type UserTradesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TradeWhereInput>;
};

export type UserWatchlistsArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UserAccount = Node & {
  __typename?: 'UserAccount';
  adminActions?: Maybe<Array<AdminAction>>;
  /** The admin on file for this account. */
  adminOnFileID?: Maybe<Scalars['ID']['output']>;
  /** Tags that can be used to filter accounts in the admin UI */
  adminTags?: Maybe<Array<Scalars['String']['output']>>;
  /** The metadata for the account if it is an Apex account. */
  apexAccountMeta?: Maybe<ApexAccountMeta>;
  /** The apex account requests submitted for this account. */
  apexAccountRequests?: Maybe<Array<ApexAccountRequest>>;
  /** The update requests submitted for this account. */
  apexUpdateRequests?: Maybe<Array<ApexUpdateRequest>>;
  /** the metadata for the account if it is an Ascend account */
  ascendMeta?: Maybe<AscendMeta>;
  /** links to the asset balances rows for the different assets in this account. */
  balances?: Maybe<Array<UserAccountAssetBalance>>;
  /** The contingent beneficiary set for this account */
  beneficiariesContingent?: Maybe<BeneficiarySet>;
  /** The primary beneficiary set for this account */
  beneficiariesPrimary?: Maybe<BeneficiarySet>;
  beneficiarySet?: Maybe<Array<BeneficiarySet>>;
  /** The contingent beneficiary set for this account */
  beneficiarySetContingent?: Maybe<Scalars['ID']['output']>;
  /** The primary beneficiary set for this account */
  beneficiarySetPrimary?: Maybe<Scalars['ID']['output']>;
  /** account is enrolled in the FDIC cash sweep program */
  cashSweepEnrolled: Scalars['Boolean']['output'];
  createTime: Scalars['Time']['output'];
  discretionaryAccountKyc?: Maybe<DiscretionaryAccountKyc>;
  /** External account identifier if the account is an external account */
  externalID?: Maybe<Scalars['String']['output']>;
  /** account is enrolled in the FPSL program */
  fpslEnrolled: Scalars['Boolean']['output'];
  /** CAT reporting for the Account Holder Type/If the account is affiliated with Finra/Exchange */
  holderType?: Maybe<UserAccountAccountHolderType>;
  id: Scalars['ID']['output'];
  isEsigned: Scalars['Boolean']['output'];
  latestAdminAction?: Maybe<AdminAction>;
  meta?: Maybe<UserAccountMetadata>;
  nonDiscretionaryAccountKyc?: Maybe<NonDiscretionaryAccountKyc>;
  /** Account-level permissions and restrictions, used within the application. */
  permissions: Scalars['Int']['output'];
  /** links to the asset balances in the constituent portfolios in this account */
  portfolioBalances?: Maybe<Array<UserAccountPortfolioAssetBalance>>;
  portfolios: UserAccountPortfolioConnection;
  /** The program enrollment history for this account. */
  programEnrollmentHistory?: Maybe<Array<ProgramEnrollmentHistory>>;
  /** The time at which the action was requested. */
  requestTime: Scalars['Time']['output'];
  requestUUID?: Maybe<Scalars['UUID']['output']>;
  requestor: Requestor;
  restrictions?: Maybe<Array<UserAccountRestriction>>;
  sodDiscrepancyTrack?: Maybe<Array<SodDiscrepancyTrack>>;
  /** Status for User's Account. */
  status: UserAccountAccountStatus;
  submittingAdminActions?: Maybe<Array<Maybe<AdminAction>>>;
  trustedContacts?: Maybe<Array<TrustedContact>>;
  type: UserAccountAccountType;
  updateTime: Scalars['Time']['output'];
  user: User;
};

export type UserAccountBalancesArgs = {
  where?: InputMaybe<UserAccountAssetBalanceWhereInput>;
};

export type UserAccountPortfolioBalancesArgs = {
  where?: InputMaybe<UserAccountPortfolioAssetBalanceWhereInput>;
};

export type UserAccountPortfoliosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserAccountPortfolioWhereInput>;
};

/** UserAccountAccountHolderType is enum for the field holder_type */
export enum UserAccountAccountHolderType {
  AccountHolderTypeAvgPrice = 'ACCOUNT_HOLDER_TYPE_AVG_PRICE',
  AccountHolderTypeEmployee = 'ACCOUNT_HOLDER_TYPE_EMPLOYEE',
  AccountHolderTypeError = 'ACCOUNT_HOLDER_TYPE_ERROR',
  AccountHolderTypeForeign = 'ACCOUNT_HOLDER_TYPE_FOREIGN',
  AccountHolderTypeIndividual = 'ACCOUNT_HOLDER_TYPE_INDIVIDUAL',
  AccountHolderTypeInstitutional = 'ACCOUNT_HOLDER_TYPE_INSTITUTIONAL',
  AccountHolderTypeMarketMaking = 'ACCOUNT_HOLDER_TYPE_MARKET_MAKING',
  AccountHolderTypeProprietary = 'ACCOUNT_HOLDER_TYPE_PROPRIETARY',
}

/** UserAccountAccountStatus is enum for the field status */
export enum UserAccountAccountStatus {
  AccountStatusActive = 'ACCOUNT_STATUS_ACTIVE',
  AccountStatusAppealed = 'ACCOUNT_STATUS_APPEALED',
  AccountStatusCancelled = 'ACCOUNT_STATUS_CANCELLED',
  AccountStatusClosed = 'ACCOUNT_STATUS_CLOSED',
  AccountStatusContacted = 'ACCOUNT_STATUS_CONTACTED',
  AccountStatusDenied = 'ACCOUNT_STATUS_DENIED',
  AccountStatusError = 'ACCOUNT_STATUS_ERROR',
  AccountStatusNew = 'ACCOUNT_STATUS_NEW',
  AccountStatusPending = 'ACCOUNT_STATUS_PENDING',
  AccountStatusRejected = 'ACCOUNT_STATUS_REJECTED',
  AccountStatusSubmitted = 'ACCOUNT_STATUS_SUBMITTED',
  AccountStatusSuspended = 'ACCOUNT_STATUS_SUSPENDED',
}

/** UserAccountAccountType is enum for the field type */
export enum UserAccountAccountType {
  AccountTypeBrokerage = 'ACCOUNT_TYPE_BROKERAGE',
  AccountTypeBrokerageDiscretionary = 'ACCOUNT_TYPE_BROKERAGE_DISCRETIONARY',
  AccountTypeIra = 'ACCOUNT_TYPE_IRA',
  AccountTypeIraDiscretionary = 'ACCOUNT_TYPE_IRA_DISCRETIONARY',
  AccountTypeRothIra = 'ACCOUNT_TYPE_ROTH_IRA',
  AccountTypeRothIraDiscretionary = 'ACCOUNT_TYPE_ROTH_IRA_DISCRETIONARY',
}

export type UserAccountAssetBalance = Node & {
  __typename?: 'UserAccountAssetBalance';
  account: UserAccount;
  /** activeBalance is the amount of the asset that is currently in the account an usable in some way. All basic values added together except pending and pending liability */
  activeBalance: Scalars['Decimal']['output'];
  asset: BaseAsset;
  /** encumbrance is the amount of the asset that is currently in an encumbered state. These are funds that have already been used in some order but that order has yet to return. */
  encumbrance: Scalars['Decimal']['output'];
  /** frozen is the amount of the asset that is currently in a frozen state. These are funds that are currently being reserved for use in an order that has yet to utilize the funds. */
  frozen: Scalars['Decimal']['output'];
  id: Scalars['ID']['output'];
  /** liability is the amount of liability on the person's account. This liability represents the amount of funds that is owed by the user but has not yet been paid. */
  liability: Scalars['Decimal']['output'];
  /** pending is the amount of the asset that is currently in a pending state. These are funds that the system is aware of but are not yet useable in any meaningful way. */
  pending: Scalars['Decimal']['output'];
  /** pendingLiability is the amount of pending liability on the person's account. This liability represents the amount of funds that have been lent to the account. */
  pendingLiability: Scalars['Decimal']['output'];
  /** settled is the amount of the asset that is currently in a settled state. These are funds that have finished clearing and are no longer held up by any order. */
  settled: Scalars['Decimal']['output'];
  /** total is the total amount of the asset that is currently in the account or pending in some way. Equal to all basic values added together. */
  total: Scalars['Decimal']['output'];
  /** tradeLockup is the sum total of active trade lockups on this asset account. */
  tradeLockup: Scalars['Decimal']['output'];
  /** tradeable is the amount of the asset that is currently in the account and can be traded. This values excludes the amount that is held in lockups. */
  tradeable: Scalars['Decimal']['output'];
  /** unsettled is the amount of the asset that is currently in and unsettled state. These are funds that have been deposited in the account but have not finished clearing. */
  unsettled: Scalars['Decimal']['output'];
  /** withdrawLockup is the sum total of active withdraw lockups on this asset account. */
  withdrawLockup: Scalars['Decimal']['output'];
  /** withdrawable is the amount of the asset that is currently in the account and can be withdrawn. This values excludes the amount that is held in lockups. */
  withdrawable: Scalars['Decimal']['output'];
  /** withdrawable is the amount of the asset that is currently in the account and can be withdrawn. This values does not factor in any lockups. */
  withdrawableBase: Scalars['Decimal']['output'];
};

/**
 * UserAccountAssetBalanceWhereInput is used for filtering UserAccountAssetBalance objects.
 * Input was generated by ent.
 */
export type UserAccountAssetBalanceWhereInput = {
  /** active_balance field predicates */
  activeBalance?: InputMaybe<Scalars['Decimal']['input']>;
  activeBalanceGT?: InputMaybe<Scalars['Decimal']['input']>;
  activeBalanceGTE?: InputMaybe<Scalars['Decimal']['input']>;
  activeBalanceIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  activeBalanceLT?: InputMaybe<Scalars['Decimal']['input']>;
  activeBalanceLTE?: InputMaybe<Scalars['Decimal']['input']>;
  activeBalanceNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  activeBalanceNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  and?: InputMaybe<Array<UserAccountAssetBalanceWhereInput>>;
  /** encumbrance field predicates */
  encumbrance?: InputMaybe<Scalars['Decimal']['input']>;
  encumbranceGT?: InputMaybe<Scalars['Decimal']['input']>;
  encumbranceGTE?: InputMaybe<Scalars['Decimal']['input']>;
  encumbranceIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  encumbranceLT?: InputMaybe<Scalars['Decimal']['input']>;
  encumbranceLTE?: InputMaybe<Scalars['Decimal']['input']>;
  encumbranceNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  encumbranceNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** frozen field predicates */
  frozen?: InputMaybe<Scalars['Decimal']['input']>;
  frozenGT?: InputMaybe<Scalars['Decimal']['input']>;
  frozenGTE?: InputMaybe<Scalars['Decimal']['input']>;
  frozenIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  frozenLT?: InputMaybe<Scalars['Decimal']['input']>;
  frozenLTE?: InputMaybe<Scalars['Decimal']['input']>;
  frozenNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  frozenNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** account edge predicates */
  hasAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountWith?: InputMaybe<Array<UserAccountWhereInput>>;
  hasAssetWith?: InputMaybe<BaseAssetWhereInput>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** liability field predicates */
  liability?: InputMaybe<Scalars['Decimal']['input']>;
  liabilityGT?: InputMaybe<Scalars['Decimal']['input']>;
  liabilityGTE?: InputMaybe<Scalars['Decimal']['input']>;
  liabilityIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  liabilityLT?: InputMaybe<Scalars['Decimal']['input']>;
  liabilityLTE?: InputMaybe<Scalars['Decimal']['input']>;
  liabilityNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  liabilityNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  not?: InputMaybe<UserAccountAssetBalanceWhereInput>;
  or?: InputMaybe<Array<UserAccountAssetBalanceWhereInput>>;
  /** pending field predicates */
  pending?: InputMaybe<Scalars['Decimal']['input']>;
  pendingGT?: InputMaybe<Scalars['Decimal']['input']>;
  pendingGTE?: InputMaybe<Scalars['Decimal']['input']>;
  pendingIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  pendingLT?: InputMaybe<Scalars['Decimal']['input']>;
  pendingLTE?: InputMaybe<Scalars['Decimal']['input']>;
  /** pending_liability field predicates */
  pendingLiability?: InputMaybe<Scalars['Decimal']['input']>;
  pendingLiabilityGT?: InputMaybe<Scalars['Decimal']['input']>;
  pendingLiabilityGTE?: InputMaybe<Scalars['Decimal']['input']>;
  pendingLiabilityIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  pendingLiabilityLT?: InputMaybe<Scalars['Decimal']['input']>;
  pendingLiabilityLTE?: InputMaybe<Scalars['Decimal']['input']>;
  pendingLiabilityNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  pendingLiabilityNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  pendingNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  pendingNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** settled field predicates */
  settled?: InputMaybe<Scalars['Decimal']['input']>;
  settledGT?: InputMaybe<Scalars['Decimal']['input']>;
  settledGTE?: InputMaybe<Scalars['Decimal']['input']>;
  settledIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  settledLT?: InputMaybe<Scalars['Decimal']['input']>;
  settledLTE?: InputMaybe<Scalars['Decimal']['input']>;
  settledNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  settledNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** total field predicates */
  total?: InputMaybe<Scalars['Decimal']['input']>;
  totalGT?: InputMaybe<Scalars['Decimal']['input']>;
  totalGTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  totalLT?: InputMaybe<Scalars['Decimal']['input']>;
  totalLTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  totalNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** trade_lockup field predicates */
  tradeLockup?: InputMaybe<Scalars['Decimal']['input']>;
  tradeLockupGT?: InputMaybe<Scalars['Decimal']['input']>;
  tradeLockupGTE?: InputMaybe<Scalars['Decimal']['input']>;
  tradeLockupIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  tradeLockupLT?: InputMaybe<Scalars['Decimal']['input']>;
  tradeLockupLTE?: InputMaybe<Scalars['Decimal']['input']>;
  tradeLockupNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  tradeLockupNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** tradeable field predicates */
  tradeable?: InputMaybe<Scalars['Decimal']['input']>;
  tradeableGT?: InputMaybe<Scalars['Decimal']['input']>;
  tradeableGTE?: InputMaybe<Scalars['Decimal']['input']>;
  tradeableIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  tradeableLT?: InputMaybe<Scalars['Decimal']['input']>;
  tradeableLTE?: InputMaybe<Scalars['Decimal']['input']>;
  tradeableNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  tradeableNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** unsettled field predicates */
  unsettled?: InputMaybe<Scalars['Decimal']['input']>;
  unsettledGT?: InputMaybe<Scalars['Decimal']['input']>;
  unsettledGTE?: InputMaybe<Scalars['Decimal']['input']>;
  unsettledIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  unsettledLT?: InputMaybe<Scalars['Decimal']['input']>;
  unsettledLTE?: InputMaybe<Scalars['Decimal']['input']>;
  unsettledNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  unsettledNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** withdraw_lockup field predicates */
  withdrawLockup?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawLockupGT?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawLockupGTE?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawLockupIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  withdrawLockupLT?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawLockupLTE?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawLockupNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawLockupNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** withdrawable field predicates */
  withdrawable?: InputMaybe<Scalars['Decimal']['input']>;
  /** withdrawable_base field predicates */
  withdrawableBase?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawableBaseGT?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawableBaseGTE?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawableBaseIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  withdrawableBaseLT?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawableBaseLTE?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawableBaseNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawableBaseNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  withdrawableGT?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawableGTE?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawableIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  withdrawableLT?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawableLTE?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawableNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawableNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

/** A connection to a list of items. */
export type UserAccountConnection = {
  __typename?: 'UserAccountConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserAccountEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UserAccountEdge = {
  __typename?: 'UserAccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<UserAccount>;
};

/**
 * UserAccountMeta contains extra information about a user's account, likely related to the
 * institution where the account is held at.
 */
export type UserAccountMetadata = ApexAccountMeta;

/** Ordering options for UserAccount connections */
export type UserAccountOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order UserAccounts. */
  field: UserAccountOrderField;
};

/** Properties by which UserAccount connections can be ordered. */
export enum UserAccountOrderField {
  RequestTime = 'REQUEST_TIME',
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

export type UserAccountPortfolio = Node & {
  __typename?: 'UserAccountPortfolio';
  /** Account that this portfolio is contained in. */
  account: UserAccount;
  assetBalances: UserAccountPortfolioAssetConnection;
  /** returns remaining limit for how much the user can deposit. */
  availableDailyDeposit: Scalars['Decimal']['output'];
  /** returns remaining limit for how much the user can withdraw. */
  availableDailyWithdrawal: Scalars['Decimal']['output'];
  /** return how much value could be available instantly if the user made a deposit */
  availableInstantDeposit: Scalars['Decimal']['output'];
  balances?: Maybe<Array<UserAccountPortfolioAssetBalance>>;
  crypto?: Maybe<AssetTypeTotal>;
  /** returns remaining limit for how much the user can deposit. */
  dailyDepositLimit: Scalars['Decimal']['output'];
  /** returns remaining limit for how much the user can withdraw. */
  dailyWithdrawalLimit: Scalars['Decimal']['output'];
  fiat: AssetTypeTotal;
  id: Scalars['ID']['output'];
  /** True if this is the default account for a portfolio, nil otherwise to prevent the uniqueness check from triggering. */
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  /** Sum total value of all deposits minus withdrawals made to this portfolio. Value used for deposit  and withdrawals are the values at deposit time. */
  lifetimeNetDepositValue: Scalars['Decimal']['output'];
  /**
   * Entity with discretion over the account. This value is being deprecated in favor of the `purpose` field.
   * @deprecated Use `purpose` instead.
   */
  managementBy?: Maybe<UserAccountPortfolioAccountManager>;
  /** Name of the account. */
  name?: Maybe<Scalars['String']['output']>;
  portfolioLedgers?: Maybe<Array<PortfolioLedger>>;
  /** link to all the recon adjustments that have been applied to this portfolio. */
  reconAdjustments?: Maybe<Array<AccountReconAdjustment>>;
  security: AssetTypeTotal;
  /** value is the value in USD of the portfolio */
  totalValue: Scalars['Decimal']['output'];
  /**
   * returns the difference been the current totalValue and the net amount of deposited funds,
   * `lifetimeNetDepositValue`,  into the portfolio.
   */
  totalValueGainLoss: Scalars['Decimal']['output'];
  /** User that owns the portfolio */
  user: User;
  /** returns the historical sum total of the account value over time */
  valueHistory: UserAccountPortfolioValueHistory;
};

export type UserAccountPortfolioAssetBalancesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserAccountPortfolioAssetWhereInput>;
};

export type UserAccountPortfolioValueHistoryArgs = {
  range?: InputMaybe<TimeSeriesRange>;
};

/** UserAccountPortfolioAccountManager is enum for the field management_by */
export enum UserAccountPortfolioAccountManager {
  AccountManagerOwner = 'ACCOUNT_MANAGER_OWNER',
  AccountManagerRa = 'ACCOUNT_MANAGER_RA',
  AccountManagerRobo = 'ACCOUNT_MANAGER_ROBO',
}

export type UserAccountPortfolioAsset = Node & {
  __typename?: 'UserAccountPortfolioAsset';
  asset?: Maybe<BaseAsset>;
  balances?: Maybe<UserAccountPortfolioAssetBalance>;
  /** costBasis returns the average cost of acquiring the asset in this portfolio */
  costBasis: Scalars['Decimal']['output'];
  id: Scalars['ID']['output'];
  /** Asset lockups that are associated with this account. */
  lockups?: Maybe<Array<AssetLockup>>;
  portfolio: UserAccountPortfolio;
  valueHistory: AccountChangeHistory;
};

export type UserAccountPortfolioAssetLockupsArgs = {
  includeInactive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserAccountPortfolioAssetValueHistoryArgs = {
  range?: InputMaybe<TimeSeriesRange>;
};

export type UserAccountPortfolioAssetBalance = Node & {
  __typename?: 'UserAccountPortfolioAssetBalance';
  account: UserAccount;
  /** activeBalance is the amount of the asset that is currently in the account an usable in some way. All basic values added together except pending and pending liability */
  activeBalance: Scalars['Decimal']['output'];
  asset?: Maybe<BaseAsset>;
  /** @deprecated Use `activeBalance`. */
  balance: Scalars['Decimal']['output'];
  /**
   * costBasis returns the average cost of acquiring the asset in this portfolio
   * @deprecated Use `costBasis` on UserAcountPortfolioAsset.
   */
  costBasis: Scalars['Decimal']['output'];
  /** encumbrance is the amount of the asset that is currently in an encumbered state. These are funds that have already been used in some order but that order has yet to return. */
  encumbrance: Scalars['Decimal']['output'];
  /** @deprecated Use `encumbrance`. */
  encumbranceBalance: Scalars['Decimal']['output'];
  /** frozen is the amount of the asset that is currently in a frozen state. These are funds that are currently being reserved for use in an order that has yet to utilize the funds. */
  frozen: Scalars['Decimal']['output'];
  /** @deprecated Use `frozen`. */
  frozenBalance: Scalars['Decimal']['output'];
  id: Scalars['ID']['output'];
  /** liability is the amount of liability on the person's account. This liability represents the amount of funds that is owed by the user but has not yet been paid. */
  liability: Scalars['Decimal']['output'];
  /** @deprecated Use `liability`. */
  liabilityBalance: Scalars['Decimal']['output'];
  /** pending is the amount of the asset that is currently in a pending state. These are funds that the system is aware of but are not yet useable in any meaningful way. */
  pending: Scalars['Decimal']['output'];
  /** @deprecated Use `pending`. */
  pendingBalance: Scalars['Decimal']['output'];
  /** pendingLiability is the amount of pending liability on the person's account. This liability represents the amount of funds that have been lent to the account. */
  pendingLiability: Scalars['Decimal']['output'];
  /** @deprecated Use `pendingLiability`. */
  pendingLiabilityBalance: Scalars['Decimal']['output'];
  portfolio: UserAccountPortfolio;
  portfolioAssetAccount: UserAccountPortfolioAsset;
  /** settled is the amount of the asset that is currently in a settled state. These are funds that have finished clearing and are no longer held up by any order. */
  settled: Scalars['Decimal']['output'];
  /** @deprecated Use `settled`. */
  settledBalance: Scalars['Decimal']['output'];
  /** total is the total amount of the asset that is currently in the account or pending in some way. Equal to all basic values added together. */
  total: Scalars['Decimal']['output'];
  /** tradeLockup is the sum total of active trade lockups on this asset account. */
  tradeLockup: Scalars['Decimal']['output'];
  /** tradeable is the amount of the asset that is currently in the account and can be traded. This values excludes the amount that is held in lockups. */
  tradeable: Scalars['Decimal']['output'];
  /** unsettled is the amount of the asset that is currently in and unsettled state. These are funds that have been deposited in the account but have not finished clearing. */
  unsettled: Scalars['Decimal']['output'];
  /** @deprecated Use `unsettled`. */
  unsettledBalance: Scalars['Decimal']['output'];
  /** value is the value in USD of the portfolio */
  value: Scalars['Decimal']['output'];
  /** withdrawLockup is the sum total of active withdraw lockups on this asset account. */
  withdrawLockup: Scalars['Decimal']['output'];
  /** withdrawable is the amount of the asset that is currently in the account and can be withdrawn. This values excludes the amount that is held in lockups. */
  withdrawable: Scalars['Decimal']['output'];
  /** withdrawable is the amount of the asset that is currently in the account and can be withdrawn. This values does not factor in any lockups. */
  withdrawableBase: Scalars['Decimal']['output'];
};

/**
 * UserAccountPortfolioAssetBalanceWhereInput is used for filtering UserAccountPortfolioAssetBalance objects.
 * Input was generated by ent.
 */
export type UserAccountPortfolioAssetBalanceWhereInput = {
  /** active_balance field predicates */
  activeBalance?: InputMaybe<Scalars['Decimal']['input']>;
  activeBalanceGT?: InputMaybe<Scalars['Decimal']['input']>;
  activeBalanceGTE?: InputMaybe<Scalars['Decimal']['input']>;
  activeBalanceIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  activeBalanceLT?: InputMaybe<Scalars['Decimal']['input']>;
  activeBalanceLTE?: InputMaybe<Scalars['Decimal']['input']>;
  activeBalanceNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  activeBalanceNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  and?: InputMaybe<Array<UserAccountPortfolioAssetBalanceWhereInput>>;
  /** encumbrance field predicates */
  encumbrance?: InputMaybe<Scalars['Decimal']['input']>;
  encumbranceGT?: InputMaybe<Scalars['Decimal']['input']>;
  encumbranceGTE?: InputMaybe<Scalars['Decimal']['input']>;
  encumbranceIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  encumbranceLT?: InputMaybe<Scalars['Decimal']['input']>;
  encumbranceLTE?: InputMaybe<Scalars['Decimal']['input']>;
  encumbranceNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  encumbranceNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** frozen field predicates */
  frozen?: InputMaybe<Scalars['Decimal']['input']>;
  frozenGT?: InputMaybe<Scalars['Decimal']['input']>;
  frozenGTE?: InputMaybe<Scalars['Decimal']['input']>;
  frozenIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  frozenLT?: InputMaybe<Scalars['Decimal']['input']>;
  frozenLTE?: InputMaybe<Scalars['Decimal']['input']>;
  frozenNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  frozenNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** account edge predicates */
  hasAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountWith?: InputMaybe<Array<UserAccountWhereInput>>;
  hasAssetWith?: InputMaybe<BaseAssetWhereInput>;
  /** portfolio edge predicates */
  hasPortfolio?: InputMaybe<Scalars['Boolean']['input']>;
  /** portfolio_asset_account edge predicates */
  hasPortfolioAssetAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasPortfolioAssetAccountWith?: InputMaybe<Array<UserAccountPortfolioAssetWhereInput>>;
  hasPortfolioWith?: InputMaybe<Array<UserAccountPortfolioWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** liability field predicates */
  liability?: InputMaybe<Scalars['Decimal']['input']>;
  liabilityGT?: InputMaybe<Scalars['Decimal']['input']>;
  liabilityGTE?: InputMaybe<Scalars['Decimal']['input']>;
  liabilityIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  liabilityLT?: InputMaybe<Scalars['Decimal']['input']>;
  liabilityLTE?: InputMaybe<Scalars['Decimal']['input']>;
  liabilityNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  liabilityNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  not?: InputMaybe<UserAccountPortfolioAssetBalanceWhereInput>;
  or?: InputMaybe<Array<UserAccountPortfolioAssetBalanceWhereInput>>;
  /** pending field predicates */
  pending?: InputMaybe<Scalars['Decimal']['input']>;
  pendingGT?: InputMaybe<Scalars['Decimal']['input']>;
  pendingGTE?: InputMaybe<Scalars['Decimal']['input']>;
  pendingIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  pendingLT?: InputMaybe<Scalars['Decimal']['input']>;
  pendingLTE?: InputMaybe<Scalars['Decimal']['input']>;
  /** pending_liability field predicates */
  pendingLiability?: InputMaybe<Scalars['Decimal']['input']>;
  pendingLiabilityGT?: InputMaybe<Scalars['Decimal']['input']>;
  pendingLiabilityGTE?: InputMaybe<Scalars['Decimal']['input']>;
  pendingLiabilityIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  pendingLiabilityLT?: InputMaybe<Scalars['Decimal']['input']>;
  pendingLiabilityLTE?: InputMaybe<Scalars['Decimal']['input']>;
  pendingLiabilityNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  pendingLiabilityNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  pendingNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  pendingNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** settled field predicates */
  settled?: InputMaybe<Scalars['Decimal']['input']>;
  settledGT?: InputMaybe<Scalars['Decimal']['input']>;
  settledGTE?: InputMaybe<Scalars['Decimal']['input']>;
  settledIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  settledLT?: InputMaybe<Scalars['Decimal']['input']>;
  settledLTE?: InputMaybe<Scalars['Decimal']['input']>;
  settledNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  settledNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** total field predicates */
  total?: InputMaybe<Scalars['Decimal']['input']>;
  totalGT?: InputMaybe<Scalars['Decimal']['input']>;
  totalGTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  totalLT?: InputMaybe<Scalars['Decimal']['input']>;
  totalLTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  totalNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** trade_lockup field predicates */
  tradeLockup?: InputMaybe<Scalars['Decimal']['input']>;
  tradeLockupGT?: InputMaybe<Scalars['Decimal']['input']>;
  tradeLockupGTE?: InputMaybe<Scalars['Decimal']['input']>;
  tradeLockupIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  tradeLockupLT?: InputMaybe<Scalars['Decimal']['input']>;
  tradeLockupLTE?: InputMaybe<Scalars['Decimal']['input']>;
  tradeLockupNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  tradeLockupNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** tradeable field predicates */
  tradeable?: InputMaybe<Scalars['Decimal']['input']>;
  tradeableGT?: InputMaybe<Scalars['Decimal']['input']>;
  tradeableGTE?: InputMaybe<Scalars['Decimal']['input']>;
  tradeableIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  tradeableLT?: InputMaybe<Scalars['Decimal']['input']>;
  tradeableLTE?: InputMaybe<Scalars['Decimal']['input']>;
  tradeableNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  tradeableNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** unsettled field predicates */
  unsettled?: InputMaybe<Scalars['Decimal']['input']>;
  unsettledGT?: InputMaybe<Scalars['Decimal']['input']>;
  unsettledGTE?: InputMaybe<Scalars['Decimal']['input']>;
  unsettledIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  unsettledLT?: InputMaybe<Scalars['Decimal']['input']>;
  unsettledLTE?: InputMaybe<Scalars['Decimal']['input']>;
  unsettledNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  unsettledNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** withdraw_lockup field predicates */
  withdrawLockup?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawLockupGT?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawLockupGTE?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawLockupIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  withdrawLockupLT?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawLockupLTE?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawLockupNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawLockupNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** withdrawable field predicates */
  withdrawable?: InputMaybe<Scalars['Decimal']['input']>;
  /** withdrawable_base field predicates */
  withdrawableBase?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawableBaseGT?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawableBaseGTE?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawableBaseIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  withdrawableBaseLT?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawableBaseLTE?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawableBaseNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawableBaseNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  withdrawableGT?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawableGTE?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawableIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  withdrawableLT?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawableLTE?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawableNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawableNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

/** A connection to a list of items. */
export type UserAccountPortfolioAssetConnection = {
  __typename?: 'UserAccountPortfolioAssetConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserAccountPortfolioAssetEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type UserAccountPortfolioAssetDailyBalance = Node & {
  __typename?: 'UserAccountPortfolioAssetDailyBalance';
  /** activeBalance is the amount of the asset that was in the account an usable in some way on the event date. All basic values added together except pending and pending liability */
  activeBalance: Scalars['Decimal']['output'];
  /** encumbrance is the amount of the asset that was in an encumbered state on the event date. These are funds that have already been used in some order but that order has yet to return. */
  encumbrance: Scalars['Decimal']['output'];
  eventDate: Scalars['Date']['output'];
  /** frozen is the amount of the asset that was in a frozen state on the event date. These are funds that are currently being reserved for use in an order that has yet to utilize the funds. */
  frozen: Scalars['Decimal']['output'];
  id: Scalars['ID']['output'];
  /** liability is the amount of liability on the person's account on the event date. This liability represents the amount of funds that is owed by the user but has not yet been paid. */
  liability: Scalars['Decimal']['output'];
  /** pending is the amount of the asset that was in a pending state on the event date. These are funds that the system is aware of but are not yet useable in any meaningful way. */
  pending: Scalars['Decimal']['output'];
  /** pendingLiability is the amount of pending liability on the person's account on the event date. This liability represents the amount of funds that have been lent to the account. */
  pendingLiability: Scalars['Decimal']['output'];
  portfolioAssetAccount: UserAccountPortfolioAsset;
  /** settled is the amount of the asset that was in a settled state on the eventdate. These are funds that have finished clearing and are no longer held up by any order. */
  settled: Scalars['Decimal']['output'];
  /** total is the total amount of the asset that was in the account or pending in some way on the event date. Equal to all basic values added together. */
  total: Scalars['Decimal']['output'];
  /** unsettled is the amount of the asset that was in an unsettled state on the event date. These are funds that have been deposited in the account but have not finished clearing. */
  unsettled: Scalars['Decimal']['output'];
};

/** An edge in a connection. */
export type UserAccountPortfolioAssetEdge = {
  __typename?: 'UserAccountPortfolioAssetEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<UserAccountPortfolioAsset>;
};

/**
 * UserAccountPortfolioAssetWhereInput is used for filtering UserAccountPortfolioAsset objects.
 * Input was generated by ent.
 */
export type UserAccountPortfolioAssetWhereInput = {
  and?: InputMaybe<Array<UserAccountPortfolioAssetWhereInput>>;
  hasAssetWith?: InputMaybe<BaseAssetWhereInput>;
  /** balances edge predicates */
  hasBalances?: InputMaybe<Scalars['Boolean']['input']>;
  hasBalancesWith?: InputMaybe<Array<UserAccountPortfolioAssetBalanceWhereInput>>;
  /** portfolio edge predicates */
  hasPortfolio?: InputMaybe<Scalars['Boolean']['input']>;
  hasPortfolioWith?: InputMaybe<Array<UserAccountPortfolioWhereInput>>;
  /**
   * hideZeroBalance will exclude accounts that have been created but currently have zero balance.
   * If not set, the default is true.
   */
  hideZeroBalance?: InputMaybe<Scalars['Boolean']['input']>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<UserAccountPortfolioAssetWhereInput>;
  or?: InputMaybe<Array<UserAccountPortfolioAssetWhereInput>>;
};

/** A connection to a list of items. */
export type UserAccountPortfolioConnection = {
  __typename?: 'UserAccountPortfolioConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserAccountPortfolioEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UserAccountPortfolioEdge = {
  __typename?: 'UserAccountPortfolioEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<UserAccountPortfolio>;
};

/** UserAccountPortfolioValueHistory is a list of data points with a time associated with each point */
export type UserAccountPortfolioValueHistory = {
  __typename?: 'UserAccountPortfolioValueHistory';
  /** end is the start datetime of the series */
  end: Scalars['Time']['output'];
  /** total value gained or lossed over the requested period */
  gainLosses: Array<Scalars['Decimal']['output']>;
  /** limit is the maximum number of datapoint used when creating the series */
  limit: Scalars['Int']['output'];
  /** period is the desired amount of time between data points */
  period: TimePeriod;
  /** time weighted rate of return over the requested period */
  returnRates: Array<Scalars['Decimal']['output']>;
  /** start is the start datetime of the series */
  start: Scalars['Time']['output'];
  /** The time of each data point in the series */
  timestamps: Array<Scalars['Time']['output']>;
  /** total historical value of the portfolio */
  values: Array<Scalars['Decimal']['output']>;
};

/**
 * UserAccountPortfolioWhereInput is used for filtering UserAccountPortfolio objects.
 * Input was generated by ent.
 */
export type UserAccountPortfolioWhereInput = {
  and?: InputMaybe<Array<UserAccountPortfolioWhereInput>>;
  /** account edge predicates */
  hasAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountWith?: InputMaybe<Array<UserAccountWhereInput>>;
  /** asset_balances edge predicates */
  hasAssetBalances?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssetBalancesWith?: InputMaybe<Array<UserAccountPortfolioAssetWhereInput>>;
  /** balances edge predicates */
  hasBalances?: InputMaybe<Scalars['Boolean']['input']>;
  hasBalancesWith?: InputMaybe<Array<UserAccountPortfolioAssetBalanceWhereInput>>;
  /** portfolio_ledgers edge predicates */
  hasPortfolioLedgers?: InputMaybe<Scalars['Boolean']['input']>;
  hasPortfolioLedgersWith?: InputMaybe<Array<PortfolioLedgerWhereInput>>;
  /** recon_adjustments edge predicates */
  hasReconAdjustments?: InputMaybe<Scalars['Boolean']['input']>;
  hasReconAdjustmentsWith?: InputMaybe<Array<AccountReconAdjustmentWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_default field predicates */
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  isDefaultIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  isDefaultNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  isDefaultNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<UserAccountPortfolioWhereInput>;
  or?: InputMaybe<Array<UserAccountPortfolioWhereInput>>;
};

export type UserAccountRestriction = Node & {
  __typename?: 'UserAccountRestriction';
  createTime: Scalars['Time']['output'];
  endOfRestrictionDate?: Maybe<Scalars['Time']['output']>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  /** The time at which the action was requested. */
  requestTime: Scalars['Time']['output'];
  requestUUID?: Maybe<Scalars['UUID']['output']>;
  requestor: Requestor;
  restrictionCode: UserAccountRestrictionRestrictionsCode;
  updateTime: Scalars['Time']['output'];
  userAccount: UserAccount;
  userAccountID: Scalars['ID']['output'];
};

/** A connection to a list of items. */
export type UserAccountRestrictionConnection = {
  __typename?: 'UserAccountRestrictionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserAccountRestrictionEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UserAccountRestrictionEdge = {
  __typename?: 'UserAccountRestrictionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<UserAccountRestriction>;
};

/** Ordering options for UserAccountRestriction connections */
export type UserAccountRestrictionOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order UserAccountRestrictions. */
  field: UserAccountRestrictionOrderField;
};

/** Properties by which UserAccountRestriction connections can be ordered. */
export enum UserAccountRestrictionOrderField {
  RequestTime = 'REQUEST_TIME',
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/** UserAccountRestrictionRestrictionsCode is enum for the field restriction_code */
export enum UserAccountRestrictionRestrictionsCode {
  RestrictionsCodeAcat = 'RESTRICTIONS_CODE_ACAT',
  RestrictionsCodeAcatFullOutbound = 'RESTRICTIONS_CODE_ACAT_FULL_OUTBOUND',
  RestrictionsCodeAcatPartialOutbound = 'RESTRICTIONS_CODE_ACAT_PARTIAL_OUTBOUND',
  RestrictionsCodeAcatReclaimInitiated = 'RESTRICTIONS_CODE_ACAT_RECLAIM_INITIATED',
  RestrictionsCodeAcatResidualPending = 'RESTRICTIONS_CODE_ACAT_RESIDUAL_PENDING',
  RestrictionsCodeAcctExpiredNegativelyConsentedRegistration = 'RESTRICTIONS_CODE_ACCT_EXPIRED_NEGATIVELY_CONSENTED_REGISTRATION',
  RestrictionsCodeAcctMaintClosure = 'RESTRICTIONS_CODE_ACCT_MAINT_CLOSURE',
  RestrictionsCodeAcctMaintClosurePrepByCorrespondent = 'RESTRICTIONS_CODE_ACCT_MAINT_CLOSURE_PREP_BY_CORRESPONDENT',
  RestrictionsCodeAcctMaintClosurePrepByCustodian = 'RESTRICTIONS_CODE_ACCT_MAINT_CLOSURE_PREP_BY_CUSTODIAN',
  RestrictionsCodeAcctMaintSuspendedByCorrespondent = 'RESTRICTIONS_CODE_ACCT_MAINT_SUSPENDED_BY_CORRESPONDENT',
  RestrictionsCodeAcctOwnerIndicatedAsDeceased = 'RESTRICTIONS_CODE_ACCT_OWNER_INDICATED_AS_DECEASED',
  RestrictionsCodeAmlPartiesFailingKyc = 'RESTRICTIONS_CODE_AML_PARTIES_FAILING_KYC',
  RestrictionsCodeAmlPartiesPendingInitialKyc = 'RESTRICTIONS_CODE_AML_PARTIES_PENDING_INITIAL_KYC',
  RestrictionsCodeAmlRestrictionNoMoneyOut = 'RESTRICTIONS_CODE_AML_RESTRICTION_NO_MONEY_OUT',
  RestrictionsCodeClosedByFirm = 'RESTRICTIONS_CODE_CLOSED_BY_FIRM',
  RestrictionsCodeClosedForInternalConversion = 'RESTRICTIONS_CODE_CLOSED_FOR_INTERNAL_CONVERSION',
  RestrictionsCodeComplianceEscheatmentRequired = 'RESTRICTIONS_CODE_COMPLIANCE_ESCHEATMENT_REQUIRED',
  RestrictionsCodeComplianceSuspendedByCourtViaCorrespondent = 'RESTRICTIONS_CODE_COMPLIANCE_SUSPENDED_BY_COURT_VIA_CORRESPONDENT',
  RestrictionsCodeComplianceSuspendedByCourtViaCustodian = 'RESTRICTIONS_CODE_COMPLIANCE_SUSPENDED_BY_COURT_VIA_CUSTODIAN',
  RestrictionsCodeConversionInProgress = 'RESTRICTIONS_CODE_CONVERSION_IN_PROGRESS',
  RestrictionsCodeDaytradingViolators = 'RESTRICTIONS_CODE_DAYTRADING_VIOLATORS',
  RestrictionsCodeDeconversionInProgress = 'RESTRICTIONS_CODE_DECONVERSION_IN_PROGRESS',
  RestrictionsCodeDepositAcctNoFundsReleasedWoExecutiveApproval = 'RESTRICTIONS_CODE_DEPOSIT_ACCT_NO_FUNDS_RELEASED_WO_EXECUTIVE_APPROVAL',
  RestrictionsCodeDoNotDeconvertSeeConversions = 'RESTRICTIONS_CODE_DO_NOT_DECONVERT_SEE_CONVERSIONS',
  RestrictionsCodeEscheatmentInProgress = 'RESTRICTIONS_CODE_ESCHEATMENT_IN_PROGRESS',
  RestrictionsCodeExcessiveReturnDeposits = 'RESTRICTIONS_CODE_EXCESSIVE_RETURN_DEPOSITS',
  RestrictionsCodeExemptCustodyFeeClosed = 'RESTRICTIONS_CODE_EXEMPT_CUSTODY_FEE_CLOSED',
  RestrictionsCodeFraudSuspendedByCorrespondent = 'RESTRICTIONS_CODE_FRAUD_SUSPENDED_BY_CORRESPONDENT',
  RestrictionsCodeFraudSuspendedByCustodian = 'RESTRICTIONS_CODE_FRAUD_SUSPENDED_BY_CUSTODIAN',
  RestrictionsCodeFrozenFor_90Days = 'RESTRICTIONS_CODE_FROZEN_FOR_90_DAYS',
  RestrictionsCodeInvalidAccountName = 'RESTRICTIONS_CODE_INVALID_ACCOUNT_NAME',
  RestrictionsCodeLegalRestriction = 'RESTRICTIONS_CODE_LEGAL_RESTRICTION',
  RestrictionsCodeLiquidatingOrdersOnly = 'RESTRICTIONS_CODE_LIQUIDATING_ORDERS_ONLY',
  RestrictionsCodeMarginCallViolationFreeriding = 'RESTRICTIONS_CODE_MARGIN_CALL_VIOLATION_FREERIDING',
  RestrictionsCodeMarginDayTradingViolation = 'RESTRICTIONS_CODE_MARGIN_DAY_TRADING_VIOLATION',
  RestrictionsCodeMarginRegulatoryViolation = 'RESTRICTIONS_CODE_MARGIN_REGULATORY_VIOLATION',
  RestrictionsCodeMarginTradethroughViolation = 'RESTRICTIONS_CODE_MARGIN_TRADETHROUGH_VIOLATION',
  RestrictionsCodeNoAssetsWithoutExecutiveApproval = 'RESTRICTIONS_CODE_NO_ASSETS_WITHOUT_EXECUTIVE_APPROVAL',
  RestrictionsCodeNoPaperWorkOnFile = 'RESTRICTIONS_CODE_NO_PAPER_WORK_ON_FILE',
  RestrictionsCodeNoPaperWorkOnFileHardRestriction = 'RESTRICTIONS_CODE_NO_PAPER_WORK_ON_FILE_HARD_RESTRICTION',
  RestrictionsCodeOnboardingDeconversionInitiated = 'RESTRICTIONS_CODE_ONBOARDING_DECONVERSION_INITIATED',
  RestrictionsCodeRestrictedDueToBothRegTAndDaytradingCallViolations = 'RESTRICTIONS_CODE_RESTRICTED_DUE_TO_BOTH_REG_T_AND_DAYTRADING_CALL_VIOLATIONS',
  RestrictionsCodeRestrictedMarginCall = 'RESTRICTIONS_CODE_RESTRICTED_MARGIN_CALL',
  RestrictionsCodeReturnedMail = 'RESTRICTIONS_CODE_RETURNED_MAIL',
  RestrictionsCodeRiskAchReversal = 'RESTRICTIONS_CODE_RISK_ACH_REVERSAL',
  RestrictionsCodeRiskFraudReportedByCustodian = 'RESTRICTIONS_CODE_RISK_FRAUD_REPORTED_BY_CUSTODIAN',
  RestrictionsCodeRiskMispricedSecurityHeld = 'RESTRICTIONS_CODE_RISK_MISPRICED_SECURITY_HELD',
  RestrictionsCodeRiskSuspendedByRegulatorFull = 'RESTRICTIONS_CODE_RISK_SUSPENDED_BY_REGULATOR_FULL',
  RestrictionsCodeRiskSuspendedByRegulatorFundsMvmt = 'RESTRICTIONS_CODE_RISK_SUSPENDED_BY_REGULATOR_FUNDS_MVMT',
  RestrictionsCodeSocialSecurityMismatch = 'RESTRICTIONS_CODE_SOCIAL_SECURITY_MISMATCH',
  RestrictionsCodeSupportBdwClosureByCustodian = 'RESTRICTIONS_CODE_SUPPORT_BDW_CLOSURE_BY_CUSTODIAN',
  RestrictionsCodeSupportTaxCertificationExpired = 'RESTRICTIONS_CODE_SUPPORT_TAX_CERTIFICATION_EXPIRED',
  RestrictionsCodeTradingriskTierViolationsingleOrder = 'RESTRICTIONS_CODE_TRADINGRISK_TIER_VIOLATIONSINGLE_ORDER',
  RestrictionsCodeTradingriskTierViolationtotalExposure = 'RESTRICTIONS_CODE_TRADINGRISK_TIER_VIOLATIONTOTAL_EXPOSURE',
  RestrictionsCodeTradingLiquidationOnlyByCorrespondent = 'RESTRICTIONS_CODE_TRADING_LIQUIDATION_ONLY_BY_CORRESPONDENT',
  RestrictionsCodeTradingLiquidationOnlyByCustodian = 'RESTRICTIONS_CODE_TRADING_LIQUIDATION_ONLY_BY_CUSTODIAN',
  RestrictionsCodeTradingLiquidationOnlyByRegulator = 'RESTRICTIONS_CODE_TRADING_LIQUIDATION_ONLY_BY_REGULATOR',
}

/**
 * UserAccountRestrictionWhereInput is used for filtering UserAccountRestriction objects.
 * Input was generated by ent.
 */
export type UserAccountRestrictionWhereInput = {
  and?: InputMaybe<Array<UserAccountRestrictionWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** end_of_restriction_date field predicates */
  endOfRestrictionDate?: InputMaybe<Scalars['Time']['input']>;
  endOfRestrictionDateGT?: InputMaybe<Scalars['Time']['input']>;
  endOfRestrictionDateGTE?: InputMaybe<Scalars['Time']['input']>;
  endOfRestrictionDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  endOfRestrictionDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  endOfRestrictionDateLT?: InputMaybe<Scalars['Time']['input']>;
  endOfRestrictionDateLTE?: InputMaybe<Scalars['Time']['input']>;
  endOfRestrictionDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  endOfRestrictionDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  endOfRestrictionDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** user_account edge predicates */
  hasUserAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserAccountWith?: InputMaybe<Array<UserAccountWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_active field predicates */
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isActiveNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<UserAccountRestrictionWhereInput>;
  or?: InputMaybe<Array<UserAccountRestrictionWhereInput>>;
  /** request_time field predicates */
  requestTime?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  requestTimeLT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** request_uuid field predicates */
  requestUUID?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  requestUUIDLT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDLTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNEQ?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNotIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** requestor field predicates */
  requestor?: InputMaybe<Requestor>;
  requestorIn?: InputMaybe<Array<Requestor>>;
  requestorNEQ?: InputMaybe<Requestor>;
  requestorNotIn?: InputMaybe<Array<Requestor>>;
  /** restriction_code field predicates */
  restrictionCode?: InputMaybe<UserAccountRestrictionRestrictionsCode>;
  restrictionCodeIn?: InputMaybe<Array<UserAccountRestrictionRestrictionsCode>>;
  restrictionCodeNEQ?: InputMaybe<UserAccountRestrictionRestrictionsCode>;
  restrictionCodeNotIn?: InputMaybe<Array<UserAccountRestrictionRestrictionsCode>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** user_account_id field predicates */
  userAccountID?: InputMaybe<Scalars['ID']['input']>;
  userAccountIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  userAccountIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  userAccountIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * UserAccountWhereInput is used for filtering UserAccount objects.
 * Input was generated by ent.
 */
export type UserAccountWhereInput = {
  /** admin_on_file_id field predicates */
  adminOnFileID?: InputMaybe<Scalars['ID']['input']>;
  adminOnFileIDGT?: InputMaybe<Scalars['ID']['input']>;
  adminOnFileIDGTE?: InputMaybe<Scalars['ID']['input']>;
  adminOnFileIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  adminOnFileIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  adminOnFileIDLT?: InputMaybe<Scalars['ID']['input']>;
  adminOnFileIDLTE?: InputMaybe<Scalars['ID']['input']>;
  adminOnFileIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  adminOnFileIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  adminOnFileIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<UserAccountWhereInput>>;
  /** beneficiary_set_contingent field predicates */
  beneficiarySetContingent?: InputMaybe<Scalars['ID']['input']>;
  beneficiarySetContingentIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  beneficiarySetContingentIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  beneficiarySetContingentNEQ?: InputMaybe<Scalars['ID']['input']>;
  beneficiarySetContingentNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  beneficiarySetContingentNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** beneficiary_set_primary field predicates */
  beneficiarySetPrimary?: InputMaybe<Scalars['ID']['input']>;
  beneficiarySetPrimaryIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  beneficiarySetPrimaryIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  beneficiarySetPrimaryNEQ?: InputMaybe<Scalars['ID']['input']>;
  beneficiarySetPrimaryNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  beneficiarySetPrimaryNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** cash_sweep_enrolled field predicates */
  cashSweepEnrolled?: InputMaybe<Scalars['Boolean']['input']>;
  cashSweepEnrolledNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** external_id field predicates */
  externalID?: InputMaybe<Scalars['String']['input']>;
  externalIDContains?: InputMaybe<Scalars['String']['input']>;
  externalIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  externalIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  externalIDGT?: InputMaybe<Scalars['String']['input']>;
  externalIDGTE?: InputMaybe<Scalars['String']['input']>;
  externalIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  externalIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  externalIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  externalIDLT?: InputMaybe<Scalars['String']['input']>;
  externalIDLTE?: InputMaybe<Scalars['String']['input']>;
  externalIDNEQ?: InputMaybe<Scalars['String']['input']>;
  externalIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** fpsl_enrolled field predicates */
  fpslEnrolled?: InputMaybe<Scalars['Boolean']['input']>;
  fpslEnrolledNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** admin_actions edge predicates */
  hasAdminActions?: InputMaybe<Scalars['Boolean']['input']>;
  hasAdminActionsWith?: InputMaybe<Array<AdminActionWhereInput>>;
  /** apex_account_meta edge predicates */
  hasApexAccountMeta?: InputMaybe<Scalars['Boolean']['input']>;
  hasApexAccountMetaWith?: InputMaybe<Array<ApexAccountMetaWhereInput>>;
  /** apex_account_requests edge predicates */
  hasApexAccountRequests?: InputMaybe<Scalars['Boolean']['input']>;
  hasApexAccountRequestsWith?: InputMaybe<Array<ApexAccountRequestWhereInput>>;
  /** apex_update_requests edge predicates */
  hasApexUpdateRequests?: InputMaybe<Scalars['Boolean']['input']>;
  hasApexUpdateRequestsWith?: InputMaybe<Array<ApexUpdateRequestWhereInput>>;
  /** ascend_meta edge predicates */
  hasAscendMeta?: InputMaybe<Scalars['Boolean']['input']>;
  hasAscendMetaWith?: InputMaybe<Array<AscendMetaWhereInput>>;
  /** beneficiaries_contingent edge predicates */
  hasBeneficiariesContingent?: InputMaybe<Scalars['Boolean']['input']>;
  hasBeneficiariesContingentWith?: InputMaybe<Array<BeneficiarySetWhereInput>>;
  /** beneficiaries_primary edge predicates */
  hasBeneficiariesPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  hasBeneficiariesPrimaryWith?: InputMaybe<Array<BeneficiarySetWhereInput>>;
  /** beneficiary_set edge predicates */
  hasBeneficiarySet?: InputMaybe<Scalars['Boolean']['input']>;
  hasBeneficiarySetWith?: InputMaybe<Array<BeneficiarySetWhereInput>>;
  /** discretionary_account_kyc edge predicates */
  hasDiscretionaryAccountKyc?: InputMaybe<Scalars['Boolean']['input']>;
  hasDiscretionaryAccountKycWith?: InputMaybe<Array<DiscretionaryAccountKycWhereInput>>;
  /** non_discretionary_account_kyc edge predicates */
  hasNonDiscretionaryAccountKyc?: InputMaybe<Scalars['Boolean']['input']>;
  hasNonDiscretionaryAccountKycWith?: InputMaybe<Array<NonDiscretionaryAccountKycWhereInput>>;
  /** portfolios edge predicates */
  hasPortfolios?: InputMaybe<Scalars['Boolean']['input']>;
  hasPortfoliosWith?: InputMaybe<Array<UserAccountPortfolioWhereInput>>;
  /** program_enrollment_history edge predicates */
  hasProgramEnrollmentHistory?: InputMaybe<Scalars['Boolean']['input']>;
  hasProgramEnrollmentHistoryWith?: InputMaybe<Array<ProgramEnrollmentHistoryWhereInput>>;
  /** restrictions edge predicates */
  hasRestrictions?: InputMaybe<Scalars['Boolean']['input']>;
  hasRestrictionsWith?: InputMaybe<Array<UserAccountRestrictionWhereInput>>;
  /** sod_discrepancy_track edge predicates */
  hasSodDiscrepancyTrack?: InputMaybe<Scalars['Boolean']['input']>;
  hasSodDiscrepancyTrackWith?: InputMaybe<Array<SodDiscrepancyTrackWhereInput>>;
  /** trusted_contacts edge predicates */
  hasTrustedContacts?: InputMaybe<Scalars['Boolean']['input']>;
  hasTrustedContactsWith?: InputMaybe<Array<TrustedContactWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** holder_type field predicates */
  holderType?: InputMaybe<UserAccountAccountHolderType>;
  holderTypeIn?: InputMaybe<Array<UserAccountAccountHolderType>>;
  holderTypeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  holderTypeNEQ?: InputMaybe<UserAccountAccountHolderType>;
  holderTypeNotIn?: InputMaybe<Array<UserAccountAccountHolderType>>;
  holderTypeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_esigned field predicates */
  isEsigned?: InputMaybe<Scalars['Boolean']['input']>;
  isEsignedNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<UserAccountWhereInput>;
  or?: InputMaybe<Array<UserAccountWhereInput>>;
  /** permissions field predicates */
  permissions?: InputMaybe<Scalars['Int']['input']>;
  permissionsGT?: InputMaybe<Scalars['Int']['input']>;
  permissionsGTE?: InputMaybe<Scalars['Int']['input']>;
  permissionsIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  permissionsLT?: InputMaybe<Scalars['Int']['input']>;
  permissionsLTE?: InputMaybe<Scalars['Int']['input']>;
  permissionsNEQ?: InputMaybe<Scalars['Int']['input']>;
  permissionsNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** request_time field predicates */
  requestTime?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  requestTimeLT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** request_uuid field predicates */
  requestUUID?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  requestUUIDLT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDLTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNEQ?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNotIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** requestor field predicates */
  requestor?: InputMaybe<Requestor>;
  requestorIn?: InputMaybe<Array<Requestor>>;
  requestorNEQ?: InputMaybe<Requestor>;
  requestorNotIn?: InputMaybe<Array<Requestor>>;
  /** status field predicates */
  status?: InputMaybe<UserAccountAccountStatus>;
  statusIn?: InputMaybe<Array<UserAccountAccountStatus>>;
  statusNEQ?: InputMaybe<UserAccountAccountStatus>;
  statusNotIn?: InputMaybe<Array<UserAccountAccountStatus>>;
  /** type field predicates */
  type?: InputMaybe<UserAccountAccountType>;
  typeIn?: InputMaybe<Array<UserAccountAccountType>>;
  typeNEQ?: InputMaybe<UserAccountAccountType>;
  typeNotIn?: InputMaybe<Array<UserAccountAccountType>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** A connection to a list of items. */
export type UserConnection = {
  __typename?: 'UserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type UserDocument = Node & {
  __typename?: 'UserDocument';
  apexInvestigationFiles?: Maybe<Array<ApexInvestigationFile>>;
  createTime: Scalars['Time']['output'];
  /** Type of document */
  documentType: UserDocumentUserDocumentType;
  id: Scalars['ID']['output'];
  /** Mime type of the file */
  mimeType: UserDocumentMimeType;
  name?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  /** The time at which the action was requested. */
  requestTime: Scalars['Time']['output'];
  requestUUID?: Maybe<Scalars['UUID']['output']>;
  requestor: Requestor;
  /** Status of the file */
  status: UserDocumentDocumentStatus;
  updateTime: Scalars['Time']['output'];
  /** Returns the signed url of the file */
  url: Scalars['String']['output'];
  user: User;
};

/** A connection to a list of items. */
export type UserDocumentConnection = {
  __typename?: 'UserDocumentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserDocumentEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** UserDocumentDocumentStatus is enum for the field status */
export enum UserDocumentDocumentStatus {
  DocumentStatusComplete = 'DOCUMENT_STATUS_COMPLETE',
  DocumentStatusFailed = 'DOCUMENT_STATUS_FAILED',
  DocumentStatusPending = 'DOCUMENT_STATUS_PENDING',
}

/** An edge in a connection. */
export type UserDocumentEdge = {
  __typename?: 'UserDocumentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<UserDocument>;
};

/** UserDocumentMimeType is enum for the field mime_type */
export enum UserDocumentMimeType {
  MimeTypeApplicationJson = 'MIME_TYPE_APPLICATION_JSON',
  MimeTypeApplicationPdf = 'MIME_TYPE_APPLICATION_PDF',
  MimeTypeImageJpeg = 'MIME_TYPE_IMAGE_JPEG',
  MimeTypeImagePng = 'MIME_TYPE_IMAGE_PNG',
  MimeTypeTextCsv = 'MIME_TYPE_TEXT_CSV',
  MimeTypeTextPlain = 'MIME_TYPE_TEXT_PLAIN',
}

/** Ordering options for UserDocument connections */
export type UserDocumentOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order UserDocuments. */
  field: UserDocumentOrderField;
};

/** Properties by which UserDocument connections can be ordered. */
export enum UserDocumentOrderField {
  RequestTime = 'REQUEST_TIME',
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

export type UserDocumentSet = Node & {
  __typename?: 'UserDocumentSet';
  apexAccountFiles?: Maybe<Array<ApexAccountFile>>;
  apexInvestigationFiles?: Maybe<Array<ApexInvestigationFile>>;
  createTime: Scalars['Time']['output'];
  document: Array<UserDocument>;
  /** Type of document */
  documentType: UserDocumentSetUserDocumentType;
  id: Scalars['ID']['output'];
  isactive: Scalars['Boolean']['output'];
  /** holds the payload of the document  for example: metadata, or the sides of the documents */
  payload: Scalars['String']['output'];
  /** The time at which the action was requested. */
  requestTime: Scalars['Time']['output'];
  requestUUID?: Maybe<Scalars['UUID']['output']>;
  requestor: Requestor;
  updateTime: Scalars['Time']['output'];
  user: User;
};

/** A connection to a list of items. */
export type UserDocumentSetConnection = {
  __typename?: 'UserDocumentSetConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserDocumentSetEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UserDocumentSetEdge = {
  __typename?: 'UserDocumentSetEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<UserDocumentSet>;
};

/** Ordering options for UserDocumentSet connections */
export type UserDocumentSetOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order UserDocumentSets. */
  field: UserDocumentSetOrderField;
};

/** Properties by which UserDocumentSet connections can be ordered. */
export enum UserDocumentSetOrderField {
  RequestTime = 'REQUEST_TIME',
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/** UserDocumentSetUserDocumentType is enum for the field document_type */
export enum UserDocumentSetUserDocumentType {
  UserDocumentTypeAccountTransferForm = 'USER_DOCUMENT_TYPE_ACCOUNT_TRANSFER_FORM',
  UserDocumentTypeAffiliatedApproval = 'USER_DOCUMENT_TYPE_AFFILIATED_APPROVAL',
  UserDocumentTypeAllPassingCipResults = 'USER_DOCUMENT_TYPE_ALL_PASSING_CIP_RESULTS',
  UserDocumentTypeCddDocument = 'USER_DOCUMENT_TYPE_CDD_DOCUMENT',
  UserDocumentTypeDepositExplanation = 'USER_DOCUMENT_TYPE_DEPOSIT_EXPLANATION',
  UserDocumentTypeDriversLicense = 'USER_DOCUMENT_TYPE_DRIVERS_LICENSE',
  UserDocumentTypeIdentificationCard = 'USER_DOCUMENT_TYPE_IDENTIFICATION_CARD',
  UserDocumentTypeIdDocument = 'USER_DOCUMENT_TYPE_ID_DOCUMENT',
  UserDocumentTypeIraDepositSlip = 'USER_DOCUMENT_TYPE_IRA_DEPOSIT_SLIP',
  UserDocumentTypeIrsItinLetter = 'USER_DOCUMENT_TYPE_IRS_ITIN_LETTER',
  UserDocumentTypeLimitedMarginAgreement = 'USER_DOCUMENT_TYPE_LIMITED_MARGIN_AGREEMENT',
  UserDocumentTypeMilitaryId = 'USER_DOCUMENT_TYPE_MILITARY_ID',
  UserDocumentTypeNationalIdCard = 'USER_DOCUMENT_TYPE_NATIONAL_ID_CARD',
  UserDocumentTypeOther = 'USER_DOCUMENT_TYPE_OTHER',
  UserDocumentTypeOtherGovernmentId = 'USER_DOCUMENT_TYPE_OTHER_GOVERNMENT_ID',
  UserDocumentTypePassport = 'USER_DOCUMENT_TYPE_PASSPORT',
  UserDocumentTypeSignatureImage = 'USER_DOCUMENT_TYPE_SIGNATURE_IMAGE',
  UserDocumentTypeSsaLetter = 'USER_DOCUMENT_TYPE_SSA_LETTER',
  UserDocumentTypeSsnCard = 'USER_DOCUMENT_TYPE_SSN_CARD',
  UserDocumentTypeStateIdCard = 'USER_DOCUMENT_TYPE_STATE_ID_CARD',
  UserDocumentTypeTransferOnDeathAgreement = 'USER_DOCUMENT_TYPE_TRANSFER_ON_DEATH_AGREEMENT',
}

/**
 * UserDocumentSetWhereInput is used for filtering UserDocumentSet objects.
 * Input was generated by ent.
 */
export type UserDocumentSetWhereInput = {
  and?: InputMaybe<Array<UserDocumentSetWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** document_type field predicates */
  documentType?: InputMaybe<UserDocumentSetUserDocumentType>;
  documentTypeIn?: InputMaybe<Array<UserDocumentSetUserDocumentType>>;
  documentTypeNEQ?: InputMaybe<UserDocumentSetUserDocumentType>;
  documentTypeNotIn?: InputMaybe<Array<UserDocumentSetUserDocumentType>>;
  /** apex_account_files edge predicates */
  hasApexAccountFiles?: InputMaybe<Scalars['Boolean']['input']>;
  hasApexAccountFilesWith?: InputMaybe<Array<ApexAccountFileWhereInput>>;
  /** apex_investigation_files edge predicates */
  hasApexInvestigationFiles?: InputMaybe<Scalars['Boolean']['input']>;
  hasApexInvestigationFilesWith?: InputMaybe<Array<ApexInvestigationFileWhereInput>>;
  /** document edge predicates */
  hasDocument?: InputMaybe<Scalars['Boolean']['input']>;
  hasDocumentWith?: InputMaybe<Array<UserDocumentWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** isActive field predicates */
  isactive?: InputMaybe<Scalars['Boolean']['input']>;
  isactiveNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<UserDocumentSetWhereInput>;
  or?: InputMaybe<Array<UserDocumentSetWhereInput>>;
  /** payload field predicates */
  payload?: InputMaybe<Scalars['String']['input']>;
  payloadContains?: InputMaybe<Scalars['String']['input']>;
  payloadContainsFold?: InputMaybe<Scalars['String']['input']>;
  payloadEqualFold?: InputMaybe<Scalars['String']['input']>;
  payloadGT?: InputMaybe<Scalars['String']['input']>;
  payloadGTE?: InputMaybe<Scalars['String']['input']>;
  payloadHasPrefix?: InputMaybe<Scalars['String']['input']>;
  payloadHasSuffix?: InputMaybe<Scalars['String']['input']>;
  payloadIn?: InputMaybe<Array<Scalars['String']['input']>>;
  payloadLT?: InputMaybe<Scalars['String']['input']>;
  payloadLTE?: InputMaybe<Scalars['String']['input']>;
  payloadNEQ?: InputMaybe<Scalars['String']['input']>;
  payloadNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** request_time field predicates */
  requestTime?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  requestTimeLT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** request_uuid field predicates */
  requestUUID?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  requestUUIDLT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDLTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNEQ?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNotIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** requestor field predicates */
  requestor?: InputMaybe<Requestor>;
  requestorIn?: InputMaybe<Array<Requestor>>;
  requestorNEQ?: InputMaybe<Requestor>;
  requestorNotIn?: InputMaybe<Array<Requestor>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** UserDocumentUserDocumentType is enum for the field document_type */
export enum UserDocumentUserDocumentType {
  UserDocumentTypeAccountTransferForm = 'USER_DOCUMENT_TYPE_ACCOUNT_TRANSFER_FORM',
  UserDocumentTypeAffiliatedApproval = 'USER_DOCUMENT_TYPE_AFFILIATED_APPROVAL',
  UserDocumentTypeAllPassingCipResults = 'USER_DOCUMENT_TYPE_ALL_PASSING_CIP_RESULTS',
  UserDocumentTypeCddDocument = 'USER_DOCUMENT_TYPE_CDD_DOCUMENT',
  UserDocumentTypeDepositExplanation = 'USER_DOCUMENT_TYPE_DEPOSIT_EXPLANATION',
  UserDocumentTypeDriversLicense = 'USER_DOCUMENT_TYPE_DRIVERS_LICENSE',
  UserDocumentTypeIdentificationCard = 'USER_DOCUMENT_TYPE_IDENTIFICATION_CARD',
  UserDocumentTypeIdDocument = 'USER_DOCUMENT_TYPE_ID_DOCUMENT',
  UserDocumentTypeIraDepositSlip = 'USER_DOCUMENT_TYPE_IRA_DEPOSIT_SLIP',
  UserDocumentTypeIrsItinLetter = 'USER_DOCUMENT_TYPE_IRS_ITIN_LETTER',
  UserDocumentTypeLimitedMarginAgreement = 'USER_DOCUMENT_TYPE_LIMITED_MARGIN_AGREEMENT',
  UserDocumentTypeMilitaryId = 'USER_DOCUMENT_TYPE_MILITARY_ID',
  UserDocumentTypeNationalIdCard = 'USER_DOCUMENT_TYPE_NATIONAL_ID_CARD',
  UserDocumentTypeOther = 'USER_DOCUMENT_TYPE_OTHER',
  UserDocumentTypeOtherGovernmentId = 'USER_DOCUMENT_TYPE_OTHER_GOVERNMENT_ID',
  UserDocumentTypePassport = 'USER_DOCUMENT_TYPE_PASSPORT',
  UserDocumentTypeSignatureImage = 'USER_DOCUMENT_TYPE_SIGNATURE_IMAGE',
  UserDocumentTypeSsaLetter = 'USER_DOCUMENT_TYPE_SSA_LETTER',
  UserDocumentTypeSsnCard = 'USER_DOCUMENT_TYPE_SSN_CARD',
  UserDocumentTypeStateIdCard = 'USER_DOCUMENT_TYPE_STATE_ID_CARD',
  UserDocumentTypeTransferOnDeathAgreement = 'USER_DOCUMENT_TYPE_TRANSFER_ON_DEATH_AGREEMENT',
}

/**
 * UserDocumentWhereInput is used for filtering UserDocument objects.
 * Input was generated by ent.
 */
export type UserDocumentWhereInput = {
  and?: InputMaybe<Array<UserDocumentWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** document_type field predicates */
  documentType?: InputMaybe<UserDocumentUserDocumentType>;
  documentTypeIn?: InputMaybe<Array<UserDocumentUserDocumentType>>;
  documentTypeNEQ?: InputMaybe<UserDocumentUserDocumentType>;
  documentTypeNotIn?: InputMaybe<Array<UserDocumentUserDocumentType>>;
  /** apex_investigation_files edge predicates */
  hasApexInvestigationFiles?: InputMaybe<Scalars['Boolean']['input']>;
  hasApexInvestigationFilesWith?: InputMaybe<Array<ApexInvestigationFileWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** mime_type field predicates */
  mimeType?: InputMaybe<UserDocumentMimeType>;
  mimeTypeIn?: InputMaybe<Array<UserDocumentMimeType>>;
  mimeTypeNEQ?: InputMaybe<UserDocumentMimeType>;
  mimeTypeNotIn?: InputMaybe<Array<UserDocumentMimeType>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<UserDocumentWhereInput>;
  or?: InputMaybe<Array<UserDocumentWhereInput>>;
  /** path field predicates */
  path?: InputMaybe<Scalars['String']['input']>;
  pathContains?: InputMaybe<Scalars['String']['input']>;
  pathContainsFold?: InputMaybe<Scalars['String']['input']>;
  pathEqualFold?: InputMaybe<Scalars['String']['input']>;
  pathGT?: InputMaybe<Scalars['String']['input']>;
  pathGTE?: InputMaybe<Scalars['String']['input']>;
  pathHasPrefix?: InputMaybe<Scalars['String']['input']>;
  pathHasSuffix?: InputMaybe<Scalars['String']['input']>;
  pathIn?: InputMaybe<Array<Scalars['String']['input']>>;
  pathIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  pathLT?: InputMaybe<Scalars['String']['input']>;
  pathLTE?: InputMaybe<Scalars['String']['input']>;
  pathNEQ?: InputMaybe<Scalars['String']['input']>;
  pathNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  pathNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** request_time field predicates */
  requestTime?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  requestTimeLT?: InputMaybe<Scalars['Time']['input']>;
  requestTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  requestTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** request_uuid field predicates */
  requestUUID?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDGTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  requestUUIDLT?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDLTE?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNEQ?: InputMaybe<Scalars['UUID']['input']>;
  requestUUIDNotIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  requestUUIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** requestor field predicates */
  requestor?: InputMaybe<Requestor>;
  requestorIn?: InputMaybe<Array<Requestor>>;
  requestorNEQ?: InputMaybe<Requestor>;
  requestorNotIn?: InputMaybe<Array<Requestor>>;
  /** status field predicates */
  status?: InputMaybe<UserDocumentDocumentStatus>;
  statusIn?: InputMaybe<Array<UserDocumentDocumentStatus>>;
  statusNEQ?: InputMaybe<UserDocumentDocumentStatus>;
  statusNotIn?: InputMaybe<Array<UserDocumentDocumentStatus>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

export enum UserFilingStatus {
  UserFilingStatusJoint = 'USER_FILING_STATUS_JOINT',
  UserFilingStatusJointFilingSeparately = 'USER_FILING_STATUS_JOINT_FILING_SEPARATELY',
  UserFilingStatusSingle = 'USER_FILING_STATUS_SINGLE',
}

/** Ordering options for User connections */
export type UserOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Users. */
  field: UserOrderField;
};

/** Properties by which User connections can be ordered. */
export enum UserOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

export type UserProfile = Node & {
  __typename?: 'UserProfile';
  additionalStreetAddress?: Maybe<Scalars['String']['output']>;
  affiliatedExchangeOrFinraDetails?: Maybe<AffiliatedExchangeOrFinraDetails>;
  /** The user's annual gross income. */
  annualGrossIncome?: Maybe<Scalars['Int']['output']>;
  /** The country of birth of the user. */
  birthCountry?: Maybe<Country>;
  /** Foreign key for birth country. */
  birthCountryID?: Maybe<Scalars['ID']['output']>;
  /** The country of citizenship of the user. */
  citizenshipCountry?: Maybe<Country>;
  /** Foreign key for citizenship country. */
  citizenshipCountryID?: Maybe<Scalars['ID']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  controlPersonDetails?: Maybe<ControlPersonDetails>;
  country?: Maybe<Scalars['CountryCode']['output']>;
  createTime: Scalars['Time']['output'];
  dateOfBirth: Scalars['Date']['output'];
  email: Scalars['String']['output'];
  /** The user's filing status. */
  filingStatus?: Maybe<UserProfileUserFilingStatus>;
  firstName: Scalars['String']['output'];
  fnpMetadata?: Maybe<FnpMetadata>;
  id: Scalars['ID']['output'];
  isCorrespondentEmployee: Scalars['Boolean']['output'];
  /** Indicates that the user is a foreign user, not associated with the US such as a citizen or permanent resident. */
  isForeign?: Maybe<Scalars['Boolean']['output']>;
  lastName: Scalars['String']['output'];
  marketDataAnswers?: Maybe<MarketDataAnswers>;
  /** The user's other sources of wealth */
  otherSourcesOfWealth?: Maybe<OtherSourcesOfWealth>;
  otherSourcesOfWealthV2?: Maybe<OtherSourcesOfWealth>;
  permanentResident?: Maybe<Scalars['Boolean']['output']>;
  phoneNumber: Scalars['String']['output'];
  phoneNumberType: UserProfileUserPhoneNumberType;
  politicallyExposedDetails?: Maybe<PoliticallyExposedDetails>;
  ssn?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  streetAddress?: Maybe<Scalars['String']['output']>;
  /** Tax ID of the user. */
  taxID?: Maybe<Scalars['String']['output']>;
  /** location to store a requested email change */
  unverifiedEmail?: Maybe<Scalars['String']['output']>;
  /** location to store a requested phone number change */
  unverifiedPhoneNumber?: Maybe<Scalars['String']['output']>;
  /** location to store a requested phone number type change */
  unverifiedPhoneNumberType?: Maybe<UserProfileUserPhoneNumberType>;
  updateRequests: UserProfileUpdateRequestConnection;
  updateTime: Scalars['Time']['output'];
  user: User;
  visaExpirationDate?: Maybe<Scalars['Date']['output']>;
  visaType?: Maybe<UserProfileUserVisaType>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type UserProfileUpdateRequestsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserProfileUpdateRequestOrder>;
  where?: InputMaybe<UserProfileUpdateRequestWhereInput>;
};

/** A connection to a list of items. */
export type UserProfileConnection = {
  __typename?: 'UserProfileConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserProfileEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UserProfileEdge = {
  __typename?: 'UserProfileEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<UserProfile>;
};

/** Ordering options for UserProfile connections */
export type UserProfileOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order UserProfiles. */
  field: UserProfileOrderField;
};

/** Properties by which UserProfile connections can be ordered. */
export enum UserProfileOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

export type UserProfileParameters = {
  __typename?: 'UserProfileParameters';
  additionalStreetAddress?: Maybe<Scalars['String']['output']>;
  affiliatedExchangeOrFinraDetails?: Maybe<AffiliatedExchangeOrFinraDetails>;
  city?: Maybe<Scalars['String']['output']>;
  controlPersonDetails?: Maybe<ControlPersonDetails>;
  country?: Maybe<Scalars['String']['output']>;
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  marketDataAnswers?: Maybe<MarketDataAnswers>;
  otherSourcesOfWealth?: Maybe<OtherSourcesOfWealth>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneNumberType?: Maybe<UserProfileUserPhoneNumberType>;
  politicallyExposedDetails?: Maybe<PoliticallyExposedDetails>;
  ssn?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  streetAddress?: Maybe<Scalars['String']['output']>;
  taxFilingInfo?: Maybe<TaxFilingInfo>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type UserProfileUpdateRequest = Node & {
  __typename?: 'UserProfileUpdateRequest';
  /** The ApexAccountUpdateQueue entities associated with this update request */
  apexAccountUpdateQueue?: Maybe<Array<ApexAccountUpdateQueue>>;
  createTime: Scalars['Time']['output'];
  /** The snapshot of the profile at the time of this request */
  currentProfile: UserProfileParameters;
  id: Scalars['ID']['output'];
  /** The parameters to update the user profile with */
  requestParameters: UserProfileParameters;
  /** The status of the update request */
  status: UserProfileUpdateRequestUpdateRequestStatus;
  updateTime: Scalars['Time']['output'];
  /** The UserProfile entity this update request is associated with */
  userProfile: UserProfile;
};

/** A connection to a list of items. */
export type UserProfileUpdateRequestConnection = {
  __typename?: 'UserProfileUpdateRequestConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserProfileUpdateRequestEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UserProfileUpdateRequestEdge = {
  __typename?: 'UserProfileUpdateRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<UserProfileUpdateRequest>;
};

/** Ordering options for UserProfileUpdateRequest connections */
export type UserProfileUpdateRequestOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order UserProfileUpdateRequests. */
  field: UserProfileUpdateRequestOrderField;
};

/** Properties by which UserProfileUpdateRequest connections can be ordered. */
export enum UserProfileUpdateRequestOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/** UserProfileUpdateRequestUpdateRequestStatus is enum for the field status */
export enum UserProfileUpdateRequestUpdateRequestStatus {
  UpdateRequestStatusApproved = 'UPDATE_REQUEST_STATUS_APPROVED',
  UpdateRequestStatusContacated = 'UPDATE_REQUEST_STATUS_CONTACATED',
  UpdateRequestStatusPending = 'UPDATE_REQUEST_STATUS_PENDING',
  UpdateRequestStatusRejected = 'UPDATE_REQUEST_STATUS_REJECTED',
  UpdateRequestStatusSubmitted = 'UPDATE_REQUEST_STATUS_SUBMITTED',
}

/**
 * UserProfileUpdateRequestWhereInput is used for filtering UserProfileUpdateRequest objects.
 * Input was generated by ent.
 */
export type UserProfileUpdateRequestWhereInput = {
  and?: InputMaybe<Array<UserProfileUpdateRequestWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** apex_account_update_queue edge predicates */
  hasApexAccountUpdateQueue?: InputMaybe<Scalars['Boolean']['input']>;
  hasApexAccountUpdateQueueWith?: InputMaybe<Array<ApexAccountUpdateQueueWhereInput>>;
  /** user_profile edge predicates */
  hasUserProfile?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserProfileWith?: InputMaybe<Array<UserProfileWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<UserProfileUpdateRequestWhereInput>;
  or?: InputMaybe<Array<UserProfileUpdateRequestWhereInput>>;
  /** status field predicates */
  status?: InputMaybe<UserProfileUpdateRequestUpdateRequestStatus>;
  statusIn?: InputMaybe<Array<UserProfileUpdateRequestUpdateRequestStatus>>;
  statusNEQ?: InputMaybe<UserProfileUpdateRequestUpdateRequestStatus>;
  statusNotIn?: InputMaybe<Array<UserProfileUpdateRequestUpdateRequestStatus>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** UserProfileUserFilingStatus is enum for the field filing_status */
export enum UserProfileUserFilingStatus {
  UserFilingStatusJoint = 'USER_FILING_STATUS_JOINT',
  UserFilingStatusJointFilingSeparately = 'USER_FILING_STATUS_JOINT_FILING_SEPARATELY',
  UserFilingStatusSingle = 'USER_FILING_STATUS_SINGLE',
}

/** UserProfileUserPhoneNumberType is enum for the field phone_number_type */
export enum UserProfileUserPhoneNumberType {
  UserPhoneNumberTypeFax = 'USER_PHONE_NUMBER_TYPE_FAX',
  UserPhoneNumberTypeHome = 'USER_PHONE_NUMBER_TYPE_HOME',
  UserPhoneNumberTypeMobile = 'USER_PHONE_NUMBER_TYPE_MOBILE',
  UserPhoneNumberTypeOther = 'USER_PHONE_NUMBER_TYPE_OTHER',
  UserPhoneNumberTypeWork = 'USER_PHONE_NUMBER_TYPE_WORK',
}

/** UserProfileUserVisaType is enum for the field visa_type */
export enum UserProfileUserVisaType {
  UserVisaTypeE1 = 'USER_VISA_TYPE_E1',
  UserVisaTypeE2 = 'USER_VISA_TYPE_E2',
  UserVisaTypeE3 = 'USER_VISA_TYPE_E3',
  UserVisaTypeF1 = 'USER_VISA_TYPE_F1',
  UserVisaTypeG4 = 'USER_VISA_TYPE_G4',
  UserVisaTypeH1B = 'USER_VISA_TYPE_H1B',
  UserVisaTypeL1 = 'USER_VISA_TYPE_L1',
  UserVisaTypeO1 = 'USER_VISA_TYPE_O1',
  UserVisaTypeOther = 'USER_VISA_TYPE_OTHER',
  UserVisaTypeTn1 = 'USER_VISA_TYPE_TN1',
}

/**
 * UserProfileWhereInput is used for filtering UserProfile objects.
 * Input was generated by ent.
 */
export type UserProfileWhereInput = {
  /** additional_street_address field predicates */
  additionalStreetAddress?: InputMaybe<Scalars['String']['input']>;
  additionalStreetAddressContains?: InputMaybe<Scalars['String']['input']>;
  additionalStreetAddressContainsFold?: InputMaybe<Scalars['String']['input']>;
  additionalStreetAddressEqualFold?: InputMaybe<Scalars['String']['input']>;
  additionalStreetAddressGT?: InputMaybe<Scalars['String']['input']>;
  additionalStreetAddressGTE?: InputMaybe<Scalars['String']['input']>;
  additionalStreetAddressHasPrefix?: InputMaybe<Scalars['String']['input']>;
  additionalStreetAddressHasSuffix?: InputMaybe<Scalars['String']['input']>;
  additionalStreetAddressIn?: InputMaybe<Array<Scalars['String']['input']>>;
  additionalStreetAddressIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  additionalStreetAddressLT?: InputMaybe<Scalars['String']['input']>;
  additionalStreetAddressLTE?: InputMaybe<Scalars['String']['input']>;
  additionalStreetAddressNEQ?: InputMaybe<Scalars['String']['input']>;
  additionalStreetAddressNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  additionalStreetAddressNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<UserProfileWhereInput>>;
  /** annual_gross_income field predicates */
  annualGrossIncome?: InputMaybe<Scalars['Int']['input']>;
  annualGrossIncomeGT?: InputMaybe<Scalars['Int']['input']>;
  annualGrossIncomeGTE?: InputMaybe<Scalars['Int']['input']>;
  annualGrossIncomeIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  annualGrossIncomeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  annualGrossIncomeLT?: InputMaybe<Scalars['Int']['input']>;
  annualGrossIncomeLTE?: InputMaybe<Scalars['Int']['input']>;
  annualGrossIncomeNEQ?: InputMaybe<Scalars['Int']['input']>;
  annualGrossIncomeNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  annualGrossIncomeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** birth_country_id field predicates */
  birthCountryID?: InputMaybe<Scalars['ID']['input']>;
  birthCountryIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  birthCountryIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  birthCountryIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  birthCountryIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  birthCountryIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** citizenship_country_id field predicates */
  citizenshipCountryID?: InputMaybe<Scalars['ID']['input']>;
  citizenshipCountryIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  citizenshipCountryIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  citizenshipCountryIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  citizenshipCountryIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  citizenshipCountryIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** city field predicates */
  city?: InputMaybe<Scalars['String']['input']>;
  cityContains?: InputMaybe<Scalars['String']['input']>;
  cityContainsFold?: InputMaybe<Scalars['String']['input']>;
  cityEqualFold?: InputMaybe<Scalars['String']['input']>;
  cityGT?: InputMaybe<Scalars['String']['input']>;
  cityGTE?: InputMaybe<Scalars['String']['input']>;
  cityHasPrefix?: InputMaybe<Scalars['String']['input']>;
  cityHasSuffix?: InputMaybe<Scalars['String']['input']>;
  cityIn?: InputMaybe<Array<Scalars['String']['input']>>;
  cityIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  cityLT?: InputMaybe<Scalars['String']['input']>;
  cityLTE?: InputMaybe<Scalars['String']['input']>;
  cityNEQ?: InputMaybe<Scalars['String']['input']>;
  cityNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  cityNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** country field predicates */
  country?: InputMaybe<Scalars['CountryCode']['input']>;
  countryGT?: InputMaybe<Scalars['CountryCode']['input']>;
  countryGTE?: InputMaybe<Scalars['CountryCode']['input']>;
  countryIn?: InputMaybe<Array<Scalars['CountryCode']['input']>>;
  countryIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  countryLT?: InputMaybe<Scalars['CountryCode']['input']>;
  countryLTE?: InputMaybe<Scalars['CountryCode']['input']>;
  countryNEQ?: InputMaybe<Scalars['CountryCode']['input']>;
  countryNotIn?: InputMaybe<Array<Scalars['CountryCode']['input']>>;
  countryNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** date_of_birth field predicates */
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirthGT?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirthGTE?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirthIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  dateOfBirthLT?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirthLTE?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirthNEQ?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirthNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  /** email field predicates */
  email?: InputMaybe<Scalars['String']['input']>;
  emailContains?: InputMaybe<Scalars['String']['input']>;
  emailContainsFold?: InputMaybe<Scalars['String']['input']>;
  emailEqualFold?: InputMaybe<Scalars['String']['input']>;
  emailGT?: InputMaybe<Scalars['String']['input']>;
  emailGTE?: InputMaybe<Scalars['String']['input']>;
  emailHasPrefix?: InputMaybe<Scalars['String']['input']>;
  emailHasSuffix?: InputMaybe<Scalars['String']['input']>;
  emailIn?: InputMaybe<Array<Scalars['String']['input']>>;
  emailLT?: InputMaybe<Scalars['String']['input']>;
  emailLTE?: InputMaybe<Scalars['String']['input']>;
  emailNEQ?: InputMaybe<Scalars['String']['input']>;
  emailNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** filing_status field predicates */
  filingStatus?: InputMaybe<UserProfileUserFilingStatus>;
  filingStatusIn?: InputMaybe<Array<UserProfileUserFilingStatus>>;
  filingStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  filingStatusNEQ?: InputMaybe<UserProfileUserFilingStatus>;
  filingStatusNotIn?: InputMaybe<Array<UserProfileUserFilingStatus>>;
  filingStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** first_name field predicates */
  firstName?: InputMaybe<Scalars['String']['input']>;
  firstNameContains?: InputMaybe<Scalars['String']['input']>;
  firstNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  firstNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  firstNameGT?: InputMaybe<Scalars['String']['input']>;
  firstNameGTE?: InputMaybe<Scalars['String']['input']>;
  firstNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  firstNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  firstNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  firstNameLT?: InputMaybe<Scalars['String']['input']>;
  firstNameLTE?: InputMaybe<Scalars['String']['input']>;
  firstNameNEQ?: InputMaybe<Scalars['String']['input']>;
  firstNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** birth_country edge predicates */
  hasBirthCountry?: InputMaybe<Scalars['Boolean']['input']>;
  hasBirthCountryWith?: InputMaybe<Array<CountryWhereInput>>;
  /** citizenship_country edge predicates */
  hasCitizenshipCountry?: InputMaybe<Scalars['Boolean']['input']>;
  hasCitizenshipCountryWith?: InputMaybe<Array<CountryWhereInput>>;
  /** fnp_metadata edge predicates */
  hasFnpMetadata?: InputMaybe<Scalars['Boolean']['input']>;
  hasFnpMetadataWith?: InputMaybe<Array<FnpMetadataWhereInput>>;
  /** market_data_answers edge predicates */
  hasMarketDataAnswers?: InputMaybe<Scalars['Boolean']['input']>;
  hasMarketDataAnswersWith?: InputMaybe<Array<MarketDataAnswersWhereInput>>;
  /** update_requests edge predicates */
  hasUpdateRequests?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdateRequestsWith?: InputMaybe<Array<UserProfileUpdateRequestWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  isAffiliatedExchangeOrFinra?: InputMaybe<Scalars['Boolean']['input']>;
  isControlPerson?: InputMaybe<Scalars['Boolean']['input']>;
  /** is_foreign field predicates */
  isForeign?: InputMaybe<Scalars['Boolean']['input']>;
  isForeignIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  isForeignNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  isForeignNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  isPoliticallyExposed?: InputMaybe<Scalars['Boolean']['input']>;
  /** last_name field predicates */
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastNameContains?: InputMaybe<Scalars['String']['input']>;
  lastNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  lastNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  lastNameGT?: InputMaybe<Scalars['String']['input']>;
  lastNameGTE?: InputMaybe<Scalars['String']['input']>;
  lastNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  lastNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  lastNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  lastNameLT?: InputMaybe<Scalars['String']['input']>;
  lastNameLTE?: InputMaybe<Scalars['String']['input']>;
  lastNameNEQ?: InputMaybe<Scalars['String']['input']>;
  lastNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<UserProfileWhereInput>;
  or?: InputMaybe<Array<UserProfileWhereInput>>;
  /** permanent_resident field predicates */
  permanentResident?: InputMaybe<Scalars['Boolean']['input']>;
  permanentResidentIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  permanentResidentNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  permanentResidentNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** phone_number field predicates */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberContains?: InputMaybe<Scalars['String']['input']>;
  phoneNumberContainsFold?: InputMaybe<Scalars['String']['input']>;
  phoneNumberEqualFold?: InputMaybe<Scalars['String']['input']>;
  phoneNumberGT?: InputMaybe<Scalars['String']['input']>;
  phoneNumberGTE?: InputMaybe<Scalars['String']['input']>;
  phoneNumberHasPrefix?: InputMaybe<Scalars['String']['input']>;
  phoneNumberHasSuffix?: InputMaybe<Scalars['String']['input']>;
  phoneNumberIn?: InputMaybe<Array<Scalars['String']['input']>>;
  phoneNumberLT?: InputMaybe<Scalars['String']['input']>;
  phoneNumberLTE?: InputMaybe<Scalars['String']['input']>;
  phoneNumberNEQ?: InputMaybe<Scalars['String']['input']>;
  phoneNumberNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** phone_number_type field predicates */
  phoneNumberType?: InputMaybe<UserProfileUserPhoneNumberType>;
  phoneNumberTypeIn?: InputMaybe<Array<UserProfileUserPhoneNumberType>>;
  phoneNumberTypeNEQ?: InputMaybe<UserProfileUserPhoneNumberType>;
  phoneNumberTypeNotIn?: InputMaybe<Array<UserProfileUserPhoneNumberType>>;
  /** state field predicates */
  state?: InputMaybe<Scalars['String']['input']>;
  stateContains?: InputMaybe<Scalars['String']['input']>;
  stateContainsFold?: InputMaybe<Scalars['String']['input']>;
  stateEqualFold?: InputMaybe<Scalars['String']['input']>;
  stateGT?: InputMaybe<Scalars['String']['input']>;
  stateGTE?: InputMaybe<Scalars['String']['input']>;
  stateHasPrefix?: InputMaybe<Scalars['String']['input']>;
  stateHasSuffix?: InputMaybe<Scalars['String']['input']>;
  stateIn?: InputMaybe<Array<Scalars['String']['input']>>;
  stateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  stateLT?: InputMaybe<Scalars['String']['input']>;
  stateLTE?: InputMaybe<Scalars['String']['input']>;
  stateNEQ?: InputMaybe<Scalars['String']['input']>;
  stateNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  stateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** street_address field predicates */
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  streetAddressContains?: InputMaybe<Scalars['String']['input']>;
  streetAddressContainsFold?: InputMaybe<Scalars['String']['input']>;
  streetAddressEqualFold?: InputMaybe<Scalars['String']['input']>;
  streetAddressGT?: InputMaybe<Scalars['String']['input']>;
  streetAddressGTE?: InputMaybe<Scalars['String']['input']>;
  streetAddressHasPrefix?: InputMaybe<Scalars['String']['input']>;
  streetAddressHasSuffix?: InputMaybe<Scalars['String']['input']>;
  streetAddressIn?: InputMaybe<Array<Scalars['String']['input']>>;
  streetAddressIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  streetAddressLT?: InputMaybe<Scalars['String']['input']>;
  streetAddressLTE?: InputMaybe<Scalars['String']['input']>;
  streetAddressNEQ?: InputMaybe<Scalars['String']['input']>;
  streetAddressNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  streetAddressNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_id field predicates */
  taxID?: InputMaybe<Scalars['String']['input']>;
  taxIDGT?: InputMaybe<Scalars['String']['input']>;
  taxIDGTE?: InputMaybe<Scalars['String']['input']>;
  taxIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  taxIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxIDLT?: InputMaybe<Scalars['String']['input']>;
  taxIDLTE?: InputMaybe<Scalars['String']['input']>;
  taxIDNEQ?: InputMaybe<Scalars['String']['input']>;
  taxIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  taxIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** unverified_email field predicates */
  unverifiedEmail?: InputMaybe<Scalars['String']['input']>;
  unverifiedEmailContains?: InputMaybe<Scalars['String']['input']>;
  unverifiedEmailContainsFold?: InputMaybe<Scalars['String']['input']>;
  unverifiedEmailEqualFold?: InputMaybe<Scalars['String']['input']>;
  unverifiedEmailGT?: InputMaybe<Scalars['String']['input']>;
  unverifiedEmailGTE?: InputMaybe<Scalars['String']['input']>;
  unverifiedEmailHasPrefix?: InputMaybe<Scalars['String']['input']>;
  unverifiedEmailHasSuffix?: InputMaybe<Scalars['String']['input']>;
  unverifiedEmailIn?: InputMaybe<Array<Scalars['String']['input']>>;
  unverifiedEmailIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  unverifiedEmailLT?: InputMaybe<Scalars['String']['input']>;
  unverifiedEmailLTE?: InputMaybe<Scalars['String']['input']>;
  unverifiedEmailNEQ?: InputMaybe<Scalars['String']['input']>;
  unverifiedEmailNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  unverifiedEmailNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** unverified_phone_number field predicates */
  unverifiedPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  unverifiedPhoneNumberContains?: InputMaybe<Scalars['String']['input']>;
  unverifiedPhoneNumberContainsFold?: InputMaybe<Scalars['String']['input']>;
  unverifiedPhoneNumberEqualFold?: InputMaybe<Scalars['String']['input']>;
  unverifiedPhoneNumberGT?: InputMaybe<Scalars['String']['input']>;
  unverifiedPhoneNumberGTE?: InputMaybe<Scalars['String']['input']>;
  unverifiedPhoneNumberHasPrefix?: InputMaybe<Scalars['String']['input']>;
  unverifiedPhoneNumberHasSuffix?: InputMaybe<Scalars['String']['input']>;
  unverifiedPhoneNumberIn?: InputMaybe<Array<Scalars['String']['input']>>;
  unverifiedPhoneNumberIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  unverifiedPhoneNumberLT?: InputMaybe<Scalars['String']['input']>;
  unverifiedPhoneNumberLTE?: InputMaybe<Scalars['String']['input']>;
  unverifiedPhoneNumberNEQ?: InputMaybe<Scalars['String']['input']>;
  unverifiedPhoneNumberNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  unverifiedPhoneNumberNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** unverified_phone_number_type field predicates */
  unverifiedPhoneNumberType?: InputMaybe<UserProfileUserPhoneNumberType>;
  unverifiedPhoneNumberTypeIn?: InputMaybe<Array<UserProfileUserPhoneNumberType>>;
  unverifiedPhoneNumberTypeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  unverifiedPhoneNumberTypeNEQ?: InputMaybe<UserProfileUserPhoneNumberType>;
  unverifiedPhoneNumberTypeNotIn?: InputMaybe<Array<UserProfileUserPhoneNumberType>>;
  unverifiedPhoneNumberTypeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** visa_expiration_date field predicates */
  visaExpirationDate?: InputMaybe<Scalars['Date']['input']>;
  visaExpirationDateGT?: InputMaybe<Scalars['Date']['input']>;
  visaExpirationDateGTE?: InputMaybe<Scalars['Date']['input']>;
  visaExpirationDateIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  visaExpirationDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  visaExpirationDateLT?: InputMaybe<Scalars['Date']['input']>;
  visaExpirationDateLTE?: InputMaybe<Scalars['Date']['input']>;
  visaExpirationDateNEQ?: InputMaybe<Scalars['Date']['input']>;
  visaExpirationDateNotIn?: InputMaybe<Array<Scalars['Date']['input']>>;
  visaExpirationDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** visa_type field predicates */
  visaType?: InputMaybe<UserProfileUserVisaType>;
  visaTypeIn?: InputMaybe<Array<UserProfileUserVisaType>>;
  visaTypeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  visaTypeNEQ?: InputMaybe<UserProfileUserVisaType>;
  visaTypeNotIn?: InputMaybe<Array<UserProfileUserVisaType>>;
  visaTypeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** zip_code field predicates */
  zipCode?: InputMaybe<Scalars['String']['input']>;
  zipCodeContains?: InputMaybe<Scalars['String']['input']>;
  zipCodeContainsFold?: InputMaybe<Scalars['String']['input']>;
  zipCodeEqualFold?: InputMaybe<Scalars['String']['input']>;
  zipCodeGT?: InputMaybe<Scalars['String']['input']>;
  zipCodeGTE?: InputMaybe<Scalars['String']['input']>;
  zipCodeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  zipCodeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  zipCodeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  zipCodeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  zipCodeLT?: InputMaybe<Scalars['String']['input']>;
  zipCodeLTE?: InputMaybe<Scalars['String']['input']>;
  zipCodeNEQ?: InputMaybe<Scalars['String']['input']>;
  zipCodeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  zipCodeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserRetirementContribution = Node & {
  __typename?: 'UserRetirementContribution';
  accountType: UserRetirementContributionRetirementAccountType;
  createTime: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  /** total is the total amount of retirement contributions made by the user in the given year. */
  total: Scalars['Decimal']['output'];
  /** total_with_pending is the total amount of retirement contributions made by the user in the given year, including pending contributions. */
  totalWithPending: Scalars['Decimal']['output'];
  updateTime: Scalars['Time']['output'];
  user: User;
  /** year is the year the information in this contribution applies to. */
  year: Scalars['Int']['output'];
};

export type UserRetirementContributionInfo = {
  __typename?: 'UserRetirementContributionInfo';
  limits: RetirementContributionLimits;
  /**
   * rothIRA is the amount of contribution to the Roth IRA account.
   * @deprecated Use nested object.
   */
  rothIRA: Scalars['Decimal']['output'];
  /**
   * rothIRALimit is the allowed contribution amount to the Roth IRA account.
   * @deprecated Use nested object.
   */
  rothIRALimit: Scalars['Decimal']['output'];
  /**
   * rothIRAWithPending is the amount of contribution to the Roth IRA account
   *   including pending contributions.
   * @deprecated Use nested object.
   */
  rothIRAWithPending: Scalars['Decimal']['output'];
  /**
   * total is the total amount of contribution across all retirement accounts.
   * @deprecated Use nested object.
   */
  total: Scalars['Decimal']['output'];
  /**
   * totalLimit is the allowed contribution amount across all retirement accounts.
   * @deprecated Use nested object.
   */
  totalLimit: Scalars['Decimal']['output'];
  /**
   * totalWithPending is the total amount of contribution across all retirement accounts
   *   including pending contributions.
   * @deprecated Use nested object.
   */
  totalWithPending: Scalars['Decimal']['output'];
  totals: UserRetirementContributionTotals;
  /**
   * traditionalIRA is the amount of contribution to the traditional IRA account.
   * @deprecated Use nested object.
   */
  traditionalIRA: Scalars['Decimal']['output'];
  /**
   * traditionalIRALimit is the allowed contribution amount to the traditional IRA account.
   * @deprecated Use nested object.
   */
  traditionalIRALimit: Scalars['Decimal']['output'];
  /**
   * traditionalIRAWithPending is the amount of contribution to the traditional IRA account
   *   including pending contributions.
   * @deprecated Use nested object.
   */
  traditionalIRAWithPending: Scalars['Decimal']['output'];
  userID: Scalars['ID']['output'];
  year: Scalars['Uint']['output'];
};

/** Ordering options for UserRetirementContribution connections */
export type UserRetirementContributionOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order UserRetirementContributions. */
  field: UserRetirementContributionOrderField;
};

/** Properties by which UserRetirementContribution connections can be ordered. */
export enum UserRetirementContributionOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/** UserRetirementContributionRetirementAccountType is enum for the field account_type */
export enum UserRetirementContributionRetirementAccountType {
  RetirementAccountTypeRothIra = 'RETIREMENT_ACCOUNT_TYPE_ROTH_IRA',
  RetirementAccountTypeTraditionalIra = 'RETIREMENT_ACCOUNT_TYPE_TRADITIONAL_IRA',
}

export type UserRetirementContributionTotal = {
  __typename?: 'UserRetirementContributionTotal';
  /** Total is the total amount of settled contribution in the account. */
  total: Scalars['Decimal']['output'];
  /** TotalWithPending is the total amount of settled and pending contribution in the account. */
  totalWithPending: Scalars['Decimal']['output'];
};

export type UserRetirementContributionTotals = {
  __typename?: 'UserRetirementContributionTotals';
  combined: UserRetirementContributionTotal;
  rothIRA: UserRetirementContributionTotal;
  traditionalIRA: UserRetirementContributionTotal;
};

/**
 * UserRetirementContributionWhereInput is used for filtering UserRetirementContribution objects.
 * Input was generated by ent.
 */
export type UserRetirementContributionWhereInput = {
  /** account_type field predicates */
  accountType?: InputMaybe<UserRetirementContributionRetirementAccountType>;
  accountTypeIn?: InputMaybe<Array<UserRetirementContributionRetirementAccountType>>;
  accountTypeNEQ?: InputMaybe<UserRetirementContributionRetirementAccountType>;
  accountTypeNotIn?: InputMaybe<Array<UserRetirementContributionRetirementAccountType>>;
  and?: InputMaybe<Array<UserRetirementContributionWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<UserRetirementContributionWhereInput>;
  or?: InputMaybe<Array<UserRetirementContributionWhereInput>>;
  /** total field predicates */
  total?: InputMaybe<Scalars['Decimal']['input']>;
  totalGT?: InputMaybe<Scalars['Decimal']['input']>;
  totalGTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  totalLT?: InputMaybe<Scalars['Decimal']['input']>;
  totalLTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  totalNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** total_with_pending field predicates */
  totalWithPending?: InputMaybe<Scalars['Decimal']['input']>;
  totalWithPendingGT?: InputMaybe<Scalars['Decimal']['input']>;
  totalWithPendingGTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalWithPendingIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  totalWithPendingLT?: InputMaybe<Scalars['Decimal']['input']>;
  totalWithPendingLTE?: InputMaybe<Scalars['Decimal']['input']>;
  totalWithPendingNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  totalWithPendingNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** year field predicates */
  year?: InputMaybe<Scalars['Int']['input']>;
  yearGT?: InputMaybe<Scalars['Int']['input']>;
  yearGTE?: InputMaybe<Scalars['Int']['input']>;
  yearIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  yearLT?: InputMaybe<Scalars['Int']['input']>;
  yearLTE?: InputMaybe<Scalars['Int']['input']>;
  yearNEQ?: InputMaybe<Scalars['Int']['input']>;
  yearNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UserRetirementContributionsWhereInput = {
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** year field predicates */
  year?: InputMaybe<Scalars['Uint']['input']>;
  yearIn?: InputMaybe<Array<Scalars['Uint']['input']>>;
};

/** UserRiskTolerance is enum for the field risk_tolerance */
export enum UserRiskTolerance {
  UserRiskToleranceHigh = 'USER_RISK_TOLERANCE_HIGH',
  UserRiskToleranceLow = 'USER_RISK_TOLERANCE_LOW',
  UserRiskToleranceMedium = 'USER_RISK_TOLERANCE_MEDIUM',
}

export type UserSetting = Node & {
  __typename?: 'UserSetting';
  createTime: Scalars['Time']['output'];
  domain: UserSettingDomain;
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  updateTime: Scalars['Time']['output'];
  user: User;
  value: Scalars['String']['output'];
};

/** UserSettingDomain is enum for the field domain */
export enum UserSettingDomain {
  DomainRetirement = 'DOMAIN_RETIREMENT',
}

/** Ordering options for UserSetting connections */
export type UserSettingOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order UserSettings. */
  field: UserSettingOrderField;
};

/** Properties by which UserSetting connections can be ordered. */
export enum UserSettingOrderField {
  CreateTime = 'create_time',
  UpdateTime = 'update_time',
}

/**
 * UserSettingWhereInput is used for filtering UserSetting objects.
 * Input was generated by ent.
 */
export type UserSettingWhereInput = {
  and?: InputMaybe<Array<UserSettingWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** domain field predicates */
  domain?: InputMaybe<UserSettingDomain>;
  domainIn?: InputMaybe<Array<UserSettingDomain>>;
  domainNEQ?: InputMaybe<UserSettingDomain>;
  domainNotIn?: InputMaybe<Array<UserSettingDomain>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** key field predicates */
  key?: InputMaybe<Scalars['String']['input']>;
  keyContains?: InputMaybe<Scalars['String']['input']>;
  keyContainsFold?: InputMaybe<Scalars['String']['input']>;
  keyEqualFold?: InputMaybe<Scalars['String']['input']>;
  keyGT?: InputMaybe<Scalars['String']['input']>;
  keyGTE?: InputMaybe<Scalars['String']['input']>;
  keyHasPrefix?: InputMaybe<Scalars['String']['input']>;
  keyHasSuffix?: InputMaybe<Scalars['String']['input']>;
  keyIn?: InputMaybe<Array<Scalars['String']['input']>>;
  keyLT?: InputMaybe<Scalars['String']['input']>;
  keyLTE?: InputMaybe<Scalars['String']['input']>;
  keyNEQ?: InputMaybe<Scalars['String']['input']>;
  keyNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<UserSettingWhereInput>;
  or?: InputMaybe<Array<UserSettingWhereInput>>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** value field predicates */
  value?: InputMaybe<Scalars['String']['input']>;
  valueContains?: InputMaybe<Scalars['String']['input']>;
  valueContainsFold?: InputMaybe<Scalars['String']['input']>;
  valueEqualFold?: InputMaybe<Scalars['String']['input']>;
  valueGT?: InputMaybe<Scalars['String']['input']>;
  valueGTE?: InputMaybe<Scalars['String']['input']>;
  valueHasPrefix?: InputMaybe<Scalars['String']['input']>;
  valueHasSuffix?: InputMaybe<Scalars['String']['input']>;
  valueIn?: InputMaybe<Array<Scalars['String']['input']>>;
  valueLT?: InputMaybe<Scalars['String']['input']>;
  valueLTE?: InputMaybe<Scalars['String']['input']>;
  valueNEQ?: InputMaybe<Scalars['String']['input']>;
  valueNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserSettings = {
  __typename?: 'UserSettings';
  retirement?: Maybe<RetirementUserSettings>;
};

/**
 * UserSettingsWhereInput is used for filtering UserSetting objects.
 * Input was generated by ent.
 */
export type UserSettingsWhereInput = {
  /** user edge predicates */
  userID?: InputMaybe<Scalars['ID']['input']>;
};

/** UserUserRestrictionStatus is enum for the field restriction_status */
export enum UserUserRestrictionStatus {
  UserRestrictionStatusNoAccess = 'USER_RESTRICTION_STATUS_NO_ACCESS',
  UserRestrictionStatusReadOnlyAccess = 'USER_RESTRICTION_STATUS_READ_ONLY_ACCESS',
  UserRestrictionStatusUnrestricted = 'USER_RESTRICTION_STATUS_UNRESTRICTED',
}

/**
 * UserWhereInput is used for filtering User objects.
 * Input was generated by ent.
 */
export type UserWhereInput = {
  /** allowed_ach_relationships field predicates */
  allowedAchRelationships?: InputMaybe<Scalars['Int']['input']>;
  allowedAchRelationshipsGT?: InputMaybe<Scalars['Int']['input']>;
  allowedAchRelationshipsGTE?: InputMaybe<Scalars['Int']['input']>;
  allowedAchRelationshipsIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  allowedAchRelationshipsLT?: InputMaybe<Scalars['Int']['input']>;
  allowedAchRelationshipsLTE?: InputMaybe<Scalars['Int']['input']>;
  allowedAchRelationshipsNEQ?: InputMaybe<Scalars['Int']['input']>;
  allowedAchRelationshipsNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  and?: InputMaybe<Array<UserWhereInput>>;
  /** create_time field predicates */
  createTime?: InputMaybe<Scalars['Time']['input']>;
  createTimeGT?: InputMaybe<Scalars['Time']['input']>;
  createTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createTimeLT?: InputMaybe<Scalars['Time']['input']>;
  createTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  createTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  createTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** email field predicates */
  email?: InputMaybe<Scalars['String']['input']>;
  emailContains?: InputMaybe<Scalars['String']['input']>;
  emailContainsFold?: InputMaybe<Scalars['String']['input']>;
  emailEqualFold?: InputMaybe<Scalars['String']['input']>;
  emailGT?: InputMaybe<Scalars['String']['input']>;
  emailGTE?: InputMaybe<Scalars['String']['input']>;
  emailHasPrefix?: InputMaybe<Scalars['String']['input']>;
  emailHasSuffix?: InputMaybe<Scalars['String']['input']>;
  emailIn?: InputMaybe<Array<Scalars['String']['input']>>;
  emailLT?: InputMaybe<Scalars['String']['input']>;
  emailLTE?: InputMaybe<Scalars['String']['input']>;
  emailNEQ?: InputMaybe<Scalars['String']['input']>;
  emailNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** enabled_crypto field predicates */
  enabledCrypto?: InputMaybe<Scalars['Boolean']['input']>;
  enabledCryptoNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** enabled_robo field predicates */
  enabledRobo?: InputMaybe<Scalars['Boolean']['input']>;
  enabledRoboNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** external_id field predicates */
  externalID?: InputMaybe<Scalars['String']['input']>;
  externalIDContains?: InputMaybe<Scalars['String']['input']>;
  externalIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  externalIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  externalIDGT?: InputMaybe<Scalars['String']['input']>;
  externalIDGTE?: InputMaybe<Scalars['String']['input']>;
  externalIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  externalIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  externalIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  externalIDLT?: InputMaybe<Scalars['String']['input']>;
  externalIDLTE?: InputMaybe<Scalars['String']['input']>;
  externalIDNEQ?: InputMaybe<Scalars['String']['input']>;
  externalIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  externalIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** first_name field predicates */
  firstName?: InputMaybe<Scalars['String']['input']>;
  firstNameContains?: InputMaybe<Scalars['String']['input']>;
  firstNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  firstNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  firstNameGT?: InputMaybe<Scalars['String']['input']>;
  firstNameGTE?: InputMaybe<Scalars['String']['input']>;
  firstNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  firstNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  firstNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  firstNameLT?: InputMaybe<Scalars['String']['input']>;
  firstNameLTE?: InputMaybe<Scalars['String']['input']>;
  firstNameNEQ?: InputMaybe<Scalars['String']['input']>;
  firstNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** accounts edge predicates */
  hasAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountsWith?: InputMaybe<Array<UserAccountWhereInput>>;
  /** ascend_investigation edge predicates */
  hasAscendInvestigation?: InputMaybe<Scalars['Boolean']['input']>;
  hasAscendInvestigationWith?: InputMaybe<Array<AscendInvestigationWhereInput>>;
  /** discretionary_account_kyc edge predicates */
  hasDiscretionaryAccountKyc?: InputMaybe<Scalars['Boolean']['input']>;
  hasDiscretionaryAccountKycWith?: InputMaybe<Array<DiscretionaryAccountKycWhereInput>>;
  /** external_accounts edge predicates */
  hasExternalAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  hasExternalAccountsWith?: InputMaybe<Array<ExternalAccountWhereInput>>;
  /** non_discretionary_account_kyc edge predicates */
  hasNonDiscretionaryAccountKyc?: InputMaybe<Scalars['Boolean']['input']>;
  hasNonDiscretionaryAccountKycWith?: InputMaybe<Array<NonDiscretionaryAccountKycWhereInput>>;
  /** portfolios edge predicates */
  hasPortfolios?: InputMaybe<Scalars['Boolean']['input']>;
  hasPortfoliosWith?: InputMaybe<Array<UserAccountPortfolioWhereInput>>;
  /** profile edge predicates */
  hasProfile?: InputMaybe<Scalars['Boolean']['input']>;
  hasProfileWith?: InputMaybe<Array<UserProfileWhereInput>>;
  /** recurring_rules edge predicates */
  hasRecurringRules?: InputMaybe<Scalars['Boolean']['input']>;
  hasRecurringRulesWith?: InputMaybe<Array<RecurringRuleWhereInput>>;
  /** retirement_contributions edge predicates */
  hasRetirementContributions?: InputMaybe<Scalars['Boolean']['input']>;
  hasRetirementContributionsWith?: InputMaybe<Array<UserRetirementContributionWhereInput>>;
  /** has_return field predicates */
  hasReturn?: InputMaybe<Scalars['Boolean']['input']>;
  hasReturnNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** robo_general_investments edge predicates */
  hasRoboGeneralInvestments?: InputMaybe<Scalars['Boolean']['input']>;
  hasRoboGeneralInvestmentsWith?: InputMaybe<Array<RoboGeneralInvestmentWhereInput>>;
  /** robo_goals edge predicates */
  hasRoboGoals?: InputMaybe<Scalars['Boolean']['input']>;
  hasRoboGoalsWith?: InputMaybe<Array<RoboGoalWhereInput>>;
  /** rtq_responses edge predicates */
  hasRtqResponses?: InputMaybe<Scalars['Boolean']['input']>;
  hasRtqResponsesWith?: InputMaybe<Array<RtqResponseWhereInput>>;
  /** settings edge predicates */
  hasSettings?: InputMaybe<Scalars['Boolean']['input']>;
  hasSettingsWith?: InputMaybe<Array<UserSettingWhereInput>>;
  /** submissions edge predicates */
  hasSubmissions?: InputMaybe<Scalars['Boolean']['input']>;
  hasSubmissionsWith?: InputMaybe<Array<RtqSubmissionWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** in_queue field predicates */
  inQueue?: InputMaybe<Scalars['Boolean']['input']>;
  inQueueNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** is_active field predicates */
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isActiveNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** is_login_mfa_enabled field predicates */
  isLoginMfaEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isLoginMfaEnabledNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** is_staff field predicates */
  isStaff?: InputMaybe<Scalars['Boolean']['input']>;
  isStaffNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** is_subscription field predicates */
  isSubscription?: InputMaybe<Scalars['Boolean']['input']>;
  isSubscriptionNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** is_withdrawal_mfa_enabled field predicates */
  isWithdrawalMfaEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isWithdrawalMfaEnabledNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** last_login_time field predicates */
  lastLoginTime?: InputMaybe<Scalars['Time']['input']>;
  lastLoginTimeGT?: InputMaybe<Scalars['Time']['input']>;
  lastLoginTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  lastLoginTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  lastLoginTimeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  lastLoginTimeLT?: InputMaybe<Scalars['Time']['input']>;
  lastLoginTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  lastLoginTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  lastLoginTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  lastLoginTimeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** last_name field predicates */
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastNameContains?: InputMaybe<Scalars['String']['input']>;
  lastNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  lastNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  lastNameGT?: InputMaybe<Scalars['String']['input']>;
  lastNameGTE?: InputMaybe<Scalars['String']['input']>;
  lastNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  lastNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  lastNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  lastNameLT?: InputMaybe<Scalars['String']['input']>;
  lastNameLTE?: InputMaybe<Scalars['String']['input']>;
  lastNameNEQ?: InputMaybe<Scalars['String']['input']>;
  lastNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** legacy_id field predicates */
  legacyID?: InputMaybe<Scalars['Int']['input']>;
  legacyIDGT?: InputMaybe<Scalars['Int']['input']>;
  legacyIDGTE?: InputMaybe<Scalars['Int']['input']>;
  legacyIDIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  legacyIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  legacyIDLT?: InputMaybe<Scalars['Int']['input']>;
  legacyIDLTE?: InputMaybe<Scalars['Int']['input']>;
  legacyIDNEQ?: InputMaybe<Scalars['Int']['input']>;
  legacyIDNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  legacyIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<UserWhereInput>;
  or?: InputMaybe<Array<UserWhereInput>>;
  /** restriction_status field predicates */
  restrictionStatus?: InputMaybe<UserUserRestrictionStatus>;
  restrictionStatusIn?: InputMaybe<Array<UserUserRestrictionStatus>>;
  restrictionStatusNEQ?: InputMaybe<UserUserRestrictionStatus>;
  restrictionStatusNotIn?: InputMaybe<Array<UserUserRestrictionStatus>>;
  /** skip_return_buffer field predicates */
  skipReturnBuffer?: InputMaybe<Scalars['Boolean']['input']>;
  skipReturnBufferNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** update_time field predicates */
  updateTime?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeGTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updateTimeLT?: InputMaybe<Scalars['Time']['input']>;
  updateTimeLTE?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNEQ?: InputMaybe<Scalars['Time']['input']>;
  updateTimeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** username field predicates */
  username?: InputMaybe<Scalars['String']['input']>;
  usernameContains?: InputMaybe<Scalars['String']['input']>;
  usernameContainsFold?: InputMaybe<Scalars['String']['input']>;
  usernameEqualFold?: InputMaybe<Scalars['String']['input']>;
  usernameGT?: InputMaybe<Scalars['String']['input']>;
  usernameGTE?: InputMaybe<Scalars['String']['input']>;
  usernameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  usernameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  usernameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  usernameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  usernameLT?: InputMaybe<Scalars['String']['input']>;
  usernameLTE?: InputMaybe<Scalars['String']['input']>;
  usernameNEQ?: InputMaybe<Scalars['String']['input']>;
  usernameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  usernameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Visa = {
  __typename?: 'Visa';
  documentID?: Maybe<Scalars['String']['output']>;
  type?: Maybe<UserProfileUserVisaType>;
};

/** Watchlist returns user created lists of assets that are being monitored */
export type Watchlist = {
  __typename?: 'Watchlist';
  createTime: Scalars['Time']['output'];
  list: Array<BaseAsset>;
  name: Scalars['String']['output'];
  updateTime: Scalars['Time']['output'];
};

export enum WithholdingRateType {
  WithholdingRateTypeFixed = 'WITHHOLDING_RATE_TYPE_FIXED',
  WithholdingRateTypePctOfFederal = 'WITHHOLDING_RATE_TYPE_PCT_OF_FEDERAL',
}

export type GetAdminAchTransfersQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['Cursor']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  startDate?: InputMaybe<Scalars['Time']['input']>;
  endDate?: InputMaybe<Scalars['Time']['input']>;
  criteria?: InputMaybe<Scalars['String']['input']>;
  allFilters?: InputMaybe<Array<AchTransferWhereInput> | AchTransferWhereInput>;
  orderDirection: OrderDirection;
}>;

export type GetAdminAchTransfersQuery = {
  __typename?: 'Query';
  response: {
    __typename?: 'ACHTransferConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'ACHTransferEdge';
      cursor: string;
      node?: {
        __typename?: 'ACHTransfer';
        id: string;
        type: AchTransferTransferType;
        amount: any;
        status: AchTransferTransferStatus;
        orderType: OrderOrderType;
        transferFee?: any | null;
        instantDepositAmount?: any | null;
        orderStatus: OrderOrderStatus;
        isReturned: boolean;
        reason?: string | null;
        createTime: any;
        user: {
          __typename?: 'User';
          profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string } | null;
        };
        externalAccount: {
          __typename?: 'ExternalAccount';
          accountNumber: string;
          id: string;
          institution: { __typename?: 'Institution'; id: string; name: string };
        };
        portfolio: {
          __typename?: 'UserAccountPortfolio';
          account: {
            __typename?: 'UserAccount';
            externalID?: string | null;
            type: UserAccountAccountType;
          };
        };
        recurringRuleInstance?: {
          __typename?: 'RecurringRuleInstance';
          id: string;
          rule: { __typename?: 'RecurringRule'; id: string };
        } | null;
        statusChangeLog: Array<{
          __typename?: 'ACHTransferStatusChangeLog';
          status: AchTransferTransferStatus;
          eventTime: any;
          note: string;
        }>;
      } | null;
    } | null> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      endCursor?: string | null;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type GetAchTransferDetailsByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetAchTransferDetailsByIdQuery = {
  __typename?: 'Query';
  response: {
    __typename?: 'ACHTransferConnection';
    edges?: Array<{
      __typename?: 'ACHTransferEdge';
      node?: {
        __typename?: 'ACHTransfer';
        id: string;
        type: AchTransferTransferType;
        amount: any;
        status: AchTransferTransferStatus;
        orderStatus: OrderOrderStatus;
        orderType: OrderOrderType;
        transferFee?: any | null;
        note?: string | null;
        instantDepositAmount?: any | null;
        isReturned: boolean;
        reason?: string | null;
        externalID?: string | null;
        user: { __typename?: 'User'; id: string };
        requestDetails: {
          __typename?: 'RequestDetails';
          requestUUID?: any | null;
          requestor: Requestor;
          admin?: {
            __typename?: 'Admin';
            id: string;
            email: string;
            createTime: any;
            updateTime: any;
            externalID: string;
            firstName?: string | null;
            lastName?: string | null;
          } | null;
        };
        externalAccount: {
          __typename?: 'ExternalAccount';
          accountNumber: string;
          id: string;
          institution: { __typename?: 'Institution'; id: string; name: string };
        };
        portfolio: {
          __typename?: 'UserAccountPortfolio';
          name?: string | null;
          account: {
            __typename?: 'UserAccount';
            externalID?: string | null;
            type: UserAccountAccountType;
          };
          user: {
            __typename?: 'User';
            username?: string | null;
            profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string } | null;
          };
        };
        recurringRuleInstance?: {
          __typename?: 'RecurringRuleInstance';
          id: string;
          rule: { __typename?: 'RecurringRule'; id: string };
        } | null;
        statusChangeLog: Array<{
          __typename?: 'ACHTransferStatusChangeLog';
          status: AchTransferTransferStatus;
          eventTime: any;
          note: string;
        }>;
      } | null;
    } | null> | null;
  };
};

export type GetAdminUserAllAccountsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  accountFilter?: InputMaybe<UserAccountWhereInput>;
  discretionaryEnabled?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetAdminUserAllAccountsQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserConnection';
    edges?: Array<{
      __typename?: 'UserEdge';
      cursor: string;
      node?: {
        __typename?: 'User';
        username?: string | null;
        restrictionStatus: UserUserRestrictionStatus;
        profile?: {
          __typename?: 'UserProfile';
          firstName: string;
          lastName: string;
          email: string;
          dateOfBirth: any;
          phoneNumber: string;
          ssn?: string | null;
          streetAddress?: string | null;
          additionalStreetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          country?: any | null;
        } | null;
        accounts: {
          __typename?: 'UserAccountConnection';
          edges?: Array<{
            __typename?: 'UserAccountEdge';
            node?: {
              __typename?: 'UserAccount';
              type: UserAccountAccountType;
              portfolios: {
                __typename?: 'UserAccountPortfolioConnection';
                edges?: Array<{
                  __typename?: 'UserAccountPortfolioEdge';
                  node?: {
                    __typename?: 'UserAccountPortfolio';
                    id: string;
                    name?: string | null;
                    managementBy?: UserAccountPortfolioAccountManager | null;
                    totalValue: any;
                    fiat: { __typename?: 'AssetTypeTotal'; total: any };
                    security: { __typename?: 'AssetTypeTotal'; total: any };
                    crypto?: { __typename?: 'AssetTypeTotal'; total: any } | null;
                  } | null;
                } | null> | null;
              };
            } | null;
          } | null> | null;
        };
        roboGoals: {
          __typename?: 'RoboGoalConnection';
          edges?: Array<{
            __typename?: 'RoboGoalEdge';
            node?: {
              __typename?: 'RoboGoal';
              id: string;
              targetPortfolioID: string;
              accountPortfolioID: string;
              name: string;
              isActive: boolean;
              isAutoInfusionPaused: boolean;
              accountPortfolio: {
                __typename?: 'UserAccountPortfolio';
                totalValue: any;
                id: string;
                name?: string | null;
                account: { __typename?: 'UserAccount'; id: string; externalID?: string | null };
              };
              allocation: { __typename?: 'RoboAllocation'; totalValue: any };
              parameters?: {
                __typename?: 'RoboGoalParameters';
                targetAmount: any;
                riskProfile: UserRiskTolerance;
                goalPriority: RoboGoalParametersGoalPriority;
              } | null;
              projection?: { __typename?: 'RoboGoalProjection'; goalProbability: number } | null;
              fundingAccount?: { __typename?: 'ExternalAccount'; accountNumber: string } | null;
            } | null;
          } | null> | null;
        };
      } | null;
    } | null> | null;
  };
};

export type GetAdminAllAccountsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['Cursor']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  startDate?: InputMaybe<Scalars['Time']['input']>;
  endDate?: InputMaybe<Scalars['Time']['input']>;
  criteria?: InputMaybe<Scalars['String']['input']>;
  allFilters?: InputMaybe<Array<UserWhereInput> | UserWhereInput>;
  accountFilter?: InputMaybe<UserAccountWhereInput>;
  orderDirection?: InputMaybe<OrderDirection>;
}>;

export type GetAdminAllAccountsQuery = {
  __typename?: 'Query';
  response: {
    __typename?: 'UserConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'UserEdge';
      cursor: string;
      node?: {
        __typename?: 'User';
        id: string;
        createTime: any;
        accounts: {
          __typename?: 'UserAccountConnection';
          edges?: Array<{
            __typename?: 'UserAccountEdge';
            node?: {
              __typename?: 'UserAccount';
              type: UserAccountAccountType;
              status: UserAccountAccountStatus;
              portfolios: {
                __typename?: 'UserAccountPortfolioConnection';
                edges?: Array<{
                  __typename?: 'UserAccountPortfolioEdge';
                  node?: {
                    __typename?: 'UserAccountPortfolio';
                    id: string;
                    totalValue: any;
                    fiat: { __typename?: 'AssetTypeTotal'; total: any };
                    security: { __typename?: 'AssetTypeTotal'; total: any };
                  } | null;
                } | null> | null;
              };
            } | null;
          } | null> | null;
        };
        profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string } | null;
      } | null;
    } | null> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      endCursor?: string | null;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type GetAdminUserDiscretionaryAccountQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetAdminUserDiscretionaryAccountQuery = {
  __typename?: 'Query';
  userAccounts: {
    __typename?: 'UserAccountConnection';
    edges?: Array<{
      __typename?: 'UserAccountEdge';
      node?: {
        __typename?: 'UserAccount';
        id: string;
        externalID?: string | null;
        type: UserAccountAccountType;
        status: UserAccountAccountStatus;
      } | null;
    } | null> | null;
  };
};

export type GetAdminUserStocksAccountQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetAdminUserStocksAccountQuery = {
  __typename?: 'Query';
  userAccounts: {
    __typename?: 'UserAccountConnection';
    edges?: Array<{
      __typename?: 'UserAccountEdge';
      node?: {
        __typename?: 'UserAccount';
        id: string;
        externalID?: string | null;
        type: UserAccountAccountType;
        status: UserAccountAccountStatus;
      } | null;
    } | null> | null;
  };
};

export type GetApexRejectReasonsQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type GetApexRejectReasonsQuery = {
  __typename?: 'Query';
  response: {
    __typename?: 'ApexAccountRequestConnection';
    edges?: Array<{
      __typename?: 'ApexAccountRequestEdge';
      node?: {
        __typename?: 'ApexAccountRequest';
        id: string;
        requestID: string;
        externalRequestID?: string | null;
        createTime: any;
        request: any;
        response?: any | null;
        userAccount: {
          __typename?: 'UserAccount';
          id: string;
          user: { __typename?: 'User'; id: string; firstName: string };
        };
        apexAccountInvestigations?: Array<{
          __typename?: 'ApexAccountInvestigation';
          id: string;
          createTime: any;
          investigationStatus: ApexAccountInvestigationApexInvestigationStatus;
          detailsBase64?: string | null;
          apexInvestigationFiles?: Array<{
            __typename?: 'ApexInvestigationFile';
            externalID?: string | null;
            documentType: ApexInvestigationFileInvestigationDocumentsType;
            userDocument?: {
              __typename?: 'UserDocument';
              url: string;
              name?: string | null;
              mimeType: UserDocumentMimeType;
              documentType: UserDocumentUserDocumentType;
            } | null;
          }> | null;
          apexAccountMeta: {
            __typename?: 'ApexAccountMeta';
            id: string;
            holderType: ApexAccountMetaHolderType;
            applicationStatus: ApexAccountMetaApexApplicationStatus;
          };
        }> | null;
      } | null;
    } | null> | null;
  };
};

export type GetApexClearingDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetApexClearingDetailsQuery = {
  __typename?: 'Query';
  userAccounts: {
    __typename?: 'UserAccountConnection';
    edges?: Array<{
      __typename?: 'UserAccountEdge';
      node?: {
        __typename?: 'UserAccount';
        id: string;
        fpslEnrolled: boolean;
        cashSweepEnrolled: boolean;
        programEnrollmentHistory?: Array<{
          __typename?: 'ProgramEnrollmentHistory';
          id: string;
          electionPreference: ProgramEnrollmentHistoryProgramElectionPreference;
          approvalStatus: ProgramEnrollmentHistoryProgramApprovalStatus;
          programType: ProgramEnrollmentHistoryProgramType;
          reviewedAt?: any | null;
          reason?: string | null;
          submissionStatus: ProgramEnrollmentHistoryProgramSubmissionStatus;
          submittedAt?: any | null;
          createTime: any;
        }> | null;
      } | null;
    } | null> | null;
  };
};

export type CommonUserProfileRequestParametersFragment = {
  __typename?: 'UserProfileParameters';
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  dateOfBirth?: any | null;
  phoneNumber?: string | null;
  phoneNumberType?: UserProfileUserPhoneNumberType | null;
  ssn?: string | null;
  streetAddress?: string | null;
  additionalStreetAddress?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  country?: string | null;
  taxFilingInfo?: {
    __typename?: 'TaxFilingInfo';
    filingStatus?: UserFilingStatus | null;
    adjustGrossIncome?: number | null;
  } | null;
  controlPersonDetails?: {
    __typename: 'ControlPersonDetails';
    companySymbols?: Array<string> | null;
    isControlPerson: boolean;
  } | null;
  politicallyExposedDetails?: {
    __typename: 'PoliticallyExposedDetails';
    organizationName?: string | null;
    familyMembers?: Array<string> | null;
    isPoliticallyExposed: boolean;
  } | null;
  affiliatedExchangeOrFinraDetails?: {
    __typename: 'AffiliatedExchangeOrFINRADetails';
    firmName?: string | null;
    isAffiliated: boolean;
  } | null;
  marketDataAnswers?: {
    __typename: 'MarketDataAnswers';
    personalUse: boolean;
    gettingDataFromExternalSource: boolean;
    registeredWithSecOrCftc: boolean;
    requiresSecOrCftcRegistration: boolean;
    investForOther: boolean;
    investForTheBenefitOfOther: boolean;
    advisorOrConsultant: boolean;
    sharesProfit: boolean;
    registeredWithEntity: boolean;
  } | null;
};

export type CommonUserProfileDetailsFragment = {
  __typename?: 'UserProfile';
  firstName: string;
  lastName: string;
  ssn?: string | null;
  dateOfBirth: any;
  phoneNumber: string;
  email: string;
  streetAddress?: string | null;
  additionalStreetAddress?: string | null;
  city?: string | null;
  id: string;
  state?: string | null;
  zipCode?: string | null;
  country?: any | null;
  permanentResident?: boolean | null;
  visaType?: UserProfileUserVisaType | null;
  visaExpirationDate?: any | null;
  birthCountry?: { __typename?: 'Country'; name: string; alpha3Code: string } | null;
  citizenshipCountry?: { __typename?: 'Country'; name: string; alpha3Code: string } | null;
  updateRequests: {
    __typename?: 'UserProfileUpdateRequestConnection';
    edges?: Array<{
      __typename?: 'UserProfileUpdateRequestEdge';
      node?: {
        __typename?: 'UserProfileUpdateRequest';
        createTime: any;
        status: UserProfileUpdateRequestUpdateRequestStatus;
        requestParameters: {
          __typename?: 'UserProfileParameters';
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          dateOfBirth?: any | null;
          phoneNumber?: string | null;
          phoneNumberType?: UserProfileUserPhoneNumberType | null;
          ssn?: string | null;
          streetAddress?: string | null;
          additionalStreetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          country?: string | null;
          taxFilingInfo?: {
            __typename?: 'TaxFilingInfo';
            filingStatus?: UserFilingStatus | null;
            adjustGrossIncome?: number | null;
          } | null;
          controlPersonDetails?: {
            __typename: 'ControlPersonDetails';
            companySymbols?: Array<string> | null;
            isControlPerson: boolean;
          } | null;
          politicallyExposedDetails?: {
            __typename: 'PoliticallyExposedDetails';
            organizationName?: string | null;
            familyMembers?: Array<string> | null;
            isPoliticallyExposed: boolean;
          } | null;
          affiliatedExchangeOrFinraDetails?: {
            __typename: 'AffiliatedExchangeOrFINRADetails';
            firmName?: string | null;
            isAffiliated: boolean;
          } | null;
          marketDataAnswers?: {
            __typename: 'MarketDataAnswers';
            personalUse: boolean;
            gettingDataFromExternalSource: boolean;
            registeredWithSecOrCftc: boolean;
            requiresSecOrCftcRegistration: boolean;
            investForOther: boolean;
            investForTheBenefitOfOther: boolean;
            advisorOrConsultant: boolean;
            sharesProfit: boolean;
            registeredWithEntity: boolean;
          } | null;
        };
      } | null;
    } | null> | null;
  };
};

export type CommonUserInvestorInformationNonDiscretionaryRequestParametersDetailsFragment = {
  __typename?: 'BrokerageAccountParameters';
  annualIncome?: NonDiscretionaryAccountKycUserAnnualIncome | null;
  investmentExperience?: NonDiscretionaryAccountKycUserInvestmentExperience | null;
  investmentObjective?: NonDiscretionaryAccountKycUserInvestmentObjective | null;
  maritalStatus?: NonDiscretionaryAccountKycUserMaritalStatus | null;
  liquidityNeeds?: NonDiscretionaryAccountKycUserLiquidityNeeds | null;
  riskTolerance?: UserRiskTolerance | null;
  timeHorizon?: NonDiscretionaryAccountKycUserTimeHorizon | null;
  netWorth?: NonDiscretionaryAccountKycUserNetWorth | null;
  employmentStatus?: NonDiscretionaryAccountKycUserEmploymentStatus | null;
  employmentDetails?: {
    __typename?: 'EmploymentDetails';
    EmployerName: string;
    Position: string;
  } | null;
};

export type CommonUserInvestorInformationDiscretionaryRequestParametersDetailsFragment = {
  __typename?: 'BrokerageDiscretionaryAccountParameters';
  annualIncome?: DiscretionaryAccountKycUserAnnualIncome | null;
  investmentObjective?: DiscretionaryAccountKycUserInvestmentObjective | null;
  riskTolerance?: UserRiskTolerance | null;
  netWorth?: DiscretionaryAccountKycUserNetWorth | null;
  employmentStatus?: DiscretionaryAccountKycUserEmploymentStatus | null;
  employmentDetails?: {
    __typename?: 'EmploymentDetails';
    EmployerName: string;
    Position: string;
  } | null;
};

export type CommonTrustedContactRequestParametersDetailsFragment = {
  __typename?: 'TrustedContactParameters';
  firstName?: string | null;
  lastName?: string | null;
  dateOfBirth?: any | null;
  email?: string | null;
  phoneNumber?: string | null;
};

export type CommonTrustedContactUpdateRequestsDetailsFragment = {
  __typename?: 'TrustedContactUpdateRequest';
  createTime: any;
  status: TrustedContactUpdateRequestUpdateRequestStatus;
  requestParameters: {
    __typename?: 'TrustedContactParameters';
    firstName?: string | null;
    lastName?: string | null;
    dateOfBirth?: any | null;
    email?: string | null;
    phoneNumber?: string | null;
  };
};

export type CommonUserInvestorInformationNonDiscretionaryDetailsFragment = {
  __typename?: 'NonDiscretionaryAccountKyc';
  annualIncome?: NonDiscretionaryAccountKycUserAnnualIncome | null;
  investmentExperience?: NonDiscretionaryAccountKycUserInvestmentExperience | null;
  investmentObjective?: NonDiscretionaryAccountKycUserInvestmentObjective | null;
  maritalStatus?: NonDiscretionaryAccountKycUserMaritalStatus | null;
  liquidityNeeds?: NonDiscretionaryAccountKycUserLiquidityNeeds | null;
  riskTolerance?: UserRiskTolerance | null;
  timeHorizon?: NonDiscretionaryAccountKycUserTimeHorizon | null;
  netWorth?: NonDiscretionaryAccountKycUserNetWorth | null;
  employmentStatus?: NonDiscretionaryAccountKycUserEmploymentStatus | null;
  account?: Array<{ __typename?: 'UserAccount'; id: string; externalID?: string | null }> | null;
  employmentDetails?: {
    __typename?: 'EmploymentDetails';
    EmployerName: string;
    Position: string;
  } | null;
  updateRequests?: Array<{
    __typename?: 'NonDiscretionaryUpdateRequest';
    createTime: any;
    status: NonDiscretionaryUpdateRequestUpdateRequestStatus;
    requestParameters?: {
      __typename?: 'BrokerageAccountParameters';
      annualIncome?: NonDiscretionaryAccountKycUserAnnualIncome | null;
      investmentExperience?: NonDiscretionaryAccountKycUserInvestmentExperience | null;
      investmentObjective?: NonDiscretionaryAccountKycUserInvestmentObjective | null;
      maritalStatus?: NonDiscretionaryAccountKycUserMaritalStatus | null;
      liquidityNeeds?: NonDiscretionaryAccountKycUserLiquidityNeeds | null;
      riskTolerance?: UserRiskTolerance | null;
      timeHorizon?: NonDiscretionaryAccountKycUserTimeHorizon | null;
      netWorth?: NonDiscretionaryAccountKycUserNetWorth | null;
      employmentStatus?: NonDiscretionaryAccountKycUserEmploymentStatus | null;
      employmentDetails?: {
        __typename?: 'EmploymentDetails';
        EmployerName: string;
        Position: string;
      } | null;
    } | null;
  }> | null;
};

export type CommonUserInvestorInformationDiscretionaryDetailsFragment = {
  __typename?: 'DiscretionaryAccountKyc';
  annualIncome?: DiscretionaryAccountKycUserAnnualIncome | null;
  investmentObjective?: DiscretionaryAccountKycUserInvestmentObjective | null;
  riskTolerance?: UserRiskTolerance | null;
  netWorth?: DiscretionaryAccountKycUserNetWorth | null;
  employmentStatus?: DiscretionaryAccountKycUserEmploymentStatus | null;
  account?: Array<{ __typename?: 'UserAccount'; externalID?: string | null; id: string }> | null;
  employmentDetails?: {
    __typename?: 'EmploymentDetails';
    EmployerName: string;
    Position: string;
  } | null;
  updateRequests?: Array<{
    __typename?: 'DiscretionaryUpdateRequest';
    createTime: any;
    status: DiscretionaryUpdateRequestUpdateRequestStatus;
    requestParameters?: {
      __typename?: 'BrokerageDiscretionaryAccountParameters';
      annualIncome?: DiscretionaryAccountKycUserAnnualIncome | null;
      investmentObjective?: DiscretionaryAccountKycUserInvestmentObjective | null;
      riskTolerance?: UserRiskTolerance | null;
      netWorth?: DiscretionaryAccountKycUserNetWorth | null;
      employmentStatus?: DiscretionaryAccountKycUserEmploymentStatus | null;
      employmentDetails?: {
        __typename?: 'EmploymentDetails';
        EmployerName: string;
        Position: string;
      } | null;
    } | null;
  }> | null;
};

export type CommonUserAccountDetailsFragmentFragment = {
  __typename?: 'UserAccount';
  id: string;
  type: UserAccountAccountType;
  externalID?: string | null;
  createTime: any;
  status: UserAccountAccountStatus;
  user: {
    __typename?: 'User';
    id: string;
    profile?: {
      __typename?: 'UserProfile';
      firstName: string;
      lastName: string;
      ssn?: string | null;
      email: string;
      phoneNumber: string;
      country?: any | null;
      state?: string | null;
      city?: string | null;
      streetAddress?: string | null;
      additionalStreetAddress?: string | null;
      zipCode?: string | null;
    } | null;
  };
  portfolios: {
    __typename?: 'UserAccountPortfolioConnection';
    edges?: Array<{
      __typename?: 'UserAccountPortfolioEdge';
      node?: {
        __typename?: 'UserAccountPortfolio';
        id: string;
        isDefault?: boolean | null;
        assetBalances: {
          __typename?: 'UserAccountPortfolioAssetConnection';
          edges?: Array<{
            __typename?: 'UserAccountPortfolioAssetEdge';
            node?: {
              __typename?: 'UserAccountPortfolioAsset';
              asset?:
                | { __typename?: 'CryptoAsset'; symbol: string }
                | { __typename?: 'FiatAsset'; symbol: string }
                | { __typename?: 'SecurityAsset'; symbol: string }
                | null;
              balances?: { __typename?: 'UserAccountPortfolioAssetBalance'; value: any } | null;
            } | null;
          } | null> | null;
        };
      } | null;
    } | null> | null;
  };
  adminActions?: Array<{
    __typename?: 'AdminAction';
    adminFirstName?: string | null;
    adminLastName?: string | null;
  }> | null;
};

export type GetLiquidationDebtsFragmentFragment = {
  __typename?: 'Liability';
  id: string;
  createTime: any;
  owedSince: any;
  amountOwed: any;
  amountPaid: any;
  amountRemaining: any;
  account?: {
    __typename?: 'UserAccount';
    id: string;
    externalID?: string | null;
    adminActions?: Array<{
      __typename?: 'AdminAction';
      adminFirstName?: string | null;
      adminLastName?: string | null;
    }> | null;
    user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
  } | null;
};

export type GetUserAccountPortfolioEdgeFragmentFragment = {
  __typename?: 'UserAccountPortfolioEdge';
  node?: {
    __typename?: 'UserAccountPortfolio';
    id: string;
    account: { __typename?: 'UserAccount'; externalID?: string | null };
    assetBalances: {
      __typename?: 'UserAccountPortfolioAssetConnection';
      edges?: Array<{
        __typename?: 'UserAccountPortfolioAssetEdge';
        node?: {
          __typename?: 'UserAccountPortfolioAsset';
          asset?:
            | {
                __typename?: 'CryptoAsset';
                id: string;
                symbol: string;
                name: string;
                createTime: any;
              }
            | {
                __typename?: 'FiatAsset';
                id: string;
                symbol: string;
                name: string;
                createTime: any;
              }
            | {
                __typename?: 'SecurityAsset';
                id: string;
                symbol: string;
                name: string;
                createTime: any;
                market?: { __typename?: 'AssetMarketData'; last: any } | null;
              }
            | null;
          balances?: {
            __typename?: 'UserAccountPortfolioAssetBalance';
            value: any;
            activeBalance: any;
          } | null;
        } | null;
      } | null> | null;
    };
  } | null;
};

export type GetLiquidationDebtsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['Cursor']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  criteria?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Time']['input']>;
  endDate?: InputMaybe<Scalars['Time']['input']>;
  allFilters?: InputMaybe<Array<LiabilityWhereInput> | LiabilityWhereInput>;
  orderDirection: OrderDirection;
}>;

export type GetLiquidationDebtsQuery = {
  __typename?: 'Query';
  response: {
    __typename?: 'LiabilityConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'LiabilityEdge';
      cursor: string;
      node?: {
        __typename?: 'Liability';
        id: string;
        createTime: any;
        owedSince: any;
        amountOwed: any;
        amountPaid: any;
        amountRemaining: any;
        account?: {
          __typename?: 'UserAccount';
          id: string;
          externalID?: string | null;
          adminActions?: Array<{
            __typename?: 'AdminAction';
            adminFirstName?: string | null;
            adminLastName?: string | null;
          }> | null;
          user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
        } | null;
      } | null;
    } | null> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      endCursor?: string | null;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type GetLiquidationDebtsByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetLiquidationDebtsByIdQuery = {
  __typename?: 'Query';
  liabilities: {
    __typename?: 'LiabilityConnection';
    edges?: Array<{
      __typename?: 'LiabilityEdge';
      node?: {
        __typename?: 'Liability';
        id: string;
        createTime: any;
        owedSince: any;
        amountOwed: any;
        amountPaid: any;
        amountRemaining: any;
        holdings?: {
          __typename?: 'UserAccount';
          portfolios: {
            __typename?: 'UserAccountPortfolioConnection';
            edges?: Array<{
              __typename?: 'UserAccountPortfolioEdge';
              node?: {
                __typename?: 'UserAccountPortfolio';
                id: string;
                account: { __typename?: 'UserAccount'; externalID?: string | null };
                assetBalances: {
                  __typename?: 'UserAccountPortfolioAssetConnection';
                  edges?: Array<{
                    __typename?: 'UserAccountPortfolioAssetEdge';
                    node?: {
                      __typename?: 'UserAccountPortfolioAsset';
                      asset?:
                        | {
                            __typename?: 'CryptoAsset';
                            id: string;
                            symbol: string;
                            name: string;
                            createTime: any;
                          }
                        | {
                            __typename?: 'FiatAsset';
                            id: string;
                            symbol: string;
                            name: string;
                            createTime: any;
                          }
                        | {
                            __typename?: 'SecurityAsset';
                            id: string;
                            symbol: string;
                            name: string;
                            createTime: any;
                            market?: { __typename?: 'AssetMarketData'; last: any } | null;
                          }
                        | null;
                      balances?: {
                        __typename?: 'UserAccountPortfolioAssetBalance';
                        value: any;
                        activeBalance: any;
                      } | null;
                    } | null;
                  } | null> | null;
                };
              } | null;
            } | null> | null;
          };
        } | null;
        account?: {
          __typename?: 'UserAccount';
          id: string;
          externalID?: string | null;
          adminActions?: Array<{
            __typename?: 'AdminAction';
            adminFirstName?: string | null;
            adminLastName?: string | null;
          }> | null;
          user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
        } | null;
      } | null;
    } | null> | null;
  };
};

export type GetAdminExternalAccountsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['Cursor']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  startDate?: InputMaybe<Scalars['Time']['input']>;
  endDate?: InputMaybe<Scalars['Time']['input']>;
  criteria?: InputMaybe<Scalars['String']['input']>;
  allFilters?: InputMaybe<Array<ExternalAccountWhereInput> | ExternalAccountWhereInput>;
}>;

export type GetAdminExternalAccountsQuery = {
  __typename?: 'Query';
  response: {
    __typename?: 'ExternalAccountConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'ExternalAccountEdge';
      node?: {
        __typename?: 'ExternalAccount';
        id: string;
        accountNumber: string;
        brokerageCanUse: boolean;
        advisoryCanUse: boolean;
        connectionStatus: ExternalAccountConnectionStatus;
        mask: string;
        createTime: any;
        updateTime: any;
        user: { __typename?: 'User'; firstName: string; lastName: string };
        connectionDetails?:
          | { __typename?: 'ExternalAccountConnectionDetailsICT' }
          | {
              __typename?: 'ExternalAccountConnectionDetailsPlaid';
              bankOwnerName?: Array<string> | null;
            }
          | null;
        institution: { __typename?: 'Institution'; id: string; name: string };
      } | null;
    } | null> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      endCursor?: string | null;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type GetAdminFeeAndCreditHistoryQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['Cursor']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  orderDirection?: InputMaybe<OrderDirection>;
  startDate?: InputMaybe<Scalars['Time']['input']>;
  endDate?: InputMaybe<Scalars['Time']['input']>;
  criteria?: InputMaybe<Scalars['String']['input']>;
  allFilters?: InputMaybe<Array<FeeAndCreditOrderWhereInput> | FeeAndCreditOrderWhereInput>;
}>;

export type GetAdminFeeAndCreditHistoryQuery = {
  __typename?: 'Query';
  response: {
    __typename?: 'FeeAndCreditOrderConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'FeeAndCreditOrderEdge';
      cursor: string;
      node?: {
        __typename?: 'FeeAndCreditOrder';
        id: string;
        submissionID?: string | null;
        type: FeeAndCreditOrderFeeAndCreditType;
        orderType: OrderOrderType;
        amount: any;
        status: FeeAndCreditOrderFeeAndCreditStatus;
        orderStatus: OrderOrderStatus;
        createTime: any;
        submission?: { __typename?: 'FeeAndCreditSubmission'; externalID?: string | null } | null;
        portfolio: {
          __typename?: 'UserAccountPortfolio';
          user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
          account: {
            __typename?: 'UserAccount';
            externalID?: string | null;
            apexAccountMeta?: {
              __typename?: 'ApexAccountMeta';
              externalRequestID?: string | null;
            } | null;
          };
        };
      } | null;
    } | null> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      endCursor?: string | null;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type GetUserAllocationsByRoboIdQueryVariables = Exact<{
  roboID: Scalars['ID']['input'];
}>;

export type GetUserAllocationsByRoboIdQuery = {
  __typename?: 'Query';
  roboGoals: {
    __typename?: 'RoboGoalConnection';
    edges?: Array<{
      __typename?: 'RoboGoalEdge';
      node?: {
        __typename?: 'RoboGoal';
        parameters?: { __typename?: 'RoboGoalParameters'; riskProfile: UserRiskTolerance } | null;
        allocation: {
          __typename?: 'RoboAllocation';
          fiatPct: any;
          stockPct: any;
          bondPct: any;
          totalValue: any;
          drift: number;
          allocationBySector: Array<{
            __typename?: 'RoboAllocationSector';
            percentage: any;
            sector: {
              __typename?: 'ModelPortfolioSector';
              id: string;
              name: string;
              category: ModelPortfolioSectorCategory;
              assets: Array<{
                __typename?: 'ModelPortfolioAssetComposition';
                targetPct: any;
                asset:
                  | { __typename?: 'CryptoAsset'; id: string; symbol: string }
                  | { __typename?: 'FiatAsset'; id: string; symbol: string }
                  | { __typename?: 'SecurityAsset'; id: string; symbol: string };
              }>;
            };
          }>;
        };
        targetPortfolio: {
          __typename?: 'ModelPortfolio';
          bondPct: any;
          fiatPct: any;
          stockPct: any;
          externalReferenceID?: string | null;
          id: string;
          sectors?: Array<{
            __typename?: 'ModelPortfolioSectorComposition';
            id: string;
            targetPct: any;
            sector: {
              __typename?: 'ModelPortfolioSector';
              id: string;
              name: string;
              description: string;
              category: ModelPortfolioSectorCategory;
              assets: Array<{
                __typename?: 'ModelPortfolioAssetComposition';
                targetPct: any;
                asset:
                  | {
                      __typename?: 'CryptoAsset';
                      imageLogo?: string | null;
                      id: string;
                      symbol: string;
                      name: string;
                    }
                  | {
                      __typename?: 'FiatAsset';
                      imageLogo?: string | null;
                      id: string;
                      symbol: string;
                      name: string;
                    }
                  | {
                      __typename?: 'SecurityAsset';
                      imageLogo?: string | null;
                      id: string;
                      symbol: string;
                      name: string;
                    };
              }>;
            };
          }> | null;
        };
        accountPortfolio: {
          __typename?: 'UserAccountPortfolio';
          totalValue: any;
          id: string;
          assetBalances: {
            __typename?: 'UserAccountPortfolioAssetConnection';
            edges?: Array<{
              __typename?: 'UserAccountPortfolioAssetEdge';
              node?: {
                __typename?: 'UserAccountPortfolioAsset';
                costBasis: any;
                balances?: {
                  __typename?: 'UserAccountPortfolioAssetBalance';
                  activeBalance: any;
                  value: any;
                  total: any;
                } | null;
                asset?:
                  | { __typename?: 'CryptoAsset'; id: string; name: string; symbol: string }
                  | { __typename?: 'FiatAsset'; id: string; name: string; symbol: string }
                  | {
                      __typename?: 'SecurityAsset';
                      id: string;
                      name: string;
                      symbol: string;
                      market?: {
                        __typename?: 'AssetMarketData';
                        last: any;
                        previousDayClose: any;
                      } | null;
                    }
                  | null;
              } | null;
            } | null> | null;
          };
        };
      } | null;
    } | null> | null;
  };
  roboGeneralInvestments: {
    __typename?: 'RoboGeneralInvestmentConnection';
    edges?: Array<{
      __typename?: 'RoboGeneralInvestmentEdge';
      node?: {
        __typename?: 'RoboGeneralInvestment';
        allocation: {
          __typename?: 'RoboAllocation';
          fiatPct: any;
          stockPct: any;
          bondPct: any;
          totalValue: any;
          drift: number;
          allocationBySector: Array<{
            __typename?: 'RoboAllocationSector';
            percentage: any;
            sector: {
              __typename?: 'ModelPortfolioSector';
              id: string;
              name: string;
              category: ModelPortfolioSectorCategory;
              assets: Array<{
                __typename?: 'ModelPortfolioAssetComposition';
                targetPct: any;
                asset:
                  | { __typename?: 'CryptoAsset'; id: string; symbol: string }
                  | { __typename?: 'FiatAsset'; id: string; symbol: string }
                  | { __typename?: 'SecurityAsset'; id: string; symbol: string };
              }>;
            };
          }>;
        };
        targetPortfolio: {
          __typename?: 'ModelPortfolio';
          bondPct: any;
          fiatPct: any;
          stockPct: any;
          externalReferenceID?: string | null;
          id: string;
          sectors?: Array<{
            __typename?: 'ModelPortfolioSectorComposition';
            id: string;
            targetPct: any;
            sector: {
              __typename?: 'ModelPortfolioSector';
              id: string;
              name: string;
              description: string;
              category: ModelPortfolioSectorCategory;
              assets: Array<{
                __typename?: 'ModelPortfolioAssetComposition';
                targetPct: any;
                asset:
                  | {
                      __typename?: 'CryptoAsset';
                      imageLogo?: string | null;
                      id: string;
                      symbol: string;
                      name: string;
                    }
                  | {
                      __typename?: 'FiatAsset';
                      imageLogo?: string | null;
                      id: string;
                      symbol: string;
                      name: string;
                    }
                  | {
                      __typename?: 'SecurityAsset';
                      imageLogo?: string | null;
                      id: string;
                      symbol: string;
                      name: string;
                    };
              }>;
            };
          }> | null;
        };
        accountPortfolio: {
          __typename?: 'UserAccountPortfolio';
          totalValue: any;
          id: string;
          assetBalances: {
            __typename?: 'UserAccountPortfolioAssetConnection';
            edges?: Array<{
              __typename?: 'UserAccountPortfolioAssetEdge';
              node?: {
                __typename?: 'UserAccountPortfolioAsset';
                costBasis: any;
                balances?: {
                  __typename?: 'UserAccountPortfolioAssetBalance';
                  activeBalance: any;
                  value: any;
                  total: any;
                } | null;
                asset?:
                  | { __typename?: 'CryptoAsset'; id: string; name: string; symbol: string }
                  | { __typename?: 'FiatAsset'; id: string; name: string; symbol: string }
                  | {
                      __typename?: 'SecurityAsset';
                      id: string;
                      name: string;
                      symbol: string;
                      market?: {
                        __typename?: 'AssetMarketData';
                        last: any;
                        previousDayClose: any;
                      } | null;
                    }
                  | null;
              } | null;
            } | null> | null;
          };
        };
      } | null;
    } | null> | null;
  };
};

export type GetUserStocksHoldingsQueryVariables = Exact<{
  portfolioID: Scalars['ID']['input'];
}>;

export type GetUserStocksHoldingsQuery = {
  __typename?: 'Query';
  userAccountPortfolios: {
    __typename?: 'UserAccountPortfolioConnection';
    edges?: Array<{
      __typename?: 'UserAccountPortfolioEdge';
      node?: {
        __typename?: 'UserAccountPortfolio';
        id: string;
        totalValue: any;
        account: { __typename?: 'UserAccount'; id: string };
        assetBalances: {
          __typename?: 'UserAccountPortfolioAssetConnection';
          edges?: Array<{
            __typename?: 'UserAccountPortfolioAssetEdge';
            node?: {
              __typename?: 'UserAccountPortfolioAsset';
              costBasis: any;
              balances?: {
                __typename?: 'UserAccountPortfolioAssetBalance';
                activeBalance: any;
                value: any;
                total: any;
                tradeable: any;
                liability: any;
              } | null;
              asset?:
                | {
                    __typename?: 'CryptoAsset';
                    id: string;
                    type: AssetAssetType;
                    name: string;
                    symbol: string;
                  }
                | {
                    __typename?: 'FiatAsset';
                    id: string;
                    type: AssetAssetType;
                    name: string;
                    symbol: string;
                  }
                | {
                    __typename?: 'SecurityAsset';
                    name: string;
                    imageLogo?: string | null;
                    securityType: SecurityAssetSecurityType;
                    id: string;
                    type: AssetAssetType;
                    symbol: string;
                    market?: {
                      __typename?: 'AssetMarketData';
                      last: any;
                      previousDayClose: any;
                    } | null;
                  }
                | null;
            } | null;
          } | null> | null;
        };
      } | null;
    } | null> | null;
  };
};

type GetSecurityLiquidationFragment_AchTransfer_Fragment = {
  __typename?: 'ACHTransfer';
  id: string;
  orderType: OrderOrderType;
  orderStatus: OrderOrderStatus;
  createTime: any;
  requestDetails: {
    __typename?: 'RequestDetails';
    requestor: Requestor;
    admin?: {
      __typename?: 'Admin';
      id: string;
      createTime: any;
      updateTime: any;
      externalID: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  };
};

type GetSecurityLiquidationFragment_AcatTransfer_Fragment = {
  __typename?: 'AcatTransfer';
  id: string;
  orderType: OrderOrderType;
  orderStatus: OrderOrderStatus;
  createTime: any;
  requestDetails: {
    __typename?: 'RequestDetails';
    requestor: Requestor;
    admin?: {
      __typename?: 'Admin';
      id: string;
      createTime: any;
      updateTime: any;
      externalID: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  };
};

type GetSecurityLiquidationFragment_AccountReconAdjustment_Fragment = {
  __typename?: 'AccountReconAdjustment';
  id: string;
  orderType: OrderOrderType;
  orderStatus: OrderOrderStatus;
  createTime: any;
  requestDetails: {
    __typename?: 'RequestDetails';
    requestor: Requestor;
    admin?: {
      __typename?: 'Admin';
      id: string;
      createTime: any;
      updateTime: any;
      externalID: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  };
};

type GetSecurityLiquidationFragment_AdminOrder_Fragment = {
  __typename?: 'AdminOrder';
  id: string;
  orderType: OrderOrderType;
  orderStatus: OrderOrderStatus;
  createTime: any;
  requestDetails: {
    __typename?: 'RequestDetails';
    requestor: Requestor;
    admin?: {
      __typename?: 'Admin';
      id: string;
      createTime: any;
      updateTime: any;
      externalID: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  };
};

type GetSecurityLiquidationFragment_CapitalGains_Fragment = {
  __typename?: 'CapitalGains';
  id: string;
  orderType: OrderOrderType;
  orderStatus: OrderOrderStatus;
  createTime: any;
  requestDetails: {
    __typename?: 'RequestDetails';
    requestor: Requestor;
    admin?: {
      __typename?: 'Admin';
      id: string;
      createTime: any;
      updateTime: any;
      externalID: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  };
};

type GetSecurityLiquidationFragment_CapitalGainsDistribution_Fragment = {
  __typename?: 'CapitalGainsDistribution';
  id: string;
  orderType: OrderOrderType;
  orderStatus: OrderOrderStatus;
  createTime: any;
  requestDetails: {
    __typename?: 'RequestDetails';
    requestor: Requestor;
    admin?: {
      __typename?: 'Admin';
      id: string;
      createTime: any;
      updateTime: any;
      externalID: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  };
};

type GetSecurityLiquidationFragment_CheckTransfer_Fragment = {
  __typename?: 'CheckTransfer';
  id: string;
  orderType: OrderOrderType;
  orderStatus: OrderOrderStatus;
  createTime: any;
  requestDetails: {
    __typename?: 'RequestDetails';
    requestor: Requestor;
    admin?: {
      __typename?: 'Admin';
      id: string;
      createTime: any;
      updateTime: any;
      externalID: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  };
};

type GetSecurityLiquidationFragment_CorporateActionModification_Fragment = {
  __typename?: 'CorporateActionModification';
  id: string;
  orderType: OrderOrderType;
  orderStatus: OrderOrderStatus;
  createTime: any;
  requestDetails: {
    __typename?: 'RequestDetails';
    requestor: Requestor;
    admin?: {
      __typename?: 'Admin';
      id: string;
      createTime: any;
      updateTime: any;
      externalID: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  };
};

type GetSecurityLiquidationFragment_DividendDistribution_Fragment = {
  __typename?: 'DividendDistribution';
  id: string;
  orderType: OrderOrderType;
  orderStatus: OrderOrderStatus;
  createTime: any;
  requestDetails: {
    __typename?: 'RequestDetails';
    requestor: Requestor;
    admin?: {
      __typename?: 'Admin';
      id: string;
      createTime: any;
      updateTime: any;
      externalID: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  };
};

type GetSecurityLiquidationFragment_FeeAndCreditOrder_Fragment = {
  __typename?: 'FeeAndCreditOrder';
  amount: any;
  note?: string | null;
  id: string;
  orderType: OrderOrderType;
  orderStatus: OrderOrderStatus;
  createTime: any;
  portfolio: {
    __typename?: 'UserAccountPortfolio';
    user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
    account: { __typename?: 'UserAccount'; externalID?: string | null };
  };
  requestDetails: {
    __typename?: 'RequestDetails';
    requestor: Requestor;
    admin?: {
      __typename?: 'Admin';
      id: string;
      createTime: any;
      updateTime: any;
      externalID: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  };
};

type GetSecurityLiquidationFragment_IctTransfer_Fragment = {
  __typename?: 'ICTTransfer';
  id: string;
  orderType: OrderOrderType;
  orderStatus: OrderOrderStatus;
  createTime: any;
  requestDetails: {
    __typename?: 'RequestDetails';
    requestor: Requestor;
    admin?: {
      __typename?: 'Admin';
      id: string;
      createTime: any;
      updateTime: any;
      externalID: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  };
};

type GetSecurityLiquidationFragment_InterestDistribution_Fragment = {
  __typename?: 'InterestDistribution';
  id: string;
  orderType: OrderOrderType;
  orderStatus: OrderOrderStatus;
  createTime: any;
  requestDetails: {
    __typename?: 'RequestDetails';
    requestor: Requestor;
    admin?: {
      __typename?: 'Admin';
      id: string;
      createTime: any;
      updateTime: any;
      externalID: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  };
};

type GetSecurityLiquidationFragment_Liquidation_Fragment = {
  __typename?: 'Liquidation';
  totalLiquidatedAmount: any;
  note?: string | null;
  id: string;
  orderType: OrderOrderType;
  orderStatus: OrderOrderStatus;
  createTime: any;
  user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
  liquidationPortfolio: {
    __typename?: 'UserAccountPortfolio';
    account: { __typename?: 'UserAccount'; externalID?: string | null };
  };
  requestDetails: {
    __typename?: 'RequestDetails';
    requestor: Requestor;
    admin?: {
      __typename?: 'Admin';
      id: string;
      createTime: any;
      updateTime: any;
      externalID: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  };
};

type GetSecurityLiquidationFragment_PortfolioLedger_Fragment = {
  __typename?: 'PortfolioLedger';
  id: string;
  orderType: OrderOrderType;
  orderStatus: OrderOrderStatus;
  createTime: any;
  requestDetails: {
    __typename?: 'RequestDetails';
    requestor: Requestor;
    admin?: {
      __typename?: 'Admin';
      id: string;
      createTime: any;
      updateTime: any;
      externalID: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  };
};

type GetSecurityLiquidationFragment_PortfolioLiquidation_Fragment = {
  __typename?: 'PortfolioLiquidation';
  id: string;
  orderType: OrderOrderType;
  orderStatus: OrderOrderStatus;
  createTime: any;
  requestDetails: {
    __typename?: 'RequestDetails';
    requestor: Requestor;
    admin?: {
      __typename?: 'Admin';
      id: string;
      createTime: any;
      updateTime: any;
      externalID: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  };
};

type GetSecurityLiquidationFragment_RecurringRuleInstance_Fragment = {
  __typename?: 'RecurringRuleInstance';
  id: string;
  orderType: OrderOrderType;
  orderStatus: OrderOrderStatus;
  createTime: any;
  requestDetails: {
    __typename?: 'RequestDetails';
    requestor: Requestor;
    admin?: {
      __typename?: 'Admin';
      id: string;
      createTime: any;
      updateTime: any;
      externalID: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  };
};

type GetSecurityLiquidationFragment_ReturnOfCapital_Fragment = {
  __typename?: 'ReturnOfCapital';
  id: string;
  orderType: OrderOrderType;
  orderStatus: OrderOrderStatus;
  createTime: any;
  requestDetails: {
    __typename?: 'RequestDetails';
    requestor: Requestor;
    admin?: {
      __typename?: 'Admin';
      id: string;
      createTime: any;
      updateTime: any;
      externalID: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  };
};

type GetSecurityLiquidationFragment_ReturnOfCapitalDistribution_Fragment = {
  __typename?: 'ReturnOfCapitalDistribution';
  id: string;
  orderType: OrderOrderType;
  orderStatus: OrderOrderStatus;
  createTime: any;
  requestDetails: {
    __typename?: 'RequestDetails';
    requestor: Requestor;
    admin?: {
      __typename?: 'Admin';
      id: string;
      createTime: any;
      updateTime: any;
      externalID: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  };
};

type GetSecurityLiquidationFragment_Trade_Fragment = {
  __typename?: 'Trade';
  id: string;
  orderType: OrderOrderType;
  orderStatus: OrderOrderStatus;
  createTime: any;
  requestDetails: {
    __typename?: 'RequestDetails';
    requestor: Requestor;
    admin?: {
      __typename?: 'Admin';
      id: string;
      createTime: any;
      updateTime: any;
      externalID: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  };
};

export type GetSecurityLiquidationFragmentFragment =
  | GetSecurityLiquidationFragment_AchTransfer_Fragment
  | GetSecurityLiquidationFragment_AcatTransfer_Fragment
  | GetSecurityLiquidationFragment_AccountReconAdjustment_Fragment
  | GetSecurityLiquidationFragment_AdminOrder_Fragment
  | GetSecurityLiquidationFragment_CapitalGains_Fragment
  | GetSecurityLiquidationFragment_CapitalGainsDistribution_Fragment
  | GetSecurityLiquidationFragment_CheckTransfer_Fragment
  | GetSecurityLiquidationFragment_CorporateActionModification_Fragment
  | GetSecurityLiquidationFragment_DividendDistribution_Fragment
  | GetSecurityLiquidationFragment_FeeAndCreditOrder_Fragment
  | GetSecurityLiquidationFragment_IctTransfer_Fragment
  | GetSecurityLiquidationFragment_InterestDistribution_Fragment
  | GetSecurityLiquidationFragment_Liquidation_Fragment
  | GetSecurityLiquidationFragment_PortfolioLedger_Fragment
  | GetSecurityLiquidationFragment_PortfolioLiquidation_Fragment
  | GetSecurityLiquidationFragment_RecurringRuleInstance_Fragment
  | GetSecurityLiquidationFragment_ReturnOfCapital_Fragment
  | GetSecurityLiquidationFragment_ReturnOfCapitalDistribution_Fragment
  | GetSecurityLiquidationFragment_Trade_Fragment;

export type GetAdminSecurityLiquidationsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['Cursor']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  criteria?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Time']['input']>;
  endDate?: InputMaybe<Scalars['Time']['input']>;
  allFilters?: InputMaybe<Array<OrderWhereInput> | OrderWhereInput>;
  orderDirection: OrderDirection;
}>;

export type GetAdminSecurityLiquidationsQuery = {
  __typename?: 'Query';
  response: {
    __typename?: 'BaseOrderConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'BaseOrderEdge';
      cursor: string;
      node:
        | {
            __typename?: 'ACHTransfer';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'AcatTransfer';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'AccountReconAdjustment';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'AdminOrder';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'CapitalGains';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'CapitalGainsDistribution';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'CheckTransfer';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'CorporateActionModification';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'DividendDistribution';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'FeeAndCreditOrder';
            amount: any;
            note?: string | null;
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            portfolio: {
              __typename?: 'UserAccountPortfolio';
              user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
              account: { __typename?: 'UserAccount'; externalID?: string | null };
            };
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'ICTTransfer';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'InterestDistribution';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'Liquidation';
            totalLiquidatedAmount: any;
            note?: string | null;
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
            liquidationPortfolio: {
              __typename?: 'UserAccountPortfolio';
              account: { __typename?: 'UserAccount'; externalID?: string | null };
            };
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'PortfolioLedger';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'PortfolioLiquidation';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'RecurringRuleInstance';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'ReturnOfCapital';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'ReturnOfCapitalDistribution';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'Trade';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          };
    } | null> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      endCursor?: string | null;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type GetAdminSecurityLiquidationByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetAdminSecurityLiquidationByIdQuery = {
  __typename?: 'Query';
  orders: {
    __typename?: 'BaseOrderConnection';
    edges?: Array<{
      __typename?: 'BaseOrderEdge';
      node:
        | {
            __typename?: 'ACHTransfer';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'AcatTransfer';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'AccountReconAdjustment';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'AdminOrder';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'CapitalGains';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'CapitalGainsDistribution';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'CheckTransfer';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'CorporateActionModification';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'DividendDistribution';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'FeeAndCreditOrder';
            id: string;
            amount: any;
            note?: string | null;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            portfolio: {
              __typename?: 'UserAccountPortfolio';
              user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
              account: { __typename?: 'UserAccount'; externalID?: string | null };
            };
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'ICTTransfer';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'InterestDistribution';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'Liquidation';
            id: string;
            totalLiquidatedAmount: any;
            note?: string | null;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            trades?: Array<{
              __typename?: 'Trade';
              id: string;
              type: TradeTradeType;
              createTime: any;
              side: TradeMarketSide;
              status: TradeTradeStatus;
              orderStatus: OrderOrderStatus;
              baseAmount?: any | null;
              quoteAmount?: any | null;
              requestAmount: any;
              requestInQuote: boolean;
              fromLiquidation: boolean;
              base:
                | { __typename?: 'CryptoAsset'; symbol: string; name: string }
                | { __typename?: 'FiatAsset'; symbol: string; name: string }
                | { __typename?: 'SecurityAsset'; symbol: string; name: string };
              quote:
                | { __typename?: 'CryptoAsset'; symbol: string; name: string }
                | { __typename?: 'FiatAsset'; symbol: string; name: string }
                | { __typename?: 'SecurityAsset'; symbol: string; name: string };
            }> | null;
            user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
            liquidationPortfolio: {
              __typename?: 'UserAccountPortfolio';
              account: { __typename?: 'UserAccount'; externalID?: string | null };
            };
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'PortfolioLedger';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'PortfolioLiquidation';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'RecurringRuleInstance';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'ReturnOfCapital';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'ReturnOfCapitalDistribution';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          }
        | {
            __typename?: 'Trade';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            createTime: any;
            requestDetails: {
              __typename?: 'RequestDetails';
              requestor: Requestor;
              admin?: {
                __typename?: 'Admin';
                id: string;
                createTime: any;
                updateTime: any;
                externalID: string;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            };
          };
    } | null> | null;
  };
};

export type GetAdminRecurringRulesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['Cursor']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  criteria?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Time']['input']>;
  endDate?: InputMaybe<Scalars['Time']['input']>;
  allFilters?: InputMaybe<Array<RecurringRuleWhereInput> | RecurringRuleWhereInput>;
}>;

export type GetAdminRecurringRulesQuery = {
  __typename?: 'Query';
  response: {
    __typename?: 'RecurringRuleConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'RecurringRuleEdge';
      node?: {
        __typename?: 'RecurringRule';
        id: string;
        amount: any;
        type: RecurringRuleType;
        interval: RecurringRuleRecurringRuleInterval;
        isActive: boolean;
        onHold: boolean;
        createTime: any;
        user: {
          __typename?: 'User';
          profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string } | null;
        };
        portfolio: {
          __typename?: 'UserAccountPortfolio';
          account: { __typename?: 'UserAccount'; type: UserAccountAccountType };
        };
        asset:
          | { __typename?: 'CryptoAsset'; symbol: string }
          | { __typename?: 'FiatAsset'; symbol: string }
          | { __typename?: 'SecurityAsset'; symbol: string };
      } | null;
    } | null> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      endCursor?: string | null;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type GetAdminRecurringRuleDetailByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetAdminRecurringRuleDetailByIdQuery = {
  __typename?: 'Query';
  recurringRules: {
    __typename?: 'RecurringRuleConnection';
    edges?: Array<{
      __typename?: 'RecurringRuleEdge';
      node?: {
        __typename?: 'RecurringRule';
        id: string;
        isActive: boolean;
        requestor: RecurringRuleRequestor;
        interval: RecurringRuleRecurringRuleInterval;
        amount: any;
        createTime: any;
        start: any;
        updateTime: any;
        user: {
          __typename?: 'User';
          id: string;
          username?: string | null;
          profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string } | null;
        };
        portfolio: {
          __typename?: 'UserAccountPortfolio';
          name?: string | null;
          account: {
            __typename?: 'UserAccount';
            externalID?: string | null;
            type: UserAccountAccountType;
          };
        };
        asset:
          | { __typename?: 'CryptoAsset'; symbol: string }
          | { __typename?: 'FiatAsset'; symbol: string }
          | { __typename?: 'SecurityAsset'; symbol: string };
        instances?: Array<{
          __typename?: 'RecurringRuleInstance';
          amount: any;
          tradeDate?: any | null;
          transferDate?: any | null;
          orderStatus: OrderOrderStatus;
          createTime: any;
          failureStage?: RecurringRuleInstanceFailureStage | null;
          achTransfer?: { __typename?: 'ACHTransfer'; orderStatus: OrderOrderStatus } | null;
          trade?: Array<{
            __typename?: 'Trade';
            quoteAmount?: any | null;
            baseAmount?: any | null;
          }> | null;
        }> | null;
        updates: Array<{
          __typename?: 'RecurringRuleUpdateLog';
          updateTime: any;
          requestor: Requestor;
          diff: Array<{
            __typename?: 'RecurringRuleUpdateChanges';
            field: string;
            oldValue: string;
            newValue: string;
          }>;
        }>;
      } | null;
    } | null> | null;
  };
};

export type AdminUserRoboGoalFragmentFragment = {
  __typename?: 'RoboGoal';
  id: string;
  targetPortfolioID: string;
  accountPortfolioID: string;
  name: string;
  isActive: boolean;
  isAutoInfusionPaused: boolean;
  createTime: any;
  updateTime: any;
  accountPortfolio: {
    __typename?: 'UserAccountPortfolio';
    totalValue: any;
    id: string;
    name?: string | null;
    account: { __typename?: 'UserAccount'; id: string; externalID?: string | null };
  };
  user: { __typename?: 'User'; id: string };
  targetPortfolio: { __typename?: 'ModelPortfolio'; externalReferenceID?: string | null };
  allocation: {
    __typename?: 'RoboAllocation';
    fiatPct: any;
    stockPct: any;
    bondPct: any;
    totalValue: any;
    drift: number;
  };
  parameters?: {
    __typename?: 'RoboGoalParameters';
    infusionAmount: any;
    infusionFreq: RoboGoalParametersRoboInfusionFreq;
    endDate: any;
    targetAmount: any;
    riskProfile: UserRiskTolerance;
    goalPriority: RoboGoalParametersGoalPriority;
    initialInvestment: any;
    maxDrift?: number | null;
  } | null;
  projection?: { __typename?: 'RoboGoalProjection'; goalProbability: number } | null;
  fundingAccount?: { __typename?: 'ExternalAccount'; accountNumber: string } | null;
  actions: {
    __typename?: 'RoboInvestmentActionConnection';
    edges?: Array<{
      __typename?: 'RoboInvestmentActionEdge';
      node?: {
        __typename?: 'RoboInvestmentAction';
        details?:
          | { __typename?: 'RoboClosureDetails' }
          | { __typename?: 'RoboDividendDetails' }
          | { __typename?: 'RoboFeeDetails' }
          | { __typename?: 'RoboGoalAlgorithmExecution' }
          | { __typename?: 'RoboInterestDetails' }
          | { __typename?: 'RoboTransferDetails'; amount: any }
          | null;
      } | null;
    } | null> | null;
  };
};

export type GetAdminUserRoboGoalsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetAdminUserRoboGoalsQuery = {
  __typename?: 'Query';
  roboGoals: {
    __typename?: 'RoboGoalConnection';
    edges?: Array<{
      __typename?: 'RoboGoalEdge';
      node?: {
        __typename?: 'RoboGoal';
        id: string;
        targetPortfolioID: string;
        accountPortfolioID: string;
        name: string;
        isActive: boolean;
        isAutoInfusionPaused: boolean;
        createTime: any;
        updateTime: any;
        accountPortfolio: {
          __typename?: 'UserAccountPortfolio';
          totalValue: any;
          id: string;
          name?: string | null;
          account: { __typename?: 'UserAccount'; id: string; externalID?: string | null };
        };
        user: { __typename?: 'User'; id: string };
        targetPortfolio: { __typename?: 'ModelPortfolio'; externalReferenceID?: string | null };
        allocation: {
          __typename?: 'RoboAllocation';
          fiatPct: any;
          stockPct: any;
          bondPct: any;
          totalValue: any;
          drift: number;
        };
        parameters?: {
          __typename?: 'RoboGoalParameters';
          infusionAmount: any;
          infusionFreq: RoboGoalParametersRoboInfusionFreq;
          endDate: any;
          targetAmount: any;
          riskProfile: UserRiskTolerance;
          goalPriority: RoboGoalParametersGoalPriority;
          initialInvestment: any;
          maxDrift?: number | null;
        } | null;
        projection?: { __typename?: 'RoboGoalProjection'; goalProbability: number } | null;
        fundingAccount?: { __typename?: 'ExternalAccount'; accountNumber: string } | null;
        actions: {
          __typename?: 'RoboInvestmentActionConnection';
          edges?: Array<{
            __typename?: 'RoboInvestmentActionEdge';
            node?: {
              __typename?: 'RoboInvestmentAction';
              details?:
                | { __typename?: 'RoboClosureDetails' }
                | { __typename?: 'RoboDividendDetails' }
                | { __typename?: 'RoboFeeDetails' }
                | { __typename?: 'RoboGoalAlgorithmExecution' }
                | { __typename?: 'RoboInterestDetails' }
                | { __typename?: 'RoboTransferDetails'; amount: any }
                | null;
            } | null;
          } | null> | null;
        };
      } | null;
    } | null> | null;
  };
};

export type GetAdminUserRoboGoalsByUserIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetAdminUserRoboGoalsByUserIdQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserConnection';
    edges?: Array<{
      __typename?: 'UserEdge';
      node?: {
        __typename?: 'User';
        roboGoals: {
          __typename?: 'RoboGoalConnection';
          edges?: Array<{
            __typename?: 'RoboGoalEdge';
            node?: {
              __typename?: 'RoboGoal';
              id: string;
              isActive: boolean;
              accountPortfolio: {
                __typename?: 'UserAccountPortfolio';
                account: { __typename?: 'UserAccount'; id: string };
              };
            } | null;
          } | null> | null;
        };
      } | null;
    } | null> | null;
  };
};

export type GetAdminUserRoboGoalByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetAdminUserRoboGoalByIdQuery = {
  __typename?: 'Query';
  roboGoals: {
    __typename?: 'RoboGoalConnection';
    edges?: Array<{
      __typename?: 'RoboGoalEdge';
      node?: {
        __typename?: 'RoboGoal';
        id: string;
        targetPortfolioID: string;
        accountPortfolioID: string;
        name: string;
        isActive: boolean;
        isAutoInfusionPaused: boolean;
        createTime: any;
        updateTime: any;
        accountPortfolio: {
          __typename?: 'UserAccountPortfolio';
          totalValue: any;
          id: string;
          name?: string | null;
          account: { __typename?: 'UserAccount'; id: string; externalID?: string | null };
        };
        user: { __typename?: 'User'; id: string };
        targetPortfolio: { __typename?: 'ModelPortfolio'; externalReferenceID?: string | null };
        allocation: {
          __typename?: 'RoboAllocation';
          fiatPct: any;
          stockPct: any;
          bondPct: any;
          totalValue: any;
          drift: number;
        };
        parameters?: {
          __typename?: 'RoboGoalParameters';
          infusionAmount: any;
          infusionFreq: RoboGoalParametersRoboInfusionFreq;
          endDate: any;
          targetAmount: any;
          riskProfile: UserRiskTolerance;
          goalPriority: RoboGoalParametersGoalPriority;
          initialInvestment: any;
          maxDrift?: number | null;
        } | null;
        projection?: { __typename?: 'RoboGoalProjection'; goalProbability: number } | null;
        fundingAccount?: { __typename?: 'ExternalAccount'; accountNumber: string } | null;
        actions: {
          __typename?: 'RoboInvestmentActionConnection';
          edges?: Array<{
            __typename?: 'RoboInvestmentActionEdge';
            node?: {
              __typename?: 'RoboInvestmentAction';
              details?:
                | { __typename?: 'RoboClosureDetails' }
                | { __typename?: 'RoboDividendDetails' }
                | { __typename?: 'RoboFeeDetails' }
                | { __typename?: 'RoboGoalAlgorithmExecution' }
                | { __typename?: 'RoboInterestDetails' }
                | { __typename?: 'RoboTransferDetails'; amount: any }
                | null;
            } | null;
          } | null> | null;
        };
      } | null;
    } | null> | null;
  };
};

export type GetAdminUserRoboGoalByGoalIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetAdminUserRoboGoalByGoalIdQuery = {
  __typename?: 'Query';
  roboGoals: {
    __typename?: 'RoboGoalConnection';
    edges?: Array<{
      __typename?: 'RoboGoalEdge';
      node?: {
        __typename?: 'RoboGoal';
        id: string;
        targetPortfolioID: string;
        accountPortfolioID: string;
        name: string;
        isActive: boolean;
        isAutoInfusionPaused: boolean;
        createTime: any;
        updateTime: any;
        accountPortfolio: {
          __typename?: 'UserAccountPortfolio';
          totalValue: any;
          id: string;
          name?: string | null;
          account: { __typename?: 'UserAccount'; id: string; externalID?: string | null };
        };
        user: { __typename?: 'User'; id: string };
        targetPortfolio: { __typename?: 'ModelPortfolio'; externalReferenceID?: string | null };
        allocation: {
          __typename?: 'RoboAllocation';
          fiatPct: any;
          stockPct: any;
          bondPct: any;
          totalValue: any;
          drift: number;
        };
        parameters?: {
          __typename?: 'RoboGoalParameters';
          infusionAmount: any;
          infusionFreq: RoboGoalParametersRoboInfusionFreq;
          endDate: any;
          targetAmount: any;
          riskProfile: UserRiskTolerance;
          goalPriority: RoboGoalParametersGoalPriority;
          initialInvestment: any;
          maxDrift?: number | null;
        } | null;
        projection?: { __typename?: 'RoboGoalProjection'; goalProbability: number } | null;
        fundingAccount?: { __typename?: 'ExternalAccount'; accountNumber: string } | null;
        actions: {
          __typename?: 'RoboInvestmentActionConnection';
          edges?: Array<{
            __typename?: 'RoboInvestmentActionEdge';
            node?: {
              __typename?: 'RoboInvestmentAction';
              details?:
                | { __typename?: 'RoboClosureDetails' }
                | { __typename?: 'RoboDividendDetails' }
                | { __typename?: 'RoboFeeDetails' }
                | { __typename?: 'RoboGoalAlgorithmExecution' }
                | { __typename?: 'RoboInterestDetails' }
                | { __typename?: 'RoboTransferDetails'; amount: any }
                | null;
            } | null;
          } | null> | null;
        };
      } | null;
    } | null> | null;
  };
};

export type GetAdminRoboAllocationCardQueryVariables = Exact<{
  roboId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetAdminRoboAllocationCardQuery = {
  __typename?: 'Query';
  roboGoals: {
    __typename?: 'RoboGoalConnection';
    edges?: Array<{
      __typename?: 'RoboGoalEdge';
      node?: {
        __typename?: 'RoboGoal';
        id: string;
        targetPortfolio: {
          __typename?: 'ModelPortfolio';
          id: string;
          externalReferenceID?: string | null;
        };
        allocation: {
          __typename?: 'RoboAllocation';
          stockPct: any;
          bondPct: any;
          fiatPct: any;
          drift: number;
        };
        parameters?: { __typename?: 'RoboGoalParameters'; maxDrift?: number | null } | null;
      } | null;
    } | null> | null;
  };
  roboGeneralInvestments: {
    __typename?: 'RoboGeneralInvestmentConnection';
    edges?: Array<{
      __typename?: 'RoboGeneralInvestmentEdge';
      node?: {
        __typename?: 'RoboGeneralInvestment';
        id: string;
        targetPortfolio: {
          __typename?: 'ModelPortfolio';
          id: string;
          externalReferenceID?: string | null;
        };
        allocation: {
          __typename?: 'RoboAllocation';
          stockPct: any;
          bondPct: any;
          fiatPct: any;
          drift: number;
        };
      } | null;
    } | null> | null;
  };
};

export type GetAdminRoboAutomatedInvestmentsCardQueryVariables = Exact<{
  roboId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetAdminRoboAutomatedInvestmentsCardQuery = {
  __typename?: 'Query';
  roboGoals: {
    __typename?: 'RoboGoalConnection';
    edges?: Array<{
      __typename?: 'RoboGoalEdge';
      node?: {
        __typename?: 'RoboGoal';
        id: string;
        isAutoInfusionPaused: boolean;
        parameters?: {
          __typename?: 'RoboGoalParameters';
          endDate: any;
          targetAmount: any;
          infusionAmount: any;
          infusionFreq: RoboGoalParametersRoboInfusionFreq;
          riskProfile: UserRiskTolerance;
          goalPriority: RoboGoalParametersGoalPriority;
        } | null;
        user: { __typename?: 'User'; id: string };
      } | null;
    } | null> | null;
  };
  roboGeneralInvestments: {
    __typename?: 'RoboGeneralInvestmentConnection';
    edges?: Array<{
      __typename?: 'RoboGeneralInvestmentEdge';
      node?: {
        __typename?: 'RoboGeneralInvestment';
        id: string;
        isAutoInfusionPaused: boolean;
        recurringInfusion: any;
        infusionFrequency: RoboGeneralInvestmentRoboInfusionFreq;
        user: { __typename?: 'User'; id: string };
      } | null;
    } | null> | null;
  };
};

export type GetAdminGoalsByAccountIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetAdminGoalsByAccountIdQuery = {
  __typename?: 'Query';
  roboGoals: {
    __typename?: 'RoboGoalConnection';
    edges?: Array<{
      __typename?: 'RoboGoalEdge';
      node?: {
        __typename?: 'RoboGoal';
        id: string;
        name: string;
        accountPortfolioID: string;
      } | null;
    } | null> | null;
  };
};

export type GetAdminRoboDriftPopoutQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetAdminRoboDriftPopoutQuery = {
  __typename?: 'Query';
  roboGoals: {
    __typename?: 'RoboGoalConnection';
    edges?: Array<{
      __typename?: 'RoboGoalEdge';
      node?: {
        __typename?: 'RoboGoal';
        id: string;
        allocation: { __typename?: 'RoboAllocation'; drift: number };
        parameters?: { __typename?: 'RoboGoalParameters'; maxDrift?: number | null } | null;
      } | null;
    } | null> | null;
  };
  roboGeneralInvestments: {
    __typename?: 'RoboGeneralInvestmentConnection';
    edges?: Array<{
      __typename?: 'RoboGeneralInvestmentEdge';
      node?: {
        __typename?: 'RoboGeneralInvestment';
        id: string;
        allocation: { __typename?: 'RoboAllocation'; drift: number };
      } | null;
    } | null> | null;
  };
};

export type GetAdminTradesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['Cursor']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  allFilters?: InputMaybe<Array<TradeWhereInput> | TradeWhereInput>;
  criteria?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Time']['input']>;
  endDate?: InputMaybe<Scalars['Time']['input']>;
  statusIn?: InputMaybe<Array<TradeTradeStatus> | TradeTradeStatus>;
  orderDirection: OrderDirection;
  orderfield: TradeOrderField;
}>;

export type GetAdminTradesQuery = {
  __typename?: 'Query';
  response: {
    __typename?: 'TradeConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'TradeEdge';
      cursor: string;
      node?: {
        __typename?: 'Trade';
        id: string;
        status: TradeTradeStatus;
        orderStatus: OrderOrderStatus;
        side: TradeMarketSide;
        type: TradeTradeType;
        requestInQuote: boolean;
        requestAmount: any;
        baseAmount?: any | null;
        quoteAmount?: any | null;
        createTime: any;
        portfolio: {
          __typename?: 'UserAccountPortfolio';
          account: { __typename?: 'UserAccount'; type: UserAccountAccountType };
        };
        user: {
          __typename?: 'User';
          id: string;
          profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string } | null;
        };
        base:
          | { __typename?: 'CryptoAsset'; symbol: string }
          | { __typename?: 'FiatAsset'; symbol: string }
          | {
              __typename?: 'SecurityAsset';
              symbol: string;
              market?: { __typename?: 'AssetMarketData'; last: any } | null;
            };
        completedAt: Array<{ __typename?: 'TradeStatusChangeLog'; eventTime: any }>;
        createdAt: Array<{ __typename?: 'TradeStatusChangeLog'; eventTime: any }>;
      } | null;
    } | null> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      endCursor?: string | null;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type GetTradeByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetTradeByIdQuery = {
  __typename?: 'Query';
  response: {
    __typename?: 'TradeConnection';
    edges?: Array<{
      __typename?: 'TradeEdge';
      node?: {
        __typename?: 'Trade';
        id: string;
        portfolio: {
          __typename?: 'UserAccountPortfolio';
          account: {
            __typename?: 'UserAccount';
            type: UserAccountAccountType;
            externalID?: string | null;
          };
          user: {
            __typename?: 'User';
            username?: string | null;
            profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string } | null;
          };
        };
      } | null;
    } | null> | null;
  };
};

export type AdminTradeFragmentFragment = {
  __typename?: 'Trade';
  id: string;
  orderStatus: OrderOrderStatus;
  expirationType: TradeTradeExpirationType;
  status: TradeTradeStatus;
  reason?: string | null;
  note?: string | null;
  side: TradeMarketSide;
  stop?: any | null;
  limit?: any | null;
  type: TradeTradeType;
  requestAmount: any;
  requestInQuote: boolean;
  baseAmount?: any | null;
  quoteAmount?: any | null;
  user: { __typename?: 'User'; id: string };
  portfolio: {
    __typename?: 'UserAccountPortfolio';
    name?: string | null;
    account: {
      __typename?: 'UserAccount';
      id: string;
      type: UserAccountAccountType;
      externalID?: string | null;
      user: {
        __typename?: 'User';
        username?: string | null;
        profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string } | null;
      };
    };
  };
  requestDetails: {
    __typename?: 'RequestDetails';
    requestUUID?: any | null;
    requestor: Requestor;
    admin?: {
      __typename?: 'Admin';
      id: string;
      email: string;
      createTime: any;
      externalID: string;
      updateTime: any;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  };
  statusChangeLog: Array<{
    __typename?: 'TradeStatusChangeLog';
    status: TradeTradeStatus;
    eventTime: any;
    note: string;
  }>;
  base:
    | { __typename?: 'CryptoAsset'; symbol: string }
    | { __typename?: 'FiatAsset'; symbol: string }
    | {
        __typename?: 'SecurityAsset';
        symbol: string;
        market?: { __typename?: 'AssetMarketData'; last: any } | null;
      };
  completedAt: Array<{ __typename?: 'TradeStatusChangeLog'; eventTime: any }>;
  createdAt: Array<{ __typename?: 'TradeStatusChangeLog'; eventTime: any }>;
  recurringRuleInstance?: {
    __typename?: 'RecurringRuleInstance';
    id: string;
    rule: { __typename?: 'RecurringRule'; id: string };
  } | null;
};

export type GetAdminTradeQueryVariables = Exact<{
  tradeId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetAdminTradeQuery = {
  __typename?: 'Query';
  trades: {
    __typename?: 'TradeConnection';
    edges?: Array<{
      __typename?: 'TradeEdge';
      cursor: string;
      node?: {
        __typename?: 'Trade';
        id: string;
        orderStatus: OrderOrderStatus;
        expirationType: TradeTradeExpirationType;
        status: TradeTradeStatus;
        reason?: string | null;
        note?: string | null;
        side: TradeMarketSide;
        stop?: any | null;
        limit?: any | null;
        type: TradeTradeType;
        requestAmount: any;
        requestInQuote: boolean;
        baseAmount?: any | null;
        quoteAmount?: any | null;
        user: { __typename?: 'User'; id: string };
        portfolio: {
          __typename?: 'UserAccountPortfolio';
          name?: string | null;
          account: {
            __typename?: 'UserAccount';
            id: string;
            type: UserAccountAccountType;
            externalID?: string | null;
            user: {
              __typename?: 'User';
              username?: string | null;
              profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string } | null;
            };
          };
        };
        requestDetails: {
          __typename?: 'RequestDetails';
          requestUUID?: any | null;
          requestor: Requestor;
          admin?: {
            __typename?: 'Admin';
            id: string;
            email: string;
            createTime: any;
            externalID: string;
            updateTime: any;
            firstName?: string | null;
            lastName?: string | null;
          } | null;
        };
        statusChangeLog: Array<{
          __typename?: 'TradeStatusChangeLog';
          status: TradeTradeStatus;
          eventTime: any;
          note: string;
        }>;
        base:
          | { __typename?: 'CryptoAsset'; symbol: string }
          | { __typename?: 'FiatAsset'; symbol: string }
          | {
              __typename?: 'SecurityAsset';
              symbol: string;
              market?: { __typename?: 'AssetMarketData'; last: any } | null;
            };
        completedAt: Array<{ __typename?: 'TradeStatusChangeLog'; eventTime: any }>;
        createdAt: Array<{ __typename?: 'TradeStatusChangeLog'; eventTime: any }>;
        recurringRuleInstance?: {
          __typename?: 'RecurringRuleInstance';
          id: string;
          rule: { __typename?: 'RecurringRule'; id: string };
        } | null;
      } | null;
    } | null> | null;
  };
};

export type GetTrustedContactRequestsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['Cursor']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  startDate?: InputMaybe<Scalars['Time']['input']>;
  endDate?: InputMaybe<Scalars['Time']['input']>;
  criteria?: InputMaybe<Scalars['String']['input']>;
  allFilters?: InputMaybe<
    Array<TrustedContactUpdateRequestWhereInput> | TrustedContactUpdateRequestWhereInput
  >;
  orderDirection?: InputMaybe<OrderDirection>;
  orderField: TrustedContactUpdateRequestOrderField;
  statusIn?: InputMaybe<
    | Array<TrustedContactUpdateRequestUpdateRequestStatus>
    | TrustedContactUpdateRequestUpdateRequestStatus
  >;
}>;

export type GetTrustedContactRequestsQuery = {
  __typename?: 'Query';
  response: {
    __typename?: 'TrustedContactUpdateRequestConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'TrustedContactUpdateRequestEdge';
      cursor: string;
      node?: {
        __typename?: 'TrustedContactUpdateRequest';
        id: string;
        createTime: any;
        updateTime: any;
        status: TrustedContactUpdateRequestUpdateRequestStatus;
        requestDetails: {
          __typename?: 'RequestDetails';
          admin?: {
            __typename?: 'Admin';
            firstName?: string | null;
            lastName?: string | null;
          } | null;
        };
        nonDiscretionaryUpdateRequest?: {
          __typename?: 'NonDiscretionaryUpdateRequest';
          nonDiscretionaryAccountKyc: {
            __typename?: 'NonDiscretionaryAccountKyc';
            account?: Array<{
              __typename?: 'UserAccount';
              externalID?: string | null;
              user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
            }> | null;
          };
        } | null;
        discretionaryUpdateRequest?: {
          __typename?: 'DiscretionaryUpdateRequest';
          discretionaryAccountKyc: {
            __typename?: 'DiscretionaryAccountKyc';
            account?: Array<{
              __typename?: 'UserAccount';
              externalID?: string | null;
              user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
            }> | null;
          };
        } | null;
      } | null;
    } | null> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      endCursor?: string | null;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type GetTrustedContactRequestsCountQueryVariables = Exact<{ [key: string]: never }>;

export type GetTrustedContactRequestsCountQuery = {
  __typename?: 'Query';
  new: { __typename?: 'TrustedContactUpdateRequestConnection'; totalCount: number };
  completed: { __typename?: 'TrustedContactUpdateRequestConnection'; totalCount: number };
  contacted: { __typename?: 'TrustedContactUpdateRequestConnection'; totalCount: number };
};

export type GetAdminAccountsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['Cursor']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  status?: InputMaybe<Array<UserAccountAccountStatus> | UserAccountAccountStatus>;
  criteria?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Time']['input']>;
  endDate?: InputMaybe<Scalars['Time']['input']>;
  allFilters?: InputMaybe<Array<UserAccountWhereInput> | UserAccountWhereInput>;
}>;

export type GetAdminAccountsQuery = {
  __typename?: 'Query';
  response: {
    __typename?: 'UserAccountConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'UserAccountEdge';
      cursor: string;
      node?: {
        __typename?: 'UserAccount';
        id: string;
        type: UserAccountAccountType;
        externalID?: string | null;
        createTime: any;
        status: UserAccountAccountStatus;
        user: {
          __typename?: 'User';
          id: string;
          profile?: {
            __typename?: 'UserProfile';
            firstName: string;
            lastName: string;
            ssn?: string | null;
            email: string;
            phoneNumber: string;
            country?: any | null;
            state?: string | null;
            city?: string | null;
            streetAddress?: string | null;
            additionalStreetAddress?: string | null;
            zipCode?: string | null;
          } | null;
        };
        portfolios: {
          __typename?: 'UserAccountPortfolioConnection';
          edges?: Array<{
            __typename?: 'UserAccountPortfolioEdge';
            node?: {
              __typename?: 'UserAccountPortfolio';
              id: string;
              isDefault?: boolean | null;
              assetBalances: {
                __typename?: 'UserAccountPortfolioAssetConnection';
                edges?: Array<{
                  __typename?: 'UserAccountPortfolioAssetEdge';
                  node?: {
                    __typename?: 'UserAccountPortfolioAsset';
                    asset?:
                      | { __typename?: 'CryptoAsset'; symbol: string }
                      | { __typename?: 'FiatAsset'; symbol: string }
                      | { __typename?: 'SecurityAsset'; symbol: string }
                      | null;
                    balances?: {
                      __typename?: 'UserAccountPortfolioAssetBalance';
                      value: any;
                    } | null;
                  } | null;
                } | null> | null;
              };
            } | null;
          } | null> | null;
        };
        adminActions?: Array<{
          __typename?: 'AdminAction';
          adminFirstName?: string | null;
          adminLastName?: string | null;
        }> | null;
      } | null;
    } | null> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      endCursor?: string | null;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
  userIds: {
    __typename?: 'UserAccountConnection';
    edges?: Array<{
      __typename?: 'UserAccountEdge';
      node?: { __typename?: 'UserAccount'; user: { __typename?: 'User'; id: string } } | null;
    } | null> | null;
  };
};

export type GetAdminAccountByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetAdminAccountByIdQuery = {
  __typename?: 'Query';
  userAccounts: {
    __typename?: 'UserAccountConnection';
    edges?: Array<{
      __typename?: 'UserAccountEdge';
      cursor: string;
      node?: {
        __typename?: 'UserAccount';
        id: string;
        type: UserAccountAccountType;
        externalID?: string | null;
        createTime: any;
        status: UserAccountAccountStatus;
        user: {
          __typename?: 'User';
          id: string;
          profile?: {
            __typename?: 'UserProfile';
            firstName: string;
            lastName: string;
            ssn?: string | null;
            email: string;
            phoneNumber: string;
            country?: any | null;
            state?: string | null;
            city?: string | null;
            streetAddress?: string | null;
            additionalStreetAddress?: string | null;
            zipCode?: string | null;
          } | null;
        };
        portfolios: {
          __typename?: 'UserAccountPortfolioConnection';
          edges?: Array<{
            __typename?: 'UserAccountPortfolioEdge';
            node?: {
              __typename?: 'UserAccountPortfolio';
              id: string;
              isDefault?: boolean | null;
              assetBalances: {
                __typename?: 'UserAccountPortfolioAssetConnection';
                edges?: Array<{
                  __typename?: 'UserAccountPortfolioAssetEdge';
                  node?: {
                    __typename?: 'UserAccountPortfolioAsset';
                    asset?:
                      | { __typename?: 'CryptoAsset'; symbol: string }
                      | { __typename?: 'FiatAsset'; symbol: string }
                      | { __typename?: 'SecurityAsset'; symbol: string }
                      | null;
                    balances?: {
                      __typename?: 'UserAccountPortfolioAssetBalance';
                      value: any;
                    } | null;
                  } | null;
                } | null> | null;
              };
            } | null;
          } | null> | null;
        };
        adminActions?: Array<{
          __typename?: 'AdminAction';
          adminFirstName?: string | null;
          adminLastName?: string | null;
        }> | null;
      } | null;
    } | null> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      endCursor?: string | null;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type GetAdminUserProfileQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  accountFilter?: InputMaybe<UserAccountWhereInput>;
}>;

export type GetAdminUserProfileQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserConnection';
    edges?: Array<{
      __typename?: 'UserEdge';
      cursor: string;
      node?: {
        __typename?: 'User';
        id: string;
        username?: string | null;
        accounts: {
          __typename?: 'UserAccountConnection';
          edges?: Array<{
            __typename?: 'UserAccountEdge';
            node?: {
              __typename?: 'UserAccount';
              type: UserAccountAccountType;
              externalID?: string | null;
              status: UserAccountAccountStatus;
              programEnrollmentHistory?: Array<{
                __typename?: 'ProgramEnrollmentHistory';
                id: string;
                electionPreference: ProgramEnrollmentHistoryProgramElectionPreference;
                approvalStatus: ProgramEnrollmentHistoryProgramApprovalStatus;
                programType: ProgramEnrollmentHistoryProgramType;
                reviewedAt?: any | null;
                reason?: string | null;
                submissionStatus: ProgramEnrollmentHistoryProgramSubmissionStatus;
                submittedAt?: any | null;
                createTime: any;
              }> | null;
            } | null;
          } | null> | null;
        };
        profile?: {
          __typename?: 'UserProfile';
          firstName: string;
          lastName: string;
          ssn?: string | null;
          dateOfBirth: any;
          phoneNumber: string;
          email: string;
          streetAddress?: string | null;
          additionalStreetAddress?: string | null;
          city?: string | null;
          id: string;
          state?: string | null;
          zipCode?: string | null;
          country?: any | null;
          permanentResident?: boolean | null;
          visaType?: UserProfileUserVisaType | null;
          visaExpirationDate?: any | null;
          birthCountry?: { __typename?: 'Country'; name: string; alpha3Code: string } | null;
          citizenshipCountry?: { __typename?: 'Country'; name: string; alpha3Code: string } | null;
          updateRequests: {
            __typename?: 'UserProfileUpdateRequestConnection';
            edges?: Array<{
              __typename?: 'UserProfileUpdateRequestEdge';
              node?: {
                __typename?: 'UserProfileUpdateRequest';
                createTime: any;
                status: UserProfileUpdateRequestUpdateRequestStatus;
                requestParameters: {
                  __typename?: 'UserProfileParameters';
                  firstName?: string | null;
                  lastName?: string | null;
                  email?: string | null;
                  dateOfBirth?: any | null;
                  phoneNumber?: string | null;
                  phoneNumberType?: UserProfileUserPhoneNumberType | null;
                  ssn?: string | null;
                  streetAddress?: string | null;
                  additionalStreetAddress?: string | null;
                  city?: string | null;
                  state?: string | null;
                  zipCode?: string | null;
                  country?: string | null;
                  taxFilingInfo?: {
                    __typename?: 'TaxFilingInfo';
                    filingStatus?: UserFilingStatus | null;
                    adjustGrossIncome?: number | null;
                  } | null;
                  controlPersonDetails?: {
                    __typename: 'ControlPersonDetails';
                    companySymbols?: Array<string> | null;
                    isControlPerson: boolean;
                  } | null;
                  politicallyExposedDetails?: {
                    __typename: 'PoliticallyExposedDetails';
                    organizationName?: string | null;
                    familyMembers?: Array<string> | null;
                    isPoliticallyExposed: boolean;
                  } | null;
                  affiliatedExchangeOrFinraDetails?: {
                    __typename: 'AffiliatedExchangeOrFINRADetails';
                    firmName?: string | null;
                    isAffiliated: boolean;
                  } | null;
                  marketDataAnswers?: {
                    __typename: 'MarketDataAnswers';
                    personalUse: boolean;
                    gettingDataFromExternalSource: boolean;
                    registeredWithSecOrCftc: boolean;
                    requiresSecOrCftcRegistration: boolean;
                    investForOther: boolean;
                    investForTheBenefitOfOther: boolean;
                    advisorOrConsultant: boolean;
                    sharesProfit: boolean;
                    registeredWithEntity: boolean;
                  } | null;
                };
              } | null;
            } | null> | null;
          };
        } | null;
      } | null;
    } | null> | null;
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean };
  };
};

export type GetAdminUserKycsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  discretionaryEnabled?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetAdminUserKycsQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserConnection';
    edges?: Array<{
      __typename?: 'UserEdge';
      node?: {
        __typename?: 'User';
        accounts: {
          __typename?: 'UserAccountConnection';
          edges?: Array<{
            __typename?: 'UserAccountEdge';
            node?: {
              __typename?: 'UserAccount';
              id: string;
              type: UserAccountAccountType;
              status: UserAccountAccountStatus;
              trustedContacts?: Array<{
                __typename?: 'TrustedContact';
                id: string;
                firstName: string;
                middleName?: string | null;
                lastName: string;
                dateOfBirth: any;
                email: string;
                phoneNumber?: any | null;
                status?: TrustedContactTrustedContactStatus | null;
              }> | null;
              portfolios: {
                __typename?: 'UserAccountPortfolioConnection';
                edges?: Array<{
                  __typename?: 'UserAccountPortfolioEdge';
                  node?: {
                    __typename?: 'UserAccountPortfolio';
                    id: string;
                    account: {
                      __typename?: 'UserAccount';
                      createTime: any;
                      externalID?: string | null;
                      ascendMeta?: {
                        __typename?: 'AscendMeta';
                        accountNumber: string;
                        accountID: string;
                      } | null;
                    };
                  } | null;
                } | null> | null;
              };
              beneficiariesPrimary?: {
                __typename?: 'BeneficiarySet';
                id: string;
                beneficiaries?: Array<{
                  __typename?: 'Beneficiary';
                  id: string;
                  additionalStreetAddress?: string | null;
                  city: string;
                  country?: any | null;
                  createTime: any;
                  dateOfBirth: any;
                  email?: string | null;
                  firstName: string;
                  middleName?: string | null;
                  lastName: string;
                  percentage: number;
                  phoneNumber?: any | null;
                  relationship?: BeneficiaryBeneficiaryRelationType | null;
                  ssn?: string | null;
                  state: string;
                  streetAddress?: string | null;
                  updateTime: any;
                  zipCode: string;
                  beneficiarySet: { __typename?: 'BeneficiarySet'; id: string; isPending: boolean };
                }> | null;
              } | null;
              beneficiariesContingent?: {
                __typename?: 'BeneficiarySet';
                id: string;
                beneficiaries?: Array<{
                  __typename?: 'Beneficiary';
                  id: string;
                  additionalStreetAddress?: string | null;
                  city: string;
                  country?: any | null;
                  createTime: any;
                  dateOfBirth: any;
                  email?: string | null;
                  firstName: string;
                  middleName?: string | null;
                  lastName: string;
                  percentage: number;
                  phoneNumber?: any | null;
                  relationship?: BeneficiaryBeneficiaryRelationType | null;
                  ssn?: string | null;
                  state: string;
                  streetAddress?: string | null;
                  updateTime: any;
                  zipCode: string;
                  beneficiarySet: { __typename?: 'BeneficiarySet'; id: string; isPending: boolean };
                }> | null;
              } | null;
            } | null;
          } | null> | null;
        };
        profile?: {
          __typename?: 'UserProfile';
          ssn?: string | null;
          dateOfBirth: any;
          controlPersonDetails?: {
            __typename?: 'ControlPersonDetails';
            companySymbols?: Array<string> | null;
            isControlPerson: boolean;
          } | null;
          affiliatedExchangeOrFinraDetails?: {
            __typename?: 'AffiliatedExchangeOrFINRADetails';
            firmName?: string | null;
            isAffiliated: boolean;
          } | null;
          politicallyExposedDetails?: {
            __typename?: 'PoliticallyExposedDetails';
            organizationName?: string | null;
            familyMembers?: Array<string> | null;
            isPoliticallyExposed: boolean;
          } | null;
          marketDataAnswers?: {
            __typename?: 'MarketDataAnswers';
            advisorOrConsultant: boolean;
            investForOther: boolean;
            personalUse: boolean;
            registeredWithSecOrCftc: boolean;
            requiresSecOrCftcRegistration: boolean;
            investForTheBenefitOfOther: boolean;
            gettingDataFromExternalSource: boolean;
            sharesProfit: boolean;
            registeredWithEntity: boolean;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  };
  nonDiscretionaryAccountKycs: {
    __typename?: 'NonDiscretionaryAccountKycConnection';
    edges?: Array<{
      __typename?: 'NonDiscretionaryAccountKycEdge';
      node?: {
        __typename?: 'NonDiscretionaryAccountKyc';
        annualIncome?: NonDiscretionaryAccountKycUserAnnualIncome | null;
        investmentExperience?: NonDiscretionaryAccountKycUserInvestmentExperience | null;
        investmentObjective?: NonDiscretionaryAccountKycUserInvestmentObjective | null;
        maritalStatus?: NonDiscretionaryAccountKycUserMaritalStatus | null;
        liquidityNeeds?: NonDiscretionaryAccountKycUserLiquidityNeeds | null;
        riskTolerance?: UserRiskTolerance | null;
        timeHorizon?: NonDiscretionaryAccountKycUserTimeHorizon | null;
        netWorth?: NonDiscretionaryAccountKycUserNetWorth | null;
        employmentStatus?: NonDiscretionaryAccountKycUserEmploymentStatus | null;
        account?: Array<{
          __typename?: 'UserAccount';
          id: string;
          externalID?: string | null;
        }> | null;
        employmentDetails?: {
          __typename?: 'EmploymentDetails';
          EmployerName: string;
          Position: string;
        } | null;
        updateRequests?: Array<{
          __typename?: 'NonDiscretionaryUpdateRequest';
          createTime: any;
          status: NonDiscretionaryUpdateRequestUpdateRequestStatus;
          requestParameters?: {
            __typename?: 'BrokerageAccountParameters';
            annualIncome?: NonDiscretionaryAccountKycUserAnnualIncome | null;
            investmentExperience?: NonDiscretionaryAccountKycUserInvestmentExperience | null;
            investmentObjective?: NonDiscretionaryAccountKycUserInvestmentObjective | null;
            maritalStatus?: NonDiscretionaryAccountKycUserMaritalStatus | null;
            liquidityNeeds?: NonDiscretionaryAccountKycUserLiquidityNeeds | null;
            riskTolerance?: UserRiskTolerance | null;
            timeHorizon?: NonDiscretionaryAccountKycUserTimeHorizon | null;
            netWorth?: NonDiscretionaryAccountKycUserNetWorth | null;
            employmentStatus?: NonDiscretionaryAccountKycUserEmploymentStatus | null;
            employmentDetails?: {
              __typename?: 'EmploymentDetails';
              EmployerName: string;
              Position: string;
            } | null;
          } | null;
        }> | null;
      } | null;
    } | null> | null;
  };
  discretionaryAccountKycs?: {
    __typename?: 'DiscretionaryAccountKycConnection';
    edges?: Array<{
      __typename?: 'DiscretionaryAccountKycEdge';
      node?: {
        __typename?: 'DiscretionaryAccountKyc';
        employmentStatus?: DiscretionaryAccountKycUserEmploymentStatus | null;
        annualIncome?: DiscretionaryAccountKycUserAnnualIncome | null;
        netWorth?: DiscretionaryAccountKycUserNetWorth | null;
        investmentObjective?: DiscretionaryAccountKycUserInvestmentObjective | null;
        riskTolerance?: UserRiskTolerance | null;
        employmentDetails?: {
          __typename?: 'EmploymentDetails';
          EmployerName: string;
          Position: string;
        } | null;
      } | null;
    } | null> | null;
  };
};

export type GetUserDocumentsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetUserDocumentsQuery = {
  __typename?: 'Query';
  userDocuments: {
    __typename?: 'UserDocumentConnection';
    edges?: Array<{
      __typename?: 'UserDocumentEdge';
      node?: {
        __typename?: 'UserDocument';
        id: string;
        requestor: Requestor;
        requestUUID?: any | null;
        name?: string | null;
        path?: string | null;
        mimeType: UserDocumentMimeType;
        url: string;
        documentType: UserDocumentUserDocumentType;
      } | null;
    } | null> | null;
  };
};

export type GetUserDocumentSetsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetUserDocumentSetsQuery = {
  __typename?: 'Query';
  userDocumentSets: {
    __typename?: 'UserDocumentSetConnection';
    edges?: Array<{
      __typename?: 'UserDocumentSetEdge';
      node?: {
        __typename?: 'UserDocumentSet';
        id: string;
        isactive: boolean;
        documentType: UserDocumentSetUserDocumentType;
        document: Array<{
          __typename?: 'UserDocument';
          id: string;
          requestor: Requestor;
          requestUUID?: any | null;
          name?: string | null;
          path?: string | null;
          mimeType: UserDocumentMimeType;
          url: string;
          documentType: UserDocumentUserDocumentType;
        }>;
      } | null;
    } | null> | null;
  };
};

export type GetUserDocumentSetsByDocumentTypeQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  documentType?: InputMaybe<UserDocumentUserDocumentType>;
}>;

export type GetUserDocumentSetsByDocumentTypeQuery = {
  __typename?: 'Query';
  userDocumentSets: {
    __typename?: 'UserDocumentSetConnection';
    edges?: Array<{
      __typename?: 'UserDocumentSetEdge';
      node?: {
        __typename?: 'UserDocumentSet';
        id: string;
        isactive: boolean;
        documentType: UserDocumentSetUserDocumentType;
        document: Array<{
          __typename?: 'UserDocument';
          id: string;
          requestor: Requestor;
          requestUUID?: any | null;
          name?: string | null;
          path?: string | null;
          mimeType: UserDocumentMimeType;
          url: string;
          documentType: UserDocumentUserDocumentType;
        }>;
      } | null;
    } | null> | null;
  };
};

export type GetAdminGiPortfoliosByUserIdQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type GetAdminGiPortfoliosByUserIdQuery = {
  __typename?: 'Query';
  roboGeneralInvestments: {
    __typename?: 'RoboGeneralInvestmentConnection';
    edges?: Array<{
      __typename?: 'RoboGeneralInvestmentEdge';
      node?: {
        __typename?: 'RoboGeneralInvestment';
        id: string;
        targetPortfolioID: string;
        accountPortfolioID: string;
        createTime: any;
      } | null;
    } | null> | null;
  };
};

export type GetUserAccountsByUserIdForAdminSidebarQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type GetUserAccountsByUserIdForAdminSidebarQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserConnection';
    edges?: Array<{
      __typename?: 'UserEdge';
      node?: {
        __typename?: 'User';
        accounts: {
          __typename?: 'UserAccountConnection';
          edges?: Array<{
            __typename?: 'UserAccountEdge';
            node?: { __typename?: 'UserAccount'; type: UserAccountAccountType } | null;
          } | null> | null;
        };
        roboGeneralInvestments: {
          __typename?: 'RoboGeneralInvestmentConnection';
          edges?: Array<{
            __typename?: 'RoboGeneralInvestmentEdge';
            node?: { __typename?: 'RoboGeneralInvestment'; isActive: boolean } | null;
          } | null> | null;
        };
      } | null;
    } | null> | null;
  };
};

export type IctTransferFragment = {
  __typename?: 'ICTTransfer';
  id: string;
  isReturned: boolean;
  instantDepositAmount?: any | null;
  orderType: OrderOrderType;
  orderStatus: OrderOrderStatus;
  status: IctTransferTransferStatus;
  amount: any;
  createTime: any;
  ictReturnFee?: any | null;
  ictType: IctTransferTransferType;
  ictTransferErrorCode?: IctTransferTransferErrorCode | null;
  externalAccount: {
    __typename: 'ExternalAccount';
    id: string;
    mask: string;
    institution: { __typename: 'Institution'; name: string };
    user: { __typename: 'User'; firstName: string; lastName: string };
  };
  recurringRuleInstance?: {
    __typename: 'RecurringRuleInstance';
    id: string;
    rule: {
      __typename: 'RecurringRule';
      id: string;
      asset:
        | { __typename: 'CryptoAsset'; symbol: string }
        | { __typename: 'FiatAsset'; symbol: string }
        | { __typename: 'SecurityAsset'; symbol: string };
      sector?: { __typename: 'RepeatInvestmentSector'; name: string } | null;
    };
  } | null;
};

export type IctTransferEdgeFragment = {
  __typename?: 'ICTTransferEdge';
  cursor: string;
  node?: {
    __typename?: 'ICTTransfer';
    id: string;
    isReturned: boolean;
    instantDepositAmount?: any | null;
    orderType: OrderOrderType;
    orderStatus: OrderOrderStatus;
    status: IctTransferTransferStatus;
    amount: any;
    createTime: any;
    ictReturnFee?: any | null;
    ictType: IctTransferTransferType;
    ictTransferErrorCode?: IctTransferTransferErrorCode | null;
    externalAccount: {
      __typename: 'ExternalAccount';
      id: string;
      mask: string;
      institution: { __typename: 'Institution'; name: string };
      user: { __typename: 'User'; firstName: string; lastName: string };
    };
    recurringRuleInstance?: {
      __typename: 'RecurringRuleInstance';
      id: string;
      rule: {
        __typename: 'RecurringRule';
        id: string;
        asset:
          | { __typename: 'CryptoAsset'; symbol: string }
          | { __typename: 'FiatAsset'; symbol: string }
          | { __typename: 'SecurityAsset'; symbol: string };
        sector?: { __typename: 'RepeatInvestmentSector'; name: string } | null;
      };
    } | null;
  } | null;
};

export type GetAcatTransfersQueryVariables = Exact<{ [key: string]: never }>;

export type GetAcatTransfersQuery = {
  __typename?: 'Query';
  response: {
    __typename?: 'AcatTransferConnection';
    edges?: Array<{
      __typename?: 'AcatTransferEdge';
      cursor: string;
      node?: {
        __typename?: 'AcatTransfer';
        id: string;
        createTime: any;
        updateTime: any;
        statusChangeLog: Array<{
          __typename?: 'AcatTransferStatusChangeLog';
          status: AcatTransferAcatTransferStatus;
          eventTime: any;
        }>;
        transfers?: Array<{
          __typename?: 'AcatTransferAsset';
          id: string;
          createTime: any;
          updateTime: any;
          quantity: any;
          asset?:
            | { __typename?: 'CryptoAsset'; id: string; symbol: string }
            | { __typename?: 'FiatAsset'; id: string; symbol: string }
            | { __typename?: 'SecurityAsset'; cusip: string; id: string; symbol: string }
            | null;
        }> | null;
      } | null;
    } | null> | null;
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null };
  };
};

export type GetAccountValuesQueryVariables = Exact<{
  start: Scalars['Time']['input'];
  end?: InputMaybe<Scalars['Time']['input']>;
  period: TimePeriod;
  portfolioId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetAccountValuesQuery = {
  __typename?: 'Query';
  totalValue: {
    __typename?: 'UserAccountPortfolioConnection';
    edges?: Array<{
      __typename?: 'UserAccountPortfolioEdge';
      node?: { __typename?: 'UserAccountPortfolio'; totalValue: any } | null;
    } | null> | null;
  };
  userAccountPortfolios: {
    __typename?: 'UserAccountPortfolioConnection';
    edges?: Array<{
      __typename?: 'UserAccountPortfolioEdge';
      node?: {
        __typename?: 'UserAccountPortfolio';
        valueHistory: {
          __typename?: 'UserAccountPortfolioValueHistory';
          period: TimePeriod;
          start: any;
          end: any;
          limit: number;
          values: Array<any>;
          timestamps: Array<any>;
          gainLosses: Array<any>;
          returnRates: Array<any>;
        };
      } | null;
    } | null> | null;
  };
};

export type GetStockAccountDetailQueryVariables = Exact<{
  accountType: UserAccountAccountType;
}>;

export type GetStockAccountDetailQuery = {
  __typename?: 'Query';
  userAccounts: {
    __typename?: 'UserAccountConnection';
    edges?: Array<{
      __typename?: 'UserAccountEdge';
      node?: {
        __typename?: 'UserAccount';
        id: string;
        externalID?: string | null;
        type: UserAccountAccountType;
        status: UserAccountAccountStatus;
        fpslEnrolled: boolean;
        cashSweepEnrolled: boolean;
        programEnrollmentHistory?: Array<{
          __typename?: 'ProgramEnrollmentHistory';
          id: string;
          electionPreference: ProgramEnrollmentHistoryProgramElectionPreference;
          approvalStatus: ProgramEnrollmentHistoryProgramApprovalStatus;
          programType: ProgramEnrollmentHistoryProgramType;
          reviewedAt?: any | null;
          reason?: string | null;
          submissionStatus: ProgramEnrollmentHistoryProgramSubmissionStatus;
          submittedAt?: any | null;
          createTime: any;
        }> | null;
        portfolios: {
          __typename?: 'UserAccountPortfolioConnection';
          edges?: Array<{
            __typename?: 'UserAccountPortfolioEdge';
            node?: {
              __typename?: 'UserAccountPortfolio';
              assetBalances: {
                __typename?: 'UserAccountPortfolioAssetConnection';
                edges?: Array<{
                  __typename?: 'UserAccountPortfolioAssetEdge';
                  node?: {
                    __typename?: 'UserAccountPortfolioAsset';
                    balances?: {
                      __typename?: 'UserAccountPortfolioAssetBalance';
                      withdrawable: any;
                      total: any;
                      pending: any;
                      tradeable: any;
                      activeBalance: any;
                      frozen: any;
                    } | null;
                  } | null;
                } | null> | null;
              };
              holdings: {
                __typename?: 'UserAccountPortfolioAssetConnection';
                edges?: Array<{
                  __typename?: 'UserAccountPortfolioAssetEdge';
                  node?: {
                    __typename?: 'UserAccountPortfolioAsset';
                    balances?: {
                      __typename?: 'UserAccountPortfolioAssetBalance';
                      activeBalance: any;
                    } | null;
                    asset?:
                      | { __typename?: 'CryptoAsset'; id: string; name: string; symbol: string }
                      | { __typename?: 'FiatAsset'; id: string; name: string; symbol: string }
                      | { __typename?: 'SecurityAsset'; id: string; name: string; symbol: string }
                      | null;
                  } | null;
                } | null> | null;
              };
            } | null;
          } | null> | null;
        };
      } | null;
    } | null> | null;
  };
  users: {
    __typename?: 'UserConnection';
    edges?: Array<{
      __typename?: 'UserEdge';
      node?: {
        __typename?: 'User';
        user_id: string;
        recurringRules: {
          __typename?: 'RecurringRuleConnection';
          edges?: Array<{
            __typename?: 'RecurringRuleEdge';
            node?: { __typename?: 'RecurringRule'; id: string } | null;
          } | null> | null;
        };
      } | null;
    } | null> | null;
  };
};

export type AchTransferFragment = {
  __typename?: 'ACHTransfer';
  id: string;
  orderStatus: OrderOrderStatus;
  amount: any;
  instantDepositAmount?: any | null;
  returnFee?: any | null;
  isReturned: boolean;
  reason?: string | null;
  expectedSettlementDate?: any | null;
  createTime: any;
  updateTime: any;
  transferErrorCode?: AchTransferTransferErrorCode | null;
  transferType: AchTransferTransferType;
  transferStatus: AchTransferTransferStatus;
  externalAccount: {
    __typename?: 'ExternalAccount';
    id: string;
    mask: string;
    institution: { __typename?: 'Institution'; name: string };
    user: { __typename?: 'User'; firstName: string; lastName: string };
  };
  recurringRuleInstance?: {
    __typename?: 'RecurringRuleInstance';
    id: string;
    rule: {
      __typename?: 'RecurringRule';
      id: string;
      asset:
        | { __typename?: 'CryptoAsset'; symbol: string }
        | { __typename?: 'FiatAsset'; symbol: string }
        | { __typename?: 'SecurityAsset'; symbol: string };
      sector?: { __typename?: 'RepeatInvestmentSector'; name: string } | null;
    };
  } | null;
  transferChangeLog: Array<{
    __typename?: 'ACHTransferStatusChangeLog';
    status: AchTransferTransferStatus;
    eventTime: any;
  }>;
  lockup?: { __typename?: 'AssetLockup'; clearDate?: any | null } | null;
};

export type AchTransferEdgeFragment = {
  __typename?: 'ACHTransferEdge';
  cursor: string;
  node?: {
    __typename?: 'ACHTransfer';
    id: string;
    orderStatus: OrderOrderStatus;
    amount: any;
    instantDepositAmount?: any | null;
    returnFee?: any | null;
    isReturned: boolean;
    reason?: string | null;
    expectedSettlementDate?: any | null;
    createTime: any;
    updateTime: any;
    transferErrorCode?: AchTransferTransferErrorCode | null;
    transferType: AchTransferTransferType;
    transferStatus: AchTransferTransferStatus;
    externalAccount: {
      __typename?: 'ExternalAccount';
      id: string;
      mask: string;
      institution: { __typename?: 'Institution'; name: string };
      user: { __typename?: 'User'; firstName: string; lastName: string };
    };
    recurringRuleInstance?: {
      __typename?: 'RecurringRuleInstance';
      id: string;
      rule: {
        __typename?: 'RecurringRule';
        id: string;
        asset:
          | { __typename?: 'CryptoAsset'; symbol: string }
          | { __typename?: 'FiatAsset'; symbol: string }
          | { __typename?: 'SecurityAsset'; symbol: string };
        sector?: { __typename?: 'RepeatInvestmentSector'; name: string } | null;
      };
    } | null;
    transferChangeLog: Array<{
      __typename?: 'ACHTransferStatusChangeLog';
      status: AchTransferTransferStatus;
      eventTime: any;
    }>;
    lockup?: { __typename?: 'AssetLockup'; clearDate?: any | null } | null;
  } | null;
};

export type GetAchTransferByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetAchTransferByIdQuery = {
  __typename?: 'Query';
  node?:
    | {
        __typename?: 'ACHTransfer';
        id: string;
        orderStatus: OrderOrderStatus;
        amount: any;
        instantDepositAmount?: any | null;
        returnFee?: any | null;
        isReturned: boolean;
        reason?: string | null;
        expectedSettlementDate?: any | null;
        createTime: any;
        updateTime: any;
        transferErrorCode?: AchTransferTransferErrorCode | null;
        transferType: AchTransferTransferType;
        transferStatus: AchTransferTransferStatus;
        externalAccount: {
          __typename?: 'ExternalAccount';
          id: string;
          mask: string;
          institution: { __typename?: 'Institution'; name: string };
          user: { __typename?: 'User'; firstName: string; lastName: string };
        };
        recurringRuleInstance?: {
          __typename?: 'RecurringRuleInstance';
          id: string;
          rule: {
            __typename?: 'RecurringRule';
            id: string;
            asset:
              | { __typename?: 'CryptoAsset'; symbol: string }
              | { __typename?: 'FiatAsset'; symbol: string }
              | { __typename?: 'SecurityAsset'; symbol: string };
            sector?: { __typename?: 'RepeatInvestmentSector'; name: string } | null;
          };
        } | null;
        transferChangeLog: Array<{
          __typename?: 'ACHTransferStatusChangeLog';
          status: AchTransferTransferStatus;
          eventTime: any;
        }>;
        lockup?: { __typename?: 'AssetLockup'; clearDate?: any | null } | null;
      }
    | { __typename?: 'AcatTransfer' }
    | { __typename?: 'AcatTransferAsset' }
    | { __typename?: 'AcatTransferDetails' }
    | { __typename?: 'AcatTransferDetailsAsset' }
    | { __typename?: 'AccountReconAdjustment' }
    | { __typename?: 'AccountTypeCustodian' }
    | { __typename?: 'AdminAction' }
    | { __typename?: 'AdminOrder' }
    | { __typename?: 'Advisory' }
    | { __typename?: 'ApexAccountFile' }
    | { __typename?: 'ApexAccountInvestigation' }
    | { __typename?: 'ApexAccountMeta' }
    | { __typename?: 'ApexAccountRequest' }
    | { __typename?: 'ApexAccountUpdateQueue' }
    | { __typename?: 'ApexFiles' }
    | { __typename?: 'ApexInvestigationFile' }
    | { __typename?: 'ApexUpdateRequest' }
    | { __typename?: 'AscendInvestigation' }
    | { __typename?: 'AscendMeta' }
    | { __typename?: 'AssetLockup' }
    | { __typename?: 'Beneficiary' }
    | { __typename?: 'BeneficiarySet' }
    | { __typename?: 'BrokerDTCC' }
    | { __typename?: 'BrokerDealer' }
    | { __typename?: 'BrokerDealerPrincipal' }
    | { __typename?: 'CapitalGains' }
    | { __typename?: 'CapitalGainsDistribution' }
    | { __typename?: 'CheckTransfer' }
    | { __typename?: 'CloseAccountRequest' }
    | { __typename?: 'CorporateAction' }
    | { __typename?: 'CorporateActionGrant' }
    | { __typename?: 'CorporateActionModification' }
    | { __typename?: 'Country' }
    | { __typename?: 'CryptoAsset' }
    | { __typename?: 'DiscretionaryAccountKyc' }
    | { __typename?: 'DiscretionaryUpdateRequest' }
    | { __typename?: 'DividendDistribution' }
    | { __typename?: 'ETFComposition' }
    | { __typename?: 'ETFDetails' }
    | { __typename?: 'ExternalAccount' }
    | { __typename?: 'FederatedIdentityProvider' }
    | { __typename?: 'FeeAndCreditOrder' }
    | { __typename?: 'FeeAndCreditProgramChangeLog' }
    | { __typename?: 'FeeAndCreditSubmission' }
    | { __typename?: 'FiatAsset' }
    | { __typename?: 'FnpMetadata' }
    | { __typename?: 'GOEPortfolioMapping' }
    | { __typename?: 'ICTPartnerInstitution' }
    | { __typename?: 'ICTTransfer' }
    | { __typename?: 'Institution' }
    | { __typename?: 'InterestDistribution' }
    | { __typename?: 'IntroducingBroker' }
    | { __typename?: 'InvestorDocument' }
    | { __typename?: 'Liability' }
    | { __typename?: 'Liquidation' }
    | { __typename?: 'MarketDataAnswers' }
    | { __typename?: 'ModelPortfolio' }
    | { __typename?: 'ModelPortfolioAssetComposition' }
    | { __typename?: 'ModelPortfolioAssetSubstitution' }
    | { __typename?: 'ModelPortfolioSector' }
    | { __typename?: 'ModelPortfolioSectorComposition' }
    | { __typename?: 'MutualFundDetails' }
    | { __typename?: 'NonDiscretionaryAccountKyc' }
    | { __typename?: 'NonDiscretionaryUpdateRequest' }
    | { __typename?: 'Order' }
    | { __typename?: 'PlaidRelationshipOwner' }
    | { __typename?: 'PortfolioLedger' }
    | { __typename?: 'PortfolioLiquidation' }
    | { __typename?: 'ProgramEnrollmentHistory' }
    | { __typename?: 'RTQPortfolioLevel' }
    | { __typename?: 'RTQPortfolioLevelModelAssignment' }
    | { __typename?: 'RTQPortfolioLevelSet' }
    | { __typename?: 'RTQQuestion' }
    | { __typename?: 'RTQQuestionnaire' }
    | { __typename?: 'RTQResponse' }
    | { __typename?: 'RTQResponseOption' }
    | { __typename?: 'RTQSubmission' }
    | { __typename?: 'RecurringRule' }
    | { __typename?: 'RecurringRuleInstance' }
    | { __typename?: 'RecurringRuleSkipRequest' }
    | { __typename?: 'ReferralRecord' }
    | { __typename?: 'RegulatedEntity' }
    | { __typename?: 'RepeatInvestmentSector' }
    | { __typename?: 'RetirementContributionInfo' }
    | { __typename?: 'RetirementDistributionInfo' }
    | { __typename?: 'ReturnOfCapital' }
    | { __typename?: 'ReturnOfCapitalDistribution' }
    | { __typename?: 'RoboGeneralInvestment' }
    | { __typename?: 'RoboGoal' }
    | { __typename?: 'RoboGoalAlgorithmExecution' }
    | { __typename?: 'RoboGoalParameters' }
    | { __typename?: 'RoboGoalProjection' }
    | { __typename?: 'RoboInvestmentAction' }
    | { __typename?: 'SecurityAsset' }
    | { __typename?: 'SecurityExchange' }
    | { __typename?: 'SecurityFixedIncomeDetails' }
    | { __typename?: 'SecuritySector' }
    | { __typename?: 'SodDiscrepancyTrack' }
    | { __typename?: 'SupportFAQ' }
    | { __typename?: 'SupportFAQCategory' }
    | { __typename?: 'SupportTicket' }
    | { __typename?: 'Tenant' }
    | { __typename?: 'Trade' }
    | { __typename?: 'TrustedContact' }
    | { __typename?: 'TrustedContactUpdateRequest' }
    | { __typename?: 'User' }
    | { __typename?: 'UserAccount' }
    | { __typename?: 'UserAccountAssetBalance' }
    | { __typename?: 'UserAccountPortfolio' }
    | { __typename?: 'UserAccountPortfolioAsset' }
    | { __typename?: 'UserAccountPortfolioAssetBalance' }
    | { __typename?: 'UserAccountPortfolioAssetDailyBalance' }
    | { __typename?: 'UserAccountRestriction' }
    | { __typename?: 'UserDocument' }
    | { __typename?: 'UserDocumentSet' }
    | { __typename?: 'UserProfile' }
    | { __typename?: 'UserProfileUpdateRequest' }
    | { __typename?: 'UserRetirementContribution' }
    | { __typename?: 'UserSetting' }
    | null;
};

export type GetDepositInfoQueryVariables = Exact<{
  portfolioID: Scalars['ID']['input'];
}>;

export type GetDepositInfoQuery = {
  __typename?: 'Query';
  userAccountPortfolios: {
    __typename?: 'UserAccountPortfolioConnection';
    edges?: Array<{
      __typename?: 'UserAccountPortfolioEdge';
      node?: {
        __typename?: 'UserAccountPortfolio';
        availableInstantDeposit: any;
        availableDailyDeposit: any;
      } | null;
    } | null> | null;
  };
};

export type UserAccountDetailFragment = {
  __typename?: 'User';
  accounts: {
    __typename?: 'UserAccountConnection';
    edges?: Array<{
      __typename?: 'UserAccountEdge';
      node?: {
        __typename?: 'UserAccount';
        type: UserAccountAccountType;
        portfolios: {
          __typename?: 'UserAccountPortfolioConnection';
          edges?: Array<{
            __typename?: 'UserAccountPortfolioEdge';
            node?: {
              __typename?: 'UserAccountPortfolio';
              id: string;
              name?: string | null;
              managementBy?: UserAccountPortfolioAccountManager | null;
              totalValue: any;
              fiat: { __typename?: 'AssetTypeTotal'; total: any };
              security: { __typename?: 'AssetTypeTotal'; total: any };
              crypto?: { __typename?: 'AssetTypeTotal'; total: any } | null;
            } | null;
          } | null> | null;
        };
      } | null;
    } | null> | null;
  };
};

export type UserRoboGoalDetailFragment = {
  __typename?: 'User';
  roboGoals: {
    __typename?: 'RoboGoalConnection';
    edges?: Array<{
      __typename?: 'RoboGoalEdge';
      node?: {
        __typename?: 'RoboGoal';
        id: string;
        targetPortfolioID: string;
        accountPortfolioID: string;
        name: string;
        isActive: boolean;
        isAutoInfusionPaused: boolean;
        accountPortfolio: {
          __typename?: 'UserAccountPortfolio';
          totalValue: any;
          id: string;
          name?: string | null;
          account: { __typename?: 'UserAccount'; id: string; externalID?: string | null };
        };
        allocation: { __typename?: 'RoboAllocation'; totalValue: any };
        parameters?: {
          __typename?: 'RoboGoalParameters';
          targetAmount: any;
          riskProfile: UserRiskTolerance;
          goalPriority: RoboGoalParametersGoalPriority;
        } | null;
        projection?: { __typename?: 'RoboGoalProjection'; goalProbability: number } | null;
        fundingAccount?: { __typename?: 'ExternalAccount'; accountNumber: string } | null;
      } | null;
    } | null> | null;
  };
};

export type GetAllAccountsDataQueryVariables = Exact<{
  accountFilter?: InputMaybe<UserAccountWhereInput>;
}>;

export type GetAllAccountsDataQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserConnection';
    edges?: Array<{
      __typename?: 'UserEdge';
      node?: {
        __typename?: 'User';
        accounts: {
          __typename?: 'UserAccountConnection';
          edges?: Array<{
            __typename?: 'UserAccountEdge';
            node?: {
              __typename?: 'UserAccount';
              type: UserAccountAccountType;
              portfolios: {
                __typename?: 'UserAccountPortfolioConnection';
                edges?: Array<{
                  __typename?: 'UserAccountPortfolioEdge';
                  node?: {
                    __typename?: 'UserAccountPortfolio';
                    id: string;
                    name?: string | null;
                    managementBy?: UserAccountPortfolioAccountManager | null;
                    totalValue: any;
                    fiat: { __typename?: 'AssetTypeTotal'; total: any };
                    security: { __typename?: 'AssetTypeTotal'; total: any };
                    crypto?: { __typename?: 'AssetTypeTotal'; total: any } | null;
                  } | null;
                } | null> | null;
              };
            } | null;
          } | null> | null;
        };
        roboGoals: {
          __typename?: 'RoboGoalConnection';
          edges?: Array<{
            __typename?: 'RoboGoalEdge';
            node?: {
              __typename?: 'RoboGoal';
              id: string;
              targetPortfolioID: string;
              accountPortfolioID: string;
              name: string;
              isActive: boolean;
              isAutoInfusionPaused: boolean;
              accountPortfolio: {
                __typename?: 'UserAccountPortfolio';
                totalValue: any;
                id: string;
                name?: string | null;
                account: { __typename?: 'UserAccount'; id: string; externalID?: string | null };
              };
              allocation: { __typename?: 'RoboAllocation'; totalValue: any };
              parameters?: {
                __typename?: 'RoboGoalParameters';
                targetAmount: any;
                riskProfile: UserRiskTolerance;
                goalPriority: RoboGoalParametersGoalPriority;
              } | null;
              projection?: { __typename?: 'RoboGoalProjection'; goalProbability: number } | null;
              fundingAccount?: { __typename?: 'ExternalAccount'; accountNumber: string } | null;
            } | null;
          } | null> | null;
        };
      } | null;
    } | null> | null;
  };
};

export type GetAllAccountsChartDataQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['ID']['input']>;
  accountFilter?: InputMaybe<UserAccountWhereInput>;
}>;

export type GetAllAccountsChartDataQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserConnection';
    edges?: Array<{
      __typename?: 'UserEdge';
      node?: {
        __typename?: 'User';
        roboGoals: {
          __typename?: 'RoboGoalConnection';
          edges?: Array<{
            __typename?: 'RoboGoalEdge';
            node?: {
              __typename?: 'RoboGoal';
              id: string;
              name: string;
              accountPortfolio: { __typename?: 'UserAccountPortfolio'; totalValue: any };
            } | null;
          } | null> | null;
        };
        accounts: {
          __typename?: 'UserAccountConnection';
          edges?: Array<{
            __typename?: 'UserAccountEdge';
            node?: {
              __typename?: 'UserAccount';
              type: UserAccountAccountType;
              portfolios: {
                __typename?: 'UserAccountPortfolioConnection';
                edges?: Array<{
                  __typename?: 'UserAccountPortfolioEdge';
                  node?: {
                    __typename?: 'UserAccountPortfolio';
                    id: string;
                    name?: string | null;
                    managementBy?: UserAccountPortfolioAccountManager | null;
                    totalValue: any;
                    fiat: { __typename?: 'AssetTypeTotal'; total: any };
                    security: { __typename?: 'AssetTypeTotal'; total: any };
                    crypto?: { __typename?: 'AssetTypeTotal'; total: any } | null;
                  } | null;
                } | null> | null;
              };
            } | null;
          } | null> | null;
        };
      } | null;
    } | null> | null;
  };
};

export type GetLatestNonDiscretionaryUpdateRequestsQueryVariables = Exact<{ [key: string]: never }>;

export type GetLatestNonDiscretionaryUpdateRequestsQuery = {
  __typename?: 'Query';
  nonDiscretionaryUpdateRequests: {
    __typename?: 'NonDiscretionaryUpdateRequestConnection';
    edges?: Array<{
      __typename?: 'NonDiscretionaryUpdateRequestEdge';
      node?: {
        __typename?: 'NonDiscretionaryUpdateRequest';
        status: NonDiscretionaryUpdateRequestUpdateRequestStatus;
        createTime: any;
        updateTime: any;
      } | null;
    } | null> | null;
  };
};

export type GetLatestDiscretionaryUpdateRequestsQueryVariables = Exact<{ [key: string]: never }>;

export type GetLatestDiscretionaryUpdateRequestsQuery = {
  __typename?: 'Query';
  discretionaryUpdateRequests: {
    __typename?: 'DiscretionaryUpdateRequestConnection';
    edges?: Array<{
      __typename?: 'DiscretionaryUpdateRequestEdge';
      node?: {
        __typename?: 'DiscretionaryUpdateRequest';
        status: DiscretionaryUpdateRequestUpdateRequestStatus;
        createTime: any;
        updateTime: any;
      } | null;
    } | null> | null;
  };
};

export type GetLatestProfileUpdateRequestsQueryVariables = Exact<{ [key: string]: never }>;

export type GetLatestProfileUpdateRequestsQuery = {
  __typename?: 'Query';
  userProfileUpdateRequests: {
    __typename?: 'UserProfileUpdateRequestConnection';
    edges?: Array<{
      __typename?: 'UserProfileUpdateRequestEdge';
      node?: {
        __typename?: 'UserProfileUpdateRequest';
        status: UserProfileUpdateRequestUpdateRequestStatus;
        createTime: any;
        updateTime: any;
      } | null;
    } | null> | null;
  };
};

export type GetLatestNonDiscretionaryUpdateRequestsDetailsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetLatestNonDiscretionaryUpdateRequestsDetailsQuery = {
  __typename?: 'Query';
  nonDiscretionaryAccountKycs: {
    __typename?: 'NonDiscretionaryAccountKycConnection';
    edges?: Array<{
      __typename?: 'NonDiscretionaryAccountKycEdge';
      node?: {
        __typename?: 'NonDiscretionaryAccountKyc';
        updateRequests?: Array<{
          __typename?: 'NonDiscretionaryUpdateRequest';
          createTime: any;
          status: NonDiscretionaryUpdateRequestUpdateRequestStatus;
          requestParameters?: {
            __typename?: 'BrokerageAccountParameters';
            annualIncome?: NonDiscretionaryAccountKycUserAnnualIncome | null;
            investmentExperience?: NonDiscretionaryAccountKycUserInvestmentExperience | null;
            investmentObjective?: NonDiscretionaryAccountKycUserInvestmentObjective | null;
            maritalStatus?: NonDiscretionaryAccountKycUserMaritalStatus | null;
            liquidityNeeds?: NonDiscretionaryAccountKycUserLiquidityNeeds | null;
            riskTolerance?: UserRiskTolerance | null;
            timeHorizon?: NonDiscretionaryAccountKycUserTimeHorizon | null;
            netWorth?: NonDiscretionaryAccountKycUserNetWorth | null;
            employmentStatus?: NonDiscretionaryAccountKycUserEmploymentStatus | null;
            employmentDetails?: {
              __typename?: 'EmploymentDetails';
              EmployerName: string;
              Position: string;
            } | null;
          } | null;
        }> | null;
      } | null;
    } | null> | null;
  };
};

export type GetLatestDiscretionaryUpdateRequestsDetailsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetLatestDiscretionaryUpdateRequestsDetailsQuery = {
  __typename?: 'Query';
  discretionaryAccountKycs: {
    __typename?: 'DiscretionaryAccountKycConnection';
    edges?: Array<{
      __typename?: 'DiscretionaryAccountKycEdge';
      node?: {
        __typename?: 'DiscretionaryAccountKyc';
        updateRequests?: Array<{
          __typename?: 'DiscretionaryUpdateRequest';
          createTime: any;
          status: DiscretionaryUpdateRequestUpdateRequestStatus;
          requestParameters?: {
            __typename?: 'BrokerageDiscretionaryAccountParameters';
            annualIncome?: DiscretionaryAccountKycUserAnnualIncome | null;
            investmentObjective?: DiscretionaryAccountKycUserInvestmentObjective | null;
            riskTolerance?: UserRiskTolerance | null;
            netWorth?: DiscretionaryAccountKycUserNetWorth | null;
            employmentStatus?: DiscretionaryAccountKycUserEmploymentStatus | null;
            employmentDetails?: {
              __typename?: 'EmploymentDetails';
              EmployerName: string;
              Position: string;
            } | null;
          } | null;
        }> | null;
      } | null;
    } | null> | null;
  };
};

export type GetLatestTrustedContactUpdateRequestsDetailsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetLatestTrustedContactUpdateRequestsDetailsQuery = {
  __typename?: 'Query';
  trustedContacts: {
    __typename?: 'TrustedContactConnection';
    edges?: Array<{
      __typename?: 'TrustedContactEdge';
      node?: {
        __typename: 'TrustedContact';
        id: string;
        firstName: string;
        lastName: string;
        dateOfBirth: any;
        email: string;
        phoneNumber?: any | null;
        isActive?: boolean | null;
      } | null;
    } | null> | null;
  };
  trustedContactUpdateRequests: {
    __typename?: 'TrustedContactUpdateRequestConnection';
    edges?: Array<{
      __typename?: 'TrustedContactUpdateRequestEdge';
      node?: {
        __typename: 'TrustedContactUpdateRequest';
        id: string;
        createTime: any;
        status: TrustedContactUpdateRequestUpdateRequestStatus;
        requestParameters: {
          __typename?: 'TrustedContactParameters';
          firstName?: string | null;
          lastName?: string | null;
          dateOfBirth?: any | null;
          email?: string | null;
          phoneNumber?: string | null;
        };
      } | null;
    } | null> | null;
  };
};

export type GetAvailableCountriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAvailableCountriesQuery = {
  __typename?: 'Query';
  countries: {
    __typename?: 'CountryConnection';
    edges?: Array<{
      __typename?: 'CountryEdge';
      node?: { __typename?: 'Country'; id: string; alpha3Code: string; name: string } | null;
    } | null> | null;
  };
};

export type DividendDistributionFragmentFragment = {
  __typename?: 'DividendDistribution';
  id: string;
  orderStatus: OrderOrderStatus;
  orderType: OrderOrderType;
  isQualified: boolean;
  isReinvested: boolean;
  payDate?: any | null;
  amount: any;
  createTime: any;
  distributionAsset?:
    | { __typename?: 'CryptoAsset'; symbol: string }
    | { __typename?: 'FiatAsset'; symbol: string }
    | { __typename?: 'SecurityAsset'; symbol: string }
    | null;
};

export type DividendDistributionEdgeFragment = {
  __typename?: 'DividendDistributionEdge';
  node?: {
    __typename?: 'DividendDistribution';
    id: string;
    orderStatus: OrderOrderStatus;
    orderType: OrderOrderType;
    isQualified: boolean;
    isReinvested: boolean;
    payDate?: any | null;
    amount: any;
    createTime: any;
    distributionAsset?:
      | { __typename?: 'CryptoAsset'; symbol: string }
      | { __typename?: 'FiatAsset'; symbol: string }
      | { __typename?: 'SecurityAsset'; symbol: string }
      | null;
  } | null;
};

export type GetEditableValuesForSingleGoalQueryVariables = Exact<{
  goalUid?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetEditableValuesForSingleGoalQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserConnection';
    edges?: Array<{
      __typename?: 'UserEdge';
      node?: {
        __typename?: 'User';
        roboGoals: {
          __typename?: 'RoboGoalConnection';
          edges?: Array<{
            __typename?: 'RoboGoalEdge';
            node?: {
              __typename?: 'RoboGoal';
              isAutoInfusionPaused: boolean;
              name: string;
              parameters?: {
                __typename?: 'RoboGoalParameters';
                targetAmount: any;
                endDate: any;
                infusionAmount: any;
                infusionFreq: RoboGoalParametersRoboInfusionFreq;
                initialInvestment: any;
              } | null;
            } | null;
          } | null> | null;
        };
      } | null;
    } | null> | null;
  };
};

export type ExternalAccountDataFragment = {
  __typename?: 'ExternalAccountEdge';
  node?: {
    __typename?: 'ExternalAccount';
    id: string;
    type: ExternalAccountExternalAccountType;
    accountNumber: string;
    mask: string;
    connectionStatus: ExternalAccountConnectionStatus;
    user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
    institution: {
      __typename?: 'Institution';
      id: string;
      createTime: any;
      updateTime: any;
      name: string;
      unusualCount: number;
    };
  } | null;
};

export type GetExternalAccountsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['ID']['input']>;
  accountStatus?: InputMaybe<
    Array<ExternalAccountConnectionStatus> | ExternalAccountConnectionStatus
  >;
}>;

export type GetExternalAccountsQuery = {
  __typename?: 'Query';
  externalAccounts: {
    __typename?: 'ExternalAccountConnection';
    edges?: Array<{
      __typename?: 'ExternalAccountEdge';
      node?: {
        __typename?: 'ExternalAccount';
        id: string;
        type: ExternalAccountExternalAccountType;
        accountNumber: string;
        mask: string;
        connectionStatus: ExternalAccountConnectionStatus;
        user: { __typename?: 'User'; id: string; firstName: string; lastName: string };
        institution: {
          __typename?: 'Institution';
          id: string;
          createTime: any;
          updateTime: any;
          name: string;
          unusualCount: number;
        };
      } | null;
    } | null> | null;
  };
};

export type GetExternalAccountQueryVariables = Exact<{
  accountId: Scalars['ID']['input'];
}>;

export type GetExternalAccountQuery = {
  __typename?: 'Query';
  externalAccounts: {
    __typename?: 'ExternalAccountConnection';
    edges?: Array<{
      __typename?: 'ExternalAccountEdge';
      node?: {
        __typename?: 'ExternalAccount';
        id: string;
        type: ExternalAccountExternalAccountType;
        mask: string;
        institution: { __typename?: 'Institution'; name: string };
      } | null;
    } | null> | null;
  };
};

export type InterestDistributionFragmentFragment = {
  __typename?: 'InterestDistribution';
  id: string;
  orderType: OrderOrderType;
  orderStatus: OrderOrderStatus;
  amount: any;
  type: InterestDistributionInterestType;
  processDate: any;
  effectiveDate: any;
  recordDate: any;
  createTime: any;
};

export type InterestDistributionEdgeFragment = {
  __typename?: 'InterestDistributionEdge';
  node?: {
    __typename?: 'InterestDistribution';
    id: string;
    orderType: OrderOrderType;
    orderStatus: OrderOrderStatus;
    amount: any;
    type: InterestDistributionInterestType;
    processDate: any;
    effectiveDate: any;
    recordDate: any;
    createTime: any;
  } | null;
};

export type GetInvestorDocumentsQueryVariables = Exact<{
  startDate?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  accountId: Scalars['ID']['input'];
}>;

export type GetInvestorDocumentsQuery = {
  __typename?: 'Query';
  statementDocuments: Array<{
    __typename?: 'InvestorDocument';
    id: string;
    name?: string | null;
    category?: InvestorDocumentCategory | null;
    type: InvestorDocumentType;
    taxIDs: Array<string>;
    url?: string | null;
    date: any;
  }>;
  taxFormDocuments: Array<{
    __typename?: 'InvestorDocument';
    id: string;
    name?: string | null;
    category?: InvestorDocumentCategory | null;
    type: InvestorDocumentType;
    taxIDs: Array<string>;
    url?: string | null;
    date: any;
  }>;
  tradeConfirmationDocuments: Array<{
    __typename?: 'InvestorDocument';
    id: string;
    name?: string | null;
    category?: InvestorDocumentCategory | null;
    type: InvestorDocumentType;
    taxIDs: Array<string>;
    url?: string | null;
    date: any;
  }>;
};

export type SectorFieldsFragment = {
  __typename?: 'Query';
  securitySectors: {
    __typename?: 'SecuritySectorConnection';
    edges?: Array<{
      __typename?: 'SecuritySectorEdge';
      node?: {
        __typename?: 'SecuritySector';
        description: string;
        id: string;
        imagePrimary: string;
        imageSector: string;
        name: string;
        portfolioAvgReturn?: number | null;
        portfolioExpRetLb?: number | null;
        portfolioExpRetUb?: number | null;
        portfolioRealTimePerf?: number | null;
        portfolioStdDev?: number | null;
        portfolioVariance?: number | null;
        portfolioYearlyPerf?: number | null;
        securities?: Array<{ __typename?: 'SecurityAsset'; symbol: string }> | null;
      } | null;
    } | null> | null;
  };
};

export type MarketFieldsFragment = {
  __typename?: 'Query';
  fiatAssets: {
    __typename?: 'FiatAssetConnection';
    edges?: Array<{
      __typename?: 'FiatAssetEdge';
      node?: {
        __typename?: 'FiatAsset';
        id: string;
        type: AssetAssetType;
        name: string;
        symbol: string;
      } | null;
    } | null> | null;
  };
};

export type GetMarketDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetMarketDataQuery = {
  __typename?: 'Query';
  fiatAssets: {
    __typename?: 'FiatAssetConnection';
    edges?: Array<{
      __typename?: 'FiatAssetEdge';
      node?: {
        __typename?: 'FiatAsset';
        id: string;
        type: AssetAssetType;
        name: string;
        symbol: string;
      } | null;
    } | null> | null;
  };
  securitySectors: {
    __typename?: 'SecuritySectorConnection';
    edges?: Array<{
      __typename?: 'SecuritySectorEdge';
      node?: {
        __typename?: 'SecuritySector';
        description: string;
        id: string;
        imagePrimary: string;
        imageSector: string;
        name: string;
        portfolioAvgReturn?: number | null;
        portfolioExpRetLb?: number | null;
        portfolioExpRetUb?: number | null;
        portfolioRealTimePerf?: number | null;
        portfolioStdDev?: number | null;
        portfolioVariance?: number | null;
        portfolioYearlyPerf?: number | null;
        securities?: Array<{ __typename?: 'SecurityAsset'; symbol: string }> | null;
      } | null;
    } | null> | null;
  };
};

export type GetMarketStatusQueryVariables = Exact<{ [key: string]: never }>;

export type GetMarketStatusQuery = {
  __typename?: 'Query';
  marketHours: {
    __typename?: 'MarketHours';
    status: {
      __typename?: 'MarketHoursStatus';
      date: any;
      status: MarketStatus;
      open?: any | null;
      close?: any | null;
      calendar: MarketCalendarType;
    };
    previous: {
      __typename?: 'MarketHoursStatus';
      date: any;
      status: MarketStatus;
      open?: any | null;
      close?: any | null;
      calendar: MarketCalendarType;
    };
  };
};

export type GetSectorDetailsFragment = {
  __typename?: 'ModelPortfolioSector';
  id: string;
  name: string;
  description: string;
  category: ModelPortfolioSectorCategory;
  assets: Array<{
    __typename?: 'ModelPortfolioAssetComposition';
    targetPct: any;
    asset:
      | {
          __typename?: 'CryptoAsset';
          imageLogo?: string | null;
          id: string;
          symbol: string;
          name: string;
        }
      | {
          __typename?: 'FiatAsset';
          imageLogo?: string | null;
          id: string;
          symbol: string;
          name: string;
        }
      | {
          __typename?: 'SecurityAsset';
          imageLogo?: string | null;
          id: string;
          symbol: string;
          name: string;
        };
  }>;
};

export type GetGoeMappingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetGoeMappingsQuery = {
  __typename?: 'Query';
  goePortfolioMappings: Array<{
    __typename?: 'GOEPortfolioMapping';
    id: string;
    goePortfolioID: number;
    modelPortfolio: {
      __typename?: 'ModelPortfolio';
      id: string;
      externalReferenceID?: string | null;
      fiatPct: any;
      bondPct: any;
      stockPct: any;
    };
  }>;
};

export type GetModelPortfolioByExternalReferenceIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetModelPortfolioByExternalReferenceIdQuery = {
  __typename?: 'Query';
  modelPortfolios: {
    __typename?: 'ModelPortfolioConnection';
    edges?: Array<{
      __typename?: 'ModelPortfolioEdge';
      node?: {
        __typename?: 'ModelPortfolio';
        bondPct: any;
        fiatPct: any;
        stockPct: any;
        externalReferenceID?: string | null;
        id: string;
        sectors?: Array<{
          __typename?: 'ModelPortfolioSectorComposition';
          id: string;
          targetPct: any;
          sector: {
            __typename?: 'ModelPortfolioSector';
            id: string;
            name: string;
            description: string;
            category: ModelPortfolioSectorCategory;
            assets: Array<{
              __typename?: 'ModelPortfolioAssetComposition';
              targetPct: any;
              asset:
                | {
                    __typename?: 'CryptoAsset';
                    imageLogo?: string | null;
                    id: string;
                    symbol: string;
                    name: string;
                  }
                | {
                    __typename?: 'FiatAsset';
                    imageLogo?: string | null;
                    id: string;
                    symbol: string;
                    name: string;
                  }
                | {
                    __typename?: 'SecurityAsset';
                    imageLogo?: string | null;
                    id: string;
                    symbol: string;
                    name: string;
                  };
            }>;
          };
        }> | null;
      } | null;
    } | null> | null;
  };
};

export type GetAllModelPortfolioAllocationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllModelPortfolioAllocationsQuery = {
  __typename?: 'Query';
  modelPortfolios: {
    __typename?: 'ModelPortfolioConnection';
    edges?: Array<{
      __typename?: 'ModelPortfolioEdge';
      node?: {
        __typename?: 'ModelPortfolio';
        bondPct: any;
        fiatPct: any;
        stockPct: any;
        externalReferenceID?: string | null;
        id: string;
      } | null;
    } | null> | null;
  };
};

export type GetModelPortfoliosScreenQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetModelPortfoliosScreenQuery = {
  __typename?: 'Query';
  modelPortfolios: {
    __typename?: 'ModelPortfolioConnection';
    edges?: Array<{
      __typename?: 'ModelPortfolioEdge';
      node?: {
        __typename?: 'ModelPortfolio';
        bondPct: any;
        fiatPct: any;
        stockPct: any;
        id: string;
        sectors?: Array<{
          __typename?: 'ModelPortfolioSectorComposition';
          id: string;
          targetPct: any;
          sector: {
            __typename?: 'ModelPortfolioSector';
            id: string;
            name: string;
            description: string;
            category: ModelPortfolioSectorCategory;
            assets: Array<{
              __typename?: 'ModelPortfolioAssetComposition';
              targetPct: any;
              asset:
                | {
                    __typename?: 'CryptoAsset';
                    imageLogo?: string | null;
                    id: string;
                    symbol: string;
                    name: string;
                  }
                | {
                    __typename?: 'FiatAsset';
                    imageLogo?: string | null;
                    id: string;
                    symbol: string;
                    name: string;
                  }
                | {
                    __typename?: 'SecurityAsset';
                    imageLogo?: string | null;
                    id: string;
                    symbol: string;
                    name: string;
                  };
            }>;
          };
        }> | null;
      } | null;
    } | null> | null;
  };
};

export type GetTargetPortfolioQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetTargetPortfolioQuery = {
  __typename?: 'Query';
  roboGoals: {
    __typename?: 'RoboGoalConnection';
    edges?: Array<{
      __typename?: 'RoboGoalEdge';
      node?: {
        __typename?: 'RoboGoal';
        allocation: {
          __typename?: 'RoboAllocation';
          fiatPct: any;
          stockPct: any;
          bondPct: any;
          totalValue: any;
          drift: number;
          allocationBySector: Array<{
            __typename?: 'RoboAllocationSector';
            percentage: any;
            sector: {
              __typename?: 'ModelPortfolioSector';
              id: string;
              name: string;
              category: ModelPortfolioSectorCategory;
              assets: Array<{
                __typename?: 'ModelPortfolioAssetComposition';
                targetPct: any;
                asset:
                  | { __typename?: 'CryptoAsset'; id: string; symbol: string }
                  | { __typename?: 'FiatAsset'; id: string; symbol: string }
                  | { __typename?: 'SecurityAsset'; id: string; symbol: string };
              }>;
            };
          }>;
        };
        targetPortfolio: {
          __typename?: 'ModelPortfolio';
          bondPct: any;
          fiatPct: any;
          stockPct: any;
          id: string;
          sectors?: Array<{
            __typename?: 'ModelPortfolioSectorComposition';
            id: string;
            targetPct: any;
            sector: {
              __typename?: 'ModelPortfolioSector';
              id: string;
              name: string;
              description: string;
              category: ModelPortfolioSectorCategory;
              assets: Array<{
                __typename?: 'ModelPortfolioAssetComposition';
                targetPct: any;
                asset:
                  | {
                      __typename?: 'CryptoAsset';
                      imageLogo?: string | null;
                      id: string;
                      symbol: string;
                      name: string;
                    }
                  | {
                      __typename?: 'FiatAsset';
                      imageLogo?: string | null;
                      id: string;
                      symbol: string;
                      name: string;
                    }
                  | {
                      __typename?: 'SecurityAsset';
                      imageLogo?: string | null;
                      id: string;
                      symbol: string;
                      name: string;
                    };
              }>;
            };
          }> | null;
        };
      } | null;
    } | null> | null;
  };
  roboGeneralInvestments: {
    __typename?: 'RoboGeneralInvestmentConnection';
    edges?: Array<{
      __typename?: 'RoboGeneralInvestmentEdge';
      node?: {
        __typename?: 'RoboGeneralInvestment';
        allocation: {
          __typename?: 'RoboAllocation';
          fiatPct: any;
          stockPct: any;
          bondPct: any;
          totalValue: any;
          drift: number;
          allocationBySector: Array<{
            __typename?: 'RoboAllocationSector';
            percentage: any;
            sector: {
              __typename?: 'ModelPortfolioSector';
              id: string;
              name: string;
              category: ModelPortfolioSectorCategory;
              assets: Array<{
                __typename?: 'ModelPortfolioAssetComposition';
                targetPct: any;
                asset:
                  | { __typename?: 'CryptoAsset'; id: string; symbol: string }
                  | { __typename?: 'FiatAsset'; id: string; symbol: string }
                  | { __typename?: 'SecurityAsset'; id: string; symbol: string };
              }>;
            };
          }>;
        };
        targetPortfolio: {
          __typename?: 'ModelPortfolio';
          bondPct: any;
          fiatPct: any;
          stockPct: any;
          id: string;
          sectors?: Array<{
            __typename?: 'ModelPortfolioSectorComposition';
            id: string;
            targetPct: any;
            sector: {
              __typename?: 'ModelPortfolioSector';
              id: string;
              name: string;
              description: string;
              category: ModelPortfolioSectorCategory;
              assets: Array<{
                __typename?: 'ModelPortfolioAssetComposition';
                targetPct: any;
                asset:
                  | {
                      __typename?: 'CryptoAsset';
                      imageLogo?: string | null;
                      id: string;
                      symbol: string;
                      name: string;
                    }
                  | {
                      __typename?: 'FiatAsset';
                      imageLogo?: string | null;
                      id: string;
                      symbol: string;
                      name: string;
                    }
                  | {
                      __typename?: 'SecurityAsset';
                      imageLogo?: string | null;
                      id: string;
                      symbol: string;
                      name: string;
                    };
              }>;
            };
          }> | null;
        };
      } | null;
    } | null> | null;
  };
};

export type GetNbboQuoteQueryVariables = Exact<{
  securityId: Scalars['ID']['input'];
  isBuy: Scalars['Boolean']['input'];
}>;

export type GetNbboQuoteQuery = {
  __typename?: 'Query';
  securityAssets: {
    __typename?: 'SecurityAssetConnection';
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        id: string;
        nbboQuote?: {
          __typename?: 'NbboQuote';
          AskPrice: any;
          AskSize: any;
          BidPrice: any;
          BidSize: any;
          AskExchange: { __typename?: 'SecurityExchange'; logo?: string | null; name: string };
          BidExchange: { __typename?: 'SecurityExchange'; logo?: string | null; name: string };
          lastTrade?: {
            __typename?: 'NbboLastTrade';
            price: any;
            volume: any;
            timestamp: any;
            securityExchange: { __typename?: 'SecurityExchange'; tape?: string | null };
          } | null;
        } | null;
      } | null;
    } | null> | null;
  };
};

export type PingServerQueryVariables = Exact<{ [key: string]: never }>;

export type PingServerQuery = {
  __typename?: 'Query';
  ping: { __typename?: 'Pong'; version: string; time: any };
};

export type GetPlaidQueryVariables = Exact<{ [key: string]: never }>;

export type GetPlaidQuery = {
  __typename?: 'Query';
  externalAccounts: {
    __typename?: 'ExternalAccountConnection';
    edges?: Array<{
      __typename?: 'ExternalAccountEdge';
      node?: {
        __typename?: 'ExternalAccount';
        id: string;
        mask: string;
        type: ExternalAccountExternalAccountType;
        connectionStatus: ExternalAccountConnectionStatus;
        institution: {
          __typename?: 'Institution';
          id: string;
          name: string;
          createTime: any;
          updateTime: any;
        };
      } | null;
    } | null> | null;
  };
};

export type ExternalAccountFragmentFragment = {
  __typename?: 'ExternalAccount';
  id: string;
  mask: string;
  type: ExternalAccountExternalAccountType;
  connectionStatus: ExternalAccountConnectionStatus;
  connectionType: ExternalAccountExternalAccountConnectionType;
  accountNumber: string;
  createTime: any;
  institution: {
    __typename?: 'Institution';
    id: string;
    name: string;
    createTime: any;
    updateTime: any;
  };
  connectionDetails?:
    | { __typename: 'ExternalAccountConnectionDetailsICT' }
    | {
        __typename: 'ExternalAccountConnectionDetailsPlaid';
        relationshipOwner: { __typename?: 'PlaidRelationshipOwner'; id: string };
      }
    | null;
};

export type GetPlaidV2QueryVariables = Exact<{ [key: string]: never }>;

export type GetPlaidV2Query = {
  __typename?: 'Query';
  tenant: {
    __typename?: 'Tenant';
    brokerage?: {
      __typename?: 'BrokerDealer';
      plaidRelationshipOwner: { __typename?: 'PlaidRelationshipOwner'; id: string };
    } | null;
    advisory?: {
      __typename?: 'Advisory';
      plaidRelationshipOwner: { __typename?: 'PlaidRelationshipOwner'; id: string };
    } | null;
  };
  externalAccounts: {
    __typename?: 'ExternalAccountConnection';
    edges?: Array<{
      __typename?: 'ExternalAccountEdge';
      node?: {
        __typename?: 'ExternalAccount';
        id: string;
        mask: string;
        type: ExternalAccountExternalAccountType;
        connectionStatus: ExternalAccountConnectionStatus;
        connectionType: ExternalAccountExternalAccountConnectionType;
        accountNumber: string;
        createTime: any;
        institution: {
          __typename?: 'Institution';
          id: string;
          name: string;
          createTime: any;
          updateTime: any;
        };
        connectionDetails?:
          | { __typename: 'ExternalAccountConnectionDetailsICT' }
          | {
              __typename: 'ExternalAccountConnectionDetailsPlaid';
              relationshipOwner: { __typename?: 'PlaidRelationshipOwner'; id: string };
            }
          | null;
      } | null;
    } | null> | null;
  };
};

export type GetWithdrawableAmountQueryVariables = Exact<{
  portfolioID?: InputMaybe<Scalars['ID']['input']>;
  assetID?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetWithdrawableAmountQuery = {
  __typename?: 'Query';
  userAccountPortfolios: {
    __typename?: 'UserAccountPortfolioConnection';
    edges?: Array<{
      __typename?: 'UserAccountPortfolioEdge';
      node?: {
        __typename?: 'UserAccountPortfolio';
        id: string;
        assetBalances: {
          __typename?: 'UserAccountPortfolioAssetConnection';
          edges?: Array<{
            __typename?: 'UserAccountPortfolioAssetEdge';
            node?: {
              __typename?: 'UserAccountPortfolioAsset';
              balances?: {
                __typename?: 'UserAccountPortfolioAssetBalance';
                withdrawable: any;
              } | null;
            } | null;
          } | null> | null;
        };
      } | null;
    } | null> | null;
  };
};

export type RecurringRuleFragmentFragment = {
  __typename?: 'RecurringRule';
  amount: any;
  lifetimeAmount: any;
  createTime: any;
  end?: any | null;
  fundingMethod: RecurringRuleFundingMethod;
  id: string;
  interval: RecurringRuleRecurringRuleInterval;
  isActive: boolean;
  name?: string | null;
  next?: any | null;
  nextTarget?: any | null;
  note?: string | null;
  reason?: string | null;
  start: any;
  type: RecurringRuleType;
  updateTime: any;
  fundingAccount: { __typename?: 'ExternalAccount'; accountNumber: string; id: string };
  sector?: {
    __typename?: 'RepeatInvestmentSector';
    id: string;
    largeImage: string;
    name: string;
    order: number;
    smallImage: string;
    type: RepeatInvestmentSectorType;
    security: {
      __typename?: 'SecurityAsset';
      id: string;
      imageLogo?: string | null;
      symbol: string;
      name: string;
      market?: { __typename?: 'AssetMarketData'; last: any; previousDayClose: any } | null;
    };
  } | null;
  user: { __typename?: 'User'; id: string };
  portfolio: { __typename?: 'UserAccountPortfolio'; id: string };
  asset:
    | {
        __typename?: 'CryptoAsset';
        id: string;
        type: AssetAssetType;
        name: string;
        symbol: string;
        active: boolean;
      }
    | {
        __typename?: 'FiatAsset';
        id: string;
        type: AssetAssetType;
        name: string;
        symbol: string;
        active: boolean;
      }
    | {
        __typename?: 'SecurityAsset';
        name: string;
        imageLogo?: string | null;
        securityType: SecurityAssetSecurityType;
        id: string;
        type: AssetAssetType;
        symbol: string;
        active: boolean;
        market?: { __typename?: 'AssetMarketData'; last: any; previousDayClose: any } | null;
      };
};

export type GetRecurringRulesV2QueryVariables = Exact<{
  where?: InputMaybe<RecurringRuleWhereInput>;
}>;

export type GetRecurringRulesV2Query = {
  __typename?: 'Query';
  recurringRules: {
    __typename?: 'RecurringRuleConnection';
    edges?: Array<{
      __typename?: 'RecurringRuleEdge';
      node?: {
        __typename?: 'RecurringRule';
        amount: any;
        lifetimeAmount: any;
        createTime: any;
        end?: any | null;
        fundingMethod: RecurringRuleFundingMethod;
        id: string;
        interval: RecurringRuleRecurringRuleInterval;
        isActive: boolean;
        name?: string | null;
        next?: any | null;
        nextTarget?: any | null;
        note?: string | null;
        reason?: string | null;
        start: any;
        type: RecurringRuleType;
        updateTime: any;
        fundingAccount: { __typename?: 'ExternalAccount'; accountNumber: string; id: string };
        sector?: {
          __typename?: 'RepeatInvestmentSector';
          id: string;
          largeImage: string;
          name: string;
          order: number;
          smallImage: string;
          type: RepeatInvestmentSectorType;
          security: {
            __typename?: 'SecurityAsset';
            id: string;
            imageLogo?: string | null;
            symbol: string;
            name: string;
            market?: { __typename?: 'AssetMarketData'; last: any; previousDayClose: any } | null;
          };
        } | null;
        user: { __typename?: 'User'; id: string };
        portfolio: { __typename?: 'UserAccountPortfolio'; id: string };
        asset:
          | {
              __typename?: 'CryptoAsset';
              id: string;
              type: AssetAssetType;
              name: string;
              symbol: string;
              active: boolean;
            }
          | {
              __typename?: 'FiatAsset';
              id: string;
              type: AssetAssetType;
              name: string;
              symbol: string;
              active: boolean;
            }
          | {
              __typename?: 'SecurityAsset';
              name: string;
              imageLogo?: string | null;
              securityType: SecurityAssetSecurityType;
              id: string;
              type: AssetAssetType;
              symbol: string;
              active: boolean;
              market?: { __typename?: 'AssetMarketData'; last: any; previousDayClose: any } | null;
            };
      } | null;
    } | null> | null;
  };
};

export type GetUserRetirementContributionsQueryVariables = Exact<{
  year: Scalars['Uint']['input'];
}>;

export type GetUserRetirementContributionsQuery = {
  __typename?: 'Query';
  userRetirementContributions?: Array<{
    __typename?: 'UserRetirementContributionInfo';
    userID: string;
    year: any;
    limits: {
      __typename?: 'RetirementContributionLimits';
      year: any;
      totalLimit: any;
      traditionalIRALimit: any;
      rothIRALimit: any;
    };
    totals: {
      __typename?: 'UserRetirementContributionTotals';
      combined: {
        __typename?: 'UserRetirementContributionTotal';
        total: any;
        totalWithPending: any;
      };
      traditionalIRA: {
        __typename?: 'UserRetirementContributionTotal';
        total: any;
        totalWithPending: any;
      };
      rothIRA: {
        __typename?: 'UserRetirementContributionTotal';
        total: any;
        totalWithPending: any;
      };
    };
  } | null> | null;
};

export type GetRetirementInfoQueryVariables = Exact<{
  state: Scalars['String']['input'];
}>;

export type GetRetirementInfoQuery = {
  __typename?: 'Query';
  retirementFundamentalInfo?: {
    __typename?: 'RetirementFundamentalInfo';
    withholding?: {
      __typename?: 'RetirementWithholdingFundamentalInfo';
      federalWithholding?: { __typename?: 'FederalWithholdingFundamentalInfo'; rate: any } | null;
      stateWithholding?: Array<{
        __typename?: 'StateWithholdingFundamentalInfo';
        state: string;
        class: StateWithholdingClass;
        rate?: any | null;
        rateType?: WithholdingRateType | null;
      } | null> | null;
    } | null;
  } | null;
};

export type RoboInvestmentActionFragmentFragment = {
  __typename?: 'RoboInvestmentAction';
  id: string;
  type: RoboInvestmentActionRoboActivityType;
  status: RoboInvestmentActionRoboActionStatus;
  requestTime: any;
  requestor: Requestor;
  goal?: {
    __typename?: 'RoboGoal';
    fundingAccount?: {
      __typename?: 'ExternalAccount';
      mask: string;
      institution: { __typename?: 'Institution'; name: string };
    } | null;
  } | null;
  details?:
    | { __typename: 'RoboClosureDetails' }
    | { __typename: 'RoboDividendDetails'; amount: any }
    | { __typename: 'RoboFeeDetails'; type: RoboFeeType; amount: any }
    | { __typename: 'RoboGoalAlgorithmExecution' }
    | { __typename: 'RoboInterestDetails'; amount: any; source: InterestDistributionInterestSource }
    | {
        __typename: 'RoboTransferDetails';
        amount: any;
        expectedSettlementDate?: any | null;
        expectedTradeDate?: any | null;
      }
    | null;
};

export type RoboInvestmentActionEdgeFragmentFragment = {
  __typename?: 'RoboInvestmentActionEdge';
  cursor: string;
  node?: {
    __typename?: 'RoboInvestmentAction';
    id: string;
    type: RoboInvestmentActionRoboActivityType;
    status: RoboInvestmentActionRoboActionStatus;
    requestTime: any;
    requestor: Requestor;
    goal?: {
      __typename?: 'RoboGoal';
      fundingAccount?: {
        __typename?: 'ExternalAccount';
        mask: string;
        institution: { __typename?: 'Institution'; name: string };
      } | null;
    } | null;
    details?:
      | { __typename: 'RoboClosureDetails' }
      | { __typename: 'RoboDividendDetails'; amount: any }
      | { __typename: 'RoboFeeDetails'; type: RoboFeeType; amount: any }
      | { __typename: 'RoboGoalAlgorithmExecution' }
      | {
          __typename: 'RoboInterestDetails';
          amount: any;
          source: InterestDistributionInterestSource;
        }
      | {
          __typename: 'RoboTransferDetails';
          amount: any;
          expectedSettlementDate?: any | null;
          expectedTradeDate?: any | null;
        }
      | null;
  } | null;
};

export type GetRoboGoalActivityHistoryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  types?: InputMaybe<
    Array<RoboInvestmentActionRoboActivityType> | RoboInvestmentActionRoboActivityType
  >;
  startDate?: InputMaybe<Scalars['Time']['input']>;
  endDate?: InputMaybe<Scalars['Time']['input']>;
  recentOnly: Scalars['Boolean']['input'];
}>;

export type GetRoboGoalActivityHistoryQuery = {
  __typename?: 'Query';
  recentPages: {
    __typename?: 'RoboInvestmentActionConnection';
    edges?: Array<{
      __typename?: 'RoboInvestmentActionEdge';
      cursor: string;
      node?: {
        __typename?: 'RoboInvestmentAction';
        id: string;
        type: RoboInvestmentActionRoboActivityType;
        status: RoboInvestmentActionRoboActionStatus;
        requestTime: any;
        requestor: Requestor;
        goal?: {
          __typename?: 'RoboGoal';
          fundingAccount?: {
            __typename?: 'ExternalAccount';
            mask: string;
            institution: { __typename?: 'Institution'; name: string };
          } | null;
        } | null;
        details?:
          | { __typename: 'RoboClosureDetails' }
          | { __typename: 'RoboDividendDetails'; amount: any }
          | { __typename: 'RoboFeeDetails'; type: RoboFeeType; amount: any }
          | { __typename: 'RoboGoalAlgorithmExecution' }
          | {
              __typename: 'RoboInterestDetails';
              amount: any;
              source: InterestDistributionInterestSource;
            }
          | {
              __typename: 'RoboTransferDetails';
              amount: any;
              expectedSettlementDate?: any | null;
              expectedTradeDate?: any | null;
            }
          | null;
      } | null;
    } | null> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      endCursor?: string | null;
    };
  };
  pages: {
    __typename?: 'RoboInvestmentActionConnection';
    edges?: Array<{
      __typename?: 'RoboInvestmentActionEdge';
      cursor: string;
      node?: {
        __typename?: 'RoboInvestmentAction';
        id: string;
        type: RoboInvestmentActionRoboActivityType;
        status: RoboInvestmentActionRoboActionStatus;
        requestTime: any;
        requestor: Requestor;
        goal?: {
          __typename?: 'RoboGoal';
          fundingAccount?: {
            __typename?: 'ExternalAccount';
            mask: string;
            institution: { __typename?: 'Institution'; name: string };
          } | null;
        } | null;
        details?:
          | { __typename: 'RoboClosureDetails' }
          | { __typename: 'RoboDividendDetails'; amount: any }
          | { __typename: 'RoboFeeDetails'; type: RoboFeeType; amount: any }
          | { __typename: 'RoboGoalAlgorithmExecution' }
          | {
              __typename: 'RoboInterestDetails';
              amount: any;
              source: InterestDistributionInterestSource;
            }
          | {
              __typename: 'RoboTransferDetails';
              amount: any;
              expectedSettlementDate?: any | null;
              expectedTradeDate?: any | null;
            }
          | null;
      } | null;
    } | null> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      endCursor?: string | null;
    };
  };
};

export type GetGoalFeesDetailQueryVariables = Exact<{
  startDate: Scalars['Time']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetGoalFeesDetailQuery = {
  __typename?: 'Query';
  roboGoals: {
    __typename?: 'RoboGoalConnection';
    edges?: Array<{
      __typename?: 'RoboGoalEdge';
      node?: {
        __typename?: 'RoboGoal';
        id: string;
        name: string;
        actionsAggregation: {
          __typename?: 'RoboActionsAggregationsTimeSeries';
          timestamps: Array<any>;
          fees: Array<any>;
        };
      } | null;
    } | null> | null;
  };
};

export type GetGoalSummaryCardWidgetQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  startDate: Scalars['Time']['input'];
}>;

export type GetGoalSummaryCardWidgetQuery = {
  __typename?: 'Query';
  roboGoals: {
    __typename?: 'RoboGoalConnection';
    edges?: Array<{
      __typename?: 'RoboGoalEdge';
      node?: {
        __typename?: 'RoboGoal';
        id: string;
        name: string;
        totalFees: any;
        totalDividends: any;
        parameters?: {
          __typename?: 'RoboGoalParameters';
          initialInvestment: any;
          targetAmount: any;
          infusionFreq: RoboGoalParametersRoboInfusionFreq;
          infusionAmount: any;
        } | null;
        allocation: { __typename?: 'RoboAllocation'; totalValue: any };
        fundingAccount?: {
          __typename?: 'ExternalAccount';
          mask: string;
          institution: { __typename?: 'Institution'; name: string };
        } | null;
        actionsAggregation: {
          __typename?: 'RoboActionsAggregationsTimeSeries';
          timestamps: Array<any>;
          fees: Array<any>;
        };
        infusionRecurringRule?: { __typename?: 'RecurringRule'; lifetimeAmount: any } | null;
      } | null;
    } | null> | null;
  };
};

export type GetRoboAccountPortfolioDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetRoboAccountPortfolioDetailsQuery = {
  __typename?: 'Query';
  roboGoals: {
    __typename?: 'RoboGoalConnection';
    edges?: Array<{
      __typename?: 'RoboGoalEdge';
      node?: {
        __typename?: 'RoboGoal';
        id: string;
        accountPortfolio: { __typename?: 'UserAccountPortfolio'; totalValue: any; id: string };
        allocation: { __typename?: 'RoboAllocation'; withdrawableValue: any };
      } | null;
    } | null> | null;
  };
  roboGeneralInvestments: {
    __typename?: 'RoboGeneralInvestmentConnection';
    edges?: Array<{
      __typename?: 'RoboGeneralInvestmentEdge';
      node?: {
        __typename?: 'RoboGeneralInvestment';
        id: string;
        accountPortfolio: { __typename?: 'UserAccountPortfolio'; totalValue: any; id: string };
        allocation: { __typename?: 'RoboAllocation'; withdrawableValue: any };
      } | null;
    } | null> | null;
  };
};

export type GetRoboStockSleeveScreenQueryVariables = Exact<{
  isRobo: Scalars['Boolean']['input'];
  portfolioID?: InputMaybe<Scalars['ID']['input']>;
  roboID?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetRoboStockSleeveScreenQuery = {
  __typename?: 'Query';
  modelPortfolios?: {
    __typename?: 'ModelPortfolioConnection';
    edges?: Array<{
      __typename?: 'ModelPortfolioEdge';
      node?: {
        __typename?: 'ModelPortfolio';
        id: string;
        sectors?: Array<{
          __typename?: 'ModelPortfolioSectorComposition';
          targetPct: any;
          sector: {
            __typename?: 'ModelPortfolioSector';
            id: string;
            name: string;
            description: string;
            uxType: ModelPortfolioSectorUxType;
            assets: Array<{
              __typename?: 'ModelPortfolioAssetComposition';
              targetPct: any;
              asset:
                | { __typename?: 'CryptoAsset'; id: string; symbol: string; name: string }
                | { __typename?: 'FiatAsset'; id: string; symbol: string; name: string }
                | { __typename?: 'SecurityAsset'; id: string; symbol: string; name: string };
            }>;
          };
        }> | null;
      } | null;
    } | null> | null;
  };
  roboGeneralInvestments?: {
    __typename?: 'RoboGeneralInvestmentConnection';
    edges?: Array<{
      __typename?: 'RoboGeneralInvestmentEdge';
      node?: {
        __typename?: 'RoboGeneralInvestment';
        id: string;
        allocation: {
          __typename?: 'RoboAllocation';
          allocationBySector: Array<{
            __typename?: 'RoboAllocationSector';
            value: any;
            sector: { __typename?: 'ModelPortfolioSector'; id: string };
            assets: Array<{
              __typename?: 'RoboAllocationAsset';
              value: any;
              asset:
                | { __typename?: 'CryptoAsset'; id: string }
                | { __typename?: 'FiatAsset'; id: string }
                | { __typename?: 'SecurityAsset'; id: string };
            }>;
          }>;
        };
        targetPortfolio: {
          __typename?: 'ModelPortfolio';
          id: string;
          sectors?: Array<{
            __typename?: 'ModelPortfolioSectorComposition';
            targetPct: any;
            sector: {
              __typename?: 'ModelPortfolioSector';
              id: string;
              name: string;
              description: string;
              uxType: ModelPortfolioSectorUxType;
              assets: Array<{
                __typename?: 'ModelPortfolioAssetComposition';
                targetPct: any;
                asset:
                  | { __typename?: 'CryptoAsset'; id: string; symbol: string; name: string }
                  | { __typename?: 'FiatAsset'; id: string; symbol: string; name: string }
                  | { __typename?: 'SecurityAsset'; id: string; symbol: string; name: string };
              }>;
            };
          }> | null;
        };
      } | null;
    } | null> | null;
  };
  roboGoals?: {
    __typename?: 'RoboGoalConnection';
    edges?: Array<{
      __typename?: 'RoboGoalEdge';
      node?: {
        __typename?: 'RoboGoal';
        id: string;
        allocation: {
          __typename?: 'RoboAllocation';
          allocationBySector: Array<{
            __typename?: 'RoboAllocationSector';
            value: any;
            sector: { __typename?: 'ModelPortfolioSector'; id: string };
            assets: Array<{
              __typename?: 'RoboAllocationAsset';
              value: any;
              asset:
                | { __typename?: 'CryptoAsset'; id: string }
                | { __typename?: 'FiatAsset'; id: string }
                | { __typename?: 'SecurityAsset'; id: string };
            }>;
          }>;
        };
        targetPortfolio: {
          __typename?: 'ModelPortfolio';
          id: string;
          sectors?: Array<{
            __typename?: 'ModelPortfolioSectorComposition';
            targetPct: any;
            sector: {
              __typename?: 'ModelPortfolioSector';
              id: string;
              name: string;
              description: string;
              uxType: ModelPortfolioSectorUxType;
              assets: Array<{
                __typename?: 'ModelPortfolioAssetComposition';
                targetPct: any;
                asset:
                  | { __typename?: 'CryptoAsset'; id: string; symbol: string; name: string }
                  | { __typename?: 'FiatAsset'; id: string; symbol: string; name: string }
                  | { __typename?: 'SecurityAsset'; id: string; symbol: string; name: string };
              }>;
            };
          }> | null;
        };
      } | null;
    } | null> | null;
  };
};

export type StockSleeveScreenModelPortfolioFragmentFragment = {
  __typename?: 'ModelPortfolio';
  id: string;
  sectors?: Array<{
    __typename?: 'ModelPortfolioSectorComposition';
    targetPct: any;
    sector: {
      __typename?: 'ModelPortfolioSector';
      id: string;
      name: string;
      description: string;
      uxType: ModelPortfolioSectorUxType;
      assets: Array<{
        __typename?: 'ModelPortfolioAssetComposition';
        targetPct: any;
        asset:
          | { __typename?: 'CryptoAsset'; id: string; symbol: string; name: string }
          | { __typename?: 'FiatAsset'; id: string; symbol: string; name: string }
          | { __typename?: 'SecurityAsset'; id: string; symbol: string; name: string };
      }>;
    };
  }> | null;
};

export type GetGoalsAndGeneralInvestmentsQueryVariables = Exact<{ [key: string]: never }>;

export type GetGoalsAndGeneralInvestmentsQuery = {
  __typename?: 'Query';
  roboGoals: {
    __typename?: 'RoboGoalConnection';
    edges?: Array<{
      __typename?: 'RoboGoalEdge';
      node?: { __typename?: 'RoboGoal'; id: string } | null;
    } | null> | null;
  };
  roboGeneralInvestments: {
    __typename?: 'RoboGeneralInvestmentConnection';
    edges?: Array<{
      __typename?: 'RoboGeneralInvestmentEdge';
      node?: { __typename?: 'RoboGeneralInvestment'; id: string } | null;
    } | null> | null;
  };
};

export type RoboGoalParametersFragment = {
  __typename?: 'RoboGoalParameters';
  endDate: any;
  riskProfile: UserRiskTolerance;
  targetAmount: any;
  infusionAmount: any;
  infusionFreq: RoboGoalParametersRoboInfusionFreq;
  initialInvestment: any;
  goalPriority: RoboGoalParametersGoalPriority;
  infusionStartDate?: any | null;
};

export type RoboGoalProjectionFragment = {
  __typename?: 'RoboGoalProjection';
  goalProbability: number;
  goalTargetProbability: number;
  suggestedOneTimeTopUp: number;
  suggestedMonthlyTopUpAccumulation: number;
  wealthPath: {
    __typename?: 'GoalValueProjections';
    years: Array<number>;
    default?: { __typename?: 'GoalValueProjection'; wealthPath: Array<number> } | null;
    pessimistic?: { __typename?: 'GoalValueProjection'; wealthPath: Array<number> } | null;
    optimistic?: { __typename?: 'GoalValueProjection'; wealthPath: Array<number> } | null;
  };
};

export type GetRoboGoalsScreenQueryVariables = Exact<{
  goalUid?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetRoboGoalsScreenQuery = {
  __typename?: 'Query';
  roboGoals: {
    __typename?: 'RoboGoalConnection';
    edges?: Array<{
      __typename?: 'RoboGoalEdge';
      node?: {
        __typename?: 'RoboGoal';
        id: string;
        name: string;
        createTime: any;
        nextScheduledInfusion: any;
        allocation: {
          __typename?: 'RoboAllocation';
          fiatPct: any;
          stockPct: any;
          bondPct: any;
          totalValue: any;
          drift: number;
        };
        projection?: {
          __typename?: 'RoboGoalProjection';
          goalProbability: number;
          goalTargetProbability: number;
          suggestedOneTimeTopUp: number;
          suggestedMonthlyTopUpAccumulation: number;
          wealthPath: {
            __typename?: 'GoalValueProjections';
            years: Array<number>;
            default?: { __typename?: 'GoalValueProjection'; wealthPath: Array<number> } | null;
            pessimistic?: { __typename?: 'GoalValueProjection'; wealthPath: Array<number> } | null;
            optimistic?: { __typename?: 'GoalValueProjection'; wealthPath: Array<number> } | null;
          };
        } | null;
        parameters?: {
          __typename?: 'RoboGoalParameters';
          endDate: any;
          riskProfile: UserRiskTolerance;
          targetAmount: any;
          infusionAmount: any;
          infusionFreq: RoboGoalParametersRoboInfusionFreq;
          initialInvestment: any;
          goalPriority: RoboGoalParametersGoalPriority;
          infusionStartDate?: any | null;
        } | null;
        targetPortfolio: {
          __typename?: 'ModelPortfolio';
          fiatPct: any;
          stockPct: any;
          bondPct: any;
        };
      } | null;
    } | null> | null;
  };
};

export type GetRoboEtfDetailsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  secId: Scalars['ID']['input'];
}>;

export type GetRoboEtfDetailsQuery = {
  __typename?: 'Query';
  securityAssets: {
    __typename?: 'SecurityAssetConnection';
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        id: string;
        name: string;
        symbol: string;
        description?: string | null;
        market?: { __typename?: 'AssetMarketData'; last: any; previousDayClose: any } | null;
        details?:
          | {
              __typename?: 'SecurityETFDetails';
              netAssetValue: any;
              yield30Day: any;
              expenseRatio: any;
            }
          | { __typename?: 'SecurityFixedIncomeDetails' }
          | { __typename?: 'SecurityMutualFundDetails' }
          | null;
      } | null;
    } | null> | null;
  };
  roboGoals: {
    __typename?: 'RoboGoalConnection';
    edges?: Array<{
      __typename?: 'RoboGoalEdge';
      node?: {
        __typename?: 'RoboGoal';
        allocation: {
          __typename?: 'RoboAllocation';
          allocation: Array<{
            __typename?: 'RoboAllocationAsset';
            percentage: any;
            asset:
              | { __typename?: 'CryptoAsset'; id: string }
              | { __typename?: 'FiatAsset'; id: string }
              | { __typename?: 'SecurityAsset'; id: string };
          }>;
        };
        accountPortfolio: {
          __typename?: 'UserAccountPortfolio';
          assetBalances: {
            __typename?: 'UserAccountPortfolioAssetConnection';
            edges?: Array<{
              __typename?: 'UserAccountPortfolioAssetEdge';
              node?: {
                __typename?: 'UserAccountPortfolioAsset';
                asset?:
                  | { __typename?: 'CryptoAsset'; id: string }
                  | { __typename?: 'FiatAsset'; id: string }
                  | { __typename?: 'SecurityAsset'; id: string }
                  | null;
                balances?: {
                  __typename?: 'UserAccountPortfolioAssetBalance';
                  costBasis: any;
                  share: any;
                } | null;
              } | null;
            } | null> | null;
          };
        };
      } | null;
    } | null> | null;
  };
};

export type GetRoboEtfSectorQueryVariables = Exact<{
  secId: Scalars['ID']['input'];
}>;

export type GetRoboEtfSectorQuery = {
  __typename?: 'Query';
  securityAssets: {
    __typename?: 'SecurityAssetConnection';
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        details?:
          | {
              __typename?: 'SecurityETFDetails';
              sectorCount: number;
              holdingsCount: number;
              sectors?: Array<{
                __typename?: 'ETFSector';
                name: string;
                percentage: number;
              }> | null;
            }
          | { __typename?: 'SecurityFixedIncomeDetails' }
          | { __typename?: 'SecurityMutualFundDetails' }
          | null;
      } | null;
    } | null> | null;
  };
};

export type GetRoboEtfHoldingQueryVariables = Exact<{
  secId: Scalars['ID']['input'];
}>;

export type GetRoboEtfHoldingQuery = {
  __typename?: 'Query';
  securityAssets: {
    __typename?: 'SecurityAssetConnection';
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        name: string;
        details?:
          | {
              __typename?: 'SecurityETFDetails';
              holdingsCount: number;
              holdings?: Array<{
                __typename?: 'ETFHolding';
                percentage: number;
                asset: { __typename?: 'HoldingAsset'; name: string; symbol: string };
              }> | null;
            }
          | { __typename?: 'SecurityFixedIncomeDetails' }
          | { __typename?: 'SecurityMutualFundDetails' }
          | null;
      } | null;
    } | null> | null;
  };
};

export type GetRoboEtfSubstitutionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetRoboEtfSubstitutionQuery = {
  __typename?: 'Query';
  roboGoals: {
    __typename?: 'RoboGoalConnection';
    edges?: Array<{
      __typename?: 'RoboGoalEdge';
      node?: {
        __typename?: 'RoboGoal';
        targetPortfolio: {
          __typename?: 'ModelPortfolio';
          availableSubstitutions?: Array<{
            __typename?: 'ModelPortfolioAssetSubstitution';
            id: string;
            default:
              | {
                  __typename?: 'CryptoAsset';
                  iconURI?: string | null;
                  id: string;
                  name: string;
                  symbol: string;
                }
              | {
                  __typename?: 'FiatAsset';
                  iconURI?: string | null;
                  id: string;
                  name: string;
                  symbol: string;
                }
              | {
                  __typename?: 'SecurityAsset';
                  iconURI?: string | null;
                  id: string;
                  name: string;
                  symbol: string;
                };
            substitution:
              | {
                  __typename?: 'CryptoAsset';
                  iconURI?: string | null;
                  id: string;
                  name: string;
                  symbol: string;
                }
              | {
                  __typename?: 'FiatAsset';
                  iconURI?: string | null;
                  id: string;
                  name: string;
                  symbol: string;
                }
              | {
                  __typename?: 'SecurityAsset';
                  iconURI?: string | null;
                  id: string;
                  name: string;
                  symbol: string;
                };
          }> | null;
        };
      } | null;
    } | null> | null;
  };
};

export type GetRoboEtfDistributionsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetRoboEtfDistributionsQuery = {
  __typename?: 'Query';
  securityAssets: {
    __typename?: 'SecurityAssetConnection';
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        id: string;
        name: string;
        symbol: string;
        financials?: {
          __typename?: 'SecurityFinancials';
          dividends?: Array<{
            __typename?: 'Dividend';
            cashAmount: any;
            frequency: number;
            payDate: any;
          }> | null;
        } | null;
      } | null;
    } | null> | null;
  };
};

export type GetAllRoboDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllRoboDetailsQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserConnection';
    edges?: Array<{
      __typename?: 'UserEdge';
      node?: {
        __typename?: 'User';
        roboGoals: {
          __typename?: 'RoboGoalConnection';
          edges?: Array<{
            __typename?: 'RoboGoalEdge';
            node?: {
              __typename?: 'RoboGoal';
              id: string;
              targetPortfolioID: string;
              accountPortfolioID: string;
              name: string;
              isActive: boolean;
              isAutoInfusionPaused: boolean;
              accountPortfolio: {
                __typename?: 'UserAccountPortfolio';
                totalValue: any;
                id: string;
                name?: string | null;
                account: { __typename?: 'UserAccount'; id: string; externalID?: string | null };
              };
              allocation: { __typename?: 'RoboAllocation'; totalValue: any };
              parameters?: {
                __typename?: 'RoboGoalParameters';
                targetAmount: any;
                riskProfile: UserRiskTolerance;
                goalPriority: RoboGoalParametersGoalPriority;
              } | null;
              projection?: { __typename?: 'RoboGoalProjection'; goalProbability: number } | null;
              fundingAccount?: { __typename?: 'ExternalAccount'; accountNumber: string } | null;
            } | null;
          } | null> | null;
        };
      } | null;
    } | null> | null;
  };
};

export type GetRoboGoalsV2QueryVariables = Exact<{
  where?: InputMaybe<RoboGoalWhereInput>;
}>;

export type GetRoboGoalsV2Query = {
  __typename?: 'Query';
  roboGoals: {
    __typename?: 'RoboGoalConnection';
    edges?: Array<{
      __typename?: 'RoboGoalEdge';
      node?: {
        __typename?: 'RoboGoal';
        id: string;
        targetPortfolioID: string;
        accountPortfolioID: string;
        name: string;
        isActive: boolean;
        nextScheduledInfusion: any;
        isAutoInfusionPaused: boolean;
        accountPortfolio: {
          __typename?: 'UserAccountPortfolio';
          totalValue: any;
          id: string;
          name?: string | null;
          account: { __typename?: 'UserAccount'; id: string; externalID?: string | null };
        };
        allocation: {
          __typename?: 'RoboAllocation';
          totalValue: any;
          stockPct: any;
          bondPct: any;
          fiatPct: any;
        };
        parameters?: {
          __typename?: 'RoboGoalParameters';
          targetAmount: any;
          riskProfile: UserRiskTolerance;
          goalPriority: RoboGoalParametersGoalPriority;
          initialInvestment: any;
          infusionAmount: any;
          infusionFreq: RoboGoalParametersRoboInfusionFreq;
          endDate: any;
        } | null;
        projection?: { __typename?: 'RoboGoalProjection'; goalProbability: number } | null;
        fundingAccount?: { __typename?: 'ExternalAccount'; accountNumber: string } | null;
      } | null;
    } | null> | null;
  };
};

export type GetRoboKycQueryVariables = Exact<{ [key: string]: never }>;

export type GetRoboKycQuery = {
  __typename?: 'Query';
  userAccounts: {
    __typename?: 'UserAccountConnection';
    edges?: Array<{
      __typename?: 'UserAccountEdge';
      node?: {
        __typename?: 'UserAccount';
        type: UserAccountAccountType;
        discretionaryAccountKyc?: {
          __typename?: 'DiscretionaryAccountKyc';
          riskTolerance?: UserRiskTolerance | null;
          employmentStatus?: DiscretionaryAccountKycUserEmploymentStatus | null;
          investmentObjective?: DiscretionaryAccountKycUserInvestmentObjective | null;
          annualIncome?: DiscretionaryAccountKycUserAnnualIncome | null;
          netWorth?: DiscretionaryAccountKycUserNetWorth | null;
          employmentDetails?: {
            __typename?: 'EmploymentDetails';
            EmployerName: string;
            Position: string;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  };
};

export type GetSubstitutionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSubstitutionsQuery = {
  __typename?: 'Query';
  modelPortfolios: {
    __typename?: 'ModelPortfolioConnection';
    edges?: Array<{
      __typename?: 'ModelPortfolioEdge';
      node?: {
        __typename?: 'ModelPortfolio';
        id: string;
        availableSubstitutions?: Array<{
          __typename?: 'ModelPortfolioAssetSubstitution';
          id: string;
          createTime: any;
          default:
            | {
                __typename?: 'CryptoAsset';
                iconURI?: string | null;
                id: string;
                name: string;
                symbol: string;
              }
            | {
                __typename?: 'FiatAsset';
                iconURI?: string | null;
                id: string;
                name: string;
                symbol: string;
              }
            | {
                __typename?: 'SecurityAsset';
                iconURI?: string | null;
                id: string;
                name: string;
                symbol: string;
              };
          substitution:
            | {
                __typename?: 'CryptoAsset';
                iconURI?: string | null;
                id: string;
                name: string;
                symbol: string;
              }
            | {
                __typename?: 'FiatAsset';
                iconURI?: string | null;
                id: string;
                name: string;
                symbol: string;
              }
            | {
                __typename?: 'SecurityAsset';
                iconURI?: string | null;
                id: string;
                name: string;
                symbol: string;
              };
        }> | null;
      } | null;
    } | null> | null;
  };
};

export type CommonRtqPortfolioLevelFragment = {
  __typename?: 'RTQPortfolioLevelSet';
  levels?: Array<{
    __typename?: 'RTQPortfolioLevel';
    id: string;
    index: number;
    name: string;
    description: string;
    assignments?: Array<{
      __typename?: 'RTQPortfolioLevelModelAssignment';
      modelPortfolio: {
        __typename?: 'ModelPortfolio';
        id: string;
        stockPct: any;
        bondPct: any;
        fiatPct: any;
        sectors?: Array<{
          __typename?: 'ModelPortfolioSectorComposition';
          id: string;
          targetPct: any;
          sector: {
            __typename?: 'ModelPortfolioSector';
            id: string;
            name: string;
            description: string;
            category: ModelPortfolioSectorCategory;
            assets: Array<{
              __typename?: 'ModelPortfolioAssetComposition';
              targetPct: any;
              asset:
                | {
                    __typename?: 'CryptoAsset';
                    imageLogo?: string | null;
                    id: string;
                    symbol: string;
                    name: string;
                  }
                | {
                    __typename?: 'FiatAsset';
                    imageLogo?: string | null;
                    id: string;
                    symbol: string;
                    name: string;
                  }
                | {
                    __typename?: 'SecurityAsset';
                    imageLogo?: string | null;
                    id: string;
                    symbol: string;
                    name: string;
                  };
            }>;
          };
        }> | null;
      };
    }> | null;
  }> | null;
};

export type GetRtqPortfoliosQueryVariables = Exact<{ [key: string]: never }>;

export type GetRtqPortfoliosQuery = {
  __typename?: 'Query';
  rtqPortfolioLevelSets: {
    __typename?: 'RTQPortfolioLevelSetConnection';
    edges?: Array<{
      __typename?: 'RTQPortfolioLevelSetEdge';
      node?: {
        __typename?: 'RTQPortfolioLevelSet';
        id: string;
        levels?: Array<{
          __typename?: 'RTQPortfolioLevel';
          id: string;
          index: number;
          name: string;
          description: string;
          assignments?: Array<{
            __typename?: 'RTQPortfolioLevelModelAssignment';
            modelPortfolio: {
              __typename?: 'ModelPortfolio';
              id: string;
              stockPct: any;
              bondPct: any;
              fiatPct: any;
              sectors?: Array<{
                __typename?: 'ModelPortfolioSectorComposition';
                id: string;
                targetPct: any;
                sector: {
                  __typename?: 'ModelPortfolioSector';
                  id: string;
                  name: string;
                  description: string;
                  category: ModelPortfolioSectorCategory;
                  assets: Array<{
                    __typename?: 'ModelPortfolioAssetComposition';
                    targetPct: any;
                    asset:
                      | {
                          __typename?: 'CryptoAsset';
                          imageLogo?: string | null;
                          id: string;
                          symbol: string;
                          name: string;
                        }
                      | {
                          __typename?: 'FiatAsset';
                          imageLogo?: string | null;
                          id: string;
                          symbol: string;
                          name: string;
                        }
                      | {
                          __typename?: 'SecurityAsset';
                          imageLogo?: string | null;
                          id: string;
                          symbol: string;
                          name: string;
                        };
                  }>;
                };
              }> | null;
            };
          }> | null;
        }> | null;
      } | null;
    } | null> | null;
  };
};

export type RtqQuestionsFragment = {
  __typename?: 'RTQQuestion';
  label: string;
  type: RtqQuestionRtqQuestionType;
  category: RtqQuestionRtqRiskCategory;
  id: string;
  options?: Array<{
    __typename?: 'RTQResponseOption';
    id: string;
    label: string;
    score: number;
  }> | null;
};

export type RtqPortfolioLevelScoresFragment = {
  __typename?: 'RTQPortfolioLevelScore';
  riskTolerance: { __typename?: 'RTQLevelScoreRange'; min: number; max: number };
  riskCapacity: { __typename?: 'RTQLevelScoreRange'; min: number; max: number };
  level: { __typename?: 'RTQPortfolioLevel'; id: string; name: string };
};

export type GetRtqQuestionnaireQueryVariables = Exact<{ [key: string]: never }>;

export type GetRtqQuestionnaireQuery = {
  __typename?: 'Query';
  rtqQuestionnaires: {
    __typename?: 'RTQQuestionnaireConnection';
    edges?: Array<{
      __typename?: 'RTQQuestionnaireEdge';
      node?: {
        __typename?: 'RTQQuestionnaire';
        id: string;
        questions?: Array<{
          __typename?: 'RTQQuestion';
          label: string;
          type: RtqQuestionRtqQuestionType;
          category: RtqQuestionRtqRiskCategory;
          id: string;
          options?: Array<{
            __typename?: 'RTQResponseOption';
            id: string;
            label: string;
            score: number;
          }> | null;
        }> | null;
        portfolioLevelScores: Array<{
          __typename?: 'RTQPortfolioLevelScore';
          riskTolerance: { __typename?: 'RTQLevelScoreRange'; min: number; max: number };
          riskCapacity: { __typename?: 'RTQLevelScoreRange'; min: number; max: number };
          level: { __typename?: 'RTQPortfolioLevel'; id: string; name: string };
        }>;
      } | null;
    } | null> | null;
  };
};

export type GetActiveRtqSubmissionByUserIdQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type GetActiveRtqSubmissionByUserIdQuery = {
  __typename?: 'Query';
  rtqSubmissions: {
    __typename?: 'RTQSubmissionConnection';
    edges?: Array<{
      __typename?: 'RTQSubmissionEdge';
      node?: {
        __typename?: 'RTQSubmission';
        responses?: Array<{
          __typename?: 'RTQResponse';
          question: { __typename?: 'RTQQuestion'; id: string };
          option: { __typename?: 'RTQResponseOption'; id: string };
        }> | null;
      } | null;
    } | null> | null;
  };
};

export type SecurityAssetBasicDetailsFragment = {
  __typename?: 'SecurityAsset';
  id: string;
  createTime: any;
  updateTime: any;
  name: string;
  description?: string | null;
  symbol: string;
  iconURI?: string | null;
  logoURI?: string | null;
  highlyVolatile: boolean;
  complexSecurity: boolean;
  market?: {
    __typename?: 'AssetMarketData';
    volume: any;
    previousDayClose: any;
    last: any;
    close: any;
    open: any;
    bidSize: any;
    askSize: any;
    baseMin?: any | null;
  } | null;
};

export type SecurityAssetDetailedDataFragment = {
  __typename?: 'SecurityAsset';
  marketCap?: any | null;
  securityType: SecurityAssetSecurityType;
  beta: any;
  cusip: string;
  id: string;
  createTime: any;
  updateTime: any;
  name: string;
  description?: string | null;
  symbol: string;
  iconURI?: string | null;
  logoURI?: string | null;
  highlyVolatile: boolean;
  complexSecurity: boolean;
  financials?: {
    __typename?: 'SecurityFinancials';
    peRatio?: any | null;
    statements?: Array<{
      __typename?: 'FinancialStatement';
      revenue: any;
      ebitda: any;
      earningsPerShare: any;
      profitMargin: any;
      bookValuePerShare: any;
    }> | null;
    dividends?: Array<{ __typename?: 'Dividend'; cashAmount: any }> | null;
  } | null;
  market?: {
    __typename?: 'AssetMarketData';
    volume: any;
    previousDayClose: any;
    last: any;
    close: any;
    open: any;
    bidSize: any;
    askSize: any;
    baseMin?: any | null;
    historical: { __typename?: 'OHLCV'; volume: Array<any> };
  } | null;
  askExchange?: {
    __typename?: 'SecurityExchange';
    id: string;
    logo?: string | null;
    name: string;
  } | null;
  bidExchange?: {
    __typename?: 'SecurityExchange';
    id: string;
    logo?: string | null;
    name: string;
  } | null;
  sector?: { __typename?: 'SecuritySector'; id: string; name: string } | null;
};

export type SecurityAssetBasicDetailsQueryQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
}>;

export type SecurityAssetBasicDetailsQueryQuery = {
  __typename?: 'Query';
  securityAssets: {
    __typename?: 'SecurityAssetConnection';
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        id: string;
        createTime: any;
        updateTime: any;
        name: string;
        description?: string | null;
        symbol: string;
        iconURI?: string | null;
        logoURI?: string | null;
        highlyVolatile: boolean;
        complexSecurity: boolean;
        market?: {
          __typename?: 'AssetMarketData';
          volume: any;
          previousDayClose: any;
          last: any;
          close: any;
          open: any;
          bidSize: any;
          askSize: any;
          baseMin?: any | null;
        } | null;
      } | null;
    } | null> | null;
  };
};

export type SecurityAssetDetailsQueryVariables = Exact<{
  securityId: Scalars['ID']['input'];
}>;

export type SecurityAssetDetailsQuery = {
  __typename?: 'Query';
  securityAssets: {
    __typename?: 'SecurityAssetConnection';
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        marketCap?: any | null;
        securityType: SecurityAssetSecurityType;
        beta: any;
        cusip: string;
        id: string;
        createTime: any;
        updateTime: any;
        name: string;
        description?: string | null;
        symbol: string;
        iconURI?: string | null;
        logoURI?: string | null;
        highlyVolatile: boolean;
        complexSecurity: boolean;
        financials?: {
          __typename?: 'SecurityFinancials';
          peRatio?: any | null;
          statements?: Array<{
            __typename?: 'FinancialStatement';
            revenue: any;
            ebitda: any;
            earningsPerShare: any;
            profitMargin: any;
            bookValuePerShare: any;
          }> | null;
          dividends?: Array<{ __typename?: 'Dividend'; cashAmount: any }> | null;
        } | null;
        market?: {
          __typename?: 'AssetMarketData';
          volume: any;
          previousDayClose: any;
          last: any;
          close: any;
          open: any;
          bidSize: any;
          askSize: any;
          baseMin?: any | null;
          historical: { __typename?: 'OHLCV'; volume: Array<any> };
        } | null;
        askExchange?: {
          __typename?: 'SecurityExchange';
          id: string;
          logo?: string | null;
          name: string;
        } | null;
        bidExchange?: {
          __typename?: 'SecurityExchange';
          id: string;
          logo?: string | null;
          name: string;
        } | null;
        sector?: { __typename?: 'SecuritySector'; id: string; name: string } | null;
      } | null;
    } | null> | null;
  };
};

export type GetSecurityTradingDataQueryVariables = Exact<{
  balanceIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  portfolioId: Scalars['ID']['input'];
  securityId: Scalars['ID']['input'];
}>;

export type GetSecurityTradingDataQuery = {
  __typename?: 'Query';
  securityAssets: {
    __typename?: 'SecurityAssetConnection';
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        securityType: SecurityAssetSecurityType;
        id: string;
        createTime: any;
        updateTime: any;
        name: string;
        description?: string | null;
        symbol: string;
        iconURI?: string | null;
        logoURI?: string | null;
        highlyVolatile: boolean;
        complexSecurity: boolean;
        details?:
          | { __typename?: 'SecurityETFDetails' }
          | { __typename?: 'SecurityFixedIncomeDetails' }
          | { __typename?: 'SecurityMutualFundDetails'; transactionFee: any }
          | null;
        market?: {
          __typename?: 'AssetMarketData';
          volume: any;
          previousDayClose: any;
          last: any;
          close: any;
          open: any;
          bidSize: any;
          askSize: any;
          baseMin?: any | null;
        } | null;
      } | null;
    } | null> | null;
  };
  userAccountPortfolioAssets: {
    __typename?: 'UserAccountPortfolioAssetConnection';
    edges?: Array<{
      __typename?: 'UserAccountPortfolioAssetEdge';
      node?: {
        __typename?: 'UserAccountPortfolioAsset';
        id: string;
        asset?:
          | { __typename?: 'CryptoAsset'; id: string; name: string }
          | { __typename?: 'FiatAsset'; id: string; name: string }
          | { __typename?: 'SecurityAsset'; id: string; name: string }
          | null;
        balances?: {
          __typename?: 'UserAccountPortfolioAssetBalance';
          balance: any;
          settledBalance: any;
          pendingBalance: any;
          tradeable: any;
        } | null;
      } | null;
    } | null> | null;
  };
};

export type GetSecurityForUserQueryVariables = Exact<{
  securityID: Scalars['ID']['input'];
  accountID: Scalars['ID']['input'];
}>;

export type GetSecurityForUserQuery = {
  __typename?: 'Query';
  securityAssets: {
    __typename?: 'SecurityAssetConnection';
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        id: string;
        createTime: any;
        updateTime: any;
        name: string;
        description?: string | null;
        symbol: string;
        iconURI?: string | null;
        logoURI?: string | null;
        highlyVolatile: boolean;
        complexSecurity: boolean;
        sector?: { __typename?: 'SecuritySector'; id: string; name: string } | null;
        financials?: {
          __typename?: 'SecurityFinancials';
          dividends?: Array<{ __typename?: 'Dividend'; cashAmount: any }> | null;
        } | null;
        market?: {
          __typename?: 'AssetMarketData';
          volume: any;
          previousDayClose: any;
          last: any;
          close: any;
          open: any;
          bidSize: any;
          askSize: any;
          baseMin?: any | null;
        } | null;
      } | null;
    } | null> | null;
  };
  userAccountPortfolios: {
    __typename?: 'UserAccountPortfolioConnection';
    edges?: Array<{
      __typename?: 'UserAccountPortfolioEdge';
      node?: {
        __typename?: 'UserAccountPortfolio';
        id: string;
        totalValue: any;
        assetBalances: {
          __typename?: 'UserAccountPortfolioAssetConnection';
          edges?: Array<{
            __typename?: 'UserAccountPortfolioAssetEdge';
            node?: {
              __typename?: 'UserAccountPortfolioAsset';
              costBasis: any;
              balances?: {
                __typename?: 'UserAccountPortfolioAssetBalance';
                activeBalance: any;
              } | null;
            } | null;
          } | null> | null;
        };
      } | null;
    } | null> | null;
  };
  dividendDistributions: {
    __typename?: 'DividendDistributionConnection';
    edges?: Array<{
      __typename?: 'DividendDistributionEdge';
      node?: {
        __typename?: 'DividendDistribution';
        amount: any;
        distributionAsset?:
          | { __typename?: 'CryptoAsset'; id: string }
          | { __typename?: 'FiatAsset'; id: string }
          | { __typename?: 'SecurityAsset'; id: string }
          | null;
      } | null;
    } | null> | null;
  };
};

export type GetStockPriceDataQueryVariables = Exact<{
  securityId: Scalars['ID']['input'];
  period: TimePeriod;
  start: Scalars['Time']['input'];
  end?: InputMaybe<Scalars['Time']['input']>;
}>;

export type GetStockPriceDataQuery = {
  __typename?: 'Query';
  securityAssets: {
    __typename?: 'SecurityAssetConnection';
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        id: string;
        symbol: string;
        market?: {
          __typename?: 'AssetMarketData';
          previousDayClose: any;
          last: any;
          historical: {
            __typename?: 'OHLCV';
            start: any;
            close: Array<any>;
            avgPrice: Array<any>;
            timestamps: Array<any>;
          };
        } | null;
      } | null;
    } | null> | null;
  };
};

export type GetUserHasSharesQueryVariables = Exact<{
  securityID: Scalars['ID']['input'];
}>;

export type GetUserHasSharesQuery = {
  __typename?: 'Query';
  userAccountPortfolioAssets: {
    __typename?: 'UserAccountPortfolioAssetConnection';
    edges?: Array<{
      __typename?: 'UserAccountPortfolioAssetEdge';
      node?: {
        __typename?: 'UserAccountPortfolioAsset';
        balances?: { __typename?: 'UserAccountPortfolioAssetBalance'; activeBalance: any } | null;
      } | null;
    } | null> | null;
  };
};

export type GetSecuritySectorsChartQueryVariables = Exact<{
  securityID: Scalars['ID']['input'];
}>;

export type GetSecuritySectorsChartQuery = {
  __typename?: 'Query';
  securityAssets: {
    __typename?: 'SecurityAssetConnection';
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        details?:
          | {
              __typename?: 'SecurityETFDetails';
              sectorCount: number;
              sectors?: Array<{
                __typename?: 'ETFSector';
                name: string;
                percentage: number;
              }> | null;
            }
          | { __typename?: 'SecurityFixedIncomeDetails' }
          | { __typename?: 'SecurityMutualFundDetails' }
          | null;
      } | null;
    } | null> | null;
  };
};

export type GetSecurityTop10HoldingsChartQueryVariables = Exact<{
  securityId: Scalars['ID']['input'];
}>;

export type GetSecurityTop10HoldingsChartQuery = {
  __typename?: 'Query';
  securityAssets: {
    __typename?: 'SecurityAssetConnection';
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        name: string;
        details?:
          | {
              __typename: 'SecurityETFDetails';
              holdings?: Array<{
                __typename?: 'ETFHolding';
                percentage: number;
                asset: { __typename?: 'HoldingAsset'; name: string; symbol: string };
              }> | null;
            }
          | { __typename?: 'SecurityFixedIncomeDetails' }
          | {
              __typename: 'SecurityMutualFundDetails';
              holdings?: Array<{
                __typename?: 'MutualFundHolding';
                percentage: number;
                asset: { __typename?: 'HoldingAsset'; name: string; symbol: string };
              }> | null;
            }
          | null;
      } | null;
    } | null> | null;
  };
};

export type GetMfSecurityBuyScreenInformationQueryVariables = Exact<{
  securityID: Scalars['ID']['input'];
}>;

export type GetMfSecurityBuyScreenInformationQuery = {
  __typename?: 'Query';
  securityAssets: {
    __typename?: 'SecurityAssetConnection';
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        id: string;
        details?:
          | { __typename: 'SecurityETFDetails' }
          | { __typename: 'SecurityFixedIncomeDetails' }
          | {
              __typename: 'SecurityMutualFundDetails';
              additionalInvestmentMinimum: any;
              distributionServiceFee: any;
              expenseRatioGross: any;
              expenseRatioNet: any;
              initialInvestmentMinimum: any;
              loadPct: any;
              loadType: string;
              transactionFee: any;
            }
          | null;
      } | null;
    } | null> | null;
  };
};

export type GetMutualFundDetailsQueryVariables = Exact<{
  securityId: Scalars['ID']['input'];
}>;

export type GetMutualFundDetailsQuery = {
  __typename?: 'Query';
  securityAssets: {
    __typename?: 'SecurityAssetConnection';
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        id: string;
        details?:
          | { __typename: 'SecurityETFDetails' }
          | { __typename: 'SecurityFixedIncomeDetails' }
          | {
              __typename: 'SecurityMutualFundDetails';
              additionalInvestmentMinimum: any;
              distributionServiceFee: any;
              expenseRatioGross: any;
              expenseRatioNet: any;
              initialInvestmentMinimum: any;
              loadPct: any;
              loadType: string;
              transactionFee: any;
              additionalGroupRetirementInvestmentMinimum: any;
              additionalIraInvestmentMinimum: any;
              annualizedStdDev: any;
              contractualExpenseWaiver: any;
              feeClass: string;
              fundCategory: string;
              fundCompany: string;
              fundInception: any;
              initialGroupRetirementInvestmentMinimum: any;
              initialIraInvestmentMinimum: any;
              mutualFundType: string;
              netAssetValue: any;
              netAssetValueChange: any;
              portfolioNetAssetValue: any;
              rSquared: any;
              shareClassNetAssetValue: any;
              sharpeRatio: any;
              turnoverRate: any;
              twelveMonthHighValue: any;
              twelveMonthLowValue: any;
              yearToDateReturns: any;
              yield30Day: any;
            }
          | null;
      } | null;
    } | null> | null;
  };
};

export type GetMutualFundPerformanceQueryVariables = Exact<{
  securityId: Scalars['ID']['input'];
}>;

export type GetMutualFundPerformanceQuery = {
  __typename?: 'Query';
  securityAssets: {
    __typename?: 'SecurityAssetConnection';
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        id: string;
        details?:
          | { __typename: 'SecurityETFDetails' }
          | { __typename: 'SecurityFixedIncomeDetails' }
          | {
              __typename: 'SecurityMutualFundDetails';
              oneYearTotalReturn: any;
              threeYearTotalReturn: any;
              fiveYearTotalReturn: any;
              tenYearTotalReturn: any;
            }
          | null;
      } | null;
    } | null> | null;
  };
};

export type GetSecurityIndexScreenQueryVariables = Exact<{
  securityID?: InputMaybe<Scalars['ID']['input']>;
  securitySymbol?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetSecurityIndexScreenQuery = {
  __typename?: 'Query';
  securityAssets: {
    __typename?: 'SecurityAssetConnection';
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        id: string;
        symbol: string;
        securityType: SecurityAssetSecurityType;
      } | null;
    } | null> | null;
  };
};

export type GetSecurityHeaderSectionQueryVariables = Exact<{
  securityID: Scalars['ID']['input'];
  accountID?: InputMaybe<Scalars['ID']['input']>;
  shouldCheckActiveBalance: Scalars['Boolean']['input'];
}>;

export type GetSecurityHeaderSectionQuery = {
  __typename?: 'Query';
  securityAssets: {
    __typename?: 'SecurityAssetConnection';
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        id: string;
        symbol: string;
        name: string;
        logoURI?: string | null;
        securityType: SecurityAssetSecurityType;
        market?: { __typename?: 'AssetMarketData'; last: any } | null;
      } | null;
    } | null> | null;
  };
  userAccounts?: {
    __typename?: 'UserAccountConnection';
    edges?: Array<{
      __typename?: 'UserAccountEdge';
      node?: {
        __typename?: 'UserAccount';
        balances?: Array<{ __typename?: 'UserAccountAssetBalance'; activeBalance: any }> | null;
      } | null;
    } | null> | null;
  };
};

export type GetSecurityMyHoldingSectionQueryVariables = Exact<{
  securityID: Scalars['ID']['input'];
  accountID: Scalars['ID']['input'];
}>;

export type GetSecurityMyHoldingSectionQuery = {
  __typename?: 'Query';
  securityAssets: {
    __typename?: 'SecurityAssetConnection';
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        id: string;
        market?: { __typename?: 'AssetMarketData'; last: any; previousDayClose: any } | null;
        financials?: {
          __typename?: 'SecurityFinancials';
          dividends?: Array<{ __typename?: 'Dividend'; cashAmount: any }> | null;
        } | null;
      } | null;
    } | null> | null;
  };
  userAccountPortfolios: {
    __typename?: 'UserAccountPortfolioConnection';
    edges?: Array<{
      __typename?: 'UserAccountPortfolioEdge';
      node?: {
        __typename?: 'UserAccountPortfolio';
        id: string;
        totalValue: any;
        assetBalances: {
          __typename?: 'UserAccountPortfolioAssetConnection';
          edges?: Array<{
            __typename?: 'UserAccountPortfolioAssetEdge';
            node?: {
              __typename?: 'UserAccountPortfolioAsset';
              costBasis: any;
              balances?: {
                __typename?: 'UserAccountPortfolioAssetBalance';
                activeBalance: any;
              } | null;
            } | null;
          } | null> | null;
        };
      } | null;
    } | null> | null;
  };
  dividendDistributions: {
    __typename?: 'DividendDistributionConnection';
    edges?: Array<{
      __typename?: 'DividendDistributionEdge';
      node?: {
        __typename?: 'DividendDistribution';
        amount: any;
        distributionAsset?:
          | { __typename?: 'CryptoAsset'; id: string }
          | { __typename?: 'FiatAsset'; id: string }
          | { __typename?: 'SecurityAsset'; id: string }
          | null;
      } | null;
    } | null> | null;
  };
};

export type GetSecurityTradingSectionQueryVariables = Exact<{
  securityID: Scalars['ID']['input'];
}>;

export type GetSecurityTradingSectionQuery = {
  __typename?: 'Query';
  securityAssets: {
    __typename?: 'SecurityAssetConnection';
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        id: string;
        askExchange?: { __typename?: 'SecurityExchange'; name: string } | null;
        bidExchange?: { __typename?: 'SecurityExchange'; name: string } | null;
        market?: {
          __typename?: 'AssetMarketData';
          askSize: any;
          bidSize: any;
          volume: any;
          historical: { __typename?: 'OHLCV'; volume: Array<any> };
        } | null;
      } | null;
    } | null> | null;
  };
};

export type GetStockSecurityDataSectionQueryVariables = Exact<{
  securityID: Scalars['ID']['input'];
}>;

export type GetStockSecurityDataSectionQuery = {
  __typename?: 'Query';
  securityAssets: {
    __typename?: 'SecurityAssetConnection';
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        id: string;
        marketCap?: any | null;
        securityType: SecurityAssetSecurityType;
        financials?: {
          __typename?: 'SecurityFinancials';
          dividendTrailingTwelveMonths: any;
          dividendYield?: any | null;
          dividends?: Array<{ __typename?: 'Dividend'; exDividendDate: any }> | null;
          statements?: Array<{
            __typename?: 'FinancialStatement';
            totalEnterpriseValue: any;
            revenue: any;
            ebitda: any;
            ebitdaMargin: any;
            ebit: any;
            profitMargin: any;
            peRatio?: any | null;
            pegRatio: any;
            earningsPerShare: any;
            bookValuePerShare: any;
            longTermDebt: any;
            totalCash: any;
          }> | null;
        } | null;
      } | null;
    } | null> | null;
  };
};

export type GetStockSecurityInfoSectionQueryVariables = Exact<{
  securityID: Scalars['ID']['input'];
}>;

export type GetStockSecurityInfoSectionQuery = {
  __typename?: 'Query';
  securityAssets: {
    __typename?: 'SecurityAssetConnection';
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        description?: string | null;
        name: string;
        sector?: { __typename?: 'SecuritySector'; name: string } | null;
      } | null;
    } | null> | null;
  };
};

export type GetSecurityNewsSectionQueryVariables = Exact<{
  securityID: Scalars['ID']['input'];
}>;

export type GetSecurityNewsSectionQuery = {
  __typename?: 'Query';
  securityAssets: {
    __typename?: 'SecurityAssetConnection';
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        news: Array<{
          __typename?: 'AssetNews';
          title: string;
          datetime: any;
          description: string;
          url: string;
          imageUrl: string;
          source: string;
        }>;
      } | null;
    } | null> | null;
  };
};

export type GetEtfSecurityInfoSectionQueryVariables = Exact<{
  securityID: Scalars['ID']['input'];
}>;

export type GetEtfSecurityInfoSectionQuery = {
  __typename?: 'Query';
  securityAssets: {
    __typename?: 'SecurityAssetConnection';
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        id: string;
        description?: string | null;
        market?: { __typename?: 'AssetMarketData'; last: any; previousDayClose: any } | null;
        details?:
          | {
              __typename: 'SecurityETFDetails';
              netAssetValue: any;
              yield30Day: any;
              expenseRatioNet: any;
            }
          | { __typename: 'SecurityFixedIncomeDetails' }
          | { __typename: 'SecurityMutualFundDetails' }
          | null;
      } | null;
    } | null> | null;
  };
};

export type GetSecurityDistributionsSectionQueryVariables = Exact<{
  securityID: Scalars['ID']['input'];
}>;

export type GetSecurityDistributionsSectionQuery = {
  __typename?: 'Query';
  securityAssets: {
    __typename?: 'SecurityAssetConnection';
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        id: string;
        name: string;
        symbol: string;
        financials?: {
          __typename?: 'SecurityFinancials';
          dividends?: Array<{
            __typename?: 'Dividend';
            cashAmount: any;
            frequency: number;
            payDate: any;
          }> | null;
        } | null;
      } | null;
    } | null> | null;
  };
};

export type GetSecuritySectorsSectionQueryVariables = Exact<{
  securityID: Scalars['ID']['input'];
}>;

export type GetSecuritySectorsSectionQuery = {
  __typename?: 'Query';
  securityAssets: {
    __typename?: 'SecurityAssetConnection';
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        id: string;
        details?:
          | {
              __typename: 'SecurityETFDetails';
              holdings?: Array<{ __typename: 'ETFHolding' }> | null;
            }
          | { __typename: 'SecurityFixedIncomeDetails' }
          | {
              __typename: 'SecurityMutualFundDetails';
              holdings?: Array<{ __typename: 'MutualFundHolding' }> | null;
            }
          | null;
      } | null;
    } | null> | null;
  };
};

export type GetSecurityTopHoldingsSectionQueryVariables = Exact<{
  securityID: Scalars['ID']['input'];
}>;

export type GetSecurityTopHoldingsSectionQuery = {
  __typename?: 'Query';
  securityAssets: {
    __typename?: 'SecurityAssetConnection';
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        id: string;
        details?:
          | {
              __typename: 'SecurityETFDetails';
              holdings?: Array<{ __typename: 'ETFHolding' }> | null;
            }
          | { __typename: 'SecurityFixedIncomeDetails' }
          | {
              __typename: 'SecurityMutualFundDetails';
              holdings?: Array<{ __typename: 'MutualFundHolding' }> | null;
            }
          | null;
      } | null;
    } | null> | null;
  };
};

export type GetMfSecurityDataSectionQueryVariables = Exact<{
  securityID: Scalars['ID']['input'];
}>;

export type GetMfSecurityDataSectionQuery = {
  __typename?: 'Query';
  securityAssets: {
    __typename?: 'SecurityAssetConnection';
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        id: string;
        beta: any;
        details?:
          | { __typename: 'SecurityETFDetails' }
          | { __typename: 'SecurityFixedIncomeDetails' }
          | {
              __typename: 'SecurityMutualFundDetails';
              transactionFee: any;
              netAssetValue: any;
              netAssetValueChange: any;
              yearToDateReturns: any;
              yield30Day: any;
              fundCategory: string;
              fundInception: any;
              loadType: string;
              loadPct: any;
              initialInvestmentMinimum: any;
              additionalInvestmentMinimum: any;
              turnoverRate: any;
              portfolioNetAssetValue: any;
              shareClassNetAssetValue: any;
              twelveMonthLowValue: any;
              twelveMonthHighValue: any;
              rSquared: any;
              sharpeRatio: any;
              annualizedStdDev: any;
            }
          | null;
      } | null;
    } | null> | null;
  };
};

export type GetMfSecurityExpensesSectionQueryVariables = Exact<{
  securityID: Scalars['ID']['input'];
}>;

export type GetMfSecurityExpensesSectionQuery = {
  __typename?: 'Query';
  securityAssets: {
    __typename?: 'SecurityAssetConnection';
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        id: string;
        details?:
          | { __typename: 'SecurityETFDetails' }
          | { __typename: 'SecurityFixedIncomeDetails' }
          | {
              __typename: 'SecurityMutualFundDetails';
              expenseRatioGross: any;
              expenseRatioNet: any;
              contractualExpenseWaiver: any;
              distributionServiceFee: any;
            }
          | null;
      } | null;
    } | null> | null;
  };
};

export type GetMfSecurityMinimumsSectionQueryVariables = Exact<{
  securityID: Scalars['ID']['input'];
}>;

export type GetMfSecurityMinimumsSectionQuery = {
  __typename?: 'Query';
  securityAssets: {
    __typename?: 'SecurityAssetConnection';
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        id: string;
        details?:
          | { __typename: 'SecurityETFDetails' }
          | { __typename: 'SecurityFixedIncomeDetails' }
          | {
              __typename: 'SecurityMutualFundDetails';
              initialInvestmentMinimum: any;
              additionalInvestmentMinimum: any;
              initialIraInvestmentMinimum: any;
              additionalIraInvestmentMinimum: any;
              initialGroupRetirementInvestmentMinimum: any;
              additionalGroupRetirementInvestmentMinimum: any;
            }
          | null;
      } | null;
    } | null> | null;
  };
};

export type GetMfSecurityInfoSectionQueryVariables = Exact<{
  securityID: Scalars['ID']['input'];
}>;

export type GetMfSecurityInfoSectionQuery = {
  __typename?: 'Query';
  securityAssets: {
    __typename?: 'SecurityAssetConnection';
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        id: string;
        name: string;
        description?: string | null;
      } | null;
    } | null> | null;
  };
};

export type GetSecuritiesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['Cursor']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  orderBy?: InputMaybe<SecurityAssetOrder>;
  where?: InputMaybe<SecurityAssetWhereInput>;
}>;

export type GetSecuritiesQuery = {
  __typename?: 'Query';
  securityAssets: {
    __typename?: 'SecurityAssetConnection';
    totalCount: number;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      endCursor?: string | null;
    };
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        id: string;
        name: string;
        symbol: string;
        active: boolean;
        description?: string | null;
        iconURI?: string | null;
        logoURI?: string | null;
        color?: string | null;
        createTime: any;
        updateTime: any;
        securityType: SecurityAssetSecurityType;
        website: string;
        cusip: string;
        marketCap?: any | null;
        highlyVolatile: boolean;
        complexSecurity: boolean;
        beta: any;
        details?:
          | { __typename?: 'SecurityETFDetails'; expenseRatioNet: any }
          | { __typename?: 'SecurityFixedIncomeDetails' }
          | {
              __typename?: 'SecurityMutualFundDetails';
              expenseRatioNet: any;
              loadType: string;
              fundCategory: string;
              initialInvestmentMinimum: any;
              netAssetValue: any;
            }
          | null;
        askExchange?: { __typename?: 'SecurityExchange'; id: string } | null;
        bidExchange?: { __typename?: 'SecurityExchange'; id: string } | null;
        sector?: { __typename?: 'SecuritySector'; id: string; name: string } | null;
        financials?: {
          __typename?: 'SecurityFinancials';
          dividendYield?: any | null;
          peRatio?: any | null;
          dividends?: Array<{ __typename?: 'Dividend'; cashAmount: any; payDate: any }> | null;
          statements?: Array<{
            __typename?: 'FinancialStatement';
            revenue: any;
            ebitda: any;
            earningsPerShare: any;
            bookValuePerShare: any;
            profitMargin: any;
            peRatio?: any | null;
          }> | null;
        } | null;
        market?: {
          __typename?: 'AssetMarketData';
          open: any;
          bidSize: any;
          last: any;
          askSize: any;
          baseMin?: any | null;
          close: any;
          previousDayClose: any;
          historical: { __typename?: 'OHLCV'; close: Array<any> };
        } | null;
        etfDetails?: { __typename?: 'ETFDetails'; expenseRatio: any } | null;
      } | null;
    } | null> | null;
  };
};

export type SecurityAssetPageFragment = {
  __typename?: 'SecurityAsset';
  id: string;
  name: string;
  symbol: string;
  active: boolean;
  type: AssetAssetType;
  iconURI?: string | null;
  logoURI?: string | null;
  market?: {
    __typename?: 'AssetMarketData';
    last: any;
    askSize: any;
    baseMin?: any | null;
    previousDayClose: any;
  } | null;
};

export type SecurityAssetEdgePageFragment = {
  __typename?: 'SecurityAssetEdge';
  node?: {
    __typename?: 'SecurityAsset';
    id: string;
    name: string;
    symbol: string;
    active: boolean;
    type: AssetAssetType;
    iconURI?: string | null;
    logoURI?: string | null;
    market?: {
      __typename?: 'AssetMarketData';
      last: any;
      askSize: any;
      baseMin?: any | null;
      previousDayClose: any;
    } | null;
  } | null;
};

export type GetSecurityPagesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['Cursor']['input']>;
  orderBy?: InputMaybe<SecurityAssetOrder>;
  where?: InputMaybe<SecurityAssetWhereInput>;
}>;

export type GetSecurityPagesQuery = {
  __typename?: 'Query';
  pages: {
    __typename?: 'SecurityAssetConnection';
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      endCursor?: string | null;
    };
    edges?: Array<{
      __typename?: 'SecurityAssetEdge';
      node?: {
        __typename?: 'SecurityAsset';
        id: string;
        name: string;
        symbol: string;
        active: boolean;
        type: AssetAssetType;
        iconURI?: string | null;
        logoURI?: string | null;
        market?: {
          __typename?: 'AssetMarketData';
          last: any;
          askSize: any;
          baseMin?: any | null;
          previousDayClose: any;
        } | null;
      } | null;
    } | null> | null;
  };
};

export type GetSecuritySectorsFilterOptionsQueryVariables = Exact<{
  where?: InputMaybe<SecuritySectorWhereInput>;
}>;

export type GetSecuritySectorsFilterOptionsQuery = {
  __typename?: 'Query';
  securitySectors: {
    __typename?: 'SecuritySectorConnection';
    edges?: Array<{
      __typename?: 'SecuritySectorEdge';
      node?: { __typename?: 'SecuritySector'; id: string; name: string } | null;
    } | null> | null;
  };
};

export type GetSearchPaginationDataQueryVariables = Exact<{
  where?: InputMaybe<SecurityAssetWhereInput>;
}>;

export type GetSearchPaginationDataQuery = {
  __typename?: 'Query';
  currentFilter: { __typename?: 'SecurityAssetConnection'; totalCount: number };
  stock: { __typename?: 'SecurityAssetConnection'; totalCount: number };
  etf: { __typename?: 'SecurityAssetConnection'; totalCount: number };
  mutualFund: { __typename?: 'SecurityAssetConnection'; totalCount: number };
};

export type GetSupportFaqsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSupportFaqsQuery = {
  __typename?: 'Query';
  supportFaqCategories: Array<{
    __typename?: 'SupportFAQCategory';
    id: string;
    name: string;
    order: number;
    faqs?: Array<{
      __typename?: 'SupportFAQ';
      id: string;
      type: string;
      title: string;
      image: string;
      description: string;
    }> | null;
  }>;
};

export type GetTenantDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type GetTenantDetailsQuery = {
  __typename?: 'Query';
  tenant: {
    __typename?: 'Tenant';
    id: string;
    alias: string;
    brokerage?: {
      __typename?: 'BrokerDealer';
      name: string;
      broker: { __typename?: 'IntroducingBroker'; name: string };
      plaidRelationshipOwner: { __typename?: 'PlaidRelationshipOwner'; name: string };
    } | null;
    advisory?: {
      __typename?: 'Advisory';
      id: string;
      name: string;
      isClearingHouseIB: boolean;
      requiresExtendedOnboarding: boolean;
      broker: { __typename?: 'IntroducingBroker'; name: string };
      plaidRelationshipOwner: { __typename?: 'PlaidRelationshipOwner'; name: string };
    } | null;
  };
};

export type TradeFragmentFragment = {
  __typename?: 'Trade';
  id: string;
  orderStatus: OrderOrderStatus;
  side: TradeMarketSide;
  limit?: any | null;
  isAll: boolean;
  requestAmount: any;
  requestInQuote: boolean;
  stop?: any | null;
  reason?: string | null;
  errorCode?: TradeTradeErrorCode | null;
  expiration?: any | null;
  expirationType: TradeTradeExpirationType;
  baseAmount?: any | null;
  quoteAmount?: any | null;
  feeAmount?: any | null;
  createTime: any;
  updateTime: any;
  tradeType: TradeTradeType;
  tradeStatus: TradeTradeStatus;
  base:
    | { __typename: 'CryptoAsset'; id: string; symbol: string; name: string }
    | { __typename: 'FiatAsset'; id: string; symbol: string; name: string }
    | {
        __typename: 'SecurityAsset';
        securityType: SecurityAssetSecurityType;
        id: string;
        symbol: string;
        name: string;
      };
  recurringRuleInstance?: {
    __typename?: 'RecurringRuleInstance';
    id: string;
    rule: {
      __typename?: 'RecurringRule';
      id: string;
      asset:
        | { __typename?: 'CryptoAsset'; symbol: string }
        | { __typename?: 'FiatAsset'; symbol: string }
        | { __typename?: 'SecurityAsset'; symbol: string };
      sector?: { __typename?: 'RepeatInvestmentSector'; name: string } | null;
    };
  } | null;
  liquidation?: { __typename?: 'Liquidation'; id: string } | null;
  tradeStatusChangeLog: Array<{
    __typename?: 'TradeStatusChangeLog';
    status: TradeTradeStatus;
    eventTime: any;
  }>;
};

export type TradeEdgeFragmentFragment = {
  __typename?: 'TradeEdge';
  cursor: string;
  node?: {
    __typename?: 'Trade';
    id: string;
    orderStatus: OrderOrderStatus;
    side: TradeMarketSide;
    limit?: any | null;
    isAll: boolean;
    requestAmount: any;
    requestInQuote: boolean;
    stop?: any | null;
    reason?: string | null;
    errorCode?: TradeTradeErrorCode | null;
    expiration?: any | null;
    expirationType: TradeTradeExpirationType;
    baseAmount?: any | null;
    quoteAmount?: any | null;
    feeAmount?: any | null;
    createTime: any;
    updateTime: any;
    tradeType: TradeTradeType;
    tradeStatus: TradeTradeStatus;
    base:
      | { __typename: 'CryptoAsset'; id: string; symbol: string; name: string }
      | { __typename: 'FiatAsset'; id: string; symbol: string; name: string }
      | {
          __typename: 'SecurityAsset';
          securityType: SecurityAssetSecurityType;
          id: string;
          symbol: string;
          name: string;
        };
    recurringRuleInstance?: {
      __typename?: 'RecurringRuleInstance';
      id: string;
      rule: {
        __typename?: 'RecurringRule';
        id: string;
        asset:
          | { __typename?: 'CryptoAsset'; symbol: string }
          | { __typename?: 'FiatAsset'; symbol: string }
          | { __typename?: 'SecurityAsset'; symbol: string };
        sector?: { __typename?: 'RepeatInvestmentSector'; name: string } | null;
      };
    } | null;
    liquidation?: { __typename?: 'Liquidation'; id: string } | null;
    tradeStatusChangeLog: Array<{
      __typename?: 'TradeStatusChangeLog';
      status: TradeTradeStatus;
      eventTime: any;
    }>;
  } | null;
};

export type GetAchTransferHistoryQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['Cursor']['input']>;
  portfolioId?: InputMaybe<Scalars['ID']['input']>;
  achType?: InputMaybe<Array<AchTransferTransferType> | AchTransferTransferType>;
  startDate?: InputMaybe<Scalars['Time']['input']>;
  endDate?: InputMaybe<Scalars['Time']['input']>;
}>;

export type GetAchTransferHistoryQuery = {
  __typename?: 'Query';
  pages: {
    __typename?: 'ACHTransferConnection';
    edges?: Array<{
      __typename?: 'ACHTransferEdge';
      cursor: string;
      node?: {
        __typename?: 'ACHTransfer';
        id: string;
        orderStatus: OrderOrderStatus;
        amount: any;
        instantDepositAmount?: any | null;
        returnFee?: any | null;
        isReturned: boolean;
        reason?: string | null;
        expectedSettlementDate?: any | null;
        createTime: any;
        updateTime: any;
        transferErrorCode?: AchTransferTransferErrorCode | null;
        transferType: AchTransferTransferType;
        transferStatus: AchTransferTransferStatus;
        externalAccount: {
          __typename?: 'ExternalAccount';
          id: string;
          mask: string;
          institution: { __typename?: 'Institution'; name: string };
          user: { __typename?: 'User'; firstName: string; lastName: string };
        };
        recurringRuleInstance?: {
          __typename?: 'RecurringRuleInstance';
          id: string;
          rule: {
            __typename?: 'RecurringRule';
            id: string;
            asset:
              | { __typename?: 'CryptoAsset'; symbol: string }
              | { __typename?: 'FiatAsset'; symbol: string }
              | { __typename?: 'SecurityAsset'; symbol: string };
            sector?: { __typename?: 'RepeatInvestmentSector'; name: string } | null;
          };
        } | null;
        transferChangeLog: Array<{
          __typename?: 'ACHTransferStatusChangeLog';
          status: AchTransferTransferStatus;
          eventTime: any;
        }>;
        lockup?: { __typename?: 'AssetLockup'; clearDate?: any | null } | null;
      } | null;
    } | null> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      endCursor?: string | null;
    };
  };
};

export type GetTradeHistoryQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['Cursor']['input']>;
  today: Scalars['Time']['input'];
  portfolioId?: InputMaybe<Scalars['ID']['input']>;
  tradeType?: InputMaybe<Array<TradeMarketSide> | TradeMarketSide>;
  startDate?: InputMaybe<Scalars['Time']['input']>;
  endDate?: InputMaybe<Scalars['Time']['input']>;
  allFilters?: InputMaybe<Array<TradeWhereInput> | TradeWhereInput>;
}>;

export type GetTradeHistoryQuery = {
  __typename?: 'Query';
  pages: {
    __typename?: 'TradeConnection';
    edges?: Array<{
      __typename?: 'TradeEdge';
      cursor: string;
      node?: {
        __typename?: 'Trade';
        id: string;
        orderStatus: OrderOrderStatus;
        side: TradeMarketSide;
        limit?: any | null;
        isAll: boolean;
        requestAmount: any;
        requestInQuote: boolean;
        stop?: any | null;
        reason?: string | null;
        errorCode?: TradeTradeErrorCode | null;
        expiration?: any | null;
        expirationType: TradeTradeExpirationType;
        baseAmount?: any | null;
        quoteAmount?: any | null;
        feeAmount?: any | null;
        createTime: any;
        updateTime: any;
        tradeType: TradeTradeType;
        tradeStatus: TradeTradeStatus;
        base:
          | { __typename: 'CryptoAsset'; id: string; symbol: string; name: string }
          | { __typename: 'FiatAsset'; id: string; symbol: string; name: string }
          | {
              __typename: 'SecurityAsset';
              securityType: SecurityAssetSecurityType;
              id: string;
              symbol: string;
              name: string;
            };
        recurringRuleInstance?: {
          __typename?: 'RecurringRuleInstance';
          id: string;
          rule: {
            __typename?: 'RecurringRule';
            id: string;
            asset:
              | { __typename?: 'CryptoAsset'; symbol: string }
              | { __typename?: 'FiatAsset'; symbol: string }
              | { __typename?: 'SecurityAsset'; symbol: string };
            sector?: { __typename?: 'RepeatInvestmentSector'; name: string } | null;
          };
        } | null;
        liquidation?: { __typename?: 'Liquidation'; id: string } | null;
        tradeStatusChangeLog: Array<{
          __typename?: 'TradeStatusChangeLog';
          status: TradeTradeStatus;
          eventTime: any;
        }>;
      } | null;
    } | null> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      endCursor?: string | null;
    };
  };
};

export type GetDividendHistoryQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['Cursor']['input']>;
}>;

export type GetDividendHistoryQuery = {
  __typename?: 'Query';
  pages: {
    __typename?: 'DividendDistributionConnection';
    edges?: Array<{
      __typename?: 'DividendDistributionEdge';
      node?: {
        __typename?: 'DividendDistribution';
        id: string;
        orderStatus: OrderOrderStatus;
        orderType: OrderOrderType;
        isQualified: boolean;
        isReinvested: boolean;
        payDate?: any | null;
        amount: any;
        createTime: any;
        distributionAsset?:
          | { __typename?: 'CryptoAsset'; symbol: string }
          | { __typename?: 'FiatAsset'; symbol: string }
          | { __typename?: 'SecurityAsset'; symbol: string }
          | null;
      } | null;
    } | null> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      endCursor?: string | null;
    };
  };
};

export type GetAllHistoryQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['Cursor']['input']>;
  portfolioId?: InputMaybe<Scalars['ID']['input']>;
  todayStart: Scalars['Time']['input'];
  orderType?: InputMaybe<Array<OrderOrderType> | OrderOrderType>;
  startDate?: InputMaybe<Scalars['Time']['input']>;
  endDate?: InputMaybe<Scalars['Time']['input']>;
  recentPages: Scalars['Boolean']['input'];
}>;

export type GetAllHistoryQuery = {
  __typename?: 'Query';
  recentPages: {
    __typename?: 'BaseOrderConnection';
    edges?: Array<{
      __typename?: 'BaseOrderEdge';
      node:
        | {
            __typename: 'ACHTransfer';
            id: string;
            orderStatus: OrderOrderStatus;
            amount: any;
            instantDepositAmount?: any | null;
            returnFee?: any | null;
            isReturned: boolean;
            reason?: string | null;
            expectedSettlementDate?: any | null;
            createTime: any;
            updateTime: any;
            transferErrorCode?: AchTransferTransferErrorCode | null;
            transferType: AchTransferTransferType;
            transferStatus: AchTransferTransferStatus;
            externalAccount: {
              __typename?: 'ExternalAccount';
              id: string;
              mask: string;
              institution: { __typename?: 'Institution'; name: string };
              user: { __typename?: 'User'; firstName: string; lastName: string };
            };
            recurringRuleInstance?: {
              __typename?: 'RecurringRuleInstance';
              id: string;
              rule: {
                __typename?: 'RecurringRule';
                id: string;
                asset:
                  | { __typename?: 'CryptoAsset'; symbol: string }
                  | { __typename?: 'FiatAsset'; symbol: string }
                  | { __typename?: 'SecurityAsset'; symbol: string };
                sector?: { __typename?: 'RepeatInvestmentSector'; name: string } | null;
              };
            } | null;
            transferChangeLog: Array<{
              __typename?: 'ACHTransferStatusChangeLog';
              status: AchTransferTransferStatus;
              eventTime: any;
            }>;
            lockup?: { __typename?: 'AssetLockup'; clearDate?: any | null } | null;
          }
        | { __typename: 'AcatTransfer'; id: string }
        | { __typename: 'AccountReconAdjustment'; id: string }
        | { __typename: 'AdminOrder'; id: string }
        | { __typename: 'CapitalGains'; id: string }
        | { __typename: 'CapitalGainsDistribution'; id: string }
        | { __typename: 'CheckTransfer'; id: string }
        | { __typename: 'CorporateActionModification'; id: string }
        | {
            __typename: 'DividendDistribution';
            id: string;
            orderStatus: OrderOrderStatus;
            orderType: OrderOrderType;
            isQualified: boolean;
            isReinvested: boolean;
            payDate?: any | null;
            amount: any;
            createTime: any;
            distributionAsset?:
              | { __typename?: 'CryptoAsset'; symbol: string }
              | { __typename?: 'FiatAsset'; symbol: string }
              | { __typename?: 'SecurityAsset'; symbol: string }
              | null;
          }
        | { __typename: 'FeeAndCreditOrder'; id: string }
        | {
            __typename: 'ICTTransfer';
            id: string;
            isReturned: boolean;
            instantDepositAmount?: any | null;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            status: IctTransferTransferStatus;
            amount: any;
            createTime: any;
            ictReturnFee?: any | null;
            ictType: IctTransferTransferType;
            ictTransferErrorCode?: IctTransferTransferErrorCode | null;
            externalAccount: {
              __typename: 'ExternalAccount';
              id: string;
              mask: string;
              institution: { __typename: 'Institution'; name: string };
              user: { __typename: 'User'; firstName: string; lastName: string };
            };
            recurringRuleInstance?: {
              __typename: 'RecurringRuleInstance';
              id: string;
              rule: {
                __typename: 'RecurringRule';
                id: string;
                asset:
                  | { __typename: 'CryptoAsset'; symbol: string }
                  | { __typename: 'FiatAsset'; symbol: string }
                  | { __typename: 'SecurityAsset'; symbol: string };
                sector?: { __typename: 'RepeatInvestmentSector'; name: string } | null;
              };
            } | null;
          }
        | {
            __typename: 'InterestDistribution';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            amount: any;
            type: InterestDistributionInterestType;
            processDate: any;
            effectiveDate: any;
            recordDate: any;
            createTime: any;
          }
        | { __typename: 'Liquidation'; id: string }
        | { __typename: 'PortfolioLedger'; id: string }
        | { __typename: 'PortfolioLiquidation'; id: string }
        | { __typename: 'RecurringRuleInstance'; id: string }
        | { __typename: 'ReturnOfCapital'; id: string }
        | { __typename: 'ReturnOfCapitalDistribution'; id: string }
        | {
            __typename: 'Trade';
            id: string;
            orderStatus: OrderOrderStatus;
            side: TradeMarketSide;
            limit?: any | null;
            isAll: boolean;
            requestAmount: any;
            requestInQuote: boolean;
            stop?: any | null;
            reason?: string | null;
            errorCode?: TradeTradeErrorCode | null;
            expiration?: any | null;
            expirationType: TradeTradeExpirationType;
            baseAmount?: any | null;
            quoteAmount?: any | null;
            feeAmount?: any | null;
            createTime: any;
            updateTime: any;
            tradeType: TradeTradeType;
            tradeStatus: TradeTradeStatus;
            base:
              | { __typename: 'CryptoAsset'; id: string; symbol: string; name: string }
              | { __typename: 'FiatAsset'; id: string; symbol: string; name: string }
              | {
                  __typename: 'SecurityAsset';
                  securityType: SecurityAssetSecurityType;
                  id: string;
                  symbol: string;
                  name: string;
                };
            recurringRuleInstance?: {
              __typename?: 'RecurringRuleInstance';
              id: string;
              rule: {
                __typename?: 'RecurringRule';
                id: string;
                asset:
                  | { __typename?: 'CryptoAsset'; symbol: string }
                  | { __typename?: 'FiatAsset'; symbol: string }
                  | { __typename?: 'SecurityAsset'; symbol: string };
                sector?: { __typename?: 'RepeatInvestmentSector'; name: string } | null;
              };
            } | null;
            liquidation?: { __typename?: 'Liquidation'; id: string } | null;
            tradeStatusChangeLog: Array<{
              __typename?: 'TradeStatusChangeLog';
              status: TradeTradeStatus;
              eventTime: any;
            }>;
          };
    } | null> | null;
  };
  pages: {
    __typename?: 'BaseOrderConnection';
    edges?: Array<{
      __typename?: 'BaseOrderEdge';
      node:
        | {
            __typename: 'ACHTransfer';
            id: string;
            orderStatus: OrderOrderStatus;
            amount: any;
            instantDepositAmount?: any | null;
            returnFee?: any | null;
            isReturned: boolean;
            reason?: string | null;
            expectedSettlementDate?: any | null;
            createTime: any;
            updateTime: any;
            transferErrorCode?: AchTransferTransferErrorCode | null;
            transferType: AchTransferTransferType;
            transferStatus: AchTransferTransferStatus;
            externalAccount: {
              __typename?: 'ExternalAccount';
              id: string;
              mask: string;
              institution: { __typename?: 'Institution'; name: string };
              user: { __typename?: 'User'; firstName: string; lastName: string };
            };
            recurringRuleInstance?: {
              __typename?: 'RecurringRuleInstance';
              id: string;
              rule: {
                __typename?: 'RecurringRule';
                id: string;
                asset:
                  | { __typename?: 'CryptoAsset'; symbol: string }
                  | { __typename?: 'FiatAsset'; symbol: string }
                  | { __typename?: 'SecurityAsset'; symbol: string };
                sector?: { __typename?: 'RepeatInvestmentSector'; name: string } | null;
              };
            } | null;
            transferChangeLog: Array<{
              __typename?: 'ACHTransferStatusChangeLog';
              status: AchTransferTransferStatus;
              eventTime: any;
            }>;
            lockup?: { __typename?: 'AssetLockup'; clearDate?: any | null } | null;
          }
        | { __typename: 'AcatTransfer'; id: string }
        | { __typename: 'AccountReconAdjustment'; id: string }
        | { __typename: 'AdminOrder'; id: string }
        | { __typename: 'CapitalGains'; id: string }
        | { __typename: 'CapitalGainsDistribution'; id: string }
        | { __typename: 'CheckTransfer'; id: string }
        | { __typename: 'CorporateActionModification'; id: string }
        | {
            __typename: 'DividendDistribution';
            id: string;
            orderStatus: OrderOrderStatus;
            orderType: OrderOrderType;
            isQualified: boolean;
            isReinvested: boolean;
            payDate?: any | null;
            amount: any;
            createTime: any;
            distributionAsset?:
              | { __typename?: 'CryptoAsset'; symbol: string }
              | { __typename?: 'FiatAsset'; symbol: string }
              | { __typename?: 'SecurityAsset'; symbol: string }
              | null;
          }
        | { __typename: 'FeeAndCreditOrder'; id: string }
        | {
            __typename: 'ICTTransfer';
            id: string;
            isReturned: boolean;
            instantDepositAmount?: any | null;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            status: IctTransferTransferStatus;
            amount: any;
            createTime: any;
            ictReturnFee?: any | null;
            ictType: IctTransferTransferType;
            ictTransferErrorCode?: IctTransferTransferErrorCode | null;
            externalAccount: {
              __typename: 'ExternalAccount';
              id: string;
              mask: string;
              institution: { __typename: 'Institution'; name: string };
              user: { __typename: 'User'; firstName: string; lastName: string };
            };
            recurringRuleInstance?: {
              __typename: 'RecurringRuleInstance';
              id: string;
              rule: {
                __typename: 'RecurringRule';
                id: string;
                asset:
                  | { __typename: 'CryptoAsset'; symbol: string }
                  | { __typename: 'FiatAsset'; symbol: string }
                  | { __typename: 'SecurityAsset'; symbol: string };
                sector?: { __typename: 'RepeatInvestmentSector'; name: string } | null;
              };
            } | null;
          }
        | {
            __typename: 'InterestDistribution';
            id: string;
            orderType: OrderOrderType;
            orderStatus: OrderOrderStatus;
            amount: any;
            type: InterestDistributionInterestType;
            processDate: any;
            effectiveDate: any;
            recordDate: any;
            createTime: any;
          }
        | { __typename: 'Liquidation'; id: string }
        | { __typename: 'PortfolioLedger'; id: string }
        | { __typename: 'PortfolioLiquidation'; id: string }
        | { __typename: 'RecurringRuleInstance'; id: string }
        | { __typename: 'ReturnOfCapital'; id: string }
        | { __typename: 'ReturnOfCapitalDistribution'; id: string }
        | {
            __typename: 'Trade';
            id: string;
            orderStatus: OrderOrderStatus;
            side: TradeMarketSide;
            limit?: any | null;
            isAll: boolean;
            requestAmount: any;
            requestInQuote: boolean;
            stop?: any | null;
            reason?: string | null;
            errorCode?: TradeTradeErrorCode | null;
            expiration?: any | null;
            expirationType: TradeTradeExpirationType;
            baseAmount?: any | null;
            quoteAmount?: any | null;
            feeAmount?: any | null;
            createTime: any;
            updateTime: any;
            tradeType: TradeTradeType;
            tradeStatus: TradeTradeStatus;
            base:
              | { __typename: 'CryptoAsset'; id: string; symbol: string; name: string }
              | { __typename: 'FiatAsset'; id: string; symbol: string; name: string }
              | {
                  __typename: 'SecurityAsset';
                  securityType: SecurityAssetSecurityType;
                  id: string;
                  symbol: string;
                  name: string;
                };
            recurringRuleInstance?: {
              __typename?: 'RecurringRuleInstance';
              id: string;
              rule: {
                __typename?: 'RecurringRule';
                id: string;
                asset:
                  | { __typename?: 'CryptoAsset'; symbol: string }
                  | { __typename?: 'FiatAsset'; symbol: string }
                  | { __typename?: 'SecurityAsset'; symbol: string };
                sector?: { __typename?: 'RepeatInvestmentSector'; name: string } | null;
              };
            } | null;
            liquidation?: { __typename?: 'Liquidation'; id: string } | null;
            tradeStatusChangeLog: Array<{
              __typename?: 'TradeStatusChangeLog';
              status: TradeTradeStatus;
              eventTime: any;
            }>;
          };
    } | null> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      endCursor?: string | null;
    };
  };
};

export type GetTransfersByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetTransfersByIdQuery = {
  __typename?: 'Query';
  node?:
    | {
        __typename?: 'ACHTransfer';
        id: string;
        orderStatus: OrderOrderStatus;
        amount: any;
        instantDepositAmount?: any | null;
        returnFee?: any | null;
        isReturned: boolean;
        reason?: string | null;
        expectedSettlementDate?: any | null;
        createTime: any;
        updateTime: any;
        transferErrorCode?: AchTransferTransferErrorCode | null;
        transferType: AchTransferTransferType;
        transferStatus: AchTransferTransferStatus;
        externalAccount: {
          __typename?: 'ExternalAccount';
          id: string;
          mask: string;
          institution: { __typename?: 'Institution'; name: string };
          user: { __typename?: 'User'; firstName: string; lastName: string };
        };
        recurringRuleInstance?: {
          __typename?: 'RecurringRuleInstance';
          id: string;
          rule: {
            __typename?: 'RecurringRule';
            id: string;
            asset:
              | { __typename?: 'CryptoAsset'; symbol: string }
              | { __typename?: 'FiatAsset'; symbol: string }
              | { __typename?: 'SecurityAsset'; symbol: string };
            sector?: { __typename?: 'RepeatInvestmentSector'; name: string } | null;
          };
        } | null;
        transferChangeLog: Array<{
          __typename?: 'ACHTransferStatusChangeLog';
          status: AchTransferTransferStatus;
          eventTime: any;
        }>;
        lockup?: { __typename?: 'AssetLockup'; clearDate?: any | null } | null;
      }
    | { __typename?: 'AcatTransfer' }
    | { __typename?: 'AcatTransferAsset' }
    | { __typename?: 'AcatTransferDetails' }
    | { __typename?: 'AcatTransferDetailsAsset' }
    | { __typename?: 'AccountReconAdjustment' }
    | { __typename?: 'AccountTypeCustodian' }
    | { __typename?: 'AdminAction' }
    | { __typename?: 'AdminOrder' }
    | { __typename?: 'Advisory' }
    | { __typename?: 'ApexAccountFile' }
    | { __typename?: 'ApexAccountInvestigation' }
    | { __typename?: 'ApexAccountMeta' }
    | { __typename?: 'ApexAccountRequest' }
    | { __typename?: 'ApexAccountUpdateQueue' }
    | { __typename?: 'ApexFiles' }
    | { __typename?: 'ApexInvestigationFile' }
    | { __typename?: 'ApexUpdateRequest' }
    | { __typename?: 'AscendInvestigation' }
    | { __typename?: 'AscendMeta' }
    | { __typename?: 'AssetLockup' }
    | { __typename?: 'Beneficiary' }
    | { __typename?: 'BeneficiarySet' }
    | { __typename?: 'BrokerDTCC' }
    | { __typename?: 'BrokerDealer' }
    | { __typename?: 'BrokerDealerPrincipal' }
    | { __typename?: 'CapitalGains' }
    | { __typename?: 'CapitalGainsDistribution' }
    | { __typename?: 'CheckTransfer' }
    | { __typename?: 'CloseAccountRequest' }
    | { __typename?: 'CorporateAction' }
    | { __typename?: 'CorporateActionGrant' }
    | { __typename?: 'CorporateActionModification' }
    | { __typename?: 'Country' }
    | { __typename?: 'CryptoAsset' }
    | { __typename?: 'DiscretionaryAccountKyc' }
    | { __typename?: 'DiscretionaryUpdateRequest' }
    | { __typename?: 'DividendDistribution' }
    | { __typename?: 'ETFComposition' }
    | { __typename?: 'ETFDetails' }
    | { __typename?: 'ExternalAccount' }
    | { __typename?: 'FederatedIdentityProvider' }
    | { __typename?: 'FeeAndCreditOrder' }
    | { __typename?: 'FeeAndCreditProgramChangeLog' }
    | { __typename?: 'FeeAndCreditSubmission' }
    | { __typename?: 'FiatAsset' }
    | { __typename?: 'FnpMetadata' }
    | { __typename?: 'GOEPortfolioMapping' }
    | { __typename?: 'ICTPartnerInstitution' }
    | {
        __typename?: 'ICTTransfer';
        id: string;
        isReturned: boolean;
        instantDepositAmount?: any | null;
        orderType: OrderOrderType;
        orderStatus: OrderOrderStatus;
        status: IctTransferTransferStatus;
        amount: any;
        createTime: any;
        ictReturnFee?: any | null;
        ictType: IctTransferTransferType;
        ictTransferErrorCode?: IctTransferTransferErrorCode | null;
        externalAccount: {
          __typename: 'ExternalAccount';
          id: string;
          mask: string;
          institution: { __typename: 'Institution'; name: string };
          user: { __typename: 'User'; firstName: string; lastName: string };
        };
        recurringRuleInstance?: {
          __typename: 'RecurringRuleInstance';
          id: string;
          rule: {
            __typename: 'RecurringRule';
            id: string;
            asset:
              | { __typename: 'CryptoAsset'; symbol: string }
              | { __typename: 'FiatAsset'; symbol: string }
              | { __typename: 'SecurityAsset'; symbol: string };
            sector?: { __typename: 'RepeatInvestmentSector'; name: string } | null;
          };
        } | null;
      }
    | { __typename?: 'Institution' }
    | { __typename?: 'InterestDistribution' }
    | { __typename?: 'IntroducingBroker' }
    | { __typename?: 'InvestorDocument' }
    | { __typename?: 'Liability' }
    | { __typename?: 'Liquidation' }
    | { __typename?: 'MarketDataAnswers' }
    | { __typename?: 'ModelPortfolio' }
    | { __typename?: 'ModelPortfolioAssetComposition' }
    | { __typename?: 'ModelPortfolioAssetSubstitution' }
    | { __typename?: 'ModelPortfolioSector' }
    | { __typename?: 'ModelPortfolioSectorComposition' }
    | { __typename?: 'MutualFundDetails' }
    | { __typename?: 'NonDiscretionaryAccountKyc' }
    | { __typename?: 'NonDiscretionaryUpdateRequest' }
    | { __typename?: 'Order' }
    | { __typename?: 'PlaidRelationshipOwner' }
    | { __typename?: 'PortfolioLedger' }
    | { __typename?: 'PortfolioLiquidation' }
    | { __typename?: 'ProgramEnrollmentHistory' }
    | { __typename?: 'RTQPortfolioLevel' }
    | { __typename?: 'RTQPortfolioLevelModelAssignment' }
    | { __typename?: 'RTQPortfolioLevelSet' }
    | { __typename?: 'RTQQuestion' }
    | { __typename?: 'RTQQuestionnaire' }
    | { __typename?: 'RTQResponse' }
    | { __typename?: 'RTQResponseOption' }
    | { __typename?: 'RTQSubmission' }
    | { __typename?: 'RecurringRule' }
    | { __typename?: 'RecurringRuleInstance' }
    | { __typename?: 'RecurringRuleSkipRequest' }
    | { __typename?: 'ReferralRecord' }
    | { __typename?: 'RegulatedEntity' }
    | { __typename?: 'RepeatInvestmentSector' }
    | { __typename?: 'RetirementContributionInfo' }
    | { __typename?: 'RetirementDistributionInfo' }
    | { __typename?: 'ReturnOfCapital' }
    | { __typename?: 'ReturnOfCapitalDistribution' }
    | { __typename?: 'RoboGeneralInvestment' }
    | { __typename?: 'RoboGoal' }
    | { __typename?: 'RoboGoalAlgorithmExecution' }
    | { __typename?: 'RoboGoalParameters' }
    | { __typename?: 'RoboGoalProjection' }
    | { __typename?: 'RoboInvestmentAction' }
    | { __typename?: 'SecurityAsset' }
    | { __typename?: 'SecurityExchange' }
    | { __typename?: 'SecurityFixedIncomeDetails' }
    | { __typename?: 'SecuritySector' }
    | { __typename?: 'SodDiscrepancyTrack' }
    | { __typename?: 'SupportFAQ' }
    | { __typename?: 'SupportFAQCategory' }
    | { __typename?: 'SupportTicket' }
    | { __typename?: 'Tenant' }
    | { __typename?: 'Trade' }
    | { __typename?: 'TrustedContact' }
    | { __typename?: 'TrustedContactUpdateRequest' }
    | { __typename?: 'User' }
    | { __typename?: 'UserAccount' }
    | { __typename?: 'UserAccountAssetBalance' }
    | { __typename?: 'UserAccountPortfolio' }
    | { __typename?: 'UserAccountPortfolioAsset' }
    | { __typename?: 'UserAccountPortfolioAssetBalance' }
    | { __typename?: 'UserAccountPortfolioAssetDailyBalance' }
    | { __typename?: 'UserAccountRestriction' }
    | { __typename?: 'UserDocument' }
    | { __typename?: 'UserDocumentSet' }
    | { __typename?: 'UserProfile' }
    | { __typename?: 'UserProfileUpdateRequest' }
    | { __typename?: 'UserRetirementContribution' }
    | { __typename?: 'UserSetting' }
    | null;
};

export type GetUserSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserSettingsQuery = {
  __typename?: 'Query';
  userSettings: {
    __typename?: 'UserSettings';
    retirement?: {
      __typename?: 'RetirementUserSettings';
      defaultWithholding: {
        __typename?: 'RetirementWithholdingSettings';
        state: string;
        federalWithholding: {
          __typename?: 'RetirementWithholdingElection';
          waive: boolean;
          percentage?: any | null;
        };
        stateWithholding: {
          __typename?: 'RetirementWithholdingElection';
          waive: boolean;
          percentage?: any | null;
        };
      };
    } | null;
  };
};

export type UserSharesEdgeFragment = {
  __typename?: 'UserAccountPortfolioAssetEdge';
  node?: {
    __typename?: 'UserAccountPortfolioAsset';
    id: string;
    costBasis: any;
    balances?: {
      __typename?: 'UserAccountPortfolioAssetBalance';
      pending: any;
      liability: any;
      pendingLiability: any;
      unsettled: any;
      settled: any;
      activeBalance: any;
      tradeable: any;
    } | null;
    asset?:
      | {
          __typename?: 'CryptoAsset';
          id: string;
          name: string;
          symbol: string;
          createTime: any;
          updateTime: any;
        }
      | {
          __typename?: 'FiatAsset';
          id: string;
          name: string;
          symbol: string;
          createTime: any;
          updateTime: any;
        }
      | {
          __typename: 'SecurityAsset';
          id: string;
          color?: string | null;
          logoURI?: string | null;
          securityType: SecurityAssetSecurityType;
          name: string;
          symbol: string;
          createTime: any;
          updateTime: any;
          sector?: { __typename?: 'SecuritySector'; id: string } | null;
          market?: { __typename?: 'AssetMarketData'; last: any; previousDayClose: any } | null;
        }
      | null;
  } | null;
};

export type GetUserSharesV2QueryVariables = Exact<{
  portfolioId: Scalars['ID']['input'];
}>;

export type GetUserSharesV2Query = {
  __typename?: 'Query';
  userSecurityAssets: {
    __typename?: 'UserAccountPortfolioAssetConnection';
    edges?: Array<{
      __typename?: 'UserAccountPortfolioAssetEdge';
      node?: {
        __typename?: 'UserAccountPortfolioAsset';
        id: string;
        costBasis: any;
        balances?: {
          __typename?: 'UserAccountPortfolioAssetBalance';
          pending: any;
          liability: any;
          pendingLiability: any;
          unsettled: any;
          settled: any;
          activeBalance: any;
          tradeable: any;
        } | null;
        asset?:
          | {
              __typename?: 'CryptoAsset';
              id: string;
              name: string;
              symbol: string;
              createTime: any;
              updateTime: any;
            }
          | {
              __typename?: 'FiatAsset';
              id: string;
              name: string;
              symbol: string;
              createTime: any;
              updateTime: any;
            }
          | {
              __typename: 'SecurityAsset';
              id: string;
              color?: string | null;
              logoURI?: string | null;
              securityType: SecurityAssetSecurityType;
              name: string;
              symbol: string;
              createTime: any;
              updateTime: any;
              sector?: { __typename?: 'SecuritySector'; id: string } | null;
              market?: { __typename?: 'AssetMarketData'; last: any; previousDayClose: any } | null;
            }
          | null;
      } | null;
    } | null> | null;
  };
  userFiatAssets: {
    __typename?: 'UserAccountPortfolioAssetConnection';
    edges?: Array<{
      __typename?: 'UserAccountPortfolioAssetEdge';
      node?: {
        __typename?: 'UserAccountPortfolioAsset';
        id: string;
        costBasis: any;
        balances?: {
          __typename?: 'UserAccountPortfolioAssetBalance';
          pending: any;
          liability: any;
          pendingLiability: any;
          unsettled: any;
          settled: any;
          activeBalance: any;
          tradeable: any;
        } | null;
        asset?:
          | {
              __typename?: 'CryptoAsset';
              id: string;
              name: string;
              symbol: string;
              createTime: any;
              updateTime: any;
            }
          | {
              __typename?: 'FiatAsset';
              id: string;
              name: string;
              symbol: string;
              createTime: any;
              updateTime: any;
            }
          | {
              __typename: 'SecurityAsset';
              id: string;
              color?: string | null;
              logoURI?: string | null;
              securityType: SecurityAssetSecurityType;
              name: string;
              symbol: string;
              createTime: any;
              updateTime: any;
              sector?: { __typename?: 'SecuritySector'; id: string } | null;
              market?: { __typename?: 'AssetMarketData'; last: any; previousDayClose: any } | null;
            }
          | null;
      } | null;
    } | null> | null;
  };
};

export type GetUserDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserDetailsQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserConnection';
    edges?: Array<{
      __typename?: 'UserEdge';
      node?: {
        __typename?: 'User';
        id: string;
        firstName: string;
        lastName: string;
        username?: string | null;
        legacyID?: number | null;
        isActive: boolean;
        isLoginMfaEnabled: boolean;
        profile?: {
          __typename?: 'UserProfile';
          firstName: string;
          lastName: string;
          dateOfBirth: any;
          email: string;
          phoneNumber: string;
          phoneNumberType: UserProfileUserPhoneNumberType;
          country?: any | null;
          city?: string | null;
          streetAddress?: string | null;
          additionalStreetAddress?: string | null;
          state?: string | null;
          zipCode?: string | null;
          unverifiedEmail?: string | null;
          unverifiedPhoneNumber?: string | null;
          controlPersonDetails?: {
            __typename?: 'ControlPersonDetails';
            companySymbols?: Array<string> | null;
            isControlPerson: boolean;
          } | null;
          politicallyExposedDetails?: {
            __typename?: 'PoliticallyExposedDetails';
            isPoliticallyExposed: boolean;
            organizationName?: string | null;
            familyMembers?: Array<string> | null;
          } | null;
          affiliatedExchangeOrFinraDetails?: {
            __typename?: 'AffiliatedExchangeOrFINRADetails';
            isAffiliated: boolean;
            firmName?: string | null;
          } | null;
          marketDataAnswers?: {
            __typename?: 'MarketDataAnswers';
            personalUse: boolean;
            gettingDataFromExternalSource: boolean;
            registeredWithSecOrCftc: boolean;
            requiresSecOrCftcRegistration: boolean;
            investForOther: boolean;
            investForTheBenefitOfOther: boolean;
            advisorOrConsultant: boolean;
            sharesProfit: boolean;
            registeredWithEntity: boolean;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  };
  discretionaryAccountKycs: {
    __typename?: 'DiscretionaryAccountKycConnection';
    edges?: Array<{
      __typename?: 'DiscretionaryAccountKycEdge';
      node?: {
        __typename?: 'DiscretionaryAccountKyc';
        id: string;
        riskTolerance?: UserRiskTolerance | null;
        account?: Array<{ __typename?: 'UserAccount'; id: string }> | null;
      } | null;
    } | null> | null;
  };
  nonDiscretionaryAccountKycs: {
    __typename?: 'NonDiscretionaryAccountKycConnection';
    edges?: Array<{
      __typename?: 'NonDiscretionaryAccountKycEdge';
      node?: {
        __typename?: 'NonDiscretionaryAccountKyc';
        id: string;
        riskTolerance?: UserRiskTolerance | null;
        investmentExperience?: NonDiscretionaryAccountKycUserInvestmentExperience | null;
        investmentObjective?: NonDiscretionaryAccountKycUserInvestmentObjective | null;
        timeHorizon?: NonDiscretionaryAccountKycUserTimeHorizon | null;
        liquidityNeeds?: NonDiscretionaryAccountKycUserLiquidityNeeds | null;
        maritalStatus?: NonDiscretionaryAccountKycUserMaritalStatus | null;
        employmentStatus?: NonDiscretionaryAccountKycUserEmploymentStatus | null;
        annualIncome?: NonDiscretionaryAccountKycUserAnnualIncome | null;
        netWorth?: NonDiscretionaryAccountKycUserNetWorth | null;
        account?: Array<{ __typename?: 'UserAccount'; id: string }> | null;
        employmentDetails?: {
          __typename?: 'EmploymentDetails';
          EmployerName: string;
          Position: string;
        } | null;
      } | null;
    } | null> | null;
  };
};

export type GetRepeatInvestmentSectorsQueryVariables = Exact<{ [key: string]: never }>;

export type GetRepeatInvestmentSectorsQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserConnection';
    edges?: Array<{
      __typename?: 'UserEdge';
      node?: {
        __typename?: 'User';
        user_id: string;
        recurringRules: {
          __typename?: 'RecurringRuleConnection';
          edges?: Array<{
            __typename?: 'RecurringRuleEdge';
            node?: {
              __typename?: 'RecurringRule';
              id: string;
              createTime: any;
              updateTime: any;
              type: RecurringRuleType;
              fundingMethod: RecurringRuleFundingMethod;
              isActive: boolean;
              interval: RecurringRuleRecurringRuleInterval;
              amount: any;
              start: any;
              sector?: {
                __typename?: 'RepeatInvestmentSector';
                id: string;
                name: string;
                type: RepeatInvestmentSectorType;
                largeImage: string;
                smallImage: string;
                order: number;
                security: {
                  __typename?: 'SecurityAsset';
                  id: string;
                  name: string;
                  symbol: string;
                  description?: string | null;
                  market?: {
                    __typename?: 'AssetMarketData';
                    open: any;
                    bidSize: any;
                    last: any;
                    askSize: any;
                    baseMin?: any | null;
                    close: any;
                    previousDayClose: any;
                  } | null;
                };
              } | null;
            } | null;
          } | null> | null;
        };
      } | null;
    } | null> | null;
  };
};

export type GetAllUserAccountsStatusFragmentFragment = {
  __typename?: 'UserAccount';
  id: string;
  type: UserAccountAccountType;
  status: UserAccountAccountStatus;
  portfolios: {
    __typename?: 'UserAccountPortfolioConnection';
    edges?: Array<{
      __typename?: 'UserAccountPortfolioEdge';
      node?: {
        __typename?: 'UserAccountPortfolio';
        id: string;
        account: {
          __typename?: 'UserAccount';
          createTime: any;
          externalID?: string | null;
          ascendMeta?: {
            __typename?: 'AscendMeta';
            accountNumber: string;
            accountID: string;
          } | null;
        };
      } | null;
    } | null> | null;
  };
  beneficiariesPrimary?: {
    __typename?: 'BeneficiarySet';
    id: string;
    beneficiaries?: Array<{
      __typename?: 'Beneficiary';
      id: string;
      additionalStreetAddress?: string | null;
      city: string;
      country?: any | null;
      createTime: any;
      dateOfBirth: any;
      email?: string | null;
      firstName: string;
      middleName?: string | null;
      lastName: string;
      percentage: number;
      phoneNumber?: any | null;
      relationship?: BeneficiaryBeneficiaryRelationType | null;
      ssn?: string | null;
      state: string;
      streetAddress?: string | null;
      updateTime: any;
      zipCode: string;
      beneficiarySet: { __typename?: 'BeneficiarySet'; id: string; isPending: boolean };
    }> | null;
  } | null;
  beneficiariesContingent?: {
    __typename?: 'BeneficiarySet';
    id: string;
    beneficiaries?: Array<{
      __typename?: 'Beneficiary';
      id: string;
      additionalStreetAddress?: string | null;
      city: string;
      country?: any | null;
      createTime: any;
      dateOfBirth: any;
      email?: string | null;
      firstName: string;
      middleName?: string | null;
      lastName: string;
      percentage: number;
      phoneNumber?: any | null;
      relationship?: BeneficiaryBeneficiaryRelationType | null;
      ssn?: string | null;
      state: string;
      streetAddress?: string | null;
      updateTime: any;
      zipCode: string;
      beneficiarySet: { __typename?: 'BeneficiarySet'; id: string; isPending: boolean };
    }> | null;
  } | null;
};

export type GetAllUserAccountsStatusQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllUserAccountsStatusQuery = {
  __typename?: 'Query';
  userAccounts: {
    __typename?: 'UserAccountConnection';
    edges?: Array<{
      __typename?: 'UserAccountEdge';
      node?: {
        __typename?: 'UserAccount';
        id: string;
        type: UserAccountAccountType;
        status: UserAccountAccountStatus;
        portfolios: {
          __typename?: 'UserAccountPortfolioConnection';
          edges?: Array<{
            __typename?: 'UserAccountPortfolioEdge';
            node?: {
              __typename?: 'UserAccountPortfolio';
              id: string;
              account: {
                __typename?: 'UserAccount';
                createTime: any;
                externalID?: string | null;
                ascendMeta?: {
                  __typename?: 'AscendMeta';
                  accountNumber: string;
                  accountID: string;
                } | null;
              };
            } | null;
          } | null> | null;
        };
        beneficiariesPrimary?: {
          __typename?: 'BeneficiarySet';
          id: string;
          beneficiaries?: Array<{
            __typename?: 'Beneficiary';
            id: string;
            additionalStreetAddress?: string | null;
            city: string;
            country?: any | null;
            createTime: any;
            dateOfBirth: any;
            email?: string | null;
            firstName: string;
            middleName?: string | null;
            lastName: string;
            percentage: number;
            phoneNumber?: any | null;
            relationship?: BeneficiaryBeneficiaryRelationType | null;
            ssn?: string | null;
            state: string;
            streetAddress?: string | null;
            updateTime: any;
            zipCode: string;
            beneficiarySet: { __typename?: 'BeneficiarySet'; id: string; isPending: boolean };
          }> | null;
        } | null;
        beneficiariesContingent?: {
          __typename?: 'BeneficiarySet';
          id: string;
          beneficiaries?: Array<{
            __typename?: 'Beneficiary';
            id: string;
            additionalStreetAddress?: string | null;
            city: string;
            country?: any | null;
            createTime: any;
            dateOfBirth: any;
            email?: string | null;
            firstName: string;
            middleName?: string | null;
            lastName: string;
            percentage: number;
            phoneNumber?: any | null;
            relationship?: BeneficiaryBeneficiaryRelationType | null;
            ssn?: string | null;
            state: string;
            streetAddress?: string | null;
            updateTime: any;
            zipCode: string;
            beneficiarySet: { __typename?: 'BeneficiarySet'; id: string; isPending: boolean };
          }> | null;
        } | null;
      } | null;
    } | null> | null;
  };
};

export type GetGeneralInvestingPortfoliosQueryVariables = Exact<{ [key: string]: never }>;

export type GetGeneralInvestingPortfoliosQuery = {
  __typename?: 'Query';
  roboGeneralInvestments: {
    __typename?: 'RoboGeneralInvestmentConnection';
    edges?: Array<{
      __typename?: 'RoboGeneralInvestmentEdge';
      node?: {
        __typename?: 'RoboGeneralInvestment';
        id: string;
        createTime: any;
        targetPortfolioID: string;
        accountPortfolioID: string;
        initialInvestmentAmount: any;
        recurringInfusion: any;
        infusionFrequency: RoboGeneralInvestmentRoboInfusionFreq;
        lastRebalanceTime?: any | null;
        totalDividends: any;
        totalInfusions: any;
        totalInterests: any;
        totalWithdrawals: any;
        isAutoInfusionPaused: boolean;
        targetPortfolio: {
          __typename?: 'ModelPortfolio';
          fiatPct: any;
          stockPct: any;
          bondPct: any;
          id: string;
        };
        accountPortfolio: {
          __typename?: 'UserAccountPortfolio';
          totalValue: any;
          account: { __typename?: 'UserAccount'; id: string; externalID?: string | null };
        };
        allocation: {
          __typename?: 'RoboAllocation';
          stockPct: any;
          fiatPct: any;
          bondPct: any;
          drift: number;
          totalValue: any;
          allocation: Array<{
            __typename?: 'RoboAllocationAsset';
            balance: any;
            value: any;
            percentage: any;
            asset:
              | { __typename?: 'CryptoAsset'; name: string }
              | { __typename?: 'FiatAsset'; name: string }
              | { __typename?: 'SecurityAsset'; name: string };
          }>;
          allocationBySector: Array<{
            __typename?: 'RoboAllocationSector';
            value: any;
            percentage: any;
            sector: {
              __typename?: 'ModelPortfolioSector';
              name: string;
              category: ModelPortfolioSectorCategory;
              id: string;
              assets: Array<{
                __typename?: 'ModelPortfolioAssetComposition';
                targetPct: any;
                asset:
                  | { __typename?: 'CryptoAsset'; id: string; symbol: string }
                  | { __typename?: 'FiatAsset'; id: string; symbol: string }
                  | { __typename?: 'SecurityAsset'; id: string; symbol: string };
              }>;
            };
            assets: Array<{
              __typename?: 'RoboAllocationAsset';
              balance: any;
              value: any;
              asset:
                | { __typename?: 'CryptoAsset'; name: string }
                | { __typename?: 'FiatAsset'; name: string }
                | { __typename?: 'SecurityAsset'; name: string };
            }>;
          }>;
        };
      } | null;
    } | null> | null;
  };
};

export type TrustedContactDetailsFragment = {
  __typename?: 'TrustedContact';
  id: string;
  firstName: string;
  middleName?: string | null;
  lastName: string;
  dateOfBirth: any;
  email: string;
  phoneNumber?: any | null;
  status?: TrustedContactTrustedContactStatus | null;
};

export type GetUserDetailsV2QueryVariables = Exact<{ [key: string]: never }>;

export type GetUserDetailsV2Query = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserConnection';
    edges?: Array<{
      __typename?: 'UserEdge';
      node?: {
        __typename?: 'User';
        createTime: any;
        allowedAchRelationships: number;
        username?: string | null;
        id: string;
        firstName: string;
        lastName: string;
        legacyID?: number | null;
        isActive: boolean;
        isLoginMfaEnabled: boolean;
        accounts: {
          __typename?: 'UserAccountConnection';
          edges?: Array<{
            __typename?: 'UserAccountEdge';
            node?: {
              __typename?: 'UserAccount';
              id: string;
              type: UserAccountAccountType;
              status: UserAccountAccountStatus;
              trustedContacts?: Array<{
                __typename?: 'TrustedContact';
                id: string;
                firstName: string;
                middleName?: string | null;
                lastName: string;
                dateOfBirth: any;
                email: string;
                phoneNumber?: any | null;
                status?: TrustedContactTrustedContactStatus | null;
              }> | null;
              portfolios: {
                __typename?: 'UserAccountPortfolioConnection';
                edges?: Array<{
                  __typename?: 'UserAccountPortfolioEdge';
                  node?: {
                    __typename?: 'UserAccountPortfolio';
                    id: string;
                    account: {
                      __typename?: 'UserAccount';
                      createTime: any;
                      externalID?: string | null;
                      ascendMeta?: {
                        __typename?: 'AscendMeta';
                        accountNumber: string;
                        accountID: string;
                      } | null;
                    };
                  } | null;
                } | null> | null;
              };
              beneficiariesPrimary?: {
                __typename?: 'BeneficiarySet';
                id: string;
                beneficiaries?: Array<{
                  __typename?: 'Beneficiary';
                  id: string;
                  additionalStreetAddress?: string | null;
                  city: string;
                  country?: any | null;
                  createTime: any;
                  dateOfBirth: any;
                  email?: string | null;
                  firstName: string;
                  middleName?: string | null;
                  lastName: string;
                  percentage: number;
                  phoneNumber?: any | null;
                  relationship?: BeneficiaryBeneficiaryRelationType | null;
                  ssn?: string | null;
                  state: string;
                  streetAddress?: string | null;
                  updateTime: any;
                  zipCode: string;
                  beneficiarySet: { __typename?: 'BeneficiarySet'; id: string; isPending: boolean };
                }> | null;
              } | null;
              beneficiariesContingent?: {
                __typename?: 'BeneficiarySet';
                id: string;
                beneficiaries?: Array<{
                  __typename?: 'Beneficiary';
                  id: string;
                  additionalStreetAddress?: string | null;
                  city: string;
                  country?: any | null;
                  createTime: any;
                  dateOfBirth: any;
                  email?: string | null;
                  firstName: string;
                  middleName?: string | null;
                  lastName: string;
                  percentage: number;
                  phoneNumber?: any | null;
                  relationship?: BeneficiaryBeneficiaryRelationType | null;
                  ssn?: string | null;
                  state: string;
                  streetAddress?: string | null;
                  updateTime: any;
                  zipCode: string;
                  beneficiarySet: { __typename?: 'BeneficiarySet'; id: string; isPending: boolean };
                }> | null;
              } | null;
            } | null;
          } | null> | null;
        };
        profile?: {
          __typename?: 'UserProfile';
          unverifiedEmail?: string | null;
          unverifiedPhoneNumber?: string | null;
          firstName: string;
          lastName: string;
          dateOfBirth: any;
          email: string;
          phoneNumber: string;
          taxID?: string | null;
          phoneNumberType: UserProfileUserPhoneNumberType;
          country?: any | null;
          visaType?: UserProfileUserVisaType | null;
          visaExpirationDate?: any | null;
          city?: string | null;
          streetAddress?: string | null;
          additionalStreetAddress?: string | null;
          state?: string | null;
          zipCode?: string | null;
          filingStatus?: UserProfileUserFilingStatus | null;
          annualGrossIncome?: number | null;
          otherSourcesOfWealth?: {
            __typename?: 'OtherSourcesOfWealth';
            hasOtherSourcesOfWealth: boolean;
            otherSourcesOfWealth?: string | null;
          } | null;
          citizenshipCountry?: {
            __typename?: 'Country';
            id: string;
            name: string;
            alpha3Code: string;
          } | null;
          birthCountry?: {
            __typename?: 'Country';
            id: string;
            name: string;
            alpha3Code: string;
          } | null;
          controlPersonDetails?: {
            __typename?: 'ControlPersonDetails';
            companySymbols?: Array<string> | null;
            isControlPerson: boolean;
          } | null;
          politicallyExposedDetails?: {
            __typename?: 'PoliticallyExposedDetails';
            organizationName?: string | null;
            familyMembers?: Array<string> | null;
            isPoliticallyExposed: boolean;
          } | null;
          affiliatedExchangeOrFinraDetails?: {
            __typename?: 'AffiliatedExchangeOrFINRADetails';
            firmName?: string | null;
            isAffiliated: boolean;
          } | null;
          marketDataAnswers?: {
            __typename?: 'MarketDataAnswers';
            personalUse: boolean;
            gettingDataFromExternalSource: boolean;
            registeredWithSecOrCftc: boolean;
            requiresSecOrCftcRegistration: boolean;
            investForOther: boolean;
            investForTheBenefitOfOther: boolean;
            advisorOrConsultant: boolean;
            sharesProfit: boolean;
            registeredWithEntity: boolean;
          } | null;
          updateRequests: {
            __typename?: 'UserProfileUpdateRequestConnection';
            edges?: Array<{
              __typename?: 'UserProfileUpdateRequestEdge';
              node?: {
                __typename?: 'UserProfileUpdateRequest';
                createTime: any;
                status: UserProfileUpdateRequestUpdateRequestStatus;
                requestParameters: {
                  __typename?: 'UserProfileParameters';
                  firstName?: string | null;
                  lastName?: string | null;
                  email?: string | null;
                  dateOfBirth?: any | null;
                  phoneNumber?: string | null;
                  phoneNumberType?: UserProfileUserPhoneNumberType | null;
                  ssn?: string | null;
                  streetAddress?: string | null;
                  additionalStreetAddress?: string | null;
                  city?: string | null;
                  state?: string | null;
                  zipCode?: string | null;
                  country?: string | null;
                  taxFilingInfo?: {
                    __typename?: 'TaxFilingInfo';
                    filingStatus?: UserFilingStatus | null;
                    adjustGrossIncome?: number | null;
                  } | null;
                  controlPersonDetails?: {
                    __typename: 'ControlPersonDetails';
                    companySymbols?: Array<string> | null;
                    isControlPerson: boolean;
                  } | null;
                  politicallyExposedDetails?: {
                    __typename: 'PoliticallyExposedDetails';
                    organizationName?: string | null;
                    familyMembers?: Array<string> | null;
                    isPoliticallyExposed: boolean;
                  } | null;
                  affiliatedExchangeOrFinraDetails?: {
                    __typename: 'AffiliatedExchangeOrFINRADetails';
                    firmName?: string | null;
                    isAffiliated: boolean;
                  } | null;
                  marketDataAnswers?: {
                    __typename: 'MarketDataAnswers';
                    personalUse: boolean;
                    gettingDataFromExternalSource: boolean;
                    registeredWithSecOrCftc: boolean;
                    requiresSecOrCftcRegistration: boolean;
                    investForOther: boolean;
                    investForTheBenefitOfOther: boolean;
                    advisorOrConsultant: boolean;
                    sharesProfit: boolean;
                    registeredWithEntity: boolean;
                  } | null;
                };
              } | null;
            } | null> | null;
          };
        } | null;
        portfolios: {
          __typename?: 'UserAccountPortfolioConnection';
          edges?: Array<{
            __typename?: 'UserAccountPortfolioEdge';
            node?: {
              __typename?: 'UserAccountPortfolio';
              id: string;
              account: {
                __typename?: 'UserAccount';
                apexAccountMeta?: { __typename?: 'ApexAccountMeta'; id: string } | null;
              };
            } | null;
          } | null> | null;
        };
      } | null;
    } | null> | null;
  };
  nonDiscretionaryAccountKycs: {
    __typename?: 'NonDiscretionaryAccountKycConnection';
    edges?: Array<{
      __typename?: 'NonDiscretionaryAccountKycEdge';
      node?: {
        __typename?: 'NonDiscretionaryAccountKyc';
        annualIncome?: NonDiscretionaryAccountKycUserAnnualIncome | null;
        investmentExperience?: NonDiscretionaryAccountKycUserInvestmentExperience | null;
        investmentObjective?: NonDiscretionaryAccountKycUserInvestmentObjective | null;
        maritalStatus?: NonDiscretionaryAccountKycUserMaritalStatus | null;
        liquidityNeeds?: NonDiscretionaryAccountKycUserLiquidityNeeds | null;
        riskTolerance?: UserRiskTolerance | null;
        timeHorizon?: NonDiscretionaryAccountKycUserTimeHorizon | null;
        netWorth?: NonDiscretionaryAccountKycUserNetWorth | null;
        employmentStatus?: NonDiscretionaryAccountKycUserEmploymentStatus | null;
        account?: Array<{
          __typename?: 'UserAccount';
          id: string;
          externalID?: string | null;
        }> | null;
        employmentDetails?: {
          __typename?: 'EmploymentDetails';
          EmployerName: string;
          Position: string;
        } | null;
        updateRequests?: Array<{
          __typename?: 'NonDiscretionaryUpdateRequest';
          createTime: any;
          status: NonDiscretionaryUpdateRequestUpdateRequestStatus;
          requestParameters?: {
            __typename?: 'BrokerageAccountParameters';
            annualIncome?: NonDiscretionaryAccountKycUserAnnualIncome | null;
            investmentExperience?: NonDiscretionaryAccountKycUserInvestmentExperience | null;
            investmentObjective?: NonDiscretionaryAccountKycUserInvestmentObjective | null;
            maritalStatus?: NonDiscretionaryAccountKycUserMaritalStatus | null;
            liquidityNeeds?: NonDiscretionaryAccountKycUserLiquidityNeeds | null;
            riskTolerance?: UserRiskTolerance | null;
            timeHorizon?: NonDiscretionaryAccountKycUserTimeHorizon | null;
            netWorth?: NonDiscretionaryAccountKycUserNetWorth | null;
            employmentStatus?: NonDiscretionaryAccountKycUserEmploymentStatus | null;
            employmentDetails?: {
              __typename?: 'EmploymentDetails';
              EmployerName: string;
              Position: string;
            } | null;
          } | null;
        }> | null;
      } | null;
    } | null> | null;
  };
  discretionaryAccountKycs: {
    __typename?: 'DiscretionaryAccountKycConnection';
    edges?: Array<{
      __typename?: 'DiscretionaryAccountKycEdge';
      node?: {
        __typename?: 'DiscretionaryAccountKyc';
        annualIncome?: DiscretionaryAccountKycUserAnnualIncome | null;
        investmentObjective?: DiscretionaryAccountKycUserInvestmentObjective | null;
        riskTolerance?: UserRiskTolerance | null;
        netWorth?: DiscretionaryAccountKycUserNetWorth | null;
        employmentStatus?: DiscretionaryAccountKycUserEmploymentStatus | null;
        account?: Array<{
          __typename?: 'UserAccount';
          externalID?: string | null;
          id: string;
        }> | null;
        employmentDetails?: {
          __typename?: 'EmploymentDetails';
          EmployerName: string;
          Position: string;
        } | null;
        updateRequests?: Array<{
          __typename?: 'DiscretionaryUpdateRequest';
          createTime: any;
          status: DiscretionaryUpdateRequestUpdateRequestStatus;
          requestParameters?: {
            __typename?: 'BrokerageDiscretionaryAccountParameters';
            annualIncome?: DiscretionaryAccountKycUserAnnualIncome | null;
            investmentObjective?: DiscretionaryAccountKycUserInvestmentObjective | null;
            riskTolerance?: UserRiskTolerance | null;
            netWorth?: DiscretionaryAccountKycUserNetWorth | null;
            employmentStatus?: DiscretionaryAccountKycUserEmploymentStatus | null;
            employmentDetails?: {
              __typename?: 'EmploymentDetails';
              EmployerName: string;
              Position: string;
            } | null;
          } | null;
        }> | null;
      } | null;
    } | null> | null;
  };
  trustedContacts: {
    __typename?: 'TrustedContactConnection';
    edges?: Array<{
      __typename?: 'TrustedContactEdge';
      node?: {
        __typename: 'TrustedContact';
        id: string;
        firstName: string;
        middleName?: string | null;
        lastName: string;
        dateOfBirth: any;
        email: string;
        phoneNumber?: any | null;
        isActive?: boolean | null;
      } | null;
    } | null> | null;
  };
  trustedContactUpdateRequests: {
    __typename?: 'TrustedContactUpdateRequestConnection';
    edges?: Array<{
      __typename?: 'TrustedContactUpdateRequestEdge';
      node?: {
        __typename: 'TrustedContactUpdateRequest';
        id: string;
        createTime: any;
        status: TrustedContactUpdateRequestUpdateRequestStatus;
        requestParameters: {
          __typename?: 'TrustedContactParameters';
          firstName?: string | null;
          lastName?: string | null;
          dateOfBirth?: any | null;
          email?: string | null;
          phoneNumber?: string | null;
        };
      } | null;
    } | null> | null;
  };
};

export type GetUserAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserAccountsQuery = {
  __typename?: 'Query';
  userAccounts: {
    __typename?: 'UserAccountConnection';
    edges?: Array<{
      __typename?: 'UserAccountEdge';
      node?: {
        __typename?: 'UserAccount';
        id: string;
        externalID?: string | null;
        type: UserAccountAccountType;
        ascendMeta?: { __typename?: 'AscendMeta'; accountID: string; accountNumber: string } | null;
        apexAccountMeta?: { __typename?: 'ApexAccountMeta'; id: string } | null;
      } | null;
    } | null> | null;
  };
};

export type GetWatchlistSecuritiesQueryVariables = Exact<{ [key: string]: never }>;

export type GetWatchlistSecuritiesQuery = {
  __typename?: 'Query';
  watchlists: Array<{
    __typename?: 'Watchlist';
    name: string;
    list: Array<
      | { __typename?: 'CryptoAsset'; id: string; type: AssetAssetType }
      | { __typename?: 'FiatAsset'; id: string; type: AssetAssetType }
      | {
          __typename?: 'SecurityAsset';
          id: string;
          type: AssetAssetType;
          name: string;
          symbol: string;
          active: boolean;
          iconURI?: string | null;
          logoURI?: string | null;
          market?: {
            __typename?: 'AssetMarketData';
            last: any;
            askSize: any;
            baseMin?: any | null;
            previousDayClose: any;
          } | null;
        }
    >;
  }>;
};

export const CommonUserProfileRequestParametersFragmentDoc = gql`
  fragment CommonUserProfileRequestParameters on UserProfileParameters {
    firstName
    lastName
    email
    dateOfBirth
    phoneNumber
    phoneNumberType
    ssn
    streetAddress
    additionalStreetAddress
    city
    state
    zipCode
    country
    taxFilingInfo {
      filingStatus
      adjustGrossIncome
    }
    controlPersonDetails {
      companySymbols
      isControlPerson
      __typename
    }
    politicallyExposedDetails {
      organizationName
      familyMembers
      isPoliticallyExposed
      __typename
    }
    affiliatedExchangeOrFinraDetails {
      firmName
      isAffiliated
      __typename
    }
    marketDataAnswers {
      personalUse
      gettingDataFromExternalSource
      registeredWithSecOrCftc
      requiresSecOrCftcRegistration
      investForOther
      investForTheBenefitOfOther
      advisorOrConsultant
      sharesProfit
      registeredWithEntity
      __typename
    }
  }
`;
export const CommonUserProfileDetailsFragmentDoc = gql`
  fragment CommonUserProfileDetails on UserProfile {
    firstName
    lastName
    ssn
    dateOfBirth
    phoneNumber
    email
    streetAddress
    additionalStreetAddress
    city
    id
    state
    zipCode
    country
    permanentResident
    visaType
    visaExpirationDate
    birthCountry {
      name
      alpha3Code
    }
    citizenshipCountry {
      name
      alpha3Code
    }
    updateRequests(orderBy: { direction: DESC, field: create_time }) {
      edges {
        node {
          createTime
          status
          requestParameters {
            ...CommonUserProfileRequestParameters
          }
        }
      }
    }
  }
  ${CommonUserProfileRequestParametersFragmentDoc}
`;
export const CommonTrustedContactRequestParametersDetailsFragmentDoc = gql`
  fragment CommonTrustedContactRequestParametersDetails on TrustedContactParameters {
    firstName
    lastName
    dateOfBirth
    email
    phoneNumber
  }
`;
export const CommonTrustedContactUpdateRequestsDetailsFragmentDoc = gql`
  fragment CommonTrustedContactUpdateRequestsDetails on TrustedContactUpdateRequest {
    createTime
    status
    requestParameters {
      ...CommonTrustedContactRequestParametersDetails
    }
  }
  ${CommonTrustedContactRequestParametersDetailsFragmentDoc}
`;
export const CommonUserInvestorInformationNonDiscretionaryRequestParametersDetailsFragmentDoc = gql`
  fragment CommonUserInvestorInformationNonDiscretionaryRequestParametersDetails on BrokerageAccountParameters {
    annualIncome
    investmentExperience
    investmentObjective
    maritalStatus
    liquidityNeeds
    riskTolerance
    timeHorizon
    netWorth
    employmentStatus
    employmentDetails {
      EmployerName
      Position
    }
  }
`;
export const CommonUserInvestorInformationNonDiscretionaryDetailsFragmentDoc = gql`
  fragment CommonUserInvestorInformationNonDiscretionaryDetails on NonDiscretionaryAccountKyc {
    account {
      id
      externalID
    }
    annualIncome
    investmentExperience
    investmentObjective
    maritalStatus
    liquidityNeeds
    riskTolerance
    timeHorizon
    netWorth
    employmentStatus
    employmentDetails {
      EmployerName
      Position
    }
    updateRequests {
      createTime
      status
      requestParameters {
        ...CommonUserInvestorInformationNonDiscretionaryRequestParametersDetails
      }
    }
  }
  ${CommonUserInvestorInformationNonDiscretionaryRequestParametersDetailsFragmentDoc}
`;
export const CommonUserInvestorInformationDiscretionaryRequestParametersDetailsFragmentDoc = gql`
  fragment CommonUserInvestorInformationDiscretionaryRequestParametersDetails on BrokerageDiscretionaryAccountParameters {
    annualIncome
    investmentObjective
    riskTolerance
    netWorth
    employmentStatus
    employmentDetails {
      EmployerName
      Position
    }
  }
`;
export const CommonUserInvestorInformationDiscretionaryDetailsFragmentDoc = gql`
  fragment CommonUserInvestorInformationDiscretionaryDetails on DiscretionaryAccountKyc {
    annualIncome
    investmentObjective
    riskTolerance
    netWorth
    employmentStatus
    account {
      externalID
      id
    }
    employmentDetails {
      EmployerName
      Position
    }
    updateRequests {
      createTime
      status
      requestParameters {
        ...CommonUserInvestorInformationDiscretionaryRequestParametersDetails
      }
    }
  }
  ${CommonUserInvestorInformationDiscretionaryRequestParametersDetailsFragmentDoc}
`;
export const CommonUserAccountDetailsFragmentFragmentDoc = gql`
  fragment CommonUserAccountDetailsFragment on UserAccount {
    id
    user {
      id
      profile {
        firstName
        lastName
        ssn
        email
        phoneNumber
        country
        state
        city
        streetAddress
        additionalStreetAddress
        zipCode
      }
    }
    portfolios {
      edges {
        node {
          id
          isDefault
          assetBalances {
            edges {
              node {
                asset {
                  symbol
                }
                balances {
                  value
                }
              }
            }
          }
        }
      }
    }
    adminActions {
      adminFirstName
      adminLastName
    }
    type
    externalID
    createTime
    status
  }
`;
export const GetLiquidationDebtsFragmentFragmentDoc = gql`
  fragment GetLiquidationDebtsFragment on Liability {
    id
    account {
      id
      adminActions {
        adminFirstName
        adminLastName
      }
      externalID
      user {
        id
        firstName
        lastName
      }
    }
    createTime
    owedSince
    amountOwed
    amountPaid
    amountRemaining
  }
`;
export const GetUserAccountPortfolioEdgeFragmentFragmentDoc = gql`
  fragment GetUserAccountPortfolioEdgeFragment on UserAccountPortfolioEdge {
    node {
      id
      account {
        externalID
      }
      assetBalances(where: { hasAssetWith: { not: { symbol: "USD" } } }) {
        edges {
          node {
            asset {
              id
              symbol
              name
              createTime
              ... on SecurityAsset {
                market {
                  last
                }
              }
            }
            balances {
              value
              activeBalance
            }
          }
        }
      }
    }
  }
`;
export const GetSecurityLiquidationFragmentFragmentDoc = gql`
  fragment GetSecurityLiquidationFragment on BaseOrder {
    id
    orderType
    orderStatus
    ... on FeeAndCreditOrder {
      amount
      note
      portfolio {
        user {
          id
          firstName
          lastName
        }
        account {
          externalID
        }
      }
    }
    ... on Liquidation {
      user {
        id
        firstName
        lastName
      }
      totalLiquidatedAmount
      liquidationPortfolio {
        account {
          externalID
        }
      }
      note
    }
    createTime
    requestDetails {
      requestor
      admin {
        id
        createTime
        updateTime
        externalID
        firstName
        lastName
      }
    }
  }
`;
export const AdminUserRoboGoalFragmentFragmentDoc = gql`
  fragment AdminUserRoboGoalFragment on RoboGoal {
    id
    targetPortfolioID
    accountPortfolioID
    name
    isActive
    accountPortfolio {
      totalValue
      account {
        id
        externalID
      }
    }
    user {
      id
    }
    targetPortfolio {
      externalReferenceID
    }
    isAutoInfusionPaused
    allocation {
      fiatPct
      stockPct
      bondPct
      totalValue
      drift
    }
    parameters {
      infusionAmount
      infusionFreq
      endDate
      targetAmount
      riskProfile
      goalPriority
      infusionAmount
      infusionFreq
      initialInvestment
      maxDrift
    }
    accountPortfolio {
      id
      name
      totalValue
    }
    projection {
      goalProbability
    }
    fundingAccount {
      accountNumber
    }
    createTime
    updateTime
    actions(where: { type: ROBO_ACTIVITY_TYPE_INFUSION, status: ROBO_ACTION_STATUS_DONE }) {
      edges {
        node {
          details {
            ... on RoboTransferDetails {
              amount
            }
          }
        }
      }
    }
  }
`;
export const AdminTradeFragmentFragmentDoc = gql`
  fragment AdminTradeFragment on Trade {
    id
    orderStatus
    user {
      id
    }
    portfolio {
      name
      account {
        id
        type
        externalID
        user {
          profile {
            firstName
            lastName
          }
          username
        }
      }
    }
    expirationType
    status
    reason
    note
    requestDetails {
      requestUUID
      requestor
      admin {
        id
        email
        createTime
        externalID
        updateTime
        firstName
        lastName
      }
    }
    statusChangeLog {
      status
      eventTime
      note
    }
    base {
      symbol
      ... on SecurityAsset {
        market {
          last
        }
      }
    }
    side
    stop
    limit
    type
    requestAmount
    requestInQuote
    baseAmount
    quoteAmount
    completedAt: statusChangeLog(status: TRADE_STATUS_UNSETTLED) {
      eventTime
    }
    createdAt: statusChangeLog(status: TRADE_STATUS_NEW) {
      eventTime
    }
    recurringRuleInstance {
      id
      rule {
        id
      }
    }
  }
`;
export const IctTransferFragmentDoc = gql`
  fragment IctTransfer on ICTTransfer {
    id
    externalAccount {
      id
      mask
      institution {
        name
        __typename
      }
      user {
        firstName
        lastName
        __typename
      }
      __typename
    }
    recurringRuleInstance {
      id
      rule {
        asset {
          symbol
          __typename
        }
        sector {
          name
          __typename
        }
        id
        __typename
      }
      __typename
    }
    isReturned
    instantDepositAmount
    orderType
    orderStatus
    status
    amount
    ictReturnFee: returnFee
    ictType: type
    ictTransferErrorCode: errorCode
    createTime
  }
`;
export const IctTransferEdgeFragmentDoc = gql`
  fragment IctTransferEdge on ICTTransferEdge {
    node {
      ...IctTransfer
    }
    cursor
  }
  ${IctTransferFragmentDoc}
`;
export const AchTransferFragmentDoc = gql`
  fragment AchTransfer on ACHTransfer {
    id
    orderStatus
    externalAccount {
      id
      mask
      institution {
        name
      }
      user {
        firstName
        lastName
      }
    }
    recurringRuleInstance {
      id
      rule {
        asset {
          symbol
        }
        sector {
          name
        }
        id
      }
    }
    amount
    instantDepositAmount
    returnFee
    isReturned
    transferErrorCode: errorCode
    transferType: type
    reason
    transferStatus: status
    transferChangeLog: statusChangeLog {
      status
      eventTime
    }
    expectedSettlementDate
    lockup {
      clearDate
    }
    createTime
    updateTime
  }
`;
export const AchTransferEdgeFragmentDoc = gql`
  fragment AchTransferEdge on ACHTransferEdge {
    node {
      ...AchTransfer
    }
    cursor
  }
  ${AchTransferFragmentDoc}
`;
export const UserAccountDetailFragmentDoc = gql`
  fragment UserAccountDetail on User {
    accounts(where: $accountFilter) {
      edges {
        node {
          type
          portfolios {
            edges {
              node {
                id
                name
                managementBy
                totalValue
                fiat {
                  total
                }
                security {
                  total
                }
                crypto {
                  total
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const UserRoboGoalDetailFragmentDoc = gql`
  fragment UserRoboGoalDetail on User {
    roboGoals {
      edges {
        node {
          id
          targetPortfolioID
          accountPortfolioID
          name
          isActive
          accountPortfolio {
            totalValue
            account {
              id
              externalID
            }
          }
          isAutoInfusionPaused
          allocation {
            totalValue
          }
          parameters {
            targetAmount
            riskProfile
            goalPriority
          }
          projection {
            goalProbability
          }
          accountPortfolio {
            id
            name
            totalValue
          }
          fundingAccount {
            accountNumber
          }
        }
      }
    }
  }
`;
export const DividendDistributionFragmentFragmentDoc = gql`
  fragment DividendDistributionFragment on DividendDistribution {
    id
    orderStatus
    orderType
    isQualified
    isReinvested
    payDate
    amount
    createTime
    distributionAsset {
      symbol
    }
  }
`;
export const DividendDistributionEdgeFragmentDoc = gql`
  fragment DividendDistributionEdge on DividendDistributionEdge {
    node {
      ...DividendDistributionFragment
    }
  }
  ${DividendDistributionFragmentFragmentDoc}
`;
export const ExternalAccountDataFragmentDoc = gql`
  fragment ExternalAccountData on ExternalAccountEdge {
    node {
      id
      type
      accountNumber
      user {
        id
        firstName
        lastName
      }
      institution {
        id
        createTime
        updateTime
        name
        unusualCount
      }
      mask
      connectionStatus
    }
  }
`;
export const InterestDistributionFragmentFragmentDoc = gql`
  fragment InterestDistributionFragment on InterestDistribution {
    id
    orderType
    orderStatus
    amount
    type
    processDate
    effectiveDate
    recordDate
    createTime
  }
`;
export const InterestDistributionEdgeFragmentDoc = gql`
  fragment InterestDistributionEdge on InterestDistributionEdge {
    node {
      ...InterestDistributionFragment
    }
  }
  ${InterestDistributionFragmentFragmentDoc}
`;
export const SectorFieldsFragmentDoc = gql`
  fragment SectorFields on Query {
    securitySectors {
      edges {
        node {
          description
          id
          imagePrimary
          imageSector
          name
          portfolioAvgReturn
          portfolioExpRetLb
          portfolioExpRetUb
          portfolioRealTimePerf
          portfolioStdDev
          portfolioVariance
          portfolioYearlyPerf
          securities {
            symbol
          }
        }
      }
    }
  }
`;
export const MarketFieldsFragmentDoc = gql`
  fragment MarketFields on Query {
    fiatAssets {
      edges {
        node {
          id
          type
          name
          symbol
        }
      }
    }
  }
`;
export const ExternalAccountFragmentFragmentDoc = gql`
  fragment ExternalAccountFragment on ExternalAccount {
    id
    mask
    type
    connectionStatus
    connectionType
    accountNumber
    createTime
    institution {
      id
      name
      createTime
      updateTime
    }
    connectionDetails {
      __typename
      ... on ExternalAccountConnectionDetailsPlaid {
        relationshipOwner {
          id
        }
      }
    }
  }
`;
export const RecurringRuleFragmentFragmentDoc = gql`
  fragment RecurringRuleFragment on RecurringRule {
    amount
    lifetimeAmount
    createTime
    end
    fundingAccount {
      accountNumber
      id
    }
    fundingMethod
    id
    interval
    isActive
    name
    next
    nextTarget
    note
    reason
    sector {
      id
      largeImage
      name
      order
      security {
        id
        imageLogo
        symbol
        name
        market {
          last
          previousDayClose
        }
      }
      smallImage
      type
    }
    start
    type
    updateTime
    user {
      id
    }
    portfolio {
      id
    }
    asset {
      id
      type
      name
      symbol
      active
      ... on SecurityAsset {
        name
        imageLogo
        securityType
        market {
          last
          previousDayClose
        }
      }
    }
  }
`;
export const RoboInvestmentActionFragmentFragmentDoc = gql`
  fragment RoboInvestmentActionFragment on RoboInvestmentAction {
    id
    type
    status
    requestTime
    requestor
    goal {
      fundingAccount {
        mask
        institution {
          name
        }
      }
    }
    details {
      __typename
      ... on RoboTransferDetails {
        amount
        expectedSettlementDate
        expectedTradeDate
      }
      ... on RoboFeeDetails {
        type
        amount
      }
      ... on RoboDividendDetails {
        amount
      }
      ... on RoboInterestDetails {
        amount
        source
      }
    }
  }
`;
export const RoboInvestmentActionEdgeFragmentFragmentDoc = gql`
  fragment RoboInvestmentActionEdgeFragment on RoboInvestmentActionEdge {
    node {
      ...RoboInvestmentActionFragment
    }
    cursor
  }
  ${RoboInvestmentActionFragmentFragmentDoc}
`;
export const StockSleeveScreenModelPortfolioFragmentFragmentDoc = gql`
  fragment StockSleeveScreenModelPortfolioFragment on ModelPortfolio {
    id
    sectors {
      targetPct
      sector {
        id
        name
        description
        uxType
        assets {
          targetPct
          asset {
            id
            symbol
            name
          }
        }
      }
    }
  }
`;
export const RoboGoalParametersFragmentDoc = gql`
  fragment RoboGoalParameters on RoboGoalParameters {
    endDate
    riskProfile
    targetAmount
    infusionAmount
    infusionFreq
    initialInvestment
    goalPriority
    infusionStartDate
  }
`;
export const RoboGoalProjectionFragmentDoc = gql`
  fragment RoboGoalProjection on RoboGoalProjection {
    goalProbability
    goalTargetProbability
    suggestedOneTimeTopUp
    suggestedMonthlyTopUpAccumulation
    wealthPath {
      years
      default {
        wealthPath
      }
      pessimistic {
        wealthPath
      }
      optimistic {
        wealthPath
      }
    }
  }
`;
export const GetSectorDetailsFragmentDoc = gql`
  fragment GetSectorDetails on ModelPortfolioSector {
    id
    name
    description
    category
    assets {
      targetPct
      asset {
        id
        symbol
        name
        ... on CryptoAsset {
          imageLogo
        }
        ... on FiatAsset {
          imageLogo
        }
        ... on SecurityAsset {
          imageLogo
        }
      }
    }
  }
`;
export const CommonRtqPortfolioLevelFragmentDoc = gql`
  fragment CommonRTQPortfolioLevel on RTQPortfolioLevelSet {
    levels {
      id
      index
      name
      description
      assignments {
        modelPortfolio {
          id
          stockPct
          bondPct
          fiatPct
          sectors {
            id
            targetPct
            sector {
              ...GetSectorDetails
            }
          }
        }
      }
    }
  }
  ${GetSectorDetailsFragmentDoc}
`;
export const RtqQuestionsFragmentDoc = gql`
  fragment RTQQuestions on RTQQuestion {
    label
    type
    category
    id
    options {
      id
      label
      score
    }
  }
`;
export const RtqPortfolioLevelScoresFragmentDoc = gql`
  fragment RTQPortfolioLevelScores on RTQPortfolioLevelScore {
    riskTolerance {
      min
      max
    }
    riskCapacity {
      min
      max
    }
    level {
      id
      name
    }
  }
`;
export const SecurityAssetBasicDetailsFragmentDoc = gql`
  fragment SecurityAssetBasicDetails on SecurityAsset {
    id
    createTime
    updateTime
    name
    description
    symbol
    iconURI
    logoURI
    highlyVolatile
    complexSecurity
    market {
      volume
      previousDayClose
      last
      close
      open
      bidSize
      askSize
      baseMin
    }
  }
`;
export const SecurityAssetDetailedDataFragmentDoc = gql`
  fragment SecurityAssetDetailedData on SecurityAsset {
    ...SecurityAssetBasicDetails
    financials {
      statements(limit: 1) {
        revenue
        ebitda
        earningsPerShare
        profitMargin
        bookValuePerShare
      }
      dividends(limit: 1) {
        cashAmount
      }
      peRatio
    }
    market {
      historical {
        volume
      }
    }
    marketCap
    askExchange {
      id
      logo
      name
    }
    bidExchange {
      id
      logo
      name
    }
    sector {
      id
      name
    }
    securityType
    beta
    cusip
  }
  ${SecurityAssetBasicDetailsFragmentDoc}
`;
export const SecurityAssetPageFragmentDoc = gql`
  fragment SecurityAssetPage on SecurityAsset {
    id
    name
    symbol
    active
    type
    iconURI
    logoURI
    market {
      last
      askSize
      baseMin
      previousDayClose
    }
  }
`;
export const SecurityAssetEdgePageFragmentDoc = gql`
  fragment SecurityAssetEdgePage on SecurityAssetEdge {
    node {
      ...SecurityAssetPage
    }
  }
  ${SecurityAssetPageFragmentDoc}
`;
export const TradeFragmentFragmentDoc = gql`
  fragment TradeFragment on Trade {
    id
    base {
      __typename
      id
      symbol
      name
      ... on SecurityAsset {
        securityType
      }
    }
    orderStatus
    side
    tradeType: type
    limit
    isAll
    requestAmount
    requestInQuote
    stop
    reason
    errorCode
    recurringRuleInstance {
      id
      rule {
        asset {
          symbol
        }
        sector {
          name
        }
        id
      }
    }
    liquidation {
      id
    }
    expiration
    expirationType
    tradeStatus: status
    tradeStatusChangeLog: statusChangeLog(status: TRADE_STATUS_SETTLED) {
      status
      eventTime
    }
    baseAmount
    quoteAmount
    feeAmount
    createTime
    updateTime
  }
`;
export const TradeEdgeFragmentFragmentDoc = gql`
  fragment TradeEdgeFragment on TradeEdge {
    node {
      ...TradeFragment
    }
    cursor
  }
  ${TradeFragmentFragmentDoc}
`;
export const UserSharesEdgeFragmentDoc = gql`
  fragment UserSharesEdge on UserAccountPortfolioAssetEdge {
    node {
      id
      costBasis
      balances {
        pending
        liability
        pendingLiability
        unsettled
        settled
        activeBalance
        tradeable
      }
      asset {
        id
        name
        symbol
        createTime
        updateTime
        ... on SecurityAsset {
          __typename
          id
          color
          logoURI
          sector {
            id
          }
          securityType
          market {
            last
            previousDayClose
          }
        }
      }
    }
  }
`;
export const GetAllUserAccountsStatusFragmentFragmentDoc = gql`
  fragment GetAllUserAccountsStatusFragment on UserAccount {
    id
    type
    status
    portfolios {
      edges {
        node {
          id
          account {
            createTime
            externalID
            ascendMeta {
              accountNumber
              accountID
            }
          }
        }
      }
    }
    beneficiariesPrimary {
      id
      beneficiaries {
        id
        additionalStreetAddress
        beneficiarySet {
          id
          isPending
        }
        city
        country
        createTime
        dateOfBirth
        email
        firstName
        middleName
        lastName
        percentage
        phoneNumber
        relationship
        ssn
        state
        streetAddress
        updateTime
        zipCode
      }
    }
    beneficiariesContingent {
      id
      beneficiaries {
        id
        additionalStreetAddress
        beneficiarySet {
          id
          isPending
        }
        city
        country
        createTime
        dateOfBirth
        email
        firstName
        middleName
        lastName
        percentage
        phoneNumber
        relationship
        ssn
        state
        streetAddress
        updateTime
        zipCode
      }
    }
  }
`;
export const TrustedContactDetailsFragmentDoc = gql`
  fragment TrustedContactDetails on TrustedContact {
    id
    firstName
    middleName
    lastName
    dateOfBirth
    email
    phoneNumber
    status
  }
`;
export const GetAdminAchTransfersDocument = gql`
  query GetAdminACHTransfers(
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
    $startDate: Time
    $endDate: Time
    $criteria: String
    $allFilters: [ACHTransferWhereInput!]
    $orderDirection: OrderDirection!
  ) {
    response: achTransfers(
      first: $first
      after: $after
      before: $before
      last: $last
      where: {
        and: $allFilters
        hasUserWith: {
          hasProfileWith: {
            or: [{ firstNameContainsFold: $criteria }, { lastNameContainsFold: $criteria }]
          }
        }
        unsettledTimeGTE: $startDate
        unsettledTimeLTE: $endDate
      }
      orderBy: { direction: $orderDirection, field: create_time }
    ) {
      edges {
        cursor
        node {
          id
          type
          amount
          status
          orderType
          transferFee
          instantDepositAmount
          orderStatus
          isReturned
          reason
          createTime
          user {
            profile {
              firstName
              lastName
            }
          }
          externalAccount {
            institution {
              id
              name
            }
            accountNumber
            id
          }
          portfolio {
            account {
              externalID
              type
            }
          }
          recurringRuleInstance {
            id
            rule {
              id
            }
          }
          statusChangeLog {
            status
            eventTime
            note
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useGetAdminAchTransfersQuery__
 *
 * To run a query within a React component, call `useGetAdminAchTransfersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminAchTransfersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminAchTransfersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      criteria: // value for 'criteria'
 *      allFilters: // value for 'allFilters'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useGetAdminAchTransfersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAdminAchTransfersQuery,
    GetAdminAchTransfersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdminAchTransfersQuery, GetAdminAchTransfersQueryVariables>(
    GetAdminAchTransfersDocument,
    options,
  );
}
export function useGetAdminAchTransfersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdminAchTransfersQuery,
    GetAdminAchTransfersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAdminAchTransfersQuery, GetAdminAchTransfersQueryVariables>(
    GetAdminAchTransfersDocument,
    options,
  );
}
export type GetAdminAchTransfersQueryHookResult = ReturnType<typeof useGetAdminAchTransfersQuery>;
export type GetAdminAchTransfersLazyQueryHookResult = ReturnType<
  typeof useGetAdminAchTransfersLazyQuery
>;
export type GetAdminAchTransfersQueryResult = Apollo.QueryResult<
  GetAdminAchTransfersQuery,
  GetAdminAchTransfersQueryVariables
>;
export const GetAchTransferDetailsByIdDocument = gql`
  query GetAchTransferDetailsById($id: ID!) {
    response: achTransfers(where: { id: $id }) {
      edges {
        node {
          id
          type
          amount
          status
          orderStatus
          orderType
          transferFee
          note
          instantDepositAmount
          orderStatus
          isReturned
          reason
          externalID
          user {
            id
          }
          requestDetails {
            requestUUID
            requestor
            admin {
              id
              email
              createTime
              updateTime
              externalID
              firstName
              lastName
            }
          }
          externalAccount {
            institution {
              id
              name
            }
            accountNumber
            id
          }
          portfolio {
            account {
              externalID
              type
            }
            name
            user {
              username
              profile {
                firstName
                lastName
              }
            }
          }
          recurringRuleInstance {
            id
            rule {
              id
            }
          }
          statusChangeLog {
            status
            eventTime
            note
          }
        }
      }
    }
  }
`;

/**
 * __useGetAchTransferDetailsByIdQuery__
 *
 * To run a query within a React component, call `useGetAchTransferDetailsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAchTransferDetailsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAchTransferDetailsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAchTransferDetailsByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAchTransferDetailsByIdQuery,
    GetAchTransferDetailsByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAchTransferDetailsByIdQuery, GetAchTransferDetailsByIdQueryVariables>(
    GetAchTransferDetailsByIdDocument,
    options,
  );
}
export function useGetAchTransferDetailsByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAchTransferDetailsByIdQuery,
    GetAchTransferDetailsByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAchTransferDetailsByIdQuery,
    GetAchTransferDetailsByIdQueryVariables
  >(GetAchTransferDetailsByIdDocument, options);
}
export type GetAchTransferDetailsByIdQueryHookResult = ReturnType<
  typeof useGetAchTransferDetailsByIdQuery
>;
export type GetAchTransferDetailsByIdLazyQueryHookResult = ReturnType<
  typeof useGetAchTransferDetailsByIdLazyQuery
>;
export type GetAchTransferDetailsByIdQueryResult = Apollo.QueryResult<
  GetAchTransferDetailsByIdQuery,
  GetAchTransferDetailsByIdQueryVariables
>;
export const GetAdminUserAllAccountsDocument = gql`
  query GetAdminUserAllAccounts(
    $id: ID!
    $accountFilter: UserAccountWhereInput
    $discretionaryEnabled: Boolean = true
  ) {
    users(where: { id: $id }) {
      edges {
        cursor
        node {
          ...UserAccountDetail
          ...UserRoboGoalDetail @include(if: $discretionaryEnabled)
          username
          restrictionStatus
          profile {
            firstName
            lastName
            email
            dateOfBirth
            phoneNumber
            ssn
            streetAddress
            additionalStreetAddress
            city
            state
            zipCode
            country
          }
        }
      }
    }
  }
  ${UserAccountDetailFragmentDoc}
  ${UserRoboGoalDetailFragmentDoc}
`;

/**
 * __useGetAdminUserAllAccountsQuery__
 *
 * To run a query within a React component, call `useGetAdminUserAllAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminUserAllAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminUserAllAccountsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      accountFilter: // value for 'accountFilter'
 *      discretionaryEnabled: // value for 'discretionaryEnabled'
 *   },
 * });
 */
export function useGetAdminUserAllAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAdminUserAllAccountsQuery,
    GetAdminUserAllAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdminUserAllAccountsQuery, GetAdminUserAllAccountsQueryVariables>(
    GetAdminUserAllAccountsDocument,
    options,
  );
}
export function useGetAdminUserAllAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdminUserAllAccountsQuery,
    GetAdminUserAllAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAdminUserAllAccountsQuery, GetAdminUserAllAccountsQueryVariables>(
    GetAdminUserAllAccountsDocument,
    options,
  );
}
export type GetAdminUserAllAccountsQueryHookResult = ReturnType<
  typeof useGetAdminUserAllAccountsQuery
>;
export type GetAdminUserAllAccountsLazyQueryHookResult = ReturnType<
  typeof useGetAdminUserAllAccountsLazyQuery
>;
export type GetAdminUserAllAccountsQueryResult = Apollo.QueryResult<
  GetAdminUserAllAccountsQuery,
  GetAdminUserAllAccountsQueryVariables
>;
export const GetAdminAllAccountsDocument = gql`
  query GetAdminAllAccounts(
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
    $startDate: Time
    $endDate: Time
    $criteria: String = ""
    $allFilters: [UserWhereInput!]
    $accountFilter: UserAccountWhereInput
    $orderDirection: OrderDirection = DESC
  ) {
    response: users(
      first: $first
      after: $after
      before: $before
      last: $last
      where: {
        hasAccounts: true
        and: $allFilters
        createTimeGTE: $startDate
        createTimeLTE: $endDate
        hasProfileWith: {
          or: [{ firstNameContainsFold: $criteria }, { lastNameContainsFold: $criteria }]
        }
      }
      orderBy: { direction: $orderDirection, field: create_time }
    ) {
      edges {
        cursor
        node {
          id
          createTime
          accounts(where: $accountFilter) {
            edges {
              node {
                type
                status
                portfolios {
                  edges {
                    node {
                      id
                      totalValue
                      fiat {
                        total
                      }
                      security {
                        total
                      }
                    }
                  }
                }
              }
            }
          }
          profile {
            firstName
            lastName
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useGetAdminAllAccountsQuery__
 *
 * To run a query within a React component, call `useGetAdminAllAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminAllAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminAllAccountsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      criteria: // value for 'criteria'
 *      allFilters: // value for 'allFilters'
 *      accountFilter: // value for 'accountFilter'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useGetAdminAllAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAdminAllAccountsQuery,
    GetAdminAllAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdminAllAccountsQuery, GetAdminAllAccountsQueryVariables>(
    GetAdminAllAccountsDocument,
    options,
  );
}
export function useGetAdminAllAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdminAllAccountsQuery,
    GetAdminAllAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAdminAllAccountsQuery, GetAdminAllAccountsQueryVariables>(
    GetAdminAllAccountsDocument,
    options,
  );
}
export type GetAdminAllAccountsQueryHookResult = ReturnType<typeof useGetAdminAllAccountsQuery>;
export type GetAdminAllAccountsLazyQueryHookResult = ReturnType<
  typeof useGetAdminAllAccountsLazyQuery
>;
export type GetAdminAllAccountsQueryResult = Apollo.QueryResult<
  GetAdminAllAccountsQuery,
  GetAdminAllAccountsQueryVariables
>;
export const GetAdminUserDiscretionaryAccountDocument = gql`
  query GetAdminUserDiscretionaryAccount($id: ID!) {
    userAccounts(where: { type: ACCOUNT_TYPE_BROKERAGE_DISCRETIONARY, hasUserWith: { id: $id } }) {
      edges {
        node {
          id
          externalID
          type
          status
        }
      }
    }
  }
`;

/**
 * __useGetAdminUserDiscretionaryAccountQuery__
 *
 * To run a query within a React component, call `useGetAdminUserDiscretionaryAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminUserDiscretionaryAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminUserDiscretionaryAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdminUserDiscretionaryAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAdminUserDiscretionaryAccountQuery,
    GetAdminUserDiscretionaryAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAdminUserDiscretionaryAccountQuery,
    GetAdminUserDiscretionaryAccountQueryVariables
  >(GetAdminUserDiscretionaryAccountDocument, options);
}
export function useGetAdminUserDiscretionaryAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdminUserDiscretionaryAccountQuery,
    GetAdminUserDiscretionaryAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAdminUserDiscretionaryAccountQuery,
    GetAdminUserDiscretionaryAccountQueryVariables
  >(GetAdminUserDiscretionaryAccountDocument, options);
}
export type GetAdminUserDiscretionaryAccountQueryHookResult = ReturnType<
  typeof useGetAdminUserDiscretionaryAccountQuery
>;
export type GetAdminUserDiscretionaryAccountLazyQueryHookResult = ReturnType<
  typeof useGetAdminUserDiscretionaryAccountLazyQuery
>;
export type GetAdminUserDiscretionaryAccountQueryResult = Apollo.QueryResult<
  GetAdminUserDiscretionaryAccountQuery,
  GetAdminUserDiscretionaryAccountQueryVariables
>;
export const GetAdminUserStocksAccountDocument = gql`
  query GetAdminUserStocksAccount($id: ID!) {
    userAccounts(where: { type: ACCOUNT_TYPE_BROKERAGE, hasUserWith: { id: $id } }) {
      edges {
        node {
          id
          externalID
          type
          status
        }
      }
    }
  }
`;

/**
 * __useGetAdminUserStocksAccountQuery__
 *
 * To run a query within a React component, call `useGetAdminUserStocksAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminUserStocksAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminUserStocksAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdminUserStocksAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAdminUserStocksAccountQuery,
    GetAdminUserStocksAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdminUserStocksAccountQuery, GetAdminUserStocksAccountQueryVariables>(
    GetAdminUserStocksAccountDocument,
    options,
  );
}
export function useGetAdminUserStocksAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdminUserStocksAccountQuery,
    GetAdminUserStocksAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAdminUserStocksAccountQuery,
    GetAdminUserStocksAccountQueryVariables
  >(GetAdminUserStocksAccountDocument, options);
}
export type GetAdminUserStocksAccountQueryHookResult = ReturnType<
  typeof useGetAdminUserStocksAccountQuery
>;
export type GetAdminUserStocksAccountLazyQueryHookResult = ReturnType<
  typeof useGetAdminUserStocksAccountLazyQuery
>;
export type GetAdminUserStocksAccountQueryResult = Apollo.QueryResult<
  GetAdminUserStocksAccountQuery,
  GetAdminUserStocksAccountQueryVariables
>;
export const GetApexRejectReasonsDocument = gql`
  query GetApexRejectReasons($userId: ID!) {
    response: apexAccountRequests(
      where: { hasUserAccountWith: { hasUserWith: { id: $userId } } }
      orderBy: { direction: DESC, field: create_time }
    ) {
      edges {
        node {
          id
          requestID
          externalRequestID
          createTime
          userAccount {
            id
            user {
              id
              firstName
            }
          }
          apexAccountInvestigations {
            id
            createTime
            investigationStatus
            detailsBase64
            apexInvestigationFiles {
              externalID
              documentType
              userDocument {
                url
                name
                mimeType
                documentType
              }
            }
            apexAccountMeta {
              id
              holderType
              applicationStatus
            }
          }
          request
          response
        }
      }
    }
  }
`;

/**
 * __useGetApexRejectReasonsQuery__
 *
 * To run a query within a React component, call `useGetApexRejectReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApexRejectReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApexRejectReasonsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetApexRejectReasonsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetApexRejectReasonsQuery,
    GetApexRejectReasonsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetApexRejectReasonsQuery, GetApexRejectReasonsQueryVariables>(
    GetApexRejectReasonsDocument,
    options,
  );
}
export function useGetApexRejectReasonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApexRejectReasonsQuery,
    GetApexRejectReasonsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetApexRejectReasonsQuery, GetApexRejectReasonsQueryVariables>(
    GetApexRejectReasonsDocument,
    options,
  );
}
export type GetApexRejectReasonsQueryHookResult = ReturnType<typeof useGetApexRejectReasonsQuery>;
export type GetApexRejectReasonsLazyQueryHookResult = ReturnType<
  typeof useGetApexRejectReasonsLazyQuery
>;
export type GetApexRejectReasonsQueryResult = Apollo.QueryResult<
  GetApexRejectReasonsQuery,
  GetApexRejectReasonsQueryVariables
>;
export const GetApexClearingDetailsDocument = gql`
  query GetApexClearingDetails($id: ID!) {
    userAccounts(where: { id: $id }) {
      edges {
        node {
          id
          fpslEnrolled
          cashSweepEnrolled
          programEnrollmentHistory {
            id
            electionPreference
            approvalStatus
            programType
            reviewedAt
            reason
            submissionStatus
            submittedAt
            createTime
          }
        }
      }
    }
  }
`;

/**
 * __useGetApexClearingDetailsQuery__
 *
 * To run a query within a React component, call `useGetApexClearingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApexClearingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApexClearingDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetApexClearingDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetApexClearingDetailsQuery,
    GetApexClearingDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetApexClearingDetailsQuery, GetApexClearingDetailsQueryVariables>(
    GetApexClearingDetailsDocument,
    options,
  );
}
export function useGetApexClearingDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApexClearingDetailsQuery,
    GetApexClearingDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetApexClearingDetailsQuery, GetApexClearingDetailsQueryVariables>(
    GetApexClearingDetailsDocument,
    options,
  );
}
export type GetApexClearingDetailsQueryHookResult = ReturnType<
  typeof useGetApexClearingDetailsQuery
>;
export type GetApexClearingDetailsLazyQueryHookResult = ReturnType<
  typeof useGetApexClearingDetailsLazyQuery
>;
export type GetApexClearingDetailsQueryResult = Apollo.QueryResult<
  GetApexClearingDetailsQuery,
  GetApexClearingDetailsQueryVariables
>;
export const GetLiquidationDebtsDocument = gql`
  query GetLiquidationDebts(
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
    $criteria: String
    $startDate: Time
    $endDate: Time
    $allFilters: [LiabilityWhereInput!]
    $orderDirection: OrderDirection!
  ) {
    response: liabilities(
      first: $first
      after: $after
      before: $before
      last: $last
      where: {
        and: $allFilters
        owedSinceGT: $startDate
        owedSinceLT: $endDate
        hasAccountWith: {
          or: [
            { externalIDContainsFold: $criteria }
            {
              hasUserWith: {
                or: [{ firstNameContainsFold: $criteria }, { lastNameContainsFold: $criteria }]
              }
            }
          ]
        }
      }
      orderBy: { direction: $orderDirection, field: owedSince }
    ) {
      edges {
        cursor
        node {
          ...GetLiquidationDebtsFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
  ${GetLiquidationDebtsFragmentFragmentDoc}
`;

/**
 * __useGetLiquidationDebtsQuery__
 *
 * To run a query within a React component, call `useGetLiquidationDebtsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiquidationDebtsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiquidationDebtsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      criteria: // value for 'criteria'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      allFilters: // value for 'allFilters'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useGetLiquidationDebtsQuery(
  baseOptions: Apollo.QueryHookOptions<GetLiquidationDebtsQuery, GetLiquidationDebtsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLiquidationDebtsQuery, GetLiquidationDebtsQueryVariables>(
    GetLiquidationDebtsDocument,
    options,
  );
}
export function useGetLiquidationDebtsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLiquidationDebtsQuery,
    GetLiquidationDebtsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLiquidationDebtsQuery, GetLiquidationDebtsQueryVariables>(
    GetLiquidationDebtsDocument,
    options,
  );
}
export type GetLiquidationDebtsQueryHookResult = ReturnType<typeof useGetLiquidationDebtsQuery>;
export type GetLiquidationDebtsLazyQueryHookResult = ReturnType<
  typeof useGetLiquidationDebtsLazyQuery
>;
export type GetLiquidationDebtsQueryResult = Apollo.QueryResult<
  GetLiquidationDebtsQuery,
  GetLiquidationDebtsQueryVariables
>;
export const GetLiquidationDebtsByIdDocument = gql`
  query GetLiquidationDebtsById($id: ID) {
    liabilities(where: { id: $id }) {
      edges {
        node {
          holdings: account {
            portfolios {
              edges {
                ...GetUserAccountPortfolioEdgeFragment
              }
            }
          }
          ...GetLiquidationDebtsFragment
        }
      }
    }
  }
  ${GetUserAccountPortfolioEdgeFragmentFragmentDoc}
  ${GetLiquidationDebtsFragmentFragmentDoc}
`;

/**
 * __useGetLiquidationDebtsByIdQuery__
 *
 * To run a query within a React component, call `useGetLiquidationDebtsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiquidationDebtsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiquidationDebtsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLiquidationDebtsByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLiquidationDebtsByIdQuery,
    GetLiquidationDebtsByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLiquidationDebtsByIdQuery, GetLiquidationDebtsByIdQueryVariables>(
    GetLiquidationDebtsByIdDocument,
    options,
  );
}
export function useGetLiquidationDebtsByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLiquidationDebtsByIdQuery,
    GetLiquidationDebtsByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLiquidationDebtsByIdQuery, GetLiquidationDebtsByIdQueryVariables>(
    GetLiquidationDebtsByIdDocument,
    options,
  );
}
export type GetLiquidationDebtsByIdQueryHookResult = ReturnType<
  typeof useGetLiquidationDebtsByIdQuery
>;
export type GetLiquidationDebtsByIdLazyQueryHookResult = ReturnType<
  typeof useGetLiquidationDebtsByIdLazyQuery
>;
export type GetLiquidationDebtsByIdQueryResult = Apollo.QueryResult<
  GetLiquidationDebtsByIdQuery,
  GetLiquidationDebtsByIdQueryVariables
>;
export const GetAdminExternalAccountsDocument = gql`
  query GetAdminExternalAccounts(
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
    $startDate: Time
    $endDate: Time
    $criteria: String = ""
    $allFilters: [ExternalAccountWhereInput!]
  ) {
    response: externalAccounts(
      first: $first
      after: $after
      before: $before
      last: $last
      where: {
        and: $allFilters
        createTimeGTE: $startDate
        createTimeLTE: $endDate
        or: [
          { accountNumberContainsFold: $criteria }
          {
            hasUserWith: {
              or: [{ firstNameContainsFold: $criteria }, { lastNameContainsFold: $criteria }]
            }
          }
        ]
      }
    ) {
      edges {
        node {
          id
          user {
            firstName
            lastName
          }
          accountNumber
          connectionDetails {
            ... on ExternalAccountConnectionDetailsPlaid {
              bankOwnerName
            }
          }
          brokerageCanUse
          advisoryCanUse
          connectionStatus
          institution {
            id
            name
          }
          mask
          createTime
          updateTime
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useGetAdminExternalAccountsQuery__
 *
 * To run a query within a React component, call `useGetAdminExternalAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminExternalAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminExternalAccountsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      criteria: // value for 'criteria'
 *      allFilters: // value for 'allFilters'
 *   },
 * });
 */
export function useGetAdminExternalAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAdminExternalAccountsQuery,
    GetAdminExternalAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdminExternalAccountsQuery, GetAdminExternalAccountsQueryVariables>(
    GetAdminExternalAccountsDocument,
    options,
  );
}
export function useGetAdminExternalAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdminExternalAccountsQuery,
    GetAdminExternalAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAdminExternalAccountsQuery, GetAdminExternalAccountsQueryVariables>(
    GetAdminExternalAccountsDocument,
    options,
  );
}
export type GetAdminExternalAccountsQueryHookResult = ReturnType<
  typeof useGetAdminExternalAccountsQuery
>;
export type GetAdminExternalAccountsLazyQueryHookResult = ReturnType<
  typeof useGetAdminExternalAccountsLazyQuery
>;
export type GetAdminExternalAccountsQueryResult = Apollo.QueryResult<
  GetAdminExternalAccountsQuery,
  GetAdminExternalAccountsQueryVariables
>;
export const GetAdminFeeAndCreditHistoryDocument = gql`
  query GetAdminFeeAndCreditHistory(
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
    $orderDirection: OrderDirection = DESC
    $startDate: Time
    $endDate: Time
    $criteria: String = ""
    $allFilters: [FeeAndCreditOrderWhereInput!]
  ) {
    response: feeAndCreditOrders(
      first: $first
      after: $after
      before: $before
      last: $last
      where: {
        and: $allFilters
        createTimeGTE: $startDate
        createTimeLTE: $endDate
        hasPortfolioWith: {
          or: [
            { hasAccountWith: { externalIDContainsFold: $criteria } }
            {
              hasUserWith: {
                or: [{ firstNameContainsFold: $criteria }, { lastNameContainsFold: $criteria }]
              }
            }
          ]
        }
      }
      orderBy: { direction: $orderDirection, field: create_time }
    ) {
      edges {
        cursor
        node {
          id
          submissionID
          submission {
            externalID
          }
          type
          orderType
          amount
          status
          orderStatus
          createTime
          portfolio {
            user {
              id
              firstName
              lastName
            }
            account {
              externalID
              apexAccountMeta {
                externalRequestID
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useGetAdminFeeAndCreditHistoryQuery__
 *
 * To run a query within a React component, call `useGetAdminFeeAndCreditHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminFeeAndCreditHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminFeeAndCreditHistoryQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      orderDirection: // value for 'orderDirection'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      criteria: // value for 'criteria'
 *      allFilters: // value for 'allFilters'
 *   },
 * });
 */
export function useGetAdminFeeAndCreditHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAdminFeeAndCreditHistoryQuery,
    GetAdminFeeAndCreditHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAdminFeeAndCreditHistoryQuery,
    GetAdminFeeAndCreditHistoryQueryVariables
  >(GetAdminFeeAndCreditHistoryDocument, options);
}
export function useGetAdminFeeAndCreditHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdminFeeAndCreditHistoryQuery,
    GetAdminFeeAndCreditHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAdminFeeAndCreditHistoryQuery,
    GetAdminFeeAndCreditHistoryQueryVariables
  >(GetAdminFeeAndCreditHistoryDocument, options);
}
export type GetAdminFeeAndCreditHistoryQueryHookResult = ReturnType<
  typeof useGetAdminFeeAndCreditHistoryQuery
>;
export type GetAdminFeeAndCreditHistoryLazyQueryHookResult = ReturnType<
  typeof useGetAdminFeeAndCreditHistoryLazyQuery
>;
export type GetAdminFeeAndCreditHistoryQueryResult = Apollo.QueryResult<
  GetAdminFeeAndCreditHistoryQuery,
  GetAdminFeeAndCreditHistoryQueryVariables
>;
export const GetUserAllocationsByRoboIdDocument = gql`
  query GetUserAllocationsByRoboID($roboID: ID!) {
    roboGoals(where: { id: $roboID }) {
      edges {
        node {
          parameters {
            riskProfile
          }
          allocation {
            fiatPct
            stockPct
            bondPct
            totalValue
            drift
            allocationBySector {
              sector {
                id
                name
                category
                assets {
                  targetPct
                  asset {
                    id
                    symbol
                  }
                }
              }
              percentage
            }
          }
          targetPortfolio {
            bondPct
            fiatPct
            stockPct
            externalReferenceID
            id
            sectors {
              id
              targetPct
              sector {
                id
                name
                description
                category
                assets {
                  targetPct
                  asset {
                    id
                    symbol
                    name
                    ... on CryptoAsset {
                      imageLogo
                    }
                    ... on FiatAsset {
                      imageLogo
                    }
                    ... on SecurityAsset {
                      imageLogo
                    }
                  }
                }
              }
            }
          }
          accountPortfolio {
            totalValue
            id
            assetBalances {
              edges {
                node {
                  balances {
                    activeBalance
                    value
                    total
                  }
                  costBasis
                  asset {
                    id
                    name
                    symbol
                    ... on SecurityAsset {
                      market {
                        last
                        previousDayClose
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    roboGeneralInvestments(where: { id: $roboID }) {
      edges {
        node {
          allocation {
            fiatPct
            stockPct
            bondPct
            totalValue
            drift
            allocationBySector {
              sector {
                id
                name
                category
                assets {
                  targetPct
                  asset {
                    id
                    symbol
                  }
                }
              }
              percentage
            }
          }
          targetPortfolio {
            bondPct
            fiatPct
            stockPct
            externalReferenceID
            id
            sectors {
              id
              targetPct
              sector {
                id
                name
                description
                category
                assets {
                  targetPct
                  asset {
                    id
                    symbol
                    name
                    ... on CryptoAsset {
                      imageLogo
                    }
                    ... on FiatAsset {
                      imageLogo
                    }
                    ... on SecurityAsset {
                      imageLogo
                    }
                  }
                }
              }
            }
          }
          accountPortfolio {
            totalValue
            id
            assetBalances {
              edges {
                node {
                  balances {
                    activeBalance
                    value
                    total
                  }
                  costBasis
                  asset {
                    id
                    name
                    symbol
                    ... on SecurityAsset {
                      market {
                        last
                        previousDayClose
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetUserAllocationsByRoboIdQuery__
 *
 * To run a query within a React component, call `useGetUserAllocationsByRoboIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAllocationsByRoboIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAllocationsByRoboIdQuery({
 *   variables: {
 *      roboID: // value for 'roboID'
 *   },
 * });
 */
export function useGetUserAllocationsByRoboIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserAllocationsByRoboIdQuery,
    GetUserAllocationsByRoboIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserAllocationsByRoboIdQuery, GetUserAllocationsByRoboIdQueryVariables>(
    GetUserAllocationsByRoboIdDocument,
    options,
  );
}
export function useGetUserAllocationsByRoboIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserAllocationsByRoboIdQuery,
    GetUserAllocationsByRoboIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserAllocationsByRoboIdQuery,
    GetUserAllocationsByRoboIdQueryVariables
  >(GetUserAllocationsByRoboIdDocument, options);
}
export type GetUserAllocationsByRoboIdQueryHookResult = ReturnType<
  typeof useGetUserAllocationsByRoboIdQuery
>;
export type GetUserAllocationsByRoboIdLazyQueryHookResult = ReturnType<
  typeof useGetUserAllocationsByRoboIdLazyQuery
>;
export type GetUserAllocationsByRoboIdQueryResult = Apollo.QueryResult<
  GetUserAllocationsByRoboIdQuery,
  GetUserAllocationsByRoboIdQueryVariables
>;
export const GetUserStocksHoldingsDocument = gql`
  query GetUserStocksHoldings($portfolioID: ID!) {
    userAccountPortfolios(where: { id: $portfolioID }) {
      edges {
        node {
          id
          totalValue
          account {
            id
          }
          assetBalances(
            where: {
              hideZeroBalance: true
              hasAssetWith: { typeIn: [ASSET_TYPE_SECURITY, ASSET_TYPE_FIAT] }
            }
          ) {
            edges {
              node {
                balances {
                  activeBalance
                  value
                  total
                  tradeable
                  liability
                }
                costBasis
                asset {
                  id
                  type
                  name
                  symbol
                  ... on SecurityAsset {
                    name
                    imageLogo
                    securityType
                    market {
                      last
                      previousDayClose
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetUserStocksHoldingsQuery__
 *
 * To run a query within a React component, call `useGetUserStocksHoldingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserStocksHoldingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserStocksHoldingsQuery({
 *   variables: {
 *      portfolioID: // value for 'portfolioID'
 *   },
 * });
 */
export function useGetUserStocksHoldingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserStocksHoldingsQuery,
    GetUserStocksHoldingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserStocksHoldingsQuery, GetUserStocksHoldingsQueryVariables>(
    GetUserStocksHoldingsDocument,
    options,
  );
}
export function useGetUserStocksHoldingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserStocksHoldingsQuery,
    GetUserStocksHoldingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserStocksHoldingsQuery, GetUserStocksHoldingsQueryVariables>(
    GetUserStocksHoldingsDocument,
    options,
  );
}
export type GetUserStocksHoldingsQueryHookResult = ReturnType<typeof useGetUserStocksHoldingsQuery>;
export type GetUserStocksHoldingsLazyQueryHookResult = ReturnType<
  typeof useGetUserStocksHoldingsLazyQuery
>;
export type GetUserStocksHoldingsQueryResult = Apollo.QueryResult<
  GetUserStocksHoldingsQuery,
  GetUserStocksHoldingsQueryVariables
>;
export const GetAdminSecurityLiquidationsDocument = gql`
  query GetAdminSecurityLiquidations(
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
    $criteria: String
    $startDate: Time
    $endDate: Time
    $allFilters: [OrderWhereInput!]
    $orderDirection: OrderDirection!
  ) {
    response: orders(
      first: $first
      after: $after
      before: $before
      last: $last
      where: {
        and: $allFilters
        createTimeGT: $startDate
        createTimeLT: $endDate
        typeIn: [ORDER_TYPE_FEE_CREDIT, ORDER_TYPE_LIQUIDATION]
        hasPortfolioWith: {
          or: [
            { hasAccountWith: { externalIDContainsFold: $criteria } }
            {
              hasUserWith: {
                or: [{ firstNameContainsFold: $criteria }, { lastNameContainsFold: $criteria }]
              }
            }
          ]
        }
      }
      orderBy: { direction: $orderDirection, field: create_time }
    ) {
      edges {
        cursor
        node {
          ...GetSecurityLiquidationFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
  ${GetSecurityLiquidationFragmentFragmentDoc}
`;

/**
 * __useGetAdminSecurityLiquidationsQuery__
 *
 * To run a query within a React component, call `useGetAdminSecurityLiquidationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminSecurityLiquidationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminSecurityLiquidationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      criteria: // value for 'criteria'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      allFilters: // value for 'allFilters'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useGetAdminSecurityLiquidationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAdminSecurityLiquidationsQuery,
    GetAdminSecurityLiquidationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAdminSecurityLiquidationsQuery,
    GetAdminSecurityLiquidationsQueryVariables
  >(GetAdminSecurityLiquidationsDocument, options);
}
export function useGetAdminSecurityLiquidationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdminSecurityLiquidationsQuery,
    GetAdminSecurityLiquidationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAdminSecurityLiquidationsQuery,
    GetAdminSecurityLiquidationsQueryVariables
  >(GetAdminSecurityLiquidationsDocument, options);
}
export type GetAdminSecurityLiquidationsQueryHookResult = ReturnType<
  typeof useGetAdminSecurityLiquidationsQuery
>;
export type GetAdminSecurityLiquidationsLazyQueryHookResult = ReturnType<
  typeof useGetAdminSecurityLiquidationsLazyQuery
>;
export type GetAdminSecurityLiquidationsQueryResult = Apollo.QueryResult<
  GetAdminSecurityLiquidationsQuery,
  GetAdminSecurityLiquidationsQueryVariables
>;
export const GetAdminSecurityLiquidationByIdDocument = gql`
  query GetAdminSecurityLiquidationById($id: ID) {
    orders(where: { id: $id }) {
      edges {
        node {
          id
          ... on Liquidation {
            trades {
              id
              type
              createTime
              side
              status
              orderStatus
              baseAmount
              quoteAmount
              requestAmount
              requestInQuote
              fromLiquidation
              base {
                symbol
                name
              }
              quote {
                symbol
                name
              }
            }
          }
          ...GetSecurityLiquidationFragment
        }
      }
    }
  }
  ${GetSecurityLiquidationFragmentFragmentDoc}
`;

/**
 * __useGetAdminSecurityLiquidationByIdQuery__
 *
 * To run a query within a React component, call `useGetAdminSecurityLiquidationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminSecurityLiquidationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminSecurityLiquidationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdminSecurityLiquidationByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAdminSecurityLiquidationByIdQuery,
    GetAdminSecurityLiquidationByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAdminSecurityLiquidationByIdQuery,
    GetAdminSecurityLiquidationByIdQueryVariables
  >(GetAdminSecurityLiquidationByIdDocument, options);
}
export function useGetAdminSecurityLiquidationByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdminSecurityLiquidationByIdQuery,
    GetAdminSecurityLiquidationByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAdminSecurityLiquidationByIdQuery,
    GetAdminSecurityLiquidationByIdQueryVariables
  >(GetAdminSecurityLiquidationByIdDocument, options);
}
export type GetAdminSecurityLiquidationByIdQueryHookResult = ReturnType<
  typeof useGetAdminSecurityLiquidationByIdQuery
>;
export type GetAdminSecurityLiquidationByIdLazyQueryHookResult = ReturnType<
  typeof useGetAdminSecurityLiquidationByIdLazyQuery
>;
export type GetAdminSecurityLiquidationByIdQueryResult = Apollo.QueryResult<
  GetAdminSecurityLiquidationByIdQuery,
  GetAdminSecurityLiquidationByIdQueryVariables
>;
export const GetAdminRecurringRulesDocument = gql`
  query GetAdminRecurringRules(
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
    $criteria: String
    $startDate: Time
    $endDate: Time
    $allFilters: [RecurringRuleWhereInput!]
  ) {
    response: recurringRules(
      first: $first
      after: $after
      before: $before
      last: $last
      where: {
        hasUserWith: {
          hasProfileWith: {
            or: [{ firstNameContainsFold: $criteria }, { lastNameContainsFold: $criteria }]
          }
        }
        createTimeGTE: $startDate
        createTimeLTE: $endDate
        and: $allFilters
      }
      orderBy: { direction: DESC, field: create_time }
    ) {
      edges {
        node {
          id
          user {
            profile {
              firstName
              lastName
            }
          }
          amount
          type
          portfolio {
            account {
              type
            }
          }
          asset {
            symbol
          }
          interval
          isActive
          onHold
          createTime
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useGetAdminRecurringRulesQuery__
 *
 * To run a query within a React component, call `useGetAdminRecurringRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminRecurringRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminRecurringRulesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      criteria: // value for 'criteria'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      allFilters: // value for 'allFilters'
 *   },
 * });
 */
export function useGetAdminRecurringRulesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAdminRecurringRulesQuery,
    GetAdminRecurringRulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdminRecurringRulesQuery, GetAdminRecurringRulesQueryVariables>(
    GetAdminRecurringRulesDocument,
    options,
  );
}
export function useGetAdminRecurringRulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdminRecurringRulesQuery,
    GetAdminRecurringRulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAdminRecurringRulesQuery, GetAdminRecurringRulesQueryVariables>(
    GetAdminRecurringRulesDocument,
    options,
  );
}
export type GetAdminRecurringRulesQueryHookResult = ReturnType<
  typeof useGetAdminRecurringRulesQuery
>;
export type GetAdminRecurringRulesLazyQueryHookResult = ReturnType<
  typeof useGetAdminRecurringRulesLazyQuery
>;
export type GetAdminRecurringRulesQueryResult = Apollo.QueryResult<
  GetAdminRecurringRulesQuery,
  GetAdminRecurringRulesQueryVariables
>;
export const GetAdminRecurringRuleDetailByIdDocument = gql`
  query GetAdminRecurringRuleDetailById($id: ID!) {
    recurringRules(where: { id: $id }) {
      edges {
        node {
          id
          isActive
          user {
            id
            username
            profile {
              firstName
              lastName
            }
          }
          portfolio {
            account {
              externalID
              type
            }
            name
          }
          asset {
            symbol
          }
          instances {
            achTransfer {
              orderStatus
            }
            amount
            tradeDate
            transferDate
            orderStatus
            createTime
            trade {
              quoteAmount
              baseAmount
            }
            failureStage
          }
          requestor
          interval
          amount
          createTime
          start
          updateTime
          updates {
            updateTime
            requestor
            diff {
              field
              oldValue
              newValue
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetAdminRecurringRuleDetailByIdQuery__
 *
 * To run a query within a React component, call `useGetAdminRecurringRuleDetailByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminRecurringRuleDetailByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminRecurringRuleDetailByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdminRecurringRuleDetailByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAdminRecurringRuleDetailByIdQuery,
    GetAdminRecurringRuleDetailByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAdminRecurringRuleDetailByIdQuery,
    GetAdminRecurringRuleDetailByIdQueryVariables
  >(GetAdminRecurringRuleDetailByIdDocument, options);
}
export function useGetAdminRecurringRuleDetailByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdminRecurringRuleDetailByIdQuery,
    GetAdminRecurringRuleDetailByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAdminRecurringRuleDetailByIdQuery,
    GetAdminRecurringRuleDetailByIdQueryVariables
  >(GetAdminRecurringRuleDetailByIdDocument, options);
}
export type GetAdminRecurringRuleDetailByIdQueryHookResult = ReturnType<
  typeof useGetAdminRecurringRuleDetailByIdQuery
>;
export type GetAdminRecurringRuleDetailByIdLazyQueryHookResult = ReturnType<
  typeof useGetAdminRecurringRuleDetailByIdLazyQuery
>;
export type GetAdminRecurringRuleDetailByIdQueryResult = Apollo.QueryResult<
  GetAdminRecurringRuleDetailByIdQuery,
  GetAdminRecurringRuleDetailByIdQueryVariables
>;
export const GetAdminUserRoboGoalsDocument = gql`
  query GetAdminUserRoboGoals($id: ID) {
    roboGoals(where: { hasUserWith: { id: $id } }) {
      edges {
        node {
          ...AdminUserRoboGoalFragment
        }
      }
    }
  }
  ${AdminUserRoboGoalFragmentFragmentDoc}
`;

/**
 * __useGetAdminUserRoboGoalsQuery__
 *
 * To run a query within a React component, call `useGetAdminUserRoboGoalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminUserRoboGoalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminUserRoboGoalsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdminUserRoboGoalsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAdminUserRoboGoalsQuery,
    GetAdminUserRoboGoalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdminUserRoboGoalsQuery, GetAdminUserRoboGoalsQueryVariables>(
    GetAdminUserRoboGoalsDocument,
    options,
  );
}
export function useGetAdminUserRoboGoalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdminUserRoboGoalsQuery,
    GetAdminUserRoboGoalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAdminUserRoboGoalsQuery, GetAdminUserRoboGoalsQueryVariables>(
    GetAdminUserRoboGoalsDocument,
    options,
  );
}
export type GetAdminUserRoboGoalsQueryHookResult = ReturnType<typeof useGetAdminUserRoboGoalsQuery>;
export type GetAdminUserRoboGoalsLazyQueryHookResult = ReturnType<
  typeof useGetAdminUserRoboGoalsLazyQuery
>;
export type GetAdminUserRoboGoalsQueryResult = Apollo.QueryResult<
  GetAdminUserRoboGoalsQuery,
  GetAdminUserRoboGoalsQueryVariables
>;
export const GetAdminUserRoboGoalsByUserIdDocument = gql`
  query GetAdminUserRoboGoalsByUserId($id: ID) {
    users(where: { id: $id }) {
      edges {
        node {
          roboGoals {
            edges {
              node {
                accountPortfolio {
                  account {
                    id
                  }
                }
                id
                isActive
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetAdminUserRoboGoalsByUserIdQuery__
 *
 * To run a query within a React component, call `useGetAdminUserRoboGoalsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminUserRoboGoalsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminUserRoboGoalsByUserIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdminUserRoboGoalsByUserIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAdminUserRoboGoalsByUserIdQuery,
    GetAdminUserRoboGoalsByUserIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAdminUserRoboGoalsByUserIdQuery,
    GetAdminUserRoboGoalsByUserIdQueryVariables
  >(GetAdminUserRoboGoalsByUserIdDocument, options);
}
export function useGetAdminUserRoboGoalsByUserIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdminUserRoboGoalsByUserIdQuery,
    GetAdminUserRoboGoalsByUserIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAdminUserRoboGoalsByUserIdQuery,
    GetAdminUserRoboGoalsByUserIdQueryVariables
  >(GetAdminUserRoboGoalsByUserIdDocument, options);
}
export type GetAdminUserRoboGoalsByUserIdQueryHookResult = ReturnType<
  typeof useGetAdminUserRoboGoalsByUserIdQuery
>;
export type GetAdminUserRoboGoalsByUserIdLazyQueryHookResult = ReturnType<
  typeof useGetAdminUserRoboGoalsByUserIdLazyQuery
>;
export type GetAdminUserRoboGoalsByUserIdQueryResult = Apollo.QueryResult<
  GetAdminUserRoboGoalsByUserIdQuery,
  GetAdminUserRoboGoalsByUserIdQueryVariables
>;
export const GetAdminUserRoboGoalByIdDocument = gql`
  query GetAdminUserRoboGoalById($id: ID) {
    roboGoals(where: { id: $id }) {
      edges {
        node {
          ...AdminUserRoboGoalFragment
        }
      }
    }
  }
  ${AdminUserRoboGoalFragmentFragmentDoc}
`;

/**
 * __useGetAdminUserRoboGoalByIdQuery__
 *
 * To run a query within a React component, call `useGetAdminUserRoboGoalByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminUserRoboGoalByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminUserRoboGoalByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdminUserRoboGoalByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAdminUserRoboGoalByIdQuery,
    GetAdminUserRoboGoalByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdminUserRoboGoalByIdQuery, GetAdminUserRoboGoalByIdQueryVariables>(
    GetAdminUserRoboGoalByIdDocument,
    options,
  );
}
export function useGetAdminUserRoboGoalByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdminUserRoboGoalByIdQuery,
    GetAdminUserRoboGoalByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAdminUserRoboGoalByIdQuery, GetAdminUserRoboGoalByIdQueryVariables>(
    GetAdminUserRoboGoalByIdDocument,
    options,
  );
}
export type GetAdminUserRoboGoalByIdQueryHookResult = ReturnType<
  typeof useGetAdminUserRoboGoalByIdQuery
>;
export type GetAdminUserRoboGoalByIdLazyQueryHookResult = ReturnType<
  typeof useGetAdminUserRoboGoalByIdLazyQuery
>;
export type GetAdminUserRoboGoalByIdQueryResult = Apollo.QueryResult<
  GetAdminUserRoboGoalByIdQuery,
  GetAdminUserRoboGoalByIdQueryVariables
>;
export const GetAdminUserRoboGoalByGoalIdDocument = gql`
  query GetAdminUserRoboGoalByGoalId($id: ID) {
    roboGoals(where: { id: $id }) {
      edges {
        node {
          ...AdminUserRoboGoalFragment
        }
      }
    }
  }
  ${AdminUserRoboGoalFragmentFragmentDoc}
`;

/**
 * __useGetAdminUserRoboGoalByGoalIdQuery__
 *
 * To run a query within a React component, call `useGetAdminUserRoboGoalByGoalIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminUserRoboGoalByGoalIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminUserRoboGoalByGoalIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdminUserRoboGoalByGoalIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAdminUserRoboGoalByGoalIdQuery,
    GetAdminUserRoboGoalByGoalIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAdminUserRoboGoalByGoalIdQuery,
    GetAdminUserRoboGoalByGoalIdQueryVariables
  >(GetAdminUserRoboGoalByGoalIdDocument, options);
}
export function useGetAdminUserRoboGoalByGoalIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdminUserRoboGoalByGoalIdQuery,
    GetAdminUserRoboGoalByGoalIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAdminUserRoboGoalByGoalIdQuery,
    GetAdminUserRoboGoalByGoalIdQueryVariables
  >(GetAdminUserRoboGoalByGoalIdDocument, options);
}
export type GetAdminUserRoboGoalByGoalIdQueryHookResult = ReturnType<
  typeof useGetAdminUserRoboGoalByGoalIdQuery
>;
export type GetAdminUserRoboGoalByGoalIdLazyQueryHookResult = ReturnType<
  typeof useGetAdminUserRoboGoalByGoalIdLazyQuery
>;
export type GetAdminUserRoboGoalByGoalIdQueryResult = Apollo.QueryResult<
  GetAdminUserRoboGoalByGoalIdQuery,
  GetAdminUserRoboGoalByGoalIdQueryVariables
>;
export const GetAdminRoboAllocationCardDocument = gql`
  query GetAdminRoboAllocationCard($roboId: ID) {
    roboGoals(where: { id: $roboId }) {
      edges {
        node {
          id
          targetPortfolio {
            id
            externalReferenceID
          }
          allocation {
            stockPct
            bondPct
            fiatPct
            drift
          }
          parameters {
            maxDrift
          }
        }
      }
    }
    roboGeneralInvestments(where: { id: $roboId }) {
      edges {
        node {
          id
          targetPortfolio {
            id
            externalReferenceID
          }
          allocation {
            stockPct
            bondPct
            fiatPct
            drift
          }
        }
      }
    }
  }
`;

/**
 * __useGetAdminRoboAllocationCardQuery__
 *
 * To run a query within a React component, call `useGetAdminRoboAllocationCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminRoboAllocationCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminRoboAllocationCardQuery({
 *   variables: {
 *      roboId: // value for 'roboId'
 *   },
 * });
 */
export function useGetAdminRoboAllocationCardQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAdminRoboAllocationCardQuery,
    GetAdminRoboAllocationCardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdminRoboAllocationCardQuery, GetAdminRoboAllocationCardQueryVariables>(
    GetAdminRoboAllocationCardDocument,
    options,
  );
}
export function useGetAdminRoboAllocationCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdminRoboAllocationCardQuery,
    GetAdminRoboAllocationCardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAdminRoboAllocationCardQuery,
    GetAdminRoboAllocationCardQueryVariables
  >(GetAdminRoboAllocationCardDocument, options);
}
export type GetAdminRoboAllocationCardQueryHookResult = ReturnType<
  typeof useGetAdminRoboAllocationCardQuery
>;
export type GetAdminRoboAllocationCardLazyQueryHookResult = ReturnType<
  typeof useGetAdminRoboAllocationCardLazyQuery
>;
export type GetAdminRoboAllocationCardQueryResult = Apollo.QueryResult<
  GetAdminRoboAllocationCardQuery,
  GetAdminRoboAllocationCardQueryVariables
>;
export const GetAdminRoboAutomatedInvestmentsCardDocument = gql`
  query GetAdminRoboAutomatedInvestmentsCard($roboId: ID) {
    roboGoals(where: { id: $roboId }) {
      edges {
        node {
          id
          isAutoInfusionPaused
          parameters {
            endDate
            targetAmount
            infusionAmount
            infusionFreq
            riskProfile
            goalPriority
          }
          user {
            id
          }
        }
      }
    }
    roboGeneralInvestments(where: { id: $roboId }) {
      edges {
        node {
          id
          isAutoInfusionPaused
          recurringInfusion
          infusionFrequency
          user {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useGetAdminRoboAutomatedInvestmentsCardQuery__
 *
 * To run a query within a React component, call `useGetAdminRoboAutomatedInvestmentsCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminRoboAutomatedInvestmentsCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminRoboAutomatedInvestmentsCardQuery({
 *   variables: {
 *      roboId: // value for 'roboId'
 *   },
 * });
 */
export function useGetAdminRoboAutomatedInvestmentsCardQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAdminRoboAutomatedInvestmentsCardQuery,
    GetAdminRoboAutomatedInvestmentsCardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAdminRoboAutomatedInvestmentsCardQuery,
    GetAdminRoboAutomatedInvestmentsCardQueryVariables
  >(GetAdminRoboAutomatedInvestmentsCardDocument, options);
}
export function useGetAdminRoboAutomatedInvestmentsCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdminRoboAutomatedInvestmentsCardQuery,
    GetAdminRoboAutomatedInvestmentsCardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAdminRoboAutomatedInvestmentsCardQuery,
    GetAdminRoboAutomatedInvestmentsCardQueryVariables
  >(GetAdminRoboAutomatedInvestmentsCardDocument, options);
}
export type GetAdminRoboAutomatedInvestmentsCardQueryHookResult = ReturnType<
  typeof useGetAdminRoboAutomatedInvestmentsCardQuery
>;
export type GetAdminRoboAutomatedInvestmentsCardLazyQueryHookResult = ReturnType<
  typeof useGetAdminRoboAutomatedInvestmentsCardLazyQuery
>;
export type GetAdminRoboAutomatedInvestmentsCardQueryResult = Apollo.QueryResult<
  GetAdminRoboAutomatedInvestmentsCardQuery,
  GetAdminRoboAutomatedInvestmentsCardQueryVariables
>;
export const GetAdminGoalsByAccountIdDocument = gql`
  query GetAdminGoalsByAccountId($id: ID) {
    roboGoals(where: { hasAccountPortfolioWith: { hasAccountWith: { id: $id } } }) {
      edges {
        node {
          id
          name
          accountPortfolioID
        }
      }
    }
  }
`;

/**
 * __useGetAdminGoalsByAccountIdQuery__
 *
 * To run a query within a React component, call `useGetAdminGoalsByAccountIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminGoalsByAccountIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminGoalsByAccountIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdminGoalsByAccountIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAdminGoalsByAccountIdQuery,
    GetAdminGoalsByAccountIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdminGoalsByAccountIdQuery, GetAdminGoalsByAccountIdQueryVariables>(
    GetAdminGoalsByAccountIdDocument,
    options,
  );
}
export function useGetAdminGoalsByAccountIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdminGoalsByAccountIdQuery,
    GetAdminGoalsByAccountIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAdminGoalsByAccountIdQuery, GetAdminGoalsByAccountIdQueryVariables>(
    GetAdminGoalsByAccountIdDocument,
    options,
  );
}
export type GetAdminGoalsByAccountIdQueryHookResult = ReturnType<
  typeof useGetAdminGoalsByAccountIdQuery
>;
export type GetAdminGoalsByAccountIdLazyQueryHookResult = ReturnType<
  typeof useGetAdminGoalsByAccountIdLazyQuery
>;
export type GetAdminGoalsByAccountIdQueryResult = Apollo.QueryResult<
  GetAdminGoalsByAccountIdQuery,
  GetAdminGoalsByAccountIdQueryVariables
>;
export const GetAdminRoboDriftPopoutDocument = gql`
  query GetAdminRoboDriftPopout($id: ID) {
    roboGoals(where: { id: $id }) {
      edges {
        node {
          id
          allocation {
            drift
          }
          parameters {
            maxDrift
          }
        }
      }
    }
    roboGeneralInvestments(where: { id: $id }) {
      edges {
        node {
          id
          allocation {
            drift
          }
        }
      }
    }
  }
`;

/**
 * __useGetAdminRoboDriftPopoutQuery__
 *
 * To run a query within a React component, call `useGetAdminRoboDriftPopoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminRoboDriftPopoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminRoboDriftPopoutQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdminRoboDriftPopoutQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAdminRoboDriftPopoutQuery,
    GetAdminRoboDriftPopoutQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdminRoboDriftPopoutQuery, GetAdminRoboDriftPopoutQueryVariables>(
    GetAdminRoboDriftPopoutDocument,
    options,
  );
}
export function useGetAdminRoboDriftPopoutLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdminRoboDriftPopoutQuery,
    GetAdminRoboDriftPopoutQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAdminRoboDriftPopoutQuery, GetAdminRoboDriftPopoutQueryVariables>(
    GetAdminRoboDriftPopoutDocument,
    options,
  );
}
export type GetAdminRoboDriftPopoutQueryHookResult = ReturnType<
  typeof useGetAdminRoboDriftPopoutQuery
>;
export type GetAdminRoboDriftPopoutLazyQueryHookResult = ReturnType<
  typeof useGetAdminRoboDriftPopoutLazyQuery
>;
export type GetAdminRoboDriftPopoutQueryResult = Apollo.QueryResult<
  GetAdminRoboDriftPopoutQuery,
  GetAdminRoboDriftPopoutQueryVariables
>;
export const GetAdminTradesDocument = gql`
  query GetAdminTrades(
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
    $allFilters: [TradeWhereInput!]
    $criteria: String
    $startDate: Time
    $endDate: Time
    $statusIn: [TradeTradeStatus!]
    $orderDirection: OrderDirection!
    $orderfield: TradeOrderField!
  ) {
    response: trades(
      first: $first
      after: $after
      before: $before
      last: $last
      where: {
        and: $allFilters
        unsettledTimeGTE: $startDate
        unsettledTimeLTE: $endDate
        statusIn: $statusIn
        hasUserWith: {
          hasProfileWith: {
            or: [{ firstNameContainsFold: $criteria }, { lastNameContainsFold: $criteria }]
          }
        }
      }
      orderBy: { direction: $orderDirection, field: $orderfield }
    ) {
      edges {
        cursor
        node {
          id
          portfolio {
            account {
              type
            }
          }
          user {
            id
            profile {
              firstName
              lastName
            }
          }
          status
          orderStatus
          base {
            symbol
            ... on SecurityAsset {
              market {
                last
              }
            }
          }
          side
          type
          requestInQuote
          requestAmount
          baseAmount
          quoteAmount
          completedAt: statusChangeLog(status: TRADE_STATUS_UNSETTLED) {
            eventTime
          }
          createdAt: statusChangeLog(status: TRADE_STATUS_NEW) {
            eventTime
          }
          createTime
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useGetAdminTradesQuery__
 *
 * To run a query within a React component, call `useGetAdminTradesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminTradesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminTradesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      allFilters: // value for 'allFilters'
 *      criteria: // value for 'criteria'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      statusIn: // value for 'statusIn'
 *      orderDirection: // value for 'orderDirection'
 *      orderfield: // value for 'orderfield'
 *   },
 * });
 */
export function useGetAdminTradesQuery(
  baseOptions: Apollo.QueryHookOptions<GetAdminTradesQuery, GetAdminTradesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdminTradesQuery, GetAdminTradesQueryVariables>(
    GetAdminTradesDocument,
    options,
  );
}
export function useGetAdminTradesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAdminTradesQuery, GetAdminTradesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAdminTradesQuery, GetAdminTradesQueryVariables>(
    GetAdminTradesDocument,
    options,
  );
}
export type GetAdminTradesQueryHookResult = ReturnType<typeof useGetAdminTradesQuery>;
export type GetAdminTradesLazyQueryHookResult = ReturnType<typeof useGetAdminTradesLazyQuery>;
export type GetAdminTradesQueryResult = Apollo.QueryResult<
  GetAdminTradesQuery,
  GetAdminTradesQueryVariables
>;
export const GetTradeByIdDocument = gql`
  query GetTradeById($id: ID!) {
    response: trades(where: { id: $id }) {
      edges {
        node {
          id
          portfolio {
            account {
              type
              externalID
            }
            user {
              username
              profile {
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetTradeByIdQuery__
 *
 * To run a query within a React component, call `useGetTradeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTradeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTradeByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTradeByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetTradeByIdQuery, GetTradeByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTradeByIdQuery, GetTradeByIdQueryVariables>(
    GetTradeByIdDocument,
    options,
  );
}
export function useGetTradeByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTradeByIdQuery, GetTradeByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTradeByIdQuery, GetTradeByIdQueryVariables>(
    GetTradeByIdDocument,
    options,
  );
}
export type GetTradeByIdQueryHookResult = ReturnType<typeof useGetTradeByIdQuery>;
export type GetTradeByIdLazyQueryHookResult = ReturnType<typeof useGetTradeByIdLazyQuery>;
export type GetTradeByIdQueryResult = Apollo.QueryResult<
  GetTradeByIdQuery,
  GetTradeByIdQueryVariables
>;
export const GetAdminTradeDocument = gql`
  query GetAdminTrade($tradeId: ID) {
    trades(where: { id: $tradeId }) {
      edges {
        cursor
        node {
          ...AdminTradeFragment
        }
      }
    }
  }
  ${AdminTradeFragmentFragmentDoc}
`;

/**
 * __useGetAdminTradeQuery__
 *
 * To run a query within a React component, call `useGetAdminTradeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminTradeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminTradeQuery({
 *   variables: {
 *      tradeId: // value for 'tradeId'
 *   },
 * });
 */
export function useGetAdminTradeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAdminTradeQuery, GetAdminTradeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdminTradeQuery, GetAdminTradeQueryVariables>(
    GetAdminTradeDocument,
    options,
  );
}
export function useGetAdminTradeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAdminTradeQuery, GetAdminTradeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAdminTradeQuery, GetAdminTradeQueryVariables>(
    GetAdminTradeDocument,
    options,
  );
}
export type GetAdminTradeQueryHookResult = ReturnType<typeof useGetAdminTradeQuery>;
export type GetAdminTradeLazyQueryHookResult = ReturnType<typeof useGetAdminTradeLazyQuery>;
export type GetAdminTradeQueryResult = Apollo.QueryResult<
  GetAdminTradeQuery,
  GetAdminTradeQueryVariables
>;
export const GetTrustedContactRequestsDocument = gql`
  query GetTrustedContactRequests(
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
    $startDate: Time
    $endDate: Time
    $criteria: String = ""
    $allFilters: [TrustedContactUpdateRequestWhereInput!]
    $orderDirection: OrderDirection = DESC
    $orderField: TrustedContactUpdateRequestOrderField!
    $statusIn: [TrustedContactUpdateRequestUpdateRequestStatus!]
  ) {
    response: trustedContactUpdateRequests(
      first: $first
      after: $after
      before: $before
      last: $last
      where: {
        and: $allFilters
        createTimeGTE: $startDate
        createTimeLTE: $endDate
        statusIn: $statusIn
        or: [
          {
            hasDiscretionaryUpdateRequestWith: {
              hasDiscretionaryAccountKycWith: {
                hasAccountWith: {
                  hasUserWith: {
                    or: [{ firstNameContainsFold: $criteria }, { lastNameContainsFold: $criteria }]
                  }
                }
              }
            }
          }
          {
            hasNonDiscretionaryUpdateRequestWith: {
              hasNonDiscretionaryAccountKycWith: {
                hasAccountWith: {
                  hasUserWith: {
                    or: [{ firstNameContainsFold: $criteria }, { lastNameContainsFold: $criteria }]
                  }
                }
              }
            }
          }
        ]
      }
      orderBy: { direction: $orderDirection, field: $orderField }
    ) {
      edges {
        cursor
        node {
          id
          createTime
          updateTime
          status
          requestDetails {
            admin {
              firstName
              lastName
            }
          }
          nonDiscretionaryUpdateRequest {
            nonDiscretionaryAccountKyc {
              account {
                user {
                  id
                  firstName
                  lastName
                }
                externalID
              }
            }
          }
          discretionaryUpdateRequest {
            discretionaryAccountKyc {
              account {
                user {
                  id
                  firstName
                  lastName
                }
                externalID
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useGetTrustedContactRequestsQuery__
 *
 * To run a query within a React component, call `useGetTrustedContactRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrustedContactRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrustedContactRequestsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      criteria: // value for 'criteria'
 *      allFilters: // value for 'allFilters'
 *      orderDirection: // value for 'orderDirection'
 *      orderField: // value for 'orderField'
 *      statusIn: // value for 'statusIn'
 *   },
 * });
 */
export function useGetTrustedContactRequestsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTrustedContactRequestsQuery,
    GetTrustedContactRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTrustedContactRequestsQuery, GetTrustedContactRequestsQueryVariables>(
    GetTrustedContactRequestsDocument,
    options,
  );
}
export function useGetTrustedContactRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTrustedContactRequestsQuery,
    GetTrustedContactRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTrustedContactRequestsQuery,
    GetTrustedContactRequestsQueryVariables
  >(GetTrustedContactRequestsDocument, options);
}
export type GetTrustedContactRequestsQueryHookResult = ReturnType<
  typeof useGetTrustedContactRequestsQuery
>;
export type GetTrustedContactRequestsLazyQueryHookResult = ReturnType<
  typeof useGetTrustedContactRequestsLazyQuery
>;
export type GetTrustedContactRequestsQueryResult = Apollo.QueryResult<
  GetTrustedContactRequestsQuery,
  GetTrustedContactRequestsQueryVariables
>;
export const GetTrustedContactRequestsCountDocument = gql`
  query GetTrustedContactRequestsCount {
    new: trustedContactUpdateRequests(where: { statusIn: [UPDATE_REQUEST_STATUS_PENDING] }) {
      totalCount
    }
    completed: trustedContactUpdateRequests(where: { statusIn: [UPDATE_REQUEST_STATUS_APPROVED] }) {
      totalCount
    }
    contacted: trustedContactUpdateRequests(
      where: { statusIn: [UPDATE_REQUEST_STATUS_CONTACATED] }
    ) {
      totalCount
    }
  }
`;

/**
 * __useGetTrustedContactRequestsCountQuery__
 *
 * To run a query within a React component, call `useGetTrustedContactRequestsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrustedContactRequestsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrustedContactRequestsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTrustedContactRequestsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTrustedContactRequestsCountQuery,
    GetTrustedContactRequestsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTrustedContactRequestsCountQuery,
    GetTrustedContactRequestsCountQueryVariables
  >(GetTrustedContactRequestsCountDocument, options);
}
export function useGetTrustedContactRequestsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTrustedContactRequestsCountQuery,
    GetTrustedContactRequestsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTrustedContactRequestsCountQuery,
    GetTrustedContactRequestsCountQueryVariables
  >(GetTrustedContactRequestsCountDocument, options);
}
export type GetTrustedContactRequestsCountQueryHookResult = ReturnType<
  typeof useGetTrustedContactRequestsCountQuery
>;
export type GetTrustedContactRequestsCountLazyQueryHookResult = ReturnType<
  typeof useGetTrustedContactRequestsCountLazyQuery
>;
export type GetTrustedContactRequestsCountQueryResult = Apollo.QueryResult<
  GetTrustedContactRequestsCountQuery,
  GetTrustedContactRequestsCountQueryVariables
>;
export const GetAdminAccountsDocument = gql`
  query GetAdminAccounts(
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
    $status: [UserAccountAccountStatus!]
    $criteria: String = ""
    $startDate: Time
    $endDate: Time
    $allFilters: [UserAccountWhereInput!]
  ) {
    response: userAccounts(
      first: $first
      after: $after
      before: $before
      last: $last
      where: {
        statusIn: $status
        hasUserWith: {
          hasProfileWith: {
            or: [{ firstNameContainsFold: $criteria }, { lastNameContainsFold: $criteria }]
          }
        }
        createTimeGTE: $startDate
        createTimeLTE: $endDate
        and: $allFilters
      }
      orderBy: { direction: DESC, field: create_time }
    ) {
      edges {
        cursor
        node {
          ...CommonUserAccountDetailsFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
      totalCount
    }
    userIds: userAccounts(
      where: {
        statusIn: $status
        hasUserWith: {
          hasProfileWith: {
            or: [{ firstNameContainsFold: $criteria }, { lastNameContainsFold: $criteria }]
          }
        }
        createTimeGTE: $startDate
        createTimeLTE: $endDate
      }
    ) {
      edges {
        node {
          user {
            id
          }
        }
      }
    }
  }
  ${CommonUserAccountDetailsFragmentFragmentDoc}
`;

/**
 * __useGetAdminAccountsQuery__
 *
 * To run a query within a React component, call `useGetAdminAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminAccountsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      status: // value for 'status'
 *      criteria: // value for 'criteria'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      allFilters: // value for 'allFilters'
 *   },
 * });
 */
export function useGetAdminAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAdminAccountsQuery, GetAdminAccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdminAccountsQuery, GetAdminAccountsQueryVariables>(
    GetAdminAccountsDocument,
    options,
  );
}
export function useGetAdminAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAdminAccountsQuery, GetAdminAccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAdminAccountsQuery, GetAdminAccountsQueryVariables>(
    GetAdminAccountsDocument,
    options,
  );
}
export type GetAdminAccountsQueryHookResult = ReturnType<typeof useGetAdminAccountsQuery>;
export type GetAdminAccountsLazyQueryHookResult = ReturnType<typeof useGetAdminAccountsLazyQuery>;
export type GetAdminAccountsQueryResult = Apollo.QueryResult<
  GetAdminAccountsQuery,
  GetAdminAccountsQueryVariables
>;
export const GetAdminAccountByIdDocument = gql`
  query GetAdminAccountById($id: ID) {
    userAccounts(where: { id: $id }) {
      edges {
        cursor
        node {
          ...CommonUserAccountDetailsFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
    }
  }
  ${CommonUserAccountDetailsFragmentFragmentDoc}
`;

/**
 * __useGetAdminAccountByIdQuery__
 *
 * To run a query within a React component, call `useGetAdminAccountByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminAccountByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminAccountByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdminAccountByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAdminAccountByIdQuery,
    GetAdminAccountByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdminAccountByIdQuery, GetAdminAccountByIdQueryVariables>(
    GetAdminAccountByIdDocument,
    options,
  );
}
export function useGetAdminAccountByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdminAccountByIdQuery,
    GetAdminAccountByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAdminAccountByIdQuery, GetAdminAccountByIdQueryVariables>(
    GetAdminAccountByIdDocument,
    options,
  );
}
export type GetAdminAccountByIdQueryHookResult = ReturnType<typeof useGetAdminAccountByIdQuery>;
export type GetAdminAccountByIdLazyQueryHookResult = ReturnType<
  typeof useGetAdminAccountByIdLazyQuery
>;
export type GetAdminAccountByIdQueryResult = Apollo.QueryResult<
  GetAdminAccountByIdQuery,
  GetAdminAccountByIdQueryVariables
>;
export const GetAdminUserProfileDocument = gql`
  query GetAdminUserProfile(
    $id: ID
    $first: Int
    $after: Cursor
    $before: Cursor
    $last: Int
    $accountFilter: UserAccountWhereInput
  ) {
    users(first: $first, after: $after, last: $last, before: $before, where: { id: $id }) {
      edges {
        cursor
        node {
          id
          username
          accounts(where: $accountFilter) {
            edges {
              node {
                type
                externalID
                status
                programEnrollmentHistory {
                  id
                  electionPreference
                  approvalStatus
                  programType
                  reviewedAt
                  reason
                  submissionStatus
                  submittedAt
                  createTime
                }
              }
            }
          }
          profile {
            ...CommonUserProfileDetails
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${CommonUserProfileDetailsFragmentDoc}
`;

/**
 * __useGetAdminUserProfileQuery__
 *
 * To run a query within a React component, call `useGetAdminUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminUserProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      accountFilter: // value for 'accountFilter'
 *   },
 * });
 */
export function useGetAdminUserProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAdminUserProfileQuery,
    GetAdminUserProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdminUserProfileQuery, GetAdminUserProfileQueryVariables>(
    GetAdminUserProfileDocument,
    options,
  );
}
export function useGetAdminUserProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdminUserProfileQuery,
    GetAdminUserProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAdminUserProfileQuery, GetAdminUserProfileQueryVariables>(
    GetAdminUserProfileDocument,
    options,
  );
}
export type GetAdminUserProfileQueryHookResult = ReturnType<typeof useGetAdminUserProfileQuery>;
export type GetAdminUserProfileLazyQueryHookResult = ReturnType<
  typeof useGetAdminUserProfileLazyQuery
>;
export type GetAdminUserProfileQueryResult = Apollo.QueryResult<
  GetAdminUserProfileQuery,
  GetAdminUserProfileQueryVariables
>;
export const GetAdminUserKycsDocument = gql`
  query GetAdminUserKycs($id: ID, $discretionaryEnabled: Boolean = true) {
    users(where: { id: $id }) {
      edges {
        node {
          accounts {
            edges {
              node {
                ...GetAllUserAccountsStatusFragment
                trustedContacts {
                  ...TrustedContactDetails
                }
              }
            }
          }
          profile {
            ssn
            dateOfBirth
            controlPersonDetails {
              companySymbols
              isControlPerson
            }
            affiliatedExchangeOrFinraDetails {
              firmName
              isAffiliated
            }
            politicallyExposedDetails {
              organizationName
              familyMembers
              isPoliticallyExposed
            }
            marketDataAnswers {
              advisorOrConsultant
              investForOther
              personalUse
              registeredWithSecOrCftc
              requiresSecOrCftcRegistration
              investForTheBenefitOfOther
              gettingDataFromExternalSource
              sharesProfit
              registeredWithEntity
            }
          }
        }
      }
    }
    nonDiscretionaryAccountKycs(where: { hasAccountWith: { hasUserWith: { id: $id } } }) {
      edges {
        node {
          ...CommonUserInvestorInformationNonDiscretionaryDetails
        }
      }
    }
    discretionaryAccountKycs(where: { hasAccountWith: { hasUserWith: { id: $id } } })
      @include(if: $discretionaryEnabled) {
      edges {
        node {
          employmentStatus
          employmentDetails {
            EmployerName
            Position
          }
          annualIncome
          netWorth
          investmentObjective
          riskTolerance
        }
      }
    }
  }
  ${GetAllUserAccountsStatusFragmentFragmentDoc}
  ${TrustedContactDetailsFragmentDoc}
  ${CommonUserInvestorInformationNonDiscretionaryDetailsFragmentDoc}
`;

/**
 * __useGetAdminUserKycsQuery__
 *
 * To run a query within a React component, call `useGetAdminUserKycsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminUserKycsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminUserKycsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      discretionaryEnabled: // value for 'discretionaryEnabled'
 *   },
 * });
 */
export function useGetAdminUserKycsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAdminUserKycsQuery, GetAdminUserKycsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdminUserKycsQuery, GetAdminUserKycsQueryVariables>(
    GetAdminUserKycsDocument,
    options,
  );
}
export function useGetAdminUserKycsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAdminUserKycsQuery, GetAdminUserKycsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAdminUserKycsQuery, GetAdminUserKycsQueryVariables>(
    GetAdminUserKycsDocument,
    options,
  );
}
export type GetAdminUserKycsQueryHookResult = ReturnType<typeof useGetAdminUserKycsQuery>;
export type GetAdminUserKycsLazyQueryHookResult = ReturnType<typeof useGetAdminUserKycsLazyQuery>;
export type GetAdminUserKycsQueryResult = Apollo.QueryResult<
  GetAdminUserKycsQuery,
  GetAdminUserKycsQueryVariables
>;
export const GetUserDocumentsDocument = gql`
  query getUserDocuments($id: ID) {
    userDocuments(where: { id: $id }) {
      edges {
        node {
          id
          requestor
          requestUUID
          name
          path
          mimeType
          url
          documentType
        }
      }
    }
  }
`;

/**
 * __useGetUserDocumentsQuery__
 *
 * To run a query within a React component, call `useGetUserDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDocumentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserDocumentsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserDocumentsQuery, GetUserDocumentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserDocumentsQuery, GetUserDocumentsQueryVariables>(
    GetUserDocumentsDocument,
    options,
  );
}
export function useGetUserDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserDocumentsQuery, GetUserDocumentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserDocumentsQuery, GetUserDocumentsQueryVariables>(
    GetUserDocumentsDocument,
    options,
  );
}
export type GetUserDocumentsQueryHookResult = ReturnType<typeof useGetUserDocumentsQuery>;
export type GetUserDocumentsLazyQueryHookResult = ReturnType<typeof useGetUserDocumentsLazyQuery>;
export type GetUserDocumentsQueryResult = Apollo.QueryResult<
  GetUserDocumentsQuery,
  GetUserDocumentsQueryVariables
>;
export const GetUserDocumentSetsDocument = gql`
  query getUserDocumentSets($id: ID) {
    userDocumentSets(where: { isactive: true, hasUserWith: { id: $id } }) {
      edges {
        node {
          id
          isactive
          documentType
          document {
            id
            requestor
            requestUUID
            name
            path
            mimeType
            url
            documentType
          }
        }
      }
    }
  }
`;

/**
 * __useGetUserDocumentSetsQuery__
 *
 * To run a query within a React component, call `useGetUserDocumentSetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDocumentSetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDocumentSetsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserDocumentSetsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserDocumentSetsQuery,
    GetUserDocumentSetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserDocumentSetsQuery, GetUserDocumentSetsQueryVariables>(
    GetUserDocumentSetsDocument,
    options,
  );
}
export function useGetUserDocumentSetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserDocumentSetsQuery,
    GetUserDocumentSetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserDocumentSetsQuery, GetUserDocumentSetsQueryVariables>(
    GetUserDocumentSetsDocument,
    options,
  );
}
export type GetUserDocumentSetsQueryHookResult = ReturnType<typeof useGetUserDocumentSetsQuery>;
export type GetUserDocumentSetsLazyQueryHookResult = ReturnType<
  typeof useGetUserDocumentSetsLazyQuery
>;
export type GetUserDocumentSetsQueryResult = Apollo.QueryResult<
  GetUserDocumentSetsQuery,
  GetUserDocumentSetsQueryVariables
>;
export const GetUserDocumentSetsByDocumentTypeDocument = gql`
  query getUserDocumentSetsByDocumentType($id: ID!, $documentType: UserDocumentUserDocumentType) {
    userDocumentSets(
      where: {
        hasDocumentWith: { documentType: $documentType }
        isactive: true
        hasUserWith: { id: $id }
      }
    ) {
      edges {
        node {
          id
          isactive
          documentType
          document {
            id
            requestor
            requestUUID
            name
            path
            mimeType
            url
            documentType
          }
        }
      }
    }
  }
`;

/**
 * __useGetUserDocumentSetsByDocumentTypeQuery__
 *
 * To run a query within a React component, call `useGetUserDocumentSetsByDocumentTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDocumentSetsByDocumentTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDocumentSetsByDocumentTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      documentType: // value for 'documentType'
 *   },
 * });
 */
export function useGetUserDocumentSetsByDocumentTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserDocumentSetsByDocumentTypeQuery,
    GetUserDocumentSetsByDocumentTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserDocumentSetsByDocumentTypeQuery,
    GetUserDocumentSetsByDocumentTypeQueryVariables
  >(GetUserDocumentSetsByDocumentTypeDocument, options);
}
export function useGetUserDocumentSetsByDocumentTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserDocumentSetsByDocumentTypeQuery,
    GetUserDocumentSetsByDocumentTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserDocumentSetsByDocumentTypeQuery,
    GetUserDocumentSetsByDocumentTypeQueryVariables
  >(GetUserDocumentSetsByDocumentTypeDocument, options);
}
export type GetUserDocumentSetsByDocumentTypeQueryHookResult = ReturnType<
  typeof useGetUserDocumentSetsByDocumentTypeQuery
>;
export type GetUserDocumentSetsByDocumentTypeLazyQueryHookResult = ReturnType<
  typeof useGetUserDocumentSetsByDocumentTypeLazyQuery
>;
export type GetUserDocumentSetsByDocumentTypeQueryResult = Apollo.QueryResult<
  GetUserDocumentSetsByDocumentTypeQuery,
  GetUserDocumentSetsByDocumentTypeQueryVariables
>;
export const GetAdminGiPortfoliosByUserIdDocument = gql`
  query GetAdminGIPortfoliosByUserId($userId: ID!) {
    roboGeneralInvestments(where: { hasUserWith: { id: $userId } }) {
      edges {
        node {
          id
          targetPortfolioID
          accountPortfolioID
          createTime
        }
      }
    }
  }
`;

/**
 * __useGetAdminGiPortfoliosByUserIdQuery__
 *
 * To run a query within a React component, call `useGetAdminGiPortfoliosByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminGiPortfoliosByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminGiPortfoliosByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAdminGiPortfoliosByUserIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAdminGiPortfoliosByUserIdQuery,
    GetAdminGiPortfoliosByUserIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAdminGiPortfoliosByUserIdQuery,
    GetAdminGiPortfoliosByUserIdQueryVariables
  >(GetAdminGiPortfoliosByUserIdDocument, options);
}
export function useGetAdminGiPortfoliosByUserIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdminGiPortfoliosByUserIdQuery,
    GetAdminGiPortfoliosByUserIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAdminGiPortfoliosByUserIdQuery,
    GetAdminGiPortfoliosByUserIdQueryVariables
  >(GetAdminGiPortfoliosByUserIdDocument, options);
}
export type GetAdminGiPortfoliosByUserIdQueryHookResult = ReturnType<
  typeof useGetAdminGiPortfoliosByUserIdQuery
>;
export type GetAdminGiPortfoliosByUserIdLazyQueryHookResult = ReturnType<
  typeof useGetAdminGiPortfoliosByUserIdLazyQuery
>;
export type GetAdminGiPortfoliosByUserIdQueryResult = Apollo.QueryResult<
  GetAdminGiPortfoliosByUserIdQuery,
  GetAdminGiPortfoliosByUserIdQueryVariables
>;
export const GetUserAccountsByUserIdForAdminSidebarDocument = gql`
  query GetUserAccountsByUserIdForAdminSidebar($userId: ID!) {
    users(where: { id: $userId }) {
      edges {
        node {
          accounts {
            edges {
              node {
                type
              }
            }
          }
          roboGeneralInvestments {
            edges {
              node {
                isActive
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetUserAccountsByUserIdForAdminSidebarQuery__
 *
 * To run a query within a React component, call `useGetUserAccountsByUserIdForAdminSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAccountsByUserIdForAdminSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAccountsByUserIdForAdminSidebarQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserAccountsByUserIdForAdminSidebarQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserAccountsByUserIdForAdminSidebarQuery,
    GetUserAccountsByUserIdForAdminSidebarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserAccountsByUserIdForAdminSidebarQuery,
    GetUserAccountsByUserIdForAdminSidebarQueryVariables
  >(GetUserAccountsByUserIdForAdminSidebarDocument, options);
}
export function useGetUserAccountsByUserIdForAdminSidebarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserAccountsByUserIdForAdminSidebarQuery,
    GetUserAccountsByUserIdForAdminSidebarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserAccountsByUserIdForAdminSidebarQuery,
    GetUserAccountsByUserIdForAdminSidebarQueryVariables
  >(GetUserAccountsByUserIdForAdminSidebarDocument, options);
}
export type GetUserAccountsByUserIdForAdminSidebarQueryHookResult = ReturnType<
  typeof useGetUserAccountsByUserIdForAdminSidebarQuery
>;
export type GetUserAccountsByUserIdForAdminSidebarLazyQueryHookResult = ReturnType<
  typeof useGetUserAccountsByUserIdForAdminSidebarLazyQuery
>;
export type GetUserAccountsByUserIdForAdminSidebarQueryResult = Apollo.QueryResult<
  GetUserAccountsByUserIdForAdminSidebarQuery,
  GetUserAccountsByUserIdForAdminSidebarQueryVariables
>;
export const GetAcatTransfersDocument = gql`
  query GetAcatTransfers {
    response: acatTransfers {
      edges {
        node {
          id
          createTime
          updateTime
          statusChangeLog {
            status
            eventTime
          }
          transfers {
            id
            asset {
              id
              symbol
              ... on SecurityAsset {
                cusip
              }
            }
            createTime
            updateTime
            quantity
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

/**
 * __useGetAcatTransfersQuery__
 *
 * To run a query within a React component, call `useGetAcatTransfersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAcatTransfersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAcatTransfersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAcatTransfersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAcatTransfersQuery, GetAcatTransfersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAcatTransfersQuery, GetAcatTransfersQueryVariables>(
    GetAcatTransfersDocument,
    options,
  );
}
export function useGetAcatTransfersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAcatTransfersQuery, GetAcatTransfersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAcatTransfersQuery, GetAcatTransfersQueryVariables>(
    GetAcatTransfersDocument,
    options,
  );
}
export type GetAcatTransfersQueryHookResult = ReturnType<typeof useGetAcatTransfersQuery>;
export type GetAcatTransfersLazyQueryHookResult = ReturnType<typeof useGetAcatTransfersLazyQuery>;
export type GetAcatTransfersQueryResult = Apollo.QueryResult<
  GetAcatTransfersQuery,
  GetAcatTransfersQueryVariables
>;
export const GetAccountValuesDocument = gql`
  query GetAccountValues($start: Time!, $end: Time, $period: TimePeriod!, $portfolioId: ID) {
    totalValue: userAccountPortfolios(where: { id: $portfolioId }) {
      edges {
        node {
          totalValue
        }
      }
    }
    userAccountPortfolios(where: { id: $portfolioId }) {
      edges {
        node {
          valueHistory(range: { period: $period, start: $start, end: $end }) {
            period
            start
            end
            limit
            values
            timestamps
            gainLosses
            returnRates
          }
        }
      }
    }
  }
`;

/**
 * __useGetAccountValuesQuery__
 *
 * To run a query within a React component, call `useGetAccountValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountValuesQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      period: // value for 'period'
 *      portfolioId: // value for 'portfolioId'
 *   },
 * });
 */
export function useGetAccountValuesQuery(
  baseOptions: Apollo.QueryHookOptions<GetAccountValuesQuery, GetAccountValuesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAccountValuesQuery, GetAccountValuesQueryVariables>(
    GetAccountValuesDocument,
    options,
  );
}
export function useGetAccountValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAccountValuesQuery, GetAccountValuesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAccountValuesQuery, GetAccountValuesQueryVariables>(
    GetAccountValuesDocument,
    options,
  );
}
export type GetAccountValuesQueryHookResult = ReturnType<typeof useGetAccountValuesQuery>;
export type GetAccountValuesLazyQueryHookResult = ReturnType<typeof useGetAccountValuesLazyQuery>;
export type GetAccountValuesQueryResult = Apollo.QueryResult<
  GetAccountValuesQuery,
  GetAccountValuesQueryVariables
>;
export const GetStockAccountDetailDocument = gql`
  query GetStockAccountDetail($accountType: UserAccountAccountType!) {
    userAccounts(where: { type: $accountType }) {
      edges {
        node {
          id
          externalID
          type
          status
          fpslEnrolled
          cashSweepEnrolled
          programEnrollmentHistory {
            id
            electionPreference
            approvalStatus
            programType
            reviewedAt
            reason
            submissionStatus
            submittedAt
            createTime
          }
          portfolios {
            edges {
              node {
                assetBalances(where: { hasAssetWith: { symbol: "USD" } }) {
                  edges {
                    node {
                      balances {
                        withdrawable
                        total
                        pending
                        tradeable
                        activeBalance
                        frozen
                      }
                    }
                  }
                }
                holdings: assetBalances(where: { hasAssetWith: { not: { symbol: "USD" } } }) {
                  edges {
                    node {
                      balances {
                        activeBalance
                      }
                      asset {
                        id
                        name
                        symbol
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    users {
      edges {
        node {
          user_id: id
          recurringRules(where: { isActive: true }) {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetStockAccountDetailQuery__
 *
 * To run a query within a React component, call `useGetStockAccountDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockAccountDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockAccountDetailQuery({
 *   variables: {
 *      accountType: // value for 'accountType'
 *   },
 * });
 */
export function useGetStockAccountDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStockAccountDetailQuery,
    GetStockAccountDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStockAccountDetailQuery, GetStockAccountDetailQueryVariables>(
    GetStockAccountDetailDocument,
    options,
  );
}
export function useGetStockAccountDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStockAccountDetailQuery,
    GetStockAccountDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStockAccountDetailQuery, GetStockAccountDetailQueryVariables>(
    GetStockAccountDetailDocument,
    options,
  );
}
export type GetStockAccountDetailQueryHookResult = ReturnType<typeof useGetStockAccountDetailQuery>;
export type GetStockAccountDetailLazyQueryHookResult = ReturnType<
  typeof useGetStockAccountDetailLazyQuery
>;
export type GetStockAccountDetailQueryResult = Apollo.QueryResult<
  GetStockAccountDetailQuery,
  GetStockAccountDetailQueryVariables
>;
export const GetAchTransferByIdDocument = gql`
  query GetACHTransferById($id: ID!) {
    node(id: $id) {
      ...AchTransfer
    }
  }
  ${AchTransferFragmentDoc}
`;

/**
 * __useGetAchTransferByIdQuery__
 *
 * To run a query within a React component, call `useGetAchTransferByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAchTransferByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAchTransferByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAchTransferByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetAchTransferByIdQuery, GetAchTransferByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAchTransferByIdQuery, GetAchTransferByIdQueryVariables>(
    GetAchTransferByIdDocument,
    options,
  );
}
export function useGetAchTransferByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAchTransferByIdQuery,
    GetAchTransferByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAchTransferByIdQuery, GetAchTransferByIdQueryVariables>(
    GetAchTransferByIdDocument,
    options,
  );
}
export type GetAchTransferByIdQueryHookResult = ReturnType<typeof useGetAchTransferByIdQuery>;
export type GetAchTransferByIdLazyQueryHookResult = ReturnType<
  typeof useGetAchTransferByIdLazyQuery
>;
export type GetAchTransferByIdQueryResult = Apollo.QueryResult<
  GetAchTransferByIdQuery,
  GetAchTransferByIdQueryVariables
>;
export const GetDepositInfoDocument = gql`
  query GetDepositInfo($portfolioID: ID!) {
    userAccountPortfolios(where: { id: $portfolioID }) {
      edges {
        node {
          availableInstantDeposit
          availableDailyDeposit
        }
      }
    }
  }
`;

/**
 * __useGetDepositInfoQuery__
 *
 * To run a query within a React component, call `useGetDepositInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepositInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepositInfoQuery({
 *   variables: {
 *      portfolioID: // value for 'portfolioID'
 *   },
 * });
 */
export function useGetDepositInfoQuery(
  baseOptions: Apollo.QueryHookOptions<GetDepositInfoQuery, GetDepositInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDepositInfoQuery, GetDepositInfoQueryVariables>(
    GetDepositInfoDocument,
    options,
  );
}
export function useGetDepositInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDepositInfoQuery, GetDepositInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDepositInfoQuery, GetDepositInfoQueryVariables>(
    GetDepositInfoDocument,
    options,
  );
}
export type GetDepositInfoQueryHookResult = ReturnType<typeof useGetDepositInfoQuery>;
export type GetDepositInfoLazyQueryHookResult = ReturnType<typeof useGetDepositInfoLazyQuery>;
export type GetDepositInfoQueryResult = Apollo.QueryResult<
  GetDepositInfoQuery,
  GetDepositInfoQueryVariables
>;
export const GetAllAccountsDataDocument = gql`
  query GetAllAccountsData($accountFilter: UserAccountWhereInput) {
    users {
      edges {
        node {
          ...UserAccountDetail
          ...UserRoboGoalDetail
        }
      }
    }
  }
  ${UserAccountDetailFragmentDoc}
  ${UserRoboGoalDetailFragmentDoc}
`;

/**
 * __useGetAllAccountsDataQuery__
 *
 * To run a query within a React component, call `useGetAllAccountsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAccountsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAccountsDataQuery({
 *   variables: {
 *      accountFilter: // value for 'accountFilter'
 *   },
 * });
 */
export function useGetAllAccountsDataQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllAccountsDataQuery, GetAllAccountsDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllAccountsDataQuery, GetAllAccountsDataQueryVariables>(
    GetAllAccountsDataDocument,
    options,
  );
}
export function useGetAllAccountsDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllAccountsDataQuery,
    GetAllAccountsDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllAccountsDataQuery, GetAllAccountsDataQueryVariables>(
    GetAllAccountsDataDocument,
    options,
  );
}
export type GetAllAccountsDataQueryHookResult = ReturnType<typeof useGetAllAccountsDataQuery>;
export type GetAllAccountsDataLazyQueryHookResult = ReturnType<
  typeof useGetAllAccountsDataLazyQuery
>;
export type GetAllAccountsDataQueryResult = Apollo.QueryResult<
  GetAllAccountsDataQuery,
  GetAllAccountsDataQueryVariables
>;
export const GetAllAccountsChartDataDocument = gql`
  query GetAllAccountsChartData($userId: ID, $accountFilter: UserAccountWhereInput) {
    users(where: { id: $userId }) {
      edges {
        node {
          ...UserAccountDetail
          roboGoals {
            edges {
              node {
                id
                name
                accountPortfolio {
                  totalValue
                }
              }
            }
          }
        }
      }
    }
  }
  ${UserAccountDetailFragmentDoc}
`;

/**
 * __useGetAllAccountsChartDataQuery__
 *
 * To run a query within a React component, call `useGetAllAccountsChartDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAccountsChartDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAccountsChartDataQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      accountFilter: // value for 'accountFilter'
 *   },
 * });
 */
export function useGetAllAccountsChartDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllAccountsChartDataQuery,
    GetAllAccountsChartDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllAccountsChartDataQuery, GetAllAccountsChartDataQueryVariables>(
    GetAllAccountsChartDataDocument,
    options,
  );
}
export function useGetAllAccountsChartDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllAccountsChartDataQuery,
    GetAllAccountsChartDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllAccountsChartDataQuery, GetAllAccountsChartDataQueryVariables>(
    GetAllAccountsChartDataDocument,
    options,
  );
}
export type GetAllAccountsChartDataQueryHookResult = ReturnType<
  typeof useGetAllAccountsChartDataQuery
>;
export type GetAllAccountsChartDataLazyQueryHookResult = ReturnType<
  typeof useGetAllAccountsChartDataLazyQuery
>;
export type GetAllAccountsChartDataQueryResult = Apollo.QueryResult<
  GetAllAccountsChartDataQuery,
  GetAllAccountsChartDataQueryVariables
>;
export const GetLatestNonDiscretionaryUpdateRequestsDocument = gql`
  query GetLatestNonDiscretionaryUpdateRequests {
    nonDiscretionaryUpdateRequests(first: 1, orderBy: { direction: DESC, field: create_time }) {
      edges {
        node {
          status
          createTime
          updateTime
        }
      }
    }
  }
`;

/**
 * __useGetLatestNonDiscretionaryUpdateRequestsQuery__
 *
 * To run a query within a React component, call `useGetLatestNonDiscretionaryUpdateRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestNonDiscretionaryUpdateRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestNonDiscretionaryUpdateRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestNonDiscretionaryUpdateRequestsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLatestNonDiscretionaryUpdateRequestsQuery,
    GetLatestNonDiscretionaryUpdateRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLatestNonDiscretionaryUpdateRequestsQuery,
    GetLatestNonDiscretionaryUpdateRequestsQueryVariables
  >(GetLatestNonDiscretionaryUpdateRequestsDocument, options);
}
export function useGetLatestNonDiscretionaryUpdateRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLatestNonDiscretionaryUpdateRequestsQuery,
    GetLatestNonDiscretionaryUpdateRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLatestNonDiscretionaryUpdateRequestsQuery,
    GetLatestNonDiscretionaryUpdateRequestsQueryVariables
  >(GetLatestNonDiscretionaryUpdateRequestsDocument, options);
}
export type GetLatestNonDiscretionaryUpdateRequestsQueryHookResult = ReturnType<
  typeof useGetLatestNonDiscretionaryUpdateRequestsQuery
>;
export type GetLatestNonDiscretionaryUpdateRequestsLazyQueryHookResult = ReturnType<
  typeof useGetLatestNonDiscretionaryUpdateRequestsLazyQuery
>;
export type GetLatestNonDiscretionaryUpdateRequestsQueryResult = Apollo.QueryResult<
  GetLatestNonDiscretionaryUpdateRequestsQuery,
  GetLatestNonDiscretionaryUpdateRequestsQueryVariables
>;
export const GetLatestDiscretionaryUpdateRequestsDocument = gql`
  query GetLatestDiscretionaryUpdateRequests {
    discretionaryUpdateRequests(first: 1, orderBy: { direction: DESC, field: create_time }) {
      edges {
        node {
          status
          createTime
          updateTime
        }
      }
    }
  }
`;

/**
 * __useGetLatestDiscretionaryUpdateRequestsQuery__
 *
 * To run a query within a React component, call `useGetLatestDiscretionaryUpdateRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestDiscretionaryUpdateRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestDiscretionaryUpdateRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestDiscretionaryUpdateRequestsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLatestDiscretionaryUpdateRequestsQuery,
    GetLatestDiscretionaryUpdateRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLatestDiscretionaryUpdateRequestsQuery,
    GetLatestDiscretionaryUpdateRequestsQueryVariables
  >(GetLatestDiscretionaryUpdateRequestsDocument, options);
}
export function useGetLatestDiscretionaryUpdateRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLatestDiscretionaryUpdateRequestsQuery,
    GetLatestDiscretionaryUpdateRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLatestDiscretionaryUpdateRequestsQuery,
    GetLatestDiscretionaryUpdateRequestsQueryVariables
  >(GetLatestDiscretionaryUpdateRequestsDocument, options);
}
export type GetLatestDiscretionaryUpdateRequestsQueryHookResult = ReturnType<
  typeof useGetLatestDiscretionaryUpdateRequestsQuery
>;
export type GetLatestDiscretionaryUpdateRequestsLazyQueryHookResult = ReturnType<
  typeof useGetLatestDiscretionaryUpdateRequestsLazyQuery
>;
export type GetLatestDiscretionaryUpdateRequestsQueryResult = Apollo.QueryResult<
  GetLatestDiscretionaryUpdateRequestsQuery,
  GetLatestDiscretionaryUpdateRequestsQueryVariables
>;
export const GetLatestProfileUpdateRequestsDocument = gql`
  query GetLatestProfileUpdateRequests {
    userProfileUpdateRequests(first: 1, orderBy: { direction: DESC, field: create_time }) {
      edges {
        node {
          status
          createTime
          updateTime
        }
      }
    }
  }
`;

/**
 * __useGetLatestProfileUpdateRequestsQuery__
 *
 * To run a query within a React component, call `useGetLatestProfileUpdateRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestProfileUpdateRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestProfileUpdateRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestProfileUpdateRequestsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLatestProfileUpdateRequestsQuery,
    GetLatestProfileUpdateRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLatestProfileUpdateRequestsQuery,
    GetLatestProfileUpdateRequestsQueryVariables
  >(GetLatestProfileUpdateRequestsDocument, options);
}
export function useGetLatestProfileUpdateRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLatestProfileUpdateRequestsQuery,
    GetLatestProfileUpdateRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLatestProfileUpdateRequestsQuery,
    GetLatestProfileUpdateRequestsQueryVariables
  >(GetLatestProfileUpdateRequestsDocument, options);
}
export type GetLatestProfileUpdateRequestsQueryHookResult = ReturnType<
  typeof useGetLatestProfileUpdateRequestsQuery
>;
export type GetLatestProfileUpdateRequestsLazyQueryHookResult = ReturnType<
  typeof useGetLatestProfileUpdateRequestsLazyQuery
>;
export type GetLatestProfileUpdateRequestsQueryResult = Apollo.QueryResult<
  GetLatestProfileUpdateRequestsQuery,
  GetLatestProfileUpdateRequestsQueryVariables
>;
export const GetLatestNonDiscretionaryUpdateRequestsDetailsDocument = gql`
  query GetLatestNonDiscretionaryUpdateRequestsDetails {
    nonDiscretionaryAccountKycs {
      edges {
        node {
          updateRequests {
            createTime
            status
            requestParameters {
              ...CommonUserInvestorInformationNonDiscretionaryRequestParametersDetails
            }
          }
        }
      }
    }
  }
  ${CommonUserInvestorInformationNonDiscretionaryRequestParametersDetailsFragmentDoc}
`;

/**
 * __useGetLatestNonDiscretionaryUpdateRequestsDetailsQuery__
 *
 * To run a query within a React component, call `useGetLatestNonDiscretionaryUpdateRequestsDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestNonDiscretionaryUpdateRequestsDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestNonDiscretionaryUpdateRequestsDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestNonDiscretionaryUpdateRequestsDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLatestNonDiscretionaryUpdateRequestsDetailsQuery,
    GetLatestNonDiscretionaryUpdateRequestsDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLatestNonDiscretionaryUpdateRequestsDetailsQuery,
    GetLatestNonDiscretionaryUpdateRequestsDetailsQueryVariables
  >(GetLatestNonDiscretionaryUpdateRequestsDetailsDocument, options);
}
export function useGetLatestNonDiscretionaryUpdateRequestsDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLatestNonDiscretionaryUpdateRequestsDetailsQuery,
    GetLatestNonDiscretionaryUpdateRequestsDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLatestNonDiscretionaryUpdateRequestsDetailsQuery,
    GetLatestNonDiscretionaryUpdateRequestsDetailsQueryVariables
  >(GetLatestNonDiscretionaryUpdateRequestsDetailsDocument, options);
}
export type GetLatestNonDiscretionaryUpdateRequestsDetailsQueryHookResult = ReturnType<
  typeof useGetLatestNonDiscretionaryUpdateRequestsDetailsQuery
>;
export type GetLatestNonDiscretionaryUpdateRequestsDetailsLazyQueryHookResult = ReturnType<
  typeof useGetLatestNonDiscretionaryUpdateRequestsDetailsLazyQuery
>;
export type GetLatestNonDiscretionaryUpdateRequestsDetailsQueryResult = Apollo.QueryResult<
  GetLatestNonDiscretionaryUpdateRequestsDetailsQuery,
  GetLatestNonDiscretionaryUpdateRequestsDetailsQueryVariables
>;
export const GetLatestDiscretionaryUpdateRequestsDetailsDocument = gql`
  query GetLatestDiscretionaryUpdateRequestsDetails {
    discretionaryAccountKycs {
      edges {
        node {
          updateRequests {
            createTime
            status
            requestParameters {
              ...CommonUserInvestorInformationDiscretionaryRequestParametersDetails
            }
          }
        }
      }
    }
  }
  ${CommonUserInvestorInformationDiscretionaryRequestParametersDetailsFragmentDoc}
`;

/**
 * __useGetLatestDiscretionaryUpdateRequestsDetailsQuery__
 *
 * To run a query within a React component, call `useGetLatestDiscretionaryUpdateRequestsDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestDiscretionaryUpdateRequestsDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestDiscretionaryUpdateRequestsDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestDiscretionaryUpdateRequestsDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLatestDiscretionaryUpdateRequestsDetailsQuery,
    GetLatestDiscretionaryUpdateRequestsDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLatestDiscretionaryUpdateRequestsDetailsQuery,
    GetLatestDiscretionaryUpdateRequestsDetailsQueryVariables
  >(GetLatestDiscretionaryUpdateRequestsDetailsDocument, options);
}
export function useGetLatestDiscretionaryUpdateRequestsDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLatestDiscretionaryUpdateRequestsDetailsQuery,
    GetLatestDiscretionaryUpdateRequestsDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLatestDiscretionaryUpdateRequestsDetailsQuery,
    GetLatestDiscretionaryUpdateRequestsDetailsQueryVariables
  >(GetLatestDiscretionaryUpdateRequestsDetailsDocument, options);
}
export type GetLatestDiscretionaryUpdateRequestsDetailsQueryHookResult = ReturnType<
  typeof useGetLatestDiscretionaryUpdateRequestsDetailsQuery
>;
export type GetLatestDiscretionaryUpdateRequestsDetailsLazyQueryHookResult = ReturnType<
  typeof useGetLatestDiscretionaryUpdateRequestsDetailsLazyQuery
>;
export type GetLatestDiscretionaryUpdateRequestsDetailsQueryResult = Apollo.QueryResult<
  GetLatestDiscretionaryUpdateRequestsDetailsQuery,
  GetLatestDiscretionaryUpdateRequestsDetailsQueryVariables
>;
export const GetLatestTrustedContactUpdateRequestsDetailsDocument = gql`
  query GetLatestTrustedContactUpdateRequestsDetails {
    trustedContacts {
      edges {
        node {
          id
          id
          firstName
          lastName
          dateOfBirth
          email
          phoneNumber
          isActive
          __typename
        }
      }
    }
    trustedContactUpdateRequests {
      edges {
        node {
          id
          ...CommonTrustedContactUpdateRequestsDetails
          __typename
        }
      }
    }
  }
  ${CommonTrustedContactUpdateRequestsDetailsFragmentDoc}
`;

/**
 * __useGetLatestTrustedContactUpdateRequestsDetailsQuery__
 *
 * To run a query within a React component, call `useGetLatestTrustedContactUpdateRequestsDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestTrustedContactUpdateRequestsDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestTrustedContactUpdateRequestsDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestTrustedContactUpdateRequestsDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLatestTrustedContactUpdateRequestsDetailsQuery,
    GetLatestTrustedContactUpdateRequestsDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLatestTrustedContactUpdateRequestsDetailsQuery,
    GetLatestTrustedContactUpdateRequestsDetailsQueryVariables
  >(GetLatestTrustedContactUpdateRequestsDetailsDocument, options);
}
export function useGetLatestTrustedContactUpdateRequestsDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLatestTrustedContactUpdateRequestsDetailsQuery,
    GetLatestTrustedContactUpdateRequestsDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLatestTrustedContactUpdateRequestsDetailsQuery,
    GetLatestTrustedContactUpdateRequestsDetailsQueryVariables
  >(GetLatestTrustedContactUpdateRequestsDetailsDocument, options);
}
export type GetLatestTrustedContactUpdateRequestsDetailsQueryHookResult = ReturnType<
  typeof useGetLatestTrustedContactUpdateRequestsDetailsQuery
>;
export type GetLatestTrustedContactUpdateRequestsDetailsLazyQueryHookResult = ReturnType<
  typeof useGetLatestTrustedContactUpdateRequestsDetailsLazyQuery
>;
export type GetLatestTrustedContactUpdateRequestsDetailsQueryResult = Apollo.QueryResult<
  GetLatestTrustedContactUpdateRequestsDetailsQuery,
  GetLatestTrustedContactUpdateRequestsDetailsQueryVariables
>;
export const GetAvailableCountriesDocument = gql`
  query GetAvailableCountries {
    countries {
      edges {
        node {
          id
          alpha3Code
          name
        }
      }
    }
  }
`;

/**
 * __useGetAvailableCountriesQuery__
 *
 * To run a query within a React component, call `useGetAvailableCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAvailableCountriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAvailableCountriesQuery,
    GetAvailableCountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAvailableCountriesQuery, GetAvailableCountriesQueryVariables>(
    GetAvailableCountriesDocument,
    options,
  );
}
export function useGetAvailableCountriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAvailableCountriesQuery,
    GetAvailableCountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAvailableCountriesQuery, GetAvailableCountriesQueryVariables>(
    GetAvailableCountriesDocument,
    options,
  );
}
export type GetAvailableCountriesQueryHookResult = ReturnType<typeof useGetAvailableCountriesQuery>;
export type GetAvailableCountriesLazyQueryHookResult = ReturnType<
  typeof useGetAvailableCountriesLazyQuery
>;
export type GetAvailableCountriesQueryResult = Apollo.QueryResult<
  GetAvailableCountriesQuery,
  GetAvailableCountriesQueryVariables
>;
export const GetEditableValuesForSingleGoalDocument = gql`
  query GetEditableValuesForSingleGoal($goalUid: ID) {
    users {
      edges {
        node {
          roboGoals(where: { id: $goalUid }) {
            edges {
              node {
                isAutoInfusionPaused
                name
                parameters {
                  targetAmount
                  endDate
                  infusionAmount
                  infusionFreq
                  initialInvestment
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetEditableValuesForSingleGoalQuery__
 *
 * To run a query within a React component, call `useGetEditableValuesForSingleGoalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditableValuesForSingleGoalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditableValuesForSingleGoalQuery({
 *   variables: {
 *      goalUid: // value for 'goalUid'
 *   },
 * });
 */
export function useGetEditableValuesForSingleGoalQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEditableValuesForSingleGoalQuery,
    GetEditableValuesForSingleGoalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEditableValuesForSingleGoalQuery,
    GetEditableValuesForSingleGoalQueryVariables
  >(GetEditableValuesForSingleGoalDocument, options);
}
export function useGetEditableValuesForSingleGoalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEditableValuesForSingleGoalQuery,
    GetEditableValuesForSingleGoalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEditableValuesForSingleGoalQuery,
    GetEditableValuesForSingleGoalQueryVariables
  >(GetEditableValuesForSingleGoalDocument, options);
}
export type GetEditableValuesForSingleGoalQueryHookResult = ReturnType<
  typeof useGetEditableValuesForSingleGoalQuery
>;
export type GetEditableValuesForSingleGoalLazyQueryHookResult = ReturnType<
  typeof useGetEditableValuesForSingleGoalLazyQuery
>;
export type GetEditableValuesForSingleGoalQueryResult = Apollo.QueryResult<
  GetEditableValuesForSingleGoalQuery,
  GetEditableValuesForSingleGoalQueryVariables
>;
export const GetExternalAccountsDocument = gql`
  query GetExternalAccounts($userId: ID, $accountStatus: [ExternalAccountConnectionStatus!]) {
    externalAccounts(where: { hasUserWith: { id: $userId }, statusIn: $accountStatus }) {
      edges {
        ...ExternalAccountData
      }
    }
  }
  ${ExternalAccountDataFragmentDoc}
`;

/**
 * __useGetExternalAccountsQuery__
 *
 * To run a query within a React component, call `useGetExternalAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalAccountsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      accountStatus: // value for 'accountStatus'
 *   },
 * });
 */
export function useGetExternalAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetExternalAccountsQuery,
    GetExternalAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExternalAccountsQuery, GetExternalAccountsQueryVariables>(
    GetExternalAccountsDocument,
    options,
  );
}
export function useGetExternalAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExternalAccountsQuery,
    GetExternalAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExternalAccountsQuery, GetExternalAccountsQueryVariables>(
    GetExternalAccountsDocument,
    options,
  );
}
export type GetExternalAccountsQueryHookResult = ReturnType<typeof useGetExternalAccountsQuery>;
export type GetExternalAccountsLazyQueryHookResult = ReturnType<
  typeof useGetExternalAccountsLazyQuery
>;
export type GetExternalAccountsQueryResult = Apollo.QueryResult<
  GetExternalAccountsQuery,
  GetExternalAccountsQueryVariables
>;
export const GetExternalAccountDocument = gql`
  query GetExternalAccount($accountId: ID!) {
    externalAccounts(where: { id: $accountId }) {
      edges {
        node {
          id
          type
          mask
          institution {
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGetExternalAccountQuery__
 *
 * To run a query within a React component, call `useGetExternalAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalAccountQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetExternalAccountQuery(
  baseOptions: Apollo.QueryHookOptions<GetExternalAccountQuery, GetExternalAccountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExternalAccountQuery, GetExternalAccountQueryVariables>(
    GetExternalAccountDocument,
    options,
  );
}
export function useGetExternalAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExternalAccountQuery,
    GetExternalAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExternalAccountQuery, GetExternalAccountQueryVariables>(
    GetExternalAccountDocument,
    options,
  );
}
export type GetExternalAccountQueryHookResult = ReturnType<typeof useGetExternalAccountQuery>;
export type GetExternalAccountLazyQueryHookResult = ReturnType<
  typeof useGetExternalAccountLazyQuery
>;
export type GetExternalAccountQueryResult = Apollo.QueryResult<
  GetExternalAccountQuery,
  GetExternalAccountQueryVariables
>;
export const GetInvestorDocumentsDocument = gql`
  query GetInvestorDocuments($startDate: Date, $endDate: Date, $accountId: ID!) {
    statementDocuments: investorDocuments(
      where: {
        startDate: $startDate
        endDate: $endDate
        userAccountID: $accountId
        documentCategory: STATEMENT
      }
    ) {
      id
      name
      category
      type
      taxIDs
      url
      date
    }
    taxFormDocuments: investorDocuments(
      where: {
        startDate: $startDate
        endDate: $endDate
        userAccountID: $accountId
        documentCategory: TAX_FORM
      }
    ) {
      id
      name
      category
      type
      taxIDs
      url
      date
    }
    tradeConfirmationDocuments: investorDocuments(
      where: {
        startDate: $startDate
        endDate: $endDate
        userAccountID: $accountId
        documentCategory: TRADE_CONFIRMATION
      }
    ) {
      id
      name
      category
      type
      taxIDs
      url
      date
    }
  }
`;

/**
 * __useGetInvestorDocumentsQuery__
 *
 * To run a query within a React component, call `useGetInvestorDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvestorDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvestorDocumentsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetInvestorDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInvestorDocumentsQuery,
    GetInvestorDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvestorDocumentsQuery, GetInvestorDocumentsQueryVariables>(
    GetInvestorDocumentsDocument,
    options,
  );
}
export function useGetInvestorDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvestorDocumentsQuery,
    GetInvestorDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvestorDocumentsQuery, GetInvestorDocumentsQueryVariables>(
    GetInvestorDocumentsDocument,
    options,
  );
}
export type GetInvestorDocumentsQueryHookResult = ReturnType<typeof useGetInvestorDocumentsQuery>;
export type GetInvestorDocumentsLazyQueryHookResult = ReturnType<
  typeof useGetInvestorDocumentsLazyQuery
>;
export type GetInvestorDocumentsQueryResult = Apollo.QueryResult<
  GetInvestorDocumentsQuery,
  GetInvestorDocumentsQueryVariables
>;
export const GetMarketDataDocument = gql`
  query GetMarketData {
    ...MarketFields
    ...SectorFields
  }
  ${MarketFieldsFragmentDoc}
  ${SectorFieldsFragmentDoc}
`;

/**
 * __useGetMarketDataQuery__
 *
 * To run a query within a React component, call `useGetMarketDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarketDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarketDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMarketDataQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMarketDataQuery, GetMarketDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMarketDataQuery, GetMarketDataQueryVariables>(
    GetMarketDataDocument,
    options,
  );
}
export function useGetMarketDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMarketDataQuery, GetMarketDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMarketDataQuery, GetMarketDataQueryVariables>(
    GetMarketDataDocument,
    options,
  );
}
export type GetMarketDataQueryHookResult = ReturnType<typeof useGetMarketDataQuery>;
export type GetMarketDataLazyQueryHookResult = ReturnType<typeof useGetMarketDataLazyQuery>;
export type GetMarketDataQueryResult = Apollo.QueryResult<
  GetMarketDataQuery,
  GetMarketDataQueryVariables
>;
export const GetMarketStatusDocument = gql`
  query GetMarketStatus {
    marketHours {
      status(calendar: MARKET_TYPE_SECURITIES) {
        date
        status
        open
        close
        calendar
      }
      previous(calendar: MARKET_TYPE_SECURITIES, count: 1) {
        date
        status
        open
        close
        calendar
      }
    }
  }
`;

/**
 * __useGetMarketStatusQuery__
 *
 * To run a query within a React component, call `useGetMarketStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarketStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarketStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMarketStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMarketStatusQuery, GetMarketStatusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMarketStatusQuery, GetMarketStatusQueryVariables>(
    GetMarketStatusDocument,
    options,
  );
}
export function useGetMarketStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMarketStatusQuery, GetMarketStatusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMarketStatusQuery, GetMarketStatusQueryVariables>(
    GetMarketStatusDocument,
    options,
  );
}
export type GetMarketStatusQueryHookResult = ReturnType<typeof useGetMarketStatusQuery>;
export type GetMarketStatusLazyQueryHookResult = ReturnType<typeof useGetMarketStatusLazyQuery>;
export type GetMarketStatusQueryResult = Apollo.QueryResult<
  GetMarketStatusQuery,
  GetMarketStatusQueryVariables
>;
export const GetGoeMappingsDocument = gql`
  query GetGOEMappings {
    goePortfolioMappings {
      id
      goePortfolioID
      modelPortfolio {
        id
        externalReferenceID
        fiatPct
        bondPct
        stockPct
      }
    }
  }
`;

/**
 * __useGetGoeMappingsQuery__
 *
 * To run a query within a React component, call `useGetGoeMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoeMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoeMappingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGoeMappingsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetGoeMappingsQuery, GetGoeMappingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGoeMappingsQuery, GetGoeMappingsQueryVariables>(
    GetGoeMappingsDocument,
    options,
  );
}
export function useGetGoeMappingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGoeMappingsQuery, GetGoeMappingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGoeMappingsQuery, GetGoeMappingsQueryVariables>(
    GetGoeMappingsDocument,
    options,
  );
}
export type GetGoeMappingsQueryHookResult = ReturnType<typeof useGetGoeMappingsQuery>;
export type GetGoeMappingsLazyQueryHookResult = ReturnType<typeof useGetGoeMappingsLazyQuery>;
export type GetGoeMappingsQueryResult = Apollo.QueryResult<
  GetGoeMappingsQuery,
  GetGoeMappingsQueryVariables
>;
export const GetModelPortfolioByExternalReferenceIdDocument = gql`
  query GetModelPortfolioByExternalReferenceID($id: ID) {
    modelPortfolios(where: { id: $id }) {
      edges {
        node {
          bondPct
          fiatPct
          stockPct
          externalReferenceID
          id
          sectors {
            id
            targetPct
            sector {
              ...GetSectorDetails
            }
          }
        }
      }
    }
  }
  ${GetSectorDetailsFragmentDoc}
`;

/**
 * __useGetModelPortfolioByExternalReferenceIdQuery__
 *
 * To run a query within a React component, call `useGetModelPortfolioByExternalReferenceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelPortfolioByExternalReferenceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelPortfolioByExternalReferenceIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetModelPortfolioByExternalReferenceIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetModelPortfolioByExternalReferenceIdQuery,
    GetModelPortfolioByExternalReferenceIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetModelPortfolioByExternalReferenceIdQuery,
    GetModelPortfolioByExternalReferenceIdQueryVariables
  >(GetModelPortfolioByExternalReferenceIdDocument, options);
}
export function useGetModelPortfolioByExternalReferenceIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetModelPortfolioByExternalReferenceIdQuery,
    GetModelPortfolioByExternalReferenceIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetModelPortfolioByExternalReferenceIdQuery,
    GetModelPortfolioByExternalReferenceIdQueryVariables
  >(GetModelPortfolioByExternalReferenceIdDocument, options);
}
export type GetModelPortfolioByExternalReferenceIdQueryHookResult = ReturnType<
  typeof useGetModelPortfolioByExternalReferenceIdQuery
>;
export type GetModelPortfolioByExternalReferenceIdLazyQueryHookResult = ReturnType<
  typeof useGetModelPortfolioByExternalReferenceIdLazyQuery
>;
export type GetModelPortfolioByExternalReferenceIdQueryResult = Apollo.QueryResult<
  GetModelPortfolioByExternalReferenceIdQuery,
  GetModelPortfolioByExternalReferenceIdQueryVariables
>;
export const GetAllModelPortfolioAllocationsDocument = gql`
  query GetAllModelPortfolioAllocations {
    modelPortfolios {
      edges {
        node {
          bondPct
          fiatPct
          stockPct
          externalReferenceID
          id
        }
      }
    }
  }
`;

/**
 * __useGetAllModelPortfolioAllocationsQuery__
 *
 * To run a query within a React component, call `useGetAllModelPortfolioAllocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllModelPortfolioAllocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllModelPortfolioAllocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllModelPortfolioAllocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllModelPortfolioAllocationsQuery,
    GetAllModelPortfolioAllocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllModelPortfolioAllocationsQuery,
    GetAllModelPortfolioAllocationsQueryVariables
  >(GetAllModelPortfolioAllocationsDocument, options);
}
export function useGetAllModelPortfolioAllocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllModelPortfolioAllocationsQuery,
    GetAllModelPortfolioAllocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllModelPortfolioAllocationsQuery,
    GetAllModelPortfolioAllocationsQueryVariables
  >(GetAllModelPortfolioAllocationsDocument, options);
}
export type GetAllModelPortfolioAllocationsQueryHookResult = ReturnType<
  typeof useGetAllModelPortfolioAllocationsQuery
>;
export type GetAllModelPortfolioAllocationsLazyQueryHookResult = ReturnType<
  typeof useGetAllModelPortfolioAllocationsLazyQuery
>;
export type GetAllModelPortfolioAllocationsQueryResult = Apollo.QueryResult<
  GetAllModelPortfolioAllocationsQuery,
  GetAllModelPortfolioAllocationsQueryVariables
>;
export const GetModelPortfoliosScreenDocument = gql`
  query GetModelPortfoliosScreen($id: ID!) {
    modelPortfolios(where: { id: $id }) {
      edges {
        node {
          bondPct
          fiatPct
          stockPct
          id
          sectors {
            id
            targetPct
            sector {
              ...GetSectorDetails
            }
          }
        }
      }
    }
  }
  ${GetSectorDetailsFragmentDoc}
`;

/**
 * __useGetModelPortfoliosScreenQuery__
 *
 * To run a query within a React component, call `useGetModelPortfoliosScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelPortfoliosScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelPortfoliosScreenQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetModelPortfoliosScreenQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetModelPortfoliosScreenQuery,
    GetModelPortfoliosScreenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetModelPortfoliosScreenQuery, GetModelPortfoliosScreenQueryVariables>(
    GetModelPortfoliosScreenDocument,
    options,
  );
}
export function useGetModelPortfoliosScreenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetModelPortfoliosScreenQuery,
    GetModelPortfoliosScreenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetModelPortfoliosScreenQuery, GetModelPortfoliosScreenQueryVariables>(
    GetModelPortfoliosScreenDocument,
    options,
  );
}
export type GetModelPortfoliosScreenQueryHookResult = ReturnType<
  typeof useGetModelPortfoliosScreenQuery
>;
export type GetModelPortfoliosScreenLazyQueryHookResult = ReturnType<
  typeof useGetModelPortfoliosScreenLazyQuery
>;
export type GetModelPortfoliosScreenQueryResult = Apollo.QueryResult<
  GetModelPortfoliosScreenQuery,
  GetModelPortfoliosScreenQueryVariables
>;
export const GetTargetPortfolioDocument = gql`
  query GetTargetPortfolio($id: ID!) {
    roboGoals(where: { id: $id }) {
      edges {
        node {
          allocation {
            fiatPct
            stockPct
            bondPct
            totalValue
            drift
            allocationBySector {
              sector {
                id
                name
                category
                assets {
                  targetPct
                  asset {
                    id
                    symbol
                  }
                }
              }
              percentage
            }
          }
          targetPortfolio {
            bondPct
            fiatPct
            stockPct
            id
            sectors {
              id
              targetPct
              sector {
                ...GetSectorDetails
              }
            }
          }
        }
      }
    }
    roboGeneralInvestments(where: { id: $id }) {
      edges {
        node {
          allocation {
            fiatPct
            stockPct
            bondPct
            totalValue
            drift
            allocationBySector {
              sector {
                id
                name
                category
                assets {
                  targetPct
                  asset {
                    id
                    symbol
                  }
                }
              }
              percentage
            }
          }
          targetPortfolio {
            bondPct
            fiatPct
            stockPct
            id
            sectors {
              id
              targetPct
              sector {
                ...GetSectorDetails
              }
            }
          }
        }
      }
    }
  }
  ${GetSectorDetailsFragmentDoc}
`;

/**
 * __useGetTargetPortfolioQuery__
 *
 * To run a query within a React component, call `useGetTargetPortfolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTargetPortfolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTargetPortfolioQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTargetPortfolioQuery(
  baseOptions: Apollo.QueryHookOptions<GetTargetPortfolioQuery, GetTargetPortfolioQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTargetPortfolioQuery, GetTargetPortfolioQueryVariables>(
    GetTargetPortfolioDocument,
    options,
  );
}
export function useGetTargetPortfolioLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTargetPortfolioQuery,
    GetTargetPortfolioQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTargetPortfolioQuery, GetTargetPortfolioQueryVariables>(
    GetTargetPortfolioDocument,
    options,
  );
}
export type GetTargetPortfolioQueryHookResult = ReturnType<typeof useGetTargetPortfolioQuery>;
export type GetTargetPortfolioLazyQueryHookResult = ReturnType<
  typeof useGetTargetPortfolioLazyQuery
>;
export type GetTargetPortfolioQueryResult = Apollo.QueryResult<
  GetTargetPortfolioQuery,
  GetTargetPortfolioQueryVariables
>;
export const GetNbboQuoteDocument = gql`
  query GetNbboQuote($securityId: ID!, $isBuy: Boolean!) {
    securityAssets(where: { id: $securityId }) {
      edges {
        node {
          id
          nbboQuote(isBuy: $isBuy) {
            AskPrice
            AskSize
            AskExchange {
              logo
              name
            }
            BidPrice
            BidSize
            BidExchange {
              logo
              name
            }
            lastTrade {
              price
              volume
              securityExchange {
                tape
              }
              timestamp
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetNbboQuoteQuery__
 *
 * To run a query within a React component, call `useGetNbboQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNbboQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNbboQuoteQuery({
 *   variables: {
 *      securityId: // value for 'securityId'
 *      isBuy: // value for 'isBuy'
 *   },
 * });
 */
export function useGetNbboQuoteQuery(
  baseOptions: Apollo.QueryHookOptions<GetNbboQuoteQuery, GetNbboQuoteQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNbboQuoteQuery, GetNbboQuoteQueryVariables>(
    GetNbboQuoteDocument,
    options,
  );
}
export function useGetNbboQuoteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNbboQuoteQuery, GetNbboQuoteQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNbboQuoteQuery, GetNbboQuoteQueryVariables>(
    GetNbboQuoteDocument,
    options,
  );
}
export type GetNbboQuoteQueryHookResult = ReturnType<typeof useGetNbboQuoteQuery>;
export type GetNbboQuoteLazyQueryHookResult = ReturnType<typeof useGetNbboQuoteLazyQuery>;
export type GetNbboQuoteQueryResult = Apollo.QueryResult<
  GetNbboQuoteQuery,
  GetNbboQuoteQueryVariables
>;
export const PingServerDocument = gql`
  query PingServer {
    ping {
      version
      time
    }
  }
`;

/**
 * __usePingServerQuery__
 *
 * To run a query within a React component, call `usePingServerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePingServerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePingServerQuery({
 *   variables: {
 *   },
 * });
 */
export function usePingServerQuery(
  baseOptions?: Apollo.QueryHookOptions<PingServerQuery, PingServerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PingServerQuery, PingServerQueryVariables>(PingServerDocument, options);
}
export function usePingServerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PingServerQuery, PingServerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PingServerQuery, PingServerQueryVariables>(
    PingServerDocument,
    options,
  );
}
export type PingServerQueryHookResult = ReturnType<typeof usePingServerQuery>;
export type PingServerLazyQueryHookResult = ReturnType<typeof usePingServerLazyQuery>;
export type PingServerQueryResult = Apollo.QueryResult<PingServerQuery, PingServerQueryVariables>;
export const GetPlaidDocument = gql`
  query GetPlaid {
    externalAccounts {
      edges {
        node {
          id
          mask
          type
          connectionStatus
          institution {
            id
            name
            createTime
            updateTime
          }
        }
      }
    }
  }
`;

/**
 * __useGetPlaidQuery__
 *
 * To run a query within a React component, call `useGetPlaidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaidQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlaidQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPlaidQuery, GetPlaidQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlaidQuery, GetPlaidQueryVariables>(GetPlaidDocument, options);
}
export function useGetPlaidLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPlaidQuery, GetPlaidQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlaidQuery, GetPlaidQueryVariables>(GetPlaidDocument, options);
}
export type GetPlaidQueryHookResult = ReturnType<typeof useGetPlaidQuery>;
export type GetPlaidLazyQueryHookResult = ReturnType<typeof useGetPlaidLazyQuery>;
export type GetPlaidQueryResult = Apollo.QueryResult<GetPlaidQuery, GetPlaidQueryVariables>;
export const GetPlaidV2Document = gql`
  query GetPlaidV2 {
    tenant {
      brokerage {
        plaidRelationshipOwner {
          id
        }
      }
      advisory {
        plaidRelationshipOwner {
          id
        }
      }
    }
    externalAccounts {
      edges {
        node {
          ...ExternalAccountFragment
        }
      }
    }
  }
  ${ExternalAccountFragmentFragmentDoc}
`;

/**
 * __useGetPlaidV2Query__
 *
 * To run a query within a React component, call `useGetPlaidV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaidV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaidV2Query({
 *   variables: {
 *   },
 * });
 */
export function useGetPlaidV2Query(
  baseOptions?: Apollo.QueryHookOptions<GetPlaidV2Query, GetPlaidV2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlaidV2Query, GetPlaidV2QueryVariables>(GetPlaidV2Document, options);
}
export function useGetPlaidV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPlaidV2Query, GetPlaidV2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlaidV2Query, GetPlaidV2QueryVariables>(
    GetPlaidV2Document,
    options,
  );
}
export type GetPlaidV2QueryHookResult = ReturnType<typeof useGetPlaidV2Query>;
export type GetPlaidV2LazyQueryHookResult = ReturnType<typeof useGetPlaidV2LazyQuery>;
export type GetPlaidV2QueryResult = Apollo.QueryResult<GetPlaidV2Query, GetPlaidV2QueryVariables>;
export const GetWithdrawableAmountDocument = gql`
  query GetWithdrawableAmount($portfolioID: ID, $assetID: ID) {
    userAccountPortfolios(where: { id: $portfolioID }) {
      edges {
        node {
          id
          assetBalances(where: { hasAssetWith: { id: $assetID } }) {
            edges {
              node {
                balances {
                  withdrawable
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetWithdrawableAmountQuery__
 *
 * To run a query within a React component, call `useGetWithdrawableAmountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWithdrawableAmountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWithdrawableAmountQuery({
 *   variables: {
 *      portfolioID: // value for 'portfolioID'
 *      assetID: // value for 'assetID'
 *   },
 * });
 */
export function useGetWithdrawableAmountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWithdrawableAmountQuery,
    GetWithdrawableAmountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWithdrawableAmountQuery, GetWithdrawableAmountQueryVariables>(
    GetWithdrawableAmountDocument,
    options,
  );
}
export function useGetWithdrawableAmountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWithdrawableAmountQuery,
    GetWithdrawableAmountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWithdrawableAmountQuery, GetWithdrawableAmountQueryVariables>(
    GetWithdrawableAmountDocument,
    options,
  );
}
export type GetWithdrawableAmountQueryHookResult = ReturnType<typeof useGetWithdrawableAmountQuery>;
export type GetWithdrawableAmountLazyQueryHookResult = ReturnType<
  typeof useGetWithdrawableAmountLazyQuery
>;
export type GetWithdrawableAmountQueryResult = Apollo.QueryResult<
  GetWithdrawableAmountQuery,
  GetWithdrawableAmountQueryVariables
>;
export const GetRecurringRulesV2Document = gql`
  query GetRecurringRulesV2($where: RecurringRuleWhereInput) {
    recurringRules(where: $where) {
      edges {
        node {
          ...RecurringRuleFragment
        }
      }
    }
  }
  ${RecurringRuleFragmentFragmentDoc}
`;

/**
 * __useGetRecurringRulesV2Query__
 *
 * To run a query within a React component, call `useGetRecurringRulesV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetRecurringRulesV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecurringRulesV2Query({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetRecurringRulesV2Query(
  baseOptions?: Apollo.QueryHookOptions<
    GetRecurringRulesV2Query,
    GetRecurringRulesV2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRecurringRulesV2Query, GetRecurringRulesV2QueryVariables>(
    GetRecurringRulesV2Document,
    options,
  );
}
export function useGetRecurringRulesV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecurringRulesV2Query,
    GetRecurringRulesV2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRecurringRulesV2Query, GetRecurringRulesV2QueryVariables>(
    GetRecurringRulesV2Document,
    options,
  );
}
export type GetRecurringRulesV2QueryHookResult = ReturnType<typeof useGetRecurringRulesV2Query>;
export type GetRecurringRulesV2LazyQueryHookResult = ReturnType<
  typeof useGetRecurringRulesV2LazyQuery
>;
export type GetRecurringRulesV2QueryResult = Apollo.QueryResult<
  GetRecurringRulesV2Query,
  GetRecurringRulesV2QueryVariables
>;
export const GetUserRetirementContributionsDocument = gql`
  query getUserRetirementContributions($year: Uint!) {
    userRetirementContributions(where: { year: $year }) {
      userID
      year
      limits {
        year
        totalLimit
        traditionalIRALimit
        rothIRALimit
      }
      totals {
        combined {
          total
          totalWithPending
        }
        traditionalIRA {
          total
          totalWithPending
        }
        rothIRA {
          total
          totalWithPending
        }
      }
    }
  }
`;

/**
 * __useGetUserRetirementContributionsQuery__
 *
 * To run a query within a React component, call `useGetUserRetirementContributionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRetirementContributionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRetirementContributionsQuery({
 *   variables: {
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetUserRetirementContributionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserRetirementContributionsQuery,
    GetUserRetirementContributionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserRetirementContributionsQuery,
    GetUserRetirementContributionsQueryVariables
  >(GetUserRetirementContributionsDocument, options);
}
export function useGetUserRetirementContributionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserRetirementContributionsQuery,
    GetUserRetirementContributionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserRetirementContributionsQuery,
    GetUserRetirementContributionsQueryVariables
  >(GetUserRetirementContributionsDocument, options);
}
export type GetUserRetirementContributionsQueryHookResult = ReturnType<
  typeof useGetUserRetirementContributionsQuery
>;
export type GetUserRetirementContributionsLazyQueryHookResult = ReturnType<
  typeof useGetUserRetirementContributionsLazyQuery
>;
export type GetUserRetirementContributionsQueryResult = Apollo.QueryResult<
  GetUserRetirementContributionsQuery,
  GetUserRetirementContributionsQueryVariables
>;
export const GetRetirementInfoDocument = gql`
  query getRetirementInfo($state: String!) {
    retirementFundamentalInfo {
      withholding {
        federalWithholding {
          rate
        }
        stateWithholding(state: $state) {
          state
          class
          rate
          rateType
        }
      }
    }
  }
`;

/**
 * __useGetRetirementInfoQuery__
 *
 * To run a query within a React component, call `useGetRetirementInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRetirementInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRetirementInfoQuery({
 *   variables: {
 *      state: // value for 'state'
 *   },
 * });
 */
export function useGetRetirementInfoQuery(
  baseOptions: Apollo.QueryHookOptions<GetRetirementInfoQuery, GetRetirementInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRetirementInfoQuery, GetRetirementInfoQueryVariables>(
    GetRetirementInfoDocument,
    options,
  );
}
export function useGetRetirementInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRetirementInfoQuery,
    GetRetirementInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRetirementInfoQuery, GetRetirementInfoQueryVariables>(
    GetRetirementInfoDocument,
    options,
  );
}
export type GetRetirementInfoQueryHookResult = ReturnType<typeof useGetRetirementInfoQuery>;
export type GetRetirementInfoLazyQueryHookResult = ReturnType<typeof useGetRetirementInfoLazyQuery>;
export type GetRetirementInfoQueryResult = Apollo.QueryResult<
  GetRetirementInfoQuery,
  GetRetirementInfoQueryVariables
>;
export const GetRoboGoalActivityHistoryDocument = gql`
  query GetRoboGoalActivityHistory(
    $id: ID!
    $types: [RoboInvestmentActionRoboActivityType!]
    $startDate: Time
    $endDate: Time
    $recentOnly: Boolean!
  ) {
    recentPages: roboInvestmentActions(
      where: { hasGoalWith: { id: $id } }
      orderBy: { direction: DESC, field: create_time }
      first: 3
    ) {
      edges {
        cursor
        ...RoboInvestmentActionEdgeFragment
      }
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
    pages: roboInvestmentActions(
      where: {
        hasGoalWith: { id: $id }
        typeIn: $types
        createTimeGTE: $startDate
        createTimeLTE: $endDate
      }
      orderBy: { direction: DESC, field: create_time }
    ) @skip(if: $recentOnly) {
      edges {
        cursor
        ...RoboInvestmentActionEdgeFragment
      }
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
  }
  ${RoboInvestmentActionEdgeFragmentFragmentDoc}
`;

/**
 * __useGetRoboGoalActivityHistoryQuery__
 *
 * To run a query within a React component, call `useGetRoboGoalActivityHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoboGoalActivityHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoboGoalActivityHistoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      types: // value for 'types'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      recentOnly: // value for 'recentOnly'
 *   },
 * });
 */
export function useGetRoboGoalActivityHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRoboGoalActivityHistoryQuery,
    GetRoboGoalActivityHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRoboGoalActivityHistoryQuery, GetRoboGoalActivityHistoryQueryVariables>(
    GetRoboGoalActivityHistoryDocument,
    options,
  );
}
export function useGetRoboGoalActivityHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRoboGoalActivityHistoryQuery,
    GetRoboGoalActivityHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRoboGoalActivityHistoryQuery,
    GetRoboGoalActivityHistoryQueryVariables
  >(GetRoboGoalActivityHistoryDocument, options);
}
export type GetRoboGoalActivityHistoryQueryHookResult = ReturnType<
  typeof useGetRoboGoalActivityHistoryQuery
>;
export type GetRoboGoalActivityHistoryLazyQueryHookResult = ReturnType<
  typeof useGetRoboGoalActivityHistoryLazyQuery
>;
export type GetRoboGoalActivityHistoryQueryResult = Apollo.QueryResult<
  GetRoboGoalActivityHistoryQuery,
  GetRoboGoalActivityHistoryQueryVariables
>;
export const GetGoalFeesDetailDocument = gql`
  query GetGoalFeesDetail($startDate: Time!, $limit: Int) {
    roboGoals {
      edges {
        node {
          id
          name
          actionsAggregation(
            range: { start: $startDate, period: TIME_PERIOD_MONTH, limit: $limit }
          ) {
            timestamps
            fees
          }
        }
      }
    }
  }
`;

/**
 * __useGetGoalFeesDetailQuery__
 *
 * To run a query within a React component, call `useGetGoalFeesDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoalFeesDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoalFeesDetailQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetGoalFeesDetailQuery(
  baseOptions: Apollo.QueryHookOptions<GetGoalFeesDetailQuery, GetGoalFeesDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGoalFeesDetailQuery, GetGoalFeesDetailQueryVariables>(
    GetGoalFeesDetailDocument,
    options,
  );
}
export function useGetGoalFeesDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGoalFeesDetailQuery,
    GetGoalFeesDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGoalFeesDetailQuery, GetGoalFeesDetailQueryVariables>(
    GetGoalFeesDetailDocument,
    options,
  );
}
export type GetGoalFeesDetailQueryHookResult = ReturnType<typeof useGetGoalFeesDetailQuery>;
export type GetGoalFeesDetailLazyQueryHookResult = ReturnType<typeof useGetGoalFeesDetailLazyQuery>;
export type GetGoalFeesDetailQueryResult = Apollo.QueryResult<
  GetGoalFeesDetailQuery,
  GetGoalFeesDetailQueryVariables
>;
export const GetGoalSummaryCardWidgetDocument = gql`
  query GetGoalSummaryCardWidget($id: ID!, $startDate: Time!) {
    roboGoals(where: { id: $id }) {
      edges {
        node {
          id
          name
          totalFees
          totalDividends
          parameters {
            initialInvestment
            targetAmount
            infusionFreq
            infusionAmount
          }
          allocation {
            totalValue
          }
          fundingAccount {
            mask
            institution {
              name
            }
          }
          actionsAggregation(range: { start: $startDate, period: TIME_PERIOD_MONTH }) {
            timestamps
            fees
          }
          infusionRecurringRule {
            lifetimeAmount
          }
        }
      }
    }
  }
`;

/**
 * __useGetGoalSummaryCardWidgetQuery__
 *
 * To run a query within a React component, call `useGetGoalSummaryCardWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoalSummaryCardWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoalSummaryCardWidgetQuery({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useGetGoalSummaryCardWidgetQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGoalSummaryCardWidgetQuery,
    GetGoalSummaryCardWidgetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGoalSummaryCardWidgetQuery, GetGoalSummaryCardWidgetQueryVariables>(
    GetGoalSummaryCardWidgetDocument,
    options,
  );
}
export function useGetGoalSummaryCardWidgetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGoalSummaryCardWidgetQuery,
    GetGoalSummaryCardWidgetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGoalSummaryCardWidgetQuery, GetGoalSummaryCardWidgetQueryVariables>(
    GetGoalSummaryCardWidgetDocument,
    options,
  );
}
export type GetGoalSummaryCardWidgetQueryHookResult = ReturnType<
  typeof useGetGoalSummaryCardWidgetQuery
>;
export type GetGoalSummaryCardWidgetLazyQueryHookResult = ReturnType<
  typeof useGetGoalSummaryCardWidgetLazyQuery
>;
export type GetGoalSummaryCardWidgetQueryResult = Apollo.QueryResult<
  GetGoalSummaryCardWidgetQuery,
  GetGoalSummaryCardWidgetQueryVariables
>;
export const GetRoboAccountPortfolioDetailsDocument = gql`
  query GetRoboAccountPortfolioDetails($id: ID!) {
    roboGoals(where: { id: $id }) {
      edges {
        node {
          id
          accountPortfolio {
            totalValue
            id
          }
          allocation {
            withdrawableValue
          }
        }
      }
    }
    roboGeneralInvestments(where: { id: $id }) {
      edges {
        node {
          id
          accountPortfolio {
            totalValue
            id
          }
          allocation {
            withdrawableValue
          }
        }
      }
    }
  }
`;

/**
 * __useGetRoboAccountPortfolioDetailsQuery__
 *
 * To run a query within a React component, call `useGetRoboAccountPortfolioDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoboAccountPortfolioDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoboAccountPortfolioDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRoboAccountPortfolioDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRoboAccountPortfolioDetailsQuery,
    GetRoboAccountPortfolioDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRoboAccountPortfolioDetailsQuery,
    GetRoboAccountPortfolioDetailsQueryVariables
  >(GetRoboAccountPortfolioDetailsDocument, options);
}
export function useGetRoboAccountPortfolioDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRoboAccountPortfolioDetailsQuery,
    GetRoboAccountPortfolioDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRoboAccountPortfolioDetailsQuery,
    GetRoboAccountPortfolioDetailsQueryVariables
  >(GetRoboAccountPortfolioDetailsDocument, options);
}
export type GetRoboAccountPortfolioDetailsQueryHookResult = ReturnType<
  typeof useGetRoboAccountPortfolioDetailsQuery
>;
export type GetRoboAccountPortfolioDetailsLazyQueryHookResult = ReturnType<
  typeof useGetRoboAccountPortfolioDetailsLazyQuery
>;
export type GetRoboAccountPortfolioDetailsQueryResult = Apollo.QueryResult<
  GetRoboAccountPortfolioDetailsQuery,
  GetRoboAccountPortfolioDetailsQueryVariables
>;
export const GetRoboStockSleeveScreenDocument = gql`
  query GetRoboStockSleeveScreen($isRobo: Boolean!, $portfolioID: ID, $roboID: ID) {
    modelPortfolios(where: { id: $portfolioID }) @skip(if: $isRobo) {
      edges {
        node {
          ...StockSleeveScreenModelPortfolioFragment
        }
      }
    }
    roboGeneralInvestments(where: { id: $roboID }) @include(if: $isRobo) {
      edges {
        node {
          id
          allocation {
            allocationBySector {
              value
              sector {
                id
              }
              assets {
                value
                asset {
                  id
                }
              }
            }
          }
          targetPortfolio {
            ...StockSleeveScreenModelPortfolioFragment
          }
        }
      }
    }
    roboGoals(where: { id: $roboID }) @include(if: $isRobo) {
      edges {
        node {
          id
          allocation {
            allocationBySector {
              value
              sector {
                id
              }
              assets {
                value
                asset {
                  id
                }
              }
            }
          }
          targetPortfolio {
            ...StockSleeveScreenModelPortfolioFragment
          }
        }
      }
    }
  }
  ${StockSleeveScreenModelPortfolioFragmentFragmentDoc}
`;

/**
 * __useGetRoboStockSleeveScreenQuery__
 *
 * To run a query within a React component, call `useGetRoboStockSleeveScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoboStockSleeveScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoboStockSleeveScreenQuery({
 *   variables: {
 *      isRobo: // value for 'isRobo'
 *      portfolioID: // value for 'portfolioID'
 *      roboID: // value for 'roboID'
 *   },
 * });
 */
export function useGetRoboStockSleeveScreenQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRoboStockSleeveScreenQuery,
    GetRoboStockSleeveScreenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRoboStockSleeveScreenQuery, GetRoboStockSleeveScreenQueryVariables>(
    GetRoboStockSleeveScreenDocument,
    options,
  );
}
export function useGetRoboStockSleeveScreenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRoboStockSleeveScreenQuery,
    GetRoboStockSleeveScreenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRoboStockSleeveScreenQuery, GetRoboStockSleeveScreenQueryVariables>(
    GetRoboStockSleeveScreenDocument,
    options,
  );
}
export type GetRoboStockSleeveScreenQueryHookResult = ReturnType<
  typeof useGetRoboStockSleeveScreenQuery
>;
export type GetRoboStockSleeveScreenLazyQueryHookResult = ReturnType<
  typeof useGetRoboStockSleeveScreenLazyQuery
>;
export type GetRoboStockSleeveScreenQueryResult = Apollo.QueryResult<
  GetRoboStockSleeveScreenQuery,
  GetRoboStockSleeveScreenQueryVariables
>;
export const GetGoalsAndGeneralInvestmentsDocument = gql`
  query GetGoalsAndGeneralInvestments {
    roboGoals {
      edges {
        node {
          id
        }
      }
    }
    roboGeneralInvestments {
      edges {
        node {
          id
        }
      }
    }
  }
`;

/**
 * __useGetGoalsAndGeneralInvestmentsQuery__
 *
 * To run a query within a React component, call `useGetGoalsAndGeneralInvestmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoalsAndGeneralInvestmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoalsAndGeneralInvestmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGoalsAndGeneralInvestmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGoalsAndGeneralInvestmentsQuery,
    GetGoalsAndGeneralInvestmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGoalsAndGeneralInvestmentsQuery,
    GetGoalsAndGeneralInvestmentsQueryVariables
  >(GetGoalsAndGeneralInvestmentsDocument, options);
}
export function useGetGoalsAndGeneralInvestmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGoalsAndGeneralInvestmentsQuery,
    GetGoalsAndGeneralInvestmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGoalsAndGeneralInvestmentsQuery,
    GetGoalsAndGeneralInvestmentsQueryVariables
  >(GetGoalsAndGeneralInvestmentsDocument, options);
}
export type GetGoalsAndGeneralInvestmentsQueryHookResult = ReturnType<
  typeof useGetGoalsAndGeneralInvestmentsQuery
>;
export type GetGoalsAndGeneralInvestmentsLazyQueryHookResult = ReturnType<
  typeof useGetGoalsAndGeneralInvestmentsLazyQuery
>;
export type GetGoalsAndGeneralInvestmentsQueryResult = Apollo.QueryResult<
  GetGoalsAndGeneralInvestmentsQuery,
  GetGoalsAndGeneralInvestmentsQueryVariables
>;
export const GetRoboGoalsScreenDocument = gql`
  query GetRoboGoalsScreen($goalUid: ID) {
    roboGoals(where: { id: $goalUid }) {
      edges {
        node {
          id
          name
          createTime
          nextScheduledInfusion
          allocation {
            fiatPct
            stockPct
            bondPct
            totalValue
            drift
          }
          projection {
            ...RoboGoalProjection
          }
          parameters {
            ...RoboGoalParameters
          }
          targetPortfolio {
            fiatPct
            stockPct
            bondPct
          }
        }
      }
    }
  }
  ${RoboGoalProjectionFragmentDoc}
  ${RoboGoalParametersFragmentDoc}
`;

/**
 * __useGetRoboGoalsScreenQuery__
 *
 * To run a query within a React component, call `useGetRoboGoalsScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoboGoalsScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoboGoalsScreenQuery({
 *   variables: {
 *      goalUid: // value for 'goalUid'
 *   },
 * });
 */
export function useGetRoboGoalsScreenQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRoboGoalsScreenQuery, GetRoboGoalsScreenQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRoboGoalsScreenQuery, GetRoboGoalsScreenQueryVariables>(
    GetRoboGoalsScreenDocument,
    options,
  );
}
export function useGetRoboGoalsScreenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRoboGoalsScreenQuery,
    GetRoboGoalsScreenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRoboGoalsScreenQuery, GetRoboGoalsScreenQueryVariables>(
    GetRoboGoalsScreenDocument,
    options,
  );
}
export type GetRoboGoalsScreenQueryHookResult = ReturnType<typeof useGetRoboGoalsScreenQuery>;
export type GetRoboGoalsScreenLazyQueryHookResult = ReturnType<
  typeof useGetRoboGoalsScreenLazyQuery
>;
export type GetRoboGoalsScreenQueryResult = Apollo.QueryResult<
  GetRoboGoalsScreenQuery,
  GetRoboGoalsScreenQueryVariables
>;
export const GetRoboEtfDetailsDocument = gql`
  query GetRoboETFDetails($id: ID, $secId: ID!) {
    securityAssets(where: { id: $secId }) {
      edges {
        node {
          id
          name
          symbol
          description
          market {
            last
            previousDayClose
          }
          details {
            ... on SecurityETFDetails {
              netAssetValue
              yield30Day
              expenseRatio
            }
          }
        }
      }
    }
    roboGoals(where: { id: $id }) {
      edges {
        node {
          allocation {
            allocation(where: { asset: { id: $secId } }) {
              asset {
                id
              }
              percentage
            }
          }
          accountPortfolio {
            assetBalances {
              edges {
                node {
                  asset {
                    id
                  }
                  balances {
                    costBasis
                    share: balance
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetRoboEtfDetailsQuery__
 *
 * To run a query within a React component, call `useGetRoboEtfDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoboEtfDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoboEtfDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      secId: // value for 'secId'
 *   },
 * });
 */
export function useGetRoboEtfDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetRoboEtfDetailsQuery, GetRoboEtfDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRoboEtfDetailsQuery, GetRoboEtfDetailsQueryVariables>(
    GetRoboEtfDetailsDocument,
    options,
  );
}
export function useGetRoboEtfDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRoboEtfDetailsQuery,
    GetRoboEtfDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRoboEtfDetailsQuery, GetRoboEtfDetailsQueryVariables>(
    GetRoboEtfDetailsDocument,
    options,
  );
}
export type GetRoboEtfDetailsQueryHookResult = ReturnType<typeof useGetRoboEtfDetailsQuery>;
export type GetRoboEtfDetailsLazyQueryHookResult = ReturnType<typeof useGetRoboEtfDetailsLazyQuery>;
export type GetRoboEtfDetailsQueryResult = Apollo.QueryResult<
  GetRoboEtfDetailsQuery,
  GetRoboEtfDetailsQueryVariables
>;
export const GetRoboEtfSectorDocument = gql`
  query GetRoboETFSector($secId: ID!) {
    securityAssets(where: { id: $secId }) {
      edges {
        node {
          details {
            ... on SecurityETFDetails {
              sectorCount
              holdingsCount
              sectors {
                name
                percentage
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetRoboEtfSectorQuery__
 *
 * To run a query within a React component, call `useGetRoboEtfSectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoboEtfSectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoboEtfSectorQuery({
 *   variables: {
 *      secId: // value for 'secId'
 *   },
 * });
 */
export function useGetRoboEtfSectorQuery(
  baseOptions: Apollo.QueryHookOptions<GetRoboEtfSectorQuery, GetRoboEtfSectorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRoboEtfSectorQuery, GetRoboEtfSectorQueryVariables>(
    GetRoboEtfSectorDocument,
    options,
  );
}
export function useGetRoboEtfSectorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRoboEtfSectorQuery, GetRoboEtfSectorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRoboEtfSectorQuery, GetRoboEtfSectorQueryVariables>(
    GetRoboEtfSectorDocument,
    options,
  );
}
export type GetRoboEtfSectorQueryHookResult = ReturnType<typeof useGetRoboEtfSectorQuery>;
export type GetRoboEtfSectorLazyQueryHookResult = ReturnType<typeof useGetRoboEtfSectorLazyQuery>;
export type GetRoboEtfSectorQueryResult = Apollo.QueryResult<
  GetRoboEtfSectorQuery,
  GetRoboEtfSectorQueryVariables
>;
export const GetRoboEtfHoldingDocument = gql`
  query GetRoboETFHolding($secId: ID!) {
    securityAssets(where: { id: $secId }) {
      edges {
        node {
          name
          details {
            ... on SecurityETFDetails {
              holdingsCount
              holdings {
                asset {
                  name
                  symbol
                }
                percentage
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetRoboEtfHoldingQuery__
 *
 * To run a query within a React component, call `useGetRoboEtfHoldingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoboEtfHoldingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoboEtfHoldingQuery({
 *   variables: {
 *      secId: // value for 'secId'
 *   },
 * });
 */
export function useGetRoboEtfHoldingQuery(
  baseOptions: Apollo.QueryHookOptions<GetRoboEtfHoldingQuery, GetRoboEtfHoldingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRoboEtfHoldingQuery, GetRoboEtfHoldingQueryVariables>(
    GetRoboEtfHoldingDocument,
    options,
  );
}
export function useGetRoboEtfHoldingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRoboEtfHoldingQuery,
    GetRoboEtfHoldingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRoboEtfHoldingQuery, GetRoboEtfHoldingQueryVariables>(
    GetRoboEtfHoldingDocument,
    options,
  );
}
export type GetRoboEtfHoldingQueryHookResult = ReturnType<typeof useGetRoboEtfHoldingQuery>;
export type GetRoboEtfHoldingLazyQueryHookResult = ReturnType<typeof useGetRoboEtfHoldingLazyQuery>;
export type GetRoboEtfHoldingQueryResult = Apollo.QueryResult<
  GetRoboEtfHoldingQuery,
  GetRoboEtfHoldingQueryVariables
>;
export const GetRoboEtfSubstitutionDocument = gql`
  query GetRoboETFSubstitution($id: ID!) {
    roboGoals(where: { id: $id }) {
      edges {
        node {
          targetPortfolio {
            availableSubstitutions {
              id
              default {
                id
                name
                symbol
                ... on CryptoAsset {
                  iconURI
                }
                ... on FiatAsset {
                  iconURI
                }
                ... on SecurityAsset {
                  iconURI
                }
              }
              substitution {
                id
                name
                symbol
                ... on CryptoAsset {
                  iconURI
                }
                ... on FiatAsset {
                  iconURI
                }
                ... on SecurityAsset {
                  iconURI
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetRoboEtfSubstitutionQuery__
 *
 * To run a query within a React component, call `useGetRoboEtfSubstitutionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoboEtfSubstitutionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoboEtfSubstitutionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRoboEtfSubstitutionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRoboEtfSubstitutionQuery,
    GetRoboEtfSubstitutionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRoboEtfSubstitutionQuery, GetRoboEtfSubstitutionQueryVariables>(
    GetRoboEtfSubstitutionDocument,
    options,
  );
}
export function useGetRoboEtfSubstitutionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRoboEtfSubstitutionQuery,
    GetRoboEtfSubstitutionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRoboEtfSubstitutionQuery, GetRoboEtfSubstitutionQueryVariables>(
    GetRoboEtfSubstitutionDocument,
    options,
  );
}
export type GetRoboEtfSubstitutionQueryHookResult = ReturnType<
  typeof useGetRoboEtfSubstitutionQuery
>;
export type GetRoboEtfSubstitutionLazyQueryHookResult = ReturnType<
  typeof useGetRoboEtfSubstitutionLazyQuery
>;
export type GetRoboEtfSubstitutionQueryResult = Apollo.QueryResult<
  GetRoboEtfSubstitutionQuery,
  GetRoboEtfSubstitutionQueryVariables
>;
export const GetRoboEtfDistributionsDocument = gql`
  query GetRoboETFDistributions($id: ID!) {
    securityAssets(where: { id: $id }) {
      edges {
        node {
          id
          name
          symbol
          financials {
            dividends {
              cashAmount
              frequency
              payDate
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetRoboEtfDistributionsQuery__
 *
 * To run a query within a React component, call `useGetRoboEtfDistributionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoboEtfDistributionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoboEtfDistributionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRoboEtfDistributionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRoboEtfDistributionsQuery,
    GetRoboEtfDistributionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRoboEtfDistributionsQuery, GetRoboEtfDistributionsQueryVariables>(
    GetRoboEtfDistributionsDocument,
    options,
  );
}
export function useGetRoboEtfDistributionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRoboEtfDistributionsQuery,
    GetRoboEtfDistributionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRoboEtfDistributionsQuery, GetRoboEtfDistributionsQueryVariables>(
    GetRoboEtfDistributionsDocument,
    options,
  );
}
export type GetRoboEtfDistributionsQueryHookResult = ReturnType<
  typeof useGetRoboEtfDistributionsQuery
>;
export type GetRoboEtfDistributionsLazyQueryHookResult = ReturnType<
  typeof useGetRoboEtfDistributionsLazyQuery
>;
export type GetRoboEtfDistributionsQueryResult = Apollo.QueryResult<
  GetRoboEtfDistributionsQuery,
  GetRoboEtfDistributionsQueryVariables
>;
export const GetAllRoboDetailsDocument = gql`
  query GetAllRoboDetails {
    users {
      edges {
        node {
          ...UserRoboGoalDetail
        }
      }
    }
  }
  ${UserRoboGoalDetailFragmentDoc}
`;

/**
 * __useGetAllRoboDetailsQuery__
 *
 * To run a query within a React component, call `useGetAllRoboDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRoboDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRoboDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRoboDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllRoboDetailsQuery, GetAllRoboDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllRoboDetailsQuery, GetAllRoboDetailsQueryVariables>(
    GetAllRoboDetailsDocument,
    options,
  );
}
export function useGetAllRoboDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllRoboDetailsQuery,
    GetAllRoboDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllRoboDetailsQuery, GetAllRoboDetailsQueryVariables>(
    GetAllRoboDetailsDocument,
    options,
  );
}
export type GetAllRoboDetailsQueryHookResult = ReturnType<typeof useGetAllRoboDetailsQuery>;
export type GetAllRoboDetailsLazyQueryHookResult = ReturnType<typeof useGetAllRoboDetailsLazyQuery>;
export type GetAllRoboDetailsQueryResult = Apollo.QueryResult<
  GetAllRoboDetailsQuery,
  GetAllRoboDetailsQueryVariables
>;
export const GetRoboGoalsV2Document = gql`
  query GetRoboGoalsV2($where: RoboGoalWhereInput) {
    roboGoals(where: $where) {
      edges {
        node {
          id
          targetPortfolioID
          accountPortfolioID
          name
          isActive
          nextScheduledInfusion
          accountPortfolio {
            totalValue
            account {
              id
              externalID
            }
          }
          isAutoInfusionPaused
          allocation {
            totalValue
            stockPct
            bondPct
            fiatPct
          }
          parameters {
            targetAmount
            riskProfile
            goalPriority
            initialInvestment
            infusionAmount
            infusionFreq
            endDate
          }
          projection {
            goalProbability
          }
          accountPortfolio {
            id
            name
            totalValue
          }
          fundingAccount {
            accountNumber
          }
        }
      }
    }
  }
`;

/**
 * __useGetRoboGoalsV2Query__
 *
 * To run a query within a React component, call `useGetRoboGoalsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetRoboGoalsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoboGoalsV2Query({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetRoboGoalsV2Query(
  baseOptions?: Apollo.QueryHookOptions<GetRoboGoalsV2Query, GetRoboGoalsV2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRoboGoalsV2Query, GetRoboGoalsV2QueryVariables>(
    GetRoboGoalsV2Document,
    options,
  );
}
export function useGetRoboGoalsV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRoboGoalsV2Query, GetRoboGoalsV2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRoboGoalsV2Query, GetRoboGoalsV2QueryVariables>(
    GetRoboGoalsV2Document,
    options,
  );
}
export type GetRoboGoalsV2QueryHookResult = ReturnType<typeof useGetRoboGoalsV2Query>;
export type GetRoboGoalsV2LazyQueryHookResult = ReturnType<typeof useGetRoboGoalsV2LazyQuery>;
export type GetRoboGoalsV2QueryResult = Apollo.QueryResult<
  GetRoboGoalsV2Query,
  GetRoboGoalsV2QueryVariables
>;
export const GetRoboKycDocument = gql`
  query GetRoboKyc {
    userAccounts(where: { type: ACCOUNT_TYPE_BROKERAGE_DISCRETIONARY }) {
      edges {
        node {
          type
          discretionaryAccountKyc {
            riskTolerance
            employmentStatus
            employmentDetails {
              EmployerName
              Position
            }
            investmentObjective
            annualIncome
            netWorth
          }
        }
      }
    }
  }
`;

/**
 * __useGetRoboKycQuery__
 *
 * To run a query within a React component, call `useGetRoboKycQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoboKycQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoboKycQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRoboKycQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRoboKycQuery, GetRoboKycQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRoboKycQuery, GetRoboKycQueryVariables>(GetRoboKycDocument, options);
}
export function useGetRoboKycLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRoboKycQuery, GetRoboKycQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRoboKycQuery, GetRoboKycQueryVariables>(
    GetRoboKycDocument,
    options,
  );
}
export type GetRoboKycQueryHookResult = ReturnType<typeof useGetRoboKycQuery>;
export type GetRoboKycLazyQueryHookResult = ReturnType<typeof useGetRoboKycLazyQuery>;
export type GetRoboKycQueryResult = Apollo.QueryResult<GetRoboKycQuery, GetRoboKycQueryVariables>;
export const GetSubstitutionsDocument = gql`
  query GetSubstitutions {
    modelPortfolios {
      edges {
        node {
          id
          availableSubstitutions {
            id
            createTime
            default {
              id
              name
              symbol
              ... on CryptoAsset {
                iconURI
              }
              ... on FiatAsset {
                iconURI
              }
              ... on SecurityAsset {
                iconURI
              }
            }
            substitution {
              id
              name
              symbol
              ... on CryptoAsset {
                iconURI
              }
              ... on FiatAsset {
                iconURI
              }
              ... on SecurityAsset {
                iconURI
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetSubstitutionsQuery__
 *
 * To run a query within a React component, call `useGetSubstitutionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubstitutionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubstitutionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubstitutionsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSubstitutionsQuery, GetSubstitutionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSubstitutionsQuery, GetSubstitutionsQueryVariables>(
    GetSubstitutionsDocument,
    options,
  );
}
export function useGetSubstitutionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSubstitutionsQuery, GetSubstitutionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSubstitutionsQuery, GetSubstitutionsQueryVariables>(
    GetSubstitutionsDocument,
    options,
  );
}
export type GetSubstitutionsQueryHookResult = ReturnType<typeof useGetSubstitutionsQuery>;
export type GetSubstitutionsLazyQueryHookResult = ReturnType<typeof useGetSubstitutionsLazyQuery>;
export type GetSubstitutionsQueryResult = Apollo.QueryResult<
  GetSubstitutionsQuery,
  GetSubstitutionsQueryVariables
>;
export const GetRtqPortfoliosDocument = gql`
  query GetRTQPortfolios {
    rtqPortfolioLevelSets {
      edges {
        node {
          id
          ...CommonRTQPortfolioLevel
        }
      }
    }
  }
  ${CommonRtqPortfolioLevelFragmentDoc}
`;

/**
 * __useGetRtqPortfoliosQuery__
 *
 * To run a query within a React component, call `useGetRtqPortfoliosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRtqPortfoliosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRtqPortfoliosQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRtqPortfoliosQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRtqPortfoliosQuery, GetRtqPortfoliosQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRtqPortfoliosQuery, GetRtqPortfoliosQueryVariables>(
    GetRtqPortfoliosDocument,
    options,
  );
}
export function useGetRtqPortfoliosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRtqPortfoliosQuery, GetRtqPortfoliosQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRtqPortfoliosQuery, GetRtqPortfoliosQueryVariables>(
    GetRtqPortfoliosDocument,
    options,
  );
}
export type GetRtqPortfoliosQueryHookResult = ReturnType<typeof useGetRtqPortfoliosQuery>;
export type GetRtqPortfoliosLazyQueryHookResult = ReturnType<typeof useGetRtqPortfoliosLazyQuery>;
export type GetRtqPortfoliosQueryResult = Apollo.QueryResult<
  GetRtqPortfoliosQuery,
  GetRtqPortfoliosQueryVariables
>;
export const GetRtqQuestionnaireDocument = gql`
  query GetRTQQuestionnaire {
    rtqQuestionnaires {
      edges {
        node {
          id
          questions {
            ...RTQQuestions
          }
          portfolioLevelScores {
            ...RTQPortfolioLevelScores
          }
        }
      }
    }
  }
  ${RtqQuestionsFragmentDoc}
  ${RtqPortfolioLevelScoresFragmentDoc}
`;

/**
 * __useGetRtqQuestionnaireQuery__
 *
 * To run a query within a React component, call `useGetRtqQuestionnaireQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRtqQuestionnaireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRtqQuestionnaireQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRtqQuestionnaireQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRtqQuestionnaireQuery,
    GetRtqQuestionnaireQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRtqQuestionnaireQuery, GetRtqQuestionnaireQueryVariables>(
    GetRtqQuestionnaireDocument,
    options,
  );
}
export function useGetRtqQuestionnaireLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRtqQuestionnaireQuery,
    GetRtqQuestionnaireQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRtqQuestionnaireQuery, GetRtqQuestionnaireQueryVariables>(
    GetRtqQuestionnaireDocument,
    options,
  );
}
export type GetRtqQuestionnaireQueryHookResult = ReturnType<typeof useGetRtqQuestionnaireQuery>;
export type GetRtqQuestionnaireLazyQueryHookResult = ReturnType<
  typeof useGetRtqQuestionnaireLazyQuery
>;
export type GetRtqQuestionnaireQueryResult = Apollo.QueryResult<
  GetRtqQuestionnaireQuery,
  GetRtqQuestionnaireQueryVariables
>;
export const GetActiveRtqSubmissionByUserIdDocument = gql`
  query GetActiveRtqSubmissionByUserId($userId: ID!) {
    rtqSubmissions(where: { isActive: true, hasUserWith: [{ id: $userId }] }) {
      edges {
        node {
          responses {
            question {
              id
            }
            option {
              id
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetActiveRtqSubmissionByUserIdQuery__
 *
 * To run a query within a React component, call `useGetActiveRtqSubmissionByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveRtqSubmissionByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveRtqSubmissionByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetActiveRtqSubmissionByUserIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActiveRtqSubmissionByUserIdQuery,
    GetActiveRtqSubmissionByUserIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActiveRtqSubmissionByUserIdQuery,
    GetActiveRtqSubmissionByUserIdQueryVariables
  >(GetActiveRtqSubmissionByUserIdDocument, options);
}
export function useGetActiveRtqSubmissionByUserIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActiveRtqSubmissionByUserIdQuery,
    GetActiveRtqSubmissionByUserIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActiveRtqSubmissionByUserIdQuery,
    GetActiveRtqSubmissionByUserIdQueryVariables
  >(GetActiveRtqSubmissionByUserIdDocument, options);
}
export type GetActiveRtqSubmissionByUserIdQueryHookResult = ReturnType<
  typeof useGetActiveRtqSubmissionByUserIdQuery
>;
export type GetActiveRtqSubmissionByUserIdLazyQueryHookResult = ReturnType<
  typeof useGetActiveRtqSubmissionByUserIdLazyQuery
>;
export type GetActiveRtqSubmissionByUserIdQueryResult = Apollo.QueryResult<
  GetActiveRtqSubmissionByUserIdQuery,
  GetActiveRtqSubmissionByUserIdQueryVariables
>;
export const SecurityAssetBasicDetailsQueryDocument = gql`
  query SecurityAssetBasicDetailsQuery($id: ID, $symbol: String) {
    securityAssets(where: { or: { id: $id, symbol: $symbol } }) {
      edges {
        node {
          ...SecurityAssetBasicDetails
        }
      }
    }
  }
  ${SecurityAssetBasicDetailsFragmentDoc}
`;

/**
 * __useSecurityAssetBasicDetailsQueryQuery__
 *
 * To run a query within a React component, call `useSecurityAssetBasicDetailsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecurityAssetBasicDetailsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecurityAssetBasicDetailsQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      symbol: // value for 'symbol'
 *   },
 * });
 */
export function useSecurityAssetBasicDetailsQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SecurityAssetBasicDetailsQueryQuery,
    SecurityAssetBasicDetailsQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SecurityAssetBasicDetailsQueryQuery,
    SecurityAssetBasicDetailsQueryQueryVariables
  >(SecurityAssetBasicDetailsQueryDocument, options);
}
export function useSecurityAssetBasicDetailsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SecurityAssetBasicDetailsQueryQuery,
    SecurityAssetBasicDetailsQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SecurityAssetBasicDetailsQueryQuery,
    SecurityAssetBasicDetailsQueryQueryVariables
  >(SecurityAssetBasicDetailsQueryDocument, options);
}
export type SecurityAssetBasicDetailsQueryQueryHookResult = ReturnType<
  typeof useSecurityAssetBasicDetailsQueryQuery
>;
export type SecurityAssetBasicDetailsQueryLazyQueryHookResult = ReturnType<
  typeof useSecurityAssetBasicDetailsQueryLazyQuery
>;
export type SecurityAssetBasicDetailsQueryQueryResult = Apollo.QueryResult<
  SecurityAssetBasicDetailsQueryQuery,
  SecurityAssetBasicDetailsQueryQueryVariables
>;
export const SecurityAssetDetailsDocument = gql`
  query SecurityAssetDetails($securityId: ID!) {
    securityAssets(where: { id: $securityId }) {
      edges {
        node {
          ...SecurityAssetDetailedData
        }
      }
    }
  }
  ${SecurityAssetDetailedDataFragmentDoc}
`;

/**
 * __useSecurityAssetDetailsQuery__
 *
 * To run a query within a React component, call `useSecurityAssetDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecurityAssetDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecurityAssetDetailsQuery({
 *   variables: {
 *      securityId: // value for 'securityId'
 *   },
 * });
 */
export function useSecurityAssetDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SecurityAssetDetailsQuery,
    SecurityAssetDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SecurityAssetDetailsQuery, SecurityAssetDetailsQueryVariables>(
    SecurityAssetDetailsDocument,
    options,
  );
}
export function useSecurityAssetDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SecurityAssetDetailsQuery,
    SecurityAssetDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SecurityAssetDetailsQuery, SecurityAssetDetailsQueryVariables>(
    SecurityAssetDetailsDocument,
    options,
  );
}
export type SecurityAssetDetailsQueryHookResult = ReturnType<typeof useSecurityAssetDetailsQuery>;
export type SecurityAssetDetailsLazyQueryHookResult = ReturnType<
  typeof useSecurityAssetDetailsLazyQuery
>;
export type SecurityAssetDetailsQueryResult = Apollo.QueryResult<
  SecurityAssetDetailsQuery,
  SecurityAssetDetailsQueryVariables
>;
export const GetSecurityTradingDataDocument = gql`
  query GetSecurityTradingData($balanceIds: [ID!], $portfolioId: ID!, $securityId: ID!) {
    securityAssets(where: { id: $securityId }) {
      edges {
        node {
          securityType
          ...SecurityAssetBasicDetails
          details {
            ... on SecurityMutualFundDetails {
              transactionFee
            }
          }
        }
      }
    }
    userAccountPortfolioAssets(
      where: { hasAssetWith: { idIn: $balanceIds }, hasPortfolioWith: { id: $portfolioId } }
    ) {
      edges {
        node {
          id
          asset {
            id
            name
          }
          balances {
            balance
            settledBalance
            pendingBalance
            tradeable
          }
        }
      }
    }
  }
  ${SecurityAssetBasicDetailsFragmentDoc}
`;

/**
 * __useGetSecurityTradingDataQuery__
 *
 * To run a query within a React component, call `useGetSecurityTradingDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSecurityTradingDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSecurityTradingDataQuery({
 *   variables: {
 *      balanceIds: // value for 'balanceIds'
 *      portfolioId: // value for 'portfolioId'
 *      securityId: // value for 'securityId'
 *   },
 * });
 */
export function useGetSecurityTradingDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSecurityTradingDataQuery,
    GetSecurityTradingDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSecurityTradingDataQuery, GetSecurityTradingDataQueryVariables>(
    GetSecurityTradingDataDocument,
    options,
  );
}
export function useGetSecurityTradingDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSecurityTradingDataQuery,
    GetSecurityTradingDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSecurityTradingDataQuery, GetSecurityTradingDataQueryVariables>(
    GetSecurityTradingDataDocument,
    options,
  );
}
export type GetSecurityTradingDataQueryHookResult = ReturnType<
  typeof useGetSecurityTradingDataQuery
>;
export type GetSecurityTradingDataLazyQueryHookResult = ReturnType<
  typeof useGetSecurityTradingDataLazyQuery
>;
export type GetSecurityTradingDataQueryResult = Apollo.QueryResult<
  GetSecurityTradingDataQuery,
  GetSecurityTradingDataQueryVariables
>;
export const GetSecurityForUserDocument = gql`
  query GetSecurityForUser($securityID: ID!, $accountID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          ...SecurityAssetBasicDetails
          sector {
            id
            name
          }
          financials {
            dividends(limit: 1) {
              cashAmount
            }
          }
        }
      }
    }
    userAccountPortfolios(where: { hasAccountWith: { id: $accountID } }) {
      edges {
        node {
          id
          totalValue
          assetBalances(where: { hasAssetWith: { id: $securityID } }) {
            edges {
              node {
                costBasis
                balances {
                  activeBalance
                }
              }
            }
          }
        }
      }
    }
    dividendDistributions(
      where: { hasPortfolioWith: { hasAccountWith: { id: $accountID } } }
      orderBy: { direction: DESC, field: create_time }
    ) {
      edges {
        node {
          amount
          distributionAsset {
            id
          }
        }
      }
    }
  }
  ${SecurityAssetBasicDetailsFragmentDoc}
`;

/**
 * __useGetSecurityForUserQuery__
 *
 * To run a query within a React component, call `useGetSecurityForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSecurityForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSecurityForUserQuery({
 *   variables: {
 *      securityID: // value for 'securityID'
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useGetSecurityForUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetSecurityForUserQuery, GetSecurityForUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSecurityForUserQuery, GetSecurityForUserQueryVariables>(
    GetSecurityForUserDocument,
    options,
  );
}
export function useGetSecurityForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSecurityForUserQuery,
    GetSecurityForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSecurityForUserQuery, GetSecurityForUserQueryVariables>(
    GetSecurityForUserDocument,
    options,
  );
}
export type GetSecurityForUserQueryHookResult = ReturnType<typeof useGetSecurityForUserQuery>;
export type GetSecurityForUserLazyQueryHookResult = ReturnType<
  typeof useGetSecurityForUserLazyQuery
>;
export type GetSecurityForUserQueryResult = Apollo.QueryResult<
  GetSecurityForUserQuery,
  GetSecurityForUserQueryVariables
>;
export const GetStockPriceDataDocument = gql`
  query GetStockPriceData($securityId: ID!, $period: TimePeriod!, $start: Time!, $end: Time) {
    securityAssets(where: { id: $securityId }) {
      edges {
        node {
          id
          symbol
          market {
            previousDayClose
            last
            historical(range: { start: $start, period: $period, end: $end }) {
              start
              close
              avgPrice
              timestamps
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetStockPriceDataQuery__
 *
 * To run a query within a React component, call `useGetStockPriceDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockPriceDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockPriceDataQuery({
 *   variables: {
 *      securityId: // value for 'securityId'
 *      period: // value for 'period'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetStockPriceDataQuery(
  baseOptions: Apollo.QueryHookOptions<GetStockPriceDataQuery, GetStockPriceDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStockPriceDataQuery, GetStockPriceDataQueryVariables>(
    GetStockPriceDataDocument,
    options,
  );
}
export function useGetStockPriceDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStockPriceDataQuery,
    GetStockPriceDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStockPriceDataQuery, GetStockPriceDataQueryVariables>(
    GetStockPriceDataDocument,
    options,
  );
}
export type GetStockPriceDataQueryHookResult = ReturnType<typeof useGetStockPriceDataQuery>;
export type GetStockPriceDataLazyQueryHookResult = ReturnType<typeof useGetStockPriceDataLazyQuery>;
export type GetStockPriceDataQueryResult = Apollo.QueryResult<
  GetStockPriceDataQuery,
  GetStockPriceDataQueryVariables
>;
export const GetUserHasSharesDocument = gql`
  query GetUserHasShares($securityID: ID!) {
    userAccountPortfolioAssets(
      where: { hideZeroBalance: true, hasAssetWith: { id: $securityID } }
    ) {
      edges {
        node {
          balances {
            activeBalance
          }
        }
      }
    }
  }
`;

/**
 * __useGetUserHasSharesQuery__
 *
 * To run a query within a React component, call `useGetUserHasSharesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserHasSharesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserHasSharesQuery({
 *   variables: {
 *      securityID: // value for 'securityID'
 *   },
 * });
 */
export function useGetUserHasSharesQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserHasSharesQuery, GetUserHasSharesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserHasSharesQuery, GetUserHasSharesQueryVariables>(
    GetUserHasSharesDocument,
    options,
  );
}
export function useGetUserHasSharesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserHasSharesQuery, GetUserHasSharesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserHasSharesQuery, GetUserHasSharesQueryVariables>(
    GetUserHasSharesDocument,
    options,
  );
}
export type GetUserHasSharesQueryHookResult = ReturnType<typeof useGetUserHasSharesQuery>;
export type GetUserHasSharesLazyQueryHookResult = ReturnType<typeof useGetUserHasSharesLazyQuery>;
export type GetUserHasSharesQueryResult = Apollo.QueryResult<
  GetUserHasSharesQuery,
  GetUserHasSharesQueryVariables
>;
export const GetSecuritySectorsChartDocument = gql`
  query GetSecuritySectorsChart($securityID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          details {
            ... on SecurityETFDetails {
              sectorCount
              sectors {
                name
                percentage
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetSecuritySectorsChartQuery__
 *
 * To run a query within a React component, call `useGetSecuritySectorsChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSecuritySectorsChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSecuritySectorsChartQuery({
 *   variables: {
 *      securityID: // value for 'securityID'
 *   },
 * });
 */
export function useGetSecuritySectorsChartQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSecuritySectorsChartQuery,
    GetSecuritySectorsChartQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSecuritySectorsChartQuery, GetSecuritySectorsChartQueryVariables>(
    GetSecuritySectorsChartDocument,
    options,
  );
}
export function useGetSecuritySectorsChartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSecuritySectorsChartQuery,
    GetSecuritySectorsChartQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSecuritySectorsChartQuery, GetSecuritySectorsChartQueryVariables>(
    GetSecuritySectorsChartDocument,
    options,
  );
}
export type GetSecuritySectorsChartQueryHookResult = ReturnType<
  typeof useGetSecuritySectorsChartQuery
>;
export type GetSecuritySectorsChartLazyQueryHookResult = ReturnType<
  typeof useGetSecuritySectorsChartLazyQuery
>;
export type GetSecuritySectorsChartQueryResult = Apollo.QueryResult<
  GetSecuritySectorsChartQuery,
  GetSecuritySectorsChartQueryVariables
>;
export const GetSecurityTop10HoldingsChartDocument = gql`
  query GetSecurityTop10HoldingsChart($securityId: ID!) {
    securityAssets(where: { id: $securityId }) {
      edges {
        node {
          name
          details {
            ... on SecurityETFDetails {
              __typename
              holdings {
                asset {
                  name
                  symbol
                }
                percentage
              }
            }
            ... on SecurityMutualFundDetails {
              __typename
              holdings {
                asset {
                  name
                  symbol
                }
                percentage
              }
            }
            ... on SecurityMutualFundDetails {
              holdings {
                asset {
                  name
                  symbol
                }
                percentage
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetSecurityTop10HoldingsChartQuery__
 *
 * To run a query within a React component, call `useGetSecurityTop10HoldingsChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSecurityTop10HoldingsChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSecurityTop10HoldingsChartQuery({
 *   variables: {
 *      securityId: // value for 'securityId'
 *   },
 * });
 */
export function useGetSecurityTop10HoldingsChartQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSecurityTop10HoldingsChartQuery,
    GetSecurityTop10HoldingsChartQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSecurityTop10HoldingsChartQuery,
    GetSecurityTop10HoldingsChartQueryVariables
  >(GetSecurityTop10HoldingsChartDocument, options);
}
export function useGetSecurityTop10HoldingsChartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSecurityTop10HoldingsChartQuery,
    GetSecurityTop10HoldingsChartQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSecurityTop10HoldingsChartQuery,
    GetSecurityTop10HoldingsChartQueryVariables
  >(GetSecurityTop10HoldingsChartDocument, options);
}
export type GetSecurityTop10HoldingsChartQueryHookResult = ReturnType<
  typeof useGetSecurityTop10HoldingsChartQuery
>;
export type GetSecurityTop10HoldingsChartLazyQueryHookResult = ReturnType<
  typeof useGetSecurityTop10HoldingsChartLazyQuery
>;
export type GetSecurityTop10HoldingsChartQueryResult = Apollo.QueryResult<
  GetSecurityTop10HoldingsChartQuery,
  GetSecurityTop10HoldingsChartQueryVariables
>;
export const GetMfSecurityBuyScreenInformationDocument = gql`
  query GetMFSecurityBuyScreenInformation($securityID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          id
          details {
            __typename
            ... on SecurityMutualFundDetails {
              additionalInvestmentMinimum
              distributionServiceFee
              expenseRatioGross
              expenseRatioNet
              initialInvestmentMinimum
              loadPct
              loadType
              transactionFee
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetMfSecurityBuyScreenInformationQuery__
 *
 * To run a query within a React component, call `useGetMfSecurityBuyScreenInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMfSecurityBuyScreenInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMfSecurityBuyScreenInformationQuery({
 *   variables: {
 *      securityID: // value for 'securityID'
 *   },
 * });
 */
export function useGetMfSecurityBuyScreenInformationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMfSecurityBuyScreenInformationQuery,
    GetMfSecurityBuyScreenInformationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMfSecurityBuyScreenInformationQuery,
    GetMfSecurityBuyScreenInformationQueryVariables
  >(GetMfSecurityBuyScreenInformationDocument, options);
}
export function useGetMfSecurityBuyScreenInformationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMfSecurityBuyScreenInformationQuery,
    GetMfSecurityBuyScreenInformationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMfSecurityBuyScreenInformationQuery,
    GetMfSecurityBuyScreenInformationQueryVariables
  >(GetMfSecurityBuyScreenInformationDocument, options);
}
export type GetMfSecurityBuyScreenInformationQueryHookResult = ReturnType<
  typeof useGetMfSecurityBuyScreenInformationQuery
>;
export type GetMfSecurityBuyScreenInformationLazyQueryHookResult = ReturnType<
  typeof useGetMfSecurityBuyScreenInformationLazyQuery
>;
export type GetMfSecurityBuyScreenInformationQueryResult = Apollo.QueryResult<
  GetMfSecurityBuyScreenInformationQuery,
  GetMfSecurityBuyScreenInformationQueryVariables
>;
export const GetMutualFundDetailsDocument = gql`
  query GetMutualFundDetails($securityId: ID!) {
    securityAssets(where: { id: $securityId }) {
      edges {
        node {
          id
          details {
            __typename
            ... on SecurityMutualFundDetails {
              additionalInvestmentMinimum
              distributionServiceFee
              expenseRatioGross
              expenseRatioNet
              initialInvestmentMinimum
              loadPct
              loadType
              transactionFee
              additionalGroupRetirementInvestmentMinimum
              additionalInvestmentMinimum
              additionalIraInvestmentMinimum
              annualizedStdDev
              contractualExpenseWaiver
              distributionServiceFee
              expenseRatioGross
              expenseRatioNet
              feeClass
              fundCategory
              fundCompany
              fundInception
              initialGroupRetirementInvestmentMinimum
              initialInvestmentMinimum
              initialIraInvestmentMinimum
              loadPct
              loadType
              mutualFundType
              netAssetValue
              netAssetValueChange
              portfolioNetAssetValue
              rSquared
              shareClassNetAssetValue
              sharpeRatio
              transactionFee
              turnoverRate
              twelveMonthHighValue
              twelveMonthLowValue
              yearToDateReturns
              yield30Day
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetMutualFundDetailsQuery__
 *
 * To run a query within a React component, call `useGetMutualFundDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMutualFundDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMutualFundDetailsQuery({
 *   variables: {
 *      securityId: // value for 'securityId'
 *   },
 * });
 */
export function useGetMutualFundDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMutualFundDetailsQuery,
    GetMutualFundDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMutualFundDetailsQuery, GetMutualFundDetailsQueryVariables>(
    GetMutualFundDetailsDocument,
    options,
  );
}
export function useGetMutualFundDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMutualFundDetailsQuery,
    GetMutualFundDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMutualFundDetailsQuery, GetMutualFundDetailsQueryVariables>(
    GetMutualFundDetailsDocument,
    options,
  );
}
export type GetMutualFundDetailsQueryHookResult = ReturnType<typeof useGetMutualFundDetailsQuery>;
export type GetMutualFundDetailsLazyQueryHookResult = ReturnType<
  typeof useGetMutualFundDetailsLazyQuery
>;
export type GetMutualFundDetailsQueryResult = Apollo.QueryResult<
  GetMutualFundDetailsQuery,
  GetMutualFundDetailsQueryVariables
>;
export const GetMutualFundPerformanceDocument = gql`
  query GetMutualFundPerformance($securityId: ID!) {
    securityAssets(where: { id: $securityId }) {
      edges {
        node {
          id
          details {
            __typename
            ... on SecurityMutualFundDetails {
              oneYearTotalReturn
              threeYearTotalReturn
              fiveYearTotalReturn
              tenYearTotalReturn
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetMutualFundPerformanceQuery__
 *
 * To run a query within a React component, call `useGetMutualFundPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMutualFundPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMutualFundPerformanceQuery({
 *   variables: {
 *      securityId: // value for 'securityId'
 *   },
 * });
 */
export function useGetMutualFundPerformanceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMutualFundPerformanceQuery,
    GetMutualFundPerformanceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMutualFundPerformanceQuery, GetMutualFundPerformanceQueryVariables>(
    GetMutualFundPerformanceDocument,
    options,
  );
}
export function useGetMutualFundPerformanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMutualFundPerformanceQuery,
    GetMutualFundPerformanceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMutualFundPerformanceQuery, GetMutualFundPerformanceQueryVariables>(
    GetMutualFundPerformanceDocument,
    options,
  );
}
export type GetMutualFundPerformanceQueryHookResult = ReturnType<
  typeof useGetMutualFundPerformanceQuery
>;
export type GetMutualFundPerformanceLazyQueryHookResult = ReturnType<
  typeof useGetMutualFundPerformanceLazyQuery
>;
export type GetMutualFundPerformanceQueryResult = Apollo.QueryResult<
  GetMutualFundPerformanceQuery,
  GetMutualFundPerformanceQueryVariables
>;
export const GetSecurityIndexScreenDocument = gql`
  query GetSecurityIndexScreen($securityID: ID, $securitySymbol: String) {
    securityAssets(where: { or: { id: $securityID, symbol: $securitySymbol } }) {
      edges {
        node {
          id
          symbol
          securityType
        }
      }
    }
  }
`;

/**
 * __useGetSecurityIndexScreenQuery__
 *
 * To run a query within a React component, call `useGetSecurityIndexScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSecurityIndexScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSecurityIndexScreenQuery({
 *   variables: {
 *      securityID: // value for 'securityID'
 *      securitySymbol: // value for 'securitySymbol'
 *   },
 * });
 */
export function useGetSecurityIndexScreenQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSecurityIndexScreenQuery,
    GetSecurityIndexScreenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSecurityIndexScreenQuery, GetSecurityIndexScreenQueryVariables>(
    GetSecurityIndexScreenDocument,
    options,
  );
}
export function useGetSecurityIndexScreenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSecurityIndexScreenQuery,
    GetSecurityIndexScreenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSecurityIndexScreenQuery, GetSecurityIndexScreenQueryVariables>(
    GetSecurityIndexScreenDocument,
    options,
  );
}
export type GetSecurityIndexScreenQueryHookResult = ReturnType<
  typeof useGetSecurityIndexScreenQuery
>;
export type GetSecurityIndexScreenLazyQueryHookResult = ReturnType<
  typeof useGetSecurityIndexScreenLazyQuery
>;
export type GetSecurityIndexScreenQueryResult = Apollo.QueryResult<
  GetSecurityIndexScreenQuery,
  GetSecurityIndexScreenQueryVariables
>;
export const GetSecurityHeaderSectionDocument = gql`
  query GetSecurityHeaderSection(
    $securityID: ID!
    $accountID: ID
    $shouldCheckActiveBalance: Boolean!
  ) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          id
          symbol
          name
          logoURI
          securityType
          market {
            last
          }
        }
      }
    }
    userAccounts(where: { id: $accountID }) @include(if: $shouldCheckActiveBalance) {
      edges {
        node {
          balances(where: { hasAssetWith: { id: $securityID } }) {
            activeBalance
          }
        }
      }
    }
  }
`;

/**
 * __useGetSecurityHeaderSectionQuery__
 *
 * To run a query within a React component, call `useGetSecurityHeaderSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSecurityHeaderSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSecurityHeaderSectionQuery({
 *   variables: {
 *      securityID: // value for 'securityID'
 *      accountID: // value for 'accountID'
 *      shouldCheckActiveBalance: // value for 'shouldCheckActiveBalance'
 *   },
 * });
 */
export function useGetSecurityHeaderSectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSecurityHeaderSectionQuery,
    GetSecurityHeaderSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSecurityHeaderSectionQuery, GetSecurityHeaderSectionQueryVariables>(
    GetSecurityHeaderSectionDocument,
    options,
  );
}
export function useGetSecurityHeaderSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSecurityHeaderSectionQuery,
    GetSecurityHeaderSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSecurityHeaderSectionQuery, GetSecurityHeaderSectionQueryVariables>(
    GetSecurityHeaderSectionDocument,
    options,
  );
}
export type GetSecurityHeaderSectionQueryHookResult = ReturnType<
  typeof useGetSecurityHeaderSectionQuery
>;
export type GetSecurityHeaderSectionLazyQueryHookResult = ReturnType<
  typeof useGetSecurityHeaderSectionLazyQuery
>;
export type GetSecurityHeaderSectionQueryResult = Apollo.QueryResult<
  GetSecurityHeaderSectionQuery,
  GetSecurityHeaderSectionQueryVariables
>;
export const GetSecurityMyHoldingSectionDocument = gql`
  query GetSecurityMyHoldingSection($securityID: ID!, $accountID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          id
          market {
            last
            previousDayClose
          }
          financials {
            dividends(limit: 1) {
              cashAmount
            }
          }
        }
      }
    }
    userAccountPortfolios(where: { hasAccountWith: { id: $accountID } }) {
      edges {
        node {
          id
          totalValue
          assetBalances(where: { hasAssetWith: { id: $securityID } }) {
            edges {
              node {
                costBasis
                balances {
                  activeBalance
                }
              }
            }
          }
        }
      }
    }
    dividendDistributions(
      where: { hasPortfolioWith: { hasAccountWith: { id: $accountID } } }
      orderBy: { direction: DESC, field: create_time }
    ) {
      edges {
        node {
          amount
          distributionAsset {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useGetSecurityMyHoldingSectionQuery__
 *
 * To run a query within a React component, call `useGetSecurityMyHoldingSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSecurityMyHoldingSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSecurityMyHoldingSectionQuery({
 *   variables: {
 *      securityID: // value for 'securityID'
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useGetSecurityMyHoldingSectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSecurityMyHoldingSectionQuery,
    GetSecurityMyHoldingSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSecurityMyHoldingSectionQuery,
    GetSecurityMyHoldingSectionQueryVariables
  >(GetSecurityMyHoldingSectionDocument, options);
}
export function useGetSecurityMyHoldingSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSecurityMyHoldingSectionQuery,
    GetSecurityMyHoldingSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSecurityMyHoldingSectionQuery,
    GetSecurityMyHoldingSectionQueryVariables
  >(GetSecurityMyHoldingSectionDocument, options);
}
export type GetSecurityMyHoldingSectionQueryHookResult = ReturnType<
  typeof useGetSecurityMyHoldingSectionQuery
>;
export type GetSecurityMyHoldingSectionLazyQueryHookResult = ReturnType<
  typeof useGetSecurityMyHoldingSectionLazyQuery
>;
export type GetSecurityMyHoldingSectionQueryResult = Apollo.QueryResult<
  GetSecurityMyHoldingSectionQuery,
  GetSecurityMyHoldingSectionQueryVariables
>;
export const GetSecurityTradingSectionDocument = gql`
  query GetSecurityTradingSection($securityID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          id
          askExchange {
            name
          }
          bidExchange {
            name
          }
          market {
            askSize
            bidSize
            volume
            historical {
              volume
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetSecurityTradingSectionQuery__
 *
 * To run a query within a React component, call `useGetSecurityTradingSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSecurityTradingSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSecurityTradingSectionQuery({
 *   variables: {
 *      securityID: // value for 'securityID'
 *   },
 * });
 */
export function useGetSecurityTradingSectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSecurityTradingSectionQuery,
    GetSecurityTradingSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSecurityTradingSectionQuery, GetSecurityTradingSectionQueryVariables>(
    GetSecurityTradingSectionDocument,
    options,
  );
}
export function useGetSecurityTradingSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSecurityTradingSectionQuery,
    GetSecurityTradingSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSecurityTradingSectionQuery,
    GetSecurityTradingSectionQueryVariables
  >(GetSecurityTradingSectionDocument, options);
}
export type GetSecurityTradingSectionQueryHookResult = ReturnType<
  typeof useGetSecurityTradingSectionQuery
>;
export type GetSecurityTradingSectionLazyQueryHookResult = ReturnType<
  typeof useGetSecurityTradingSectionLazyQuery
>;
export type GetSecurityTradingSectionQueryResult = Apollo.QueryResult<
  GetSecurityTradingSectionQuery,
  GetSecurityTradingSectionQueryVariables
>;
export const GetStockSecurityDataSectionDocument = gql`
  query GetStockSecurityDataSection($securityID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          id
          marketCap
          securityType
          financials {
            dividendTrailingTwelveMonths
            dividendYield
            dividends(limit: 1) {
              exDividendDate
            }
            statements(limit: 1) {
              totalEnterpriseValue
              revenue
              ebitda
              ebitdaMargin
              ebit
              profitMargin
              peRatio
              pegRatio
              earningsPerShare
              bookValuePerShare
              longTermDebt
              totalCash
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetStockSecurityDataSectionQuery__
 *
 * To run a query within a React component, call `useGetStockSecurityDataSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockSecurityDataSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockSecurityDataSectionQuery({
 *   variables: {
 *      securityID: // value for 'securityID'
 *   },
 * });
 */
export function useGetStockSecurityDataSectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStockSecurityDataSectionQuery,
    GetStockSecurityDataSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStockSecurityDataSectionQuery,
    GetStockSecurityDataSectionQueryVariables
  >(GetStockSecurityDataSectionDocument, options);
}
export function useGetStockSecurityDataSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStockSecurityDataSectionQuery,
    GetStockSecurityDataSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStockSecurityDataSectionQuery,
    GetStockSecurityDataSectionQueryVariables
  >(GetStockSecurityDataSectionDocument, options);
}
export type GetStockSecurityDataSectionQueryHookResult = ReturnType<
  typeof useGetStockSecurityDataSectionQuery
>;
export type GetStockSecurityDataSectionLazyQueryHookResult = ReturnType<
  typeof useGetStockSecurityDataSectionLazyQuery
>;
export type GetStockSecurityDataSectionQueryResult = Apollo.QueryResult<
  GetStockSecurityDataSectionQuery,
  GetStockSecurityDataSectionQueryVariables
>;
export const GetStockSecurityInfoSectionDocument = gql`
  query GetStockSecurityInfoSection($securityID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          description
          name
          sector {
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGetStockSecurityInfoSectionQuery__
 *
 * To run a query within a React component, call `useGetStockSecurityInfoSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockSecurityInfoSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockSecurityInfoSectionQuery({
 *   variables: {
 *      securityID: // value for 'securityID'
 *   },
 * });
 */
export function useGetStockSecurityInfoSectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStockSecurityInfoSectionQuery,
    GetStockSecurityInfoSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStockSecurityInfoSectionQuery,
    GetStockSecurityInfoSectionQueryVariables
  >(GetStockSecurityInfoSectionDocument, options);
}
export function useGetStockSecurityInfoSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStockSecurityInfoSectionQuery,
    GetStockSecurityInfoSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStockSecurityInfoSectionQuery,
    GetStockSecurityInfoSectionQueryVariables
  >(GetStockSecurityInfoSectionDocument, options);
}
export type GetStockSecurityInfoSectionQueryHookResult = ReturnType<
  typeof useGetStockSecurityInfoSectionQuery
>;
export type GetStockSecurityInfoSectionLazyQueryHookResult = ReturnType<
  typeof useGetStockSecurityInfoSectionLazyQuery
>;
export type GetStockSecurityInfoSectionQueryResult = Apollo.QueryResult<
  GetStockSecurityInfoSectionQuery,
  GetStockSecurityInfoSectionQueryVariables
>;
export const GetSecurityNewsSectionDocument = gql`
  query GetSecurityNewsSection($securityID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          news(limit: 1) {
            title
            datetime
            description
            url
            imageUrl
            source
          }
        }
      }
    }
  }
`;

/**
 * __useGetSecurityNewsSectionQuery__
 *
 * To run a query within a React component, call `useGetSecurityNewsSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSecurityNewsSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSecurityNewsSectionQuery({
 *   variables: {
 *      securityID: // value for 'securityID'
 *   },
 * });
 */
export function useGetSecurityNewsSectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSecurityNewsSectionQuery,
    GetSecurityNewsSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSecurityNewsSectionQuery, GetSecurityNewsSectionQueryVariables>(
    GetSecurityNewsSectionDocument,
    options,
  );
}
export function useGetSecurityNewsSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSecurityNewsSectionQuery,
    GetSecurityNewsSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSecurityNewsSectionQuery, GetSecurityNewsSectionQueryVariables>(
    GetSecurityNewsSectionDocument,
    options,
  );
}
export type GetSecurityNewsSectionQueryHookResult = ReturnType<
  typeof useGetSecurityNewsSectionQuery
>;
export type GetSecurityNewsSectionLazyQueryHookResult = ReturnType<
  typeof useGetSecurityNewsSectionLazyQuery
>;
export type GetSecurityNewsSectionQueryResult = Apollo.QueryResult<
  GetSecurityNewsSectionQuery,
  GetSecurityNewsSectionQueryVariables
>;
export const GetEtfSecurityInfoSectionDocument = gql`
  query GetEtfSecurityInfoSection($securityID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          id
          description
          market {
            last
            previousDayClose
          }
          details {
            __typename
            ... on SecurityETFDetails {
              netAssetValue
              yield30Day
              expenseRatioNet
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetEtfSecurityInfoSectionQuery__
 *
 * To run a query within a React component, call `useGetEtfSecurityInfoSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEtfSecurityInfoSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEtfSecurityInfoSectionQuery({
 *   variables: {
 *      securityID: // value for 'securityID'
 *   },
 * });
 */
export function useGetEtfSecurityInfoSectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEtfSecurityInfoSectionQuery,
    GetEtfSecurityInfoSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEtfSecurityInfoSectionQuery, GetEtfSecurityInfoSectionQueryVariables>(
    GetEtfSecurityInfoSectionDocument,
    options,
  );
}
export function useGetEtfSecurityInfoSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEtfSecurityInfoSectionQuery,
    GetEtfSecurityInfoSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEtfSecurityInfoSectionQuery,
    GetEtfSecurityInfoSectionQueryVariables
  >(GetEtfSecurityInfoSectionDocument, options);
}
export type GetEtfSecurityInfoSectionQueryHookResult = ReturnType<
  typeof useGetEtfSecurityInfoSectionQuery
>;
export type GetEtfSecurityInfoSectionLazyQueryHookResult = ReturnType<
  typeof useGetEtfSecurityInfoSectionLazyQuery
>;
export type GetEtfSecurityInfoSectionQueryResult = Apollo.QueryResult<
  GetEtfSecurityInfoSectionQuery,
  GetEtfSecurityInfoSectionQueryVariables
>;
export const GetSecurityDistributionsSectionDocument = gql`
  query GetSecurityDistributionsSection($securityID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          id
          name
          symbol
          financials {
            dividends {
              cashAmount
              frequency
              payDate
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetSecurityDistributionsSectionQuery__
 *
 * To run a query within a React component, call `useGetSecurityDistributionsSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSecurityDistributionsSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSecurityDistributionsSectionQuery({
 *   variables: {
 *      securityID: // value for 'securityID'
 *   },
 * });
 */
export function useGetSecurityDistributionsSectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSecurityDistributionsSectionQuery,
    GetSecurityDistributionsSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSecurityDistributionsSectionQuery,
    GetSecurityDistributionsSectionQueryVariables
  >(GetSecurityDistributionsSectionDocument, options);
}
export function useGetSecurityDistributionsSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSecurityDistributionsSectionQuery,
    GetSecurityDistributionsSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSecurityDistributionsSectionQuery,
    GetSecurityDistributionsSectionQueryVariables
  >(GetSecurityDistributionsSectionDocument, options);
}
export type GetSecurityDistributionsSectionQueryHookResult = ReturnType<
  typeof useGetSecurityDistributionsSectionQuery
>;
export type GetSecurityDistributionsSectionLazyQueryHookResult = ReturnType<
  typeof useGetSecurityDistributionsSectionLazyQuery
>;
export type GetSecurityDistributionsSectionQueryResult = Apollo.QueryResult<
  GetSecurityDistributionsSectionQuery,
  GetSecurityDistributionsSectionQueryVariables
>;
export const GetSecuritySectorsSectionDocument = gql`
  query GetSecuritySectorsSection($securityID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          id
          details {
            __typename
            ... on SecurityETFDetails {
              holdings {
                __typename
              }
            }
            ... on SecurityMutualFundDetails {
              holdings {
                __typename
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetSecuritySectorsSectionQuery__
 *
 * To run a query within a React component, call `useGetSecuritySectorsSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSecuritySectorsSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSecuritySectorsSectionQuery({
 *   variables: {
 *      securityID: // value for 'securityID'
 *   },
 * });
 */
export function useGetSecuritySectorsSectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSecuritySectorsSectionQuery,
    GetSecuritySectorsSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSecuritySectorsSectionQuery, GetSecuritySectorsSectionQueryVariables>(
    GetSecuritySectorsSectionDocument,
    options,
  );
}
export function useGetSecuritySectorsSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSecuritySectorsSectionQuery,
    GetSecuritySectorsSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSecuritySectorsSectionQuery,
    GetSecuritySectorsSectionQueryVariables
  >(GetSecuritySectorsSectionDocument, options);
}
export type GetSecuritySectorsSectionQueryHookResult = ReturnType<
  typeof useGetSecuritySectorsSectionQuery
>;
export type GetSecuritySectorsSectionLazyQueryHookResult = ReturnType<
  typeof useGetSecuritySectorsSectionLazyQuery
>;
export type GetSecuritySectorsSectionQueryResult = Apollo.QueryResult<
  GetSecuritySectorsSectionQuery,
  GetSecuritySectorsSectionQueryVariables
>;
export const GetSecurityTopHoldingsSectionDocument = gql`
  query GetSecurityTopHoldingsSection($securityID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          id
          details {
            __typename
            ... on SecurityETFDetails {
              holdings {
                __typename
              }
            }
            ... on SecurityMutualFundDetails {
              holdings {
                __typename
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetSecurityTopHoldingsSectionQuery__
 *
 * To run a query within a React component, call `useGetSecurityTopHoldingsSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSecurityTopHoldingsSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSecurityTopHoldingsSectionQuery({
 *   variables: {
 *      securityID: // value for 'securityID'
 *   },
 * });
 */
export function useGetSecurityTopHoldingsSectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSecurityTopHoldingsSectionQuery,
    GetSecurityTopHoldingsSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSecurityTopHoldingsSectionQuery,
    GetSecurityTopHoldingsSectionQueryVariables
  >(GetSecurityTopHoldingsSectionDocument, options);
}
export function useGetSecurityTopHoldingsSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSecurityTopHoldingsSectionQuery,
    GetSecurityTopHoldingsSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSecurityTopHoldingsSectionQuery,
    GetSecurityTopHoldingsSectionQueryVariables
  >(GetSecurityTopHoldingsSectionDocument, options);
}
export type GetSecurityTopHoldingsSectionQueryHookResult = ReturnType<
  typeof useGetSecurityTopHoldingsSectionQuery
>;
export type GetSecurityTopHoldingsSectionLazyQueryHookResult = ReturnType<
  typeof useGetSecurityTopHoldingsSectionLazyQuery
>;
export type GetSecurityTopHoldingsSectionQueryResult = Apollo.QueryResult<
  GetSecurityTopHoldingsSectionQuery,
  GetSecurityTopHoldingsSectionQueryVariables
>;
export const GetMfSecurityDataSectionDocument = gql`
  query GetMFSecurityDataSection($securityID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          id
          beta
          details {
            __typename
            ... on SecurityMutualFundDetails {
              transactionFee
              netAssetValue
              netAssetValueChange
              yearToDateReturns
              yield30Day
              fundCategory
              fundInception
              loadType
              loadPct
              initialInvestmentMinimum
              additionalInvestmentMinimum
              turnoverRate
              portfolioNetAssetValue
              shareClassNetAssetValue
              twelveMonthLowValue
              twelveMonthHighValue
              rSquared
              sharpeRatio
              annualizedStdDev
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetMfSecurityDataSectionQuery__
 *
 * To run a query within a React component, call `useGetMfSecurityDataSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMfSecurityDataSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMfSecurityDataSectionQuery({
 *   variables: {
 *      securityID: // value for 'securityID'
 *   },
 * });
 */
export function useGetMfSecurityDataSectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMfSecurityDataSectionQuery,
    GetMfSecurityDataSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMfSecurityDataSectionQuery, GetMfSecurityDataSectionQueryVariables>(
    GetMfSecurityDataSectionDocument,
    options,
  );
}
export function useGetMfSecurityDataSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMfSecurityDataSectionQuery,
    GetMfSecurityDataSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMfSecurityDataSectionQuery, GetMfSecurityDataSectionQueryVariables>(
    GetMfSecurityDataSectionDocument,
    options,
  );
}
export type GetMfSecurityDataSectionQueryHookResult = ReturnType<
  typeof useGetMfSecurityDataSectionQuery
>;
export type GetMfSecurityDataSectionLazyQueryHookResult = ReturnType<
  typeof useGetMfSecurityDataSectionLazyQuery
>;
export type GetMfSecurityDataSectionQueryResult = Apollo.QueryResult<
  GetMfSecurityDataSectionQuery,
  GetMfSecurityDataSectionQueryVariables
>;
export const GetMfSecurityExpensesSectionDocument = gql`
  query GetMFSecurityExpensesSection($securityID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          id
          details {
            __typename
            ... on SecurityMutualFundDetails {
              expenseRatioGross
              expenseRatioNet
              contractualExpenseWaiver
              distributionServiceFee
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetMfSecurityExpensesSectionQuery__
 *
 * To run a query within a React component, call `useGetMfSecurityExpensesSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMfSecurityExpensesSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMfSecurityExpensesSectionQuery({
 *   variables: {
 *      securityID: // value for 'securityID'
 *   },
 * });
 */
export function useGetMfSecurityExpensesSectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMfSecurityExpensesSectionQuery,
    GetMfSecurityExpensesSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMfSecurityExpensesSectionQuery,
    GetMfSecurityExpensesSectionQueryVariables
  >(GetMfSecurityExpensesSectionDocument, options);
}
export function useGetMfSecurityExpensesSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMfSecurityExpensesSectionQuery,
    GetMfSecurityExpensesSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMfSecurityExpensesSectionQuery,
    GetMfSecurityExpensesSectionQueryVariables
  >(GetMfSecurityExpensesSectionDocument, options);
}
export type GetMfSecurityExpensesSectionQueryHookResult = ReturnType<
  typeof useGetMfSecurityExpensesSectionQuery
>;
export type GetMfSecurityExpensesSectionLazyQueryHookResult = ReturnType<
  typeof useGetMfSecurityExpensesSectionLazyQuery
>;
export type GetMfSecurityExpensesSectionQueryResult = Apollo.QueryResult<
  GetMfSecurityExpensesSectionQuery,
  GetMfSecurityExpensesSectionQueryVariables
>;
export const GetMfSecurityMinimumsSectionDocument = gql`
  query GetMFSecurityMinimumsSection($securityID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          id
          details {
            __typename
            ... on SecurityMutualFundDetails {
              initialInvestmentMinimum
              additionalInvestmentMinimum
              initialIraInvestmentMinimum
              additionalIraInvestmentMinimum
              initialGroupRetirementInvestmentMinimum
              additionalGroupRetirementInvestmentMinimum
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetMfSecurityMinimumsSectionQuery__
 *
 * To run a query within a React component, call `useGetMfSecurityMinimumsSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMfSecurityMinimumsSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMfSecurityMinimumsSectionQuery({
 *   variables: {
 *      securityID: // value for 'securityID'
 *   },
 * });
 */
export function useGetMfSecurityMinimumsSectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMfSecurityMinimumsSectionQuery,
    GetMfSecurityMinimumsSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMfSecurityMinimumsSectionQuery,
    GetMfSecurityMinimumsSectionQueryVariables
  >(GetMfSecurityMinimumsSectionDocument, options);
}
export function useGetMfSecurityMinimumsSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMfSecurityMinimumsSectionQuery,
    GetMfSecurityMinimumsSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMfSecurityMinimumsSectionQuery,
    GetMfSecurityMinimumsSectionQueryVariables
  >(GetMfSecurityMinimumsSectionDocument, options);
}
export type GetMfSecurityMinimumsSectionQueryHookResult = ReturnType<
  typeof useGetMfSecurityMinimumsSectionQuery
>;
export type GetMfSecurityMinimumsSectionLazyQueryHookResult = ReturnType<
  typeof useGetMfSecurityMinimumsSectionLazyQuery
>;
export type GetMfSecurityMinimumsSectionQueryResult = Apollo.QueryResult<
  GetMfSecurityMinimumsSectionQuery,
  GetMfSecurityMinimumsSectionQueryVariables
>;
export const GetMfSecurityInfoSectionDocument = gql`
  query GetMFSecurityInfoSection($securityID: ID!) {
    securityAssets(where: { id: $securityID }) {
      edges {
        node {
          id
          name
          description
        }
      }
    }
  }
`;

/**
 * __useGetMfSecurityInfoSectionQuery__
 *
 * To run a query within a React component, call `useGetMfSecurityInfoSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMfSecurityInfoSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMfSecurityInfoSectionQuery({
 *   variables: {
 *      securityID: // value for 'securityID'
 *   },
 * });
 */
export function useGetMfSecurityInfoSectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMfSecurityInfoSectionQuery,
    GetMfSecurityInfoSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMfSecurityInfoSectionQuery, GetMfSecurityInfoSectionQueryVariables>(
    GetMfSecurityInfoSectionDocument,
    options,
  );
}
export function useGetMfSecurityInfoSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMfSecurityInfoSectionQuery,
    GetMfSecurityInfoSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMfSecurityInfoSectionQuery, GetMfSecurityInfoSectionQueryVariables>(
    GetMfSecurityInfoSectionDocument,
    options,
  );
}
export type GetMfSecurityInfoSectionQueryHookResult = ReturnType<
  typeof useGetMfSecurityInfoSectionQuery
>;
export type GetMfSecurityInfoSectionLazyQueryHookResult = ReturnType<
  typeof useGetMfSecurityInfoSectionLazyQuery
>;
export type GetMfSecurityInfoSectionQueryResult = Apollo.QueryResult<
  GetMfSecurityInfoSectionQuery,
  GetMfSecurityInfoSectionQueryVariables
>;
export const GetSecuritiesDocument = gql`
  query GetSecurities(
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
    $orderBy: SecurityAssetOrder
    $where: SecurityAssetWhereInput
  ) {
    securityAssets(
      first: $first
      after: $after
      last: $last
      before: $before
      where: $where
      orderBy: $orderBy
    ) {
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
        endCursor
      }
      totalCount
      edges {
        node {
          id
          name
          symbol
          active
          description
          iconURI
          logoURI
          color
          createTime
          updateTime
          securityType
          website
          cusip
          marketCap
          highlyVolatile
          details {
            ... on SecurityETFDetails {
              expenseRatioNet
            }
            ... on SecurityMutualFundDetails {
              expenseRatioNet
              loadType
              fundCategory
              initialInvestmentMinimum
              netAssetValue
            }
          }
          askExchange {
            id
          }
          bidExchange {
            id
          }
          complexSecurity
          sector {
            id
            name
          }
          financials {
            dividendYield
            peRatio
            dividends(limit: 1) {
              cashAmount
              payDate
            }
            statements(limit: 1) {
              revenue
              ebitda
              earningsPerShare
              bookValuePerShare
              profitMargin
              peRatio
            }
          }
          beta
          market {
            open
            bidSize
            last
            askSize
            baseMin
            close
            previousDayClose
            historical {
              close
            }
          }
          etfDetails {
            expenseRatio
          }
        }
      }
    }
  }
`;

/**
 * __useGetSecuritiesQuery__
 *
 * To run a query within a React component, call `useGetSecuritiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSecuritiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSecuritiesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetSecuritiesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSecuritiesQuery, GetSecuritiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSecuritiesQuery, GetSecuritiesQueryVariables>(
    GetSecuritiesDocument,
    options,
  );
}
export function useGetSecuritiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSecuritiesQuery, GetSecuritiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSecuritiesQuery, GetSecuritiesQueryVariables>(
    GetSecuritiesDocument,
    options,
  );
}
export type GetSecuritiesQueryHookResult = ReturnType<typeof useGetSecuritiesQuery>;
export type GetSecuritiesLazyQueryHookResult = ReturnType<typeof useGetSecuritiesLazyQuery>;
export type GetSecuritiesQueryResult = Apollo.QueryResult<
  GetSecuritiesQuery,
  GetSecuritiesQueryVariables
>;
export const GetSecurityPagesDocument = gql`
  query GetSecurityPages(
    $first: Int
    $after: Cursor
    $orderBy: SecurityAssetOrder
    $where: SecurityAssetWhereInput
  ) {
    pages: securityAssets(first: $first, after: $after, where: $where, orderBy: $orderBy) {
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
        endCursor
      }
      edges {
        ...SecurityAssetEdgePage
      }
    }
  }
  ${SecurityAssetEdgePageFragmentDoc}
`;

/**
 * __useGetSecurityPagesQuery__
 *
 * To run a query within a React component, call `useGetSecurityPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSecurityPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSecurityPagesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetSecurityPagesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSecurityPagesQuery, GetSecurityPagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSecurityPagesQuery, GetSecurityPagesQueryVariables>(
    GetSecurityPagesDocument,
    options,
  );
}
export function useGetSecurityPagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSecurityPagesQuery, GetSecurityPagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSecurityPagesQuery, GetSecurityPagesQueryVariables>(
    GetSecurityPagesDocument,
    options,
  );
}
export type GetSecurityPagesQueryHookResult = ReturnType<typeof useGetSecurityPagesQuery>;
export type GetSecurityPagesLazyQueryHookResult = ReturnType<typeof useGetSecurityPagesLazyQuery>;
export type GetSecurityPagesQueryResult = Apollo.QueryResult<
  GetSecurityPagesQuery,
  GetSecurityPagesQueryVariables
>;
export const GetSecuritySectorsFilterOptionsDocument = gql`
  query GetSecuritySectorsFilterOptions($where: SecuritySectorWhereInput) {
    securitySectors(where: $where) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetSecuritySectorsFilterOptionsQuery__
 *
 * To run a query within a React component, call `useGetSecuritySectorsFilterOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSecuritySectorsFilterOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSecuritySectorsFilterOptionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetSecuritySectorsFilterOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSecuritySectorsFilterOptionsQuery,
    GetSecuritySectorsFilterOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSecuritySectorsFilterOptionsQuery,
    GetSecuritySectorsFilterOptionsQueryVariables
  >(GetSecuritySectorsFilterOptionsDocument, options);
}
export function useGetSecuritySectorsFilterOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSecuritySectorsFilterOptionsQuery,
    GetSecuritySectorsFilterOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSecuritySectorsFilterOptionsQuery,
    GetSecuritySectorsFilterOptionsQueryVariables
  >(GetSecuritySectorsFilterOptionsDocument, options);
}
export type GetSecuritySectorsFilterOptionsQueryHookResult = ReturnType<
  typeof useGetSecuritySectorsFilterOptionsQuery
>;
export type GetSecuritySectorsFilterOptionsLazyQueryHookResult = ReturnType<
  typeof useGetSecuritySectorsFilterOptionsLazyQuery
>;
export type GetSecuritySectorsFilterOptionsQueryResult = Apollo.QueryResult<
  GetSecuritySectorsFilterOptionsQuery,
  GetSecuritySectorsFilterOptionsQueryVariables
>;
export const GetSearchPaginationDataDocument = gql`
  query GetSearchPaginationData($where: SecurityAssetWhereInput) {
    currentFilter: securityAssets(where: $where) {
      totalCount
    }
    stock: securityAssets(where: { securityTypeIn: [CS, CVR, PFD] }) {
      totalCount
    }
    etf: securityAssets(where: { securityTypeIn: [ETF, ETN] }) {
      totalCount
    }
    mutualFund: securityAssets(where: { securityTypeIn: [MUTUAL_FUND] }) {
      totalCount
    }
  }
`;

/**
 * __useGetSearchPaginationDataQuery__
 *
 * To run a query within a React component, call `useGetSearchPaginationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchPaginationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchPaginationDataQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetSearchPaginationDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSearchPaginationDataQuery,
    GetSearchPaginationDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSearchPaginationDataQuery, GetSearchPaginationDataQueryVariables>(
    GetSearchPaginationDataDocument,
    options,
  );
}
export function useGetSearchPaginationDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSearchPaginationDataQuery,
    GetSearchPaginationDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSearchPaginationDataQuery, GetSearchPaginationDataQueryVariables>(
    GetSearchPaginationDataDocument,
    options,
  );
}
export type GetSearchPaginationDataQueryHookResult = ReturnType<
  typeof useGetSearchPaginationDataQuery
>;
export type GetSearchPaginationDataLazyQueryHookResult = ReturnType<
  typeof useGetSearchPaginationDataLazyQuery
>;
export type GetSearchPaginationDataQueryResult = Apollo.QueryResult<
  GetSearchPaginationDataQuery,
  GetSearchPaginationDataQueryVariables
>;
export const GetSupportFaqsDocument = gql`
  query GetSupportFaqs {
    supportFaqCategories {
      id
      name
      order
      faqs {
        id
        type
        title
        image
        description
      }
    }
  }
`;

/**
 * __useGetSupportFaqsQuery__
 *
 * To run a query within a React component, call `useGetSupportFaqsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportFaqsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportFaqsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSupportFaqsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSupportFaqsQuery, GetSupportFaqsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSupportFaqsQuery, GetSupportFaqsQueryVariables>(
    GetSupportFaqsDocument,
    options,
  );
}
export function useGetSupportFaqsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSupportFaqsQuery, GetSupportFaqsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSupportFaqsQuery, GetSupportFaqsQueryVariables>(
    GetSupportFaqsDocument,
    options,
  );
}
export type GetSupportFaqsQueryHookResult = ReturnType<typeof useGetSupportFaqsQuery>;
export type GetSupportFaqsLazyQueryHookResult = ReturnType<typeof useGetSupportFaqsLazyQuery>;
export type GetSupportFaqsQueryResult = Apollo.QueryResult<
  GetSupportFaqsQuery,
  GetSupportFaqsQueryVariables
>;
export const GetTenantDetailsDocument = gql`
  query GetTenantDetails {
    tenant {
      id
      alias
      brokerage {
        name
        broker {
          name
        }
        plaidRelationshipOwner {
          name
        }
      }
      advisory {
        id
        name
        broker {
          name
        }
        plaidRelationshipOwner {
          name
        }
        isClearingHouseIB
        requiresExtendedOnboarding
      }
    }
  }
`;

/**
 * __useGetTenantDetailsQuery__
 *
 * To run a query within a React component, call `useGetTenantDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTenantDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTenantDetailsQuery, GetTenantDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTenantDetailsQuery, GetTenantDetailsQueryVariables>(
    GetTenantDetailsDocument,
    options,
  );
}
export function useGetTenantDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTenantDetailsQuery, GetTenantDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTenantDetailsQuery, GetTenantDetailsQueryVariables>(
    GetTenantDetailsDocument,
    options,
  );
}
export type GetTenantDetailsQueryHookResult = ReturnType<typeof useGetTenantDetailsQuery>;
export type GetTenantDetailsLazyQueryHookResult = ReturnType<typeof useGetTenantDetailsLazyQuery>;
export type GetTenantDetailsQueryResult = Apollo.QueryResult<
  GetTenantDetailsQuery,
  GetTenantDetailsQueryVariables
>;
export const GetAchTransferHistoryDocument = gql`
  query GetAchTransferHistory(
    $first: Int
    $after: Cursor
    $portfolioId: ID
    $achType: [ACHTransferTransferType!]
    $startDate: Time
    $endDate: Time
  ) {
    pages: achTransfers(
      where: {
        hasPortfolioWith: { id: $portfolioId }
        typeIn: $achType
        createTimeGT: $startDate
        createTimeLT: $endDate
      }
      first: $first
      after: $after
      orderBy: { direction: DESC, field: create_time }
    ) {
      edges {
        ...AchTransferEdge
      }
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
  }
  ${AchTransferEdgeFragmentDoc}
`;

/**
 * __useGetAchTransferHistoryQuery__
 *
 * To run a query within a React component, call `useGetAchTransferHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAchTransferHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAchTransferHistoryQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      portfolioId: // value for 'portfolioId'
 *      achType: // value for 'achType'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetAchTransferHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAchTransferHistoryQuery,
    GetAchTransferHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAchTransferHistoryQuery, GetAchTransferHistoryQueryVariables>(
    GetAchTransferHistoryDocument,
    options,
  );
}
export function useGetAchTransferHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAchTransferHistoryQuery,
    GetAchTransferHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAchTransferHistoryQuery, GetAchTransferHistoryQueryVariables>(
    GetAchTransferHistoryDocument,
    options,
  );
}
export type GetAchTransferHistoryQueryHookResult = ReturnType<typeof useGetAchTransferHistoryQuery>;
export type GetAchTransferHistoryLazyQueryHookResult = ReturnType<
  typeof useGetAchTransferHistoryLazyQuery
>;
export type GetAchTransferHistoryQueryResult = Apollo.QueryResult<
  GetAchTransferHistoryQuery,
  GetAchTransferHistoryQueryVariables
>;
export const GetTradeHistoryDocument = gql`
  query GetTradeHistory(
    $first: Int
    $after: Cursor
    $today: Time!
    $portfolioId: ID
    $tradeType: [TradeMarketSide!]
    $startDate: Time
    $endDate: Time
    $allFilters: [TradeWhereInput!]
  ) {
    pages: trades(
      first: $first
      after: $after
      where: {
        and: $allFilters
        or: [{ statusNEQ: TRADE_STATUS_FAILED }, { createTimeGTE: $today }]
        hasPortfolioWith: { id: $portfolioId }
        sideIn: $tradeType
        createTimeGT: $startDate
        createTimeLT: $endDate
      }
      orderBy: { direction: DESC, field: create_time }
    ) {
      edges {
        ...TradeEdgeFragment
      }
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
  }
  ${TradeEdgeFragmentFragmentDoc}
`;

/**
 * __useGetTradeHistoryQuery__
 *
 * To run a query within a React component, call `useGetTradeHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTradeHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTradeHistoryQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      today: // value for 'today'
 *      portfolioId: // value for 'portfolioId'
 *      tradeType: // value for 'tradeType'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      allFilters: // value for 'allFilters'
 *   },
 * });
 */
export function useGetTradeHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<GetTradeHistoryQuery, GetTradeHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTradeHistoryQuery, GetTradeHistoryQueryVariables>(
    GetTradeHistoryDocument,
    options,
  );
}
export function useGetTradeHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTradeHistoryQuery, GetTradeHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTradeHistoryQuery, GetTradeHistoryQueryVariables>(
    GetTradeHistoryDocument,
    options,
  );
}
export type GetTradeHistoryQueryHookResult = ReturnType<typeof useGetTradeHistoryQuery>;
export type GetTradeHistoryLazyQueryHookResult = ReturnType<typeof useGetTradeHistoryLazyQuery>;
export type GetTradeHistoryQueryResult = Apollo.QueryResult<
  GetTradeHistoryQuery,
  GetTradeHistoryQueryVariables
>;
export const GetDividendHistoryDocument = gql`
  query GetDividendHistory($first: Int, $after: Cursor) {
    pages: dividendDistributions(first: $first, after: $after) {
      edges {
        ...DividendDistributionEdge
      }
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
  }
  ${DividendDistributionEdgeFragmentDoc}
`;

/**
 * __useGetDividendHistoryQuery__
 *
 * To run a query within a React component, call `useGetDividendHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDividendHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDividendHistoryQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetDividendHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<GetDividendHistoryQuery, GetDividendHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDividendHistoryQuery, GetDividendHistoryQueryVariables>(
    GetDividendHistoryDocument,
    options,
  );
}
export function useGetDividendHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDividendHistoryQuery,
    GetDividendHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDividendHistoryQuery, GetDividendHistoryQueryVariables>(
    GetDividendHistoryDocument,
    options,
  );
}
export type GetDividendHistoryQueryHookResult = ReturnType<typeof useGetDividendHistoryQuery>;
export type GetDividendHistoryLazyQueryHookResult = ReturnType<
  typeof useGetDividendHistoryLazyQuery
>;
export type GetDividendHistoryQueryResult = Apollo.QueryResult<
  GetDividendHistoryQuery,
  GetDividendHistoryQueryVariables
>;
export const GetAllHistoryDocument = gql`
  query GetAllHistory(
    $first: Int
    $after: Cursor
    $portfolioId: ID
    $todayStart: Time!
    $orderType: [OrderOrderType!]
    $startDate: Time
    $endDate: Time
    $recentPages: Boolean!
  ) {
    recentPages: orders(
      first: 20
      after: $after
      orderBy: { direction: DESC, field: create_time }
      where: {
        hasPortfolioWith: { id: $portfolioId }
        typeIn: [
          ORDER_TYPE_ACHTRANSFER
          ORDER_TYPE_TRADE
          ORDER_TYPE_DIVIDEND
          ORDER_TYPE_INTEREST
          ORDER_TYPE_ICTTRANSFER
        ]
        or: [{ statusIn: [ORDER_STATUS_PROCESSING] }, { createTimeGT: $todayStart }]
      }
    ) @include(if: $recentPages) {
      edges {
        node {
          id
          __typename
          ... on ACHTransfer {
            ...AchTransfer
          }
          ... on Trade {
            ...TradeFragment
          }
          ... on DividendDistribution {
            ...DividendDistributionFragment
          }
          ... on InterestDistribution {
            ...InterestDistributionFragment
          }
          ... on ICTTransfer {
            ...IctTransfer
          }
        }
      }
    }
    pages: orders(
      first: $first
      after: $after
      orderBy: { direction: DESC, field: create_time }
      where: {
        typeIn: $orderType
        statusIn: [ORDER_STATUS_DONE, ORDER_STATUS_FINALIZING]
        hasPortfolioWith: { id: $portfolioId }
        createTimeGT: $startDate
        createTimeLT: $endDate
      }
    ) {
      edges {
        node {
          id
          __typename
          ... on ACHTransfer {
            ...AchTransfer
          }
          ... on Trade {
            ...TradeFragment
          }
          ... on DividendDistribution {
            ...DividendDistributionFragment
          }
          ... on InterestDistribution {
            ...InterestDistributionFragment
          }
          ... on ICTTransfer {
            ...IctTransfer
          }
        }
      }
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
  }
  ${AchTransferFragmentDoc}
  ${TradeFragmentFragmentDoc}
  ${DividendDistributionFragmentFragmentDoc}
  ${InterestDistributionFragmentFragmentDoc}
  ${IctTransferFragmentDoc}
`;

/**
 * __useGetAllHistoryQuery__
 *
 * To run a query within a React component, call `useGetAllHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllHistoryQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      portfolioId: // value for 'portfolioId'
 *      todayStart: // value for 'todayStart'
 *      orderType: // value for 'orderType'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      recentPages: // value for 'recentPages'
 *   },
 * });
 */
export function useGetAllHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<GetAllHistoryQuery, GetAllHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllHistoryQuery, GetAllHistoryQueryVariables>(
    GetAllHistoryDocument,
    options,
  );
}
export function useGetAllHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllHistoryQuery, GetAllHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllHistoryQuery, GetAllHistoryQueryVariables>(
    GetAllHistoryDocument,
    options,
  );
}
export type GetAllHistoryQueryHookResult = ReturnType<typeof useGetAllHistoryQuery>;
export type GetAllHistoryLazyQueryHookResult = ReturnType<typeof useGetAllHistoryLazyQuery>;
export type GetAllHistoryQueryResult = Apollo.QueryResult<
  GetAllHistoryQuery,
  GetAllHistoryQueryVariables
>;
export const GetTransfersByIdDocument = gql`
  query GetTransfersById($id: ID!) {
    node(id: $id) {
      ...AchTransfer
      ...IctTransfer
    }
  }
  ${AchTransferFragmentDoc}
  ${IctTransferFragmentDoc}
`;

/**
 * __useGetTransfersByIdQuery__
 *
 * To run a query within a React component, call `useGetTransfersByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransfersByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransfersByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTransfersByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetTransfersByIdQuery, GetTransfersByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTransfersByIdQuery, GetTransfersByIdQueryVariables>(
    GetTransfersByIdDocument,
    options,
  );
}
export function useGetTransfersByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTransfersByIdQuery, GetTransfersByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTransfersByIdQuery, GetTransfersByIdQueryVariables>(
    GetTransfersByIdDocument,
    options,
  );
}
export type GetTransfersByIdQueryHookResult = ReturnType<typeof useGetTransfersByIdQuery>;
export type GetTransfersByIdLazyQueryHookResult = ReturnType<typeof useGetTransfersByIdLazyQuery>;
export type GetTransfersByIdQueryResult = Apollo.QueryResult<
  GetTransfersByIdQuery,
  GetTransfersByIdQueryVariables
>;
export const GetUserSettingsDocument = gql`
  query getUserSettings {
    userSettings {
      retirement {
        defaultWithholding {
          state
          federalWithholding {
            waive
            percentage
          }
          stateWithholding {
            waive
            percentage
          }
        }
      }
    }
  }
`;

/**
 * __useGetUserSettingsQuery__
 *
 * To run a query within a React component, call `useGetUserSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserSettingsQuery, GetUserSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserSettingsQuery, GetUserSettingsQueryVariables>(
    GetUserSettingsDocument,
    options,
  );
}
export function useGetUserSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserSettingsQuery, GetUserSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserSettingsQuery, GetUserSettingsQueryVariables>(
    GetUserSettingsDocument,
    options,
  );
}
export type GetUserSettingsQueryHookResult = ReturnType<typeof useGetUserSettingsQuery>;
export type GetUserSettingsLazyQueryHookResult = ReturnType<typeof useGetUserSettingsLazyQuery>;
export type GetUserSettingsQueryResult = Apollo.QueryResult<
  GetUserSettingsQuery,
  GetUserSettingsQueryVariables
>;
export const GetUserSharesV2Document = gql`
  query GetUserSharesV2($portfolioId: ID!) {
    userSecurityAssets: userAccountPortfolioAssets(
      where: {
        hasPortfolioWith: { id: $portfolioId }
        hasAssetWith: { type: ASSET_TYPE_SECURITY }
        hideZeroBalance: true
      }
    ) {
      edges {
        ...UserSharesEdge
      }
    }
    userFiatAssets: userAccountPortfolioAssets(
      where: {
        hasPortfolioWith: { id: $portfolioId }
        hasAssetWith: { type: ASSET_TYPE_FIAT }
        hideZeroBalance: false
      }
    ) {
      edges {
        ...UserSharesEdge
      }
    }
  }
  ${UserSharesEdgeFragmentDoc}
`;

/**
 * __useGetUserSharesV2Query__
 *
 * To run a query within a React component, call `useGetUserSharesV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSharesV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSharesV2Query({
 *   variables: {
 *      portfolioId: // value for 'portfolioId'
 *   },
 * });
 */
export function useGetUserSharesV2Query(
  baseOptions: Apollo.QueryHookOptions<GetUserSharesV2Query, GetUserSharesV2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserSharesV2Query, GetUserSharesV2QueryVariables>(
    GetUserSharesV2Document,
    options,
  );
}
export function useGetUserSharesV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserSharesV2Query, GetUserSharesV2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserSharesV2Query, GetUserSharesV2QueryVariables>(
    GetUserSharesV2Document,
    options,
  );
}
export type GetUserSharesV2QueryHookResult = ReturnType<typeof useGetUserSharesV2Query>;
export type GetUserSharesV2LazyQueryHookResult = ReturnType<typeof useGetUserSharesV2LazyQuery>;
export type GetUserSharesV2QueryResult = Apollo.QueryResult<
  GetUserSharesV2Query,
  GetUserSharesV2QueryVariables
>;
export const GetUserDetailsDocument = gql`
  query GetUserDetails {
    users {
      edges {
        node {
          id
          firstName
          lastName
          username
          legacyID
          isActive
          isLoginMfaEnabled
          profile {
            firstName
            lastName
            dateOfBirth
            email
            phoneNumber
            phoneNumberType
            country
            city
            streetAddress
            additionalStreetAddress
            state
            zipCode
            unverifiedEmail
            unverifiedPhoneNumber
            controlPersonDetails {
              companySymbols
              isControlPerson
            }
            politicallyExposedDetails {
              isPoliticallyExposed
              organizationName
              familyMembers
            }
            affiliatedExchangeOrFinraDetails {
              isAffiliated
              firmName
            }
            marketDataAnswers {
              personalUse
              gettingDataFromExternalSource
              registeredWithSecOrCftc
              requiresSecOrCftcRegistration
              investForOther
              investForTheBenefitOfOther
              advisorOrConsultant
              sharesProfit
              registeredWithEntity
            }
          }
        }
      }
    }
    discretionaryAccountKycs {
      edges {
        node {
          id
          riskTolerance
          account {
            id
          }
        }
      }
    }
    nonDiscretionaryAccountKycs {
      edges {
        node {
          id
          account {
            id
          }
          riskTolerance
          investmentExperience
          investmentObjective
          timeHorizon
          liquidityNeeds
          maritalStatus
          employmentStatus
          annualIncome
          netWorth
          employmentDetails {
            EmployerName
            Position
          }
        }
      }
    }
  }
`;

/**
 * __useGetUserDetailsQuery__
 *
 * To run a query within a React component, call `useGetUserDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(
    GetUserDetailsDocument,
    options,
  );
}
export function useGetUserDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(
    GetUserDetailsDocument,
    options,
  );
}
export type GetUserDetailsQueryHookResult = ReturnType<typeof useGetUserDetailsQuery>;
export type GetUserDetailsLazyQueryHookResult = ReturnType<typeof useGetUserDetailsLazyQuery>;
export type GetUserDetailsQueryResult = Apollo.QueryResult<
  GetUserDetailsQuery,
  GetUserDetailsQueryVariables
>;
export const GetRepeatInvestmentSectorsDocument = gql`
  query GetRepeatInvestmentSectors {
    users {
      edges {
        node {
          user_id: id
          recurringRules {
            edges {
              node {
                id
                createTime
                updateTime
                type
                fundingMethod
                isActive
                interval
                amount
                start
                sector {
                  id
                  name
                  type
                  largeImage
                  smallImage
                  order
                  security {
                    id
                    name
                    symbol
                    description
                    market {
                      open
                      bidSize
                      last
                      askSize
                      baseMin
                      close
                      previousDayClose
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetRepeatInvestmentSectorsQuery__
 *
 * To run a query within a React component, call `useGetRepeatInvestmentSectorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRepeatInvestmentSectorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRepeatInvestmentSectorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRepeatInvestmentSectorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRepeatInvestmentSectorsQuery,
    GetRepeatInvestmentSectorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRepeatInvestmentSectorsQuery, GetRepeatInvestmentSectorsQueryVariables>(
    GetRepeatInvestmentSectorsDocument,
    options,
  );
}
export function useGetRepeatInvestmentSectorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRepeatInvestmentSectorsQuery,
    GetRepeatInvestmentSectorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRepeatInvestmentSectorsQuery,
    GetRepeatInvestmentSectorsQueryVariables
  >(GetRepeatInvestmentSectorsDocument, options);
}
export type GetRepeatInvestmentSectorsQueryHookResult = ReturnType<
  typeof useGetRepeatInvestmentSectorsQuery
>;
export type GetRepeatInvestmentSectorsLazyQueryHookResult = ReturnType<
  typeof useGetRepeatInvestmentSectorsLazyQuery
>;
export type GetRepeatInvestmentSectorsQueryResult = Apollo.QueryResult<
  GetRepeatInvestmentSectorsQuery,
  GetRepeatInvestmentSectorsQueryVariables
>;
export const GetAllUserAccountsStatusDocument = gql`
  query GetAllUserAccountsStatus {
    userAccounts {
      edges {
        node {
          ...GetAllUserAccountsStatusFragment
        }
      }
    }
  }
  ${GetAllUserAccountsStatusFragmentFragmentDoc}
`;

/**
 * __useGetAllUserAccountsStatusQuery__
 *
 * To run a query within a React component, call `useGetAllUserAccountsStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUserAccountsStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUserAccountsStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUserAccountsStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllUserAccountsStatusQuery,
    GetAllUserAccountsStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllUserAccountsStatusQuery, GetAllUserAccountsStatusQueryVariables>(
    GetAllUserAccountsStatusDocument,
    options,
  );
}
export function useGetAllUserAccountsStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllUserAccountsStatusQuery,
    GetAllUserAccountsStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllUserAccountsStatusQuery, GetAllUserAccountsStatusQueryVariables>(
    GetAllUserAccountsStatusDocument,
    options,
  );
}
export type GetAllUserAccountsStatusQueryHookResult = ReturnType<
  typeof useGetAllUserAccountsStatusQuery
>;
export type GetAllUserAccountsStatusLazyQueryHookResult = ReturnType<
  typeof useGetAllUserAccountsStatusLazyQuery
>;
export type GetAllUserAccountsStatusQueryResult = Apollo.QueryResult<
  GetAllUserAccountsStatusQuery,
  GetAllUserAccountsStatusQueryVariables
>;
export const GetGeneralInvestingPortfoliosDocument = gql`
  query GetGeneralInvestingPortfolios {
    roboGeneralInvestments {
      edges {
        node {
          id
          createTime
          targetPortfolioID
          accountPortfolioID
          initialInvestmentAmount
          recurringInfusion
          infusionFrequency
          lastRebalanceTime
          totalDividends
          totalInfusions
          totalInterests
          totalWithdrawals
          targetPortfolio {
            fiatPct
            stockPct
            bondPct
            id
          }
          accountPortfolio {
            totalValue
            account {
              id
              externalID
            }
          }
          isAutoInfusionPaused
          allocation {
            stockPct
            fiatPct
            bondPct
            drift
            totalValue
            allocation {
              asset {
                name
              }
              balance
              value
              percentage
            }
            allocationBySector {
              value
              percentage
              sector {
                name
                category
                id
                assets {
                  targetPct
                  asset {
                    id
                    symbol
                  }
                }
              }
              assets {
                asset {
                  name
                }
                balance
                value
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetGeneralInvestingPortfoliosQuery__
 *
 * To run a query within a React component, call `useGetGeneralInvestingPortfoliosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralInvestingPortfoliosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralInvestingPortfoliosQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGeneralInvestingPortfoliosQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGeneralInvestingPortfoliosQuery,
    GetGeneralInvestingPortfoliosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGeneralInvestingPortfoliosQuery,
    GetGeneralInvestingPortfoliosQueryVariables
  >(GetGeneralInvestingPortfoliosDocument, options);
}
export function useGetGeneralInvestingPortfoliosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGeneralInvestingPortfoliosQuery,
    GetGeneralInvestingPortfoliosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGeneralInvestingPortfoliosQuery,
    GetGeneralInvestingPortfoliosQueryVariables
  >(GetGeneralInvestingPortfoliosDocument, options);
}
export type GetGeneralInvestingPortfoliosQueryHookResult = ReturnType<
  typeof useGetGeneralInvestingPortfoliosQuery
>;
export type GetGeneralInvestingPortfoliosLazyQueryHookResult = ReturnType<
  typeof useGetGeneralInvestingPortfoliosLazyQuery
>;
export type GetGeneralInvestingPortfoliosQueryResult = Apollo.QueryResult<
  GetGeneralInvestingPortfoliosQuery,
  GetGeneralInvestingPortfoliosQueryVariables
>;
export const GetUserDetailsV2Document = gql`
  query GetUserDetailsV2 {
    users {
      edges {
        node {
          createTime
          allowedAchRelationships
          username
          id
          firstName
          lastName
          username
          legacyID
          isActive
          isLoginMfaEnabled
          accounts {
            edges {
              node {
                ...GetAllUserAccountsStatusFragment
                trustedContacts {
                  ...TrustedContactDetails
                }
              }
            }
          }
          profile {
            unverifiedEmail
            unverifiedPhoneNumber
            firstName
            lastName
            dateOfBirth
            email
            phoneNumber
            taxID
            phoneNumberType
            country
            otherSourcesOfWealth {
              hasOtherSourcesOfWealth
              otherSourcesOfWealth
            }
            citizenshipCountry {
              id
              name
              alpha3Code
            }
            visaType
            birthCountry {
              id
              name
              alpha3Code
            }
            visaExpirationDate
            city
            streetAddress
            additionalStreetAddress
            state
            zipCode
            filingStatus
            annualGrossIncome
            controlPersonDetails {
              companySymbols
              isControlPerson
            }
            politicallyExposedDetails {
              organizationName
              familyMembers
              isPoliticallyExposed
            }
            affiliatedExchangeOrFinraDetails {
              firmName
              isAffiliated
            }
            marketDataAnswers {
              personalUse
              gettingDataFromExternalSource
              registeredWithSecOrCftc
              requiresSecOrCftcRegistration
              investForOther
              investForTheBenefitOfOther
              advisorOrConsultant
              sharesProfit
              registeredWithEntity
            }
            updateRequests(orderBy: { direction: DESC, field: create_time }) {
              edges {
                node {
                  createTime
                  status
                  requestParameters {
                    ...CommonUserProfileRequestParameters
                  }
                }
              }
            }
          }
          portfolios {
            edges {
              node {
                id
                account {
                  apexAccountMeta {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
    nonDiscretionaryAccountKycs {
      edges {
        node {
          ...CommonUserInvestorInformationNonDiscretionaryDetails
        }
      }
    }
    discretionaryAccountKycs {
      edges {
        node {
          ...CommonUserInvestorInformationDiscretionaryDetails
        }
      }
    }
    trustedContacts {
      edges {
        node {
          id
          id
          firstName
          middleName
          lastName
          dateOfBirth
          email
          phoneNumber
          isActive
          __typename
        }
      }
    }
    trustedContactUpdateRequests {
      edges {
        node {
          id
          ...CommonTrustedContactUpdateRequestsDetails
          __typename
        }
      }
    }
  }
  ${GetAllUserAccountsStatusFragmentFragmentDoc}
  ${TrustedContactDetailsFragmentDoc}
  ${CommonUserProfileRequestParametersFragmentDoc}
  ${CommonUserInvestorInformationNonDiscretionaryDetailsFragmentDoc}
  ${CommonUserInvestorInformationDiscretionaryDetailsFragmentDoc}
  ${CommonTrustedContactUpdateRequestsDetailsFragmentDoc}
`;

/**
 * __useGetUserDetailsV2Query__
 *
 * To run a query within a React component, call `useGetUserDetailsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDetailsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDetailsV2Query({
 *   variables: {
 *   },
 * });
 */
export function useGetUserDetailsV2Query(
  baseOptions?: Apollo.QueryHookOptions<GetUserDetailsV2Query, GetUserDetailsV2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserDetailsV2Query, GetUserDetailsV2QueryVariables>(
    GetUserDetailsV2Document,
    options,
  );
}
export function useGetUserDetailsV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserDetailsV2Query, GetUserDetailsV2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserDetailsV2Query, GetUserDetailsV2QueryVariables>(
    GetUserDetailsV2Document,
    options,
  );
}
export type GetUserDetailsV2QueryHookResult = ReturnType<typeof useGetUserDetailsV2Query>;
export type GetUserDetailsV2LazyQueryHookResult = ReturnType<typeof useGetUserDetailsV2LazyQuery>;
export type GetUserDetailsV2QueryResult = Apollo.QueryResult<
  GetUserDetailsV2Query,
  GetUserDetailsV2QueryVariables
>;
export const GetUserAccountsDocument = gql`
  query getUserAccounts {
    userAccounts {
      edges {
        node {
          id
          externalID
          type
          ascendMeta {
            accountID
            accountNumber
          }
          apexAccountMeta {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useGetUserAccountsQuery__
 *
 * To run a query within a React component, call `useGetUserAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserAccountsQuery, GetUserAccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserAccountsQuery, GetUserAccountsQueryVariables>(
    GetUserAccountsDocument,
    options,
  );
}
export function useGetUserAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserAccountsQuery, GetUserAccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserAccountsQuery, GetUserAccountsQueryVariables>(
    GetUserAccountsDocument,
    options,
  );
}
export type GetUserAccountsQueryHookResult = ReturnType<typeof useGetUserAccountsQuery>;
export type GetUserAccountsLazyQueryHookResult = ReturnType<typeof useGetUserAccountsLazyQuery>;
export type GetUserAccountsQueryResult = Apollo.QueryResult<
  GetUserAccountsQuery,
  GetUserAccountsQueryVariables
>;
export const GetWatchlistSecuritiesDocument = gql`
  query GetWatchlistSecurities {
    watchlists {
      name
      list {
        id
        type
        ...SecurityAssetPage
      }
    }
  }
  ${SecurityAssetPageFragmentDoc}
`;

/**
 * __useGetWatchlistSecuritiesQuery__
 *
 * To run a query within a React component, call `useGetWatchlistSecuritiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWatchlistSecuritiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWatchlistSecuritiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWatchlistSecuritiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWatchlistSecuritiesQuery,
    GetWatchlistSecuritiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWatchlistSecuritiesQuery, GetWatchlistSecuritiesQueryVariables>(
    GetWatchlistSecuritiesDocument,
    options,
  );
}
export function useGetWatchlistSecuritiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWatchlistSecuritiesQuery,
    GetWatchlistSecuritiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWatchlistSecuritiesQuery, GetWatchlistSecuritiesQueryVariables>(
    GetWatchlistSecuritiesDocument,
    options,
  );
}
export type GetWatchlistSecuritiesQueryHookResult = ReturnType<
  typeof useGetWatchlistSecuritiesQuery
>;
export type GetWatchlistSecuritiesLazyQueryHookResult = ReturnType<
  typeof useGetWatchlistSecuritiesLazyQuery
>;
export type GetWatchlistSecuritiesQueryResult = Apollo.QueryResult<
  GetWatchlistSecuritiesQuery,
  GetWatchlistSecuritiesQueryVariables
>;
