import * as yup from 'yup';
import { FORM_ERROR_MESSAGES } from '@fintronners/react-api/src/utils/formUtils/schemas/constants';
import i18n from '@fintronners/react-language/src/Lang';
import {
  REG_PHONE_NUMBER_WITH_MASK,
  REG_EMAIL,
  REG_LETTER_NUMBER,
  REG_UPPERCASE_LETTERS,
  REG_LOWERCASE_LETTERS,
  REG_NUMBERS,
  REG_SPECIAL_CHARACTERS,
  REG_FIRST_LAST_NAME,
  REG_ASCEND_STRING,
} from '@fintronners/react-utils/src/regexUtils';
import {
  AvailableAlpha3Countries,
  AvailableCountries,
} from '@fintronners/react-utils/src/countries';
import { getPhoneNumberDetails } from '@fintronners/react-utils/src/phoneNumberUtils';
import { ACCEPTED_IMAGE_FILE_MIMES } from '@fintronners/react-utils/src/fileUtils';
import { YES_OR_NO } from './types';

export function genericStringWhen<n extends string | string[], v>(
  name: n,
  isValue: v,
  requiredMessage: string,
) {
  return yup.string().when(name, {
    is: isValue,
    then: (schema) => schema.required(requiredMessage),
  });
}

export const getPhoneNumberSchema = (withMask: boolean = false, withAdmin: boolean = false) => {
  if (withAdmin)
    return yup
      .string()
      .required(FORM_ERROR_MESSAGES.required)
      .min(12, FORM_ERROR_MESSAGES.invalidPhoneNumberAdmin)
      .max(12, FORM_ERROR_MESSAGES.invalidPhoneNumberAdmin);
  return withMask
    ? yup.string().required(FORM_ERROR_MESSAGES.required).matches(REG_PHONE_NUMBER_WITH_MASK, {
        message: FORM_ERROR_MESSAGES.invalidPhoneNumber,
        excludeEmptyString: true,
      })
    : yup.string().required(FORM_ERROR_MESSAGES.required).length(10);
};

export const getNameSchema = () => {
  return yup.string().required(FORM_ERROR_MESSAGES.required).max(40);
};

export const getEmailSchema = () => {
  return yup.string().required(FORM_ERROR_MESSAGES.required).matches(REG_EMAIL, {
    message: FORM_ERROR_MESSAGES.invalidEmail,
    excludeEmptyString: true,
  });
};

export const getDateOfBirthSchema = () => {
  return yup.string().required(FORM_ERROR_MESSAGES.required);
};

export const getUserNameSchema = () => {
  return yup
    .string()
    .required(FORM_ERROR_MESSAGES.required)
    .min(3, FORM_ERROR_MESSAGES.minLength(3))
    .matches(REG_LETTER_NUMBER, {
      message: FORM_ERROR_MESSAGES.invalidName('User name'),
      excludeEmptyString: true,
    });
};

export const getPasswordSchema = () => {
  return yup
    .string()
    .required(FORM_ERROR_MESSAGES.required)
    .min(8, FORM_ERROR_MESSAGES.minLength(8))
    .matches(REG_UPPERCASE_LETTERS, FORM_ERROR_MESSAGES.invalidPasswordUppercase)
    .matches(REG_LOWERCASE_LETTERS, FORM_ERROR_MESSAGES.invalidPasswordLowercase)
    .matches(REG_NUMBERS, FORM_ERROR_MESSAGES.invalidPasswordNumber)
    .matches(REG_SPECIAL_CHARACTERS, FORM_ERROR_MESSAGES.invalidPasswordSpecialCharacter)
    .strict()
    .test(
      'no-leading-trailing-space',
      FORM_ERROR_MESSAGES.invalidPasswordLeadingTrailingSpace,
      (value) => {
        if (value) {
          return value.trim() === value;
        }
        return true;
      },
    );
};

export const getConfirmPasswordSchema = (passwordFieldName: string = 'password') => {
  return yup
    .string()
    .required(FORM_ERROR_MESSAGES.required)
    .oneOf([yup.ref(passwordFieldName)], FORM_ERROR_MESSAGES.passwordDoNotMatch);
};

export const getSixDigitsCodeSchema = () => {
  return yup
    .string()
    .required(FORM_ERROR_MESSAGES.required)
    .length(6, FORM_ERROR_MESSAGES.invalidCode);
};

export const getBooleanRequiredSchema = () => {
  return yup.bool().oneOf([true], FORM_ERROR_MESSAGES.required);
};

export const getBooleanSchema = () => {
  return yup.boolean();
};

export const getStringRequiredSchema = () => {
  return yup.string().required(FORM_ERROR_MESSAGES.required);
};

export const getBooleanWhenSchema = (name: string) => {
  return yup.boolean().when('$context', (context: Record<string, any> | undefined, schema) => {
    return context?.[name] ? getBooleanRequiredSchema() : schema.notRequired();
  });
};

export const getArrayNumbersSchema = () => {
  return yup.array().of(yup.number().required()).min(1).required(FORM_ERROR_MESSAGES.required);
};

export const getAscendStringRequiredSchema = (field: string) => {
  return yup
    .string()
    .required(FORM_ERROR_MESSAGES.required)
    .matches(REG_ASCEND_STRING, {
      message: FORM_ERROR_MESSAGES.invalidInputAscend(field),
      excludeEmptyString: true,
    });
};

export const getOneOfEnumSchema = <T extends string>(enumValues: Record<string, T>) => {
  return yup.string().oneOf(Object.values(enumValues)).required(FORM_ERROR_MESSAGES.required);
};

export const getFirstLastNameSchema = (field: 'First name' | 'Last name') => {
  return yup
    .string()
    .required(FORM_ERROR_MESSAGES.required)
    .max(20, FORM_ERROR_MESSAGES.maxLength(20))
    .matches(REG_FIRST_LAST_NAME, {
      message: FORM_ERROR_MESSAGES.invalidName(field),
      excludeEmptyString: true,
    });
};

export const getCountrySchema = () => {
  return yup.object().shape({
    name: getStringRequiredSchema(),
    flag: getStringRequiredSchema(),
    alpha2Code: getOneOfEnumSchema(AvailableCountries),
    alpha3Code: getOneOfEnumSchema(AvailableAlpha3Countries).optional(),
    countryCode: getStringRequiredSchema(),
    areaCodes: getArrayNumbersSchema(),
  });
};

export const getNewPhoneNumberSchema = (desiredCountry?: AvailableCountries) => {
  return yup
    .string()
    .required(i18n.t('errors.required'))
    .test('phone-validation', i18n.t('errors.phoneNumberMismatch'), (value, context) => {
      const countryCode = context.parent.country?.countryCode || '';
      let formattedValue = value.includes('+') ? value : `+${value}`;

      if (countryCode && !value.includes('+')) {
        formattedValue = `${countryCode} ${value}`;
      }

      if (!formattedValue) {
        return context.createError({
          message: i18n.t('errors.required'),
        });
      }

      const { isValid, country } = getPhoneNumberDetails(formattedValue);

      if (
        !isValid ||
        !country ||
        (desiredCountry && country && country.alpha2Code !== desiredCountry)
      )
        return context.createError({
          message: i18n.t('errors.invalidPhoneNumber'),
        });

      return true;
    });
};

export const getImageFileSchema = (field: string, is?: any) => {
  return yup.mixed().when(field, (fieldValues, schema) => {
    if (is !== undefined && fieldValues[0] === is) {
      return schema
        .required(i18n.t('errors.required'))
        .test('fileType', i18n.t('errors.imageFileRequired'), (value) => {
          return value instanceof File && ACCEPTED_IMAGE_FILE_MIMES.includes(value.type);
        });
    }

    return schema.notRequired();
  });
};

export const getYesOrNoSchema = () => {
  return yup
    .mixed<YES_OR_NO>()
    .oneOf(Object.values(YES_OR_NO))
    .required(FORM_ERROR_MESSAGES.required);
};
