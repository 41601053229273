import React, { useEffect } from 'react';
import {
  WebBodyText12,
  WebBodyText14,
} from '@fintronners/react-ui/src/GlobalStyling/webTypography';

import { Button } from '@/components/common/Button';
import { Checkbox } from '@/components/common/Checkbox/Checkbox';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { showToast } from '@/components/common/Toaster/Toaster';
import { supportEmailList } from '@/config';
import {
  WithholdingInfoFormData,
  withholdingInfoSchema,
} from '@fintronners/internal-core/src/withholdingElections/schema';
import { WaiveWithholdingDialog } from '@/components/features/settings/withholdingElections/WaiveWithholdingDialog';
import { WithholdingStateDialog } from '@/components/features/settings/withholdingElections/WithholdingStateDialog';
import PercentageTextField from '@/components/common/Forms/fields/PercentageTextField';
import { StateWithholdingClass, WithholdingRateType } from '@fintronners/react-api/src';
import { useAppStore } from '@/stores/app-store';
import useUserSettingsService from '@/hooks/api/useUserSettingsService';
import { useSidePanel } from '@/context/SidePanelContext';
import cn from '@/utils/tailwind';
import type { UseWithholdingsReturnType } from '@fintronners/internal-core/src/withholdingElections/hooks/useWithholdings';

import type { WithholdingElectionFormProps } from './WithholdingElectionForm';

type WithholdingElectionInnerFormProps = WithholdingElectionFormProps &
  Omit<UseWithholdingsReturnType, 'loading'>;

const displayStrings = {
  withholdingState: 'Withholding state:',
  enterFederalTax: 'Enter a federal tax withholding percentage.',
  federalWithholding: 'Federal withholding',
  stateWithholding: 'State withholding',
  sidePanelFooterDescription:
    'Any changes to your withholding elections will become the default for future withdrawals. You can change these at any time from the Settings page.',
  waive: 'Waive',
  percentage: 'Percentage',
  stateHoldingsLabel:
    'Your state of residence does not require information to be submitted related to state withholdings. You will only be submitting information related to federal withholdings.',
  mandatoryStateLabel:
    'Enter a federal tax withholding percentage. If you enter your federal withholding amount, then you cannot waive your state withholdings and must enter a state withholding amount.',
  mandatoryOptOutStateLabel:
    'You may waive your state withholdings, but this requires the submission of a tax form from your state.',
  saveChanges: 'Save changes',
  contactLabel: (supportEmail: string) =>
    `Please contact ${supportEmail} for the withdrawal form and instructions related to opting out of your state's withholdings.`,
  contactSupport: 'Contact Support',
  enforceWaiveValue: (amount: number) => `Your state requires a minimum withholding of ${amount}%.`,
  successToast: 'Success! Your withholding elections have been saved.',
  errorToast: 'An error occurred. Please try again.',
};

/*
 * Withholding Election Inner Form Component.
 * Rendered by the parent component after all required data is fetched.
 */
const WithholdingElectionInnerForm: React.FC<WithholdingElectionInnerFormProps> = ({
  isSidePanel,
  state,
  userState,
  userStateRate,
  federalWithholdingRate,
  stateWithholdingRate,
  defaultWithholdingState,
}) => {
  const supportEmail = useAppStore((appStoreState) => appStoreState.tenantSupportEmail);

  const userSettingsServiceApi = useUserSettingsService();
  const { closeSidePanel } = useSidePanel();

  const getStateWithholdingRate = () => {
    // FIXME: We are temporarily comparing the string since the WithholdingRateType enum is not updated.
    // We should compare it using WithholdingRateType once it's updated.
    const isPCTofFederal =
      (state.rateType as string) === 'STATE_WITHHOLDING_RATE_TYPE_PCT_OF_FEDERAL';

    if (isPCTofFederal) {
      if (userStateRate) return Number(stateWithholdingRate);
      return Math.ceil(Number(stateWithholdingRate) * Number(federalWithholdingRate)) / 100;
    }

    return Number(stateWithholdingRate);
  };

  const { control, formState, handleSubmit, setError, setValue, resetField } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      withholdingInfoSchema(
        Number(state.rate),
        state.class as StateWithholdingClass,
        state.rateType as WithholdingRateType,
      ),
    ),
    values: {
      federalWithholding: Number(federalWithholdingRate),
      stateWithholding: getStateWithholdingRate(),
      isWaivingFederalWithholding: !federalWithholdingRate,
      isWaivingStateWithholding: !stateWithholdingRate,
    },
  });

  const onSubmitWithholdingInformation = async (data: WithholdingInfoFormData) => {
    const stateValue = defaultWithholdingState || userState;
    if (!stateValue) return;

    try {
      await userSettingsServiceApi.userSettingsServiceUpdateUserSettings({
        retirement: {
          defaultWithholding: {
            federalTaxWithholding: {
              waive: data.isWaivingFederalWithholding,
              percentage: data.federalWithholding?.toString() ?? '0',
            },
            stateTaxWithholding: {
              waive:
                state.class !== StateWithholdingClass.StateWithholdingClassMayNotBeElected
                  ? data.isWaivingStateWithholding
                  : true,
              percentage: data.stateWithholding?.toString() ?? '0',
            },
            state: stateValue,
          },
        },
      });

      isSidePanel && closeSidePanel();

      showToast({ message: displayStrings.successToast, variant: 'success' });
    } catch (error) {
      console.error(error);
      showToast({ message: displayStrings.errorToast, variant: 'error' });
    }
  };

  const { isWaivingFederalWithholding, isWaivingStateWithholding, federalWithholding } = useWatch({
    control,
  });

  const isSaveDisabledForMandatoryOptOut =
    state.class === StateWithholdingClass.StateWithholdingClassMandatoryOptOut &&
    isWaivingStateWithholding;

  useEffect(() => {
    if (isWaivingFederalWithholding) {
      setValue('federalWithholding', 0);
      setError('federalWithholding', {});
    } else {
      resetField('federalWithholding', {
        defaultValue: !federalWithholdingRate ? 1 : federalWithholdingRate,
      });
    }
  }, [isWaivingFederalWithholding]);

  useEffect(() => {
    if (isWaivingStateWithholding) {
      setValue('stateWithholding', 0);
      setError('stateWithholding', {});
    } else {
      const isPCTofFederal =
        (state.rateType as string) === 'STATE_WITHHOLDING_RATE_TYPE_PCT_OF_FEDERAL';

      let defaultStateWithholdingRate;

      if (isPCTofFederal) {
        if (userStateRate) defaultStateWithholdingRate = Number(stateWithholdingRate);
        else
          defaultStateWithholdingRate =
            Math.ceil(stateWithholdingRate * (federalWithholding ?? 10)) / 100;
      } else {
        if (parseFloat(state.rate) === 0) defaultStateWithholdingRate = 1;
        else defaultStateWithholdingRate = stateWithholdingRate;
      }

      resetField('stateWithholding', {
        defaultValue: defaultStateWithholdingRate,
      });
    }
  }, [isWaivingStateWithholding]);

  useEffect(() => {
    if (federalWithholding === 0) {
      setValue('isWaivingFederalWithholding', true);
    } else {
      if (
        federalWithholding &&
        !isWaivingStateWithholding &&
        (state.rateType as string) === 'STATE_WITHHOLDING_RATE_TYPE_PCT_OF_FEDERAL'
      ) {
        resetField('stateWithholding', {
          defaultValue: userStateRate
            ? Number(stateWithholdingRate)
            : Math.ceil(stateWithholdingRate * federalWithholding) / 100,
        });
      }
    }
  }, [federalWithholding]);

  const showStateWithholding =
    state.class !== StateWithholdingClass.StateWithholdingClassMayNotBeElected;
  const showEnforceWaiveValue =
    !isWaivingStateWithholding &&
    ((parseFloat(state.rate) && state.rateType === WithholdingRateType.WithholdingRateTypeFixed) ||
      ((state.rateType as string) === 'STATE_WITHHOLDING_RATE_TYPE_PCT_OF_FEDERAL' &&
        !isWaivingFederalWithholding));

  return (
    <form
      onSubmit={handleSubmit(onSubmitWithholdingInformation)}
      className={cn({
        'flex h-full flex-col justify-between': isSidePanel,
      })}
    >
      <div className="mt-5 flex flex-col gap-5">
        <div>
          {state.name && (
            <>
              <div className="flex gap-1">
                <WithholdingStateDialog state={state.name} />
                <WebBodyText14.Bold className="text-grey72">
                  {displayStrings.withholdingState}
                </WebBodyText14.Bold>
              </div>
              <Button className="bg-primary/20 text-primary pointer-events-none mt-1 border-none">
                {state.name}
              </Button>
            </>
          )}
        </div>
        {state.class === StateWithholdingClass.StateWithholdingClassMandatory ? (
          <WebBodyText14.Regular className="text-grey72">
            {displayStrings.mandatoryStateLabel}
          </WebBodyText14.Regular>
        ) : (
          <WebBodyText14.Regular className="text-grey72">
            {displayStrings.enterFederalTax}
          </WebBodyText14.Regular>
        )}
        {state.class === StateWithholdingClass.StateWithholdingClassMandatoryOptOut && (
          <WebBodyText14.Regular className="text-grey72">
            {displayStrings.mandatoryOptOutStateLabel}
          </WebBodyText14.Regular>
        )}
        <div>
          <div className="flex items-center justify-between">
            <WebBodyText14.Bold className="text-darkestBlue">
              {displayStrings.federalWithholding}
            </WebBodyText14.Bold>
            <div className="flex items-center gap-1">
              <WaiveWithholdingDialog />
              <WebBodyText12.Bold className="text-grey55">
                {displayStrings.waive}
              </WebBodyText12.Bold>
              <Controller
                control={control}
                name="isWaivingFederalWithholding"
                render={({ field: { value, onChange } }) => (
                  <Checkbox onCheckedChange={onChange} checked={value} />
                )}
              />
            </div>
          </div>
          <div className="mt-2 flex flex-col gap-2">
            <WebBodyText12.Medium className="text-grey72">
              {displayStrings.percentage}
            </WebBodyText12.Medium>
            <Controller
              control={control}
              name="federalWithholding"
              disabled={isWaivingFederalWithholding}
              render={({ field: { name, onChange, ref, value, disabled }, fieldState }) => (
                <PercentageTextField
                  ref={ref}
                  name={name}
                  disabled={disabled}
                  value={value}
                  onValueChange={onChange}
                  fieldError={fieldState.error}
                />
              )}
            />
          </div>
        </div>
        {showStateWithholding && (
          <div>
            <div className="flex items-center justify-between">
              <WebBodyText14.Bold className="text-darkestBlue">
                {displayStrings.stateWithholding}
              </WebBodyText14.Bold>
              {(state.class !== StateWithholdingClass.StateWithholdingClassMandatory ||
                isWaivingFederalWithholding) && (
                <div className="flex items-center gap-1">
                  <WaiveWithholdingDialog />
                  <WebBodyText12.Bold className="text-grey55">
                    {displayStrings.waive}
                  </WebBodyText12.Bold>
                  <Controller
                    control={control}
                    name="isWaivingStateWithholding"
                    render={({ field: { value, onChange } }) => (
                      <Checkbox onCheckedChange={onChange} checked={value} />
                    )}
                  />
                </div>
              )}
            </div>
            <div className="mt-2 flex flex-col gap-2">
              <WebBodyText12.Medium className="text-grey72">
                {displayStrings.percentage}
              </WebBodyText12.Medium>
              <Controller
                control={control}
                name="stateWithholding"
                disabled={isWaivingStateWithholding}
                render={({ field: { name, onChange, ref, value, disabled }, fieldState }) => (
                  <>
                    <PercentageTextField
                      ref={ref}
                      name={name}
                      disabled={disabled}
                      value={value}
                      onValueChange={(newValue) => {
                        onChange(newValue ?? '');
                      }}
                      fieldError={!showEnforceWaiveValue ? fieldState.error : undefined}
                    />
                    {showEnforceWaiveValue && (
                      <WebBodyText12.Regular
                        className={`${fieldState.error ? 'text-red' : 'text-grey55'}`}
                      >
                        {displayStrings.enforceWaiveValue(
                          (state.rateType as string) ===
                            'STATE_WITHHOLDING_RATE_TYPE_PCT_OF_FEDERAL' && federalWithholding
                            ? Math.ceil(Number(state.rate) * federalWithholding) / 100
                            : Number(state.rate),
                        )}
                      </WebBodyText12.Regular>
                    )}
                  </>
                )}
              />
            </div>
          </div>
        )}
        {state.class === StateWithholdingClass.StateWithholdingClassMayNotBeElected && (
          <WebBodyText14.Regular className="text-grey55">
            {displayStrings.stateHoldingsLabel}
          </WebBodyText14.Regular>
        )}
        {state.class === StateWithholdingClass.StateWithholdingClassMandatoryOptOut &&
          isWaivingStateWithholding && (
            <div className="flex flex-col items-center justify-center gap-6">
              <WebBodyText14.Regular className="text-grey55">
                {displayStrings.contactLabel(supportEmail ?? supportEmailList.Fintron)}
              </WebBodyText14.Regular>
              <Button variant="secondary" type="button">
                {displayStrings.contactSupport}
              </Button>
            </div>
          )}
      </div>
      <div>
        {isSidePanel && (
          <WebBodyText14.Regular className="text-grey41 mb-7 text-center font-normal">
            {displayStrings.sidePanelFooterDescription}
          </WebBodyText14.Regular>
        )}

        <Button
          className={cn('w-full', {
            'mt-7': !isSidePanel,
            'mb-12': isSidePanel,
          })}
          variant="primary"
          type="submit"
          disabled={
            !formState.isValid ||
            !formState.isDirty ||
            !userState ||
            isSaveDisabledForMandatoryOptOut
          }
        >
          {displayStrings.saveChanges}
        </Button>
      </div>
    </form>
  );
};

export default WithholdingElectionInnerForm;
