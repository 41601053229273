import React, { useEffect } from 'react';
import { SheetContent, SheetFooter, SheetHeader, SheetTitle } from '@/components/common/Sheet';
import {
  NonDiscretionaryAccountKycUserEmploymentStatus,
  V1UserEmploymentStatus,
  V1UserMaritalStatus,
  V1UserNetWorth,
} from '@fintronners/react-api';
import { InvestmentQuestionsRadio } from '../InvestmentQuestionsRadio';
import { Button } from '@/components/common/Button';
import { PersonalFormData, personalFormSchema } from './schema';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSidePanel } from '@/context/SidePanelContext';
import useUserDetails from '@fintronners/internal-core/src/user/hooks/useUserDetails';
import { ErrorSidePanel } from '@/components/common/SidePanels/ErrorSidePanel/ErrorSidePanel';
import {
  getInvestorInformationNonDiscretionaryFormFieldPending,
  getInvestorInformationNonDiscretionaryFormFieldValue,
} from '@fintronners/react-api/src/utils/formUtils/schemas/pending/investorInformationNonDiscretionary';
import useNonDiscretionaryUpdateRequests from '@fintronners/react-widgets/src/hooks/useNonDiscretionaryUpdateRequests';
import { getFieldDifferenceKycNonDiscretionary } from '@fintronners/react-api/src/utils/formUtils/schemas/investorInformationNonDiscretionary/utils';
import { displayStrings, formOptions } from './data';
import FormTextField from '@/components/common/Forms/fields/FormTextField';
import PendingChangesNotice from '@/components/notices/PendingChangesNotice';
import {
  NonDiscretionaryAccountKycUserAnnualIncome,
  V1UpdateKYCRequest,
} from '@fintronners/react-api/src';
import useProfileService from '@/hooks/api/useProfileService';
import { useSidepanelError } from '@/hooks/utils/useSidepanelError';
import { useAppStore } from '@/stores/app-store';
import { getCurrentTenant } from '@/config';

export const PersonalQuestionsForm = () => {
  // TODO: Workaround for the tenant while we don't have a way to identify if it is an Ascend user or not
  const newApiUrl = useAppStore((state) => state.newApiUrl);
  const currentTenant = getCurrentTenant(newApiUrl);

  const userApiService = useProfileService();
  const { nonDiscretionaryAccountKycs: kycInfo } = useUserDetails();
  const { nonDiscretionaryUpdateRequests: updateRequests } = useNonDiscretionaryUpdateRequests();

  const { goBack, setShowBackLink, setShowCloseButton } = useSidePanel();

  const { hasError, handleError, onErrorSidePanelBack } = useSidepanelError(
    setShowBackLink,
    setShowCloseButton,
  );

  const {
    control,
    formState: { isDirty, isValid },
    handleSubmit,
    watch,
    reset,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(personalFormSchema),
    values: {
      maritalStatus: kycInfo?.maritalStatus ?? V1UserMaritalStatus.Single,
      employmentStatus:
        (kycInfo?.employmentStatus as V1UserEmploymentStatus) ?? V1UserEmploymentStatus.Unemployed,
      annualIncome:
        kycInfo?.annualIncome ??
        NonDiscretionaryAccountKycUserAnnualIncome.UserAnnualIncome_0_25000,
      netWorth: kycInfo?.netWorth ?? V1UserNetWorth._050000,
      employerName: kycInfo?.employmentDetails?.EmployerName ?? '',
      jobPosition: kycInfo?.employmentDetails?.Position ?? '',
    },
  });

  const employmentStatus = watch('employmentStatus');

  const isPending = formOptions(currentTenant)
    .map((formOption) =>
      getInvestorInformationNonDiscretionaryFormFieldPending(updateRequests, formOption.name),
    )
    .some((formOptionIsPending) => formOptionIsPending === true);

  const items = formOptions(currentTenant).map((option) => {
    return {
      ...option,
      isPending: getInvestorInformationNonDiscretionaryFormFieldPending(
        updateRequests,
        option.name,
      ),
    };
  });

  useEffect(() => {
    reset({
      maritalStatus: getInvestorInformationNonDiscretionaryFormFieldValue(
        updateRequests,
        'maritalStatus',
        kycInfo?.maritalStatus,
      ),
      employmentStatus: getInvestorInformationNonDiscretionaryFormFieldValue(
        updateRequests,
        'employmentStatus',
        kycInfo?.employmentStatus,
      ),
      annualIncome: getInvestorInformationNonDiscretionaryFormFieldValue(
        updateRequests,
        'annualIncome',
        kycInfo?.annualIncome,
      ),
      netWorth: getInvestorInformationNonDiscretionaryFormFieldValue(
        updateRequests,
        'netWorth',
        kycInfo?.netWorth,
      ),
      employerName: getInvestorInformationNonDiscretionaryFormFieldValue(
        updateRequests,
        'EmployerName',
        kycInfo?.employmentDetails?.EmployerName,
      ),
      jobPosition: getInvestorInformationNonDiscretionaryFormFieldValue(
        updateRequests,
        'Position',
        kycInfo?.employmentDetails?.Position,
      ),
    });
  }, [kycInfo, updateRequests]);

  const onUpdatePersonalQuestions = async (data: PersonalFormData) => {
    const request: V1UpdateKYCRequest = {
      brokerage: {
        ...getFieldDifferenceKycNonDiscretionary(
          kycInfo?.maritalStatus,
          data?.maritalStatus,
          'maritalStatus',
        ),
        ...getFieldDifferenceKycNonDiscretionary(
          kycInfo?.employmentStatus,
          data?.employmentStatus,
          'employmentStatus',
        ),
        ...getFieldDifferenceKycNonDiscretionary(
          kycInfo?.annualIncome,
          data?.annualIncome,
          'annualIncomeRange',
        ),
        ...getFieldDifferenceKycNonDiscretionary(
          kycInfo?.netWorth,
          data?.netWorth,
          'netWorthRange',
        ),
        ...(employmentStatus === V1UserEmploymentStatus.Employed && {
          employmentStatus:
            NonDiscretionaryAccountKycUserEmploymentStatus.UserEmploymentStatusEmployed,
          employmentInfo: {
            employerName: data.employerName,
            jobPosition: data.jobPosition,
          },
        }),
      },
    };

    try {
      await userApiService.userServiceUpdateKYC(request);

      goBack();
    } catch (error) {
      handleError();
    }
  };

  if (hasError) return <ErrorSidePanel onBack={onErrorSidePanelBack} />;

  return (
    <SheetContent className="overflow-hidden">
      <form className="flex h-full flex-col" onSubmit={handleSubmit(onUpdatePersonalQuestions)}>
        <SheetHeader>
          <SheetTitle className="mb-4">{displayStrings.title}</SheetTitle>
        </SheetHeader>
        <div>{isPending && <PendingChangesNotice />}</div>
        <div className="mt-4 flex h-full flex-col gap-7 overflow-auto">
          {items.map((option, index) => (
            <div key={index}>
              <InvestmentQuestionsRadio {...option} control={control} />
              {option.name === 'employmentStatus' &&
                employmentStatus === V1UserEmploymentStatus.Employed && (
                  <div className="mt-2 flex flex-col gap-2 pr-2">
                    <Controller
                      control={control}
                      name="employerName"
                      render={({ field, fieldState }) => (
                        <FormTextField
                          {...field}
                          fieldError={fieldState.error}
                          label={displayStrings.employerLabel}
                          placeholder={displayStrings.employerName}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="jobPosition"
                      render={({ field, fieldState }) => (
                        <FormTextField
                          {...field}
                          fieldError={fieldState.error}
                          label={displayStrings.positionLabel}
                          placeholder={displayStrings.yourPosition}
                        />
                      )}
                    />
                  </div>
                )}
            </div>
          ))}
        </div>

        <SheetFooter>
          <div className="mt-8 flex w-full flex-col">
            <Button
              variant="primary"
              type="submit"
              className="mb-4"
              disabled={!isDirty || !isValid}
            >
              {displayStrings.save}
            </Button>
            <Button variant="secondary" type="button" onClick={goBack}>
              {displayStrings.back}
            </Button>
          </div>
        </SheetFooter>
      </form>
    </SheetContent>
  );
};
