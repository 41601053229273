import React, { useEffect, useMemo } from 'react';
import { SheetContent, SheetHeader, SheetTitle } from '@/components/common/Sheet';
import {
  WebBodyText16,
  WebBodyText14,
} from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import useUserDetails from '@fintronners/internal-core/src/user/hooks/useUserDetails';
import { getUserKycDisplayString } from '@fintronners/react-experience/src/Helpers/GraphQLMapUtilsTS';
import {
  getInvestorInformationNonDiscretionaryFormFieldPending,
  getInvestorInformationNonDiscretionaryFormFieldValue,
} from '@fintronners/react-api/src/utils/formUtils/schemas/pending/investorInformationNonDiscretionary';
import useNonDiscretionaryUpdateRequests from '@fintronners/react-widgets/src/hooks/useNonDiscretionaryUpdateRequests';
import { ListItemButton } from '@/components/common/ListItemButton';
import { InvestmentValuesList } from './parts/InvestmentValuesList';
import { InvestorFormKey } from './InvestorProfileSidePanel';
import { getInvestorProfilePendingStates, mapFormTypeToPendingState } from './utils';
import { useSidePanel } from '@/context/SidePanelContext';

const displayStrings = {
  investorProfileInformation: 'Investor profile information',
  investing: 'Investing',
  personal: 'Personal',
  exposure: 'Exposure',
  investingExperience: 'Investment experience',
  investmentObjective: 'Investment objective',
  investmentTimeHorizon: 'Investment time horizon',
  importanceOfCashOnHand: 'Importance of cash on hand',
  marriageStatus: 'Marriage status',
  employmentStatus: 'Employment status',
  annualIncome: 'Annual income',
  netWorth: 'Net worth',
  controlPerson: 'Control person',
  politicallyExposed: 'Politically exposed',
  finraStockExchangeMember: 'FINRA/Stock exchange member',
  securitiesProfessional: 'Securities professional',
};

interface InvestorProfileSummaryProps {
  onEdit: (formToEdit: InvestorFormKey, isPending?: boolean) => void;
  step: number;
}

export const InvestorProfileSummary = ({ onEdit, step }: InvestorProfileSummaryProps) => {
  const { userDetails, nonDiscretionaryAccountKycs } = useUserDetails();
  const { nonDiscretionaryUpdateRequests, refetchNonDiscretionaryUpdateRequests } =
    useNonDiscretionaryUpdateRequests();
  const { onGoBack } = useSidePanel();

  const formPendingStates = useMemo(() => {
    return getInvestorProfilePendingStates(userDetails?.profile?.updateRequests);
  }, [userDetails, nonDiscretionaryUpdateRequests]);

  const investingDetails = [
    {
      label: displayStrings.investingExperience,
      value: getUserKycDisplayString(
        getInvestorInformationNonDiscretionaryFormFieldValue(
          nonDiscretionaryUpdateRequests,
          'investmentExperience',
          nonDiscretionaryAccountKycs?.investmentExperience,
        ),
      ),
      isPending: getInvestorInformationNonDiscretionaryFormFieldPending(
        nonDiscretionaryUpdateRequests,
        'investmentExperience',
      ),
    },
    {
      label: displayStrings.investmentObjective,
      value: getUserKycDisplayString(
        getInvestorInformationNonDiscretionaryFormFieldValue(
          nonDiscretionaryUpdateRequests,
          'investmentObjective',
          nonDiscretionaryAccountKycs?.investmentObjective,
        ),
      ),
      isPending: getInvestorInformationNonDiscretionaryFormFieldPending(
        nonDiscretionaryUpdateRequests,
        'investmentObjective',
      ),
    },
    {
      label: displayStrings.investmentTimeHorizon,
      value: getUserKycDisplayString(
        getInvestorInformationNonDiscretionaryFormFieldValue(
          nonDiscretionaryUpdateRequests,
          'timeHorizon',
          nonDiscretionaryAccountKycs?.timeHorizon,
        ),
      ),
      isPending: getInvestorInformationNonDiscretionaryFormFieldPending(
        nonDiscretionaryUpdateRequests,
        'timeHorizon',
      ),
    },
    {
      label: displayStrings.importanceOfCashOnHand,
      value: getUserKycDisplayString(
        getInvestorInformationNonDiscretionaryFormFieldValue(
          nonDiscretionaryUpdateRequests,
          'liquidityNeeds',
          nonDiscretionaryAccountKycs?.liquidityNeeds,
        ),
      ),
      isPending: getInvestorInformationNonDiscretionaryFormFieldPending(
        nonDiscretionaryUpdateRequests,
        'liquidityNeeds',
      ),
    },
  ];

  const personalDetails = [
    {
      label: displayStrings.marriageStatus,
      value: getUserKycDisplayString(
        getInvestorInformationNonDiscretionaryFormFieldValue(
          nonDiscretionaryUpdateRequests,
          'maritalStatus',
          nonDiscretionaryAccountKycs?.maritalStatus,
        ),
      ),
      isPending: getInvestorInformationNonDiscretionaryFormFieldPending(
        nonDiscretionaryUpdateRequests,
        'maritalStatus',
      ),
    },
    {
      label: displayStrings.employmentStatus,
      value: getUserKycDisplayString(
        getInvestorInformationNonDiscretionaryFormFieldValue(
          nonDiscretionaryUpdateRequests,
          'employmentStatus',
          nonDiscretionaryAccountKycs?.employmentStatus,
        ),
      ),
      isPending: getInvestorInformationNonDiscretionaryFormFieldPending(
        nonDiscretionaryUpdateRequests,
        'employmentStatus',
      ),
    },
    {
      label: displayStrings.annualIncome,
      value: getUserKycDisplayString(
        getInvestorInformationNonDiscretionaryFormFieldValue(
          nonDiscretionaryUpdateRequests,
          'annualIncome',
          nonDiscretionaryAccountKycs?.annualIncome,
        ),
      ),
      isPending: getInvestorInformationNonDiscretionaryFormFieldPending(
        nonDiscretionaryUpdateRequests,
        'annualIncome',
      ),
    },
    {
      label: displayStrings.netWorth,
      value: getUserKycDisplayString(
        getInvestorInformationNonDiscretionaryFormFieldValue(
          nonDiscretionaryUpdateRequests,
          'netWorth',
          nonDiscretionaryAccountKycs?.netWorth,
        ),
      ),
      isPending: getInvestorInformationNonDiscretionaryFormFieldPending(
        nonDiscretionaryUpdateRequests,
        'netWorth',
      ),
    },
  ];

  const marketData = userDetails?.profile?.marketDataAnswers;

  const isSecuritiesProfessional =
    marketData?.advisorOrConsultant ||
    marketData?.investForOther ||
    marketData?.personalUse === false ||
    marketData?.registeredWithSecOrCftc ||
    marketData?.requiresSecOrCftcRegistration;

  const exposureMenuItems = [
    {
      title: displayStrings.controlPerson,
      subtitle: getUserKycDisplayString(
        Boolean(userDetails?.profile?.controlPersonDetails?.isControlPerson),
      ),
      form: InvestorFormKey.CONTROL_PERSON,
    },
    {
      title: displayStrings.politicallyExposed,
      subtitle: getUserKycDisplayString(
        Boolean(userDetails?.profile?.politicallyExposedDetails?.isPoliticallyExposed),
      ),
      form: InvestorFormKey.POLITICALLY_EXPOSED,
    },
    {
      title: displayStrings.finraStockExchangeMember,
      subtitle: getUserKycDisplayString(
        Boolean(userDetails?.profile?.affiliatedExchangeOrFinraDetails?.isAffiliated),
      ),
      form: InvestorFormKey.FINRA_STOCK_EXCHANGE_MEMBER,
    },
    {
      title: displayStrings.securitiesProfessional,
      subtitle: getUserKycDisplayString(isSecuritiesProfessional ?? false),
      form: InvestorFormKey.SECURITIES_PROFESSIONAL,
    },
  ];

  const displayItems = [
    {
      title: displayStrings.investing,
      items: investingDetails,
      form: InvestorFormKey.INVESTING,
    },
    {
      title: displayStrings.personal,
      items: personalDetails,
      form: InvestorFormKey.PERSONAL,
    },
  ];

  useEffect(() => {
    refetchNonDiscretionaryUpdateRequests();
  }, [onGoBack]);

  if (step !== 0) return null;

  return (
    <SheetContent>
      <SheetHeader>
        <SheetTitle>{displayStrings.investorProfileInformation}</SheetTitle>
      </SheetHeader>
      <div className="flex h-full flex-col justify-between">
        <div className="flex flex-col gap-8 pb-5">
          {displayItems.map((details, index) => (
            <InvestmentValuesList key={index} {...details} onEdit={(form) => onEdit(form)} />
          ))}
          <div>
            <WebBodyText16.Bold className="text-darkestBlue">
              {displayStrings.exposure}
            </WebBodyText16.Bold>

            {exposureMenuItems.map((details, index) => {
              const pendingKey = mapFormTypeToPendingState(details.form);
              const isPending =
                pendingKey in formPendingStates &&
                !!formPendingStates[pendingKey as keyof typeof formPendingStates];

              return (
                <ListItemButton
                  key={index}
                  className="!py-4"
                  {...details}
                  rightContent={
                    isPending ? (
                      <div className="flex items-center justify-center rounded-[3px] bg-primary/10 px-[7px] py-[3px] text-primary">
                        <WebBodyText14.Bold className="text-primary">Pending</WebBodyText14.Bold>
                      </div>
                    ) : undefined
                  }
                  onClick={() => onEdit(details.form, isPending)}
                />
              );
            })}
          </div>
        </div>
      </div>
    </SheetContent>
  );
};
